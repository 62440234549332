import { UploadRounded } from '@mui/icons-material';
import initialState from './initialState';
import moment from 'moment-timezone'

const bulkcardReducer = (state = initialState, action) => {

    switch (action.type) {
        case 'UPDATE_REQID':
            return Object.assign({}, state, { requestNo : action.data });
        case 'REMOVE_BULKDATA' :
            return Object.assign({}, state, { passedData: [], failedData: [] });
        case 'ADD_BULK_DATA':
            return Object.assign({}, state, { bulkobj: action.obj, saved: action.saved });
        case 'GET_BULKDATA':
            let bulkData = action.data.data.carddata;
            let passedData = [];
            let failedData = [];
            let failedRecords = [];
            let uploadedRecords = [];
            let inProcessRecords = [];
            let completedRecords = [];
            for (let i = 0; i < bulkData.length; ++i) {
                let mNumber = bulkData[i]['mobileNumber'].split('@');
                let obj = {
                    'key': bulkData[i]['id'],
                    'ID': bulkData[i]['id'],
                    'id': bulkData[i]['id'],
                    'cardRef': bulkData[i]['cardRef'],
                    'request_id': bulkData[i]['requestId'],
                    'name': bulkData[i]['name'],
                    'lastName': bulkData[i]['lastName'],
                    'emailId': bulkData[i]['emailId'],
                    'cCode': mNumber[0],
                    'mobileNumber':mNumber[1],
                    'validFrom': bulkData[i]['isPassed'] === 'Completed' ? moment(new Date(bulkData[i]['updatedTimestamp'])).format('YYYY-MM-DD') : bulkData[i]['validFrom'],
                    'validTill': bulkData[i]['validTill'],
                    'creditLimit': bulkData[i]['creditLimit'],
                    'field1': bulkData[i]['field1'],
                    'field2': bulkData[i]['field2'],
                    'notes': bulkData[i]['notes'],
                    'employeeId': bulkData[i]['employeeId'],
                    'status' :  bulkData[i]['isPassed'],
                    'cardCreatedTime': bulkData[i]['cardCreatedDate'],
                    'osn': bulkData[i]['osn'],
                }
                if (bulkData[i]['isPassed'] === "false") {
                    failedData.push(obj);
                    
                } else {
                    passedData.push(obj);
                    if(bulkData[i]['isPassed'] === "true")
                        uploadedRecords.push(obj)
                    if(bulkData[i]['isPassed'] === "Processing" || bulkData[i]['isPassed'] === "Sent For Approval")
                        inProcessRecords.push(obj);
                    if(bulkData[i]['isPassed'] === "Failed" || bulkData[i]['isPassed'] === "Card Creation Failed")
                        failedRecords.push(obj);
                    if(bulkData[i]['isPassed'] === "Completed" || bulkData[i]['isPassed'] === "Approval Declined" || bulkData[i]['isPassed'] === "Rejected")
                        completedRecords.push(obj);
                }
            }
            return Object.assign({}, state, { passedData: passedData, failedData: failedData, uploadedRecords: uploadedRecords,
                 failedRecords: failedRecords , completedRecords: completedRecords, inProcessRecords: inProcessRecords, totalPage:Math.ceil(passedData.length/10)});
        case 'CREATE_CARD':
            return Object.assign({}, state, { cardCreationResponseObj: action.data.data.data, saved: action.saved });
        case 'SEND_APPROVAL':
            return Object.assign({}, state, { sentForApprovalObj: action.data.data, saved: action.saved });
        case 'VALIDTE_BULK_DATA':
            return Object.assign({}, state, { validateObj: action.obj, saved: action.saved });
        case 'DELETE_CARDS':
            return Object.assign({}, state, { deleteObj: action.obj, saved: action.saved });
        case 'GET_APPROVAL_DATA': {
            return Object.assign({}, state, { approvalObj: action.obj, saved: action.saved });
        }
        default:
            return state;
    }
};

export default bulkcardReducer;