import React from 'react';
import { connect } from 'react-redux';
import 'antd/dist/antd.css';
import { Select } from 'antd';
import axios from 'axios';
import MediaQuery from 'react-responsive';
import 'react-responsive-ui/style.css';
import XLSX from 'xlsx';
import { Modal, Spin, Upload, Button, notification, Icon, Table } from 'antd';
import { loaderFocus } from '../../../utils/accessability';
// import '../../../styling/createtrip.css';
import '../../../styling/expenseCat.css';
const { URLs, errorMessages } = require('../../../data/constants.js');
const confirm = Modal.confirm;
const Dragger = Upload.Dragger;
var initialData = [];
const Option = Select.Option;
var catfirstValue = null;
var rABS = typeof FileReader !== 'undefined' && FileReader.prototype && FileReader.prototype.readAsBinaryString;
const EditableCell = ({ editable, value, onChange }) => (
    <div>
        {editable
            ? <input className="editabletextbox" value={value} onChange={e => onChange(e.target.value)} />
            : <span style={{ paddingLeft: "20%", fontWeight: "500", fontSize: "20px" }}>{value}</span>
        }
    </div>
);
const EditableCategoryCell = ({ editable, value, onChange, expenseCat, uniqueExpenseCat }) => (
    <div>
        {expenseCat.map(item => {
            if (value && value.toUpperCase() === item.cadreCategory.toUpperCase()) {
                catfirstValue = item.cadreCategory;
            }
            return null;
        }
        )}
        {editable ?
            <Select className="columnrenderclass" defaultValue={catfirstValue} onChange={value => onChange(value)} >
                {
                    uniqueExpenseCat.map((item, key) =>
                        <Option value={`${item}_${key}`}>{item}</Option>)
                }
            </Select> :
            <span style={{ textAlign: "center" }} className="input-text">{value}</span>
        }
    </div>
);

const EditableForm = ({ editable, value, onChange, app_labels }) => (
    <div>
        {editable ?
            <Select className="columnrenderclass" defaultValue={value == null ? 0 : value} onChange={value => onChange(value)}>
                <Option value={1}>{app_labels.form1}</Option>
                <Option value={2}>{app_labels.form2}</Option>
                <Option value={3}>{app_labels.form3}</Option>
                <Option value={4}>{app_labels.form4}</Option>
                <Option value={5}>{app_labels.form5}</Option>
                <Option value={0}>{app_labels.na}</Option>
            </Select> : <span style={{ textAlign: "center" }} className="input-text">{value !== null && value !== undefined ? "Form" + value : 'NA'}</span>
        }
    </div>
);

const EditableExpenseStatus = ({ editable, value, onChange }) => (
    <div>
        {editable ?
            <Select className="columnrenderclass" defaultValue={value} onChange={value => onChange(value)}>
                <Option value='1'>True</Option>
                <Option value='0'>False</Option>
            </Select> : <span style={{ textAlign: "center" }} className="input-text" >{value}</span>
        }
    </div>
);
class AddBulkExpCat extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fileList: [],
            allExpCat: [],
            filename: "",
            visible: false,
            successmsg: false,
            messageContent: "",
            jsonData: false,
            selectedRowKeys: [],
            editiconColor: 'grey',
            deleteiconColor: "grey",
            data: [],
            initialData: [],
            uploadFlag: false,
            expWizardCategory: [],
            editFlag: false,
            row: null,
            visibleTable: false
        }
        this.cacheData = this.state.data.map(item => ({ ...item }));
        this.handlebulkCancel = this.handlebulkCancel.bind(this);
        this.updateStates = this.updateStates.bind(this);
        this.handleUpload = this.handleUpload.bind(this);
    }

    componentDidMount() {
        this.props.getAllExpCategoryMap(false)
    }
    /**
     * @param  {} text
     * @param  {} record
     * @param  {} column
     *rendering the columns according to requirement
     */

    renderColumns(text, record, column) {
        return <EditableCell
            editable={record.editable}
            value={text}
            onChange={value => this.handleChange(value, record.key, column)}
        />
    }

    /**
     * rendering the category column
     * @param {*} text 
     * @param {*} record 
     * @param {*} column 
     */
    renderCategoryColumns(text, record, column) {
        // let uniqueExpenseCat = this.props.expenseCat.reduce((accumulator, item) => {
        //     if (accumulator.findIndex(el => el.cadreCategory === item.cadreCategory) === -1) {
        //         accumulator.push(item)
        //     }
        //     return accumulator
        // }, []) 
        return <EditableCategoryCell
            editable={record.editable}
            value={record.ExpenseWizardCategory}
            onChange={value => this.handleChangeExpcat(value, record.key, column)}
            expenseCat={this.props.expenseCat}
            uniqueExpenseCat={this.state.expWizardCategory}
        />
    }
    /**
     * rendering the form column
     */
    renderFormColumn(text, record, column) {
        return <EditableForm
            editable={record.editable}
            value={text}
            onChange={value => this.handleChange(value, record.key, column)}
            app_labels= {this.props.app_labels}
        />
    }

    /**
     * Rendering the expense status column
     * @param {*} text 
     * @param {*} record 
     * @param {*} column 
     */
    renderExpenseStatusColumn(text, record, column) {
        return <EditableExpenseStatus
            editable={record.editable}
            value={text}
            onChange={value => this.handleChange(value, record.key, column)}
        />
    }

    /**
     * on click of Save, parse the data into json and return the same, else download the error response
     * @param {*} blob 
     */
    handleResponseOfSaveBulk(blob) {
        return new Promise((resolve, reject) => {
            let reader = new FileReader()
            reader.onload = (data) => {
                try {
                    // try to json parse the data
                    let jsonData = JSON.parse(data.target.result);
                    return resolve(jsonData)
                } catch (e) {
                    // error in json parsing; response may not be json; download that
                    this.handleDownload(blob)
                    return resolve(undefined)
                }
            }
            reader.onerror = function (event) {
                reader.abort()
                return resolve(undefined)
            }
            reader.readAsText(blob)
        })
    }

    /**
     * on click of Download, handles download of excel sheet with data
     * @param {*} data 
     */
    handleDownload(data) {
        if (navigator.msSaveBlob) { // IE block
            var blobObject = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            window.navigator.msSaveOrOpenBlob(blobObject, "Error Excel.xlsx");
        } else { //non IE block
            var a = document.createElement('a');
            a.href = window.URL.createObjectURL(new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })); // xhr.response is a blob
            a.download = "Error Excel"; // Set the file name.
            a.style.display = 'none';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            setTimeout(function () {
                // For Firefox it is necessary to delay revoking the ObjectURL
                window.URL.revokeObjectURL(a.href);
            }, 100)
        }
    }

    /**
     * handles the changes in the expense category
     */
    handleChangeExpcat = (value, key, column) => {
        const newData = [...this.state.data];
        const target = newData.filter(item => key === item.key)[0];
        let pos = value.search("_")
        let selectedcrCategory = value.substring(0, pos)
        if (target) {
            target[column] = selectedcrCategory;
            this.setState({ data: newData });
        }
        this.props.expenseCat.map(item => {
            if (selectedcrCategory === item.expCategoryId) {
                this.setState({ [key]: item.crCategory })
            }
            return null;
        })
    }
    /**
     * @param  {} value
     * @param  {} key
     * @param  {} column
     * onchange of cell it update the dataList
     */
    handleChange = (value, key, column) => {
        const newData = [...this.state.data];
        const target = newData.filter(item => key === item.key)[0];
        if (target) {
            target[column] = value;
            this.setState({ data: newData });
        }
    }
    /**
     * onclick of save ,handles the data
     */
    handleSave = () => {
        var bulkarray = [];
        this.state.data.map((object) => {
            if (object.CompanyName) {
                var expenseId = "";
                var companyId = "";
                this.props.activeCompanyName.map((item) => {
                    if (item.companyName.toLowerCase() === object.CompanyName.toLowerCase()) {
                        companyId = item.companyId;
                    }
                    return null;
                })
                if (object.ExpenseWizardCategory !== undefined) {
                    this.props.expenseCat.map((item) => {
                        if (parseInt(item.companyId) === parseInt(companyId) && item.cadreCategory.toLowerCase() === object.ExpenseWizardCategory.toLowerCase()) {
                            expenseId = item.expCategoryId;
                        }
                        return null;
                    })
                }
                let obj = {
                    "companyId": companyId,
                    "expCategoryId": expenseId,
                    "formFlag": Number(object.FormFlags),
                    "userDefinedFields": object.UserDefineFields,
                    "question": object.Question,
                    "transactiontype": 0,
                    "expenseStatus": object.ExpenseStatus ? object.ExpenseStatus.toLowerCase() : true,
                    "udaID": object.udaID,
                    "typeOfFields": object.typeOfFields
                }
                bulkarray.push(obj);
            }
            return null;
        })
        axios.post(URLs.companyUrl + "uploadBulkExpenseCategoryMap", bulkarray, {
            responseType: 'blob',
            headers: {
                "Authorization": 'Bearer ' + this.props.token.access_token
            }
        }).then(async (response) => {
            let responseData = await this.handleResponseOfSaveBulk(response.data)
            if (!responseData) {
                return
            }
            if (responseData.serviceStatus.statusCode === 200) {
                this.setState({ fileList: [], selectedRowKeys: [], jsonData: false, successmsg: true, messageContent: responseData.serviceStatus.message })
                notification.success({
                    message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Success </span>{responseData.serviceStatus.message}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#dff0d8',
                        color: "green",
                        border: "1px solid green",
                    }
                });
                this.props.getAllExpCategoryMap(true)
            }
            else if (responseData.serviceStatus.statusCode === 210) notification.error({
                message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{responseData.serviceStatus.message}</span>,
                duration: 3,
                style: {
                    backgroundColor: '#fff1f0',
                    border: "1px solid #ffa39e",
                }
            });
            else notification.error({
                message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{errorMessages.error_userProfile}</span>,
                duration: 3,
                style: {
                    backgroundColor: '#fff1f0',
                    border: "1px solid #ffa39e",
                }
            });
        }).catch(error => {
            if (error.response !== undefined && error.response.data !== null && error.response.data !== undefined && error.response.data.serviceStatus !== undefined) {
                notification.error({
                    message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{error.response.data.serviceStatus.message}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }
            else notification.error({
                message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{errorMessages.generic_error}</span>,
                duration: 3,
                style: {
                    backgroundColor: '#fff1f0',
                    border: "1px solid #ffa39e",
                }
            });
        })

    }
    updateStates = (requiredArray) => {
        this.setState({ uploadFlag: false, visible: false, jsonData: true, data: requiredArray });
    }

    /**
     * onclick of upload ,uploads the template excell
     */

    handleUpload = (event) => {
        this.setState({ uploadFlag: true, visibleTable: true })
        loaderFocus(".loader-color .ant-spin-text");  
        let current = this;
        const { fileList } = this.state;
        var file = fileList[0];
        var reader = new FileReader();
        let resultarray;
        let keyValue = 1
        let requiredArray = [];
        let requiredCategory = [];
        reader.onload = function (event) {
            var data = event.target.result;
            if (!rABS) data = new Uint8Array(data);
            var newData = XLSX.read(data, { type: rABS ? 'binary' : 'array' });
            newData.SheetNames.forEach(function (sheetName) {
                if (current.props.selectedCompanyName.toLowerCase() !== sheetName.toLowerCase()) {
                    current.setState({ jsonData: false })
                    notification.error({
                        message: <span  role='alert' aria-live="assertive" aria-atomic="true" ><span className="visually-hidden">Error </span>You've selected incorrent Excel sheet</span>,
                        duration: 3,
                        style: {
                            backgroundColor: '#fff1f0',
                            border: "1px solid #ffa39e",
                        }
                    });
                } else current.setState({ jsonData: true })
                resultarray = XLSX.utils.sheet_to_json(newData.Sheets[sheetName], { raw: false, header: 1 });
                for (let i = 1; i < resultarray.length; ++i) {
                    var flag = 0
                    requiredCategory.push(resultarray[i][7])
                    if (resultarray[i][4] !== 'null' && resultarray[i][4] !== null && resultarray[i][4] !== "NA" && resultarray[i][4] !== undefined) {
                        flag = resultarray[i][4].substring(4)
                    } else flag = 0
                    if (resultarray[i][1] || resultarray[i][2] || resultarray[i][3] || resultarray[i][5] || resultarray[i][8]) {
                        let obj = {
                            'key': keyValue,
                            'CompanyName': resultarray[i][0] || sheetName,
                            'ExpenseWizardCategory': resultarray[i][1],
                            'UserDefineFields': resultarray[i][2],
                            'FormFlags': flag,
                            'Question': resultarray[i][3],
                            'ExpenseStatus': (resultarray[i][5].toUpperCase() === 'FALSE') ? 'FALSE'
                                : (resultarray[i][5].toUpperCase() === 'TRUE' ? 'TRUE' : 'null'),
                            'udaID': resultarray[i][6],
                            'typeOfFields': resultarray[i][8],
                        }
                        keyValue++
                        requiredArray.push(obj);
                    }
                }
            });
            initialData = requiredArray;
            current.setState({
                uploadFlag: false,
                visible: false,
                data: requiredArray,
                expWizardCategory: requiredCategory
            });
        };
        reader.onerror = console.log
        if (rABS) reader.readAsBinaryString(file);
        else reader.readAsArrayBuffer(file);
    }
    /**
     * onclick of cancel , won't upload the file
     */
    handleCancel = () => {
        this.setState({ visible: false, visibleTable: false });
    }
    /**
     * onclick of cancel ,bulk data (user profile creation) will be reverted 
     * if data has been edited all that will be reverted on first click(it works as backbutton in general)
     */
    handlebulkCancel = (e) => {
        e.preventDefault();
        if (JSON.stringify(initialData.length) !== JSON.stringify(this.state.data.length)) {
            this.setState({ data: initialData, selectedRowKeys: [], successmsg: false });
        }
        else {
            var noModify = true;
            if (initialData.length === this.state.data.length) {
                for (var i = 0; i < initialData.length; i++) {
                    if (JSON.stringify(initialData[i].CompanyName) !== JSON.stringify(this.state.data[i].CompanyName)) {
                        noModify = false;
                        this.setState({ data: initialData, selectedRowKeys: [], successmsg: false });
                    }
                    else if (JSON.stringify(initialData[i].ExpenseWizardCategory) !== JSON.stringify(this.state.data[i].ExpenseWizardCategory)) {
                        noModify = false;
                        this.setState({ data: initialData, selectedRowKeys: [], successmsg: false });
                    }
                    else if (JSON.stringify(initialData[i].FormFlags) !== JSON.stringify(this.state.data[i].FormFlags)) {
                        noModify = false;
                        this.setState({ data: initialData, selectedRowKeys: [], successmsg: false });
                    }
                    else if (JSON.stringify(initialData[i].UserDefineFields) !== JSON.stringify(this.state.data[i].UserDefineFields)) {
                        noModify = false;
                        this.setState({ data: initialData, selectedRowKeys: [], successmsg: false });
                    }
                    else if (JSON.stringify(initialData[i].Question) !== JSON.stringify(this.state.data[i].Question)) {
                        noModify = false;
                        this.setState({ data: initialData, selectedRowKeys: [], successmsg: false });
                    }
                    else if (JSON.stringify(initialData[i].ExpenseStatus) !== JSON.stringify(this.state.data[i].ExpenseStatus)) {
                        noModify = false;
                        this.setState({ data: initialData, selectedRowKeys: [], successmsg: false });
                    }
                }
            }
            if (noModify) {
                this.setState({ fileList: [], selectedRowKeys: [], jsonData: false, successmsg: false, visibleTable: false })
            }
        }
    }
    /**
     * onclick of upload need to display a upload modal
     */
    modalPopUp = () => {
        this.setState({ visible: true });
    }
    /**************Click on edit and then select the boxes*****************/
    /*     edit = (selectedRowKeys) => {
            selectedRowKeys.map((key) => {
                const newData = [...this.state.data];
     
                const target = newData.filter(item => key === item.key)[0];
                if (target) {
                    target.editable = true;
                    this.setState({ data: newData });
                }
            })
        } */
    onSelectChange = (selectedRowKeys) => {
        this.setState({ selectedRowKeys });
        /*  Edit click and then selected checkbox ****/
        /*{ this.state.editiconColor === "#112A70" ? this.edit(selectedRowKeys) : null }*/
        /*  delete click and then selected checkbox ****/
        /* { this.state.deleteiconColor === "#112A70" ? this.delete(selectedRowKeys) : null } */
    }


    render() {
        const { selectedRowKeys } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        const columns = [{
            title: 'Company Name',
            width: "15%",
            dataIndex: 'CompanyName',
            render: (text, record) => {
                const editable = record.editable;
                return (
                    <span className='input-text' style={{ paddingLeft: "2%", width: "inherit" }}>
                        {editable === undefined ? (<span>{text}</span>) : null}
                        {editable ? (<span> {this.state[record.key] === undefined ? text : this.state[record.key]}</span >) : null}
                    </span>)
            }
        }, {
            title: 'Expense Wizard Category',
            width: "15%",
            dataIndex: 'ExpenseWizardCategory',
            render: (text, record) => this.renderCategoryColumns(text, record, 'ExpenseWizardCategory'),
        }, {
            title: 'Form Flags',
            width: "15%",
            dataIndex: 'FormFlags',
            render: (text, record) => this.renderFormColumn(text, record, 'FormFlags'),
        }, {
            title: 'User Defined Fields',
            width: "20%",
            dataIndex: 'UserDefineFields',
            render: (text, record) => this.renderColumns(text, record, 'UserDefineFields'),
        }, {
            title: 'Question',
            width: "20%",
            dataIndex: 'Question',
            render: (text, record) => this.renderColumns(text, record, 'Question'),
        }, {
            title: 'Expense Status',
            width: "15%",
            dataIndex: 'ExpenseStatus',
            render: (text, record) => this.renderExpenseStatusColumn(text, record, 'ExpenseStatus'),
        }];

        const uploadprops = {
            name: 'userTemplate',
            onRemove: file => {
                this.setState(({ fileList }) => {
                    const index = fileList.indexOf(file);
                    const newFileList = fileList.slice();
                    newFileList.splice(index, 1);
                    return {
                        fileList: newFileList
                    };
                });
            },
            beforeUpload: file => {
                let fileSize = file.size / 1024 / 1024;
                const xcel = (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
                if (!xcel) {
                    notification.error({
                        message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{errorMessages.error_uploadExcel}</span>,
                        duration: 3,
                        style: {
                            backgroundColor: '#fff1f0',
                            border: "1px solid #ffa39e",
                        }
                    });
                    return;
                }
                if (fileSize > 5) {
                    notification.warning({
                        message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{errorMessages.error_fileSize}</span>,
                        duration: 3,
                        style: {
                            backgroundColor: '#fffbe6',
                            border: "1px solid #ffe58f",
                        }
                    });
                    return;
                }
                this.setState(() => ({ fileList: [file] }));
                return false;
            },
            fileList: this.state.fileList
        };
        if (this.state.jsonData === false) {
            return (
                <div className="row">
                    {/*  <div className="row">
                        <div className="col-sm-10 col-sm-offset-1">
                            {this.state.successmsg === true ? <div className="alert alert-success createuserSuccess"><span style={{ marginLeft: "43%" }}>{this.state.messageContent}</span></div> : null}
                        </div>
                    </div> */}
                    <div className="col-md-6">
                        <button type="button" className="button-createtrip-ghost" onClick={this.modalPopUp} ><b>{this.props.app_labels.uploadExcel}</b></button>
                    </div>
                    <div className="col-md-6">
                        <button type="button" className="button-createtrip-ghost" onClick={(e) => {
                            axios.get(URLs.companyUrl + "downloadTemplateExpense/" + this.props.selectedCompanyId, {
                                responseType: 'blob',
                                headers: {
                                    "Authorization": 'Bearer ' + this.props.token.access_token
                                }
                            }).then(response => {
                                if (navigator.msSaveBlob) {                // IE block
                                    var blobObject = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                                    window.navigator.msSaveOrOpenBlob(blobObject, "Template.xlsx");
                                }
                                else { //non IE block
                                    var a = document.createElement('a');
                                    a.href = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })); // xhr.response is a blob
                                    a.download = "Template"; // Set the file name.
                                    a.style.display = 'none';
                                    document.body.appendChild(a);
                                    a.click();
                                    document.body.removeChild(a);
                                    setTimeout(function () {
                                        // For Firefox it is necessary to delay revoking the ObjectURL
                                        window.URL.revokeObjectURL(a.href);
                                    }, 100)
                                }
                            }).catch(error => {
                                if (error.response !== undefined && error.response.data !== null && error.response.data !== undefined && error.response.data.serviceStatus !== undefined) {
                                    notification.error({
                                        message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{error.response.data.serviceStatus.message}</span>,
                                        duration: 3,
                                        style: {
                                            backgroundColor: '#fff1f0',
                                            border: "1px solid #ffa39e",
                                        }
                                    });
                                }
                                else notification.error({
                                    message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{errorMessages.generic_error}</span>,
                                    duration: 3,
                                    style: {
                                        backgroundColor: '#fff1f0',
                                        border: "1px solid #ffa39e",
                                    }
                                });
                            })
                        }}><b>{this.props.app_labels.downloadTemplate}</b></button><br /><br />
                    </div>
                    {this.result}
                    <Modal
                        visible={this.state.visible}
                        closable={false}
                        destroyOnClose={true}
                        onCancel={this.handleCancel}
                        footer={null}
                        className='modal_container' >
                        <MediaQuery minDeviceWidth={768}>
                            <div className='row row_center'>
                                <div className='col-md-6 col-md-offset-3 col-sm-10 col-sm-offset-1 col-xs-8 col-xs-offset-2'>
                                    <Dragger {...uploadprops} showUploadList={false} style={{ width: "100%", height: "217px" }} >
                                        {/* <p className="ant-upload-drag-icon">
                                            <Icon type="safety-certificate" style={{ fontSize: "50px", color: "grey" }} />
                                        </p> */}
                                        <p className="ant-upload-text horizontalCentering" style={{ color: "grey" }} ><b>{this.props.app_labels.dragnDropFile}</b></p>
                                        {/* <p className="ant-upload-hint">Support for a single or bulk upload. Strictly prohibit from uploading company data or other band files</p> */}
                                    </Dragger>
                                </div>
                            </div>
                        </MediaQuery>
                        {/*  <Dragger {...uploadprops} showUploadList={false}>
                            <p className="ant-upload-text bulkupload" style={{ color: "grey" }} ><b>DRAG AND DROP EXCEL FILE</b></p>
                        </Dragger */}
                        <div className='row upload-class row_center'>
                            <div className='col-sm-8 col-sm-offset-2 col-xs-8 col-xs-offset-2 col_center'>
                                <Upload {...uploadprops} >
                                    <Button style={{ width: "100%", height: "40px" }} className="horizontalCentering" >
                                        <Icon type="cloud-upload-o" style={{ fontSize: 25, color: "#112A70" }} /><span className="upload-text">{this.props.app_labels.clickToBrowse}</span>
                                    </Button>
                                </Upload>
                            </div>
                        </div>
                        {this.state.uploadFlag ? <div className="dark-spinner loader-color"><Spin tip="Uploading..." /></div> : null}
                        <br />
                        <div className='row'>
                            <div className='col-lg-1 col-lg-offset-3 col-md-2 col-md-offset-3 col-sm-6 col-sm-offset-0 col-xs-6 col-xs-offset-0'>
                                <Button onClick={this.handleCancel} className="horizontalCentering upload-buttons button-wrapclass1" type="primary" ghost><b>{this.props.app_labels.cancelCapital}</b></Button>
                            </div>
                            <div className='col-lg-2 col-lg-offset-3 col-md-2 col-md-offset-2 col-sm-6 col-sm-offset-0 col-xs-6 col-xs-offset-0'>
                                <Button disabled={this.state.fileList.length === 0} onClick={(e) => this.handleUpload(e)} className="horizontalCentering upload-buttons-save button-wrapclass1" type="primary"><b>{this.props.app_labels.uploadCapital}</b></Button>
                            </div>
                        </div>
                    </Modal>
                </div>
            );
        }
        else {
            return (
                <Modal
                    visible={this.state.visibleTable}
                    closable={false}
                    maskClosable={false}
                    destroyOnClose={true}
                    onCancel={this.handleCancel}
                    footer={null}
                    className='modal_container table-modal' >
                    <div style={{ textAlign: "right", marginRight: "6%", marginBottom: 16 }} >
                        <span onClick={(e) => {
                            if (selectedRowKeys.length !== 0) {
                                if (this.state.editiconColor === "grey") {
                                    this.setState({ editiconColor: "#112A70", deleteiconColor: "grey" });
                                    selectedRowKeys.map((key) => {
                                        const newData = [...this.state.data];

                                        const target = newData.filter(item => key === item.key)[0];
                                        if (target) {
                                            target.editable = true;
                                            this.setState({ data: newData });
                                        }
                                        return null;
                                    })
                                }
                                else {
                                    this.setState({ editiconColor: "grey" });
                                    const newData = [...this.state.data];
                                    newData.map((item) => {
                                        delete item.editable;
                                        this.setState({ data: newData });
                                        this.cacheData = newData.map(item => ({ ...item }));
                                        return null;
                                    })
                                }
                            }
                        }} >
                            <i className="icon-Edit" style={{ fontSize: "20px", color: this.state.editiconColor }}></i>
                        </span>
                        <span className="left-space" onClick={(e) => {
                            this.handleCancel();
                            if (selectedRowKeys.length !== 0) {
                                var currentnew = this;
                                confirm({
                                    title: 'Do you want to delete selected rows?',
                                    onOk() {
                                        currentnew.setState({ editiconColor: "grey", deleteiconColor: "#112A70" })
                                        {
                                            const newData = [...currentnew.state.data];
                                            selectedRowKeys.map((key) => {

                                                const target = newData.filter(item => key === item.key)[0];
                                                if (target) {
                                                    const index = newData.indexOf(target);
                                                    //const newdata = newData.slice();
                                                    newData.splice(index, 1);
                                                    currentnew.setState({ data: newData });
                                                    currentnew.cacheData = newData.map(item => ({ ...item }));
                                                }
                                                return null;
                                            })
                                            currentnew.setState({ data: newData });
                                            currentnew.cacheData = newData.map(item => ({ ...item }));
                                        }
                                    }
                                    ,
                                    onCancel() { },
                                });
                            }
                        }}  >
                            <i className="icon-Delete" style={{ fontSize: "20px", color: this.state.deleteiconColor }}></i>
                        </span>
                    </div>
                    <Table style={{ marginRight: "2%" }}
                        rowSelection={rowSelection}
                        columns={columns}
                        scroll={{ x: 970, y: 300 }}
                        dataSource={this.state.data}
                        pagination={false}
                        className = "addBulkExp" />
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-sm-offset-0 col-md-12 col-md-offset-0" style={{ textAlign: 'right' }}>
                            <button style={{ marginTop: "3%" }}
                                className="focusbutton button-wrapclass buttonRightPadding button-createtrip-ghost"
                                onClick={this.handlebulkCancel}>{this.props.app_labels.cancelCapital}</button>
                            <button style={{ marginTop: "3%" }}
                                className="focusbutton button-createtrip button-wrapclass"
                                onClick={(e) => {
                                    this.setState({ editiconColor: "grey", deleteiconColor: "grey" })
                                    {
                                        const newData = [...this.state.data];
                                        newData.map((item) => {
                                            delete item.editable;
                                            this.setState({ data: newData });
                                            this.cacheData = newData.map(item => ({ ...item }));
                                            return null;
                                        })
                                    }
                                    this.handleSave();
                                }} >{this.props.app_labels.saveCapital}</button>
                        </div>
                    </div>
                </Modal>
            );
        }
    }
}
function mapStateToProps(state, props) {
    return {
        token: state.authenticationReducer.token,
        userDetails: state.authenticationReducer.userDetails,
        app_labels: state.adduserReducer.app_labels
    };
}
export default connect(mapStateToProps)(AddBulkExpCat);