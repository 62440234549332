import initialState from './initialState';

const getAllDetailReducer = (state = initialState, action) => {
	
    switch (action.type) {

    	  case 'ALL_DETAILS':
    	
			return Object.assign({}, state, {allTripDetail : action.data});

    	default:
            return state;
    }
};

export default getAllDetailReducer;