import React, { Component } from 'react'
import { Layout } from 'antd'
import { connect } from 'react-redux';
import UserActionHeaderComponent from '../useractionheader'
import Search from './Search'
import Body from './Body'
import { resetTransactionDetails, fetchTransactionDetails, updateTransactionDetails, downloadTripMedia } from '../../actions/csrActions'
const Footer = Layout.Footer

class CSR extends Component {
  componentDidMount() {
    this.props.resetTransactionDetails();
    if (!this.props.userDetails.roles.includes('CSR')) {
      window.history.back();
    }
  }
  render() {
    return (
      <div >
        <Layout>
          <div><UserActionHeaderComponent roles={"CSR"} /></div>
          <Layout style={{ boxShadow: " 0px 0px 5px 5px #CCCCCC", backgroundColor: 'white' }}>
            <Search 
              fetchTransactionDetails={this.props.fetchTransactionDetails} 
              resetTransactionDetails={this.props.resetTransactionDetails} 
              visiblity={this.props.csrTransactionData ? true : false}
            />
            <Body
              data={this.props.csrTransactionData}
              isFetching={this.props.csrTransactionIsFetching}
              error={this.props.csrTransactionError}
              isUpdating={this.props.csrTransactionDetailsIsUpdating}
              updateError={this.props.csrTransactionDetailsUpdateError}
              updateSuccess={this.props.csrTransactionDetailsUpdateSuccess}
              updateTransactionDetails={this.props.updateTransactionDetails}
              downloadFile={this.props.downloadTripMedia}
            />
          </Layout>
          <Footer id='footer'></Footer>
        </Layout>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    csrTransactionIsFetching: state.csrReducer.csrTransactionIsFetching,
    csrTransactionError: state.csrReducer.csrTransactionError,
    csrTransactionData: state.csrReducer.csrTransactionData,
    csrTransactionDetailsIsUpdating: state.csrReducer.csrTransactionDetailsIsUpdating,
    csrTransactionDetailsUpdateError: state.csrReducer.csrTransactionDetailsUpdateError,
    csrTransactionDetailsUpdateSuccess: state.csrReducer.csrTransactionDetailsUpdateSuccess,
    userDetails: state.authenticationReducer.userDetails,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    resetTransactionDetails: () => dispatch(resetTransactionDetails()),
    fetchTransactionDetails: (data) => {
      dispatch(fetchTransactionDetails(data))
    },
    updateTransactionDetails: (data) => {
      dispatch(updateTransactionDetails(data))
    },
    downloadTripMedia: (...data) => {
      dispatch(downloadTripMedia(...data))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CSR);
