import React, { Component } from 'react'
import { Radio, Select } from 'antd';
import { isNull } from 'underscore';
const Option = Select.Option;
const { app_labels } = require('../../../data/english_label');

export default class CustomizeInput extends Component {
	constructor(props) {
		super(props);
		this.state = {
			lable: null
		}
	}

	componentDidMount() {
		this.props.field === 'mileageRate' && this.setState({ lable: this.props.app_labels.mileageRate })
		this.props.field === 'cardLimit' && this.setState({ lable: this.props.app_labels.cardLimit })
		this.props.field === 'deactivateRule' && this.setState({ lable: this.props.app_labels.deactivateRule })
		this.props.field === 'expirationRule' && this.setState({ lable: this.props.app_labels.expirationRule })
		this.props.field === 'singleCardApproval' && this.setState({ lable: this.props.app_labels.singleCardApproval })
		this.props.field === 'bulkCardApproval' && this.setState({ lable: this.props.app_labels.bulkCardApproval })
		this.props.field === 'requestCardFeature' && this.setState({ lable: this.props.app_labels.requestCardFetaure })
		this.props.field === 'approvalRule' && this.setState({ lable: this.props.app_labels.approvalThresholds })
		this.props.field === 'creditLimitRule' && this.setState({ lable: this.props.app_labels.thresholdAmount })
		this.props.field === 'creditValidityRule' && this.setState({ lable: this.props.app_labels.thresholdDays })
	}

	handleChange(e) {
		this.props.field === 'mileageRate' && this.props.onChangeMileageLimit(e)
		this.props.field === 'cardLimit' && this.props.onChangeCardLimit(e)
		this.props.field === 'deactivateRule' && this.props.onChangeDeactivation(e)
		this.props.field === 'expirationRule' && this.props.onChangeExpire(e)
		this.props.field === 'bulkCardApproval' && this.props.onChangeBulkCardApproval(e)
		this.props.field === 'singleCardApproval' && this.props.onChangeSingleCardApproval(e)
		this.props.field === 'requestCardFeature' && this.props.onChangeRequestCardFeature(e)
		this.props.field === 'approvalRule' && this.props.onChangeApprovalRule(e)
		this.props.field === 'creditLimitRule' && this.props.onChangeCreditLimitRule(e)
		this.props.field === 'creditValidityRule' && this.props.onChangeCreditValidityRule(e)
	}

	componentDidUpdate(prevProps){
		const icon = document.querySelectorAll('.anticon.anticon-down.ant-select-arrow-icon');
        if (icon) {
            icon.forEach(el => {
                el.setAttribute('role', 'img')
                el.setAttribute('aria-hidden', 'true')
            })
        }
        const dropDown = document.querySelectorAll('.appr-rule .ant-select-selection.ant-select-selection--single');
        if (dropDown[0]) {
            dropDown.forEach(div => {
                
                div.addEventListener('keyup', function () {
                    // console.log("Event on click fired");
    
                    let optionDiv = document.querySelectorAll('.ant-select-dropdown-menu-item.ant-select-dropdown-menu-item-active');
                    // console.log("Div:::", optionDiv);
                    if (optionDiv[0]) {
                        let activeDescendant = optionDiv[0].getAttribute('id')
                        //  console.log("active descendant::", activeDescendant);   
                        div.setAttribute('aria-activedescendant', activeDescendant);
                    }
                });
            })

        }

		// const labelDiv = document.querySelectorAll('.ant-radio-wrapper');
        // // console.log("Div::", labelDiv);
		// if (labelDiv) {
        //     labelDiv.forEach(el => {
        //         // el.outerHTML = el.outerHTML.replace(/label/g, 'div');
		// 		el.setAttribute('role', 'presentation');
        //     })
        // };
		
		if(prevProps.app_labels !== this.props.app_labels){
			this.props.field === 'mileageRate' && this.setState({ lable: this.props.app_labels.mileageRate })
			this.props.field === 'cardLimit' && this.setState({ lable: this.props.app_labels.cardLimit })
			this.props.field === 'deactivateRule' && this.setState({ lable: this.props.app_labels.deactivateRule })
			this.props.field === 'expirationRule' && this.setState({ lable: this.props.app_labels.expirationRule })
			this.props.field === 'singleCardApproval' && this.setState({ lable: this.props.app_labels.singleCardApproval })
			this.props.field === 'bulkCardApproval' && this.setState({ lable: this.props.app_labels.bulkCardApproval })
			this.props.field === 'requestCardFeature' && this.setState({ lable: this.props.app_labels.requestCardFetaure })
			this.props.field === 'approvalRule' && this.setState({ lable: this.props.app_labels.approvalThresholds })
			this.props.field === 'creditLimitRule' && this.setState({ lable: this.props.app_labels.thresholdAmount })
			this.props.field === 'creditValidityRule' && this.setState({ lable: this.props.app_labels.thresholdDays })
		}
	}

	render() {
		return (
			<div className="col-sm-3 col-sm-offset-1 cardsetrule1" aria-label={(this.props.field === 'bulkCardApproval' ||
			this.props.field === 'singleCardApproval' ||
			this.props.field === 'requestCardFeature')?this.state.lable: null}
				role={(this.props.field === 'bulkCardApproval' ||
				this.props.field === 'singleCardApproval' ||
				this.props.field === 'requestCardFeature')? "radiogroup": null} 
			>
				<div role={(this.props.field === 'bulkCardApproval' ||
                    this.props.field === 'singleCardApproval' ||
                    this.props.field === 'requestCardFeature') ? "heading" : null} 
					aria-level={(this.props.field === 'bulkCardApproval' ||
                    this.props.field === 'singleCardApproval' ||
                    this.props.field === 'requestCardFeature') ? "3" : null} 
					id={this.state.lable && this.state.lable.replace(' ', '-').toLowerCase()}>{this.state.lable}</div><br />
				{(this.props.field === 'bulkCardApproval' ||
					this.props.field === 'singleCardApproval' ||
					this.props.field === 'requestCardFeature') ?
					<Radio.Group  name={`${this.state.lable}-radio-grp`} disabled={this.props.role.toLowerCase() === 'customer support'? true: false} onChange={e => { this.handleChange(e) }} value={this.props.value}>
						<Radio aria-label={this.props.value === 'enable'? this.state.lable + ' enable': null} value={'enable'}> {this.props.app_labels.enable} </Radio>
						<Radio aria-label={this.props.value === 'disable'?this.state.lable + ' disable': null} value={'disable'}> {this.props.app_labels.disable} </Radio>
					</Radio.Group>
					: this.props.field === 'approvalRule' ?
						<Select
							aria-labelledby={this.state.lable && this.state.lable.replace(' ', '-').toLowerCase()}
							onChange={e => this.handleChange(e)}
							className="appr-rule"
							style={{ width: '75%', borderBottom: '1px solid #666666' }}
							value={this.props.value}>
							{/* <Option value={null} disabled hidden>{this.props.app_labels.selectApprovalThresholds}</Option> */}
							<Option value={1} id="1">{this.props.app_labels.creditLimit}</Option>
							<Option value={2} id="2">{this.props.app_labels.cardValidityPeriod}</Option>
							<Option value={3} id="3">{this.props.app_labels.creditLimitAndValidityPeriod}</Option>
							<Option value={4} id="4">{this.props.app_labels.creditLimitOrValidityPeriod}</Option>
						</Select>
						: <input
							aria-labelledby={this.state.lable && this.state.lable.replace(' ', '-').toLowerCase()}
							className="col-sm-1"
							aria-describedby={this.state.lable && this.state.lable.replace(' ', '-').toLowerCase() +"_th"}
							type={this.props.type ? this.props.type : "number"}
							min={this.props.min ? this.props.min : "1"}
							step={this.props.step ? this.props.step : "1"}
							value={this.props.value}
							onChange={e => this.handleChange(e)}
							disabled={this.props.role.toLowerCase() === 'customer support'? true: false} />}
				&nbsp;<span className="col-sm-8" id={this.state.lable && this.state.lable.replace(' ', '-').toLowerCase() +"_th"} style={{ fontSize: "14px" }}>{this.props.lastText}</span>
			</div>
		)
	}
}