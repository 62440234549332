import React from 'react'
import { Alert } from 'antd'

export default function (props) {
  return (
    <div style={{
      width: '50%',
      position: 'relative',
      margin: 'auto',
      marginTop: '50px'
    }}>
      <Alert
        description={props.message}
        type="error"
      />
    </div>
  )
}