import React from 'react';
import { connect } from 'react-redux';
import { Spin, Table, notification, Modal, Select, Tooltip } from 'antd';
import { loaderFocus } from '../../../utils/accessability';
import axios from 'axios';
import '../../../styling/expenseCat.css';
import $ from 'jquery';
import AddNewExpCat from './addNewExpCat';
import AddBulkExpCat from './addBulkExpCat';
const { URLs, errorMessages } = require('../../../data/constants.js');
const confirm = Modal.confirm;
const Option = Select.Option;
var catfirstValue = null;
var transactionTypeVar = null;

const EditableCell = ({ editable, value, onChange }) => (
    <>{editable ? 
        <input className="editabletextbox" value={value} onChange={e => onChange(e.target.value)} />
        : <span className="input-text" >{value}</span> }
    </>
);

const EditableCellExpCat = ({ editable, value, onChange, expenseCat, filteredCategory }) => (
    <div> 
        {expenseCat.map(item => {
            if (value.toUpperCase() === item.cadreCategory.toUpperCase()) {
                catfirstValue = item.cadreCategory;
            }
            return null;
        })}
        {editable ?
            <Select className="columnrenderclass" defaultValue={catfirstValue} onChange={value => onChange(value)} >
                {filteredCategory.map(item =>
                    <Option value={item.expCategoryId}>
                        <Tooltip placement="topLeft" title={item.cadreCategory}>
                            {item.cadreCategory}
                        </Tooltip>
                    </Option>)
                }
            </Select> : <span style={{ textAlign: "center" }} className="input-text" >{value}</span>
        }
    </div>
);

/* const EditableCellCrExpCat = ({ editable, value, onChange, crExpCat }) => (
    <div>
        {crExpCat.map(item => {
            if (value.toUpperCase() === item.expCategoryDesc.toUpperCase()) {
                crCatfirstValue = item.crExpCategoryId;
            }
        }
        )}
        {editable ?
            <Select className="columnrenderclass" defaultValue={crCatfirstValue} onChange={value => onChange(value)}>
                {crExpCat.map(item =>
                    <Option value={item.crExpCategoryId}>{item.expCategoryDesc}</Option>)}
            </Select> : <span style={{ textAlign: "center" }} className="input-text" >{value}</span>
        }
    </div>
); */

const EditableCellType = ({ editable, value, onChange, transactionType }) => (
    <div>
        {transactionType.map(item => {
            if (value === item.transactionTypeId) {
                transactionTypeVar = item.transactionType;
            }
            return null;
        })}
        {editable ?
            <Select defaultValue={value} className="columnrenderclass" onChange={value => onChange(value)}>
                {transactionType.map(item =>
                    <Option value={item.transactionTypeId}>{item.transactionType.toUpperCase()}</Option>)}
            </Select> : <span style={{ textAlign: "center" }} className="input-text">{transactionTypeVar}</span>
        }
    </div>
);

const EditableForm = ({ editable, value, onChange, app_labels }) => (
    <div>
        {editable ?
            <Select className="columnrenderclass" defaultValue={value == null ? 0 : value} onChange={value => onChange(value)}>
                <Option value={1}>{app_labels.form1}</Option>
                <Option value={2}>{app_labels.form2}</Option>
                <Option value={3}>{app_labels.form3}</Option>
                <Option value={4}>{app_labels.form4}</Option>
                <Option value={5}>{app_labels.form5}</Option>
                <Option value={0}>{app_labels.na}</Option>
            </Select> : <span style={{ textAlign: "center" }} className="input-text">{value !== null && value !== undefined ? "Form" + value : 'NA'}</span>
        }
    </div>
);

const EditableApplicable = ({ required, editable, value, onChange }) => (
    <div>
        {editable && !required ?
            <Select className="columnrenderclass" defaultValue={value} onChange={value => onChange(value)}>
                <Option value='Yes'>Yes</Option>
                <Option value='No'>No</Option>
            </Select> : <span style={{ textAlign: "center" }} className="input-text" >{value}</span>
        }
    </div>
);
class ExpenseCategoryComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            crExpCategory: '',
            allExpCat: [],
            addNewExpCat: false,
            fetchdata: false,
            fetchAllData: false,
            messages: false,
            editiconColor: 'grey',
            deleteiconColor: "grey",
            selectedRowKeys: [],
            companyName: [],
            selectedCompanyId: null,
            selectedCompanyName: null,
            showComponent: false
        }
        this.cacheData = this.state.allExpCat.map(item => ({ ...item }));
    }

    /**
     * Render the required column
     */
    renderColumns(text, record, column) {
        return  <EditableCell
                editable={record.editable}
                value={text}
                onChange={value => this.handleChange(value, record.key, column)} />
    }

    /**
     * Render the expense Category column
     */
    renderExpCatColumns(text, record, column) {
        let filteredCategory = []
        this.props.expenseCat.map(item => {
            if(parseInt(item.companyId) === parseInt(this.state.selectedCompanyId)) {
                filteredCategory.push(item)
            }
            return null;
        })
        return  <EditableCellExpCat
                editable={record.editable}
                value={record.companyExpenseCategoryDescription}
                onChange={value => this.handleChangeExpcat(value, record.key, column)}
                expenseCat={this.props.expenseCat}
                filteredCategory={filteredCategory} />
    }
    /*  renderCrExpCatColumns(text, record, column) {
         return (
             <EditableCellCrExpCat
                 editable={record.editable}
                 value={record.CRExpenseCategoryDescription}
                 onChange={value => this.handleChange(value, record.key, column)}
                 crExpCat={this.state.crExpCat}
             />
         )
     } */


     /**
      * Render the data in the dropdown
      */
    renderDropdowntransactionType(text, record, column) {
        return  <EditableCellType
                editable={record.editable}
                value={text}
                onChange={value => this.handleChange(value, record.key, column)}
                transactionType={this.props.transactionType} />
    }

    /**
     * Render the form 
     * @param {*} text 
     * @param {*} record 
     * @param {*} column 
     */
    renderFormColumn(text, record, column) {
        return  <EditableForm
                editable={record.editable}
                value={text}
                onChange={value => this.handleChange(value, record.key, column)}
                app_labels={this.props.app_labels} />
    }

    renderApplicableColumns(text, record, column) {
        return <EditableApplicable
                required={record.required}
                editable={record.editable}
                value={text}
                onChange={value => this.handleChange(value, record.key, column)} />
    }

    /**
     * Handle the required changes in the expense category column
     */
    handleChangeExpcat = (value, key, column) => {
        const newData = [...this.state.allExpCat];
        const target = newData.filter(item => key === item.key)[0];
        if (target) {
            target[column] = value;
            this.setState({ allExpCat: newData });
        }
        this.props.expenseCat.map(item => {
            if (value === item.expCategoryId) {
                this.setState({ [key]: item.crCategory })
            }
            return null;
        })
    }

    /**
     * handles the changes
     */
    handleChange = (value, key, column) => {
        const newData = [...this.state.allExpCat];
        const target = newData.filter(item => key === item.key)[0];
        if (target) {
            target[column] = value;
            this.setState({ allExpCat: newData });
        }
    }

    /**
     * Lifecycle method
     * will be called onMount of the component
     */
    componentDidMount() {
        this.setState({ companyName: this.props.activeCompanyName });
        // prepare the icons
        let element = $('.ant-select-arrow-icon')
        element.empty()
        element.removeClass().addClass('icon-Drop-down')
    }

    /**
     * Lifecycle method will be called on recieveing the props
     */
    componentWillReceiveProps(nextProps) {
        if (this.state.selectedCompanyId !== null && this.state.selectedCompanyId !== undefined) {
            this.getAllExpCategoryMap(this.state.selectedCompanyId);
        }
    }
    /**
     * fetching all the Expense categories
     */
    getAllExpCategoryMap = (value) => {
        axios.get(URLs.companyUrl + "viewExpenseCategoryMap/" + value, {
            headers: {
                "Authorization": 'Bearer ' + this.props.token.access_token
            }
        }).then(response => {
            this.setState({ fetchAllData: false })
            if (response.data.serviceStatus.statusCode === 200) {
                var newObj = [];
                var count = 1;
                response.data.data.map((item) => {
                    item.key = count;
                    count++;
                    newObj.push(item);
                    return null;
                })
                this.setState({ allExpCat: newObj })
            } else if (response.data.serviceStatus.statusCode === 210) {
                notification.error({
                    message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{response.data.serviceStatus.message}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            } else {
                notification.error({
                    message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{errorMessages.generic_error}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }
        }).catch(error => {
            this.setState({ fetchAllData: false })
            if (error.response !== undefined && error.response.data !== null && error.response.data !== undefined && error.response.data.serviceStatus !== undefined) {
                notification.error({
                    message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{error.response.data.serviceStatus.message}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            } else {
                notification.error({
                    message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{errorMessages.generic_error}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }
        })
    }
    /* 
        getCrExpCat = () => {
            axios.get(URLs.companyUrl + "getCRExpenseCategory", {
                headers: {
                    "Authorization": 'Bearer ' + this.props.token.access_token
                }
            }).then(response => {
                if (response.data.serviceStatus.statusCode === 200) {
                    this.setState({ crExpCat: response.data.data, fetchdata: true })
                }
                else if (response.data.serviceStatus.statusCode === 210) {
                    notification.error({
                        message: response.data.serviceStatus.message,
                        duration: 3,
                        style: {
                            backgroundColor: '#fff1f0',
                            border: "1px solid #ffa39e",
                        }
                    });
                }
                else {
                    notification.error({
                        message: "sorry! Plase try again",
                        duration: 3,
                        style: {
                            backgroundColor: '#fff1f0',
                            border: "1px solid #ffa39e",
                        }
                    });
                }
    
            }).catch(error => {
                notification.error({
                    message: error.response.data.serviceStatus.message,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            })
        } */

    /**
     * @param  {} selectedRowKeys
     * allows to delete the expense category
     */
    deleteExpenseCategoryMap = selectedRowKeys => {
        let obj = [];
        var current = this;
        selectedRowKeys.map((key) => {
            const target = this.state.allExpCat.filter(item => key === item.key)[0];
            if (target) {
                var obj1 = {
                    "id": target.id,
                }
            }
            obj.push(obj1);
            return null;
        })
        confirm({
            title: 'Do you want to delete selected rows?',
            onOk() {
                axios.post(URLs.companyUrl + "deleteExpenseCategoryMap", obj, {
                    headers: {
                        "Authorization": 'Bearer ' + current.props.token.access_token
                    }
                }).then(response => {
                    if (response.data.serviceStatus.statusCode === 200) {
                        current.getAllExpCategoryMap(current.state.selectedCompanyId);
                        current.setState({ selectedRowKeys: [] });
                        notification.success({
                            message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Success </span>{response.data.serviceStatus.message}</span>,
                            duration: 3,
                            style: {
                                backgroundColor: '#dff0d8',
                                color: "green",
                                border: "1px solid green",
                            }
                        });
                    } else if (response.data.serviceStatus.statusCode === 210) {
                        notification.error({
                            message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{response.data.serviceStatus.message}</span>,
                            duration: 3,
                            style: {
                                backgroundColor: '#fff1f0',
                                border: "1px solid #ffa39e",
                            }
                        });
                    } else {
                        notification.error({
                            message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{errorMessages.generic_error}</span>,
                            duration: 3,
                            style: {
                                backgroundColor: '#fff1f0',
                                border: "1px solid #ffa39e",
                            }
                        });
                    }
                }).catch(error => {
                    if (error.response !== undefined && error.response.data !== null && error.response.data !== undefined && error.response.data.serviceStatus !== undefined) {
                        notification.error({
                            message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{error.response.data.serviceStatus.message}</span>,
                            duration: 3,
                            style: {
                                backgroundColor: '#fff1f0',
                                border: "1px solid #ffa39e",
                            }
                        });
                    } else {
                        notification.error({
                            message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{errorMessages.generic_error}</span>,
                            duration: 3,
                            style: {
                                backgroundColor: '#fff1f0',
                                border: "1px solid #ffa39e",
                            }
                        });
                    }
                })
            },
            onCancel() { },
        });
    }
    /**
     * @param  {} selectedRowKeys
     * allows to update the dited expense category
     */
    updateExpCategory = selectedRowKeys => {
        let obj = [];
        var current = this;
        selectedRowKeys.map((key) => {
            const target = this.state.allExpCat.filter(item => key === item.key)[0];
            if (target) {
                var obj1 = {
                    "id": target.id,
                    "expCategoryId": target.expCategoryId,
                    "transactiontype": 0,
                    "userDefinedFields": target.userDefinedFields,
                    "typeOfField": target.typeOfField,
                    "formFlag": target.formFlag,
                    "question": target.question,
                    "applicable": target.applicable,
                    "companyId": target.companyId,
                }
            }
            obj.push(obj1);
            return null;
        })
        confirm({
            title: 'Do you want to Save the Edited rows?',
            onOk() {
                axios.post(URLs.companyUrl + "updateExpenseCategoryMap", obj, {
                    headers: {
                        "Authorization": 'Bearer ' + current.props.token.access_token
                    }
                }).then(response => {
                    if (response.data.serviceStatus.statusCode === 200) {
                        current.getAllExpCategoryMap(current.state.selectedCompanyId);
                        current.setState({ selectedRowKeys: [] });
                        notification.success({
                            message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Success </span>{response.data.serviceStatus.message}</span>,
                            duration: 3,
                            style: {
                                backgroundColor: '#dff0d8',
                                color: "green",
                                border: "1px solid green",
                            }
                        });
                    } else if (response.data.serviceStatus.statusCode === 210) {
                        notification.error({
                            message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{response.data.serviceStatus.message}</span>,
                            duration: 3,
                            style: {
                                backgroundColor: '#fff1f0',
                                border: "1px solid #ffa39e",
                            }
                        });
                    } else {
                        notification.error({
                            message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{errorMessages.generic_error}</span>,
                            duration: 3,
                            style: {
                                backgroundColor: '#fff1f0',
                                border: "1px solid #ffa39e",
                            }
                        });
                    }
                }).catch(error => {
                    if (error.response !== undefined && error.response.data !== null && error.response.data !== undefined && error.response.data.serviceStatus !== undefined) {
                        notification.error({
                            message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{error.response.data.serviceStatus.message}</span>,
                            duration: 3,
                            style: {
                                backgroundColor: '#fff1f0',
                                border: "1px solid #ffa39e",
                            }
                        });
                    } else {
                        notification.error({
                            message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{errorMessages.generic_error}</span>,
                            duration: 3,
                            style: {
                                backgroundColor: '#fff1f0',
                                border: "1px solid #ffa39e",
                            }
                        });
                    }
                })
            },
            onCancel() { },
        });
    }
    /**
   * @param  {} value
   * called on change of company
   */
    companyChange = value => {
        let pos = value.search(">")
        let selectedCompanyId = value.substring(0, pos)
        let selectedCompanyName = value.slice(pos+1)
        this.setState({ 
            selectedCompanyId, 
            selectedCompanyName, 
            fetchAllData: true,
            selectedRowKeys: []
        })
        loaderFocus(".loader-color .ant-spin-text");
        this.getAllExpCategoryMap(selectedCompanyId);
    }

    /**
     * @param  {} selectedRowKeys
     * selection of rows
     */
    onSelectChange = (selectedRowKeys) => {
        this.setState({ selectedRowKeys });
    }

    render() {
        const { selectedRowKeys } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        const columns = [{
            title: this.props.app_labels.expenseWizardCategory,
            dataIndex: 'expCategoryId',
            className: "column",
            key:"expCategoryId",
            align: "center",
            width: '15%',
            render: (text, record) => this.renderExpCatColumns(text, record, 'expCategoryId'),
        }, {
            title: this.props.app_labels.crExpenseCategory,
            dataIndex: 'CRExpenseCategoryDescription',
            className: "column",
            align: "center",
            width: '15%',
            render: (text, record) => {
                const editable = record.editable;
                return (
                    <span className='input-text'>
                        {editable === undefined ? (<span>{text}</span>) : null}
                        {editable ? (<span> {this.state[record.key] === undefined ? text : this.state[record.key]}</span >) : null}
                    </span>)
            }
        }, {
            title: this.props.app_labels.userDefineFields,
            dataIndex: 'userDefinedFields',
            className: 'column',
            width: '15%',
            render: (text, record) => this.renderColumns(text, record, 'userDefinedFields'),
        }, {
            title: this.props.app_labels.formFlags,
            align: "center",
            width: '10%',
            // className: "column",
            dataIndex: 'formFlag',
            render: (text, record) => this.renderFormColumn(text, record, 'formFlag'),
        }, {
            title: 'Questions',
            dataIndex: 'question',
            align: "center",
            key:"ques",
            className: 'column',
            width: '30%',
            render: (text, record) => this.renderColumns(text, record, 'question'),
        }, {
            title: 'Questions Apply',
            align: "center",
            width: '15%',
            // className: "column",
            dataIndex: 'applicable',
            render: (text, record) => this.renderApplicableColumns(text, record, 'applicable'),
        }];
        
        return (
            <div>{!this.state.showComponent ?
            <div className="expenseCategoryclass">
                {/* <div className="row admin_heading" >
                    <b>CADRE Expense Management</b><br /><hr style={{ color: "lightgrey", width: "100%" }} />
                </div> */}
                <br />
                <div className="row">
                    <div className="col-md-4 margibnLeftExpCat">
                        {this.props.app_labels.companyName}<br /><br />
                        <Select defaultValue={this.state.selectedCompanyName ? this.state.selectedCompanyName : null} 
                            onChange={this.companyChange} 
                            style={{ width: '100%' }} 
                            placeholder="Please Select Company">
                            {this.state.companyName.map(item =>
                                <Option value={`${item.companyId}>${item.companyName}`}>{item.companyName}</Option>
                            )}
                        </Select>
                    </div>
                    {this.state.selectedCompanyId && <div className="col-md-7">
                        <div className="expenseAddButton" >
                            <div className="row">
                                <div className="col-md-3 button-align">
                                    <button className="button-createtrip-ghost" onClick={() => this.setState({showComponent: true})}> ADD NEW</button>
                                </div>
                                <div className="col-md-8" style={{ textAlign: "center" }}>
                                    <AddBulkExpCat 
                                        app_labels = {this.props.app_labels}
                                        selectedCompanyId={this.state.selectedCompanyId}
                                        selectedCompanyName={this.state.selectedCompanyName}
                                        activeCompanyName={this.props.activeCompanyName} 
                                        expenseCat={this.props.expenseCat} 
                                        transactionType={this.props.transactionType}
                                        getAllExpCategoryMap={value => value === true && this.getAllExpCategoryMap(this.state.selectedCompanyId)} />
                                </div>
                            </div>
                        </div>
                    </div>}
                </div> <br />
                <div>
                    {this.state.selectedCompanyId !== null ?
                    <div>
                        <div className="row" style={{ textAlign: "right" }}>
                            <span >
                                <span style={{ paddingBottom: 16, marginRight: "2%" }}>
                                    <button className="pseudoButtonIcon" onClick={(e) => {
                                        if (selectedRowKeys.length !== 0) {
                                            if (this.state.editiconColor === "grey") {
                                                this.setState({ editiconColor: "#112A70", deleteiconColor: "grey" });
                                                selectedRowKeys.map((key) => {
                                                    const newData = [...this.state.allExpCat];
                                                    const target = newData.filter(item => key === item.key)[0];
                                                    if (target) {
                                                        target.editable = true;
                                                        this.setState({ allExpCat: newData });
                                                    }
                                                    return null;
                                                })
                                            }
                                            else {
                                                this.getAllExpCategoryMap(this.state.selectedCompanyId);
                                                this.setState({ editiconColor: "grey" });
                                                const newData = [...this.state.allExpCat];
                                                newData.map((item) => {
                                                    delete item.editable;
                                                    this.setState({ allExpCat: newData });
                                                    this.cacheData = newData.map(item => ({ ...item }));
                                                    return null;
                                                })
                                            }
                                        }
                                    }} >
                                        <i className="icon-Edit" style={{ fontSize: "20px", color: this.state.editiconColor }}></i>
                                    </button><span>
                                        <button className="pseudoButtonIcon" onClick={(e) => {
                                            if (selectedRowKeys.length !== 0) {
                                                this.setState({ editiconColor: "grey", deleteiconColor: "#112A70" })
                                                this.deleteExpenseCategoryMap(selectedRowKeys);
                                            }
                                        }}  >
                                            <i className="icon-Delete" style={{ fontSize: "20px", color: this.state.deleteiconColor }}></i>
                                        </button></span>
                                </span>
                            </span>
                        </div>

                        {this.state.fetchAllData ? <div className="dark-spinner loader-color"><Spin tip="Loading..." /></div> : null}
                        <div className="row" style={{ overflow: "scroll" }}>
                            <Table style={{ marginLeft: "2%", marginRight: "2%" }}
                                className='viewEditProfileCls'
                                rowSelection={rowSelection}
                                columns={columns}
                                scroll={{ x: 970, y: 300 }}
                                dataSource={this.state.allExpCat}
                                pagination={false}
                            />
                        </div>
                        <div className="row">
                            <div className="col-sm-2 col-sm-offset-9">
                                <button type="button" className="button-createtrip" onClick={(e) => {
                                    if (selectedRowKeys.length !== 0) {
                                        this.setState({ editiconColor: "grey", deleteiconColor: "grey" })
                                        this.updateExpCategory(selectedRowKeys);
                                    }
                                }}><b>{this.props.app_labels.saveCapital}</b></button>
                            </div>
                        </div>
                    </div>
                    : null}
                </div>
            </div> :
            <AddNewExpCat 
                app_labels = {this.props.app_labels}
                activeCompanyName={this.props.activeCompanyName} 
                selectedCompanyId={this.state.selectedCompanyId}
                selectedCompanyName={this.state.selectedCompanyName}
                expenseCat={this.props.expenseCat} 
                transactionType={this.props.transactionType}
                changeComponent={(showComponent, companyId, selectedCompanyName) => {
                    this.setState({ showComponent, selectedCompanyId: companyId, selectedCompanyName })
                    this.getAllExpCategoryMap(companyId);
                }} />
            }</div>
        )
    }

}
function mapStateToProps(state, props) {
    return {
        token: state.authenticationReducer.token,
        app_labels: state.adduserReducer.app_labels
    };
}
export default connect(mapStateToProps)(ExpenseCategoryComponent);