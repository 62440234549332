import React from 'react';
import '../../../styling/cardsettings.css';
import axios from 'axios';
import $ from 'jquery';
import { connect } from 'react-redux';
import { Spin, Icon, Table, Select, notification, Input, AutoComplete } from 'antd';
import { loaderFocus } from '../../../utils/accessability';
import CustomizeInput from './CustomizeInput';
import PolicyUpload from '.././uploadPolicy';
import Fuse from "fuse.js";
import { Tabs, Tab } from '@mui/material' ;

const Option = Select.Option;
const { TextArea } = Input;
const TabPane = Tabs.TabPane;
const { URLs } = require('../../../data/constants.js');
const { countries } = require('../../../data/airport.js');

// commented unused array variable
// const dataSource = [{
//         minAmount: 0,
//         maxAmount: 5000,
//         validity: 60,
//     },{
//         minAmount: 5000,
//         maxAmount: 50000,
//         validity: 2,
//     },{
//         minAmount: 50000,
//         maxAmount: 100000,
//         validity: 1,
//     }];

/**
* renders when settings tab is  clicked on admin screen
*/
class CardMileageSettings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cardExpire: 0,
            cardDeactivation: 0,
            companyAddress: null,
            cardLimit: 0,
            mileageRate: 0,
            retrieve: false,
            selectedCompanyId: null,
            companyName: [],
            uniqueCardId: null,
            applyAll: false,
            allCardDetails: [],
            companyId: null,
            uploadPolicyflag: false,
            uploadComapnyId: '',
            policyType: '2',
            companyFiles: null,
            country: null,
            state: [],
            city: null,
            streetAddress: null,
            selected_state: null,
            flat: null,
            zipCode: null,
            state_data: [],
            countryArray: countries,
            singleCardApproval: null,
            bulkCardApproval: null,
            requestCardFeature: null,
            approvalRule: null,
            creditLimitRule: null,
            creditValidityRule: null,
            focused: false,
            activeTab: this.props.roles === 'Card Company Admin'? '3' :'1',
            isClose: true,
            selectSearchItem : null,
        }
        this.initialData = null;

        this.options = {
            shouldSort: true,
            threshold: 0.0,
            maxPatternLength: 32,
            keys: ['country']
        };
        this.fuse = new Fuse(countries, this.options)

        this.options1 = {
            shouldSort: true,
            threshold: 0.0,
            maxPatternLength: 32,
            keys: ['states']
        };
    }

    /**
     * @param  {} {this.getCompanyLists(
     * lifecycle method
     */
    componentDidMount() {
        if (window.location.hostname.includes('elan-solutions.elaneasypay'))
            document.title = "Card Company Admin: Elan Easy Pay";
        else
            document.title = "Card Company Admin: US Bank Expense Wizard";

        if (this.props.roles === "Company Admin" || this.props.roles === "Card Company Admin") {
            this.setState({ companyName: this.props.activeCompanyName });
            this.setState({ companyId: this.props.companyDetails.companyId }, () => {
                this.getAllFiles();
                this.getCompanyAddress();
                this.companyChange(this.state.companyId)
            })
        } else {
            let list = this.props.activeCompanyName;
            list.sort((a, b) => {
              let companyname = a.companyName.toLowerCase().trim(),
              companyname2 = b.companyName.toLowerCase().trim();
              return companyname < companyname2 ? -1 : 1
            });
            this.setState({ companyName: list })
        }
        // prepare the icons
        $('.ant-select-arrow-icon').addClass('icon-Drop-down');
        setTimeout(() => {
            const dropDown = document.querySelectorAll('.ant-input.ant-select-search__field');
            const selectDiv = document.querySelectorAll('.ant-select-selection.ant-select-selection--single');
            if(dropDown[0] && selectDiv[0] ){
                dropDown[0].setAttribute('aria-labelledby', 'country');
                dropDown[1].setAttribute('aria-labelledby', 'state');
                dropDown[0].setAttribute('role', 'combobox');
                dropDown[1].setAttribute('role', 'combobox');
                dropDown[0].setAttribute('aria-autocomplete', 'list');
                dropDown[1].setAttribute('aria-autocomplete', 'list');
                dropDown[0].setAttribute('aria-haspopup', 'true');
                dropDown[1].setAttribute('aria-haspopup', 'true');
                dropDown[0].setAttribute('aria-expanded', 'false');
                dropDown[1].setAttribute('aria-expanded', 'false');
                dropDown[0].setAttribute('aria-controls', selectDiv[2].getAttribute('aria-controls'));
                dropDown[1].setAttribute('aria-controls', selectDiv[3].getAttribute('aria-controls'));
                
                selectDiv[2] && selectDiv[2].removeAttribute('role');
                selectDiv[2] && selectDiv[2].removeAttribute('aria-autocomplete');
                selectDiv[2] && selectDiv[2].removeAttribute('aria-haspopup');
                selectDiv[2] && selectDiv[2].removeAttribute('aria-expanded');
                selectDiv[2] && selectDiv[2].removeAttribute('aria-controls');
                
                selectDiv[3] && selectDiv[3].removeAttribute('aria-controls');
                selectDiv[3] && selectDiv[3].removeAttribute('aria-expanded');
                selectDiv[3] && selectDiv[3].removeAttribute('role');
                selectDiv[3] && selectDiv[3].removeAttribute('aria-autocomplete');
                selectDiv[3] && selectDiv[3].removeAttribute('aria-haspopup');
                selectDiv[2] && selectDiv[2].children[0].children[0].setAttribute('role', 'presentation');
                selectDiv[3] && selectDiv[3].children[0].children[0].setAttribute('role', 'presentation');
                
            }

        }, 500);
    }

    componentDidUpdate(){
        const icon = document.querySelectorAll('.icon-Drop-down');
        if (icon) {
            icon.forEach(el => {
                el.setAttribute('role', 'img')
                el.setAttribute('aria-hidden', 'true')
            })
        };
        let activeDescendant = null;
        const dropDown = document.querySelectorAll('.ant-input.ant-select-search__field');
        const selectDiv = document.querySelectorAll('.ant-select-selection.ant-select-selection--single');
        if(dropDown[0] && selectDiv[0] ){
            // console.log("Select div::", selectDiv[2].children[0].children )
            dropDown.forEach(div => {
                div.addEventListener('keyup', function(){
                    if(dropDown[0].getAttribute('aria-expanded') !== selectDiv[2].getAttribute('aria-expanded')){
                        dropDown[0].setAttribute('aria-expanded', selectDiv[2].getAttribute('aria-expanded') !== null && selectDiv[2].getAttribute('aria-expanded'));
                    }else{
                        selectDiv[2].removeAttribute('aria-expanded');
                    }
                    if(dropDown[1].getAttribute('aria-expanded') !== selectDiv[3].getAttribute('aria-expanded')){
                        dropDown[1].setAttribute('aria-expanded', selectDiv[3].getAttribute('aria-expanded') !== null && selectDiv[3].getAttribute('aria-expanded'));
                    
                    }else{
                        selectDiv[3].removeAttribute('aria-expanded');
                    }
                    let optionDiv = document.querySelectorAll('.ant-select-dropdown-menu-item.ant-select-dropdown-menu-item-active');
                    
                    if(optionDiv[0]){
                        activeDescendant = optionDiv[0].getAttribute('id')
                        //  console.log("active descendant::", activeDescendant);   
                        div.setAttribute('aria-activedescendant', activeDescendant);
                    }                               
                });
            })
            
        }
    }

    componentDidUpdate(){
        const icon = document.querySelectorAll('.icon-Drop-down');
        if (icon) {
            icon.forEach(el => {
                el.setAttribute('role', 'img')
                el.setAttribute('aria-hidden', 'true')
            })
        };
        let activeDescendant = null;
        const dropDown = document.querySelectorAll('.ant-input.ant-select-search__field');
        // console.log("Div:::", dropDown);
        if(dropDown[0] ){
            dropDown.forEach(div => {
                
                div.addEventListener('keyup', function(){
                    
                    let optionDiv = document.querySelectorAll('.ant-select-dropdown-menu-item.ant-select-dropdown-menu-item-active');
                    // console.log("Div:::", dropDown[0]);
                    if(optionDiv[0]){
                        activeDescendant = optionDiv[0].getAttribute('id')
                        //  console.log("active descendant::", activeDescendant);   
                        div.setAttribute('aria-activedescendant', activeDescendant);
                           
                    }                               
                });
            })
            
        }
    }

    /**
     * fetches card details for all the companies
     */
    getcardDetailsForAll = () => {
        this.setState({ retrieve: true });
        loaderFocus(".loader-color .ant-spin-text");
        axios.get(URLs.comUrl + "getCardSetting", {
            responseType: 'Json',
            headers: {
                "Authorization": 'Bearer ' + this.props.token.access_token
            }
        }).then(response => {
            if (response.data.serviceStatus.statusCode === 200) {
                this.setState({ retrieve: false });
                if (response.data.data !== undefined && response.data.data !== null) {
                    this.setState({
                        allCardDetails: JSON.parse(JSON.stringify(response.data.data)),
                        cardDeactivation: 0, cardExpire: 0, cardLimit: 0, mileageRate: 0
                    });
                } else this.setState({ retrieve: false });
            } else if (response.data.serviceStatus.statusCode === 210) {
                this.setState({ retrieve: false });
                notification.error({
                    message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{this.props.app_labels.couldNotcompleteReq}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            } else {
                this.setState({ retrieve: false });
                notification.error({
                    message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{this.props.app_labels.error_cardCreation}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }
        }).catch(error => {
            this.setState({ retrieve: false });
            if (error.response.data !== null && error.response !== undefined && error.response.data !== undefined && error.response.data.serviceStatus !== undefined) {
                notification.error({
                    message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{this.props.app_labels.couldNotcompleteReq}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            } else {
                notification.error({
                    message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{this.props.app_labels.couldNotcompleteReq}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }
        })
    }

    /* @param  {} value
    * getting cardsetting details for particular company
    */
    getCardDetails = value => {
        if (value !== 'ALL') {
            this.setState({ retrieve: true });
            loaderFocus(".loader-color .ant-spin-text");  
            axios.get(URLs.comUrl + "getCardSetting/" + value, {
                responseType: 'Json',
                headers: {
                    "Authorization": 'Bearer ' + this.props.token.access_token
                }
            }).then(response => {
                if (response.data.serviceStatus.statusCode === 200) {
                    this.setState({ retrieve: false });
                    if (response.data.data !== undefined && response.data.data !== null) {
                        this.setState({ uniqueCardId: parseInt(response.data.data.id), cardDeactivation: parseInt(response.data.data.cardDeactivation), cardExpire: parseInt(response.data.data.cardExpiration), cardLimit: parseInt(response.data.data.cardLimit), mileageRate: response.data.data.mileageRate });
                        this.initialData = JSON.parse(JSON.stringify(response.data.data));
                    } else {
                        this.setState({ retrieve: false });
                        this.setState({ uniqueCardId: null, cardDeactivation: 0, cardExpire: 0, cardLimit: 0, mileageRate: 0 });
                    }

                } else if (response.data.serviceStatus.statusCode === 210) {
                    this.setState({ retrieve: false });
                    notification.error({
                        message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{this.props.app_labels.couldNotcompleteReq}</span>,
                        duration: 3,
                        style: {
                            backgroundColor: '#fff1f0',
                            border: "1px solid #ffa39e",
                        }
                    });
                } else {
                    this.setState({ retrieve: false });
                    notification.error({
                        message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{this.props.app_labels.error_cardCreation}</span>,
                        duration: 3,
                        style: {
                            backgroundColor: '#fff1f0',
                            border: "1px solid #ffa39e",
                        }
                    });
                }
            }).catch(error => {
                this.setState({ retrieve: false });
                if (error.response.data !== null && error.response !== undefined && error.response.data !== undefined && error.response.data.serviceStatus !== undefined) {
                    notification.error({
                        message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{this.props.app_labels.couldNotcompleteReq}</span>,
                        duration: 3,
                        style: {
                            backgroundColor: '#fff1f0',
                            border: "1px solid #ffa39e",
                        }
                    });
                } else {
                    notification.error({
                        message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{this.props.app_labels.couldNotcompleteReq}</span>,
                        duration: 3,
                        style: {
                            backgroundColor: '#fff1f0',
                            border: "1px solid #ffa39e",
                        }
                    });
                }
            })
        }
    }

    /**
     * @param  {} value
     * called on change of company 
     */
    companyChange = value => {
        if (value === "ALL") {
            this.setState({ applyAll: true, selectedCompanyId: value, companyFiles: null,selectSearchItem:null })
            this.getcardDetailsForAll()
        } else {
            this.getCardDetails(value);
            this.setState({ allCardDetails: [], selectedCompanyId: value, applyAll: false,selectSearchItem:null}, () => {
                this.getAllFiles()
                this.getCompanyAddress()
            })
        }
    }

    /**
    * To close the upload file modal
    */
    refreshFileUploadStatus() {
        this.getAllFiles();
        this.setState({ uploadPolicyflag: false });
    }

    /**
     * @param  {} e
     * assigning Card Expiration data
     */
    onChangeExpire = e => this.setState({ cardExpire: e.target.value })

    /**
     * @param  {} e
     * assigning Card Deactivation data
     */
    onChangeDeactivation = e => this.setState({ cardDeactivation: e.target.value })

    /**
     * @param  {} e
     * assigning Card mileage Limit data
     */
    onChangeMileageLimit = e => this.setState({ mileageRate: e.target.value })

    /**
     * @param  {} e
     * assigning Card Limit data
     */
    onChangeCardLimit = (e) => this.setState({ cardLimit: e.target.value })

    /**
     * handels change on adress input value
     */
    onChangeAddress = (e) => this.setState({ companyAddress: e.target.value })

    /**
     * handels change on flat input value
     */
    onChangeFlat = (e) => this.setState({ flat: e.target.value })
    /**
     * handels change on city input value
     */
    onChangeCity = (e) => this.setState({ city: e.target.value })
    /**
     * handels change on street input value
     */
    onChangestreet = (e) => this.setState({ streetAddress: e.target.value })
    /**
     * handels change on zipcode input value
     */
    onChangezipcode = (e) => this.setState({ zipCode: e.target.value })

    /**
     * @param  {} e
     * assigning Bulk Card Approval data
     */
    onChangeBulkCardApproval = e => { this.setState({ bulkCardApproval: e.target.value }) }
    /**
     * @param  {} e
     * assigning Single card approval data
     */
    onChangeSingleCardApproval = e => this.setState({ singleCardApproval: e.target.value })
    /**
     * @param  {} e
     * assigning request card feature enable or disable data
     */
    onChangeRequestCardFeature = e => this.setState({ requestCardFeature: e.target.value })
    /**
     * @param  {} e
     * assigning approval rule data
     */
    onChangeApprovalRule = e => this.setState({ approvalRule: e })
    /**
     * @param  {} e
     * assigning credit limit rule data
     */
    onChangeCreditLimitRule = e => this.setState({ creditLimitRule: e.target.value })
    /**
     * @param  {} e
     * assigning credit validity rule data
     */
    onChangeCreditValidityRule = e => this.setState({ creditValidityRule: e.target.value })

    /**
     * To get all the states in dropdown according to selected country value
     */
    renderState(data) {
        let val = data.states ? data.states : data
        return <Option key={val} value={val} id={val}>{val}</Option>
    }

    /**
     * handels change on state input value
     */
    handlestateSearch = (value) => {
        this.setState({ selected_state: value })
        if (this.state.country) {
            let resultState
            let stateArray = []
            this.state.countryArray.forEach(country => {
                if (country.country === this.state.country) resultState = country.states
            })
            resultState.forEach(val => {
                let obj = { states: val }
                stateArray.push(obj)
            })
            let fuse1 = new Fuse(stateArray, this.options1)
            if (value) {
                setTimeout(() => {
                    let arrayOfResults = fuse1.search(value);
                    let results = arrayOfResults.slice(0);
                    this.setState({ state: results })
                }, 0)
            }
        }
    }
    /**
     * To get the list of country in dropdown
     */
    renderCountry(val) {
        return <Option key={val.country} aria-label={val.country} value={val.country} id={val.country}>{val.country}</Option>
    }

    /**
     * handels change on country input value
     */
    handlecountrySearch = (value) => {
        this.setState({ country: value })
        if (value) {
            setTimeout(() => {
                let arrayOfResults = this.fuse.search(value);
                let results = arrayOfResults.slice(0);
                this.setState({ countryArray: results })
            }, 0)
        }
    }

    /**
     * handels change on country input value
     */
    handlecountryChange = (value) => {
        this.state.countryArray.forEach(country => {
            if (country.country === value) {
                let state = country.states;
                this.setState({
                    country: value,
                    state: state,
                    selected_state: state[0]
                })
            }
        })
    }

    /**
     * To get the company address for the selected company
     */
    handleGetCompanyAddress = (value) => {
        if (value !== null) {
            this.state.countryArray.forEach((country) => {
                if (country.country === value) {
                    this.setState({
                        country: country.country,
                        state: country.states,
                    })
                }
            })
        } else this.setState({
            country: null,
            state: [],
        })
    }

    /**
     * handels change on state input value
     */
    handlestateChange = (value) => {
        this.setState({ selected_state: value })
    }

    /**
     * cancel will set the state variables to initial data
     */
    Cancel = () => this.setState({
        cardExpire: 0,
        cardDeactivation: 0,
        mileageRate: 0,
        cardLimit: 0
    })

    /**
    * Saves the card setting details in Database
    */
    Save = () => {
        if (this.state.selectedCompanyId === null && this.state.applyAll === false) {
            notification.warning({
                message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{this.props.app_labels.error_req_company}</span>,
                duration: 3,
                style: {
                    backgroundColor: '#fffbe6',
                    border: "1px solid #ffe58f",
                }
            });
        } else if (this.state.country === null || this.state.country === '') {
            notification.warning({
                message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{this.props.app_labels.error_req_country}</span>,
                duration: 3,
                style: {
                    backgroundColor: '#fffbe6',
                    border: "1px solid #ffe58f",
                }
            });
        } else if (this.state.state === null || this.state.state === '' || this.state.selected_state === null || this.state.selected_state === '') {
            notification.warning({
                message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{this.props.app_labels.error_req_state}</span>,
                duration: 3,
                style: {
                    backgroundColor: '#fffbe6',
                    border: "1px solid #ffe58f",
                }
            });
        } else if (this.state.city === null || this.state.city === '') {
            notification.warning({
                message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{this.props.app_labels.error_req_city}</span>,
                duration: 3,
                style: {
                    backgroundColor: '#fffbe6',
                    border: "1px solid #ffe58f",
                }
            });
        } else if (this.state.streetAddress === null || this.state.streetAddress === '') {
            notification.warning({
                message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{this.props.app_labels.error_req_streetAddress}</span>,
                duration: 3,
                style: {
                    backgroundColor: '#fffbe6',
                    border: "1px solid #ffe58f",
                }
            });
        } else if (this.state.zipCode === null || this.state.zipCode === '') {
            notification.warning({
                message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{this.props.app_labels.error_req_zipCode}</span>,
                duration: 3,
                style: {
                    backgroundColor: '#fffbe6',
                    border: "1px solid #ffe58f",
                }
            });
        } else {
            this.setState({ retrieve: true });
            loaderFocus(".loader-color .ant-spin-text");
            let companyAddress = {
                "country": this.state.country,
                "state": this.state.selected_state,
                "city": this.state.city,
                "flat": this.state.flat,
                "streetAddress": this.state.streetAddress,
                "zipCode": this.state.zipCode,
            }
            let obj = {
                'id': this.state.uniqueCardId,
                "companyId": parseInt(this.state.selectedCompanyId),
                "cardDeactivation": parseInt(this.state.cardDeactivation),
                "cardExpiration": parseInt(this.state.cardExpire),
                "cardLimit": parseInt(this.state.cardLimit),
                "mileageRate": this.state.mileageRate,
                "applyAll": this.state.applyAll,
                "companyAddress": companyAddress,
                "singleCardApproval": this.state.singleCardApproval === "enable" ? true : false,
                "bulkCardApproval": this.state.bulkCardApproval === "enable" ? true : false,
                "requestCardFeature": this.state.requestCardFeature === "enable" ? true : false,
                "creditLimitRule": this.state.creditLimitRule,
                "creditValidityRule": this.state.creditValidityRule,
                "approvalThresholds": this.state.approvalRule
            }
            axios.post(URLs.comUrl + "updateCompanyConfig", obj, {
                headers: {
                    "Authorization": 'Bearer ' + this.props.token.access_token
                }
            }).then(response => {
                this.setState({ retrieve: false });
                if (response.data.serviceStatus.statusCode === 200) {
                    notification.success({
                        message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Success </span>{this.props.app_labels.dataUpdateSuccessMsg}</span>,
                        duration: 3,
                        style: {
                            backgroundColor: '#dff0d8',
                            color: "green",
                            border: "1px solid green",
                        }
                    });
                } else if (response.data.serviceStatus.statusCode === 210) {
                    this.setState({ retrieve: false });
                    notification.error({
                        message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{this.props.app_labels.couldNotcompleteReq}</span>,
                        duration: 3,
                        style: {
                            backgroundColor: '#fff1f0',
                            border: "1px solid #ffa39e",
                        }
                    });
                } else {
                    this.setState({ retrieve: false });
                    notification.error({
                        message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{this.props.app_labels.couldNotcompleteReq}</span>,
                        duration: 3,
                        style: {
                            backgroundColor: '#fff1f0',
                            border: "1px solid #ffa39e",
                        }
                    });
                }
            }).catch(error => {
                this.setState({ retrieve: false });
                if (error.response.data !== null && error.response !== undefined && error.response.data !== undefined && error.response.data.serviceStatus !== undefined) {
                    notification.error({
                        message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{this.props.app_labels.couldNotcompleteReq}</span>,
                        duration: 3,
                        style: {
                            backgroundColor: '#fff1f0',
                            border: "1px solid #ffa39e",
                        }
                    });
                } else {
                    notification.error({
                        message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{this.props.app_labels.couldNotcompleteReq}</span>,
                        duration: 3,
                        style: {
                            backgroundColor: '#fff1f0',
                            border: "1px solid #ffa39e",
                        }
                    });
                }
            })
        }
    }

    /**
     * To call the service API for the given url
     */
    getApiRequest(URL, params, headers, callback) {
        axios.get(URL, {
            params: params,
            responseType: 'json',
            headers: headers
        }).then(response => {
            callback({
                status: true,
                data: response.data
            });
            return;
        }).catch((error) => {
            callback({
                status: false,
                error: error
            });
            if (error.response !== undefined && error.response.data !== null && error.response.data !== undefined && error.response.data.serviceStatus !== undefined) {
                notification.error({
                    message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{this.props.app_labels.couldNotcompleteReq}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }
            else {
                notification.error({
                    message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{this.props.app_labels.couldNotcompleteReq}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }
        })
    }

    /**
     * To get all the policy document for the company
     */
    getAllFiles() {
        let id = this.props.roles === "Company Admin" || this.props.roles === "Card Company Admin" ? this.state.companyId : this.state.selectedCompanyId;
        let url = URLs.fileUrl + "checkPolicyDocByCompanyId/" + id;
        let params = null;
        let headers = {
            "Authorization": 'Bearer ' + this.props.token.access_token
        }
        this.getApiRequest(url, params, headers, (res) => {
            if (res.status) {
                let data = res.data.data;
                this.setState({
                    companyFiles: data
                });
            }
        })
    }

    /* @param  {} value
    * getting cardsetting details for particular company
    */
    getCompanyAddress() {
        let id = this.props.roles === "Company Admin" || this.props.roles === "Card Company Admin" ? this.state.companyId : this.state.selectedCompanyId;
        axios.get(URLs.comUrl + "getCompanyAddByCompanyId/" + id, {
            responseType: 'Json',
            headers: {
                "Authorization": 'Bearer ' + this.props.token.access_token
            }
        }).then(response => {
            if (response.data.serviceStatus.statusCode === 200) {
                if (response.data.data !== undefined && response.data.data !== null) {
                    this.setState({
                        country: response.data.data.companyAddress.country, selected_state: response.data.data.companyAddress.state,
                        city: response.data.data.companyAddress.city, flat: response.data.data.companyAddress.flat,
                        streetAddress: response.data.data.companyAddress.streetAddress, zipCode: response.data.data.companyAddress.zipCode,
                        singleCardApproval: response.data.data.approvalSetting.singleCardApproval ? 'enable' : 'disable',
                        bulkCardApproval: response.data.data.approvalSetting.bulkCardApproval ? 'enable' : 'disable',
                        requestCardFeature: response.data.data.approvalSetting.requestCardFeature ? 'enable' : 'disable',
                        approvalRule: response.data.data.approvalSetting.approvalThresholds ? response.data.data.approvalSetting.approvalThresholds : null,
                        creditLimitRule: response.data.data.approvalSetting.cardLimitRule,
                        creditValidityRule: response.data.data.approvalSetting.cardValidityRule
                    });
                    this.handleGetCompanyAddress(response.data.data.country)
                } else {
                    this.setState({ companyAddress: null });
                }
            } else if (response.data.serviceStatus.statusCode === 210) {
                notification.error({
                    message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{this.props.app_labels.couldNotcompleteReq}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            } else {
                notification.error({
                    message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{this.props.app_labels.error_companyAddress}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }
        }).catch(error => {
            if (error.response.data !== null && error.response !== undefined && error.response.data !== undefined && error.response.data.serviceStatus !== undefined) {
                notification.error({
                    message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{this.props.app_labels.couldNotcompleteReq}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            } else {
                notification.error({
                    message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{this.props.app_labels.couldNotcompleteReq}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }
        })
    }

    /**
     * To render company files
     */
    renderCompanyFiles() {
        let companyFiles = this.state.companyFiles;
        if (companyFiles && companyFiles.companyPolicyExist) {
            return (
                <div onClick={() => this.downloadCompanyFile(companyFiles.companyPolicyDoc)} className="a1-link" style={{ display: "flex", width: "100%", alignItems: "center" }}>
                    <Icon type="file-pdf" style={{ fontSize: 23 }} />
                    <span style={{ fontSize: 23, marginLeft: 4 }}>{companyFiles.companyPolicyDoc}</span>
                </div>
            )
        }

        return null;
    }

    /**
     * To download the company files
     */
    downloadCompanyFile(name) {
        let id = this.props.roles === "Company Admin" || this.props.roles === "Card Company Admin" ? this.state.companyId : this.state.selectedCompanyId;
        try {
            let access_token = this.props.token.access_token
            axios.get(URLs.fileUrl + 'downloadPolicyDoc/' + id, {
                responseType: 'blob',
                headers: {
                    "Authorization": 'Bearer ' + access_token
                }
            }).then(response => {
                var a = document.createElement('a');
                a.href = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' })); // xhr.response is a blob
                a.download = name; // Set the file name.
                a.style.display = 'none';
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                setTimeout(function () {
                    window.URL.revokeObjectURL(a.href);
                }, 100)
            })
        } catch (err) {
            notification.error({
                message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{this.props.app_labels.couldNotcompleteReq}</span>,
                duration: 3,
                style: {
                    backgroundColor: '#fff1f0',
                    border: "1px solid #ffa39e",
                }
            });
        }
    }

    callback = (event, tabName) => {
        console.log(tabName);
        this.setState({activeTab: tabName})
    }

    handleSearch = search =>{
        this.setState({ selectSearchItem: search});
      }

    renderLabel = (item) => {
        let label = item.companyName;
        let searchString = this.state.selectSearchItem;
        if (searchString) {
            let index = label.toLowerCase().indexOf(searchString.toLowerCase());
            if (index !== -1) {
                let length = searchString.length;
                let prefix = label.substring(0, index);
                let suffix = label.substring(index + length);
                let match = label.substring(index, index + length);
    
                return (
                    <span>{prefix}<span className="searchString" style={{color: "#19398A", fontWeight: 600}}>{match}</span>{suffix}
                    </span>
                );
            }
        }
        return (
          <span>
              {label}
          </span>
        );
      }

    render() {
        const columns = [{
            title: 'Company name',
            dataIndex: 'companyName',
            key: 'companyName',
            align: "center",
        }, {
            title: 'Card expiration',
            dataIndex: 'cardExpiration',
            key: 'cardExpiration',
            align: "center",
        }, {
            title: 'Card deactivation',
            dataIndex: 'cardDeactivation',
            key: 'cardDeactivation',
            align: "center",
        }, {
            title: 'Card limit',
            dataIndex: 'cardLimit',
            key: 'cardLimit',
            align: "center",
        }, {
            title: 'Mileage rate',
            dataIndex: 'mileageRate',
            key: 'mileageRate',
            align: "center",
        }]
        // const columnsValidity = [{
        //     title: 'Card amount range',
        //     children: [{
        //         title: null,
        //         dataIndex: 'minAmount',
        //         key: 'minAmount',
        //         align: "center",
        //         width: '30%',
        //     },{
        //         title: null,
        //         dataIndex: 'maxAmount',
        //         key: 'maxAmount',
        //         align: "center",
        //         width: '30%',
        //     }
        //     ]},{
        //     title: 'Validity period (in Days)',
        //     dataIndex: 'validity',
        //     key: 'validity',
        //     align: "center",
        //     width: '50%',
        // }];

        return (
            <div>
                <div className="cardsettingclass" >
                    <div className="row admin_heading">
                        <b role="heading" aria-level="2">{this.props.app_labels.cardMileageSettings}</b><br /><hr aria-hidden="true" className="hrStyleclass" />
                    </div>
                    <div className="row">
                        <div className="col-sm-3 col-sm-offset-1 cardsetrule1" >
                            <span id='comp-name'>{this.props.app_labels.companyName}</span><br /><br />
                            {(this.props.roles === "Company Admin" || this.props.roles === "Card Company Admin") ?
                                <Select
                                    disabled
                                    aria-labelledby='comp-name'
                                    aria-disabled="true"
                                    defaultValue={this.state.companyId}
                                    onChange={this.companyChange} style={{ width: '100%' }} placeholder="Select company">
                                    <Option value={this.state.companyId}>{this.props.activeCompanyName[0]}</Option>
                                </Select> :
                                <Select className="active-select-comp super-admin-select"
                                showSearch
                                aria-labelledby='comp-name'
                                onChange={this.companyChange}
                                onDropdownVisibleChange={open => {
                                    this.setState({ isClose: !open })
                                }}
                                defaultActiveFirstOption={this.state.isClose}
                                optionFilterProp="children"
                                onSearch={this.handleSearch}
                                filterOption={(input, option) => {
                                    return option.props?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }}
                                style={{ width: '100%', border: '1px solid #D2D2E0', borderRadius: '4px' }} placeholder="Select Company">
                                  <Option value="ALL">All</Option>
                                {this.state.companyName.map(item => {
                                    return <Option value={item.companyId} label={item.companyName}>{this.renderLabel(item)}</Option>
                                })}
                            </Select>
                            }
                        </div>
                    </div> <br />
                    {this.state.selectedCompanyId === "ALL" ?
                        <div className="row" style={{ color: "#112A70" }}>
                            <div className="col-sm-10 col-sm-offset-1" >
                                <Icon type="info-circle" />
                                <span> {this.props.app_labels.configWarning}</span>
                            </div>
                        </div> : null}
                    {this.state.selectedCompanyId &&
                        // <Tabs defaultActiveKey="1"
                        //     onTabClick={this.callback}
                        //     style={{ marginLeft: "2%" }}>
                        //     {this.props.roles !== "Card Company Admin" &&
                        //         <TabPane tab={this.props.app_labels.cardSettings} key="1">
                        //             <div className="row">
                        //                 <CustomizeInput
                        //                     field="expirationRule"
                        //                     value={this.state.cardExpire}
                        //                     lastText={this.props.app_labels.daysFromTravel}
                        //                     onChangeExpire={this.onChangeExpire}
                        //                     app_labels={this.props.app_labels} />
                        //                 <CustomizeInput
                        //                     field="deactivateRule"
                        //                     value={this.state.cardDeactivation}
                        //                     lastText={this.props.app_labels.daysFromTravel}
                        //                     onChangeDeactivation={this.onChangeDeactivation}
                        //                     app_labels={this.props.app_labels} />
                        //             </div><br />
                        //             <div className="row">
                        //                 <CustomizeInput
                        //                     field="cardLimit"
                        //                     value={this.state.cardLimit}
                        //                     onChangeCardLimit={this.onChangeCardLimit}
                        //                     app_labels={this.props.app_labels} />
                        //             </div>
                        //             { /* Correcting the Admin Setting page and taking out the unwanted “Card validity terms” display in the UI */}
                        //         </TabPane>}
                        //     {this.props.roles !== "Card Company Admin" &&
                        //         <TabPane tab={this.props.app_labels.mileageSettings} key="2">
                        //             <div className="row">
                        //                 <CustomizeInput
                        //                     field="mileageRate"
                        //                     value={this.state.mileageRate}
                        //                     min="0"
                        //                     onChangeMileageLimit={this.onChangeMileageLimit}
                        //                     app_labels={this.props.app_labels} />
                        //             </div>
                        //         </TabPane>}
                        //     <TabPane tab={this.props.app_labels.approvalSettings} key="3">
                        //         <div className="row">
                        //             <CustomizeInput
                        //                 field="singleCardApproval"
                        //                 value={this.state.singleCardApproval}
                        //                 onChangeSingleCardApproval={this.onChangeSingleCardApproval}
                        //                 app_labels={this.props.app_labels} />
                        //             <CustomizeInput
                        //                 field="bulkCardApproval"
                        //                 value={this.state.bulkCardApproval}
                        //                 onChangeBulkCardApproval={this.onChangeBulkCardApproval}
                        //                 app_labels={this.props.app_labels} />
                        //             <CustomizeInput
                        //                 field="requestCardFeature"
                        //                 value={this.state.requestCardFeature}
                        //                 onChangeRequestCardFeature={this.onChangeRequestCardFeature}
                        //                 app_labels={this.props.app_labels} />
                        //         </div><br />
                        //         <div className="row">
                        //             <CustomizeInput
                        //                 field="approvalRule"
                        //                 value={this.state.approvalRule}
                        //                 onChangeApprovalRule={this.onChangeApprovalRule}
                        //                 app_labels={this.props.app_labels} />
                        //             {this.state.approvalRule === 1 && <CustomizeInput
                        //                 field="creditLimitRule"
                        //                 value={this.state.creditLimitRule}
                        //                 lastText={this.props.app_labels.requestAboveAmountApproval}
                        //                 onChangeCreditLimitRule={this.onChangeCreditLimitRule}
                        //                 app_labels={this.props.app_labels} />}
                        //             {this.state.approvalRule === 2 && <CustomizeInput
                        //                 field="creditValidityRule"
                        //                 value={this.state.creditValidityRule}
                        //                 lastText={this.props.app_labels.requestAboveValidityDayApproval}
                        //                 onChangeCreditValidityRule={this.onChangeCreditValidityRule}
                        //                 app_labels={this.props.app_labels} />}
                        //             {(this.state.approvalRule === 3 || this.state.approvalRule === 4) && <><CustomizeInput
                        //                 field="creditLimitRule"
                        //                 value={this.state.creditLimitRule}
                        //                 lastText={this.props.app_labels.requestAboveAmountApproval}
                        //                 onChangeCreditLimitRule={this.onChangeCreditLimitRule}
                        //                 app_labels={this.props.app_labels} />
                        //                 <CustomizeInput
                        //                 field="creditValidityRule"
                        //                 value={this.state.creditValidityRule}
                        //                 lastText={this.props.app_labels.requestAboveValidityDayApproval}
                        //                 onChangeCreditValidityRule={this.onChangeCreditValidityRule}
                        //                 app_labels={this.props.app_labels} /></>}
                        //         </div>
                        //     </TabPane>
                        //     {this.props.roles === "Company Admin" &&
                        //         <TabPane tab={this.props.app_labels.policyDocument} key="4">
                        //             {this.renderCompanyFiles()}
                        //             <br></br>
                        //             <div className="uploadPolicy"
                        //                 onClick={() => this.setState({
                        //                     uploadPolicyflag: true,
                        //                     uploadComapnyId: this.state.selectedCompanyId
                        //                 })}>
                        //                 <b><i role='img' style={{ fontSize: "larger" }} aria-label="icon-Upload attachment" className='icon-Upload-attachment-02' /> {this.props.app_labels.uploadPolicyDoc}</b>
                        //             </div>
                        //         </TabPane>}
                        //     <TabPane tab={this.props.app_labels.companyAddress} key="5">
                        //         <div className="row">
                        //             <div className="col-xs-10 col-sm-10 col-sm-offset-0 col-md-4 col-md-offset-1 cardsetrule1">
                        //                 <div className="form-group">
                        //                     <span id="country" className="text-class">{this.props.app_labels.country_AdminView}</span>
                        //                     <span className="required">*</span>
                        //                     <br />
                        //                     <AutoComplete
                        //                         aria-label="Select Country"
                        //                         required
                        //                         style={{ width: '100%', border: '1px solid #666666' }}
                        //                         backfill={true}
                        //                         dataSource={this.state.countryArray.map(this.renderCountry.bind(this))}
                        //                         onChange={value => this.handlecountrySearch(value)}
                        //                         onSelect={value => this.handlecountryChange(value)}
                        //                         onFocus={() => this.setState({focused: true})}
                        //                         onBlur={() => this.setState({focused: false})}
                        //                         value={this.state.country} />
                        //                 </div>
                        //                 <br />
                        //                 <div className="form-group">
                        //                     <span id="state" className="text-class">{this.props.app_labels.state}</span>
                        //                     <span className="required">*</span>
                        //                     <br />
                        //                     <AutoComplete
                        //                         required
                        //                         aria-label="Select State"
                        //                         style={{ width: '100%', border: '1px solid #666666' }}
                        //                         backfill={true}
                        //                         dataSource={this.state.state.map(this.renderState.bind(this))}
                        //                         onChange={value => this.handlestateSearch(value)}
                        //                         onSelect={value => this.handlestateChange(value)}
                        //                         value={this.state.selected_state ? this.state.selected_state : this.state.state.map(val => { return val[0] })}
                        //                         disabled={!this.state.country} />
                        //                 </div>
                        //                 <br />
                        //                 <div className="form-group">
                        //                     <span id="city" className="text-class">{this.props.app_labels.city}</span>
                        //                     <span className="required">*</span>
                        //                     <br />
                        //                     <TextArea
                        //                         required
                        //                         rows={1}
                        //                         aria-labelledby="city"
                        //                         placeholder={this.props.app_labels.enterCity}
                        //                         value={this.state.city}
                        //                         onChange={this.onChangeCity}
                        //                         autocomplete="address-level2"
                        //                     />
                        //                 </div>
                        //                 <br />
                        //                 <div className="form-group">
                        //                     <span id='flat' className="text-class">{this.props.app_labels.flat}</span>
                        //                     <br />
                        //                     <TextArea
                        //                         rows={1}
                        //                         aria-labelledby="flat"
                        //                         placeholder={this.props.app_labels.enterFlatNumber}
                        //                         value={this.state.flat}
                        //                         onChange={this.onChangeFlat}
                        //                         autocomplete="address-level1" />
                        //                 </div>
                        //                 <br />
                        //                 <div className="form-group">
                        //                     <span id="street" className="text-class">{this.props.app_labels.streetAddress}</span>
                        //                     <span className="required">*</span>
                        //                     <br />
                        //                     <TextArea
                        //                         rows={1}
                        //                         required
                        //                         aria-labelledby="street"
                        //                         placeholder={this.props.app_labels.enterStreetAddress}
                        //                         value={this.state.streetAddress}
                        //                         onChange={this.onChangestreet}
                        //                         autocomplete="street-address"
                        //                     />
                        //                 </div>
                        //                 <br />
                        //                 <div className="form-group">
                        //                     <span id="zipcode" className="text-class">{this.props.app_labels.zipcode}</span>
                        //                     <span className="required">*</span>
                        //                     <br />
                        //                     <TextArea
                        //                         rows={1}
                        //                         placeholder={this.props.app_labels.zipcode}
                        //                         aria-labelledby="zipcode"
                        //                         required
                        //                         value={this.state.zipCode}
                        //                         onChange={this.onChangezipcode}
                        //                         autocomplete="postal-code" />
                        //                 </div>
                        //                 <br />
                        //             </div>
                        //         </div>
                        //     </TabPane>
                        // </Tabs>

                        <div>
                            <Tabs style={{ marginLeft: "2%", borderBottom: '1px solid #333333' }} className="request-extra" 
                            defaultActiveKey="1"  value={this.state.activeTab} 
                            onChange={this.callback}> 
                            {this.props.roles !== "Card Company Admin" && 
                                <Tab disableRipple id="setting-tab" aria-controls='setting-tab-panel' className="single-tab" value={"1"} label={
                                <span id='settingTab' ref={'SettingTab'} >
                                    {this.props.app_labels.cardSettings}
                                </span>}  
                                />}
                            
                            {this.props.roles !== "Card Company Admin" &&
                                <Tab disableRipple id="mileage-tab" aria-controls='mileage-tab-panel' className="single-tab" value={"2"} label={ <span id='mileageTab' 
                                ref={'mileageTab'}
                                >{this.props.app_labels.mileageSettings} </span>} /> }

                            <Tab disableRipple id="approvalSetting-tab" aria-controls='approvalSetting-tab-panel' className="single-tab" value={"3"} label={
                                <span id='approvalSettingTab' ref={'approvalSettingTab'} >
                                    {this.props.app_labels.approvalSettings}
                                </span>}  
                                />
                            
                            {this.props.roles === "Company Admin" &&
                                <Tab disableRipple id="policy-tab" aria-controls='policy-tab-panel' className="single-tab" value={"4"} label={ <span id='bulkTab' 
                                ref={'policyTab'}
                                >{this.props.app_labels.policyDocument} </span>} /> }

                            <Tab disableRipple id="company-tab" aria-controls='company-tab-panel' className="single-tab" value={"5"} label={
                                <span id='companyTab' ref={'companyTab'} >
                                    {this.props.app_labels.companyAddress}
                                </span>}  
                                />
                            
                                                
                            </Tabs>

                            {this.props.roles !== "Card Company Admin" && <div role="tabpanel" id='setting-tab-panel' aria-labelledby='setting-tab' value={"1"}
                            hidden={this.state.activeTab !== "1"} 
                            style={{marginTop: '2%'}}
                            >
                                <div className="row">
                                            <CustomizeInput
                                                field="expirationRule"
                                                value={this.state.cardExpire}
                                                lastText={this.props.app_labels.daysFromTravel}
                                                onChangeExpire={this.onChangeExpire}
                                                app_labels={this.props.app_labels}
                                                role={this.props.roles} />
                                            <CustomizeInput
                                                field="deactivateRule"
                                                value={this.state.cardDeactivation}
                                                lastText={this.props.app_labels.daysFromTravel}
                                                onChangeDeactivation={this.onChangeDeactivation}
                                                app_labels={this.props.app_labels}
                                                role={this.props.roles} />
                                </div><br />
                                <div className="row">
                                    <CustomizeInput
                                        field="cardLimit"
                                        value={this.state.cardLimit}
                                        onChangeCardLimit={this.onChangeCardLimit}
                                        app_labels={this.props.app_labels}
                                        role={this.props.roles} />
                                </div>
                            </div>}

                            {this.props.roles !== "Card Company Admin" && <div role="tabpanel" id='mileage-tab-panel' aria-labelledby='mileage-tab' value={"2"}
                            hidden={this.state.activeTab !== "2"} 
                            style={{marginTop: '2%'}}
                            >
                                <div className="row">
                                            <CustomizeInput
                                                field="mileageRate"
                                                value={this.state.mileageRate}
                                                min="0"
                                                onChangeMileageLimit={this.onChangeMileageLimit}
                                                app_labels={this.props.app_labels}
                                                role={this.props.roles} />
                                </div>
                            </div>}
                            <div role="tabpanel" id='approvalSetting-tab-panel' aria-labelledby='approvalSetting-tab' value={"3"}
                            hidden={this.state.activeTab !== "3"} 
                            style={{marginTop: '2%'}}
                            >
                            <div className="row">
                                        <CustomizeInput
                                            field="singleCardApproval"
                                            value={this.state.singleCardApproval}
                                            onChangeSingleCardApproval={this.onChangeSingleCardApproval}
                                            app_labels={this.props.app_labels}
                                            role={this.props.roles} />
                                        <CustomizeInput
                                            field="bulkCardApproval"
                                            value={this.state.bulkCardApproval}
                                            onChangeBulkCardApproval={this.onChangeBulkCardApproval}
                                            app_labels={this.props.app_labels}
                                            role={this.props.roles} />
                                        <CustomizeInput
                                            field="requestCardFeature"
                                            value={this.state.requestCardFeature}
                                            onChangeRequestCardFeature={this.onChangeRequestCardFeature}
                                            app_labels={this.props.app_labels}
                                            role={this.props.roles} />
                                    </div><br />
                                    <div className="row">
                                        <CustomizeInput
                                            field="approvalRule"
                                            value={this.state.approvalRule}
                                            onChangeApprovalRule={this.onChangeApprovalRule}
                                            app_labels={this.props.app_labels}
                                            role={this.props.roles} />
                                        {this.state.approvalRule === 1 && <CustomizeInput
                                            field="creditLimitRule"
                                            value={this.state.creditLimitRule}
                                            lastText={this.props.app_labels.requestAboveAmountApproval}
                                            onChangeCreditLimitRule={this.onChangeCreditLimitRule}
                                            app_labels={this.props.app_labels}
                                            role={this.props.roles} />}
                                        {this.state.approvalRule === 2 && <CustomizeInput
                                            field="creditValidityRule"
                                            value={this.state.creditValidityRule}
                                            lastText={this.props.app_labels.requestAboveValidityDayApproval}
                                            onChangeCreditValidityRule={this.onChangeCreditValidityRule}
                                            app_labels={this.props.app_labels}
                                            role={this.props.roles} />}
                                        {(this.state.approvalRule === 3 || this.state.approvalRule === 4) && <><CustomizeInput
                                            field="creditLimitRule"
                                            value={this.state.creditLimitRule}
                                            lastText={this.props.app_labels.requestAboveAmountApproval}
                                            onChangeCreditLimitRule={this.onChangeCreditLimitRule}
                                            app_labels={this.props.app_labels}
                                            role={this.props.roles} />
                                            <CustomizeInput
                                            field="creditValidityRule"
                                            value={this.state.creditValidityRule}
                                            lastText={this.props.app_labels.requestAboveValidityDayApproval}
                                            onChangeCreditValidityRule={this.onChangeCreditValidityRule}
                                            app_labels={this.props.app_labels}
                                            role={this.props.roles} /></>}
                                    </div> 
                            </div>
                            {this.props.roles === "Company Admin" && <div role="tabpanel" id='policy-tab-panel' aria-labelledby='policy-tab' value={"4"}
                            hidden={this.state.activeTab !== "4"} 
                            style={{marginTop: '2%'}}
                            >
                            {this.renderCompanyFiles()}
                                        <br></br>
                                        <div className="uploadPolicy"
                                            onClick={() => this.setState({
                                                uploadPolicyflag: true,
                                                uploadComapnyId: this.state.selectedCompanyId
                                            })}>
                                            <b><i role='img' style={{ fontSize: "larger" }} aria-label="icon-Upload attachment" className='icon-Upload-attachment-02' /> {this.props.app_labels.uploadPolicyDoc}</b>
                                        </div>
                            </div>}

                            <div role="tabpanel" id='company-tab-panel' aria-labelledby='company-tab' value={"5"}
                            hidden={this.state.activeTab !== "5"} 
                            style={{marginTop: '2%'}}
                            >
                            <div className="row">
                                        <div className="col-xs-10 col-sm-10 col-sm-offset-0 col-md-4 col-md-offset-1 cardsetrule1">
                                            <div className="form-group">
                                                <span id="country" className="text-class">{this.props.app_labels.country_AdminView}</span>
                                                <span className="required">*</span>
                                                <br />
                                                <AutoComplete
                                                    required
                                                    style={{ width: '100%', border: '1px solid #666666' }}
                                                    backfill={true}
                                                    className="select-country-input"
                                                    dataSource={this.state.countryArray.map(this.renderCountry.bind(this))}
                                                    onChange={value => this.handlecountrySearch(value)}
                                                    onSelect={value => this.handlecountryChange(value)}
                                                    onFocus={() => this.setState({focused: true})}
                                                    onBlur={() => this.setState({focused: false})}
                                                    value={this.state.country}
                                                    disabled={this.props.roles.toLowerCase() === 'customer support'? true : false} />
                                            </div>
                                            <br />
                                            <div className="form-group">
                                                <span id="state" className="text-class">{this.props.app_labels.state}</span>
                                                <span className="required">*</span>
                                                <br />
                                                <AutoComplete
                                                    required
                                                    style={{ width: '100%', border: '1px solid #666666' }}
                                                    className="select-state-input"
                                                    backfill={true}
                                                    dataSource={this.state.state.map(this.renderState.bind(this))}
                                                    onChange={value => this.handlestateSearch(value)}
                                                    onSelect={value => this.handlestateChange(value)}
                                                    value={this.state.selected_state ? this.state.selected_state : this.state.state.map(val => { return val[0] })}
                                                    disabled={this.props.roles.toLowerCase() === 'customer support'? true :!this.state.country} />
                                            </div>
                                            <br />
                                            <div className="form-group">
                                                <span id="city" className="text-class">{this.props.app_labels.city}</span>
                                                <span className="required">*</span>
                                                <br />
                                                <TextArea
                                                    required
                                                    rows={1}
                                                    aria-labelledby="city"
                                                    placeholder={this.props.app_labels.enterCity}
                                                    value={this.state.city}
                                                    onChange={this.onChangeCity}
                                                    autocomplete="address-level2"
                                                    disabled={this.props.roles.toLowerCase() === 'customer support'? true : false}
                                                />
                                            </div>
                                            <br />
                                            <div className="form-group">
                                                <span id='flat' className="text-class">{this.props.app_labels.flat}</span>
                                                <br />
                                                <TextArea
                                                    rows={1}
                                                    aria-labelledby="flat"
                                                    placeholder={this.props.app_labels.enterFlatNumber}
                                                    value={this.state.flat}
                                                    onChange={this.onChangeFlat}
                                                    autocomplete="address-level1"
                                                    disabled={this.props.roles.toLowerCase() === 'customer support'? true : false} />
                                            </div>
                                            <br />
                                            <div className="form-group">
                                                <span id="street" className="text-class">{this.props.app_labels.streetAddress}</span>
                                                <span className="required">*</span>
                                                <br />
                                                <TextArea
                                                    rows={1}
                                                    required
                                                    aria-labelledby="street"
                                                    placeholder={this.props.app_labels.enterStreetAddress}
                                                    value={this.state.streetAddress}
                                                    onChange={this.onChangestreet}
                                                    autocomplete="street-address"
                                                    disabled={this.props.roles.toLowerCase() === 'customer support'? true : false}
                                                />
                                            </div>
                                            <br />
                                            <div className="form-group">
                                                <span id="zipcode" className="text-class">{this.props.app_labels.zipcode}</span>
                                                <span className="required">*</span>
                                                <br />
                                                <TextArea
                                                    rows={1}
                                                    placeholder={this.props.app_labels.zipcode}
                                                    aria-labelledby="zipcode"
                                                    required
                                                    value={this.state.zipCode}
                                                    onChange={this.onChangezipcode}
                                                    autocomplete="postal-code"
                                                    disabled={this.props.roles.toLowerCase() === 'customer support'? true : false} />
                                            </div>
                                            <br />
                                        </div>
                                    </div>
                            </div>
                        </div>
                        
                    }

                    {this.state.uploadPolicyflag &&
                        <PolicyUpload
                            action={this.state.uploadPolicyflag}
                            hideUploadModal={() => this.refreshFileUploadStatus()}
                            companyId={parseInt(this.state.selectedCompanyId)}
                            policyType={parseInt(this.state.policyType)} />}

                    {this.state.retrieve ? <div className="dark-spinner loader-color"><Spin tip="Loading..." /></div> : null}
                    {this.state.allCardDetails.length !== 0 ?
                        <div className="row" style={{ marginTop: "4%" }} >
                            <div className="col-xs-12 col-sm-12 col-sm-offset-0 col-md-10 col-md-offset-1">
                                <Table
                                    columns={columns}
                                    dataSource={this.state.allCardDetails}
                                    bordered={true}
                                    pagination={{ pageSize: 3 }}
                                />
                            </div>
                        </div> : null}
                    <br /><br />
                </div>
                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-sm-offset-0 col-md-12 col-md-offset-0" style={{ textAlign: 'right' }}>
                        <button className="focusbutton buttonRightPadding button-createtrip-ghost" style={{ marginTop: "3%", marginBottom: "5%" }} onClick={this.Cancel} disabled={this.props.roles.toLowerCase() === 'customer support'? true : false}><b>{this.props.app_labels.createCardCancel}</b></button>&nbsp;
                        <button className="focusbutton button-createtrip button-wrapclass" style={{ marginBottom: "5%", marginTop: "3%" }} onClick={this.Save} disabled={this.props.roles.toLowerCase() === 'customer support'? true : false} ><b>{this.props.app_labels.save}</b></button>
                    </div>
                </div>
            </div>
        )
    }
}
function mapStateToProps(state, props) {
    return {
        token: state.authenticationReducer.token,
        userDetails: state.authenticationReducer.userDetails,
    };
}
export default connect(mapStateToProps)(CardMileageSettings);