import React from 'react';
import { Card, Button, Table } from 'antd';
import '../../FontIcons/style.css';
import '../../styling/userAction.css';
import '../../styling/focus.css';
import history from '../history'
import { connect } from 'react-redux';
import { cleartripId } from '../../actions/createtripActions.js';
const { userActionDescription } = require('../../data/constants.js')
class UserAction extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            UserTripData: this.props.allTripDetail,
            requestSent: false,
            filteredInfo: null,
            sortedInfo: null,
            cardTileColor: '#0A41C5',
            itineraryTileColor: '#0A41C5',
            notifyTileColor: '#0A41C5',
            closureTileColor: '#0A41C5',
            tripStatusFilter : []
        }
    }

    componentDidMount(){
        this.getStatusFilter(this.props.allTripDetail);
    }

    /**Load new data when the dataSource property changes
     * @param  {} nextProps
     */
    componentWillReceiveProps(nextProps) {
        this.setState({ UserTripData: nextProps.allTripDetail })
        this.getStatusFilter(nextProps.allTripDetail);
        /* this.setState({ UserTripData: nextProps.allTripDetail.slice(0, 10) }) */
    }

    /**
     * Filters based on trip status
     * @param {*} data 
     */
    getStatusFilter(data){
        let status = {};
        if(data){
            data.forEach((obj) => {
                if(obj.tripStatus && !status[obj.tripStatus]){
                    status[obj.tripStatus] = "";
                }
            });
        }

        let filter = [];
        for(let key in status){
            filter.push({
                text : key,
                value : key
            });
        }

        this.setState({
            tripStatusFilter : filter
        });
        console.log("UserTripDetails : "+JSON.stringify(status));
    }

    /**Fetch more data on scrolling down
     */
    fetchMoreData = () => {
        this.setState({ requestSent: true });
        setTimeout(() => {
            if (this.state.UserTripData.length === this.props.allTripDetail.length) {
                this.setState({ requestSent: false });
            }
            var count = this.state.UserTripData.length + 10;
            var moredata = this.props.allTripDetail.slice(this.state.UserTripData.length, count)
            this.setState({
                UserTripData: this.state.UserTripData.concat(moredata), requestSent: false

            });
        }, 1500);

    }

    /**Trip Id will be cleared in the store
     */
    createNewTripButton = () => {
        this.props.cleartripID();
        history.push('/newtrip')
    }

    /**
     * handles filters and sorters in the table
     */
    handleTableChange = (pagination, filters, sorter) => {
        if (filters.action !== null && filters.action.length === 0) {
            this.setState({ cardTileColor: '#0A41C5', itineraryTileColor: '#0A41C5', notifyTileColor: '#0A41C5', closureTileColor: '#0A41C5' })
        }
        this.setState({
            filteredInfo: filters,
            sortedInfo: sorter,
        });
    }
    onClickofTile = (value) => {
        let filters = {};
        filters.action = value;
        this.setState({
            filteredInfo: filters,
        });
    }

    render() {
        let { sortedInfo, filteredInfo } = this.state;
        sortedInfo = sortedInfo || {};
        filteredInfo = filteredInfo || {};
        const columns = [
            {
                title: <span className='tableHeader'>{this.props.app_labels.byAllStatus}</span>,
                dataIndex: "tripStatus",
                className: 'column-tripStatus',
                align: "center",
                width: '15%',
                filterIcon: filtered => <i role='img' aria-label='drop down' className="icon-Drop-down" style={{ color: filtered ? '#0A41C5' : '#0A41C5' }}></i>,
                filters : this.state.tripStatusFilter,
                onFilter: (value, record) => record.tripStatus.indexOf(value) === 0,
                render: tripStatus => (
                    <div className='tableContent'  style={{ marginLeft: "10%" }}  >
                        <span>{tripStatus}</span>
                    </div>
                ),
            },

            {
                title: <span className='tableHeader'>{this.props.app_labels.name}</span>,
                dataIndex: "travelerName",
                align: "center",
                className: 'column-travelerName',
                width: '20%',
                render: travelerName => (
                    <div className='tableContent' style={{ marginLeft: "10%" }} >
                        <span >{travelerName}</span>
                    </div>
                ),
                sorter: (a, b) => {
                    let name1 = a.travelerName.toLowerCase();
                    let name2 = b.travelerName.toLowerCase();
                    return name1 < name2 ? -1 : 1
                },
                sortOrder: sortedInfo.columnKey === 'travelerName' && sortedInfo.order,
            },
            {
                title: <span className='tableHeader'>{this.props.app_labels.startDate}</span>,
                dataIndex: "travelStartDate",
                align: "center",
                width: '15%',
                render: travelStartDate => (
                    <div className='tableContent' style={{ marginLeft: "0%" }} >
                        <span >{travelStartDate}</span>
                    </div>
                ),
                sorter: (a, b) => { return Number(new Date(a.travelStartDate)) > Number(new Date(b.travelStartDate)) ? -1 : Number(new Date(a.travelStartDate)) < Number(new Date(b.travelStartDate)) ? 1 : 0 },
                sortOrder: sortedInfo.columnKey === 'travelStartDate' && sortedInfo.order,
            },


            {
                title: <span className='tableHeader'>{this.props.app_labels.destination1}</span>,
                dataIndex: "destination",
                align: "center",
                className: 'column-destination',
                width: '20%',
                render: destination => (
                    <div className='tableContent' style={{ marginLeft: "10%" }} >
                        <span >{destination}</span>
                    </div>
                ),
                sorter: (a, b) => {
                    let dest1 = a.destination.toLowerCase();
                    let dest2 = b.destination.toLowerCase();
                    return dest1 < dest2 ? -1 : 1
                },
                sortOrder: sortedInfo.columnKey === 'destination' && sortedInfo.order,
            },
            {
                title: <span className='tableHeader'>{this.props.app_labels.byAllAction}</span>,
                dataIndex: "action",
                align: "center",
                className: 'column-action',
                width: '20%',
                filteredValue: filteredInfo.action || null,
                filterIcon: filtered => <i role='img' aria-label='dropdown' className="icon-Drop-down" style={{ color: filtered ? '#0A41C5' : '#0A41C5' }}></i>,
                filters: [{
                    text: userActionDescription.flightItinerary,
                    value: userActionDescription.flightItinerary,
                }, {
                    text: userActionDescription.assignCreditCard,
                    value: userActionDescription.assignCreditCard,
                },
                {
                    text: userActionDescription.hotelItinerary,
                    value: userActionDescription.hotelItinerary,
                }, {
                    text: userActionDescription.tripClosure,
                    value: userActionDescription.tripClosure,
                }, {
                    text: userActionDescription.travelerNotified,
                    value: userActionDescription.travelerNotified,
                }],
                onFilter: (value, record) => record.action.indexOf(value) === 0,

                render: (text, record) => (

                    <span className='tableContent'>
                        {record.action.toLowerCase() === userActionDescription.flightItinerary.toLowerCase() ? <div><p className = "a1-link" style={{ color: "#0A41C5" }} onClick={() => { this.props.hyperlinkFunc(record.tripId) }}><i role='img' aria-label='Flight' title='Flight' className="icon-Airplane-blue-size-1 tableicons"></i><span style={{ marginLeft: "2%" }}>{record.action}</span></p></div> : null}
                        {record.action.toLowerCase() === userActionDescription.hotelItinerary.toLowerCase() ? <div><p className = "a1-link" style={{ color: "#0A41C5" }} onClick={() => { this.props.hyperlinkFunc(record.tripId) }}><i role='img' aria-label='Hotel' title='Hotel' className="icon-Buildings-blue-size-1 tableicons" ></i><span style={{ marginLeft: "2%" }}>{record.action}</span></p></div> : null}
                        {record.action.toLowerCase() === userActionDescription.travelerNotified.toLowerCase() ? <div><p className = "a1-link" style={{ color: "#0A41C5" }} onClick={() => { this.props.hyperlinkFunc(record.tripId) }}><i role='img' aria-label='Notify traveler' title='Notify' className="icon-Notify tableicons"></i><span style={{ marginLeft: "2%" }}>{record.action}</span></p></div> : null}
                        {record.action.toLowerCase() === userActionDescription.assignCreditCard.toLowerCase() ? <div><p className = "a1-link" style={{ color: "#0A41C5" }} onClick={() => { this.props.hyperlinkFunc(record.tripId) }}><i role='img' aria-label='credit card' title='credit card' className="icon-Credit-Card-blue-size1 tableicons"></i><span style={{ marginLeft: "2%" }}>{record.action}</span></p></div> : null}
                        {record.action.toLowerCase() === userActionDescription.tripClosure.toLowerCase() ? <div><p className = "a1-link" style={{ color: "#0A41C5" }} onClick={() => { this.props.hyperlinkFunc(record.tripId) }}><i role='img' aria-label='tripclosure' title='Tripclosure' className="icon-Symbol-38--3 tableicons"></i><span style={{ marginLeft: "2%" }}>{record.action}</span></p></div> : null}
                    </span>
                ),
            }
        ];


        return (
            <div className="container-fluid">
                <div className="row marginRight">
                    <div className="col-xs-7 col-xs-offset-5 col-sm-4 col-sm-offset-8 col-md-3 col-md-offset-8 col-lg-3 col-lg-offset-9">
                        {/* <span><Link to="/newtrip" onClick={this.createNewTripButton}> */}<div className='createNewTripClass'>
                            <Button tabindex='0' className='focusbutton button-wrapclass1' type="primary" onClick={this.createNewTripButton}>{this.props.app_labels.createNewTrip}</Button>
                        </div>{/* </Link></span> */}
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-10 col-xs-offset-0 col-sm-6 col-sm-offset-1 col-md-6 col-md-offset-0">
                        <div className="action-filte-title">{this.props.app_labels.actionFilters}</div>
                    </div>
                </div>
                <div className="row introcardFocus">
                    <div className="action-filters">
                        <div className="col-sm-3 col-sm-offset-0 col-xs-6 col-xs-offset-0">
                            <Card tabindex='0' className="cardFocus cardShadow" style={{ cursor: "pointer", backgroundColor: this.state.cardTileColor === 'white' ? '#0A41C5' : 'white' }} onClick={() => {
                                this.setState({ cardTileColor: 'white', itineraryTileColor: '#0A41C5', notifyTileColor: '#0A41C5', closureTileColor: '#0A41C5' })
                                this.onClickofTile([userActionDescription.assignCreditCard])
                            }} bordered={false}
                                onKeyUp={e => {
                                    if (e.keyCode === 13) {
                                        this.onClickofTile([userActionDescription.assignCreditCard])
                                    }
                                }}
                            >
                                <div className="row">
                                    <div className="useaction-Icon">
                                        <div className="col-sm-1 col-sm-offset-0">
                                            <i role='img' aria-label='credit card' title='credit card' className="icon-Credit-Card-blue-size1" style={{ fontSize: "20px", color: this.state.cardTileColor }}></i>
                                        </div>

                                    </div>
                                </div>
                                <div className="row">
                                    <div className="action-number">
                                        <div className='useractionNumber' style={{ color: this.state.cardTileColor }}>{this.props.cardsTobeAssigned}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="actionfilterMarginTop">
                                        <div className='useractionTile' style={{ color: this.state.cardTileColor }}>
                                            {this.props.app_labels.cards}</div></div></div>
                                <div className="row">
                                    <div>
                                        <div className='useractionTile' style={{ color: this.state.cardTileColor }}>
                                            {this.props.app_labels.toBeAssigned}</div></div>
                                </div>
                            </Card>
                        </div>
                        <div className="col-sm-3 col-sm-offset-0 col-xs-6 col-xs-offset-0">
                            <Card tabindex='0' className="cardFocus cardShadow" style={{ cursor: "pointer", backgroundColor: this.state.itineraryTileColor === 'white' ? '#0A41C5' : 'white' }} onClick={() => {
                                this.setState({ cardTileColor: '#0A41C5', itineraryTileColor: 'white', notifyTileColor: '#0A41C5', closureTileColor: '#0A41C5' })
                                this.onClickofTile([userActionDescription.flightItinerary, userActionDescription.hotelItinerary])
                            }} bordered={false}
                                onKeyUp={e => {
                                    if (e.keyCode === 13) {
                                        this.onClickofTile([userActionDescription.flightItinerary, userActionDescription.hotelItinerary])
                                    }
                                }}
                            >
                                <div className="row">
                                    <div className="useaction-Icon">
                                        <div className="col-sm-1 col-sm-offset-0">
                                            <i role='img' aria-label='Flight' title='flight' className="icon-Airplane-blue-size-1" style={{ fontSize: "20px", color: this.state.itineraryTileColor }}></i>
                                        </div>

                                    </div>
                                </div>
                                <div className="row">
                                    <div className="action-number">
                                        <div className='useractionNumber' style={{ color: this.state.itineraryTileColor }}>{this.props.pendingItenaryUpload}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="actionfilterMarginTop">
                                        <div className='useractionTile' style={{ color: this.state.itineraryTileColor }}>
                                            {this.props.app_labels.pending}</div></div></div>
                                <div className="row">
                                    <div>
                                        <div className='useractionTile' style={{ color: this.state.itineraryTileColor }}>
                                            {this.props.app_labels.itineraryUpload}</div></div>
                                </div>
                            </Card>
                        </div>
                        <div className="col-sm-3 col-sm-offset-0 col-xs-6 col-xs-offset-0 marginTop">
                            <Card tabindex='0' className="cardFocus cardShadow" style={{ cursor: "pointer", backgroundColor: this.state.notifyTileColor === 'white' ? '#0A41C5' : 'white' }} onClick={() => {
                                this.setState({ cardTileColor: '#0A41C5', itineraryTileColor: '#0A41C5', notifyTileColor: 'white', closureTileColor: '#0A41C5' })
                                this.onClickofTile([userActionDescription.travelerNotified])
                            }} bordered={false}
                                onKeyUp={e => {
                                    if (e.keyCode === 13) {
                                        this.onClickofTile([userActionDescription.travelerNotified])
                                    }
                                }}
                            >
                                <div className="row">
                                    <div className="useaction-Icon">
                                        <div className="col-sm-1 col-sm-offset-0">
                                            <i role='img' aria-label='Notify' title='Notify' className="icon-Notify" style={{ fontSize: "20px", color: this.state.notifyTileColor }}></i>
                                        </div>

                                    </div>
                                </div>
                                <div className="row">
                                    <div className="action-number">
                                        <div className='useractionNumber' style={{ color: this.state.notifyTileColor }}>{this.props.travelersToBeNotified}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="actionfilterMarginTop">
                                        <div className='useractionTile' style={{ color: this.state.notifyTileColor }}>
                                            {this.props.app_labels.travelers}</div></div></div>
                                <div className="row">
                                    <div style={{ marginTop: "0%" }}>
                                        <div className='useractionTile' style={{ marginLeft: "4%", color: this.state.notifyTileColor }}>
                                            {this.props.app_labels.toBeNotified} </div></div>
                                </div>
                            </Card>
                        </div>
                        <div className="col-sm-3 col-sm-offset-0 col-xs-6 col-xs-offset-0 marginTop">
                            <Card tabindex='0' className="cardFocus cardShadow" style={{ cursor: "pointer", backgroundColor: this.state.closureTileColor === 'white' ? '#0A41C5' : 'white' }} onClick={() => {
                                this.setState({ cardTileColor: '#0A41C5', itineraryTileColor: '#0A41C5', notifyTileColor: '#0A41C5', closureTileColor: 'white' })
                                this.onClickofTile([userActionDescription.tripClosure])
                            }} bordered={false}
                                onKeyUp={e => {
                                    if (e.keyCode === 13) {
                                        this.onClickofTile([userActionDescription.tripClosure])
                                    }
                                }}
                            >
                                <div className="row">
                                    <div className="useaction-Icon">
                                        <div className="col-sm-1 col-sm-offset-0">
                                            <i role='img' aria-label='Tripclosure' title='Tripclosure' className="icon-Symbol-38--3" style={{ fontSize: "20px", color: this.state.closureTileColor }}></i>
                                        </div>

                                    </div>
                                </div>
                                <div className="row">
                                    <div className="action-number">
                                        <div className='useractionNumber' style={{ color: this.state.closureTileColor }}>{this.props.pendingTripClosure}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="actionfilterMarginTop">
                                        <div className='useractionTile' style={{ color: this.state.closureTileColor }}>
                                            {this.props.app_labels.pending}</div></div></div>
                                <div className="row">
                                    <div style={{ marginTop: "0%" }}>
                                        <div className='useractionTile' style={{ color: this.state.closureTileColor }}>
                                            {this.props.app_labels.tripClosure}</div></div>
                                </div>
                            </Card>
                        </div>
                    </div>
                </div >

                {/* all trip details for hiring manager */}
                < div className="row" ><br/>
                    <div className="col-xs-10 col-xs-offset-0 col-sm-6 col-sm-offset-1 col-md-6 col-md-offset-0">
                        <div className="action-filte-title" style={{ marginTop: "2%" }}>{this.props.app_labels.pendingActions}</div>
                    </div>
                </div >
                <div className="row">
                    <div className="pending-actions">
                          {/*<div id="scrolablediv" style={{ height: "60vh", overflow: "auto" }}>
                           <InfiniteScroll
                                dataLength={this.state.UserTripData.length}
                                next={this.fetchMoreData}
                                hasMore={true}
                                scrollableTarget="scrolablediv"
                                loader={this.state.requestSent === true ? <div style={{ textAlign: "center" }}><h5>Loading...</h5></div> : null}
                            > */}
                            <Table 
                                size="middle"
                                columns={columns}
                                scroll={{ x:950, y: '50vh' }}
                                /*  dataSource={this.props.allTripDetail} */
                                dataSource={this.state.UserTripData}
                                pagination={false}
                                onChange={this.handleTableChange}
                            />
                            {/*   </InfiniteScroll> */}
                       {/*  </div> */}
                    </div>
                </div>
            </div >
        )
    }
}

function mapStateToProps(state, props) {

    return {
        pendingTripClosure: state.getuserActionsReducer.pendingTripClosure,
        cardsTobeAssigned: state.getuserActionsReducer.cardsTobeAssigned,
        pendingItenaryUpload: state.getuserActionsReducer.pendingItenaryUpload,
        travelersToBeNotified: state.getuserActionsReducer.travelersToBeNotified,
        app_labels: state.adduserReducer.app_labels,

    };
}
function mapDispatchToProps(dispatch) {
    return {
        cleartripID: () => {
            dispatch(cleartripId());
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(UserAction);
