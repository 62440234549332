import React, { useState } from 'react';
import { connect } from 'react-redux'
import{ MenuItem, Menu, Button, FormControlLabel, Checkbox,Paper, InputAdornment, TextField} from '@mui/material';
import { Divider, Icon } from 'antd'

import  SearchIcon  from "@mui/icons-material/Search";



export function FilterByRoleBtn(props){

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [searchedValue, setSearchValue] = React.useState(props.filterRoles);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        if(open){
            setAnchorEl(null);
            // props.onClose();
        }else
            setAnchorEl(event.currentTarget);
      
    };
    const handleClose = () => {
      setAnchorEl(null);
      setSearchText('');
    };

    const setSearchText = (value) =>{
        let data = props.filterRoles;
        let roleArray;
        if(value === '')
            setSearchValue(data);
        else{
            roleArray = data.filter(o =>
                Object.keys(o).some(k =>
                  String(o[k])
                    .toLowerCase()
                    .includes(value.toLowerCase())
                )
            );
            setSearchValue(roleArray);
        }
    }

    return(
        <>
        <div style={{fontStyle:'U.S.BankCircularWeb-Regular'}}>
            <Button
                className={props.userRole === 'Super Admin' ||  props.userRole === 'Customer Support' ? "superadmin dropdown-btn" :"dropdown-btn ViewNEditBtn"}
                id="filter-role-button"
                disableRipple
                disableElevation
                style={{ height: 30, minWidth: props.userRole === 'Super Admin' ||  props.userRole === 'Customer Support' ? 24 : 44, borderBottom: "none", textTransform: "none",
                    border: "1px solid #666666" , borderRadius: "4px", color:"#333333",
                    marginLeft: props.userRole === 'Super Admin' ||  props.userRole === 'Customer Support' ? null : "-15%", backgroundColor: "white", fontSize: 12,
                    fontFamily: "U.S.BankCircularWeb-Regular"
                 }}
                aria-label="filter by role"
                aria-controls={open ? 'filter-role-menu' : undefined}
                aria-haspopup="true"
                // aria-expanded={open ? 'true' : 'false'}
                variant="contained"
                //disableElevation
                onClick={handleClick}
                endIcon={<Icon aria-hidden="true" role="img" style={{  marginLeft: '20px', fontSize: 12 }} type="down" />}
                >
                    <span style={{fontSize: props.userRole === 'Super Admin' ||  props.userRole === 'Customer Support' ? 11 : null}}>{props.app_labels.filterByRole}</span>
                </Button>
                <Menu
                    id="filter-role-menu"
                    anchorEl={anchorEl}
                    open={open}
                    // onClick={handleClose}
                    onClose={handleClose}
                    MenuListProps={{
                    'aria-labelledby': 'filter-role-button',
                    }}
                    PaperProps={{
                        style: {
                          width: props.userRole === 'Super Admin' ||  props.userRole === 'Customer Support' ? '15%' : '12%', height: props.userRole === 'Super Admin' ||  props.userRole === 'Customer Support' ? '35%' : null
                        },
                      }}
                >
                    {props.userRole === "Super Admin" ||  props.userRole === 'Customer Support'?
                        <div style={{position: 'sticky', top: 0, zIndex: 5}}>
                            <Paper style={{boxShadow: 'none', borderRadius: 'none'}}>
                                <TextField
                                    className="search-menuItem"
                                    size="small"
                                    autoFocus
                                    variant='standard'
                                    placeholder="Search Roles"
                                    InputProps={{
                                        endAdornment: (
                                        <InputAdornment position="end">
                                            <SearchIcon />
                                        </InputAdornment>
                                        )
                                    }}
                                    onChange={(e) => setSearchText(e.target.value)}
                                    onKeyDown={(e) => {
                                        if (e.key !== "Escape") {
                                        // Prevents autoselecting item while typing (default Select behaviour)
                                        e.stopPropagation();
                                        }
                                    }}
                                />
                            </Paper>
                        </div>
                        : null
                    }
                    {searchedValue.map((item) =>{
                        return <MenuItem role="menuitem" tabIndex={0} aria-label={item.text + (props.selectedFilterValue && props.selectedFilterValue.includes(item.value)?" checked" : " unchecked")} onClick={(e) => props.onSelectValue(item.text)} divider = {props.userRole === "Super Admin" ||  props.userRole === 'Customer Support' ? false : (item.text === 'Approver' ? true : false)}  key={item.value} 
                                >
                                <div style={{fontSize: 13, fontFamily: 'U.S.BankCircularWeb-Regular'}} >
                                    { (props.selectedFilterValue && props.selectedFilterValue.includes(item.value)) ? <Icon aria-hidden="true" type="check-square"  className='checked-checkbox-icon' theme="twoTone" twoToneColor="#19398A" /> 
                                    : <Icon type="border" aria-hidden="true" className='checkbox-icon' 
                                    style={{fontSize: 13}} />}
                                    &nbsp;&nbsp;{item.text}
                                </div>
                        </MenuItem>
                        }
                    )}
                    {/* </FormControl> */}
                
                
                </Menu>
                </div>
        </>
    )
}


export function FilterByCompanyBtn(props){

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [searchedValue, setSearchValue] = React.useState(props.activeCompanyName);
    const open = Boolean(anchorEl);
    
    const handleClick = (event) => {
        setSearchValue(props.activeCompanyName);
        if(open){
            setAnchorEl(null);
            // props.onClose();
        }else
            setAnchorEl(event.currentTarget);
      
    };
    const handleClose = () => {
      setAnchorEl(null);
      setSearchText('');
    };

    const setSearchText = (value) =>{
        let data = props.activeCompanyName;
        let companyArray;
        if(value === '')
            setSearchValue(data);
        else{
            companyArray = data.filter(o =>
                Object.keys(o).some(k =>
                  String(o['companyName'])
                    .toLowerCase()
                    .includes(value.toLowerCase())
                )
            );
            setSearchValue(companyArray);
        }
    }

    return(
        <>
        <div style={{fontStyle:'U.S.BankCircularWeb-Regular'}}>
            <Button
                className="comp-dropdown-btn"
                id="filter-role-button"
                disableRipple
                disableElevation
                style={{ height: 30, minWidth: 44, borderBottom: "none", textTransform: "none",
                    border: "1px solid #666666" , borderRadius: "4px", color:"#333333",
                    marginLeft: "-18%", backgroundColor: "white", fontSize: 12,
                    fontFamily: "U.S.BankCircularWeb-Regular"
                 }}
                aria-label="filter by role"
                aria-controls={open ? 'filter-role-menu' : undefined}
                aria-haspopup="true"
                //aria-expanded={open ? 'true' : 'false'}
                variant="contained"
                //disableElevation
                onClick={handleClick}
                endIcon={<Icon aria-hidden="true" role="img" style={{  marginLeft: '10px', fontSize: 12 }} type="down" />}
                >
                    <span style={{fontSize: props.userRole === 'Super Admin' ||  props.userRole === 'Customer Support' ? 11 : null}}>{props.app_labels.filterBy} {props.app_labels.company}</span>
                </Button>
                <Menu
                    id="filter-role-menu"
                    anchorEl={anchorEl}
                    open={open}
                    // onClick={handleClose}
                    onClose={handleClose}
                    MenuListProps={{
                    'aria-labelledby': 'filter-role-button',
                    }}
                    PaperProps={{
                        style: {
                          width: '20%', height: '35%'
                        },
                      }}
                >
                    <div style={{position: 'sticky', top: 0, zIndex: 5}}>
                        <Paper style={{boxShadow: 'none', borderRadius: 'none'}}>
                            <TextField
                                className="search-menuItem"
                                size="small"
                                variant='standard'
                                placeholder="Search Company"
                                InputProps={{
                                    endAdornment: (
                                    <InputAdornment position="end">
                                        <SearchIcon />
                                    </InputAdornment>
                                    )
                                }}
                                onChange={(e) => setSearchText(e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.key !== "Escape") {
                                    // Prevents autoselecting item while typing (default Select behaviour)
                                    e.stopPropagation();
                                    }
                                }}
                            />
                        </Paper>
                    </div>

                    {searchedValue.map(item =>{
                        return <div> 
                                <MenuItem role="menuitemcheckbox" aria-checked={props.selectedFilterValue && props.selectedFilterValue.includes(item.companyName)} key={item.companyName} 
                                    onClick={(e) => props.onSelectValue(item.companyName)} value={item.companyName}>
                                    <div style={{fontSize: 13, fontFamily: 'U.S.BankCircularWeb-Regular'}} aria-assertive="true">
                                        { (props.selectedFilterValue && props.selectedFilterValue.includes(item.companyName)) ? <Icon role="img" aria-hidden="true" type="check-square"  className='checked-checkbox-icon' theme="twoTone" twoToneColor="#19398A" /> 
                                        : <Icon type="border" role="img" aria-hidden="true" className='checkbox-icon' 
                                        style={{fontSize: 13}} />}
                                        &nbsp;&nbsp;{item.companyName}
                                    </div>
                                </MenuItem>
                            </div>
                        }
                    )}
                    {/* </FormControl> */}
                
                
                </Menu>
                </div>
        </>
    )
}