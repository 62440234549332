import React from 'react';
// import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import axios from 'axios';
import $ from 'jquery';
import { Select, Spin, notification } from 'antd';
import { loaderFocus } from '../../../utils/accessability';
import '../../../styling/expenseCat.css';
// import '../../../styling/createtrip.css';
const { URLs, errorMessages } = require('../../../data/constants.js');
// const { app_labels } = require('../../../data/english_label');
const Option = Select.Option;
class AddNewExpCat extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            addExpSpin: false,
            dropdownChildren: [],
            companyIds: [],
            typeOfField: "StringValue",
            category: this.props.expenseCat.length !== 0 ? this.props.expenseCat[0].expCategoryId : "",
            expCategory: undefined,
            crExpCategory: "",
            transtype: this.props.transactionType.length !== 0 ? this.props.transactionType[0].transactionType : "",
            transtypeId: this.props.transactionType.length !== 0 ? this.props.transactionType[0].transactionTypeId : "",
            formFlags: 1,
            questionApplicable: 'yes',
            filteredCategoryRes: null,
            sendCompany: null
        }
        //create Refs
        this.usreDefField = React.createRef()
        this.formFlags = React.createRef()
        this.question = React.createRef()
        this.quesApp = React.createRef()
    }

    /**
     * Lifecycle method
     * will be called onMount of the component
     */
    componentDidMount() {
        let childDropdown = [];
        this.props.activeCompanyName.map(item =>
            childDropdown.push(<Option value={item.companyId}>{item.companyName}</Option>)
        )
        this.setState({ dropdownChildren: childDropdown }) 
        // prepare the icons
        let element = $('.ant-select-arrow-icon')
        element.empty()
        element.removeClass().addClass('icon-Drop-down')
        let uniqueExpenseCat = this.props.expenseCat.reduce((accumulator, item) => {
            if (accumulator.findIndex(el => el.companyId === item.companyId && el.cadreCategory === item.cadreCategory) === -1) {
                accumulator.push(item)
            }
            return accumulator
        }, []) 
        let filteredCategory = []
        let compareComparyId = this.props.selectedCompanyId ? this.props.selectedCompanyId : this.state.companyIds
        uniqueExpenseCat.forEach(item => {
            if(item.companyId === parseInt(compareComparyId)) {
                filteredCategory.push(item)
            }
        })
        this.setState({ 
            filteredCategoryRes: filteredCategory, 
            companyIds: compareComparyId,
            sendCompany: this.props.selectedCompanyName  })
    }

    /**
     * Render the required columns on selecting a company
     */
    companyChange = (value, e) => {
        this.setState({ companyIds: value })
        this.resetForm()
        let uniqueExpenseCat = this.props.expenseCat.reduce((accumulator, item) => {
            if (accumulator.findIndex(el => el.companyId === item.companyId && el.cadreCategory === item.cadreCategory) === -1) {
                accumulator.push(item)
            }
            return accumulator
        }, []) 
        let filteredCategory = []
        uniqueExpenseCat.forEach(item => {
            if(item.companyId === value) {
                filteredCategory.push(item)
            }
        })
        let sendCompany = this.props.activeCompanyName.find(item => item.companyId === value)
        this.setState({ filteredCategoryRes: filteredCategory, sendCompany: sendCompany.companyName })
    }
    /**
    * @param  {} element
    highlighting the field when its epmty or not valid
    */
    highlightInvalidField(element) {
        let label = $(element).siblings('.text-class')
        label = label.length === 0 ? $(element).find('.text-class') : label
        label = label.length === 0 ? $(element).closest('.form-group').find('.text-class') : label
        label.addClass('has-error')
        element.focus()
    }
    /**
    * @param  {} element
    *removing the error class applied during validation on change of event
    */
    clearInvalidField(element) {
        let label = $(element).siblings('.text-class')
        label = label.length === 0 ? $(element).find('.text-class') : label
        label = label.length === 0 ? $(element).closest('.form-group').find('.text-class') : label
        label.removeClass('has-error')
    }
    /**
     *Form validation(all the mandatory fields should be present)
     */
    validateForm() {
        const crCategorySelector = document.getElementsByClassName('crCategory');
        const expCategorySelector = document.getElementsByClassName('expCategory');
        const transTypeSelector = document.getElementsByClassName('transType');
        const CompanyClassSelector = document.getElementsByClassName('CompanyClassSelector');
        [this.usreDefField.current,
            crCategorySelector,
            expCategorySelector,
            CompanyClassSelector,
            transTypeSelector,
        this.question.current].forEach(el => this.clearInvalidField(el))
        if (this.state.companyIds.length === 0) {
            notification.warning({
                message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{errorMessages.error_req_company}</span>,
                duration: 3,
                style: {
                    backgroundColor: '#fffbe6',
                    border: "1px solid #ffe58f",
                }
            });
            let label = $(CompanyClassSelector).siblings('.text-class')
            label.addClass('has-error');
            return false;
        }
        if (this.state.category === null || this.state.category === undefined || this.state.category === "" || this.state.category.length === 0) {
            notification.warning({
                message: <span  role='alert' aria-live="assertive" aria-atomic="true" ><span className="visually-hidden">Error </span>{errorMessages.error_req_expCat}</span>,
                duration: 3,
                style: {
                    backgroundColor: '#fffbe6',
                    border: "1px solid #ffe58f",
                }
            });
            let label = $(expCategorySelector).siblings('.text-class')
            label.addClass('has-error');
            return false;
        }
        if (this.state.crExpCategory === null || this.state.crExpCategory === undefined || this.state.crExpCategory === "" || this.state.crExpCategory.length === 0) {
            notification.warning({
                message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{errorMessages.error_req_crExpCat}</span>,
                duration: 3,
                style: {
                    backgroundColor: '#fffbe6',
                    border: "1px solid #ffe58f",
                }
            });
            let label = $(crCategorySelector).siblings('.text-class')
            label.addClass('has-error');
            return false;
        }
        if (this.props.transactionType === null || this.props.transactionType === undefined || this.props.transactionType === "" || this.props.transactionType.length === 0) {
            notification.warning({
                message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{errorMessages.error_req_transactionType}</span>,
                duration: 3,
                style: {
                    backgroundColor: '#fffbe6',
                    border: "1px solid #ffe58f",
                }
            });
            let label = $(transTypeSelector).siblings('.text-class')
            label.addClass('has-error');
            return false;
        }
        if (!(this.usreDefField.current.value && this.usreDefField.current.value.trim().length > 0)) {
            notification.warning({
                message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{errorMessages.error_req_userDefinedField}</span>,
                duration: 3,
                style: {
                    backgroundColor: '#fffbe6',
                    border: "1px solid #ffe58f",
                }
            });
            this.highlightInvalidField(this.usreDefField.current)
            return false;
        }
        if (!(this.question.current.value && this.question.current.value.trim().length > 0)) {
            notification.warning({
                message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{errorMessages.error_req_question}</span>,
                duration: 3,
                style: {
                    backgroundColor: '#fffbe6',
                    border: "1px solid #ffe58f",
                }
            });
            this.highlightInvalidField(this.question.current)
            return false;
        }
        return true;
    }
    /**
     * onclick of reset,need to reset the Form
     */
    resetForm = () => {
        [this.usreDefField.current,
        this.question.current].forEach(el => this.clearInvalidField(el));
        this.setState({ 
            expCategory: undefined, 
            category: '', 
            crExpCategory: '', 
            formFlags: 1, 
            transtype: 'AUTH', 
            questionApplicable: 'Yes' 
        });
        this.usreDefField.current.value = "";
        this.question.current.value = "";
    }

    /**
     * allows to add news Expense Category
     */
    addExpCategory = () => {
        let valid = this.validateForm();
        if (!valid) {
            return;
        }
        let expMapObj = {
            "expCategoryId": parseInt(this.state.category),
            "transactiontype": 0,
            "userDefinedFields": this.usreDefField.current.value,
            "typeOfField": this.state.typeOfField,
            "formFlag": parseInt(this.state.formFlags),
            "question": this.question.current.value,
            "applicable": this.state.questionApplicable,
            "companyId": this.state.companyIds + '',
        };
        this.setState({ addExpSpin: true })
        loaderFocus(".loader-color .ant-spin-text");  
        axios.post(URLs.companyUrl + "addExpenseCategoryMap ", expMapObj, {
            headers: {
                "Content-Type": 'application/json',
                "Authorization": 'Bearer ' + this.props.token.access_token
            }
        }).then(response => {
            if (response.data.serviceStatus.statusCode === 200) {
                /*  this.props.getAllExpCategoryMap(this.props.companyId);
                 this.props.addNewExpCat(false, true); */
                this.setState({ addExpSpin: false })
                this.resetForm()
                notification.success({
                    message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Success </span>{response.data.serviceStatus.message}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#dff0d8',
                        color: "green",
                        border: "1px solid green",
                    }
                });
            }
            else {
                this.setState({ addExpSpin: false })
                notification.error({
                    message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{errorMessages.error_addExpCat}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }
        })
        .catch(error => {
            this.setState({ addExpSpin: false })
            if (error.response !== undefined && error.response.data !== null && error.response.data !== undefined && error.response.data.serviceStatus !== undefined) {
                notification.error({
                    message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{error.response.data.serviceStatus.message}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }
            else {
                notification.error({
                    message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{errorMessages.generic_error}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }
        })
    }
    render() {
        return (
            <div style={{ width: "100%" }} className="container selectClasses">
                <br /><br /><div className='row'>
                    <div className="col-sm-3 col-sm-offset-0 margibnLeftExpCat">
                        <span className="text-class">{this.props.app_labels.applicableCompanyNames}</span><br /><br />
                        <Select className='CompanyClassSelector'
                            // mode="multiple"
                            // showArrow={true}
                            // allowClear={true}
                            defaultValue={this.props.selectedCompanyName ? this.props.selectedCompanyName : this.state.selectedCompanyName}
                            style={{ width: '100%' }}
                            placeholder="Select Company Name"
                            onChange={this.companyChange}
                        >
                            {this.state.dropdownChildren}
                        </Select>
                    </div>
                </div><br /><br />

                <div className="row" >
                    <div className="col-sm-3 col-sm-offset-0 rowspace margibnLeftExpCat">
                        <span className="text-class">{this.props.app_labels.expenseWizardCategory}</span><br /><br />
                        <Select className="expCategory"
                            value={this.state.expCategory}
                            style={{ width: '100%' }} 
                            placeholder="Select Expense Category"
                            onChange={(value) => {
                                let expCategorySelector = document.getElementsByClassName('expCategory');
                                this.clearInvalidField(expCategorySelector)
                                this.setState({ expCategory: value[0], category: value[1], crExpCategory: value[2] })
                            }}>
                            {this.state.filteredCategoryRes && this.state.filteredCategoryRes.map((item,index) =>
                                <Option value={[item.cadreCategory, item.expCategoryId, item.crCategory]} key={index}>{item.cadreCategory}</Option>)}
                        </Select>
                    </div>

                    <div className="col-sm-3 col-sm-offset-1 rowspace">
                        <span className="text-class">{this.props.app_labels.crExpenseCategory}</span><br /><br />
                        <span className='input-text' >{this.state.crExpCategory}</span>
                         {/* <Select className="crCategory" value={this.state.crExpCategory} style={{ width: '100%' }} onChange={(value) => {
                            let crCategorySelector = document.getElementsByClassName('crCategory');
                            this.clearInvalidField(crCategorySelector)
                            this.setState({ crExpCategory: value })
                        }}>
                            {this.props.crExpCat.map(item =>
                                <Option value={item.crExpCategoryId}>{item.expCategoryDesc}</Option>)}
                        </Select> */}
                    </div>
                    <div className="col-sm-3 col-sm-offset-1 rowspace">
                        <span className="text-class">{this.props.app_labels.formFlags}</span><br /><br />
                        <Select value={this.state.formFlags} style={{ width: '100%' }} onChange={(value) => {
                            this.setState({ formFlags: value })
                        }}>
                            <Option value={1}>{this.props.app_labels.form1}</Option>
                            <Option value={2}>{this.props.app_labels.form2}</Option>
                            <Option value={3}>{this.props.app_labels.form3}</Option>
                            <Option value={4}>{this.props.app_labels.form4}</Option>
                            <Option value={5}>{this.props.app_labels.form5}</Option>
                            <Option value={0}>{this.props.app_labels.na}</Option>
                        </Select>
                    </div>
                </div>
                {this.state.addExpSpin ? <div className="dark-spinner loader-color"><Spin tip="Loading..." /></div> : null}
                <br />
                <div className="row rowspaceexp" >
                    <div className="col-sm-6 col-sm-offset-0 margibnLeftExpCat">
                        <span className="text-class">{this.props.app_labels.userDefineFields}</span><br /><br />
                        <input type="text" ref={this.usreDefField} onChange={(e) => this.clearInvalidField(e.target)} />
                    </div>
                    <div className="col-sm-3 col-sm-offset-2 rowspace ">  <span className="text-class">{this.props.app_labels.typeOfField}</span><br /><br />
                        <Select className="transType" value={this.state.typeOfField}
                            style={{ width: '100%' }}
                            Placeholder="Select type of field"
                            onChange={(value) => {
                                let transTypeSelector = document.getElementsByClassName('transType');
                                this.clearInvalidField(transTypeSelector)
                                this.setState({ typeOfField: value })
                            }} >
                            <Option value='BooleanValue'>Boolean</Option>
                            <Option value='DateValue'>Date</Option>
                            <Option value='EntityValue'>Entity</Option>
                            <Option value='NumberValue'>Number</Option>
                            <Option value='RADIO'>Radio</Option>
                            <Option value='StringValue'>String</Option>
                            <Option value='TEXT'>Text</Option>

                        </Select>
                       
                    </div>
                </div>
                <br />
                <div className="row rowspaceexp" >
                    <div className="col-sm-6 col-sm-offset-0 margibnLeftExpCat">
                        <span className="text-class">{this.props.app_labels.question}</span><br /><br />
                        <input type="text" ref={this.question} onChange={(e) => this.clearInvalidField(e.target)} />
                    </div>
                    <div className="col-sm-3 col-sm-offset-2 rowspace">
                    <span className="text-class">{this.props.app_labels.questionApplicable}</span><br /><br />
                        <Select value={this.state.questionApplicable} style={{ width: '100%' }} onChange={(value) => {
                            this.setState({ questionApplicable: value })
                        }}>
                            <Option value='yes'>Yes</Option>
                            <Option value='no'>No</Option>
                        </Select>

                    </div>

                    {/* <div className="col-sm-3 col-sm-offset-2 rowspace">
                        <span className="text-class">Question Applicable</span><br /><br />
                        <Select value={this.state.questionApplicable} style={{ width: '100%' }} onChange={(value) => {
                            this.setState({ questionApplicable: value })
                        }}>
                            <Option value='yes'>Yes</Option>
                            <Option value='no'>No</Option>
                        </Select>
                    </div> */}
                </div>
                <div className="row rowspaceexp" >
                    <div className="col-xs-12 col-sm-4 col-sm-offset-0 col-md-4 col-md-offset-0" style={{ textAlign: 'left' }}>
                        <button type="button" className="button-wrapclass button-createtrip-ghost" style={{ marginTop: '1%', marginBottom: '1%' }} onClick={() => this.props.changeComponent(false, this.state.companyIds, this.state.sendCompany)}><b>{this.props.app_labels.back}</b></button>
                    </div>
                    <div className="col-xs-12 col-sm-8 col-sm-offset-0 col-md-8 col-md-offset-0" style={{ textAlign: 'right' }}>
                        <button type="button" className="buttonRightPadding button-wrapclass button-createtrip-ghost" style={{ marginTop: '1%', marginBottom: '1%' }} onClick={this.resetForm}><b>{this.props.app_labels.reset}</b></button>
                        <button type="button" className="button-wrapclass button-createtrip" style={{ marginTop: '1%', marginBottom: '1%' }} onClick={this.addExpCategory}><b>{this.props.app_labels.saveCapital}</b></button>
                    </div>
                </div>
            </div>
        )

    }
}
function mapStateToProps(state, props) {
    return {
        token: state.authenticationReducer.token,
        app_labels: state.adduserReducer.app_labels
    };
}
export default connect(mapStateToProps)(AddNewExpCat);