import React from 'react'
import { Row, Col, Table, Alert } from 'antd'
import TransactionDetailsForm from "./transactionDetailsForm"

function TransactionTable(props) {
  let columns = Object.keys(props.transactionData).map(columnName => ({
    title: columnName,
    dataIndex: columnName,
    key: columnName,
  }))

  return (
    <Row>
      <Col span={24} style={{ overflow: 'scroll' }}>
        <Table size='small'
          columns={columns}
          pagination={false}
          dataSource={[props.transactionData]}
          rowKey={(record) => JSON.stringify(record)}
          style={{ tableLayout: 'fixed', minWidth: '500px' }}
        />
      </Col>
    </Row>
  )
}

class Body extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isFormEditable: false
    }
  }
  render() {
    return (
      <div className='body'>
        <TransactionTable transactionData={this.props.transactionData} />
        {
          this.props.transactionQuestionnaire && this.props.transactionQuestionnaire.length > 0 ? (
            <Row>
              <Col span={24} style={{ padding: '10px', fontSize: '16px', fontWeight: 'bold' }}>
                <span>
                  Update transaction details
              </span>
                <span style={{ marginLeft: '5px' }}>
                  <i className="icon-Edit" style={{ color: this.state.isFormEditable === false ? '#112A70' : '#2929F5', cursor: 'pointer' }}
                    onClick={() => {
                      this.setState(() => ({
                        isFormEditable: !this.state.isFormEditable
                      }))
                    }}
                  ></i>
                </span>
              </Col>
              <Col span={24} style={{ padding: '10px' }}>
                <div className="update-transaction-status-container">
                  {this.props.updateError ? <div className='update-transaction-error'>
                    <Alert
                      message={`Error`}
                      description={this.props.updateError.message}
                      type="error"
                    />
                  </div> : this.props.updateSuccess ? <div className='update-transaction-failure'>
                    <Alert
                      description='Transaction details have been updated'
                      type="success"
                    />
                  </div> : ''}
                </div>
              </Col>

              <Col span={24}>
                <TransactionDetailsForm transactionQuestionnaire={this.props.transactionQuestionnaire}
                  expenseCategory={this.props.transactionData['Expense Category']}
                  isEditable={this.state.isFormEditable}
                  isUpdating={this.props.isUpdating}
                  updateError={this.props.updateError}
                  updateSuccess={this.props.updateSuccess}
                  updateTransactionDetails={this.props.updateTransactionDetails}
                />
              </Col>
            </Row>
          ) : null
        }
      </div>
    )
  }
}

export default Body