import React from 'react'
import PropTypes from 'prop-types';
import { Row, Col, Input, Button, message, Icon } from 'antd'
import schema from 'async-validator';
import MediaQuery from 'react-responsive';
import './search.css'

class Search extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      transactionIdInput: null
    }
    this.descriptor = {
      transactionId: [
        { type: 'string', required: true, message: 'Transaction Id cannot be empty' },
        {
          validator(rule, value, callback, source, options) {
            let errors = []
            if (value.trim().length === 0)
              errors.push(new Error('Transaction Id cannot be empty'))
            callback(errors)
          }
        }
      ]
    }
    this.validator = new schema(this.descriptor)
  }

  validateInput(obj) {
    return new Promise((resolve, reject) => {
      this.validator.validate(obj, {
        first: true
      }, (errors, fields) => {
        if (errors) {
          let error = errors[0]
          reject(error instanceof Error ? error : new Error(error.message));
        }
        else resolve()
      });
    })
  }

  handleInput(value) {
    this.setState(() => ({
      transactionIdInput: value
    }))
  }

  async handleSearch() {
    try {
      const obj = {
        transactionId: this.state.transactionIdInput
      }
      await this.validateInput(obj)
      this.props.fetchTransactionDetails(obj)
    } catch (err) {
      message.error(err.message)
    }
  }

  render() {
    return (
      <Row style={{ padding: '10px 20px' }} className='search'>
        <Col
          xs={{ span: 16 }}
          sm={{ span: 16 }}
          md={{ span: 12 }}
          lg={{ span: 12 }}
          xl={{ span: 8 }}
        >
          <Input.Search
            className="transaction-search-input"
            placeholder="Search by Transaction id"
            onChange={e => this.handleInput(e.target.value)}
            onSearch={value => this.handleSearch()}
            style={{ width: '85%' }}
            value={this.state.transactionIdInput}
          />
          {this.props.visiblity ? 
            <Icon 
              style={{ marginLeft: "10px", fontSize: "16px"}} 
              type="undo"
              onClick={() => {
                this.props.resetTransactionDetails()
                this.setState({ transactionIdInput: null })
              }} 
            /> 
          :  null}
        </Col>
        <Col
          xs={{ span: 8 }}
          sm={{ span: 8 }}
          md={{ span: 12 }}
          lg={{ span: 12 }}
          xl={{ span: 16 }}
          style={{ textAlign: 'right' }}
        >
          <MediaQuery minDeviceWidth={576}>
            <Button className="send-email-button">
              Send email
            </Button>
          </MediaQuery>
          <MediaQuery maxDeviceWidth={575}>
            <Button shape="circle" className="send-email-button">
              <i className="icon-Notification"></i>
            </Button>
          </MediaQuery>
        </Col>
      </Row>
    )
  }
}

Search.propTypes = {
  fetchTransactionDetails: PropTypes.func.isRequired
}

export default Search