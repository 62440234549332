import React from 'react'
import Header from '../../TripTravelerInformationContainer/header'
import Body from './body'

class ChatHistory extends React.Component {
  render() {
    return (
      <div className='transaction-chat-history'>
        <Header text={`Chat History`} />
        <Body transactionChatHistory={this.props.transactionChatHistory}/>
      </div>
    )
  }
}

export default ChatHistory