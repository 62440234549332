import React, { useState } from 'react'
import { connect } from 'react-redux'
import {  Dropdown,  Icon } from 'antd';
import{ MenuItem, Menu, Button} from '@mui/material';
import 'antd/dist/antd.css'
// import '../../styling/provisioner.css'
import CreateCard from './CreateCard'
import BulkUploadComponent from '../bulk/bulkUpload';
const { bulkFeature } = require('../../data/constants.js')

// Accessbility To invoke click method on tab enter.
function onKeyDown(event) {
    if (event.keyCode === 13) {
        // event.preventDefault()
        // document.activeElement.click();
    }

}

function CreateCardBtn(props) {

    const [visible, setvisible] = useState(false);
    const [bulkvisible, setBulkvisible] = useState(false);
    const [selectedLang, setselectedLang] = useState('EN');
    const [previousActiveElement, setPreviousActiveElement] = useState(null)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      const btn = document.getElementById("create-card-button")
      setAnchorEl(event.currentTarget);
      setPreviousActiveElement(btn);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };


    return (
        <>
            {bulkFeature.enable ?
            //  <Dropdown overlay={menu} placement='bottomCenter' trigger={['click']}>

            //     <Button  tabIndex={6} onKeyDown={onKeyDown.bind(this)}   className="request_button ml-10" style={{ backgroundColor: '#39bf71', height: '40px' }}>
            //         <Icon aria-hidden="true" style={{ color: 'white' }} type="plus-circle" />{props.label_keys.createCard}</Button>
            // </Dropdown>
            <div style={{fontStyle:'U.S.BankCircularWeb-Regular'}}>
            <Button
                className="create-card-button"
                id="create-card-button"
                tabIndex={0} 
                disableRipple
                disableElevation
                // style={{ backgroundColor: '#39bf71', height: '40px', 
                // fontSize:'16px', top:'7px', fontWeight: "500", marginLeft:"10px", 
                //  textTransform: 'none',
                // fontFamily: "U.S.BankCircularWeb-Bold", letterSpacing:'1px'  }}
                aria-controls={open ? 'create-card-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant="contained"
                onClick={handleClick}
                endIcon={<Icon aria-hidden="true" role="img" style={{ color: 'white', marginLeft: '20px', fontSize: 13 }} type="down" />}
                >
                    <span style={{marginRight: '10px'}}>{props.label_keys.createCard}</span>
                </Button>
                <Menu
                    id="create-card-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClick={handleClose}
                    onClose={handleClose}
                    MenuListProps={{
                    'aria-labelledby': 'create-card-button',
                    }}
                    PaperProps={{
                        style: {
                          width: '15%',
                        },
                      }}
                    // style={{ widt }}
                >
                    <MenuItem  key="0"className="CreateCard-menuItem" onKeyDown={onKeyDown.bind(this)}  
                    onClick={() => {
                        handleClose();
                        setTimeout(() =>{setvisible(!visible)},100);
                    }}>
                    <div >{props.label_keys.singleCard}</div>
                    </MenuItem>
                    <MenuItem className="CreateCard-menuItem" key="1" onKeyDown={onKeyDown.bind(this)}   onClick={() => {
                        props.tabsClick(props.label_keys.bulkCardCreation)
                    }}>
                        <div  >{props.label_keys.bulkCard}</div>
                    </MenuItem>
                </Menu>
                </div>   
                : 
                <Button
                id="create-card-button"
                tabIndex={0} 
                disableRipple
                disableElevation
                style={{ backgroundColor: '#39bf71', height: '40px', 
                fontSize:'16px', top:'7px', fontWeight: "500", marginLeft:"10px", 
                 textTransform: 'none',
                fontFamily: "U.S.BankCircularWeb-Bold", letterSpacing:'1px'  }}
                onClick={() => {
                    setvisible(!visible)
                }}
                startIcon={<Icon aria-hidden="true" role="img" style={{ color: 'white', marginRight:'12px' }} type="plus-circle" />}
                >
                    <span style={{marginRight: '10px'}}>{props.label_keys.createCard}</span>
                </Button>}
            <CreateCard
                setFocus={true}
                createVisible={visible}
                onCLoseCreate={() => {
                    setvisible(!visible)
                    setTimeout(() =>{document.getElementById('create-card-button').focus()})
                }}
                onCLoseCreatePreview={() => {
                    setvisible(!visible)
                }}

                reloadUserList={() => props.removeSearch()}
                label_keys={props.label_keys}
                reloadParent={() => props.getProvisionerRequestData()}
                previousActiveElement={previousActiveElement}
            />
            {bulkvisible ? <BulkUploadComponent label_keys={props.label_keys} /> : null}
        </>
    )
}

function mapStateToProps(state, props) {
    return {
        userDetails: state.authenticationReducer.userDetails,
        token: state.authenticationReducer.token,
        iclanguageList: state.instantCardMultiLanReducer.iclanguageList,
        //   app_labels: state.adduserReducer.app_labels,
        //   label_keys: state.instantCardMultiLanReducer.label_keys
    }
}

const mapDispatchToProps = dispatch => {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateCardBtn)

