import * as React from "react"

export function Counter() {
  const [counter, setCounter] = React.useState(30)
  React.useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000)
  }, [counter])

  return (
      <span role="timer" aria-atomic="true" aria-live={`${counter%5 === 0 ? 'assertive' : 'off'}`} className="counter">
        {counter < 10 ? `0${counter}` : counter} 
        <span className="visually-hidden">Seconds left for resend code button to enable</span>
      </span>
  )
}