import axios from 'axios';
import React from 'react';
import { notification } from 'antd';
import { getTrips } from './gettripsActions';
import { getUserActions } from './userAction';
import { getAllDetails } from './getAllDetails';
const { URLs,errorMessages } = require('../data/constants.js');

/**
 * updates the trip status information
 * @param {*} tripID 
 * @param {*} status 
 * @param {*} cadreID 
 * @param {*} hyperlinkFunc 
 */
export function tripStatus(tripID, status, cadreID, hyperlinkFunc) {
  const obj = {
    "tripId": tripID,
    "requestType": status,
    "travelerId": cadreID
  }
  return (dispatch, getState) => {
    let {
      token
    } = getState().authenticationReducer;


    axios.post(URLs.tripUrl + 'updateTripStatusInfo', obj, {
      responseType: 'json',
      headers: {
        "Authorization": 'Bearer ' + token.access_token,
        "Content-Type": 'application/json',
      }
    }).then(result => {
      if (result.data.serviceStatus.statusCode === 202) {
        notification.warning({
          message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Error </span>{result.data.serviceStatus.message}</span>,
          duration: 3,
          style: {
            backgroundColor: '#fffbe6',
            border: "1px solid #ffe58f",
          }
        });
      }
      else if (result.data.serviceStatus.statusCode === 201) {
        notification.warning({
          message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Error </span>{result.data.serviceStatus.message}</span>,
          duration: 3,
          style: {
            backgroundColor: '#fffbe6',
            border: "1px solid #ffe58f",
          }
        });
      }
      else if (result.data.serviceStatus.statusCode === 210) {
        notification.error({
          message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Error </span>{result.data.serviceStatus.message}</span>,
          duration: 3,
          style: {
            backgroundColor: '#fff1f0',
            border: "1px solid #ffa39e",
          }
        });
      }
      else if (result.data.serviceStatus.statusCode === 200) {
        dispatch(getTrips());
        dispatch(getUserActions());
        dispatch(getAllDetails());
        if (hyperlinkFunc !== undefined && hyperlinkFunc !== null) {
          hyperlinkFunc("");
        }
        notification.success({
          message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Success </span>{result.data.data.message}</span>,
          duration: 3,
          style: {
            backgroundColor: '#dff0d8',
            color: "green",
            border: "1px solid green",
          }
        });

      }
      else {
        notification.error({
          message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Error </span>{result.data.data.message}</span>,
          duration: 3,
          style: {
            backgroundColor: '#fff1f0',
            border: "1px solid #ffa39e",
          }
        });
      }
    }).catch((error) => {
      if (error.response !== undefined && error.response.data !== null && error.response.data !== undefined && error.response.data.serviceStatus !== undefined) {
        notification.error({
          message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Error </span>{error.response.data.serviceStatus.message}</span>,
          duration: 3,
          style: {
            backgroundColor: '#fff1f0',
            border: "1px solid #ffa39e",
          }
        });
      }
      else {
        notification.error({
          message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Error </span>{errorMessages.generic_error}</span>,
          duration: 3,
          style: {
            backgroundColor: '#fff1f0',
            border: "1px solid #ffa39e",
          }
        });
      }

    })
  }
}
