import React from 'react'
import { Row, Col } from 'antd'
const FILENAME_LENGTH = 25

function TripInformation(props) {
  return (
    <Row className='trip-information'>
      <Col span={24}>
        <span className='label'>Trip Start Date: </span>
        <span className='trip-start-date'>{`${props.tripData.tripStartDate}`}</span>
      </Col>
      <Col span={24}>
        <span className='label'>Trip End Date: </span>
        <span className='trip-end-date'>{`${props.tripData.tripEndDate}`}</span>
      </Col>
      <Col span={24}>
        <span className='label'>Origin: </span>
        <span className='trip-origin'>{`${props.tripData.origin}`}</span>
      </Col>
      <Col span={24}>
        <span className='label'>Destination: </span>
        <span className='trip-destination'>{`${props.tripData.destination}`}</span>
      </Col>
    </Row>
  )
}

function TripMedia(props) {
  return (
    <Row className='trip-media'>
      <Col span={24} style={{ fontWeight: 'bold' }}>Media</Col>
      {
        props.tripData.media.transactionReceipts && props.tripData.media.transactionReceipts.length > 0 ? (
          <Col span={24} className='transaction-receipts'>
            <div style={{ marginBottom: '5px' }}> Transaction Receipts </div>
            <div>
              {
                props.tripData.media.transactionReceipts.map((transactionReceiptsDoc, index) => (
                  <div className='transaction-receipt-document' key={index}>
                    <i className="icon-Pdf" ></i>
                    &nbsp;
                    <button onClick={() => props.handleDownload(transactionReceiptsDoc.locationUrl, transactionReceiptsDoc.fileName, transactionReceiptsDoc.attachmentId)}
                      type='button'
                      className='download-button'
                    >
                      {
                        transactionReceiptsDoc.fileName.length > FILENAME_LENGTH ?
                          (transactionReceiptsDoc.fileName.substr(0, FILENAME_LENGTH) + '...')
                          : transactionReceiptsDoc.fileName
                      }
                    </button>
                  </div>
                ))
              }
            </div>
          </Col>
        ) : null
      }

      {
        props.tripData.media.flight && props.tripData.media.flight.length > 0 ? (
          <Col span={24} className='flight'>
            <div style={{ marginBottom: '5px' }}> Flight </div>
            <div>
              {
                props.tripData.media.flight.map((flightDoc, index) => (
                  <div className='flight-document' key={index}>
                    <i className="icon-Pdf" ></i>
                    &nbsp;
                    <button onClick={() => props.handleDownload(flightDoc.locationUrl, flightDoc.fileName, flightDoc.attachmentId)}
                      type='button'
                      className='download-button'
                    >
                      {
                        flightDoc.fileName.length > FILENAME_LENGTH ?
                          (flightDoc.fileName.substr(0, FILENAME_LENGTH) + '...')
                          : flightDoc.fileName
                      }
                    </button>
                  </div>
                ))
              }
            </div>
          </Col>
        )
          : null
      }
      <Col span={24} className='hotel'>
        {
          props.tripData.media.hotel && props.tripData.media.hotel.length > 0 ? (
            <Col span={24} className='hotel'>
              <div style={{ marginBottom: '5px' }}> Hotel </div>
              <div>
                {
                  props.tripData.media.hotel.map((hotelDoc, index) => (
                    <div className='hotel-document' key={index}>
                      <i className="icon-Pdf" ></i>
                      &nbsp;
                    <button onClick={() => props.handleDownload(hotelDoc.locationUrl, hotelDoc.fileName, hotelDoc.attachmentId)}
                        type='button'
                        className='download-button'
                      >
                        {
                          hotelDoc.fileName.length > FILENAME_LENGTH ?
                            (hotelDoc.fileName.substr(0, FILENAME_LENGTH) + '...')
                            : hotelDoc.fileName
                        }
                      </button>
                    </div>
                  ))
                }
              </div>
            </Col>
          )
            : null
        }
      </Col>
    </Row>
  )
}

class Body extends React.Component {

  handleDownload(...data) {
    this.props.downloadFile(...data)
  }

  render() {
    return (
      <div className='body'>
        <TripInformation tripData={this.props.tripData} />
        <TripMedia tripData={this.props.tripData}
          handleDownload={(...data) => this.handleDownload(...data)}
        />
      </div>
    )
  }
}

export default Body