import initialState from './initialState';

const logoutReducer = (state = initialState, action) => {

    switch (action.type) {
        case 'LOG_OUT_REQUEST':
            return {...state};
        case 'LOG_OUT_SUCCESS':
            
            return {
                credentialsResetError: null,
                credentialsResetSuccess: null,
                authenticationError:null,
            };
        case 'LOG_OUT_FAIL':
            return null;
        default:
            return state;
    }
};

export default logoutReducer;