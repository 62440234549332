import React from 'react'
import './chatHistory.css'

function MessageBubble(props) {
  return (
    <div className='message-bubble-container'
      key={props.index}
    >
      <div className={`message ${props.data.source === 'user' ? 'user-message' : 'chatbot-message'}`}>
        {
          props.data.source === 'chatbot' ? 
          (<div className='message-icon' style={{marginRight: '5px'}}>
            <i className="icon-Create-user-profile"></i>
          </div>) : null
        }
        <div className='message-body'>
          {props.data.message}
        </div>
        {
          props.data.source === 'user' ? 
          (<div className='message-icon' style={{marginLeft: '5px'}}>
            <i className="icon-Create-user-profile"></i>
          </div>) : null
        }
      </div>
    </div>
  )
}

class Body extends React.Component {

  renderChatBubble(message, index) {
    return <MessageBubble data={message} key={index} />
  }

  render() {
    return (
      <div className="message-container" style={{ padding: '5px 10px' }}>
        {
          this.props.transactionChatHistory.map((message, index) => this.renderChatBubble(message, index))
        }
      </div>
    )
  }
}

export default Body