import React from 'react';
import { Row, Col } from 'antd';

function PageNotFoundComponent() {
  return (
    <Row style={{
      fontFamily: 'Segoe UI !important',
      paddingTop: '15px'
    }}>
      <Col 
        span={24}
        style={{
          fontSize: '24px',
          fontWeight: 'bold',
          textAlign: 'center'
        }}
      >
        404 - Not Found
      </Col>
      <Col 
        span={24}
        style={{
          textAlign: 'center'
        }}
      >
        Sorry, but the page you are looking for does not exist.
      </Col>
    </Row>
  )
}

export default PageNotFoundComponent