import React from 'react';
import { connect } from 'react-redux';
import $ from 'jquery';
import AdminCarousel from './adminCarousel';
import { loaderFocus } from '../../utils/accessability';
import DelegateHiringManager from './delegateManager';
import CreateUserProfileComponent from './CreateUserProfile/createUserProfile';
import UserActionHeaderComponent from '../useractionheader.js';
import ViewandEditProfile from './ViewEditProfile/viewandeditprofile';
import CompanySetupComponent from './CompanySetupComponents/companySetup';
import ReceiptRulesComponent from './ReceiptRules';
import CardMileage from './Settings/cardMileage';
import ReportComponent from './reports';
import MainExpCategoryComponent from './ExpenseCategory/expenseCategory';
import AllActiveCards from '../allActiveCards';
import '../../styling/companysetup.css';
import '../../styling/header.css'
import { Layout, notification, Spin } from 'antd';
import BatchJob from './BatchJob';
import AppVersion from './appVersion';
import axios from 'axios';
import { getExpenseCategoryAll, getTransactionTypeAll } from '../../actions/AdminActions/receiptruleActions';
const { URLs,errorMessages } = require('../../data/constants.js');
const { Content, Footer } = Layout;
class SuperAdminComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            superadminCompany: "",
            activeCompanyName: [],
            selecteditem: 1,
            cadreRoles: [],
            retrieve: false,
            expenseCat: [],
            expenseCatAll: [],
            catRetrieve: false,
            transactionType: [],
            transactionTypeAll: [],
            typeRetrieve: false,
            compretrieve: false,
        }
    }
    componentDidMount() {
        if (!(this.state.retrieve === true && this.state.catRetrieve === true && this.state.typeRetrieve === true && this.state.compretrieve === true)) {
            loaderFocus(".loader-color .ant-spin-text");
        }
        if (!this.props.userDetails.roles.includes("Super Admin") && !this.props.userDetails.roles.includes("Customer Support")) {
            window.history.back();
        }
        if (window.location.hostname.includes('elan-solutions.elaneasypay')){
            $('.page-title').text("Super Admin: Elan Easy Pay").focus();
            document.title = "Super Admin: Elan Easy Pay";
        }
        else{
            $('.page-title').text("Super Admin: US Bank Expense Wizard").focus();
            document.title = "Super Admin: US Bank Expense Wizard";
        }
        /**********getcadre roles *********/
        axios.get(URLs.adminUrl + "cadreRoles", {
            responseType: 'json',
            headers: {
                "Authorization": 'Bearer ' + this.props.token.access_token
            }
        })
            .then(response => {
                this.setState({ cadreRoles: response.data.data, retrieve: true });
            })
            .catch((error) => {
                this.setState({ retrieve: true })
                if (error.response !== undefined && error.response.data !== null && error.response.data !== undefined && error.response.data.serviceStatus !== undefined) {
                    notification.error({
                        message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{error.response.data.serviceStatus.message}</span>,
                        duration: 3,
                        style: {
                            backgroundColor: '#fff1f0',
                            border: "1px solid #ffa39e",
                        }
                    });
                }
                else {
                    notification.error({
                        message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{errorMessages.generic_error}</span>,
                        duration: 3,
                        style: {
                            backgroundColor: '#fff1f0',
                            border: "1px solid #ffa39e",
                        }
                    });
                }
            })
        this.getExpenseCategory();
        this.props.getExpenseCatAll(this.updateCatAllSpin, this.updateCatAllState);
        this.getTransactionType();
        this.props.getTransTypeAll(this.updateTypeAllSpin, this.updateTypeAllState)
        this.getCompanyLists();
    }

    /**
    * fetching all active company
    */
    getCompanyLists = () => {
        let URL = "activeRegisteredCompanies";
        axios.get(URLs.companyUrl + URL, {
            responseType: 'Json',
            headers: {
                "Authorization": 'Bearer ' + this.props.token.access_token
            }
        }).then(response => {
            this.setState({ compretrieve: true })
            if (response.data.serviceStatus.statusCode === 200) {
                response.data.data.forEach(item => 
                    (!this.props.userDetails.companyId ? this.setState({ superadminCompany: "Expense Wizard" }) :
                        item.companyId === this.props.userDetails.companyId ? this.setState({ superadminCompany: item.companyName }) : null
                        )
                    )
                this.setState({ activeCompanyName: response.data.data })
            }
            else if (response.data.serviceStatus.statusCode === 210) {
                notification.error({
                    message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error</span>{response.data.serviceStatus.message}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }
            else {
                notification.error({
                    message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error</span>{errorMessages.error_fetchCompany}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }
        }).catch(error => {
            this.setState({ compretrieve: true })
            if (error.response !== undefined && error.response.data !== null && error.response.data !== undefined && error.response.data.serviceStatus !== undefined) {
                notification.error({
                    message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error</span>{error.response.data.serviceStatus.message}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }
            else {
                notification.error({
                    message: <span  role='alert' aria-live="assertive" aria-atomic="true" ><span className="visually-hidden">Error </span>{errorMessages.generic_error}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }
        })

    }
    /**********getExpense category*************/
    getExpenseCategory = () => {
        axios.get(URLs.tripUrl + "getCompanyExpenseCategory", {
            responseType: 'json',
            headers: {
                "Authorization": 'Bearer ' + this.props.token.access_token
            }
        }).then(response => {
            this.setState({ catRetrieve: true });
            if (response.data.serviceStatus.statusCode === 200) {
                this.setState({ expenseCat: response.data.data });
            }
            else if (response.data.serviceStatus.statusCode === 210) {
                notification.error({
                    message: <span  role='alert' aria-live="assertive" aria-atomic="true" ><span className="visually-hidden">Error </span>{response.data.serviceStatus.message}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }
        }).catch((error) => {
            this.setState({ catRetrieve: true });
            if (error.response !== undefined && error.response.data !== null && error.response.data !== undefined && error.response.data.serviceStatus !== undefined) {
                notification.error({
                    message: <span  role='alert' aria-live="assertive" aria-atomic="true" ><span className="visually-hidden">Error </span>{error.response.data.serviceStatus.message}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }
            else {
                notification.error({
                    message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error</span>{errorMessages.generic_error}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }
        })
    }
    /**********getExpense category and All*************/

    updateCatAllSpin = (value) => {
        this.setState({ catRetrieve: value });
    }
    updateCatAllState = (value) => {
        this.setState({ expenseCatAll: value });
    }
    /**********get Transaction type and All*************/
    updateTypeAllSpin = (value) => {
        this.setState({ typeRetrieve: value });
    }
    updateTypeAllState = (value) => {
        this.setState({ transactionTypeAll: value });
    }
    /**********get Transaction type*************/
    getTransactionType = () => {
        axios.get(URLs.tripUrl + "getTransactionTypes", {
            responseType: 'json',
            headers: {
                "Authorization": 'Bearer ' + this.props.token.access_token
            }
        }).then(response => {
            this.setState({ typeRetrieve: true })
            if (response.data.serviceStatus.statusCode === 200) {
                this.setState({ transactionType: response.data.data });
            }
            else if (response.data.serviceStatus.statusCode === 210) {
                notification.error({
                    message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error</span>{response.data.serviceStatus.message}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }
        }).catch((error) => {
            this.setState({ typeRetrieve: true })
            if (error.response !== undefined && error.response.data !== null && error.response.data !== undefined && error.response.data.serviceStatus !== undefined) {
                notification.error({
                    message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error</span>{error.response.data.serviceStatus.message}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }
            else {
                notification.error({
                    message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error</span>{errorMessages.generic_error}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }
        })
    }
    selectedItems = (item) => {
        this.setState({ selecteditem: item });
    }
    render() {
        if (this.props.cardAssigned) {
            this.props.handlenewTripandCard(this.props.creditCardNo, this.props.newtripobj);
        }
        return (
            this.state.retrieve === true && this.state.catRetrieve === true && this.state.typeRetrieve === true && this.state.compretrieve === true ?
                <Layout>
                    <div><UserActionHeaderComponent app_labels = {this.props.app_labels} superadminCompany={this.state.superadminCompany} roles={this.props.userDetails.roles.includes("Super Admin") ? "Super Admin": "Customer Support"} /></div>
                    
                        <Content>
                            <div className="container-fluid" style={{ boxShadow: " 0px 3px 5px 5px #f2f2f2" }}>
                                {/* <div className="row" style={{ backgroundColor: "white", textAlign: "center" }}>
                                    <span className="company-name"><b>{this.state.superadminCompany} Administration</b></span>
                                </div> */}
                                <div className="row">
                                    <div className="col-sm-10 col-sm-offset-1">
                                        <AdminCarousel app_labels = {this.props.app_labels} index={1} initialSlide={this.state.selecteditem - 1} selectedItems={this.selectedItems} userrole={this.props.userDetails.roles.includes("Super Admin") ? "Super Admin": "Customer Support"} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12 col-sm-offset-0">
                                        {   this.props.userDetails.roles.includes("Super Admin") ?
                                            <div>
                                                {this.state.selecteditem === 1 ? <CreateUserProfileComponent app_labels = {this.props.app_labels} cadreRoles={this.state.cadreRoles} selectedRole={"Super Admin"} /> : null}
                                                {this.state.selecteditem === 7 ? <MainExpCategoryComponent app_labels = {this.props.app_labels} activeCompanyName={this.state.activeCompanyName} expenseCat={this.state.expenseCat} transactionType={this.state.transactionType} /> : null}
                                                {this.state.selecteditem === 4 ? <ReceiptRulesComponent app_labels = {this.props.app_labels} superAdmin={true} activeCompanyName={this.state.activeCompanyName} expenseCat={this.state.expenseCatAll} transactionType={this.state.transactionTypeAll} /> : null}
                                                {/*  {this.state.selecteditem === 2 ? <ViewandEditProfile /> : null} */}
                                                {this.state.selecteditem === 5 ? <CompanySetupComponent app_labels = {this.props.app_labels} getCompanyLists={this.getCompanyLists} roles={"Super Admin"} /> : null}
                                                {/* {this.state.selecteditem === 3 ? <CardSettingComponent activeCompanyName={this.state.activeCompanyName} /> : null} */}
                                                {this.state.selecteditem === 2 ? <ViewandEditProfile app_labels = {this.props.app_labels} activeCompanyName={this.state.activeCompanyName} cadreRoles={this.state.cadreRoles} selectedItems={this.selectedItems} userrole={"Super Admin"} /> : null}
                                                {this.state.selecteditem === 3 ? <AllActiveCards label_keys={this.props.app_labels} activeCompanyName={this.state.activeCompanyName}  roles={"Super Admin"} selectedLang={"English"} /> : null}
                                                {this.state.selecteditem === 8 ? <DelegateHiringManager app_labels = {this.props.app_labels} activeCompanyName={this.state.activeCompanyName} roles={"Super Admin"} /> : null}
                                                {this.state.selecteditem === 6 ? <ReportComponent app_labels = {this.props.app_labels}/> : null}
                                                {this.state.selecteditem === 9 ? <BatchJob app_labels = {this.props.app_labels}/> : null}
                                                {this.state.selecteditem === 10 ? <CardMileage app_labels = {this.props.app_labels} activeCompanyName={this.state.activeCompanyName} roles={"Super Admin"} /> : null}
                                                {this.state.selecteditem === 11 ? <AppVersion app_labels = {this.props.app_labels} roles={"Super Admin"} />:null}
                                            </div> : 
                                            <div> 
                                                {this.state.selecteditem === 1 ? <ViewandEditProfile app_labels = {this.props.app_labels} activeCompanyName={this.state.activeCompanyName} cadreRoles={this.state.cadreRoles} selectedItems={this.selectedItems} userrole={'Customer Support'} /> : null}
                                                {this.state.selecteditem === 2 ? <AllActiveCards label_keys={this.props.app_labels} activeCompanyName={this.state.activeCompanyName}  roles={'Customer Support'} selectedLang={"English"} /> : null}
                                                {this.state.selecteditem === 3 ? <CompanySetupComponent app_labels = {this.props.app_labels} roles={'Customer Support'} getCompanyLists={this.getCompanyLists} /> : null}
                                                {this.state.selecteditem === 4 ? <CardMileage app_labels = {this.props.app_labels} activeCompanyName={this.state.activeCompanyName} roles={'Customer Support'} /> : null}
                                                {this.state.selecteditem === 5 ? <AppVersion app_labels = {this.props.app_labels} roles={'Customer Support'} />:null}
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </Content>
                    

                    <Footer id='footer'></Footer>
                </Layout> : <div className="dark-spinner loader-color"><Spin tip="Loading..." />
                <span class='visually-hidden page-title' tabIndex={-1}></span>
                </div>
        )
    }

}
function mapStateToProps(state, props) {
    return {
        userDetails: state.authenticationReducer.userDetails,
        token: state.authenticationReducer.token,
        app_labels: state.adduserReducer.app_labels,
    };
}
function mapDispatchToProps(dispatch) {
    return {
        getExpenseCatAll: (updateCatAllSpin, updateCatAllState) => {
            dispatch(getExpenseCategoryAll(updateCatAllSpin, updateCatAllState));
        },
        getTransTypeAll: (updateTypeAllSpin, updateTypeAllState) => {
            dispatch(getTransactionTypeAll(updateTypeAllSpin, updateTypeAllState))

        }

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(SuperAdminComponent);