import React from 'react';
import $ from 'jquery';
import axios from 'axios';
import qs from 'qs'
import { Link } from 'react-router-dom';
import { Row, Col, Select, Menu, Dropdown, Icon, notification } from 'antd';
import { logOut } from '../actions/logoutactions';
import MediaQuery from 'react-responsive';
import { connect } from 'react-redux';
import '../styling/header.css';
import history from './history';
import LogUSBank from '../FontIcons/fonts/US BANK.svg';
import { multiLanguageLabelRetrieve } from '../actions/multilanAction';
import LanguageSelectBtn from './ProvisionerScreen/LanguageSelect';
const { URLs } = require('../data/constants.js')

const { Option } = Select;
class UserActionHeaderComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            helpFlag: true,
            selectedLanguage: this.props.selectedLanguage
        }
    }
    componentDidMount() {
        $('#select-roles-dropdown').on('keydown', function(e){ if(e.keyCode === 37 || e.keyCode === 38 || e.keyCode === 40 || e.keyCode === 39) {
            e.preventDefault(); return false; } });
        if (this.props.roles !== "Manager") {
            this.setState({ helpFlag: false })
        }

    }
    /**clearing all session*/
    logout = () => {
        sessionStorage.clear();
        this.props.logOutFun();
        history.replace('/login');
    }

    languageChange = (event) => {
        if(event.target.textContent){
            this.props.multiLanguageLabelRetrieve(event.target.textContent); 
            this.setState({selectedLanguage : event.target.textContent});
            setTimeout(() =>{
                notification.success({
                message: <span role='alert' aria-live="assertive" aria-atomic="true" ><span className="visually-hidden">Success </span>{this.props.app_labels.languageChangeSuccess}</span>,
                duration: 3,
                style: {
                    backgroundColor: '#dff0d8',
                    color: "green",
                    border: "1px solid green",
                }
                })
            },1000);
            // let obj = { language: event.target.textContent }
            // axios.post(URLs.comUrl + 'updateUserPreference', qs.stringify(obj), {
            //     headers: {
            //         "Authorization": 'Bearer ' + this.props.token.access_token
            //     }
            // }).then(res => {
            //     this.setState({selectedLanguage : event.target.textContent})
            //     notification.success({
            //         message: <span role='alert' aria-live="assertive" aria-atomic="true" ><span className="visually-hidden">Success </span>{this.props.app_labels.languageChangeSuccess}</span>,
            //         duration: 3,
            //         style: {
            //             backgroundColor: '#dff0d8',
            //             color: "green",
            //             border: "1px solid green",
            //         }
            //     });

            // })
            //     .catch(err => notification.error({
            //         message: <span role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{this.props.app_labels.couldNotcompleteReq}</span>,
            //         duration: 3,
            //         style: {
            //             backgroundColor: '#fff1f0',
            //             border: "1px solid #ffa39e",
            //         }
            //     }))
        }       
    }

    /**Will route to corresponding pages on chnage of role
     * @param  {} e
     */
    roleChange = (e) => {  
        console.log("in useractionheader e.target.value is:"+e.target.value)
        if (e.target.value === "Company Admin") {
            this.setState({ helpFlag: false })
            history.push({
                pathname: '/admin',
                state: { role: 'Company Admin' }
            });
        }
        if (e.target.value === "Super Admin") {
            this.setState({ helpFlag: false })
            history.push({
                pathname: '/superadmin',
                state: { role: 'Super Admin' }
            });
        }
        if (e.target.value === "Customer Support") {
            this.setState({ helpFlag: false })
            history.push({
                pathname: '/customersupport',
                state: { role: 'Customer Support' }
            });
        }
        if (e.target.value === "Card Company Admin") {
            this.setState({ helpFlag: false })
            history.push({
                pathname: '/cardadmin',
                state: { role: 'Card Company Admin' }
            });
        }
        if (e.target.value === "Provisioner") {
            history.push({
                pathname: '/carddashboard',
                state: { role: 'Provisioner' }
            });
        }
        if (e.target.value === "Approver") {
            history.push({
                pathname: '/carddashboard',
                state: { role: 'Approver' }
            });
        }
        if (e.target.value === "Manager") {
            this.setState({ helpFlag: true })
            history.push('/dashboard');
        }
        if (e.target.value.toUpperCase() === "CSR") {
            this.setState({ helpFlag: false })
            history.push('/csr');
        }
    }
    helpTuto = () => {
        history.push('/tutorial');
    }
    render() {
        let userDetailsLocal = [];
        this.props.userDetails.roles.map(data => data!=='Traveler' && userDetailsLocal.push(data))
        const menu = (
            <Menu>
                <Menu.Item>
                    <span className='header-user'>
                        {this.props.userDetails.firstName + " " + this.props.userDetails.lastName}
                    </span>
                </Menu.Item>
                <Menu.Item>
                    <span onClick={e => {
                        e.stopPropagation()
                    }}>
                        <select onChange={this.roleChange} aria-label='roles of user'>
                            {userDetailsLocal.map((item, index) =>
                                this.props.roles === item ? <option value={item} key={index} selected>{item}</option> : <option key={index} value={item}>{item}</option>
                            )}
                        </select>
                    </span>
                </Menu.Item>
                <Menu.Item>
                    <Link aria-label='logout button' className="logout-button" to="/login" onClick={this.logout}>{this.props.app_labels.logout_AdminView}</Link>
                </Menu.Item>
            </Menu>
        );
        return (
            <Row className={`useraction-header ${this.props.theme}`}>
                <Col
                    xs={{ span: 14 }}
                    sm={{ span: 16 }}
                    md={{ span: 15 }}
                    lg={{ span: 17 }}
                    xl={{ span: 17 }}
                >
                    <div>
                        { 
                            this.props.theme === "elan" ?
                                <>  <img src={require(`../images/provisioner/elan/logo.svg`).default} height="25px" alt='Elan logo' />&nbsp;<span className={this.props.theme == 'elan' ? 'instant-card-elan' : 'instant-card'}>|&nbsp;&nbsp;Easy Pay</span> </>
                        :
                                <>  <img src={require(`../images/${this.props.theme}/logo.svg`).default} alt='US Bank logo' className='logo' />&nbsp; &nbsp;< h3 role="presentation" className="headerlogo-card" style={{marginTop: '7px', fontSize: 20, fontWeight: 600 }} >{this.props.theme == 'elan' ? 'Easy Pay' : 'Expense wizard'}</h3> </>
                        }
                        {(this.props.theme !== 'elan' )&&
                                   <div className={`${this.props.roles === "Super Admin" || this.props.roles === "Customer Support" ? 'super-admin' : ''} company-name-row row`} style={{ backgroundColor: "white", textAlign: "center", marginLeft: this.props.roles === "Super Admin" || this.props.roles === "Customer Support" ? "17%" : "29%" }}>
                                        <span className="company-name" ><b  role="heading" aria-level="1">{this.props.superadminCompany} {this.props.app_labels.administration}</b></span>
                                    </div>}
                        {(this.props.theme == 'elan') && <Col style={{width: this.props.roles === "Super Admin" || this.props.roles === "Customer Support" ? '60%' : null}}>
                            <div className={`${this.props.roles === "Super Admin" || this.props.roles === "Customer Support" ? 'super-admin' : ''} company-name-row-elan row`} style={{ marginLeft: this.props.roles === "Super Admin" || this.props.roles === "Customer Support" ? '46%' : '250%', color: "white" }} >
                                <span className="company-name" style={{ color: "white" }} ><b role="heading" aria-level="1" >{this.props.superadminCompany} {this.props.app_labels.administration}</b></span>
                            </div>
                        </Col>}
                        {/* <img src={LogUSBank} alt='US Bank logo' className='logo' />&nbsp; &nbsp;<i role='img' aria-label="Expense wizard" className='icon-Expense-wizard iconFontsize' /> */}
                    </div>
                </Col>

                <Col
                    xs={{ span: 8 }}
                    sm={{ span: 8 }}
                    md={{ span: 0 }}
                    lg={{ span: 0 }}
                    xl={{ span: 0 }}
                    style={{ paddingRight: '5px' }}
                >
                    <div style={{
                        justifyContent: 'flex-end'
                    }}>
                        <Dropdown overlay={menu} trigger='click'>
                            <span style={{
                                alignItems: 'center',
                                justifyContent: 'center',
                                display: 'flex'
                            }}>
                                <i className='icon-Group--1 header-profile'></i>
                                <i className='icon-Drop-down' style={{ marginLeft: '3px' }}></i>
                            </span>
                        </Dropdown>
                    </div>
                </Col>

                <Col
                    xs={{ span: 0, offset: 0 }}
                    sm={{ span: 0, offset: 0 }}
                    md={{ span: 1, offset: 0 }}
                    lg={{ span: 1, offset: 0 }}
                    xl={{ span: 1, offset: 0 }}
                    style={{ textAlign: 'center' }}
                >
                    <div
                    >{/* <i className="icon-Group--1 header-profile" /> */}
                    </div>
                </Col>
                <Col
                    xs={{ span: 0 }}
                    sm={{ span: 0 }}
                    md={{ span: 4 }}
                    lg={{ span: 3 }}
                    xl={{ span: 3 }} 
                    style={{marginLeft: this.props.selectedLanguage==='FR'?'-3%': null}}
                >
                    <div>
                        <Row style={{ width: '100%' }}>
                            <Col span={24} className='header-user'>
                                {this.props.userDetails.firstName + " " + this.props.userDetails.lastName}
                            </Col>
                            <Col span={24} className="header-index" style={{ width: "fit-content" }}>
                                <select id="select-roles-dropdown" className="user-roles" onChange={this.roleChange} aria-label={this.props.userDetails.firstName + " " + this.props.userDetails.lastName}>
                                    {userDetailsLocal.map((item, index) =>
                                        this.props.roles === item ? <option value={item} key={index} selected>{item}</option> : <option key={index} value={item}>{item}</option>
                                    )};
                                </select>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col
                    xs={{ span: 0 }}
                    sm={{ span: 0 }}
                    md={{ span: 3 }}
                    lg={{ span: 3 }}
                    xl={{ span: 3 }}
                >
                    <div className="headerClass">
                        {/* <Link role="button" className="logout-button  header-index" to="/login" onClick={this.logout}>{this.props.app_labels.logout}</Link> */}
                        {this.state.helpFlag ? <MediaQuery minDeviceWidth={1025}>
                            <span style={{ marginLeft: '4%' }}><Icon title='Help' style={{ color: '#0a41c5' }} type="info-circle" onClick={this.helpTuto} />
                            </span>
                        </MediaQuery> : null}
                        {/* <Select defaultValue={this.props.selectedLanguage}
                            showArrow={false}
                            className='header-index'
                            onChange={this.languageChange}
                            style={{ width: 44, borderBottom: "none", border: "1px solid lightgrey", borderRadius: "4px", marginLeft: "8%", backgroundColor:'white' }} >
                            {this.props.languageList.map((item,i) =>
                                item.code === 'SP' || item.code === 'FR' ?
                                <Option value={item.name} key={i} disabled>{item.code}</Option> :
                                <Option value={item.name} key={i} >{item.code}</Option>
                            )}
                        </Select> */}
                        <LanguageSelectBtn onChange={this.languageChange}
                            value={this.state.selectedLanguage}
                            iclanguageList={this.props.languageList}
                            theme={this.props.theme+" admin"}
                            role = {this.props.roles}
                        />

                        <Link role="button" className="logout-button  header-index" to="/login" onClick={this.logout} style={{marginLeft: "10%", color: this.props.theme == 'elan'? "white" : "#19398A", fontWeight: this.props.roles === "Super Admin" || this.props.roles === "Customer Support" ? 600: 500}}>{this.props.app_labels.logout_AdminView}</Link>

                    </div>
                </Col>
                {/* {(this.props.theme == 'elan' && this.props.roles !== "Card Company Admin") && <Col>
                    <div className="row" >
                        <span role="heading" aria-level="1" className="company-name" style={{ marginLeft: '44%', color: "white" }}><b>{this.state.superadminCompany} Administration</b></span>
                    </div>
                </Col>} */}
            </Row>

        )
    }
}
function mapStateToProps(state, props) {
    return {
        userDetails: state.authenticationReducer.userDetails,
        languageList: state.instantCardMultiLanReducer.iclanguageList || [],
        selectedLanguage: state.adduserReducer.selectedLanguage,
        app_labels: state.adduserReducer.app_labels,
        theme: state.authenticationReducer.theme,
        token: state.authenticationReducer.token,
    };
}
function mapDispatchToProps(dispatch) {
    return {
        logOutFun: () => {
            dispatch(logOut());
        },
        multiLanguageLabelRetrieve: (values) => {
            dispatch(multiLanguageLabelRetrieve(values))
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(UserActionHeaderComponent);