import * as React from 'react';
import { Popover, Button, Typography } from '@mui/material';
import { viewPhoneNumber } from './phoneNumberFormat';
import history from '../history'

import { Icon } from 'antd';

export default function UserLogoutPopover(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setTimeout(() => {
      if (document.getElementById('user'))
        document.getElementById('user').focus()
    }, 500);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseTab = () => {
    setAnchorEl(null);
    props.focusLangSelect();
  };

  const handleRoleChange = () => {
    history.push({
      pathname: '/cardadmin',
      state: { role: 'Card Company Admin' }
    });
  }


  const open = Boolean(anchorEl);
  const id = open ? 'logout-popover' : undefined;

  return (
    <div className='popover-header-span dashboard-zoom-fr'>
      <Button disableRipple className="header-span popover-btn" id='popover-btn-user'
        aria-expanded={open}
        variant="text" onClick={handleClick}
        style={{
          backgroundColor: 'transparent', fontWeight: 500,
          boxShadow: 'none', color: "white", fontSize: 12, marginLeft: props.selectedLang === 'French' ? '-35%' : '-15%', textTransform: 'none'
        }}

      >
        {props.label_keys.hi}, {props.userDetails.firstName} {props.userDetails.lastName}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Typography id="menuLogout-div" className="profile-dropdown" sx={{ p: 2, fontSize: 14 }}>
          {/* <Menu role="presentation" id="menuLogout-div" className="profile-dropdown" > */}
          <div id="Logout-div">
            <div aria-hidden="true" className="profile-icon" role="img"><Icon type="user" /></div>
            <div tabIndex={-1} id="user"><b>{props.userDetails.firstName} {props.userDetails.lastName}</b></div>
            <div><span>{props.userDetails.email}</span></div>
            <div><span>{viewPhoneNumber(props.userDetails.phoneNo)}</span></div>
            {props.userDetails.roles.includes("Card Company Admin") && <div><button id="admin-btn" className="ant-btn ant-btn-background-ghost" data-testid="companyAdminButton"
              onKeyDown={e => {
                if (e.keyCode === 13) {
                  handleRoleChange();
                }
              }}
              onClick={handleRoleChange}>Company Admin</button>
            </div>}
            <div><button id="logout-btn" className="ant-btn ant-btn-background-ghost" data-testid="loggedInUsername"
              onKeyDown={e => {
                if (e.key === "Tab" && !e.shiftKey) {
                  handleCloseTab();
                }
                if (e.key === "Enter") {
                  props.onClick()
                }

              }}
              onClick={() => { props.onClick() }}>{props.label_keys.logout}</button>
              <div className="visually-hidden"><span >To close the expanded view use Escape key</span></div>
            </div>
          </div>
          {/* </Menu> */}
        </Typography>
      </Popover>
      <Icon type="user" aria-hidden="true" role="img" />
    </div>
  );
}