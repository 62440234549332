import React from 'react';
import { connect } from 'react-redux';
import 'antd/dist/antd.css';
import axios from 'axios';
import MediaQuery from 'react-responsive';
import $ from 'jquery';
import 'react-responsive-ui/style.css';
import XLSX from 'xlsx';
import { Modal, Spin, Upload, Button, notification, Icon, Table } from 'antd';
import { loaderFocus,checkBoxStatus } from '../../../utils/accessability';
// import '../../../styling/createtrip.css';
const { URLs, errorMessages } = require('../../../data/constants.js');
// const  app_labels  = require('../../../data/english_label');
const confirm = Modal.confirm;
const Dragger = Upload.Dragger;
var initialData = [];
var rABS = typeof FileReader !== 'undefined' && FileReader.prototype && FileReader.prototype.readAsBinaryString;

const handleAutoComplete = (column) => {
    switch (column) {
      case 'FirstName':
        return 'given-name'
      case 'LastName':
        return 'family-name'
      case 'EmailId':
        return 'email'
      case 'MobileNumber':
        return 'tel-local'
      default:
      return false;
    }

  }
const EditableCell = ({ editable, value, onChange, column }) => (
    <div>
        {editable
            ? <input className="editabletextbox" style={{ fontSize: '16px' }} autoComplete = {handleAutoComplete(column)} aria-label={column} tabIndex="0" value={value} onChange={e => onChange(e.target.value)} />
            : <span>{value}</span>
        }
    </div>
);
const EditableRoleCell = ({ cadreRoles, editable, value, onChange,}) => {
    return (
     <div>
        {editable
            ? <select className="columnrenderclass" aria-label={` Job role`} onChange={e => onChange(e.target.value)}>
                {cadreRoles.map(item =>
                    item.roleDesc === value ? <option value={item.roleDesc} selected>{item.roleDesc}</option> : <option value={item.roleDesc}>{item.roleDesc}</option>)};
            </select>
            : <span>{value}</span>
        }
    </div>
    )
    };
/**
 * Creates profiles for Bulk users
 */
class CreateBulkUserProfileComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fileList: [],
            filename: "",
            visible: false,
            successmsg: false,
            messageContent: "",
            jsonData: false,
            selectedRowKeys: [],
            editiconColor: 'grey',
            deleteiconColor: "grey",
            data: [],
            initialData: [],
            uploadFlag: false,
        }
        this.cacheData = this.state.data.map(item => ({ ...item }));
        this.handlebulkCancel = this.handlebulkCancel.bind(this);
        this.updateStates = this.updateStates.bind(this);
        this.handleUpload = this.handleUpload.bind(this);
    }

    componentDidUpdate() {

        if (this.state.visible) {
            var ulDiv = document.querySelectorAll(".ant-upload-list-item-card-actions");
            if (ulDiv[0]) {
                ulDiv.forEach(div => {
                    div.setAttribute('class', 'ant-upload-list-item-card-actions picture');
                })
            }

            var fileNameDiv = document.querySelectorAll(".ant-upload-list-item-name.ant-upload-list-item-name-icon-count-1");
            if (fileNameDiv[0]) {
                fileNameDiv.forEach(div => {
                    div.setAttribute('id', 'fileName');
                })
            }

            var iconDiv = document.querySelectorAll(".anticon.anticon-delete");
            if (iconDiv[0]) {
                iconDiv.forEach(div => {
                    div.setAttribute('tabindex', '0');
                    div.setAttribute('role', 'button');
                    div.setAttribute('aria-label', 'Delete');
                    div.setAttribute('aria-describedby', 'fileName')
                })
            }

            setTimeout(() => {
                var uploadDiv = document.querySelectorAll(".ant-upload.ant-upload-btn");
                console.log("Upload Div::", uploadDiv);
                if (uploadDiv[0]) {
                    uploadDiv.forEach(div => {
                        div.setAttribute('tabindex', '-1');
                        div.removeAttribute('role');
                    });
                }
            }, 500)


        }


    }

    /**
     * @param  {} text
     * @param  {} record
     * @param  {} column
     *rendering the columns according to requirement
     */
    renderColumns(text, record, column) {
        return (
            <EditableCell
                editable={record.editable}
                value={text}
                onChange={value => this.handleChange(value, record.key, column)}
                column={column}
            />
        );
    }
    /**
     * @param  {} text
     * @param  {} record
     * @param  {} column
     *rendering the Role column according to requirement
     */
    renderRoleColumns(text, record, column) {
    
        return (
            <EditableRoleCell
                cadreRoles={this.props.cadreRoles}
                editable={record.editable}
                value={text}
                onChange={value => this.handleChange(value, record.key, column)}
                
            />
        );
    }

    /**
     * on click of Save, parse the data into json and return the same, else download the error response
     * @param {*} blob 
     */
    handleResponseOfSaveBulk(blob) {
        return new Promise((resolve, reject) => {
            let reader = new FileReader()
            reader.onload = (data) => {
                try {
                    // try to json parse the data
                    let jsonData = JSON.parse(data.target.result);
                    return resolve(jsonData)
                } catch (e) {
                    // error in json parsing; response may not be json; download that
                    this.handleDownload(blob)
                    return resolve(undefined)
                }
            }
            reader.onerror = function (event) {
                reader.abort()
                return resolve(undefined)
            }
            reader.readAsText(blob)
        })
    }
    /**
     * on click of Download, handles download of excel sheet with data
     * @param {*} data 
     */
    handleDownload(data) {
        if (navigator.msSaveBlob) { // IE block
            var blobObject = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            window.navigator.msSaveOrOpenBlob(blobObject, "Error Excel.xlsx");
        }
        else { //non IE block
            var a = document.createElement('a');
            a.href = window.URL.createObjectURL(new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })); // xhr.response is a blob
            a.download = "Error Excel"; // Set the file name.
            a.style.display = 'none';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            setTimeout(function () {
                // For Firefox it is necessary to delay revoking the ObjectURL
                window.URL.revokeObjectURL(a.href);
            }, 100)
        }
    }
    /**
     * @param  {} value
     * @param  {} key
     * @param  {} column
     * onchange of cell it update the dataList
     */
    handleChange = (value, key, column) => {
        const newData = [...this.state.data];
        const target = newData.filter(item => key === item.key)[0];
        if (target) {
            target[column] = value;
            this.setState({ data: newData });
        }

    }
    /**
     * onclick of save ,handles the data
     */
    handleSave = () => {
        var bulkarray = [];
        this.state.data.forEach((object) => {
            var jobRole = "";
            this.props.cadreRoles.forEach((item) => {
                if (object.SelectJobRole && item.roleDesc.toLowerCase() === object.SelectJobRole.toLowerCase()) {
                    jobRole = item.roleId;
                }
            })
            let obj = {
                "cadreId": object.CadreId,
                "companyId": this.props.userDetails.companyId,
                "firstName": object.FirstName,
                "lastName": object.LastName,
                "emailId": object.EmailId,
                "phoneNo": object.MobileNumber,
                "ALT_PHONE_NO": object.AltMobileNumber,
                "roleId": jobRole,
                "jobRole": object.SelectJobRole,
                "companyName": object.CompanyName,
            }
            bulkarray.push(obj);

        })
        axios.post(URLs.adminUrl + "createNewUserBulk", bulkarray, {
            responseType: 'blob',
            headers: {
                "Authorization": 'Bearer ' + this.props.token.access_token
            }
        }).then(async (response) => {
            let responseData = await this.handleResponseOfSaveBulk(response.data)
            if (!responseData) {
                return
            }
            if (responseData.serviceStatus.statusCode === 200) {
                this.setState({ fileList: [], selectedRowKeys: [], jsonData: false, successmsg: true, messageContent: responseData.serviceStatus.message })
                notification.success({
                    message: <span role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Success </span>{this.props.app_labels.userProfilesCreated}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#dff0d8',
                        color: "green",
                        border: "1px solid green",
                    }
                });
            }
            else if (responseData.serviceStatus.statusCode === 210) {
                /*  message.error(response.data.serviceStatus.message); */
                notification.error({
                    message: <span role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{this.props.app_labels.error_userProfile}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }
            else {
                notification.error({
                    message: <span role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{this.props.app_labels.error_userProfile}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }

        }).catch(error => {
            if (error.response !== undefined && error.response.data !== null && error.response.data !== undefined && error.response.data.serviceStatus !== undefined) {
                notification.error({
                    message: <span role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{this.props.app_labels.couldNotcompleteReq}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }
            else {
                notification.error({
                    message: <span role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{this.props.app_labels.couldNotcompleteReq}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }
        })

    }

    /**
     * updates the required states
     */
    updateStates = (requiredArray) => {
        this.setState({ uploadFlag: false, visible: false, jsonData: true, data: requiredArray });
    }

    /**
     * onclick of upload ,uploads the template excell
     */

    handleUpload = (event) => {
        this.setState({ uploadFlag: true })
        loaderFocus(".loader-color .ant-spin-text"); 
        let current = this;
        const { fileList } = this.state;
        var file = fileList[0];
        var reader = new FileReader();
        let resultarray;
        let requiredArray = [];
        reader.onload = function (event) {
            var data = event.target.result;
            if (!rABS) data = new Uint8Array(data);
            var newData = XLSX.read(data, { type: rABS ? 'binary' : 'array' });
            newData.SheetNames.forEach(function (sheetName) {
                resultarray = XLSX.utils.sheet_to_json(newData.Sheets[sheetName], { raw: false, header: 1 });
            });
            for (let i = 1; i < resultarray.length; ++i) {
                if (resultarray[i][5] && (resultarray[i][0] || resultarray[i][1] || resultarray[i][2] || resultarray[i][3] || resultarray[i][4] || resultarray[i][7])) {
                    var obj = {
                        'key': i - 1,
                        'CadreId': resultarray[i][0],
                        'SelectJobRole': resultarray[i][1],
                        'EmailId': resultarray[i][2],
                        'FirstName': resultarray[i][3],
                        'LastName': resultarray[i][4],
                        'CompanyName': resultarray[i][5],
                        'ReportingTo': resultarray[i][6],
                        'MobileNumber': resultarray[i][7],
                        'AltMobileNumber': resultarray[i][8],
                    }
                    requiredArray.push(obj);
                }
            }
            initialData = requiredArray;
            current.setState({ uploadFlag: false, visible: false, jsonData: true, data: requiredArray });
        };
        reader.onerror = console.log
        if (rABS) reader.readAsBinaryString(file);
        else reader.readAsArrayBuffer(file);
        setTimeout(() => {
            document.getElementById('editable-btn') && document.getElementById('editable-btn').focus()
            const div = document.querySelectorAll('.ant-checkbox-input');
            if (div[0]) {
                div.forEach(el => {
                    if (!el.getAttribute('aria-label')) {
                        el.setAttribute('aria-label', 'Select all bulk user profiles');
                        // el.setAttribute('id', 'Select-all-checkbox');
                    }
                })
            }
        }, 500)
    }
    /**
     * onclick of cancel , won't upload the file
     */
    handleCancel = () => {
        this.setState({ visible: false });
    }
    /**
     * onclick of cancel ,bulk data (user profile creation) will be reverted 
     * if data has been edited all that will be reverted on first click(it works as backbutton in general)
     */
    handlebulkCancel = (e) => {
        e.preventDefault();
        if (JSON.stringify(initialData.length) !== JSON.stringify(this.state.data.length)) {
            this.setState({ data: initialData, selectedRowKeys: [], successmsg: false });
        }
        else {
            var noModify = true;
            if (initialData.length === this.state.data.length) {
                for (var i = 0; i < initialData.length; i++) {
                    if (JSON.stringify(initialData[i].FirstName) !== JSON.stringify(this.state.data[i].FirstName)) {
                        noModify = false;
                        this.setState({ data: initialData, selectedRowKeys: [], successmsg: false });
                    }
                    else if (JSON.stringify(initialData[i].LastName) !== JSON.stringify(this.state.data[i].LastName)) {
                        noModify = false;
                        this.setState({ data: initialData, selectedRowKeys: [], successmsg: false });
                    }
                    else if (JSON.stringify(initialData[i].SelectJobRole) !== JSON.stringify(this.state.data[i].SelectJobRole)) {
                        noModify = false;
                        this.setState({ data: initialData, selectedRowKeys: [], successmsg: false });
                    }
                    else if (JSON.stringify(initialData[i].CadreId) !== JSON.stringify(this.state.data[i].CadreId)) {
                        noModify = false;
                        this.setState({ data: initialData, selectedRowKeys: [], successmsg: false });
                    }
                    else if (JSON.stringify(initialData[i].EmailId) !== JSON.stringify(this.state.data[i].EmailId)) {
                        noModify = false;
                        this.setState({ data: initialData, selectedRowKeys: [], successmsg: false });
                    }
                    else if (JSON.stringify(initialData[i].MobileNumber) !== JSON.stringify(this.state.data[i].MobileNumber)) {
                        noModify = false;
                        this.setState({ data: initialData, selectedRowKeys: [], successmsg: false });
                    }
                }
            }
            if (noModify) {
                this.setState({ fileList: [], selectedRowKeys: [], jsonData: false, successmsg: false })
            }
        }
        setTimeout(() => {
            $('.uploadBtnFocus').focus();
        }, 100)
    }
    /**
     * onclick of upload need to display a upload modal
     */
    modalPopUp = () => {
        this.setState({ visible: true });
    }
    /**************Click on edit and then select the boxes*****************/
    /*     edit = (selectedRowKeys) => {
            selectedRowKeys.map((key) => {
                const newData = [...this.state.data];
     
                const target = newData.filter(item => key === item.key)[0];
                if (target) {
                    target.editable = true;
                    this.setState({ data: newData });
                }
            })
        } */
    onSelectChange = (selectedRowKeys) => {
        this.setState({ selectedRowKeys });
        /*  Edit click and then selected checkbox ****/
        /*{ this.state.editiconColor === "#112A70" ? this.edit(selectedRowKeys) : null }*/
        /*  delete click and then selected checkbox ****/
        /* { this.state.deleteiconColor === "#112A70" ? this.delete(selectedRowKeys) : null } */
    }

    render() {
        const { selectedRowKeys } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
            getCheckboxProps: record => ({ // Column configuration not to be checked        
                'aria-checked': false,
                name: !record.FirstName ? "text" : record.FirstName,
                'aria-label': record.FirstName,
                role: "checkbox",
                onKeyDown: checkBoxStatus('#bulk-tab-panel')
            }),
        };
        const columns = [{
            title: `${this.props.app_labels.firstName}`,
            // width: 100,
            dataIndex: 'FirstName',
            render: (text, record) => this.renderColumns(text, record, 'FirstName'),
        }, {
            title: `${this.props.app_labels.lastName}`,
            // width: 100,
            dataIndex: 'LastName',
            render: (text, record) => this.renderColumns(text, record, 'LastName'),
        }, {
            title: `${this.props.app_labels.jobRoleCol}`,
            // width: 100,
            dataIndex: 'SelectJobRole',
            render: (text, record) => this.renderRoleColumns(text, record, 'SelectJobRole'),
        }, {
            title: `${this.props.app_labels.employeeId}`,
            // width: 100,
            dataIndex: 'CadreId',
            render: (text, record) => this.renderColumns(text, record, 'CadreId'),
        }, {
            title: `${this.props.app_labels.emailID}`,
            // width: "10%",
            dataIndex: 'EmailId',
            render: (text, record) => this.renderColumns(text, record, 'EmailId'),
        }, {
            title: `${this.props.app_labels.mobileNumber}`,
            // width: 100,
            dataIndex: 'MobileNumber',
            render: (text, record) => this.renderColumns(text, record, 'MobileNumber'),
        }];


        const uploadprops = {
            name: 'userTemplate',
            onRemove: file => {
                this.setState(({ fileList }) => {
                    const index = fileList.indexOf(file);
                    const newFileList = fileList.slice();
                    newFileList.splice(index, 1);
                    return {
                        fileList: newFileList
                    };
                });
            },
            beforeUpload: file => {
                let fileSize = file.size / 1024 / 1024;
                const xcel = (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
                if (!xcel) {
                    notification.error({
                        message: <span role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{this.props.app_labels.error_uploadExcel}</span>,
                        duration: 3,
                        style: {
                            backgroundColor: '#fff1f0',
                            border: "1px solid #ffa39e",
                        }
                    });
                    return;
                }
                if (fileSize > 5) {
                    notification.warning({
                        message: <span role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{this.props.app_labels.error_fileSize}</span>,
                        duration: 3,
                        style: {
                            backgroundColor: '#fffbe6',
                            border: "1px solid #ffe58f",
                        }
                    });
                    return;
                }
                this.setState(({ fileList }) => ({
                    fileList: [file]
                }));
                return false;
            },
            fileList: this.state.fileList,
        };
        if (this.state.jsonData === false) {
            return (

                <div className="create-trip-container">
                    {/*  <div className="row">
                        <div className="col-sm-10 col-sm-offset-1">
                            {this.state.successmsg === true ? <div className="alert alert-success createuserSuccess"><span style={{ marginLeft: "43%" }}>{this.state.messageContent}</span></div> : null}
                        </div>
                    </div> */}
                    <div className="row" style={{ minHeight: "60vh", maxHeight: "80vh", paddingTop: "9%" }}>
                        <div className="col-xs-12 col-xs-offset-0 col-sm-3 col-sm-offset-2 col-md-3 col-md-offset-3">
                            <button type="button" className="button-createtrip uploadBtnFocus" onClick={this.modalPopUp} style={{ textTransform: 'uppercase' }} ><b>{this.props.app_labels.uploadExcelFile}</b></button>
                        </div>
                        <div className="col-xs-12 col-xs-offset-0 col-sm-3 col-sm-offset-1 col-md-3 col-md-offset-0">
                            <button type="button" className="button-createtrip-ghost width-class"
                                style={{ marginTop: '13%' }}
                                onClick={(e) => {
                                    axios.get(URLs.adminUrl + "downloadTemplate", {
                                        responseType: 'blob',
                                        headers: {
                                            "Authorization": 'Bearer ' + this.props.token.access_token
                                        }
                                    }).then(response => {
                                        if (navigator.msSaveBlob) {                // IE block
                                            var blobObject = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                                            window.navigator.msSaveOrOpenBlob(blobObject, "Template Excel.xlsx");
                                        }
                                        else { //non IE block
                                            var a = document.createElement('a');
                                            a.href = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })); // xhr.response is a blob
                                            a.download = "Template Excel"; // Set the file name.
                                            a.style.display = 'none';
                                            document.body.appendChild(a);
                                            a.click();
                                            document.body.removeChild(a);
                                            setTimeout(function () {
                                                // For Firefox it is necessary to delay revoking the ObjectURL
                                                window.URL.revokeObjectURL(a.href);
                                            }, 100)
                                        }
                                    }).catch(error => {
                                        if (error.response !== undefined && error.response.data !== null && error.response.data !== undefined && error.response.data.serviceStatus !== undefined) {
                                            notification.error({
                                                message: <span role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{this.props.app_labels.couldNotcompleteReq}</span>,
                                                duration: 3,
                                                style: {
                                                    backgroundColor: '#fff1f0',
                                                    border: "1px solid #ffa39e",
                                                }
                                            });
                                        }
                                        else {
                                            notification.error({
                                                message: <span role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{this.props.app_labels.couldNotcompleteReq}</span>,
                                                duration: 3,
                                                style: {
                                                    backgroundColor: '#fff1f0',
                                                    border: "1px solid #ffa39e",
                                                }
                                            });
                                        }
                                    })
                                }}><b>{this.props.app_labels.downloadTemplate}</b></button><br /><br />
                        </div>
                    </div>
                    {this.result}
                    <Modal
                        visible={this.state.visible}
                        /*  afterClose={this.afterClose1} */
                        role="dialog"
                        title={<b className="visually-hidden" id="title">Upload Excel</b>}
                        closable={false}
                        destroyOnClose={true}
                        onCancel={this.handleCancel}
                        footer={null}
                        className='modal_container' >
                        <MediaQuery minDeviceWidth={768}>
                            <div className='row row_center'>
                                <div className='col-md-6 col-md-offset-3 col-sm-10 col-sm-offset-1 col-xs-8 col-xs-offset-2'>
                                    <Dragger {...uploadprops} showUploadList={false} style={{ width: "100%", height: "217px" }} >
                                        {/* <p className="ant-upload-drag-icon">
                                            <Icon type="safety-certificate" style={{ fontSize: "50px", color: "grey" }} />
                                        </p> */}
                                        <p className="ant-upload-text horizontalCentering" style={{ color: "grey" }} ><b>{this.props.app_labels.dragnDropFile}</b></p>
                                        {/* <p className="ant-upload-hint">Support for a single or bulk upload. Strictly prohibit from uploading company data or other band files</p> */}
                                    </Dragger>
                                </div>
                            </div>
                        </MediaQuery>
                        {/*  <Dragger {...uploadprops} showUploadList={false}>
                            <p className="ant-upload-text bulkupload" style={{ color: "grey" }} ><b>DRAG AND DROP EXCEL FILE</b></p>
                        </Dragger */}
                        <div className='row upload-class row_center'>
                            <div className='col-sm-8 col-sm-offset-2 col-xs-8 col-xs-offset-2 col_center'>
                                <Upload {...uploadprops} >
                                    <div style={{ width: "100%", height: "40px" }} className="ant-btn horizontalCentering up-btn" >
                                        <Icon aria-hidden="true" role="img" type="cloud-upload-o" style={{ fontSize: 25, color: "#19398A", paddingTop: '5px' }} /><span className="upload-text">{this.props.app_labels.clickToBrowse}</span>
                                    </div>
                                </Upload>
                            </div>
                        </div>
                        {this.state.uploadFlag ? <div className="dark-spinner loader-color"><Spin tip="Uploading..." /></div> : null}
                        <br />
                        <div className='row'>
                            <div className='col-lg-1 col-lg-offset-3 col-md-2 col-md-offset-3 col-sm-6 col-sm-offset-0 col-xs-6 col-xs-offset-0'>
                                <Button onClick={this.handleCancel} className="horizontalCentering upload-buttons-save button-wrapclass1" type="primary" style={{ textTransform: 'uppercase' }} ghost><b>{this.props.app_labels.cancel}</b></Button>
                            </div>
                            <div className='col-lg-2 col-lg-offset-3 col-md-2 col-md-offset-2 col-sm-6 col-sm-offset-0 col-xs-6 col-xs-offset-0 save-btn-col'>
                                <Button disabled={this.state.fileList.length === 0} onClick={(e) => this.handleUpload(e)} className="horizontalCentering upload-buttons-save upload-btn-sv button-wrapclass1" type="primary" style={{ textTransform: 'uppercase' }}><b>{this.props.app_labels.upload}</b></Button>
                            </div>
                        </div>
                        {/*  <Button onClick={this.handleCancel} style={{ marginTop: "9%", width: "35%", marginLeft: "6%" }} type="primary" ghost><b>CANCEL</b></Button>
                        <span style={{ marginLeft: "14%" }}><Button onClick={(e) => this.handleUpload(e)} style={{ marginTop: "9%", width: "35%" }} type="primary" disabled={this.state.checkboxStatus}><b>UPLOAD</b></Button></span> */}
                    </Modal>
                </div>
            );
        }
        else {
            return (
                <div>
                    <div className="row">
                        <div style={{ textAlign: "right", marginRight: "6%" }} >
                            <div style={{ marginBottom: 16 }}>
                                <span onClick={(e) => {
                                    if (selectedRowKeys.length !== 0) {
                                        if (this.state.editiconColor === "grey") {
                                            this.setState({ editiconColor: "#112A70", deleteiconColor: "grey" });
                                            selectedRowKeys.forEach((key) => {
                                                const newData = [...this.state.data];
                                                const target = newData.filter(item => key === item.key)[0];
                                                if (target) {
                                                    target.editable = true;
                                                    this.setState({ data: newData });
                                                }
                                            })
                                        }
                                        else {
                                            this.setState({ editiconColor: "grey" });
                                            const newData = [...this.state.data];
                                            newData.forEach((item) => {
                                                delete item.editable;
                                                this.setState({ data: newData });
                                                this.cacheData = newData.map(item => ({ ...item }));
                                            })
                                        }
                                    }
                                }} 
                                onKeyDown={(e) => {
                                    if( e.keyCode === 13){
                                        if (selectedRowKeys.length !== 0) {
                                            if (this.state.editiconColor === "grey") {
                                                this.setState({ editiconColor: "#112A70", deleteiconColor: "grey" });
                                                selectedRowKeys.forEach((key) => {
                                                    const newData = [...this.state.data];
                                                    const target = newData.filter(item => key === item.key)[0];
                                                    if (target) {
                                                        target.editable = true;
                                                        this.setState({ data: newData });
                                                    }
                                                })
                                            }
                                            else {
                                                this.setState({ editiconColor: "grey" });
                                                const newData = [...this.state.data];
                                                newData.forEach((item) => {
                                                    delete item.editable;
                                                    this.setState({ data: newData });
                                                    this.cacheData = newData.map(item => ({ ...item }));
                                                })
                                            }
                                        }
                                    }
                                    
                                }}>
                                    <i id='editable-btn' className="icon-Edit" role="button" aria-label="Edit" tabIndex="0" style={{ fontSize: "20px", color: this.state.editiconColor }}></i>
                                </span>
                                <span className="left-space" onClick={(e) => {
                                    if (selectedRowKeys.length !== 0) {
                                        var currentnew = this;
                                        confirm({
                                            title: 'Do you want to delete selected rows?',
                                            onOk() {
                                                currentnew.setState({ editiconColor: "grey", deleteiconColor: "#112A70" })
                                                {
                                                    const newData = [...currentnew.state.data];
                                                    selectedRowKeys.forEach((key) => {

                                                        const target = newData.filter(item => key === item.key)[0];
                                                        if (target) {
                                                            const index = newData.indexOf(target);
                                                            //const newdata = newData.slice();
                                                            newData.splice(index, 1);
                                                            currentnew.setState({ data: newData });
                                                            currentnew.cacheData = newData.map(item => ({ ...item }));
                                                        }
                                                    })
                                                    currentnew.setState({ data: newData });
                                                    currentnew.cacheData = newData.map(item => ({ ...item }));
                                                }
                                            }
                                            ,
                                            onCancel() { },
                                        });
                                    }
                                }}  >
                                    <i className="icon-Delete" style={{ fontSize: "20px", color: this.state.deleteiconColor }}></i>
                                </span>
                            </div>
                        </div>
                    </div>
                    <Table style={{ marginRight: "2%" }}
                        rowSelection={rowSelection}
                        columns={columns}
                        scroll={{ x: 970 }}
                        dataSource={this.state.data}
                        pagination={false} />
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-sm-offset-0 col-md-12 col-md-offset-0" style={{ textAlign: 'right', marginLeft: '-2%' }}>
                            <button style={{ marginTop: "3%", textTransform: 'uppercase' }} className="focusbutton button-wrapclass buttonRightPadding button-createtrip-ghost" onClick={this.handlebulkCancel}>{this.props.app_labels.cancel}</button>

                            <span><button style={{ marginTop: "3%", textTransform: 'uppercase', marginLeft: '2%' }} className="focusbutton button-createtrip button-wrapclass" onClick={(e) => {
                                this.setState({ editiconColor: "grey", deleteiconColor: "grey" })
                                {
                                    const newData = [...this.state.data];
                                    newData.forEach((item) => {
                                        delete item.editable;
                                        this.setState({ data: newData });
                                        this.cacheData = newData.forEach(item => ({ ...item }));
                                    })
                                }
                                this.handleSave();
                            }} >{this.props.app_labels.save}</button></span>
                        </div>
                    </div>
                </div >
            );
        }
    }
}
function mapStateToProps(state, props) {
    return {
        token: state.authenticationReducer.token,
        userDetails: state.authenticationReducer.userDetails,
    };
}
export default connect(mapStateToProps)(CreateBulkUserProfileComponent);
