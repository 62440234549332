import React from 'react';
import { Link } from 'react-router-dom';/***important for Link to work****/
import { Button, Icon, Avatar, notification, Modal, Spin } from 'antd';
import "antd/dist/antd.css";
import { connect } from 'react-redux';
import { loaderFocus } from '../../utils/accessability';
import { getUserActions } from '../../actions/userAction';
import { getTrips } from '../../actions/gettripsActions';
import { tripStatus } from '../../actions/changetripstatusAction';
import { getAllDetails } from '../../actions/getAllDetails';
import MediaQuery from 'react-responsive';
import DashFlightFileUpload from './flightuploadDash';
import DashHotelFileUpload from './hoteluploadDash';
import ViewCardDetails from './viewcarddetails';
import { settripId } from '../../actions/createtripActions';
import axios from 'axios';
import GreenBar from '../../images/Green_1.svg';
import Greybar from '../../images/Grey_3.svg';
import Bluebar from '../../images/Blue_2.svg';
const confirm = Modal.confirm;
const { progressBarHoverText_Blue } = require('../../data/constants.js')
const { app_labels } = require('../../data/english_label.js');
const { URLs, tripStatusConstants } = require('../../data/constants.js');
/*
*renders when any perticular user's profile is clicked on dashboard screen
*/
class DashContent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            deleteFileFlag: false,
            flightupload: false,
            visible: false,
            hotelupload: false,
            ViewCard: false,
        }
    }

    /**called on Mount of Component and sets the trip Id in state variables
     */
    componentDidMount() {
        this.props.settripID(this.props.data.tripId);
    }

    /**Enable the card Details
    */
    viewCardDetails = () => {
        this.setState({
            ViewCard: true,
        });
    }

    /**will hide the card Details
    */
    hideCardDetails = () => {
        this.setState({
            ViewCard: false,
        });
    }

    /**To display texts in correct foramt(First letter will be in Capital)
    * @param  {} text
    */
    capitalize = (text) => {
        if (text !== undefined) {
            var s = text.toLowerCase();
            if (typeof s !== 'string') return ''
            return s.charAt(0).toUpperCase() + s.slice(1)
        }
    }
    /*******************deleteAll files******/
    deleteAllFile = (e, type, tripId) => {
        var current = this;

        confirm({
            title: <span className="cardHeading">{this.props.app_labels.confirmDeleteFileAll}</span>,
            onOk() {
                current.setState({ deleteFileFlag: true });
                loaderFocus(".loader-color .ant-spin-text");
                axios.get(URLs.fileUrl + "delete/" + type + "/" + tripId, {
                    responseType: 'json',
                    headers: {
                        "Authorization": 'Bearer ' + current.props.token.access_token
                    }
                }).then(response => {
                    if (response.data.serviceStatus.statusCode === 200) {
                        current.props.gettrips();
                        current.props.getuseractions();
                        current.props.getalldetails();
                        notification.success({
                            message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Success </span>{response.data.serviceStatus.message}</span>,
                            duration: 3,
                            style: {
                                backgroundColor: '#dff0d8',
                                color: "green",
                                border: "1px solid green",
                            }
                        });
                        current.setState({ deleteFileFlag: false });
                    }
                    else if (response.data.serviceStatus.statusCode === 210) {
                        current.setState({ deleteFileFlag: false });
                        notification.error({
                            message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Error </span>{response.data.serviceStatus.message}</span>,
                            duration: 3,
                            style: {
                                backgroundColor: '#fff1f0',
                                border: "1px solid #ffa39e",
                            }
                        });
                    }
                }).catch((error) => {
                    current.setState({ deleteFileFlag: false });
                    if (error.response !== undefined && error.response.data !== null && error.response.data !== undefined && error.response.data.serviceStatus !== undefined) {
                        notification.error({
                            message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Error </span>{error.response.data.serviceStatus.message}</span>,
                            duration: 3,
                            style: {
                                backgroundColor: '#fff1f0',
                                border: "1px solid #ffa39e",
                            }
                        });
                    }
                    else {
                        notification.error({
                            message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Error </span>{this.props.app_labels.couldNotcompleteReq}</span>,
                            duration: 3,
                            style: {
                                backgroundColor: '#fff1f0',
                                border: "1px solid #ffa39e",
                            }
                        });
                    }
                })

            },
            onCancel() { },
        });


    }

    /**Deletes the Flight itenary
     * @param  {} e
     * @param  {} attachmentID
     */
    deleteFile = (e, attachmentID) => {
        var current = this;
        
        confirm({
            title: <span className="cardHeading">{this.props.app_labels.confirmDeleteFileOne}</span>,
            onOk() {
                current.setState({ deleteFileFlag: true });
                loaderFocus(".loader-color .ant-spin-text");
                axios.get(URLs.fileUrl + "delete/" + attachmentID.toString(), {
                    responseType: 'json',
                    headers: {
                        "Authorization": 'Bearer ' + current.props.token.access_token
                    }
                }).then(response => {
                    if (response.data.serviceStatus.statusCode === 200) {
                        current.props.gettrips();
                        current.props.getuseractions();
                        current.props.getalldetails();
                        notification.success({
                            message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Success </span>{response.data.serviceStatus.message}</span>,
                            duration: 3,
                            style: {
                                backgroundColor: '#dff0d8',
                                color: "green",
                                border: "1px solid green",
                            }
                        });
                        current.setState({ deleteFileFlag: false });
                    }
                    else if (response.data.serviceStatus.statusCode === 210) {
                        current.setState({ deleteFileFlag: false });
                        notification.error({
                            message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Error </span>{response.data.serviceStatus.message}</span>,
                            duration: 3,
                            style: {
                                backgroundColor: '#fff1f0',
                                border: "1px solid #ffa39e",
                            }
                        });
                    }
                }).catch((error) => {
                    current.setState({ deleteFileFlag: false });
                    if (error.response !== undefined && error.response.data !== null && error.response.data !== undefined && error.response.data.serviceStatus !== undefined) {
                        notification.error({
                            message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Error </span>{error.response.data.serviceStatus.message}</span>,
                            duration: 3,
                            style: {
                                backgroundColor: '#fff1f0',
                                border: "1px solid #ffa39e",
                            }
                        });
                    }
                    else {
                        notification.error({
                            message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Error </span>{this.props.app_labels.couldNotcompleteReq}</span>,
                            duration: 3,
                            style: {
                                backgroundColor: '#fff1f0',
                                border: "1px solid #ffa39e",
                            }
                        });
                    }
                })

            },
            onCancel() { },
        });


    }

    /**downloads the selected file onclick of file name
     * @param  {} e
     * @param  {} url
     * @param  {} filename
     */
    downloadFile = (e, url, filename, attachmentId) => {
        /*  var uri = '/cadre' + url.split('/cadre')[1] */
        axios.get(URLs.fileUrl + 'downloads/' + attachmentId, {
            responseType: 'blob',
            headers: {
                "Authorization": 'Bearer ' + this.props.token.access_token
            }
        }).then(response => {
            if (navigator.msSaveBlob) {                // ie block
                var blobObject = new Blob([response.data], { type: 'application/pdf' });
                window.navigator.msSaveOrOpenBlob(blobObject, filename + '.pdf');
            }
            else { //non IE block
                var a = document.createElement('a');
                a.href = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' })); // xhr.response is a blob
                a.download = filename; // Set the file name.
                a.style.display = 'none';
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                setTimeout(function () {
                    window.URL.revokeObjectURL(a.href);
                }, 100)
            }
        }).catch(error => {
            if (error.response !== undefined && error.response.data !== null && error.response.data !== undefined && error.response.data.serviceStatus !== undefined) {
                notification.error({
                    message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Error </span>{error.response.data.serviceStatus.message}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }
            else {
                notification.error({
                    message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Error </span>{this.props.app_labels.couldNotcompleteReq}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }
        })
    }

    /**Enable the upload Flight Itenerary Pop up
     */
    showflightModal = () => {
        this.setState({
            flightupload: true,
            visible: true
        });
    }

    /**Enable the upload Hotel Itenerary Pop up
    */
    showhotelModal = () => {
        this.setState({
            hotelupload: true,
            visible: true
        });
    }

    /**Hide the upload hotel Itenerary Pop up
    */
    hideFligthModal = () => {
        this.setState({ visible: false, flightupload: false })
    }

    /**Hide the upload Flight Itenerary Pop up
    */
    hideHotelModal = () => {
        this.setState({ visible: false, hotelupload: false })
    }

    /**changes the trip status to cancel
     */
    cancelTrip = () => {
        var current = this;
        confirm({
            title: current.props.data.creditCard.length !== 0 ?
                <div><i role='img' aria-label='attention' title='attention' className='icon-attention' style={{ float: 'left', fontSize: '23px', color: '#faad14' }} />
                    <div className="cardHeading">{this.props.app_labels.cardAssignedTrip}</div>
                    <div className="modalheader">{this.props.app_labels.cardInfo}</div>
                    <div className="modalheader" style={{ marginTop: '2%' }}><b>{this.props.app_labels.cancelTripConfirmation}</b></div>
                </div> : <div className="cardHeading">{this.props.app_labels.confirmCancelTrip}</div>,
            okText: 'Yes',
            cancelText: 'No',
            onOk() {
                current.props.changeTripStatus(current.props.data.tripId, "Cancelled", null, current.props.hyperlinkFunc);
            },
            onCancel() { },
        });
    }

    /**changes the trip status to Closed
     */
    closeTrip = () => {
        var current = this;
        confirm({
            title: <div className="cardHeading">{this.props.app_labels.confirmCloseTrip}</div>,
            okText: 'Yes',
            cancelText: 'No',
            onOk() {
                current.props.changeTripStatus(current.props.data.tripId, "CloseTripByHM", current.props.data.traveler.travelerId);

            },
            onCancel() { },
        });
    }

    render() {
        var selectedtrip = this.props.data;
        return (
            <div className="container-fluid">
                <div style={{ fontSize: '26px', fontWeight: "bold", color: "#1F337C", paddingLeft: "3%", paddingTop: "2%" }}>{app_labels.tripDetails}</div>
                <div className="dash-content-box">
                    {/********************    progress bar ****************/}
                    <MediaQuery minDeviceWidth={770}>
                        <div className="row" style={{ marginLeft: "4%", marginTop: "2%" }}>
                            <div className="col-sm-2 col-sm-offset-0">
                                {selectedtrip.cardStatus === "green" || selectedtrip.cardStatus === "no display" ?
                                    <img alt = "" src={GreenBar} style={{ width: "100%" }} title={progressBarHoverText_Blue.createCard} /> : <img alt = "" src={Bluebar} style={{ width: "100%" }} title={progressBarHoverText_Blue.createCard} />}
                            </div>
                            <div className="col-sm-2 col-sm-offset-0 progressbar-margin">
                                {selectedtrip.flightStatus === "green" || selectedtrip.flightStatus === "no display" ?
                                    <img alt = "" src={GreenBar} style={{ width: "100%" }} title={progressBarHoverText_Blue.flighItenary} /> : <img alt = "" src={Bluebar} style={{ width: "100%" }} title={progressBarHoverText_Blue.flighItenary} />}
                            </div>
                            <div className="col-sm-2 col-sm-offset-0 progressbar-margin">
                                {selectedtrip.hotelStatus === "green" || selectedtrip.hotelStatus === "no display" ?
                                    <img alt = "" src={GreenBar} style={{ width: "100%" }} title={progressBarHoverText_Blue.hotelItenary} /> : <img alt = "" src={Bluebar} style={{ width: "100%" }} title={progressBarHoverText_Blue.hotelItenary} />}
                            </div>
                            <div className="col-sm-2 col-sm-offset-0 progressbar-margin">

                                {selectedtrip.flightApplicable === true && selectedtrip.hotelApplicable === true && selectedtrip.hotelStatus === "green" && selectedtrip.flightStatus === "green" && selectedtrip.travelerNotified === false ? <img alt = "" src={Bluebar} style={{ width: "100%" }} title={progressBarHoverText_Blue.sendInfo} /> : null}
                                {selectedtrip.flightApplicable === true && selectedtrip.hotelApplicable === true && (selectedtrip.hotelStatus === "red" || selectedtrip.flightStatus === "red") && selectedtrip.travelerNotified === false ? <img alt = "" src={Greybar} style={{ width: "100%" }} title={progressBarHoverText_Blue.sendInfo} /> : null}
                                {selectedtrip.flightApplicable === true && selectedtrip.hotelApplicable === true && selectedtrip.hotelStatus === "green" && selectedtrip.flightStatus === "green" && selectedtrip.travelerNotified === true ? <img alt = "" src={GreenBar} style={{ width: "100%" }} title={progressBarHoverText_Blue.sendInfo} /> : null}
                                {selectedtrip.flightApplicable === true && selectedtrip.hotelApplicable === true && (selectedtrip.hotelStatus === "red" || selectedtrip.flightStatus === "red") && selectedtrip.travelerNotified === true ? <img alt = "" src={Greybar} style={{ width: "100%" }} title={progressBarHoverText_Blue.sendInfo} /> : null}


                                {selectedtrip.flightApplicable === false && selectedtrip.hotelApplicable === false && selectedtrip.travelerNotified === true ? <img alt = "" src={GreenBar} style={{ width: "100%" }} title={progressBarHoverText_Blue.sendInfo} /> : null}
                                {selectedtrip.flightApplicable === false && selectedtrip.hotelApplicable === false && selectedtrip.travelerNotified === false ? <img alt = "" src={Bluebar} style={{ width: "100%" }} title={progressBarHoverText_Blue.sendInfo} /> : null}

                                {selectedtrip.flightApplicable === true && selectedtrip.hotelApplicable === false && selectedtrip.flightStatus === "green" && selectedtrip.travelerNotified === false ? <img alt = "" src={Bluebar} style={{ width: "100%" }} title={progressBarHoverText_Blue.sendInfo} /> : null}
                                {selectedtrip.flightApplicable === true && selectedtrip.hotelApplicable === false && selectedtrip.flightStatus === "red" && selectedtrip.travelerNotified === false ? <img alt = "" src={Greybar} style={{ width: "100%" }} title={progressBarHoverText_Blue.sendInfo} /> : null}
                                {selectedtrip.flightApplicable === true && selectedtrip.hotelApplicable === false && selectedtrip.flightStatus === "green" && selectedtrip.travelerNotified === true ? <img alt = "" src={GreenBar} style={{ width: "100%" }} title={progressBarHoverText_Blue.sendInfo} /> : null}

                                {selectedtrip.flightApplicable === false && selectedtrip.hotelApplicable === true && selectedtrip.hotelStatus === "green" && selectedtrip.travelerNotified === false ? <img alt = "" src={Bluebar} style={{ width: "100%" }} title={progressBarHoverText_Blue.sendInfo} /> : null}
                                {selectedtrip.flightApplicable === false && selectedtrip.hotelApplicable === true && selectedtrip.hotelStatus === "red" && selectedtrip.travelerNotified === false ? <img alt = "" src={Greybar} style={{ width: "100%" }} title={progressBarHoverText_Blue.sendInfo} /> : null}
                                {selectedtrip.flightApplicable === false && selectedtrip.hotelApplicable === true && selectedtrip.hotelStatus === "green" && selectedtrip.travelerNotified === true ? <img alt = "" src={GreenBar} style={{ width: "100%" }} title={progressBarHoverText_Blue.sendInfo} /> : null}

                            </div>
                            {/* <div className="col-sm-2 col-sm-offset-0  progressbar-margin">
                                {(selectedtrip.tripActionStatus.description.toLowerCase() === tripStatusConstants.AwaitingTravel.toLowerCase() || selectedtrip.tripActionStatus.description.toLowerCase() === tripStatusConstants.PendingClosure.toLowerCase() || selectedtrip.tripActionStatus.description.toLowerCase() === tripStatusConstants.InProgress.toLowerCase() || selectedtrip.tripActionStatus.description.toLowerCase() === tripStatusConstants.PendingExpenseReporting.toLowerCase()) && selectedtrip.tripActionStatus.description.toLowerCase() !== tripStatusConstants.Complete.toLowerCase() ?
                                    <img src={Bluebar} style={{ width: "100%" }} title={progressBarHoverText_Blue.completeTrip} /> : null}
                                {selectedtrip.tripActionStatus.description.toLowerCase() !== tripStatusConstants.AwaitingTravel.toLowerCase() && selectedtrip.tripActionStatus.description.toLowerCase() !== tripStatusConstants.PendingClosure.toLowerCase() && selectedtrip.tripActionStatus.description.toLowerCase() !== tripStatusConstants.InProgress.toLowerCase() && selectedtrip.tripActionStatus.description.toLowerCase() !== tripStatusConstants.PendingExpenseReporting.toLowerCase() && selectedtrip.tripActionStatus.description.toLowerCase() !== tripStatusConstants.Complete.toLowerCase() ? <img src={Greybar} style={{ width: "100%" }} title={progressBarHoverText_Blue.completeTrip} /> : null}
                                {selectedtrip.tripActionStatus.description.toLowerCase() === tripStatusConstants.Complete.toLowerCase() ? <img src={GreenBar} style={{ width: "100%" }} title={progressBarHoverText_Blue.completeTrip} /> : null}
                            </div> */}
                            <div className="col-sm-2 col-sm-offset-0  progressbar-margin">
                                {selectedtrip.tripActionStatus.description.toLowerCase() !== tripStatusConstants.PendingClosure.toLowerCase() && selectedtrip.tripActionStatus.description.toLowerCase() !== tripStatusConstants.Closed.toLowerCase() ? <img alt = "" src={Greybar} style={{ width: "100%" }} title={progressBarHoverText_Blue.closeTrip} /> : null}
                                {selectedtrip.tripActionStatus.description.toLowerCase() === tripStatusConstants.PendingClosure.toLowerCase() ? <img alt = "" src={Bluebar} style={{ width: "100%" }} title={progressBarHoverText_Blue.closeTrip} /> : null}
                                {selectedtrip.tripActionStatus.description.toLowerCase() === tripStatusConstants.Closed.toLowerCase() ? <img alt = "" src={GreenBar} style={{ width: "100%" }} title={progressBarHoverText_Blue.closeTrip} /> : null}

                            </div>
                        </div>
                    </MediaQuery >
                    {/********************   end of  progress bar ****************/}
                    <div className="row">
                        <div className="col-sm-1 col-sm-offset-0 col-xs-2 col-xs-offset-0 avatarSpaceClass">
                            <Avatar style={{ backgroundColor: "#112A70", verticalAlign: 'middle' }} size="large">  <b>{selectedtrip.initials}</b>
                            </Avatar>
                        </div>
                        <div className="col-sm-6 col-sm-offset-0  col-xs-8 col-xs-offset-0">
                            <div className="user-account-div">
                                <div className="account-user-name" style={{ wordBreak: 'break-all' }}>{this.capitalize(selectedtrip.firstName) + " " + this.capitalize(selectedtrip.lastName)}</div>
                                <div className="dash-content">{this.props.app_labels.travelerId} : {selectedtrip.traveler.cadreId ? selectedtrip.traveler.cadreId.trim() : selectedtrip.traveler.cadreId}</div>
                                <div className="dash-content">{this.props.app_labels.startDate} : {selectedtrip.actualStartDate === null ? selectedtrip.estimatedStartDate : selectedtrip.actualStartDate}</div>
                                <div className="dash-content">Travel End Date : {selectedtrip.actualEndDate === null ? selectedtrip.estimatedEndDate : selectedtrip.actualEndDate}</div>
                                <div className="dash-content">{this.props.app_labels.email}  : {selectedtrip.emailId.trim()}</div>
                                <div className="dash-content">{this.props.app_labels.mobileNo} : {selectedtrip.phoneNo.trim().toString().split(' ')[1]
                                    ? (selectedtrip.phoneNo.trim().toString().split(' ')[0] + ' ' + selectedtrip.phoneNo.trim().toString().split(' ')[1].slice(0, 3) + ' ' + selectedtrip.phoneNo.trim().toString().split(' ')[1].slice(3, 6) + ' ' + selectedtrip.phoneNo.trim().toString().split(' ')[1].slice(6))
                                    : (selectedtrip.phoneNo.trim().toString().split(' ')[0].slice(0, 3) + ' ' + selectedtrip.phoneNo.trim().toString().split(' ')[0].slice(3, 6) + ' ' + selectedtrip.phoneNo.trim().toString().split(' ')[0].slice(6))}
                                </div></div>
                        </div>
                        <div className="col-sm-3 col-sm-offset-1 col-xs-9 col-xs-offset-2">
                            <div className="dash-Account">
                                <div className="row" style={{ marginTop: "2%" }}>
                                    <div className="col-sm-3 col-sm-offset-0" style={{ marginTop: "2%" }}>
                                        <i role='img' aria-label='Accounting code' title='Accounting code' className="icon-Money-Dollar icon-account" ></i>
                                    </div>
                                    <div className="col-sm-7 col-sm-offset-1" style={{ textAlign: "end" }}>
                                        <span className='account-label'>{this.props.app_labels.accountingCode}</span><br />
                                        <div className="account-details" style={{ fontSize: "normal" }}> <b>{selectedtrip.accountingCode !== "" ? selectedtrip.accountingCode : 'NA'}</b></div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-7 col-sm-offset-4" style={{ textAlign: "end" }}>
                                        <span className='account-label'>{this.props.app_labels.presentCardLimit}</span>
                                        <div className="account-details"><b>${parseInt(selectedtrip.budgetAmt).toFixed(2)}</b></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-11 col-sm-offset-1  col-xs-12 col-xs-offset-0">
                            <div className="user-account-div">
                                {selectedtrip.isTripDelegated ? <div className="dash-content" style={{ color: "rgb(10, 65, 197)" }}>
                                    <Icon type="exclamation-circle" /> {this.props.app_labels.delegatedFrom} {selectedtrip.delegatedFrom} to {selectedtrip.delegatedTo}  {selectedtrip.delegatedTill === "12/31/9999" ? "permanently" :
                                        "till " + selectedtrip.delegatedTill}</div> : null}
                            </div>
                        </div>
                    </div>


                    <div className="dash-card">
                        <div className="row row_center">
                            {selectedtrip.cardStatus === "red" ?
                                <div className='fullwidth'>
                                    <div className="col-sm-2 col-sm-offset-0 col-xs-3 col-xs-offset-0 col-md-2 col-md-offset-0 col-lg-1 col-lg-offset-0 col_center">
                                        <div className="dashAll-icon">
                                            <i role='img' aria-label='credit card' title='Credit card' className="icon-Credit-Card-grey-size1 icon-class cardIcon" style={{ lineHeight: 'unset' }} ></i>
                                        </div>
                                    </div>
                                    <div className="col-sm-4 col-sm-offset-0 col-xs-8 col-xs-offset-0 col_center_content">
                                        <div className="dash-carddetails">
                                            <div className='contentHeading'>{this.props.app_labels.cardStatus}</div>
                                            <div className='dash-tripdetails'>{this.props.app_labels.notSpecified}</div>
                                        </div>
                                    </div>
                                </div>
                                : null}
                            {selectedtrip.cardStatus === "no display" ? <div className='fullwidth'>
                                <div className="col-sm-2 col-sm-offset-0 col-xs-3 col-xs-offset-0 col-md-2 col-md-offset-0 col-lg-1 col-lg-offset-0 col_center">
                                    <div className="dashAll-icon">
                                        <i role='img' aria-label='credit card' title='Credit card' className="icon-Credit-Card-grey-size1 icon-class cardIcon" style={{ lineHeight: 'unset' }} ></i>
                                    </div>
                                </div>
                                <div className="col-sm-2 col-sm-offset-0 col-xs-5 col-xs-offset-0 col_center_content">
                                    <div className="dash-carddetails">
                                        <div style={{ color: "#636466" }}>{this.props.app_labels.cardStatus}</div>
                                        <div style={{ size: "medium", color: "#636466" }}><b>NA</b></div>
                                    </div>
                                </div>
                            </div> : null}

                            {selectedtrip.cardStatus === "green" ? <div className='fullwidth'>
                                <div className="col-sm-2 col-sm-offset-0 col-xs-3 col-xs-offset-0 col-md-2 col-md-offset-0 col-lg-1 col-lg-offset-0 col_center">
                                    <div className="dashAll-icon">
                                        <i role='img' aria-label='credit card' title='Credit card' className="icon-Credit-Card02 icon-class cardIcon" style={{ lineHeight: 'unset' }} ></i>
                                    </div>
                                </div>

                                <MediaQuery minDeviceWidth={771}>
                                    <div className="col-sm-4 col-sm-offset-0 col-xs-6 col-xs-offset-0 col-md-6 col-md-offset-0 col-lg-6 col-lg-offset-0 col_center_content">
                                        <div className="dash-carddetails">
                                            <div className='contentHeading'>{this.props.app_labels.cardStatus}</div>
                                            <div className='dash-tripdetails'>{this.props.app_labels.virtualCard}</div>
                                        </div>
                                    </div>
                                    <div className="col-sm-3 col-sm-offset-3 col-xs-4 col-xs-offset-0 col-md-3 col-md-offset-0 col-lg-3 col-lg-offset-2 col_center_content">
                                        <div className="view-card" style={{ cursor: 'pointer' }} onClick={this.viewCardDetails} ><i role='img' aria-label='view card' title='view card details' className="icon-show-password" style={{ color: "#112A70" }} /><span style={{ color: "#112A70" }}> {app_labels.viewCardDetails}</span></div>
                                        {this.state.ViewCard === true ? <ViewCardDetails username={this.props.username} /*sending logged in username for otp validation*/ app_labels = {this.props.app_labels} action={this.state.ViewCard} hideCardDetails={this.hideCardDetails} selectedtrip1={selectedtrip} /> : null}
                                    </div>
                                </MediaQuery>
                                <MediaQuery maxDeviceWidth={770}>
                                    <div className="col-sm-4 col-sm-offset-0 col-xs-6 col-xs-offset-0">
                                        <div className="dash-carddetails">
                                            <div className='contentHeading'>{this.props.app_labels.cardStatus}</div>
                                            <div className='dash-tripdetails'>{this.props.app_labels.virtualCard}</div>
                                        </div>
                                    </div>

                                    <div className="col-sm-3 col-sm-offset-3 col-xs-8 col-xs-offset-3">
                                        <div className="view-card" style={{ cursor: 'pointer' }} onClick={this.viewCardDetails} ><i role='img' aria-label='view card' title='view card details' className="icon-show-password" style={{ color: "#112A70" }} /><span style={{ color: "#112A70" }}> {this.props.app_labels.viewCardDetails}</span></div>
                                        {this.state.ViewCard === true ? <ViewCardDetails username={this.props.username} /*sending logged in username for otp validation*/ app_labels = {this.props.app_labels} action={this.state.ViewCard} hideCardDetails={this.hideCardDetails} selectedtrip1={selectedtrip} /> : null}
                                    </div>

                                </MediaQuery>
                            </div> : null}
                        </div>
                    </div>
                    {this.props.isDbCardFetching ? <div className="dark-spinner loader-color"><Spin tip="retrieving..." /></div> : null}
                    {this.state.deleteFileFlag ? <div className="dark-spinner loader-color"><Spin tip="Uploading..." /></div> : null}
                    {selectedtrip.flightStatus === "red" || selectedtrip.flightStatus === "green" || selectedtrip.flightStatus === "no display" ?
                        <div className="dash-flight">
                            <div className="row row_center">
                                <div className="col-sm-2 col-sm-offset-0 col-xs-12 col-xs-offset-0 col-md-2 col-md-offset-0 col-lg-1 col-lg-offset-0 col_center">
                                    <div className="dashAll-icon">
                                        {selectedtrip.flightStatus === "red" || selectedtrip.flightStatus === "no display" ? <i role='img' aria-label='Flight' title='Flight' className="icon-Airplane-grey-size-1 icon-class" style={{ color: "grey" }} ></i> : null}
                                        {selectedtrip.flightStatus === "green" ? <i role='img' aria-label='Flight' title='Flight' className="icon-Airplane-blue-size-1 icon-class" style={{ color: "#1B3281" }} ></i> : null}
                                    </div>
                                </div>
                                <div style={{ minHeight: '42px' }} className="col-sm-2 col-sm-offset-0 col-xs-7 col-xs-offset-0 col_center">
                                    <div className="dash-itenarydetails">
                                        <div className='contentHeading'>{this.props.app_labels.flightDetails}</div>
                                    </div>
                                </div>
                                <div className="col-sm-2 col-sm-offset-0 col-xs-6 col-xs-offset-0 col_center left-margin-dashboard">
                                    <div className="dashtravel-date">
                                        <div className='contentHeading'>{this.props.app_labels.fromDate}</div>
                                        <div className="dash-tripdetails">{selectedtrip.actualStartDate === null ? selectedtrip.estimatedStartDate : selectedtrip.actualStartDate}</div>
                                    </div>
                                </div>
                                <div className="col-sm-2 col-sm-offset-0 col-xs-6 col-xs-offset-0 col_center left-margin-dashboard">
                                    <div className="dashtravel-date">
                                        <div className='contentHeading'>{this.props.app_labels.toDate}</div>
                                        <div className="dash-tripdetails">{selectedtrip.actualEndDate === null ? selectedtrip.estimatedEndDate : selectedtrip.actualEndDate}</div>
                                    </div>
                                </div>
                                <div className="col-sm-2 col-sm-offset-0 col-xs-6 col-xs-offset-0 col_center left-margin-dashboard">
                                    <div className="dashtravel-date">
                                        {selectedtrip.tripActionStatus.description !== "Cancelled"
                                            ? (<Link to={{ pathname: "/pendingTrip", overallTripFocusSource: true }}>
                                                <div className='contentHeading'>{this.props.app_labels.origin}</div>
                                                <div className="dash-tripdetails" style={{ wordBreak: 'break-all' }}>{this.capitalize(selectedtrip.source.split("(")[0])}</div>
                                                {selectedtrip.source.split("(").length === 2 ? <div className="dash-tripdetails">({selectedtrip.source.split("(")[1]}</div> : null}
                                            </Link>)
                                            : (<div><div className='contentHeading'>{this.props.app_labels.origin}</div>
                                                <div className="dash-tripdetails" style={{ wordBreak: 'break-all' }}>{this.capitalize(selectedtrip.source.split("(")[0])}</div>
                                                {selectedtrip.source.split("(").length === 2 ? <div className="dash-tripdetails">({selectedtrip.source.split("(")[1]}</div> : null}</div>
                                            )}
                                    </div>
                                </div>
                                <div className="col-sm-2 col-sm-offset-0 col-xs-6 col-xs-offset-0 col_center left-margin-dashboard">
                                    <div className="dashtravel-date">
                                        {selectedtrip.tripActionStatus.description !== "Cancelled"
                                            ? (<Link to={{ pathname: "/pendingTrip", overallTripFocusDest: true }} >
                                                <div className='contentHeading'>{this.props.app_labels.destination1}</div>
                                                <div className="dash-tripdetails" style={{ wordBreak: 'break-all' }}>{this.capitalize(selectedtrip.destination.split("(")[0])}</div>
                                                {selectedtrip.destination.split("(").length === 2 ? <div className="dash-tripdetails">({selectedtrip.destination.split("(")[1]}</div> : null}
                                            </Link>)
                                            : (<div><div className='contentHeading'>{this.props.app_labels.destination1}</div>
                                                <div className="dash-tripdetails" style={{ wordBreak: 'break-all' }}>{this.capitalize(selectedtrip.destination.split("(")[0])}</div>
                                                {selectedtrip.destination.split("(").length === 2 ? <div className="dash-tripdetails">({selectedtrip.destination.split("(")[1]}</div> : null}
                                            </div>
                                            )}
                                    </div>
                                </div>
                                {/*        <div className="col-sm-2 col-sm-offset-0 col-xs-7 col-xs-offset-0 col_center">
                                    <div className="dash-itenarydetails">
                                        {selectedtrip.flightStatus === "red" ? <div className='contentHeading'>Pdf upload pending</div> : null}
                                        {selectedtrip.flightStatus === "green" ? <div className='contentHeading' style={{ color: "#112A70" }}>Pdf uploaded </div> : null}
                                      
                                    </div>
                                </div> */}

                            </div>

                            <div className="row">
                                <br /><hr className="dash-filelist-line" />
                                <div className="col-sm-9 col-sm-offset-1 col-xs-7 col-xs-offset-1 col-md-9 col-md-offset-1">
                                    {
                                        selectedtrip.flightAttachments.map((object) =>{
                                            return (
                                                <div style = {{display : "flex",alignItems : "center"}}>
                                                    <div title={object.fileName}
                                                    style={{ color: "#112A70",marginRight : 16 }}><p className = "a1-link" onClick={(e) => this.downloadFile(e, object.locationUrl, object.fileName, object.tripAttachmentId)} download>{object.fileName.length < 56 ? object.fileName : object.fileName.substring(0, 56) + "...."}</p></div>
                                                    {selectedtrip.flightApplicable ? <div > <i role='img' aria-label='delete' title='delete' className="icon-Close" style={{ color: '#C53D47', textDecoration : "none",fontWeight: 'bold' }} onClick={(e) => this.deleteFile(e, object.tripAttachmentId)} /></div> : null}
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <div className="col-sm-2 col-sm-offset-0 col-xs-2 col-xs-offset-0 col-md-2 col-md-offset-0 col-lg-2 col-lg-offset-0 col_center">
                                    <div className="dash-upload">
                                        {selectedtrip.flightApplicable && selectedtrip.flightAttachments.length !== 0 ? <i role='img' aria-label='Delete' title='Delete' className="icon-Trash-Garbage-Can uploadBtnClass" style={{ fontSize: 22 }} onClick={(e) => this.deleteAllFile(e, 'F', selectedtrip.tripId)}  ></i> : null}
                                        {/* {this.state.flightupload === true ? <DashFlightFileUpload action={this.state.visible} selectedtrip1={selectedtrip} hideFligthModal={this.hideFligthModal} /> : null}
                                    </div>
                                    <div className="dash-upload"> */}
                                        <i role='img' aria-label='Upload attachment' title='upload' className="icon-Upload-attachment uploadBtnClass" style={{ fontSize: 22 }} onClick={this.showflightModal} ></i>
                                        {this.state.flightupload === true ? <DashFlightFileUpload action={this.state.visible} selectedtrip1={selectedtrip} hideFligthModal={this.hideFligthModal} /> : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                        : null}

                    {selectedtrip.hotelStatus === "red" || selectedtrip.hotelStatus === "green" || selectedtrip.hotelStatus === "no display" ?
                        <div className="dash-flight">
                            <div className="row row_center">
                                <div className="col-sm-2 col-sm-offset-0 col-md-2 col-md-offset-0 col-lg-1 col-lg-offset-0 col-xs-12 col-xs-offset-0 col_center">
                                    <div className="dashAll-icon">
                                        {selectedtrip.hotelStatus === "red" || selectedtrip.hotelStatus === "no display" ? <i role='img' aria-label='Hotel' title='Hotel' className="icon-Buildings-grey-size-1 icon-class" style={{ color: "#666666" }} /> : null}
                                        {selectedtrip.hotelStatus === "green" ? <i role='img' aria-label='Hotel' title='Hotel' className="icon-Buildings-blue-size-1 icon-class" style={{ color: "#1B3281" }} /> : null}
                                    </div>
                                </div>
                                <div style={{ minHeight: '42px' }} className="col-sm-2 col-sm-offset-0 col-xs-7 col-xs-offset-0 col_center">
                                    <div className="dash-itenarydetails">
                                        <div className='contentHeading'>{this.props.app_labels.hotelDetails}</div>
                                    </div>
                                </div>
                                <div className="col-sm-2 col-sm-offset-0 col-xs-6 col-xs-offset-0 col_center">
                                    <div className="dashtravel-date left-margin-dashboard_from">
                                        <div className='contentHeading'>{this.props.app_labels.fromDate}</div>
                                        <div className="dash-tripdetails">{selectedtrip.actualStartDate === null ? selectedtrip.estimatedStartDate : selectedtrip.actualStartDate}</div>
                                    </div>
                                </div>
                                <div className="col-sm-2 col-sm-offset-0 col-xs-6 col-xs-offset-0 col_center">
                                    <div className="dashtravel-date left-margin-dashboard_to">
                                        <div className='contentHeading'>{this.props.app_labels.toDate}</div>
                                        <div className="dash-tripdetails">{selectedtrip.actualEndDate === null ? selectedtrip.estimatedEndDate : selectedtrip.actualEndDate}</div>
                                    </div>
                                </div>
                                <div className="col-sm-3 col-sm-offset-0 col-xs-6 col-xs-offset-0 col_center">
                                    <div className="dashtravel-date left-margin-dashboard_location">
                                        <div className='contentHeading'>{this.props.app_labels.location}</div>
                                        <div className="dash-tripdetails" style={{ wordBreak: 'break-all' }}>{this.capitalize(selectedtrip.destination.split("(")[0])}</div>
                                        {selectedtrip.destination.split("(").length === 2 ? <div className="dash-tripdetails">({selectedtrip.destination.split("(")[1]}</div> : null}
                                    </div>
                                </div>
                                {/* <div className="col-sm-2 col-sm-offset-4 col-xs-7 col-xs-offset-0 col_cente">
                                    <div className="dash-itenarydetails">
                                        {selectedtrip.hotelStatus === "red" ? <div className='contentHeading'>Pdf upload pending</div> : null}
                                        {selectedtrip.hotelStatus === "green" ? <div style={{ color: "#112A70" }} className='contentHeading'>Pdf uploaded</div> : null}
                                       
                                    </div>
                                </div> */}


                            </div>
                            <div className="row">
                                <br /><hr className="dash-filelist-line" />
                                <div className="col-sm-9 col-sm-offset-1 col-xs-7 col-xs-offset-1 col-md-9 col-md-offset-1">
                                    {
                                        selectedtrip.hotelAttachments.map((object) =>{
                                            return (
                                                <div style = {{display : "flex",alignItems : "center"}}>
                                                    <div title={object.fileName}
                                                    style={{ color: "#112A70",marginRight : 16 }}><p className = "a1-link" onClick={(e) => this.downloadFile(e, object.locationUrl, object.fileName, object.tripAttachmentId)} download>{object.fileName.length < 56 ? object.fileName : object.fileName.substring(0, 56) + "...."}</p></div>
                                                    {selectedtrip.hotelApplicable ? <div > <i role='img' aria-label='delete' title='delete' className="icon-Close" style={{ color: '#C53D47', fontWeight: 'bold' }} onClick={(e) => this.deleteFile(e, object.tripAttachmentId)} /></div> : null}
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <div className="col-sm-2 col-sm-offset-0 col-xs-2 col-xs-offset-0 col-md-2 col-md-offset-0 col-lg-2 col-lg-offset-0 col_center">
                                    <div className="dash-upload">
                                    {selectedtrip.hotelApplicable && selectedtrip.hotelAttachments.length !== 0 ? <i role='img' aria-label='delete' title='Delete' className="icon-Trash-Garbage-Can uploadBtnClass1" style={{ fontSize: 22 }} onClick={(e) => this.deleteAllFile(e, 'H', selectedtrip.tripId)} ></i> : null}
                                        <i role='img' aria-label='upload attachment' title='Upload' className="icon-Upload-attachment uploadBtnClass2" style={{ fontSize: 22, color: "grey" }} onClick={this.showhotelModal} />
                                        {this.state.hotelupload === true ? <DashHotelFileUpload action={this.state.visible} selectedtrip1={selectedtrip} hideHotelModal={this.hideHotelModal} /> : null}
                                        {this.state.flightupload === true ? <DashFlightFileUpload action={this.state.visible} selectedtrip1={selectedtrip} hideFligthModal={this.hideFligthModal} /> : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                        : null}
                </div>
                <div className='button-div-box'>
                    <div className="row buttonTopPadding">
                        <div className="col-sm-4 col-sm-offset-0" style={{ textAlign: 'left' }} >
                            {selectedtrip.tripActionStatus.description.toLowerCase() === tripStatusConstants.PendingClosure.toLowerCase() ?
                                <Button className="button-wrapclass" type="primary" ghost onClick={this.closeTrip}><b>Close trip</b></Button> : null}
                        </div>
                        <div className="col-sm-8 col-sm-offset-0" style={{ textAlign: 'right' }}>
                            {selectedtrip.tripActionStatus.description.toLowerCase() !== tripStatusConstants.Cancelled.toLowerCase() && selectedtrip.tripActionStatus.description.toLowerCase() !== tripStatusConstants.Closed.toLowerCase() ?
                                <Button className="buttonRightPadding button-wrapclass" type="primary"
                                    disabled={selectedtrip.tripActionStatus.description.toLowerCase() === tripStatusConstants.PendingClosure.toLowerCase() ||
                                        selectedtrip.tripActionStatus.description.toLowerCase() === tripStatusConstants.PendingExpenseReporting.toLowerCase() ||
                                        selectedtrip.tripActionStatus.description.toLowerCase() === tripStatusConstants.InProgress.toLowerCase()}
                                    ghost onClick={this.cancelTrip}><b>{this.props.app_labels.cancelTrip}</b></Button> : null}
                            {/* </div>
                        <div className="col-sm-3 col-sm-offset-0"> */}
                            {selectedtrip.tripActionStatus.description.toLowerCase() !== tripStatusConstants.Cancelled.toLowerCase() && selectedtrip.tripActionStatus.description.toLowerCase() !== tripStatusConstants.Closed.toLowerCase() && selectedtrip.tripActionStatus.description.toLowerCase() !== tripStatusConstants.AwaitingTravel.toLowerCase() && selectedtrip.tripActionStatus.description.toLowerCase() !== tripStatusConstants.PendingClosure.toLowerCase() && selectedtrip.tripActionStatus.description.toLowerCase() !== tripStatusConstants.PendingExpenseReporting.toLowerCase() && selectedtrip.cardStatus.toLowerCase() === "red".toLowerCase() ? <span><Link to={{ pathname: "/continue", continue: this.props.data }}>
                                <Button className="button-wrapclass" type="primary"  ><b>{this.props.app_labels.continue}</b></Button></Link></span> : null}
                            {selectedtrip.tripActionStatus.description.toLowerCase() !== tripStatusConstants.Cancelled.toLowerCase() && selectedtrip.tripActionStatus.description.toLowerCase() !== tripStatusConstants.Closed.toLowerCase() && selectedtrip.tripActionStatus.description.toLowerCase() !== tripStatusConstants.AwaitingTravel.toLowerCase() && selectedtrip.tripActionStatus.description.toLowerCase() !== tripStatusConstants.PendingClosure.toLowerCase() && selectedtrip.tripActionStatus.description.toLowerCase() !== tripStatusConstants.PendingExpenseReporting.toLowerCase() && selectedtrip.cardStatus.toLowerCase() !== "red".toLowerCase() ? <span><Link to="/pendingTrip">
                                <Button className="button-wrapclass" type="primary"  ><b>{this.props.app_labels.continue}</b></Button></Link></span> : null}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state, props) {
    return {
        tripdata: state.gettripReducer.getAlltripData,
        token: state.authenticationReducer.token,
        isDbCardFetching: state.gettripReducer.isDbCardFetching,
    };
}
function mapDispatchToProps(dispatch) {
    return {
        gettrips: () => {
            dispatch(getTrips());
        },
        getuseractions: () => {
            dispatch(getUserActions())
        },

        getalldetails: () => {
            dispatch(getAllDetails());
        },
        settripID: (tripId) => {
            dispatch(settripId(tripId));
        },
        changeTripStatus: (tripId, status, cadreID, hyperlinkFunc) => {
            dispatch(tripStatus(tripId, status, cadreID, hyperlinkFunc));
        }

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(DashContent);

