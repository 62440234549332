import axios from 'axios';
import React from 'react';
import { loaderFocus } from '../utils/accessability.js';
import { notification } from 'antd';
const { URLs } = require('../data/constants.js');

export function updateReq(data) {
    return {
        type: "REJECT_REQUEST",
        data
    }
}

export function updateReqStatus(reqObj, updatestate, getData, label_keys, isModalVisible) {

    return async (dispatch, getState) => {
        let {
            token,
        } = getState().authenticationReducer;
        isModalVisible(false);
        try {
            let result = await axios.put(URLs.cardUrl + '/updateApprovalStatus', reqObj, {
                responseType: 'json',
                headers: {
                    "Authorization": 'Bearer ' + token.access_token
                }
            })
            if (result) {
                if (result.data.serviceStatus.statusCode === 200) {
                    updatestate(true);
                    loaderFocus(".loader-color .ant-spin-text");
                    isModalVisible(true);
                    dispatch(updateReq(result, updatestate));
                    setTimeout(() => {
                        console.log("------Execution started after 10 sec--------------------");
                        getData()
                        console.log("------Executed--------------------");
                    },10000);
                    

                }
                else if (result.data.serviceStatus.statusCode === 201) {
                    updatestate(true);
                    isModalVisible(true);
                    dispatch(updateReq(result, updatestate));
                    notification.success({
                        message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Success </span>{label_keys.approvedAlready}</span>,
                        duration: 3,
                        style: {
                            backgroundColor: '#dff0d8',
                            color: "green",
                            border: "1px solid green",
                        }
                    })
                    getData();
                    updatestate(false)
                }
                else {
                    updatestate(false);
                    isModalVisible(true);
                    notification.error({
                        message: <span  role='alert' aria-live="assertive" aria-atomic="true" ><span className="visually-hidden">Error </span>{label_keys.couldNotcompleteReq}</span>,
                        duration: 10,
                        style: {
                            backgroundColor: '#fff1f0',
                            border: "1px solid #ffa39e",
                        }
                    });
                }
            }
        } catch (error) {
            if (error.response !== undefined && error.response.data !== null && error.response.data !== undefined && error.response.data.serviceStatus !== undefined) {
                updatestate(false);
                notification.error({
                    message: <span  role='alert' aria-live="assertive" aria-atomic="true" ><span className="visually-hidden">Error </span>{label_keys.couldNotcompleteReq}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }
            else {
                notification.error({
                    message: <span  role='alert' aria-live="assertive" aria-atomic="true" ><span className="visually-hidden">Error </span>{label_keys.couldNotcompleteReq}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }
        }
    }
}