import React from 'react';
import { Modal, Icon, Button, notification, Input } from 'antd';
import 'antd/dist/antd.css';
// import '../../styling/createtrip.css';
import 'react-responsive-ui/style.css';
import { connect } from 'react-redux';
import { viewCard } from '../../actions/createcardActions';
import axios from 'axios';
import qs from 'qs'
import { Counter } from '../ProvisionerScreen/Counter'
var dateFormat = require('dateformat');
const { URLs, errorMessages } = require('../../data/constants.js');

/*
*rendered when clicked on view card details on dashboard screen
*/
class ViewCardDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cardDetails: {},
      dbSuccess: false,
      otp1: '',
      otp2: '',
      otp3: '',
      otp4: '',
      /* XW-61 issue - invalid OTP attempts for unlimited times 
        added two more state variable to store sth and 6th otp digits*/
      otp5: '',
      otp6: '',
      activeResend: false,
      otpCom: false,
      wrongOTP: false,
      show: false,
    }
  }

  /**
   * Sets the updated card details
   */
  setCardDetails = (data) => {
    this.setState({ cardDetails: data, dbSuccess: true, otpCom: false })
  }

  /*  componentWillUnmount() {
     this.setState({ dbSuccess: false, cardDetails: {} })
   } */

  /**card details pop up will be closed
     * @param  {} nextProps
     */
  handleCancel = (data) => {
    /* XW-61 issue - invalid OTP attempts for unlimited times
        now redirecting to OTP screen on third wrong attempt of otp */
    if (data.type === "otp_error") {
      this.setState({ wrongOTP: true, otp1: '', otp2: '', otp3: '', otp4: '', otp5: '', otp6: '' })
    }
    else if (data.type === "authorization_error") {
      this.setState({ otpCom: false, wrongOTP: true, show: false })
    }
    else {
      this.props.hideCardDetails()
    }
    return;
  }

  /*
  *To generate otp in order to view the card details
  */
  sendOTP = () => {
    /* XW-61 issue - invalid OTP attempts for unlimited times 
      added two more state variable to store sth and 6th otp digits*/
    this.setState({ otpCom: true, wrongOTP: false, otp1: '', otp2: '', otp3: '', otp4: '', otp5: '', otp6: '', activeResend: false })
    let obj = { username: this.props.username, OTP_PREFERENCE: 'email' }
    axios.post(URLs.mfaUrl + 'sendOTP', qs.stringify(obj), {
      headers: {
        "Authorization": "Basic Q2FkcmUtV2ViOmNhZHJl",
      }
    }).then(res => setTimeout(() => this.setState({ activeResend: true }), 30000))
      .catch(err => notification.error({
        message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{errorMessages.otpSendingGenericErrorMessage}</span>,
        duration: 3,
        style: {
          backgroundColor: '#fff1f0',
          border: "1px solid #ffa39e",
        }
      })
      )
  }

  /*
  *To verify the otp and get the card details
  */
  submitOTP = () => {
    /* XW-61 issue - invalid OTP attempts for unlimited times 
      added two more state variable to store sth and 6th otp digits*/
    let otp = this.state.otp1 + this.state.otp2 + this.state.otp3 + this.state.otp4 + this.state.otp5 + this.state.otp6
    this.props.viewcard(this.props.selectedtrip1.tripId, this.setCardDetails, this.handleCancel, otp)
  }

  render() {
    /****view card pop up will disappear after 30 secs*******/
    // setTimeout(this.handleCancel, 30000);
    return (
      this.state.otpCom === false ?
        <div className="wrapClassName">
          <Modal
            visible={this.props.action}
            closable={false}
            destroyOnClose={true}
            onCancel={this.handleCancel}
            footer={null}
            wrapClassName="wrapClassName"
            width={430}
          >
            {this.state.dbSuccess ?
              <div className="jp-card-container jp-card jp-card-jcb jp-card-identified">
                <div style={{ float: "right", marginRight: "-1%", color: 'white' }}>
                  <button className="pseudoButtonIcon" onClick={this.handleCancel}><Icon type="close" /></button>
                </div>
                <div className="jp-card-number">
                  <div>{this.state.cardDetails.cardNo.replace(/(\d{4})/g, '$1 ').replace(/(^\s+|\s+$)/, '')}</div>
                  <div style={{ marginTop: '4%' }}>
                    <span className="validthru">{this.props.app_labels.validThru}</span>
                    <span className="jp-card-expiry"> {dateFormat(this.state.cardDetails.cardExpiryDate, "mm/yyyy")}</span>
                    <span style={{ marginLeft: "24%" }} className="validthru">{this.props.app_labels.cvv}</span>
                    <span className="jp-card-expiry">{this.state.cardDetails.cvv}</span>
                  </div>
                </div>
              </div>
              : <div className={`jp-card-container jp-card jp-card-jcb jp-card-identified card-blur`}>
                <div className="blur-card-otp">
                  <span><b>To view Card Details, tap on 'Send OTP'</b></span>
                  <div>
                    <Button ghost onClick={this.sendOTP}>Send OTP</Button>
                  </div>
                </div>
              </div>}
          </Modal >
        </div>
        : <Modal
          visible={this.props.action}
          closable={false}
          destroyOnClose={true}
          onCancel={this.handleCancel}
          footer={null}
        >
          <div tabindex={0} className="preview-otp-body" style={{ margin: "0 10%" }}>
            <div tabindex={0}><span>A 6-digit verification has been sent to your registered Email Id.</span></div>
            {this.state.wrongOTP === true ?
              <div tabindex={0} className="preview-otp-error-text">
                <Icon type="warning" />
                Please enter the correct varification code!!
                  </div> : <div tabindex={0} className="preview-otp-error-empty"></div>}
            <div tabindex={0} ><span className={`verification-text ${this.state.wrongOTP === true && 'error-text'}`}><b>Verification code</b></span></div>
            <div tabindex={0} className="otp-input">
              <Input autocomplete="new-password" maxLength='1' type={this.state.show === false && 'password'} value={this.state.otp1} onChange={e => this.setState({ otp1: e.target.value })}
                onKeyUp={e => {
                  (e.keyCode !== 8 && e.keyCode !== 46 && e.keyCode !== 9 && e.keyCode !== 16) && document.getElementById("otp2").focus()
                }}
                id="otp1" className={this.state.wrongOTP === true ? "error-border" : (this.state.otp1 !== "" && "blue-border")} />
              <Input autocomplete="new-password" maxLength='1' type={this.state.show === false && 'password'} value={this.state.otp2} onChange={e => this.setState({ otp2: e.target.value })}
                onKeyUp={e => {
                  (e.keyCode !== 8 && e.keyCode !== 46 && e.keyCode !== 9 && e.keyCode !== 16) && document.getElementById("otp3").focus()
                  if (e.keyCode === 8) document.getElementById("otp1").focus()
                }}
                id="otp2" className={this.state.wrongOTP === true ? "error-border" : (this.state.otp2 !== "" && "blue-border")} />
              <Input autocomplete="new-password" maxLength='1' type={this.state.show === false && 'password'} value={this.state.otp3} onChange={e => this.setState({ otp3: e.target.value })}
                onKeyUp={e => {
                  (e.keyCode !== 8 && e.keyCode !== 46 && e.keyCode !== 9 && e.keyCode !== 16) && document.getElementById("otp4").focus()
                  if (e.keyCode === 8) document.getElementById("otp2").focus()
                }}
                id="otp3" className={this.state.wrongOTP === true ? "error-border" : (this.state.otp3 !== "" && "blue-border")} />
              <Input autocomplete="new-password" maxLength='1' type={this.state.show === false && 'password'} value={this.state.otp4} onChange={e => this.setState({ otp4: e.target.value })}
                onKeyUp={e => {
                  (e.keyCode !== 8 && e.keyCode !== 46 && e.keyCode !== 9 && e.keyCode !== 16) && document.getElementById("otp5").focus()
                  if (e.keyCode === 8) document.getElementById("otp3").focus()
                }}
                id="otp4" className={this.state.wrongOTP === true ? "error-border" : (this.state.otp4 !== "" && "blue-border")} />
                {/* XW-61 issue - invalid OTP attempts for unlimited times 
                    added two more input fields for user to enter 5th and 6th otp digits*/}
              <Input autocomplete="new-password" maxLength='1' type={this.state.show === false && 'password'} value={this.state.otp5} onChange={e => this.setState({ otp5: e.target.value })}
                onKeyUp={e => {
                  (e.keyCode !== 8 && e.keyCode !== 46 && e.keyCode !== 9 && e.keyCode !== 16) && document.getElementById("otp6").focus()
                  if (e.keyCode === 8) document.getElementById("otp4").focus()
                }}
                id="otp5" className={this.state.wrongOTP === true ? "error-border" : (this.state.otp5 !== "" && "blue-border")} />
              <Input autocomplete="new-password" maxLength='1' type={this.state.show === false && 'password'} value={this.state.otp6} onChange={e => this.setState({ otp6: e.target.value })}
                onKeyUp={e => {
                  (e.keyCode !== 8 && e.keyCode !== 46 && e.keyCode !== 9 && e.keyCode !== 16) && document.getElementById("otp6").blur()
                  if (e.keyCode === 8) document.getElementById("otp5").focus()
                }}
                id="otp6" className={this.state.wrongOTP === true ? "error-border" : (this.state.otp6 !== "" && "blue-border")} />
            </div>
            {(this.state.otp1 !== '' || this.state.otp2 !== '' || this.state.otp3 !== '' || this.state.otp4 !== '' || this.state.otp5 !== '' || this.state.otp6 !== '') &&
              <p tabindex={0} className="show" role="button" aria-label={this.state.show === true ? 'Hide' : 'Show'}
                onKeyUp={e => {
                  if (e.keyCode === 13) {
                    this.setState({ show: !this.state.show })
                  }
                }}
                onClick={() => this.setState({ show: !this.state.show })}>
                {this.state.show === true ? 'Hide' : 'Show'}
              </p>}
            <div tabindex={0} className={`resend-otp ${!this.state.activeResend ? 'resend-padding' : ''}`}>
              {!this.state.activeResend && <Icon type="loading" className="count-down" />}
              {!this.state.activeResend && <Counter />}
              <Button tabindex={0} className="resend-otp-button"
                disabled={!this.state.activeResend}
                onClick={this.sendOTP}>Resend code</Button>
              <Button tabindex={0} className="otp-button" type="primary"
                disabled={(this.state.otp1 === '' || this.state.otp2 === '' || this.state.otp3 === '' || this.state.otp4 === '' || this.state.otp5 === '' || this.state.otp6 === '') ? true : false}
                onClick={() => this.submitOTP()}>
                Submit
                  </Button>
            </div>
          </div>

        </Modal >
    )
  }
}
function mapDispatchToProps(dispatch) {
  return {
    viewcard: (tripId, setcardDetails, setactionOnFail, otp) => {
      dispatch(viewCard(tripId, setcardDetails, setactionOnFail, otp));
    }
  }
}
export default connect(null, mapDispatchToProps)(ViewCardDetails);
