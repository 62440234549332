import React, { Component } from "react";
import ReactDOM from 'react-dom';
import { connect } from 'react-redux'
import axios from 'axios'
import { Row, Col, Icon, Avatar, notification, Drawer, Input, Modal, Result, Spin } from 'antd'

import { Autocomplete, Switch, TextField } from '@mui/material';
import moment from 'moment-timezone'
// import '../../styling/otpselect.css';
const { URLs } = require('../data/constants.js')

var phoneNoString = "";
var phoneNoString1 = "";

class ProfileDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {
            checked: false,    
            phoneCode: '+1',
            focusOnClose: true,
            previousActiveElement: null,
            profileActive: this.props.profileType === "Provisioner" ? this.props.selectedRow.creatorActiveStatus : this.props.selectedRow.approverActiveStatus,
            
        }

    }

    closeIconItem = React.createRef();

    componentDidMount() {
        // this.props.userDetails.singleCardApproval && this.setState({ approvalButton: true })
        

        
    }

    componentDidUpdate(){

        if (this.state.modalVisible) {
            setTimeout(() => {
                const modal = document.querySelector('.ant-modal-root .ant-modal-wrap');
                const div = document.getElementById("closeBtn")
                if (modal) {
                    modal.setAttribute('aria-label', "Send for Approval")
                    modal.focus();
                }
                if (div) {
                    div.setAttribute("tabindex", "0")
                }
            },100)
        }
        if(this.props.createVisible && this.state.focusOnClose){
            if (this.props.previousActiveElement && !this.state.previousActiveElement){
                this.setState({previousActiveElement: this.props.previousActiveElement})
            }
            setTimeout(() => {
                const cls = document.getElementById("closeIconItem");
                // if (cls) cls.focus()
                this.setState({ focusOnClose: false})
            }, 100);
        }
    }

    handleFocusCloseModal = () => {
        if(this.state.modalVisible) {
            document.addEventListener('keyup', (e) => {
                if (e.keyCode === 13) {
                    this.handleCloseModal()
                }

                if (e.key === "Tab" || e.key === "Shift") {
                    const cls = document.getElementById("closeBtn")
                    if (cls) {
                        setTimeout(() => {
                            cls.focus()
                        }, 100) 
                    }
                }
            })
        }
    }

    handleCloseModal = () => {
        const btn = this.state.previousActiveElement;
        if (document.body.contains(btn)) btn.focus()
        this.setState({modalVisible: false})
    }

        // Accessbility To make default focus on element.

    // Accessbility To invoke click method on tab enter.
    onKeyDown = (event) => {
        if (event.key === "Tab" && !event.shiftKey) {
            setTimeout(() => {
                    document.getElementById("closeIconItem").focus();
            }, 100);
            
        }
    }

    /**
     * Phone number format in the required convention
     */
     phoneNoFormat = (value, e) => {
        phoneNoString = value.toString()
        phoneNoString1 = ""
        for (let i = phoneNoString.length - 1; i > phoneNoString.length - 5; i--) {
            if (phoneNoString[i] === ' ') {
                continue
            }
            else {
                phoneNoString1 = phoneNoString[i] + phoneNoString1
            }
        }
        phoneNoString1 = " " + phoneNoString1
        for (let i = phoneNoString.length - 5; i > phoneNoString.length - 8; i--) {
            if (phoneNoString[i] === ' ') {
                continue
            }
            else {
                phoneNoString1 = phoneNoString[i] + phoneNoString1
            }
        }
        phoneNoString1 = " " + phoneNoString1
        for (let i = phoneNoString.length - 8; i > phoneNoString.length - 11; i--) {
            if (phoneNoString[i] === ' ') {
                continue
            }
            else {
                phoneNoString1 = phoneNoString[i] + phoneNoString1
            }
        }
        if (phoneNoString.length > 10) {
            phoneNoString1 = " " + phoneNoString1
            for (let i = phoneNoString.length - 11; i >= 0; i--) {
                if (phoneNoString[i] === ' ') {
                    continue
                }
                else {
                    phoneNoString1 = phoneNoString[i] + phoneNoString1
                }
            }
        }
        return phoneNoString1
    }


    render() {
        
        return (
            <Drawer
                aria-labelledby="create_singleCard"
                role="dialog"
                width={700}
                closable={false}
                maskClosable={false}
                onClose={() => this.props.onCloseProfileDetail()}
                visible={this.props.profileDetailsVisible}
            >
                <div  className="create-head profile-details" style={{marginTop: "3%"}}>
                    <span id="create_singleCard" role="heading" aria-level="2" style={{color: '#19398A'}}>{this.props.label_keys.profileDetails} </span>
                    <Icon role="button" aria-label="Close" tabIndex={0} id="closeIconItem_profile"
                    type="close-circle" 
                    onKeyDown={e => {
                        if (e.keyCode === 13) {
                            this.props.onCloseProfileDetail();
                        }if(e.key === "Tab"){
                            setTimeout(() =>{
                                document.getElementById("closeIconItem_profile").focus();
                            }, 100)
                        }
                    }}  
                    onClick={() => this.props.onCloseProfileDetail()} />
                    <div></div>
                </div><br />

                <div className="profile-details">

                    <div className="row avatar">
                        
                        <div style={{ textAlign: 'center' }} /* className="col-xs-3 col-xs-offset-5 col-sm-3 col-sm-offset-5 col-md-3 col-md-offset-5 col-lg-3 col-lg-offset-5 col-xl-3 col-xl-offset-5" */ /* avatar-details */>
                            <Avatar size='large' icon="user" role="img" aria-hidden="true" style={{marginLeft: "-4.5%"}} />
                        </div>

                    </div>
                    <br />
                    <div className="row">
                        {/*{this.state.editcolor === "black" ? <div className="margin-left-Name"><b>{this.capitalize(this.props.data.firstName) + " " + this.capitalize(this.props.data.lastName)}</b></div> : */ /*Removed capitalize EW-817 */}
                        <div className="col-xs-8 col-xs-offset-3 col-sm-3 col-sm-offset-5 col-md-3 col-md-offset-5 col-lg-3 col-lg-offset-5 col-xl-3 col-xl-offset-5" >
                            <div className="margin-left-Name" style={{margin: "0 auto"}}><b>{this.props.profileType === "Provisioner" ? this.props.selectedRow.creatorname : this.props.selectedRow.approvername}</b></div> 
                            
                        </div>
                    </div>
                    <br />
                    { !this.state.profileActive ? <div style={{color: "#CC371C", marginLeft: this.props.selectedLang === "French" ?'37%': '35%'}}>{this.props.label_keys.profileDisabled}</div>: null}
                    <br />
                    <div style={{opacity: !this.state.profileActive? 0.6 : 1}}>    
                        <Row className="row details-row">
                            <Col span={6} offset={4} >
                                {this.props.label_keys.expenseWizardId}
                            </Col>
                            {/* <MediaQuery minDeviceWidth={770}> */}
                            {/* <div className="col-xs-1 col-xs-offset-0 col-sm-1 col-sm-offset-0 col-md-1 col-md-offset-0 col-lg-1 col-lg-offset-0 col-xl-1 col-xl-offset-0">
                                :
                        </div> */}
                            {/* </MediaQuery> */}
                            
                            <Col span={6} style={{width:'55%'}} ><span aria-hidden="true">:</span>
                                <span className="details-editProfile" style={{marginLeft: '11%'}}>
                                    {this.props.profileType === "Provisioner" ? this.props.selectedRow.creatorCadreId : this.props.selectedRow.approverCadreId}</span>
                            </Col>
                        </Row>
                        <Row className="row details-row">
                            <Col span={6} offset={4}>
                                {this.props.label_keys.email}
                            </Col>
                            {/* <MediaQuery minDeviceWidth={770}>
                                <div className="col-sm-1 col-sm-offset-0">
                                    :
                        </div>
                            </MediaQuery> */}
                            {/* <div className="col-xs-1 col-xs-offset-0 col-sm-1 col-sm-offset-0 col-md-1 col-md-offset-0 col-lg-1 col-lg-offset-0 col-xl-1 col-xl-offset-0">
                                :
                        </div> */}
                            <Col span={6} style={{width:'55%'}} ><span aria-hidden="true">:</span>
                                <span className="details-editProfile" style={{marginLeft: '11%'}}>{this.props.profileType === "Provisioner" ? this.props.selectedRow.creatoremailId : this.props.selectedRow.approveremailId}</span>
                            </Col>
                        </Row>
                        <Row className="row details-row">
                            <Col span={6} offset={4} >
                                <span aria-hidden="true">{this.props.label_keys.mobileNo}</span>
                                <span className="visually-hidden">Mobile number</span>
                            </Col>
                            {/*  <MediaQuery minDeviceWidth={770}>
                                <div className="col-sm-1 col-sm-offset-0">
                                    :
                        </div>
                            </MediaQuery> */}

                            {/* <div className="col-xs-1 col-xs-offset-0 col-sm-1 col-sm-offset-0 col-md-1 col-md-offset-0 col-lg-1 col-lg-offset-0 col-xl-1 col-xl-offset-0">
                                :
                        </div> */}
                            <Col span={6} style={{width: '50%'}}><span aria-hidden="true">:</span>
                                <span className="details-editProfile phNo">
                                    {this.props.profileType === "Provisioner" ? 
                                        (this.props.selectedRow.creatormobileNumber !== '' && this.props.selectedRow.creatormobileNumber !== null ? this.phoneNoFormat(this.props.selectedRow.creatormobileNumber) : null)
                                        :
                                        (this.props.selectedRow.approvermobileNumber !== '' && this.props.selectedRow.approvermobileNumber !== null ? this.phoneNoFormat(this.props.selectedRow.approvermobileNumber) : null)
                                    }
                                </span>
                            </Col>
                        </Row>
                        <Row className="row details-row">
                            <Col span={6} offset={4} >
                                <span aria-hidden="true">{this.props.label_keys.alternateNo}</span>
                                <span className="visually-hidden">Alternate number</span>
                            </Col>
                            {/*  <MediaQuery minDeviceWidth={770}>
                                <div className="col-sm-1 col-sm-offset-0">
                                    :
                        </div>
                            </MediaQuery> */}
                            {/* <div className="col-xs-1 col-xs-offset-0 col-sm-1 col-sm-offset-0 col-md-1 col-md-offset-0 col-lg-1 col-lg-offset-0 col-xl-1 col-xl-offset-0">
                                :
                        </div> */}
                            <Col span={6} style={{width: '50%'}}><span aria-hidden="true">:</span>
                                <span className="details-editProfile phNo">
                                {this.props.profileType === "Provisioner" ? 
                                        (this.props.selectedRow.creatoraltmobileNumber !== '' && this.props.selectedRow.creatoraltmobileNumber !== null ? this.phoneNoFormat(this.props.selectedRow.creatoraltmobileNumber) : "-")
                                        :
                                        (this.props.selectedRow.approveraltmobileNumber !== '' && this.props.selectedRow.approveraltmobileNumber !== null ? this.phoneNoFormat(this.props.selectedRow.approveraltmobileNumber) : "-")
                                    }</span>
                            </Col>
                        </Row>
                        <Row className="row details-row">
                            <Col span={6} offset={4} >
                                {this.props.label_keys.companyName}
                            </Col>
                            {/*  <MediaQuery minDeviceWidth={770}>
                                <div className="col-sm-1 col-sm-offset-0">
                                    :
                        </div>
                            </MediaQuery> */}
                            {/* <div className="col-xs-1 col-xs-offset-0 col-sm-1 col-sm-offset-0 col-md-1 col-md-offset-0 col-lg-1 col-lg-offset-0 col-xl-1 col-xl-offset-0">
                                :
                        </div> */}
                            <Col span={6} style={{width:'55%'}} ><span aria-hidden="true">:</span>
                                <span className="details-editProfile" style={{marginLeft:'11%'}}>{this.props.selectedRow.companyname}</span>
                            </Col>
                        </Row>
                        <Row className="row details-row">
                            <Col span={6} offset={4} id='assign-role' >
                                {this.props.label_keys.assignRole}
                            </Col>
                            {/*   <MediaQuery minDeviceWidth={770}>
                                <div className="col-sm-1 col-sm-offset-0">
                                    :
                        </div>
                            </MediaQuery> */}
                            {/* <div className="col-xs-1 col-xs-offset-0 col-sm-1 col-sm-offset-0 col-md-1 col-md-offset-0 col-lg-1 col-lg-offset-0 col-xl-1 col-xl-offset-0">
                                :
                        </div> */}
                            <Col span={12} style={{width:'55%'}} ><span aria-hidden="true">:</span>
                                <span style={{color: "#333333", marginLeft: "11%", fontWeight: 600}}> 
                                {this.props.profileType === "Provisioner" ? (this.props.selectedRow.provisionerroles?.length === 0 ? "-" :
                                    this.props.selectedRow.provisionerroles?.toString()):
                                    (this.props.selectedRow.approverroles?.length === 0 ? "-" :
                                    this.props.selectedRow.approverroles?.toString())
                                }</span> 
                            </Col>
                        </Row>
                        <Row className="row details-row" style={{display: this.props.profileType === "Provisioner"? null : this.props.selectedRow.approverroles?.includes("Provisioner") ? null : "none"}} >
                            <Col span={6} offset={4} style={{marginTop: "7px"}} >
                                {this.props.label_keys.viewAllActiveCards}
                            </Col>
                            {/*   <MediaQuery minDeviceWidth={770}>
                                <div className="col-sm-1 col-sm-offset-0">
                                    :
                        </div>
                            </MediaQuery> */}
                            {/* <div className="col-xs-1 col-xs-offset-0 col-sm-1 col-sm-offset-0 col-md-1 col-md-offset-0 col-lg-1 col-lg-offset-0 col-xl-1 col-xl-offset-0">
                                :
                        </div> */}
                            <Col span={6} ><span aria-hidden="true">:</span>
                                <span className="details-editProfile" style={{marginLeft:'27%'}}> 
                                    <Switch className='profile-toggle-button' color="success" 
                                        disabled={true} checked={this.props.profileType === "Provisioner" ? this.props.selectedRow.creatorViewCardStatus : this.props.selectedRow.approverViewCardStatus}
                                        inputProps={{ 'aria-label': 'View All Active Cards' }}
                                        sx={{marginLeft: "-10%"}}
                                    />
                                </span>
                            </Col>
                        </Row><br />
                    </div>
                    <div tabIndex={0} className="visually-hidden" aria-hidden="true"></div>
                </div>
                
                

            </Drawer>
        )
    }
}


function mapStateToProps(state, props) {
    return {
        userDetails: state.authenticationReducer.userDetails,
        token: state.authenticationReducer.token
    }
}

export default connect(mapStateToProps, null)(ProfileDetails)
