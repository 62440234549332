import React, { Component, Fragment } from 'react';
import { Form, Icon, Input, Button, Alert, Row } from 'antd';
import '../styling/login.css';
import { errorMessages } from '../data/constants';
import passwordValidator from 'password-validator'
const FormItem = Form.Item;
class ResetPasswordFormComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            passwordsMatch: true,
            flagNewPassword: false,
            flagCNFPassword: false,
            new_pswd_strength: false,
            cnf_pswd_strength: false
        }
    }

    handleNewPassword(e){
        if(e.target.value == ""){
            this.setState({flagNewPassword: true})
        }else{
            this.setState({flagNewPassword: false})
        }
    }
    handleCNFPasswordValue(e){
        if(e.target.value == ""){
            this.setState({flagCNFPassword: true})
            // this.setState({cnf_pswd_strength: false})
        }else{
            this.setState({flagCNFPassword: false})
        }
    }

    componentDidMount() {
        this.props.resetPasswordStateReset()
        this.passwordValidatorSchema = new passwordValidator()
        this.passwordValidatorSchema
            .is().min(8) // Minimum length 8 
            .is().max(15) // Maximum length 15
            .has().uppercase() // Must have uppercase letters
            .has().lowercase() // Must have lowercase letters
            .has().digits() // Must have digits
            .has().symbols()
            .has().not().spaces() // Should not have spaces
            if(window.location.hostname.includes('elan-solutions.elaneasypay'))
            document.title="Reset Password: Elan Easy Pay";
            else
            document.title="Reset Password: US Bank Instant Card";
          
    }

    showPasswordsDontMatchWarning() {
        this.setState(() => ({
            passwordsMatch: false
        }))
    }

    clearPasswordsDontMatchWarning() {
        this.setState(() => ({
            passwordsMatch: true
        }))
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <div>
            <Row>
          <Alert
            style={{ marginBottom: '5px', marginTop: '10px' }}
            message={errorMessages.messagePasswordSuggestion}
            type="info" />
      </Row>
            <Form onSubmit={(e) => {
                e.preventDefault();
                this.props.form.validateFields((err, values) => {
                    if (!err) {
                        if(values.password !== values.confirmPassword) {
                            this.showPasswordsDontMatchWarning()
                        } else {
                            this.clearPasswordsDontMatchWarning()
                            this.props.onSubmit(values)
                        }
                    }
                    <span><span className="visually-hidden">Error: </span>{err}</span>

                });
            }} className="reset-password-form">
                {
                    this.state.passwordsMatch === false ? (
                        <Alert
                            role='alert'
                            
                            style={{ marginBottom: '5px' }}
                            message={
                                'Passwords do not match'
                            }
                            type="error" />
                    )
                        : null
                }
                {
                    this.props.requestType === 'otp' ?
                        (
                            <FormItem>
                                {getFieldDecorator('otp', {
                                    validateFirst: true,
                                    rules: [{ required: true, message: errorMessages.otpTypeMessage },
                                    {
                                        validator: function (rule, value, callback) {
                                            let v = String(value)
                                            if (v.length !== 4) return callback(errorMessages.error_val_otp)
                                            for (let i = 0; i < v.length; i++) {
                                                if (isNaN(+v[i])) return callback(errorMessages.error_val_otp)
                                            }
                                            callback()
                                        }
                                    }
                                    ]
                                })(
                                    <Input autocomplete="one-time-code" required placeholder="OTP" style={{ textAlign: 'center' }} />
                                )}
                            </FormItem>
                        )
                        :
                        null
                }
                <FormItem>
                    {getFieldDecorator('password', {
                        rules: [{ required: true, message: <span id="new-password-error" className="form-ele-error"><span className="visually-hidden">Error: </span>{errorMessages.passwordPrompt}</span> },
                        {
                            validator: (rule, value, callback) => {
                                let v = String(value)
                                if (this.passwordValidatorSchema.validate(v) !== true) {
                                    this.setState({new_pswd_strength: true})
                                    return callback(<span id="new-pswd-strength-error" className="form-ele-error"><span className="visually-hidden">Error: </span>{errorMessages.credentialsStrengthError}</span>)
                                } else {
                                    this.setState({new_pswd_strength: false})
                                    return callback()
                                }
                            }
                        }],
                        validateFirst: true
                    })(
                        <Input className="reset-pass" onChange={e => this.handleNewPassword(e)} aria-describedby={ (!this.state.new_pswd_strength && !this.state.flagNewPassword? null :(this.state.new_pswd_strength&&!this.state.flagNewPassword)?"new-pswd-strength-error": "new-password-error")} aria-label="New Password" autocomplete="new-password" required type="password" placeholder="New Password*" />
                        /*   <Input autoComplete="off" prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} style={{ border: '0px', borderBottom: '1px solid gray' }} />} type="password" placeholder="New Password" /> */
                    )}
                </FormItem>
                <FormItem>
                    {getFieldDecorator('confirmPassword', {
                        rules: [{ required: true, message: <span id="cnf-password-error" className="form-ele-error"><span className="visually-hidden">Error: </span>{errorMessages.confirmPasswordPrompt}</span> },
                        {
                            validator: (rule, value, callback) => {
                                let v = String(value)
                                if (this.passwordValidatorSchema.validate(v) !== true) {
                                    this.setState({cnf_pswd_strength: true})
                                    return callback(<span id="cnf-pswd-strength-error" className="form-ele-error"><span className="visually-hidden">Error: </span>{errorMessages.credentialsStrengthError}</span>)
                                } else {
                                    this.setState({cnf_pswd_strength: false})
                                    return callback()
                                }
                            }
                        }],
                        validateFirst: true
                    })(
                        <Input className="reset-pass" onChange={e => this.handleCNFPasswordValue(e)} aria-describedby={ (!this.state.cnf_pswd_strength && !this.state.flagCNFPassword? null :(this.state.cnf_pswd_strength&&!this.state.flagCNFPassword)?"cnf-pswd-strength-error": "cnf-password-error")} aria-label="Confirm Password" autocomplete="new-password" required type="password" placeholder="Confirm Password*" />
                        /*   <Input autoComplete="off" prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="Confirm Password" /> */
                    )}
                </FormItem>
                <FormItem style={{ textAlign: 'center' }}>
                    <Button type="primary" htmlType="submit"
                        className="login-form-button"
                        style={{ backgroundColor: '#0A41C5', borderRadius: '0.5rem', color: 'white' }}
                        disabled={this.props.isPasswordResetting}>
                        {
                            this.props.isPasswordResetting === true ?
                                (
                                    <Fragment>
                                        <span>{this.props.app_labels.reseting}</span>
                                        <Icon type="loading" />
                                    </Fragment>
                                ) : <span>{this.props.app_labels.reset1}</span>
                        }
                    </Button>
                </FormItem>
            </Form>
        </div>

        );
    }
}

const WrappedResetPasswordForm = Form.create()(ResetPasswordFormComponent);

export default WrappedResetPasswordForm