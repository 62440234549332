import React from 'react';
import { connect } from 'react-redux';
import $ from 'jquery';
import AdminCarousel from './adminCarousel';
import { loaderFocus } from '../../utils/accessability';
import CreateUserProfileComponent from './CreateUserProfile/createUserProfile';
import UserActionHeaderComponent from '../useractionheader.js';
import ViewandEditProfile from './ViewEditProfile/viewandeditprofile';
import CardMileage from './Settings/cardMileage';
import AllActiveCards from '../allActiveCards';
import '../../styling/companysetup.css';
import '../../styling/header.css'
import '../../styling/carousel.css';
import { Layout, notification, Spin, } from 'antd';
import axios from 'axios';
import { getExpenseCategoryAll, getTransactionTypeAll } from '../../actions/AdminActions/receiptruleActions';

const { URLs, errorMessages } = require('../../data/constants.js');
const { Content, Footer } = Layout;
class CardAdminComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            companyDetails: "NA",
            superadminCompany: "",
            activeCompanyName: [],
            selecteditem: 1,
            cadreRoles: [],
            retrieve: false,
            expenseCat: [],
            expenseCatAll: [],
            catRetrieve: false,
            transactionType: [],
            transactionTypeAll: [],
            typeRetrieve: false,
            compretrieve: false,
        }
    }
    componentDidMount() {
        if(!(this.state.retrieve === true && this.state.catRetrieve === true && this.state.typeRetrieve === true && this.state.compretrieve === true)){
            loaderFocus(".loader-color .ant-spin-text");
        }
        if (!this.props.userDetails.roles.includes("Card Company Admin")) {
            window.history.back();
        }
        if (window.location.hostname.includes('elan-solutions.elaneasypay')){
            $('.page-title').text("Card Company Admin: Elan Easy Pay").focus();
            document.title = "Card Company Admin: Elan Easy Pay";
        }
        else{
            $('.page-title').text("Card Company Admin: US Bank Expense Wizard").focus();
            document.title = "Card Company Admin: US Bank Expense Wizard";
        }
        /**********getcadre roles *********/
        axios.get(URLs.adminUrl + "cadreRoles/CardCompanyAdmin", {
            responseType: 'json',
            headers: {
                "Authorization": 'Bearer ' + this.props.token.access_token
            }
        })
            .then(response => {
                this.setState({ cadreRoles: response.data.data, retrieve: true });
            })
            .catch((error) => {
                this.setState({ retrieve: true })
                if (error.response !== undefined && error.response.data !== null && error.response.data !== undefined && error.response.data.serviceStatus !== undefined) {
                    notification.error({
                        message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Error </span>{this.props.app_labels.couldNotcompleteReq}</span>,
                        duration: 3,
                        style: {
                            backgroundColor: '#fff1f0',
                            border: "1px solid #ffa39e",
                        }
                    });
                }
                else {
                    notification.error({
                        message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Error </span>{this.props.app_labels.couldNotcompleteReq}</span>,
                        duration: 3,
                        style: {
                            backgroundColor: '#fff1f0',
                            border: "1px solid #ffa39e",
                        }
                    });
                }
            })
        this.getExpenseCategory();
        this.props.getExpenseCatAll(this.updateCatAllSpin, this.updateCatAllState);
        this.getTransactionType();
        this.props.getTransTypeAll(this.updateTypeAllSpin, this.updateTypeAllState)
        this.getCompanyLists();
    }

    /**
    * fetching all active company
    */
    getCompanyLists = () => {
        axios.get(URLs.tripUrl + "registeredCompanies/" + this.props.userDetails.companyId, {
            responseType: 'Json',
            headers: {
                "Authorization": 'Bearer ' + this.props.token.access_token
            }
        }).then(response => {
            this.setState({ compretrieve: true });
            if (response.data.serviceStatus.statusCode === 200) {
                let companyArray = [];
                companyArray.push(response.data.data.companyName);
                let companyDetails = response.data.data;
                companyDetails.companyId = this.props.userDetails.companyId
                this.setState({ activeCompanyName: companyArray, companyName: response.data.data.companyName, companyDetails: companyDetails })

            }
            else if (response.data.serviceStatus.statusCode === 210) {
                notification.error({
                    message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Error </span>{this.props.app_labels.couldNotcompleteReq}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }
            else {
                notification.error({
                    message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Error </span>{this.props.app_labels.error_fetchCompany}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }
        }).catch(error => {
            this.setState({ compretrieve: true })
            if (error.response !== undefined && error.response.data !== null && error.response.data !== undefined && error.response.data.serviceStatus !== undefined) {
                notification.error({
                    message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Error </span>{this.props.app_labels.couldNotcompleteReq}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }
            else {
                notification.error({
                    message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Error </span>{this.props.app_labels.couldNotcompleteReq}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }
        })

    }
    /**********getExpense category*************/
    getExpenseCategory = () => {
        axios.get(URLs.tripUrl + "getCompanyExpenseCategory", {
            responseType: 'json',
            headers: {
                "Authorization": 'Bearer ' + this.props.token.access_token
            }
        }).then(response => {
            this.setState({ catRetrieve: true });
            if (response.data.serviceStatus.statusCode === 200) {
                this.setState({ expenseCat: response.data.data });
            }
            else if (response.data.serviceStatus.statusCode === 210) {
                notification.error({
                    message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Error </span>{this.props.app_labels.couldNotcompleteReq}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }
        }).catch((error) => {
            this.setState({ catRetrieve: true });
            if (error.response !== undefined && error.response.data !== null && error.response.data !== undefined && error.response.data.serviceStatus !== undefined) {
                notification.error({
                    message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Error </span>{this.props.app_labels.couldNotcompleteReq}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }
            else {
                notification.error({
                    message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Error </span>{this.props.app_labels.couldNotcompleteReq}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }
        })
    }
    /**********getExpense category and All*************/

    updateCatAllSpin = (value) => {
        this.setState({ catRetrieve: value });
    }
    updateCatAllState = (value) => {
        this.setState({ expenseCatAll: value });
    }
    /**********get Transaction type and All*************/
    updateTypeAllSpin = (value) => {
        this.setState({ typeRetrieve: value });
    }
    updateTypeAllState = (value) => {
        this.setState({ transactionTypeAll: value });
    }
    /**********get Transaction type*************/
    getTransactionType = () => {
        axios.get(URLs.tripUrl + "getTransactionTypes", {
            responseType: 'json',
            headers: {
                "Authorization": 'Bearer ' + this.props.token.access_token
            }
        }).then(response => {
            this.setState({ typeRetrieve: true })
            if (response.data.serviceStatus.statusCode === 200) {
                this.setState({ transactionType: response.data.data });
            }
            else if (response.data.serviceStatus.statusCode === 210) {
                notification.error({
                    message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Error </span>{this.props.app_labels.couldNotcompleteReq}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }
        }).catch((error) => {
            this.setState({ typeRetrieve: true })
            if (error.response !== undefined && error.response.data !== null && error.response.data !== undefined && error.response.data.serviceStatus !== undefined) {
                notification.error({
                    message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Error </span>{this.props.app_labels.couldNotcompleteReq}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }
            else {
                notification.error({
                    message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Error </span>{this.props.app_labels.couldNotcompleteReq}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }
        })
    }
    selectedItems = (item) => {
        this.setState({ selecteditem: item });
    }
    render() {
        if (this.props.cardAssigned) {
            this.props.handlenewTripandCard(this.props.creditCardNo, this.props.newtripobj);
        }
        return (
            this.state.retrieve === true && this.state.catRetrieve === true && this.state.typeRetrieve === true && this.state.compretrieve === true ?
                <Layout>
                    <Content>
                    <div><UserActionHeaderComponent app_labels={this.props.app_labels} roles={"Card Company Admin"} /></div>
                        <div className="container-fluid" style={{ boxShadow: " 0px 3px 5px 5px #f2f2f2", background: "#F2F2F5" }}>
                            {/* {this.props.theme !=="elan" &&<div className="row" style={{ backgroundColor: "white", textAlign: "center" }}>
                                <span className="company-name"><b  role="heading" aria-level="1">{this.state.superadminCompany} Administration</b></span>
                            </div>} */}
                            <div className="row">
                                <div className="col-sm-10 col-sm-offset-1">
                                    <AdminCarousel app_labels={this.props.app_labels} index={1} initialSlide={this.state.selecteditem - 1} selectedItems={this.selectedItems} userrole={"Card Company Admin"} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12 col-sm-offset-0">
                                    <div>
                                        {this.state.selecteditem === 1 ? <CreateUserProfileComponent app_labels={this.props.app_labels} cadreRoles={this.state.cadreRoles} selectedRole={"Card Company Admin"} /> : null}
                                        {this.state.selecteditem === 2 ? <ViewandEditProfile app_labels={this.props.app_labels} activeCompanyName={this.state.activeCompanyName} cadreRoles={this.state.cadreRoles} selectedItems={this.selectedItems} userrole={"Card Company Admin"} /> : null}
                                        {this.state.selecteditem === 3 ? <AllActiveCards label_keys={this.props.app_labels} activeCompanyName={this.state.activeCompanyName} companyId={this.props.userDetails.companyId} companyDetails={this.state.companyDetails} roles={"Card Company Admin"} selectedLang={this.props.selectedLanguage === 'EN' ? 'English' : 'French'} /> : null}
                                        {this.state.selecteditem === 4 ? <CardMileage app_labels={this.props.app_labels} activeCompanyName={this.state.activeCompanyName} companyDetails={this.state.companyDetails} roles={"Card Company Admin"} /> : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Content>

                    <Footer id='footer'></Footer>
                </Layout> : <div className="dark-spinner loader-color"><Spin tip="Loading..." />
                <span class='visually-hidden page-title' tabIndex={-1}></span>
                </div>
        )
    }

}
function mapStateToProps(state, props) {
    return {
        userDetails: state.authenticationReducer.userDetails,
        token: state.authenticationReducer.token,
        app_labels: state.adduserReducer.app_labels,
        theme: state.authenticationReducer.theme,
        selectedLanguage: state.adduserReducer.selectedLanguage,
    };
}
function mapDispatchToProps(dispatch) {
    return {
        getExpenseCatAll: (updateCatAllSpin, updateCatAllState) => {
            dispatch(getExpenseCategoryAll(updateCatAllSpin, updateCatAllState));
        },
        getTransTypeAll: (updateTypeAllSpin, updateTypeAllState) => {
            dispatch(getTransactionTypeAll(updateTypeAllSpin, updateTypeAllState))

        }

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(CardAdminComponent);