/* eslint-disable no-unused-expressions */
import axios from 'axios';
const { URLs } = require('../data/constants.js')


function multiLanRequest() {
    return {
        type: 'MULTI_LAN_REQUEST'
    }
}

function multiLanRequestSuccess(response,selectedLan) {
    return {
        type: 'MULTI_LAN_REQUEST_SUCCESS',
        response: response,
        selectedLanguage:selectedLan.toUpperCase()
    }
}

function multiLanRequestComplete() {
    return {
        type: 'MULTI_LAN_REQUEST_COMP'
    }
}

function listofLanguages(response) {
    return {
        type: 'LIST_LANGUAGE',
        response: response
    }
}

export function multiLanguage(preferedLan) {
    return function (dispatch) {
        dispatch(multiLanRequest());
        return axios.get(URLs.comUrl + 'multiLanguageLabel/' + preferedLan, {
            headers: {
              "Authorization": "Basic Q2FkcmUtV2ViOmNhZHJl"
            }
        })
            .then((response) => {
                dispatch(multiLanRequestComplete());
                return response.data.data;
            })
            .catch((err) => {
                dispatch(multiLanRequestComplete());
                throw err;
            })
    }
}

export function multiLanguageLabelRetrieve(preferedLabel) {
    return function (dispatch, getState) {

        dispatch(multiLanguage(preferedLabel))
            .then((response) => {
                dispatch(multiLanRequestSuccess(response,preferedLabel));
            }).catch(error => {
                dispatch(multiLanRequestComplete());
            })

    }
}


export function getListOfLanguage() {
    return function (dispatch) {
        dispatch(multiLanRequest());
        return axios.get(URLs.comUrl + 'instantCardMultiLanguageList/', {
            headers: {
                "Authorization": "Basic Q2FkcmUtV2ViOmNhZHJl"
            }
        })
            .then((response) => {
                dispatch(multiLanRequestComplete());
                return response.data.data;
            })
            .catch((err) => {
                dispatch(multiLanRequestComplete());
                throw err;
            })
    }
}

export function fetchListLangauages() {
    return function (dispatch, getState) {

        dispatch(getListOfLanguage())
            .then((response) => {

                dispatch(listofLanguages(response));
            }).catch(error => {
                dispatch(multiLanRequestComplete());
            })

    }
}