import axios from 'axios';
import React from 'react';
import { notification } from 'antd';
import { getTrips } from './gettripsActions';
const { URLs, errorMessages } = require('../data/constants.js');

function getActiveHM(data) {
    return {
        type: "ACTIVE_HM",
        data
    }
}

/**
 * calls delegateAccess post request , updates the dB
 * @param {*} obj 
 * @param {*} stateUpdation 
 * @param {*} cancelDelegation 
 */
export function delegateAccess(obj, stateUpdation, cancelDelegation) {
    return (dispatch, getState) => {
        let {
            token,
        } = getState().authenticationReducer
        axios.post(URLs.tripUrl + "delegateaccess", obj, {
            headers: {
                "Authorization": 'Bearer ' + token.access_token
            }
        }).then(response => {
            if (response.data.serviceStatus.statusCode === 200) {
                stateUpdation(false);
                cancelDelegation();
                dispatch(getTrips());
                notification.success({
                    message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Success </span>{response.data.serviceStatus.message}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#dff0d8',
                        color: "green",
                        border: "1px solid green",
                    }
                });
            }
            else {
                stateUpdation(false);
                notification.error({
                    message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{response.data.serviceStatus.message}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }
        })
            .catch(error => {
                stateUpdation(false);
                if (error.response !== undefined && error.response.data !== null && error.response.data !== undefined && error.response.data.serviceStatus !== undefined) {
                    notification.error({
                        message: <span  role='alert' aria-live="assertive" aria-atomic="true" ><span className="visually-hidden">Error </span>{error.response.data.serviceStatus.message}</span>,
                        duration: 3,
                        style: {
                            backgroundColor: '#fff1f0',
                            border: "1px solid #ffa39e",
                        }
                    });
                }
                else {
                    notification.error({
                        message: <span  role='alert' aria-live="assertive" aria-atomic="true" ><span className="visually-hidden">Error </span>{errorMessages.generic_error}</span>,
                        duration: 3,
                        style: {
                            backgroundColor: '#fff1f0',
                            border: "1px solid #ffa39e",
                        }
                    });
                }
            })
    }
}

/**
 * Fetches active hirirng manager for companyId
 * @param {*} companyId 
 */
export function getActiveHm(companyId) {
    return (dispatch, getState) => {
        let {
            token,
        } = getState().authenticationReducer
        axios.get(URLs.adminUrl + "activeHiringManagers/" + companyId, {
            headers: {
                "Authorization": 'Bearer ' + token.access_token
            }
        }).then(response => {
            if (response.data.serviceStatus.statusCode === 200) {
                dispatch(getActiveHM(response.data));
            }
            else if (response.data.serviceStatus.statusCode === 210) {
                notification.warning({
                    message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{response.data.serviceStatus.message}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fffbe6',
                        border: "1px solid #ffe58f",
                    }
                });
            }
            else {
                notification.error({
                    message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>Couldn't fetch data Please try again!!</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }
        })
            .catch(error => {
                if (error.response !== undefined && error.response.data !== null && error.response.data !== undefined && error.response.data.serviceStatus !== undefined) {
                    notification.error({
                        message: <span  role='alert' aria-live="assertive" aria-atomic="true" ><span className="visually-hidden">Error </span>{error.response.data.serviceStatus.message}</span>,
                        duration: 3,
                        style: {
                            backgroundColor: '#fff1f0',
                            border: "1px solid #ffa39e",
                        }
                    });
                }
                else {
                    notification.error({
                        message: <span  role='alert' aria-live="assertive" aria-atomic="true" ><span className="visually-hidden">Error </span>{errorMessages.generic_error}</span>,
                        duration: 3,
                        style: {
                            backgroundColor: '#fff1f0',
                            border: "1px solid #ffa39e",
                        }
                    });
                }
            })
    }
}