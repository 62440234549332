import React from "react";
import "antd/dist/antd.css";
import '../../FontIcons/style.css';
import MediaQuery from 'react-responsive';
import axios from 'axios';
import { getUserActions } from '../../actions/userAction';
import { getTrips, fetchingDbCompletes } from '../../actions/gettripsActions';
import { getAllDetails } from '../../actions/getAllDetails';
import { Menu, Layout, Badge, Spin, Input, Table, notification, Checkbox, Icon } from "antd";
import { connect } from 'react-redux';
import DashContent from './dashcontent';
import UserAction from './userActionscomponent';
import DelegationDashBoard from './delegationDashBoard'
import UserActionHeaderComponent from '../useractionheader.js';
import { loaderFocus } from '../../utils/accessability';
const { URLs, tripStatusConstants } = require('../../data/constants.js');
const Search = Input.Search;
const { Sider, Content, Footer } = Layout;

/*
*renders the dashboard screen when manager logins in XW
*/
class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            current: "",
            collapsed: false,
            searchArray: [],
            delegateArray: [],
            delegation: false,
            tripCheckedStatus: [],
            searchFlag: false,
            selectedTripId: null
        };
        this.handleClick = this.handleClick.bind(this)
    }

    /**on render of all child component(to route back to the user action page)
     * @param  {} prevProps
     */
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.setState({ current: "" });
        }
        // if ((!prevProps.tripdata || prevProps.tripdata.length === 0) && (this.props.tripdata && this.props.tripdata.length > 0)) {
        if (prevProps.tripdata !== this.props.tripdata) {
            let tripCheckedStatus = this.props.tripdata.map(el => ({
                tripId: el.tripId,
                checked: false,
                isTripDelegated: el.isTripDelegated
            }))
            this.setState(() => ({
                tripCheckedStatus
            }))
        }
    }

    /**will be executed on mount of the component
     */

    redirect(){
        this.props.history.push('/dashboard');
    }

    /**
     * Lifecycle method
     * will be called onMount of the component
     */
    componentDidMount() {
        if(this.props.isDbFetching && this.props.isDbuserTripFetching && this.props.isDbuserActionFetching){
            loaderFocus(".loader-color .ant-spin-text"); 
        }
        if (!this.props.userDetails.roles.includes('Manager')) {
            window.history.back();
        }
        
        window.onpopstate = () => {
            this.redirect();
        };

        if (window.sessionStorage.getItem("LOGIN_STATUS") === "true") {
            this.props.gettrips();
            this.props.getuseractions();
            this.props.getalldetails();
        }else{
            this.props.history.push('/login');
        }
    }

    /**
     * Searches the trip using axios request based on the name entered
     */
    onSearch = (value) => {
        this.setState({ searchFlag: true })
        loaderFocus(".loader-color .ant-spin-text");
        let obj = {
            "name": value,
            "count": "10"
        }
        axios.post(URLs.tripUrl + "searchTrip", obj, {
            headers: {
                "Authorization": 'Bearer ' + this.props.token.access_token
            }
        }).then(response => {
            if (response.data.serviceStatus.statusCode === 200) {
                this.setState({ searchArray: response.data.trips, searchFlag: false });
                if (response.data.trips.length === 0) {
                    notification.warning({
                        message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error</span>{this.props.app_labels.error_noTripsFound}</span>,
                        duration: 3,
                        style: {
                            backgroundColor: '#fffbe6',
                            border: "1px solid #ffe58f",
                        }
                    });
                }
            }
            else {
                this.setState({ searchFlag: false });
                notification.error({
                    message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{this.props.app_labels.error_searching}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }

        })
            .catch(err => {
                this.setState({ searchFlag: false });
                notification.error({
                    message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{this.props.app_labels.error_searching}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            })
    }

    /**will be executed on unmount of the component
     */
    componentWillUnmount() {
        window.removeEventListener("popstate",this.redirect);
        this.props.fetchingDbComplete(true)
    }

    /**To display texts in correct foramt(First letter will be in Capital)
     * @param  {} text
     */
    capitalize = (text) => {
        if (text !== undefined) {
            var s = text.toLowerCase();
            if (typeof s !== 'string') return ''
            return s.charAt(0).toUpperCase() + s.slice(1)
        }
    }


    /**on click of by all action link it opens the corresponding trip details
     * @param  {} tripid
     */
    hyperlinkFunc = (tripid) => {
        this.setState({
            current: tripid
        });
    }
    /**on Cick of corresponding trip in list view will open the corresponing trip details
     * @param  {} e
     */
    handleClick(key) {
        this.setState({
            current: key,
            selectedTripId: key
        });
    }

    /**
     * based on the trip status, sets the delegate array and updated checked status
     */
    delegationSelect = (value, e) => {
        let newTripCheckedStatus = this.state.tripCheckedStatus.slice()
        newTripCheckedStatus.filter(item => !item.isTripDelegated).find(el => el.tripId === value.tripId).checked = !!e.target.checked
        let newDelegateArray = this.state.delegateArray.slice()
        if (!!e.target.checked) {
            let newDelegate = { ...this.props.tripdata.find(el => el.tripId === value.tripId) }
            newDelegateArray.push(newDelegate)
        } else {
            newDelegateArray.splice(
                newDelegateArray.findIndex(el => el.tripId === value.tripId), 1
            )
        }

        this.setState(() => ({
            tripCheckedStatus: newTripCheckedStatus,
            delegateArray: newDelegateArray
        }))
    }

    /**
     * Updates the trip Checked status and delegate array for all the trips
     */
    delegationSelectAll = (e) => {
        let newTripCheckedStatus = this.state.tripCheckedStatus.slice()
        let newDelegateArray = !!e.target.checked === true ? this.props.tripdata.filter(e1 => e1.isTripDelegated !== true) : []
        for (let i = 0, n = newTripCheckedStatus.length; i < n; i++) {
            if (!newTripCheckedStatus[i].isTripDelegated) {
                newTripCheckedStatus[i].checked = !!e.target.checked;
            }

        }
        this.setState(() => ({
            tripCheckedStatus: newTripCheckedStatus,
            delegateArray: newDelegateArray
        }))
    }

    /**
     * Cancels the delegation of the trip to unchecked
     */
    cancelDelegation = (value) => {
        let newTripCheckedStatus = this.state.tripCheckedStatus.slice()
        let newDelegateArray = []
        for (let i = 0, n = newTripCheckedStatus.length; i < n; i++) {
            newTripCheckedStatus[i].checked = value
        }
        this.setState(() => ({
            tripCheckedStatus: newTripCheckedStatus,
            delegateArray: newDelegateArray
        }))

    }


    render() {

        const columns = [
            {
                title: <span className="sort-filter">{this.props.app_labels.sortByDate}</span>,
                dataIndex: "description",
                width: 40,
                sortDirections: ['descend'],
                sorter: (a, b) => {
                    return Number(new Date(a.estimatedStartDate)) - Number(new Date(b.estimatedStartDate))
                    // return Number(new Date(a.estimatedStartDate)) > Number(new Date(b.estimatedStartDate)) ? -1 : Number(new Date(a.estimatedStartDate)) < Number(new Date(b.estimatedStartDate)) ? 1 : 0
                },
                filterIcon: filtered => <span>
                    <MediaQuery maxDeviceWidth={1214} minDeviceWidth={800}>
                        <br /> <span className="sort-filter">{this.props.app_labels.filteByAllStatus}</span>
                        <i role='img' aria-label="filter by status" className="icon-Drop-down" style={{ color: filtered ? 'grey' : 'grey', marginLeft: "9%" }}></i>
                    </MediaQuery>
                    <MediaQuery minDeviceWidth={1215}>
                        <span className="sort-filter">{this.props.app_labels.filteByAllStatus}</span>
                        <i role='img' aria-label="filter by status" className="icon-Drop-down" style={{ color: filtered ? 'grey' : 'grey', marginLeft: "2%" }}></i>
                    </MediaQuery>
                    <MediaQuery maxDeviceWidth={799}>
                        <span className="sort-filter">{this.props.app_labels.filteByAllStatus}</span>
                        <i role='img' aria-label="filter by status" className="icon-Drop-down" style={{ color: filtered ? 'grey' : 'grey', marginLeft: "2%" }}></i>
                    </MediaQuery>
                </span>,
                filters: [{
                    text: tripStatusConstants.Saved,
                    value: tripStatusConstants.Saved,
                }, {
                    text: tripStatusConstants.Cancelled,
                    value: tripStatusConstants.Cancelled,
                }, {
                    text: tripStatusConstants.AwaitingTravel,
                    value: tripStatusConstants.AwaitingTravel,
                }, {
                    text: tripStatusConstants.InProgress,
                    value: tripStatusConstants.InProgress,
                }, {
                    text: tripStatusConstants.PendingExpenseReporting,
                    value: tripStatusConstants.PendingExpenseReporting,
                }, {
                    text: tripStatusConstants.PendingClosure,
                    value: tripStatusConstants.PendingClosure,
                }, {
                    text: tripStatusConstants.ArchivedTrip,
                    value: tripStatusConstants.ArchivedTrip,
                }],
                onFilter: (value, record) => record.tripActionStatus.description.includes(value),
                children: [{
                    title: this.state.delegation ?
                        <div>
                            <MediaQuery maxDeviceWidth={1214} minDeviceWidth={767}>
                                <span style={{ float: "left", marginLeft: "2%" }}>
                                    <Checkbox className="delegationCheckbox" checked={this.state.tripCheckedStatus.filter(item => !item.isTripDelegated).every(el => el.checked === true)}
                                        onChange={(e) => this.delegationSelectAll(e)}>
                                        <span className="sort-filter">{this.props.app_labels.selectAll}</span>
                                    </Checkbox>
                                </span>
                                <span className="sort-filter" style={{ float: "right", marginRight: "2%" }}>
                                    {this.state.delegateArray.length} {this.props.app_labels.tripsSelected}
                                </span>
                            </MediaQuery>
                            <MediaQuery minDeviceWidth={1215}>
                                <span style={{ float: "left", marginLeft: "2%" }}>
                                    <Checkbox className="delegationCheckbox" checked={this.state.tripCheckedStatus.filter(item => !item.isTripDelegated).every(el => el.checked === true)}
                                        onChange={(e) => this.delegationSelectAll(e)}>
                                        <span className="sort-filter">{this.props.app_labels.selectAll}</span>
                                    </Checkbox>
                                </span>
                                <span className="sort-filter" style={{ float: "right", marginRight: "2%" }}>
                                    {this.state.delegateArray.length} {this.props.app_labels.tripsSelected}
                                </span>
                            </MediaQuery>
                            <MediaQuery maxDeviceWidth={766}>
                                <span style={{ float: "left", marginLeft: "1%" }}>
                                    <Checkbox className="delegationCheckbox" checked={this.state.tripCheckedStatus.filter(item => !item.isTripDelegated).every(el => el.checked === true)}
                                        onChange={(e) => this.delegationSelectAll(e)}>
                                        <span className="sort-filter">{this.props.app_labels.selectAll}</span>
                                    </Checkbox>
                                </span>
                                <span className="sort-filter" style={{ float: "right", marginRight: "2%" }}>
                                    {this.state.delegateArray.length} {this.props.app_labels.tripsSelected}
                                </span>
                            </MediaQuery>

                        </div>
                        : null,
                    dataIndex: "description",
                    width: 200,

                    render: (text, object) => (
                        <div>
                            <label>
                                <Menu
                                    theme={"light"}
                                    /* onClick={this.handleClick} */
                                    defaultSelectedKeys={[1]}
                                    defaultOpenKeys={["1"]}
                                    selectedKeys={[this.state.current]}
                                    mode="inline"
                                    style={{ backgroundColor: "white" }}>

                                    <Menu.Item key={object.tripId}
                                        style={{ height: "20%" }}
                                        className={this.state.selectedTripId === object.tripId ? 'selected-trip' : ''}
                                    >
                                        <div className="row" tabindex='0' onClick={() =>
                                            this.handleClick(object.tripId)
                                        }
                                            onKeyUp={e => {
                                                if (e.keyCode === 13) {
                                                    this.handleClick(object.tripId)
                                                }
                                            }}>
                                            {/*   / *********Color bar displayed according to the status of trip***********/
                 /*  
                 <Col style={{ paddingLeft: "0%", borderLeft: "2px", borderTop: "0%" }} xs={{ span: 2, offset: 0 }} lg={{ span: 1, offset: 0 }}>
                    <Divider type="verticle" style={{ backgroundColor: color, width: "9px", height: "135px" }} />
                  </Col> */}
                                            <div className="col-sm-12 col-sm-offset-0">
                                                {/* <div className="row" >
                                                    <div className="col-sm-2 col-sm-offset-1 delegationCheckbox">
                                                        {this.state.delegation ? <Checkbox checked={this.state.tripCheckedStatus.find(el => el.tripId === object.tripId).checked} onChange={(e) => this.delegationSelect(object, e)}></Checkbox> : null}
                                                    </div>
                                                </div> */}
                                                <div className="row row_center" >
                                                    {/* <div className="col-sm-5 col-xs-5 col_center">
                                                        <Avatar size='large' className="avatar-class" ><span>{object.initials}</span>
                                                        </Avatar>
                                                    </div> */}
                                                    <div className="col-sm-9 col-xs-9" style={{ marginLeft: "2%" }}>
                                                        {this.state.delegation ? <Checkbox className="delegationCheckbox"
                                                            disabled={object.isTripDelegated}
                                                            checked={this.state.tripCheckedStatus.find(el => el.tripId === object.tripId).checked}
                                                            onChange={(e) => this.delegationSelect(object, e)}></Checkbox> :
                                                            null}
                                                        { /*<span className='dashboardName' style={{ wordWrap: 'break-word' }} title={this.capitalize(object.firstName) + ' ' + this.capitalize(object.lastName)}>{object.firstName.length > 20 ? object.firstName.slice(0, 19) + '...' : object.firstName.concat(object.lastName).length > 20 ? this.capitalize(object.firstName) + "..." : this.capitalize(object.firstName) + ' ' + this.capitalize(object.lastName)}</span>*/ /*EW-817 Remove capitalize*/ }
                                                        <span className='dashboardName' style={{ wordWrap: 'break-word' }} title={object.firstName + ' ' + object.lastName}>{object.firstName.length > 20 ? object.firstName.slice(0, 19) + '...' : object.firstName.concat(object.lastName).length > 20 ? object.firstName + "..." : object.firstName + ' ' + object.lastName}</span>
                                                        <div className="margin-top-sider">{this.props.app_labels.travelerId} :{object.traveler.cadreId}</div>
                                                        <div className="margin-top-sider">{this.props.app_labels.travelDate} :{object.actualStartDate === null ? object.estimatedStartDate : object.actualStartDate}</div>
                                                        <div className="margin-top-sider">{this.props.app_labels.destination1} :{object.destination.length > 15 ? object.destination.substring(0, 15) + "..." : object.destination}</div>
                                                    </div>
                                                    <div className="col-sm-3 col-xs-3" style={{ marginTop: "4%" }}>
                                                        <div className="margin-top-sider">
                                                            {object.flightStatus === "red" ? <div> <i role='img' aria-label="Flight" title="Flight booking" className="icon-Airplane-grey-size-1 dashboard-listicons" ></i> &nbsp; <i role='img' aria-label="Attention" title='Attention' className="icon-attention" style={{ color: '#C53D47' }} ></i></div> : null}
                                                            {object.flightStatus === "green" ? <div> <i role='img' aria-label="Flight" title="Flight booking" className="icon-Airplane-grey-size-1 dashboard-listicons" > </i> <i role='img' aria-label="Complete" title='Complete' className="icon-green-check" style={{ color: '#00B818' }} ></i></div> : null}
                                                        </div>
                                                        <div className="margin-top-sider">
                                                            {object.hotelStatus === "red" ? <div> <i role='img' aria-label="Hotel" title="Hotel booking" className="icon-Buildings-grey-size-1 dashboard-listicons" ></i> &nbsp;&nbsp; <i role='img' aria-label="Attention" title='Attention' className="icon-attention" style={{ color: '#C53D47' }} ></i></div> : null}
                                                            {object.hotelStatus === "green" ? <div><i role='img' aria-label="Hotel" title="Hotel booking" className="icon-Buildings-grey-size-1 dashboard-listicons" ></i> &nbsp; <i role='img' aria-label="Complete" title='Complete' className="icon-green-check" style={{ color: '#00B818' }} ></i></div> : null}
                                                        </div>
                                                        <div className="margin-top-sider">
                                                            {object.cardStatus === "red" ? <div>  <i role='img' aria-label="Card" title="Card assignment" className="icon-Credit-Card-grey-size1 dashboard-listicons" ></i> <i role='img' aria-label="Attention" title='Attention' className="icon-attention" style={{ color: '#C53D47' }} ></i></div> : null}
                                                            {object.cardStatus === "green" ? <div> <i role='img' aria-label="Card" title="Card assignment" className="icon-Credit-Card-grey-size1 dashboard-listicons" ></i> <i role='img' aria-label="Complete" title='Complete' className="icon-green-check" style={{ color: '#00B818' }} ></i></div> : null}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-9 col-xs-9">
                                                        <Badge
                                                            count={object.tripActionStatus.description}
                                                            style={{
                                                                borderRadius: '5px',
                                                                backgroundColor: object.tripActionStatus.description === "Saved" || object.tripActionStatus.description === "Pending Closure" ? "#0A41C5" : "#737373",
                                                            }}
                                                            className='badgeclass'
                                                        />
                                                    </div>
                                                    {/* <div className="col-sm-2 col-sm-offset-0 col-xs-2 col-xs-offset-0">
                                                        {object.flightStatus === "red" ? <div> <i role='img' aria-label="Flight" title="Flight booking" className="icon-Airplane-grey-size-1 dashboard-listicons" ></i> <i role='img' aria-label="Attention" title='Attention' className="icon-attention" style={{ color: '#C53D47' }} ></i></div> : null}
                                                        {object.flightStatus === "green" ? <div> <i role='img' aria-label="Flight" title="Flight booking" className="icon-Airplane-grey-size-1 dashboard-listicons" > </i>  <i role='img' aria-label="Complete" title='Complete' className="icon-green-check" style={{ color: '#00B818' }} ></i></div> : null}
                                                    </div>
                                                    <div className="col-sm-2 col-sm-offset-0 col-xs-2 col-xs-offset-0">
                                                        {object.hotelStatus === "red" ? <div> <i role='img' aria-label="Hotel" title="Hotel booking" className="icon-Buildings-grey-size-1 dashboard-listicons" ></i>  <i role='img' aria-label="Attention" title='Attention' className="icon-attention" style={{ color: '#C53D47' }} ></i></div> : null}
                                                        {object.hotelStatus === "green" ? <div><i role='img' aria-label="Hotel" title="Hotel booking" className="icon-Buildings-grey-size-1 dashboard-listicons" ></i> <i role='img' aria-label="Complete" title='Complete' className="icon-green-check" style={{ color: '#00B818' }} ></i></div> : null}
                                                    </div>
                                                    <div className="col-sm-2 col-sm-offset-0 col-xs-2 col-xs-offset-0">
                                                        {object.cardStatus === "red" ? <div>  <i role='img' aria-label="Card" title="Card assignment" className="icon-Credit-Card-grey-size1 dashboard-listicons" ></i>  <i role='img' aria-label="Attention" title='Attention' className="icon-attention" style={{ color: '#C53D47' }} ></i></div> : null}
                                                        {object.cardStatus === "green" ? <div> <i role='img' aria-label="Card" title="Card assignment" className="icon-Credit-Card-grey-size1 dashboard-listicons" ></i> <i role='img' aria-label="Complete" title='Complete' className="icon-green-check" style={{ color: '#00B818' }} ></i></div> : null}
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </Menu.Item>
                                </Menu>
                            </label>
                        </div >
                    ),
                }],
            }]

        var rows2 = [];

        rows2 = this.props.tripdata;
        return (
            <div >
                {!this.props.isDbFetching && !this.props.isDbuserTripFetching && !this.props.isDbuserActionFetching ?
                    <Layout>
                        <div><UserActionHeaderComponent roles={"Manager"} /></div>
                        {/* <Header style={{ backgroundColor: "white" }}><UserActionHeaderComponent roles={"Hiring Manager"} /> </Header> */}
                        <Layout style={{ boxShadow: " 0px 0px 5px 5px #CCCCCC" }}>
                            <MediaQuery minDeviceWidth={800}>
                                <Sider
                                    breakpoint="lg"
                                    width="320px" /*23%*/
                                    collapsedWidth="0"
                                    theme="light"
                                //onBreakpoint={(broken) => { console.log(broken); }}
                                //onCollapse={(collapsed, type) => { console.log(collapsed, type); }} 
                                >
                                    <div className="row row_center dashboard-home" style={{ margin: 'auto', borderBottom: '1.4px solid #E8E8EB', background: this.state.current === "" && this.state.delegation === false ? "#0C2074" : "white" }}>
                                        <div className='col-sm-9 col-sm-offset-0 col_center'>
                                            <button className="focusicon pseudoButtonIcon" tabindex='0' type='button' onClick={(e) => {
                                                e.stopPropagation()
                                                this.setState({ delegation: false, current: "" })
                                                this.props.gettrips();
                                            }}>
                                                <span className="icon-Home-white dashboard-home-icon"
                                                    style={{ color: this.state.current === "" && this.state.delegation === false ? "white" : "#0C2074" }}> </span>
                                                <span className="dashboard-home-text" style={{ color: this.state.current === "" && this.state.delegation === false ? "white" : "#0C2074" }}>
                                                    <b>{this.props.app_labels.dashboard}</b></span></button>
                                        </div>
                                        <div className='col-sm-1 col-sm-offset-2 col_center'>
                                            {this.state.current === "" && this.state.delegation === false
                                                ? <span className="delegationIcon dashboard-home" style={{ color: "white" }}> <Icon type="right" /></span> : null}
                                        </div>
                                    </div>
                                    <div className="row row_center dashboard-home" style={{ margin: 'auto', borderBottom: '1.4px solid #E8E8EB', background: this.state.delegation ? "#0C2074" : "white" }} >
                                        <div className='col-sm-9 col-sm-offset-0 col_center'>
                                            <button className="focusicon pseudoButtonIcon" tabindex='0' type='button' onClick={(e) => {
                                                e.stopPropagation()
                                                this.setState({ delegation: !this.state.delegation })
                                            }}>
                                                <span className="icon-Delegation-Black  dashboard-home-icon" style={{ color: this.state.delegation === true ? "white" : "#0C2074" }}> </span> <span className="dashboard-home-text" style={{ color: this.state.delegation === true ? "white" : "#0C2074" }}><b>{this.props.app_labels.delegation}</b></span></button>
                                        </div>
                                        <div className='col-sm-1 col-sm-offset-2 col_center'>
                                            {this.state.delegation === true
                                                ? <span className="delegationIcon dashboard-home" style={{ color: "white" }}> <Icon type="right" /></span> : null}
                                        </div>
                                    </div>
                                    <div style={{ borderBottom: '1.4px solid #E8E8EB' }}>
                                        <div className="row">
                                            <div className="col-sm-11 col-sm-offset-0 searchinput">
                                                <div className='allTripsText'>{this.props.app_labels.allTrips}
                                                    <span><Search className="search-input"
                                                        aria-label="Search trips"
                                                        // placeholder="Search Trips"
                                                        onChange={(e) => {
                                                            if (e.target.value === "" || e.target.value === null) {
                                                                this.setState({ searchArray: [] });
                                                            }
                                                        }}
                                                        onSearch={this.onSearch}
                                                    /></span>
                                                </div>
                                            </div>
                                        </div><br />
                                    </div>
                                    <div className="row" style={{ marginTop: "2%" }}>
                                        {this.state.searchFlag ? <div className="dark-spinner loader-color"><Spin tip="Searching..." /></div> : null}
                                        <div className="col-sm-12 col-sm-offset-0">
                                            <div id="content">
                                                <Table
                                                    className='dashboardTable'
                                                    size="middle"
                                                    columns={columns}
                                                    scroll={{ y: '80vh', x: 300 }}
                                                    dataSource={this.state.searchArray.length === 0 ? rows2 : this.state.searchArray}
                                                    pagination={false}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </Sider>
                            </MediaQuery>
                            <MediaQuery maxDeviceWidth={799}>
                                <Sider
                                    breakpoint="lg"
                                    width="90%"
                                    collapsedWidth="0"
                                    theme="light"
                                //onBreakpoint={(broken) => { console.log(broken); }}
                                //onCollapse={(collapsed, type) => { console.log(collapsed, type); }}
                                >
                                    <div className="row row_center dashboard-home" style={{ margin: 'auto', borderBottom: '1.4px solid #E8E8EB', background: this.state.current === "" && this.state.delegation === false ? "#0C2074" : "white" }}>
                                        <div className='col-sm-9 col-sm-offset-0 col_center'>
                                            <button className="focusicon pseudoButtonIcon" tabindex='0' type='button' onClick={(e) => {
                                                e.stopPropagation()
                                                this.setState({ delegation: false, current: "" })
                                            }}>
                                                <span className="icon-Home-white dashboard-home-icon" style={{ color: this.state.current === "" && this.state.delegation === false ? "white" : "#0C2074" }}> </span> <span className="dashboard-home-text" style={{ color: this.state.current === "" && this.state.delegation === false ? "white" : "#0C2074" }}><b>{this.props.app_labels.dashboard}</b></span></button>
                                        </div>
                                        <div className='col-sm-1 col-sm-offset-2 col_center'>
                                            {this.state.current === "" && this.state.delegation === false
                                                ? <span className="delegationIcon dashboard-home" style={{ color: "white" }}> <Icon type="right" /></span> : null}
                                        </div>
                                    </div>
                                    <div className="row row_center dashboard-home" style={{ margin: 'auto', borderBottom: '1.4px solid #E8E8EB', background: this.state.delegation ? "#0C2074" : "white" }} >
                                        <div className='col-sm-9 col-sm-offset-0 col_center'>
                                            <button className="focusicon pseudoButtonIcon" tabindex='0' type='button' onClick={(e) => {
                                                e.stopPropagation()
                                                this.setState({ delegation: !this.state.delegation })
                                            }}>
                                                <span className="icon-Delegation-Black  dashboard-home-icon" style={{ color: this.state.delegation === true ? "white" : "#0C2074" }}> </span> <span className="dashboard-home-text" style={{ color: this.state.delegation === true ? "white" : "#0C2074" }}><b>{this.props.app_labels.delegation}</b></span></button>
                                        </div>
                                        <div className='col-sm-1 col-sm-offset-2 col_center'>
                                            {this.state.delegation === true
                                                ? <span className="delegationIcon dashboard-home" style={{ color: "white" }}> <Icon type="right" /></span> : null}
                                        </div>
                                    </div>
                                    <div style={{ borderBottom: '1.4px solid #E8E8EB' }}>
                                        <div className="row">
                                            <div className="col-sm-10 col-sm-offset-0 searchinput">
                                                < div className='allTripsText'>{this.props.app_labels.allTrips}
                                                    <span>
                                                        <Search className="search-input"
                                                            aria-label="Search trips"
                                                            // placeholder="Search trips"
                                                            onChange={(e) => {
                                                                if (e.target.value === "" || e.target.value === null) {
                                                                    this.setState({ searchArray: [] });
                                                                }
                                                            }}
                                                            onSearch={this.onSearch}

                                                        /></span>
                                                </div>
                                            </div><br />
                                        </div>
                                    </div>
                                    <div className="row" style={{ marginTop: "2%" }}>
                                        {this.state.searchFlag ? <div className="dark-spinner loader-color"><Spin tip="Searching..." /></div> : null}
                                        <div className="col-sm-12 col-sm-offset-0">
                                            <div id="content">
                                                <Table
                                                    className='dashboardTable'
                                                    size="middle"
                                                    columns={columns}
                                                    scroll={{ y: '100vh' }}
                                                    dataSource={this.state.searchArray.length === 0 ? rows2 : this.state.searchArray}
                                                    pagination={false}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </Sider>
                            </MediaQuery>

                            <Content className='contentClass'>
                                {this.state.current === "" && this.state.delegation !== true ? <div>
                                    <UserAction hyperlinkFunc={this.hyperlinkFunc} allTripDetail={this.props.allTripDetail} />
                                </div> : this.state.delegation ? <DelegationDashBoard app_labels={this.props.app_labels} cancelDelegation={this.cancelDelegation} delegateArray={this.state.delegateArray} /> : <div>
                                    {rows2.map((object, index) => {
                                        return <div>{this.state.current === object.tripId ? <DashContent username={this.props.userDetails.username} /*sending logged in username for ViewCardDetais to verify otp against this user*/ app_labels={this.props.app_labels} hyperlinkFunc={this.hyperlinkFunc} data={object} /* flightattachmentId={flightattachmentId} flightLocationUrl={flightLocationUrl} hotelLocationUrl={hotelLocationUrl} hotelAttachmentId={hotelAttachmentId} */ /> : null} </div>
                                    })}
                                </div>
                                }
                            </Content>
                        </Layout>
                        <Footer id='footer'></Footer>
                    </Layout> : <div className="dark-spinner loader-color"><Spin tip="Loading..." /></div>
                }
            </div>
        );
    }
}

function mapStateToProps(state, props) {
    return {
        logoutStatus: state.logoutReducer,
        tripdata: state.gettripReducer.getAlltripData,
        isDbFetching: state.gettripReducer.isDbFetching,
        isDbuserTripFetching: state.gettripReducer.isDbuserTripFetching,
        isDbuserActionFetching: state.gettripReducer.isDbuserActionFetching,
        allTripDetail: state.getAllDetailReducer.allTripDetail,
        userDetails: state.authenticationReducer.userDetails,
        token: state.authenticationReducer.token,
        app_labels: state.adduserReducer.app_labels,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        gettrips: () => {
            dispatch(getTrips());
        },
        getuseractions: () => {

            dispatch(getUserActions())
        },
        getalldetails: () => {
            dispatch(getAllDetails());
        },
        fetchingDbComplete: (data) => {
            dispatch(fetchingDbCompletes(data));
        }


    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);

