import React, { Component } from 'react'
import ReactDOM from 'react-dom';
import { connect } from 'react-redux'
import axios from 'axios'
import qs from 'qs'
import moment from 'moment-timezone'
import {  Tabs, Tab, TextField  } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import { Row, Col, Icon, Button, notification, Drawer, Input, Spin, Modal, Table, Card, Tooltip } from 'antd'
import { Counter } from './Counter'
import { viewPhoneNumber } from './phoneNumberFormat'
import prepareTitle,{ loaderFocus } from '../../utils/accessability';
import { forEach, lastIndexOf } from 'lodash'
import { ConnectedTvOutlined } from '@mui/icons-material';
const { URLs, elanRelatedUrls } = require('../../data/constants.js')
var dateFormat = require('dateformat')

/*
* renders when user wants to view the previously created card.
*/
class CradDetailComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            viewCardDetails: null,
            showOtp: false,
            otpCom: false,
            otp1: '',
            otp2: '',
            otp3: '',
            otp4: '',
            /* XW-61 issue - invalid OTP attempts for unlimited times 
            added two more state variable to store sth and 6th otp digits*/
            otp5: '',
            otp6: '',
            wrongOTP: false,
            show: false,
            confirmVisible: false,
            confirmError: false,
            confirmSuccess: false,
            errorViewCard: false,
            previousCard: null,
            previousUser: null,
            showSpin: false,
            unMasked: false,
            activeResend: false,
            selectedRow: props.selectedRow,
            thirdWrongOtp: false,
            trxButtonSelected: false,
            availableBalance: null,
            trxData: [],
            current: 1,
            TotalPage: null,
            sortedInfo: null,
            sortOrderText: '',
            isFocused: true,
            activeTab: '1',
            editEnable: false,
            error: null,
            tooltipVisible: false,
            creditLimit: props.selectedRow.creditLimit,
            validFrom: dateFormat(props.selectedRow.validFrom, 'mm/dd/yyyy'),
            validTo:  props.selectedRow.validTo ? dateFormat( props.selectedRow.validTo, 'mm/dd/yyyy') : dateFormat( props.selectedRow.validTill, 'mm/dd/yyyy'),
            datepickerOpen: false,
            validFromEdit: false, 
            validToEdit: false,
            creditLimitEdit: false,
            isloading: false,
            open: false,
            okClicked: false,
            futureDate: null,
        }
        this.defaultFocus = this.defaultFocus.bind(this);
        this.deactivateBtnRef = React.createRef();

    }

    handleOpen = () => {
        this.setState({ confirmVisible: true })
        if (ReactDOM.findDOMNode(this.refs.successDeactivateItem)){
            ReactDOM.findDOMNode(this.refs.successDeactivateItem).focus();
        }
    }

    componentDidMount() {
        this.availableCreditApiMethod()
        this.viewCardList(true, null)
        this.trxApiMethod()
    }

    /*
     * lifecycle method, its called on update of the component
     */
    componentDidUpdate(prevProps, prevState) {
        if (!this.state.viewCardDetails && this.state.errorViewCard === false) this.viewCardList(true, null)
        else if (!this.state.viewCardDetails && (this.state.previousUser + '' !== this.props.cardDetails.USER_ID + '' || this.state.previousCard + '' !== this.props.cardDetails.CARD_REF + ''))
            this.setState({ errorViewCard: false })
        else if (this.state.viewCardDetails && (this.state.previousUser + '' !== this.props.cardDetails.USER_ID + '' || this.state.previousCard + '' !== this.props.cardDetails.CARD_REF + ''))
            this.setState({ viewCardDetails: null, errorViewCard: false })    
        
        if (this.props.type !== "bulkCard" && this.props.selectedRow && (prevProps.selectedRow.creditLimit !== this.props.selectedRow.creditLimit || dateFormat( prevProps.selectedRow.validFrom, 'mm/dd/yyyy') !== dateFormat(this.props.selectedRow.validFrom, 'mm/dd/yyyy') || (dateFormat( prevProps.selectedRow.validTo, 'mm/dd/yyyy') !== (this.props.selectedRow.validTo ? dateFormat(this.props.selectedRow.validTo, 'mm/dd/yyyy') : dateFormat(this.props.selectedRow.validTill, 'mm/dd/yyyy'))))) {
            this.setState(() => ({
                isloading: false, 
                creditLimit: this.props.selectedRow.creditLimit,
                validFrom: dateFormat(this.props.selectedRow.validFrom, 'mm/dd/yyyy'),
                validTo:  this.props.selectedRow.validTo ? dateFormat( this.props.selectedRow.validTo, 'mm/dd/yyyy') : dateFormat( this.props.selectedRow.validTill, 'mm/dd/yyyy'),
                selectedRow: this.props.selectedRow
            }))
            this.availableCreditApiMethod();
            this.props.updateSuccess();
        }

        if(prevProps.okClicked !== this.props.okClicked){
            if(this.props.okClicked){
                document.getElementById('edit-btn-icon').focus();
            }
        }

        const divUp = document.querySelectorAll(".anticon.anticon-caret-up.ant-table-column-sorter-up.off");
        const divDown = document.querySelectorAll(".anticon.anticon-caret-down.ant-table-column-sorter-down.off");
        if(divUp[0]){
            divUp.forEach(div => {
                div.setAttribute('role', 'img')
                div.setAttribute('aria-hidden', 'true')
            })
        }
        if(divDown[0]){
    
            divDown.forEach(div => {
                div.setAttribute('role', 'img')
                div.setAttribute('aria-hidden', 'true')
            })
    
        }

        const divPagination = document.querySelectorAll(".ant-pagination-item");
        if(divPagination[0]){
            divPagination.forEach(div => {
                div.setAttribute('tabindex', "-1");
            })
        }

        const divPaginationPrev = document.querySelectorAll(".ant-pagination-prev");
        if(divPaginationPrev[0]){
            divPaginationPrev.forEach(div => {
                div.setAttribute('tabindex', "-1");
            })
        }

        const divPaginationNext = document.querySelectorAll(".ant-pagination-next");
        if(divPaginationNext[0]){
            divPaginationNext.forEach(div => {
                div.setAttribute('tabindex', "-1");
            })
        }

        if (this.state.confirmSuccess) {
            setTimeout(() => {
                const modals = document.querySelectorAll('.ant-modal-wrap');
                const header = document.querySelector('.ant-modal-wrap .confirm-visible.success-box .ant-modal-header')
                if (modals) {
                    modals.forEach(modal => {
                        modal.setAttribute('aria-labelledby', 'deactivateCardSuccessMsg');
                    })
                }
                // This change was commented by Sajid with MR:705. Uncommenting it back
                header.style.display = " none";
            },100)
        }
    }

    /*
     *To get the card details of a perticular card
     */
    viewCardList(isMasked, otp) {
        // isMasked === false && this.setState({ showSpin: true })
        let val = this.props.cardDetails
        let cardRef = this.props.selectedRow.cardRef

        let url = this.props.view && this.props.view === "AllActiveCards" ? `/activeCardView/V1/?cardReferenceNumber=${cardRef}&isMasked=${isMasked}&OTP=${otp}`
        :`/viewCardList/V1/?cardReferenceNumber=${cardRef}&isMasked=${isMasked}&OTP=${otp}`

        axios.get(URLs.cardUrl + url, {
            responseType: 'json',
            headers: {
                "Authorization": 'Bearer ' + this.props.token.access_token
            }
        }).then(response => {
            if(isMasked === false) 
                this.setState({ unMasked: true, otpCom: false, wrongOTP: false })
            else{
                this.setState({unMasked: false});
            }

            
            let date = new Date(dateFormat(response.data.creditcarddata[0].cardCreatedDate, "mm/dd/yyyy"));
            let futureDate = dateFormat(date.setDate(date.getDate() + 364), "mm/dd/yyyy")
            console.log("Future Date::", futureDate);
        // this.setState({ futureDate });
            
            this.setState({
                viewCardDetails: response.data.creditcarddata[0],
                errorViewCard: false,
                previousCard: val.CARD_REF,
                previousUser: val.USER_ID,
                showSpin: false,
                futureDate: futureDate,
            })
        })
            .catch(error => {
                error.response.data.serviceStatus.type && error.response.data.serviceStatus.type === "otp_error" && this.setState({ wrongOTP: true, otp1: '', otp2: '', otp3: '', otp4: '', otp5: '', otp6: '' })
                this.setState({
                    errorViewCard: true,
                    previousCard: val.CARD_REF,
                    previousUser: val.USER_ID,
                    showSpin: false,
                    unMasked: false
                })
                /* XW-61 issue - invalid OTP attempts for unlimited times
                    now redirecting to OTP screen on third wrong attempt of otp */
                error.response.data.serviceStatus.type && error.response.data.serviceStatus.type === "authorization_error" && this.setState({ wrongOTP: true, otpCom: false, errorViewCard: false, showOtp: true, thirdWrongOtp: true })
            })
    }

    /*
     *To deactivate a perticular card
     */
    deactiveCard = (cardRef) => {

        let url = this.props.view && this.props.view === "AllActiveCards" ? `/closeActiveCard/${cardRef}`: `/closeCard/${cardRef}`;
        axios.get(URLs.cardUrl + url, {
            responseType: 'json',
            headers: {
                "Authorization": 'Bearer ' + this.props.token.access_token
            }
        }).then(response => {
            this.setState({ confirmVisible: false, confirmError: false, confirmSuccess: true })
            this.props.updateDetails()
        }
        )
            .catch(error => this.setState({ confirmVisible: false, confirmError: true, confirmSuccess: false }))
    }

    /*
     *To assign the created card to cardUser
     */
    sendCard = (cardAssignmentId) => {
        axios.put(URLs.cardUrl + `/assign/${cardAssignmentId}`, null, {
            responseType: 'json',
            headers: {
                "Authorization": 'Bearer ' + this.props.token.access_token
            }
        }).then(response => notification.success({
            message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Success </span>{this.props.label_keys.assignCardSuccessMsg}</span>,
            duration: 3,
            style: {
                backgroundColor: '#dff0d8',
                color: "green",
                border: "1px solid green",
            }
        })
        )
            .catch(error => notification.error({
                message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{this.props.label_keys.couldNotcompleteReq}</span>,
                duration: 3,
                style: {
                    backgroundColor: '#fff1f0',
                    border: "1px solid #ffa39e",
                }
            })
            )
    }

    /*
     *To close the view card screen
     */
    onClose = () => {
        this.setState({ otpCom: false, showOtp: false, thirdWrongOtp: false })
    }

    /*
     *To generate otp in order to view the card details
     */
    sendOTP = () => {
        /* XW-61 issue - invalid OTP attempts for unlimited times 
            added two more state variable to store sth and 6th otp digits*/
        this.setState({ otpCom: true, wrongOTP: false, otp1: '', otp2: '', otp3: '',
         otp4: '', otp5: '', otp6: '', activeResend: false, thirdWrongOtp: false })
        let obj = { username: this.props.userDetails.email, OTP_PREFERENCE: 'email' }
        axios.post(URLs.mfaUrl + 'sendOTP', qs.stringify(obj), {
            headers: {
                "Authorization": "Basic Q2FkcmUtV2ViOmNhZHJl",
            }
        }).then(res => setTimeout(() => this.setState({ activeResend: true }), 30000))
            .catch(err => notification.error({
                message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{this.props.label_keys.sendOtpErrorMsg}</span>,
                duration: 3,
                style: {
                    backgroundColor: '#fff1f0',
                    border: "1px solid #ffa39e",
                }
            })
            )
    }

    /*
     *To verify the otp and get the card details
     */
    submitOTP = () => {
        /* XW-61 issue - invalid OTP attempts for unlimited times 
            added two more state variable to store sth and 6th otp digits*/
        let otp = this.state.otp1 + this.state.otp2 + this.state.otp3 + this.state.otp4 + this.state.otp5 + this.state.otp6
        this.setState({ showSpin: true }, () => this.viewCardList(false, otp))
    }

    detailBtnClick = () => {
        this.setState({ trxButtonSelected: false })
    }

    trxBtnClick = () => {
        this.setState({ trxButtonSelected: true })
    }

    trxApiMethod = () => {
        let cardRef = this.props.selectedRow.cardRef
        axios.get(URLs.trxUrl + `/getAllTransactions?cardRef=${cardRef}`, {
            responseType: 'json',
            headers: {
                "Authorization": 'Bearer ' + this.props.token.access_token
            }
        })
            .then(response => {
                this.setState({
                    trxData: response.data.transactionSummary,
                    TotalPage: (Math.ceil(response.data.transactionSummary.length / 8)),
                })
            })
            .catch(error => {
                notification.error({
                    message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{this.props.label_keys.couldNotcompleteReq}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                })
            })
    }

    availableCreditApiMethod = () => {
        // console.log("Selected row::", this.props.selectedRow);
        let cardRef = this.props.selectedRow.cardRef
        axios.get(URLs.cardUrl + `/getAvailableCreditDetail?cardRef=${cardRef}`, {
            responseType: 'json',
            headers: {
                "Authorization": 'Bearer ' + this.props.token.access_token
            }
        })
            .then(response => {
                this.setState({
                    availableBalance: response.data.data
                })
            })
            .catch(error => {
                notification.error({
                    message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{this.props.label_keys.couldNotcompleteReq}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                })
            })
    }

    itemRender = (current, type, originalElement) => {
        if (type === 'prev') {
            return <>
                <button className="trx-pagination-button">
                    <Icon style={{ color: '#19398A' }} aria-label="previous page" type="left" />
                </button>
                <button className="trx-pagination-button" aria-current="page"> {this.state.current} {this.props.label_keys.of.toUpperCase()} {this.state.TotalPage}
                </button>
                </>
        }
        if (type === 'next') {
            return <button className="trx-pagination-button"><Icon style={{ color: '#19398A' }} aria-label="next page" type="right" /></button>;
        }
        return originalElement;
    }

    pageChanger = (page, defaultPageSize) => {
        let TotalPage = Math.ceil(this.state.trxData.length / defaultPageSize)
        this.setState({ current: page, TotalPage: TotalPage })
    }

    handleChange = (pagination, filters, sorter) => {
        this.setState({
            sortedInfo: sorter,
            sortOrderText: `Table sorted by ${sorter.columnKey}, in ${sorter.order}ing order`,
        });
    }

    // Accessbility To make default focus on element.
    defaultFocus = () => {      
        if (this.state.confirmSuccess || this.state.confirmVisible) {
            // console.log("Document :::", ReactDOM.findDOMNode(this.refs.successDeactivateItem))
            // setTimeout(() =>{

            //     if (ReactDOM.findDOMNode(this.refs.successDeactivateItem))
            //     ReactDOM.findDOMNode(this.refs.successDeactivateItem).focus();
            // }, 100);
            // document.getElementById("successDeactivateItem").focus();
        }
    }

    changeTab = (event, value) => {
        //maxValue= this.getMaxCardLimitValue();
        this.setState({
            activeTab: value
        });

        if(value === "1"){
            this.detailBtnClick()
        }else{
            this.trxBtnClick()
        }


    };

    onViewCardDetailsSA =  () => {
        this.setState({ showSpin: true }, () => this.viewCardList(false, null));
    }

    getDate() {
        let date = new Date(this.state.validFrom)
        date.setDate(date.getDate() + 2)
        return date
    }

    disableDate(current, type) {
        let startDate;
        
        if(type === "ValidTo"){
            let fromDate = moment(dateFormat(this.getDate(), "mm/dd/yyyy"), 'MM/DD/YYYYY');
            let todayDate = moment(dateFormat(new Date(), "mm/dd/yyyy"), 'MM/DD/YYYYY');
            let days = fromDate.diff(todayDate, 'days');
            if(days > 0)
                startDate = new Date(moment(this.getDate()).tz('America/Chicago').format('MM/DD/YYYY'))
            else
                startDate = new Date(moment(new Date()).tz('America/Chicago').format('MM/DD/YYYY'))
        }
        else
            startDate = new Date(moment(new Date()).tz('America/Chicago').format('MM/DD/YYYY'))

        // console.log("current date::", current,"future date", futureDate, current < moment(startDate).startOf('day') || current > moment(futureDate).startOf('day'))
        if (startDate) return (current < moment(startDate).startOf('day') || current > moment(this.state.futureDate).startOf('day'))
        else return false
    }


    checkValidToRule = (validFrom) => {
        let fromDate = moment(dateFormat(validFrom, "mm/dd/yyyy"), 'MM/DD/YYYYY');
        let toDate = moment(dateFormat(this.state.validTo, "mm/dd/yyyy"), 'MM/DD/YYYYY');
        let days = toDate.diff(fromDate, 'days');
        return days > 0 && days <= 365 ? true : false;;
    }

    checkCardValidityDates = (dateValue) => {
        let today = new Date(moment(new Date()).tz('America/Chicago').format('MM/DD/YYYY'));
        let date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    
        let dateChk = moment(dateValue);
        let toDateTill = moment(date);
        let tillDays = toDateTill.diff(dateChk, 'days');
        let isValidTillDate = tillDays > 0 ? false : true
    
        let cardCreatedDateChk = moment(this.state.viewCardDetails.cardCreatedDate);
        let bothDaysDiff = dateChk.diff(cardCreatedDateChk, 'days');
        let isBothValid = bothDaysDiff >= 0 && bothDaysDiff < 365 ? true : false;
        // if (isValidFromDate && isValidTillDate && isBothValid)
        // console.log("checkCardValidityDates:::",isValidTillDate , isBothValid, bothDaysDiff)
        if (isValidTillDate && isBothValid)
          return true;
        else
          return false;
      }

    onSaveCardDetails = () =>{

        try{
            if(this.state.datepickerOpen){
                this.setState({datepickerOpen : false, open : false})
            }
            else{
                this.setState({isloading: true});
                loaderFocus(".loader-color .ant-spin-text"); 
                let validFrom = this.state.validFrom;
                let validTo = this.state.validTo;
                let error = {creditLimit: false, validFrom: false, validTo: false};
                let creditLimit = this.state.creditLimit;
                console.log(this.state.creditLimit, this.state.validFrom, this.state.validTo);

                if((this.state.validFromEdit && dateFormat( validFrom, 'mm/dd/yyyy') !== dateFormat(this.props.selectedRow.validFrom, 'mm/dd/yyyy'))){
                    if(!this.checkCardValidityDates(validFrom)){
                        error.validFrom = true;
                    }
                }
                 // This change was removed by Sajid with MR:705
                if(!this.checkValidToRule(validFrom))
                error.validTo = true;

                if((this.state.validToEdit &&  (dateFormat( validTo, 'mm/dd/yyyy') !== (this.state.selectedRow.validTo ? dateFormat(this.state.selectedRow.validTo, 'mm/dd/yyyy') : dateFormat(this.state.selectedRow.validTill, 'mm/dd/yyyy'))))){
                    if(!this.checkCardValidityDates(validTo))
                        error.validTo = true;
                }
                
                let numberReg = /^[1-9]\d*$/;
                if(this.state.creditLimitEdit && (creditLimit === null || numberReg.test(creditLimit) === false || creditLimit < 1)){
                    error.creditLimit = true;
                }

                this.setState({ error })

                if(error && !error.creditLimit && !error.validFrom && !error.validTo){
                    let cardDetailObjList = [];
                    let cardDetailsObj = {
                        startDate: this.state.validFromEdit && (dateFormat( validFrom, 'mm/dd/yyyy') !== dateFormat(this.props.selectedRow.validFrom, 'mm/dd/yyyy')) ? dateFormat(validFrom, 'mm/dd/yyyy') : null,
                        endDate: this.state.validToEdit && (dateFormat( validTo, 'mm/dd/yyyy') !== (this.state.selectedRow.validTo ? dateFormat(this.state.selectedRow.validTo, 'mm/dd/yyyy') : dateFormat(this.state.selectedRow.validTill, 'mm/dd/yyyy')))? dateFormat(validTo, 'mm/dd/yyyy'): null,
                        cardRef: this.state.selectedRow.cardRef,
                        budgetAmount: this.state.creditLimitEdit ? creditLimit: null,
                        osn: this.props.selectedRow.osn 
                    }
                    cardDetailObjList.push(cardDetailsObj)
                    let cardDetailsList = {cardDetails: cardDetailObjList}
                    
                    axios.post(URLs.cardUrl + '/updateCardData', cardDetailsList, {
                        responseType: 'json',
                        headers: {
                            "Authorization": 'Bearer ' + this.props.token.access_token
                        }
                    }).then(result => {
                        if (result.data.serviceStatus.statusCode === 200 && result.data.requestNo && result.data.requestNo.cardStatus && result.data.requestNo.cardStatus.length > 0 && result.data.requestNo.cardStatus[0].status === "Success") {
                            // this.setState({ reqId: result.data.cardData_id })
                            // this.sentForApproval()
                            // console.log("Result data::", result);
                            
                            this.props.updateDetails();
                            setTimeout(() => {
                                this.setState({ 
                                    editEnable: false, 
                                    validFromEdit: false, 
                                    validToEdit: false,
                                    creditLimitEdit: false
                                });
                                // this.props.updateSuccess();
                            },500);
                        }
                        else {

                            notification.error({
                                message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>
                                    {this.props.label_keys.couldNotcompleteReq}</span>,
                                duration: 3,
                                style: {
                                    backgroundColor: '#fff1f0',
                                    border: "1px solid #ffa39e",
                                }
                            });
                            this.setState({
                                error: null, 
                                isloading: false,
                                editEnable: false, 
                                validFromEdit: false, 
                                validToEdit: false,
                                creditLimitEdit: false,
                                creditLimit: this.props.selectedRow && this.props.selectedRow.creditLimit,
                                validFrom: this.props.selectedRow && dateFormat(this.props.selectedRow.validFrom, 'mm/dd/yyyy'),
                                validTo:  this.props.selectedRow && (this.props.selectedRow.validTo ? dateFormat( this.props.selectedRow.validTo, 'mm/dd/yyyy') : dateFormat( this.props.selectedRow.validTill, 'mm/dd/yyyy')),
                            })
                            setTimeout(() =>{
                                // console.log("Ocon:::", document.getElementById('edit-btn-icon'))
                                document.getElementById('edit-btn-icon').focus()
                            },100);
                        }
                    }).catch((error) => {
                        
                        this.setState({
                            error: null,
                            isloading: false,
                            editEnable: false, 
                            validFromEdit: false, 
                            validToEdit: false,
                            creditLimitEdit: false,
                            creditLimit: this.props.selectedRow && this.props.selectedRow.creditLimit,
                            validFrom: this.props.selectedRow && dateFormat(this.props.selectedRow.validFrom, 'mm/dd/yyyy'),
                            validTo:  this.props.selectedRow && (this.props.selectedRow.validTo ? dateFormat( this.props.selectedRow.validTo, 'mm/dd/yyyy') : dateFormat( this.props.selectedRow.validTill, 'mm/dd/yyyy')),
                        })
                        
                        setTimeout(() =>{
                            // console.log("Ocon:::", document.getElementById('edit-btn-icon'))
                            document.getElementById('edit-btn-icon').focus()
                        },100);
                        if (error.response !== undefined && error.response.data !== null && error.response.data !== undefined && error.response.data.serviceStatus !== undefined) {

                            notification.error({
                                message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>
                                    {this.props.label_keys.couldNotcompleteReq}</span>,
                                duration: 3,
                                style: {
                                    backgroundColor: '#fff1f0',
                                    border: "1px solid #ffa39e",
                                }
                            });
                        }
                        else {
                            notification.error({
                                message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>
                                    {this.props.label_keys.couldNotcompleteReq}</span>,
                                duration: 3,
                                style: {
                                    backgroundColor: '#fff1f0',
                                    border: "1px solid #ffa39e",
                                }
                            });
                        }
                    })

                }else{
                    // console.log("Error::::::", error, this.state.error);
                    this.setState({isloading: false});
                    
                    if(error.creditLimit){
                        setTimeout(() => {document.getElementById('credit-limit-input-field').focus()}, 100);
                    }
                    else if(error.validFrom)
                        setTimeout(() => {document.getElementById('valid-from-field-input').focus()}, 100);
                    else if(error.validTo)
                        setTimeout(() => {document.getElementById('valid-to-field-input').focus()}, 100);
                    
                }
                
            }
        }catch(e){
            this.setState({
                error: null,
                isloading: false,
                editEnable: false, 
                validFromEdit: false, 
                validToEdit: false,
                creditLimitEdit: false,
                creditLimit: this.props.selectedRow.creditLimit,
                validFrom: dateFormat(this.props.selectedRow.validFrom, 'mm/dd/yyyy'),
                validTo:  this.props.selectedRow.validTo ? dateFormat( this.props.selectedRow.validTo, 'mm/dd/yyyy') : dateFormat( this.props.selectedRow.validTill, 'mm/dd/yyyy'),
            })
            notification.error({
                message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>
                    {this.props.label_keys.couldNotcompleteReq}</span>,
                duration: 3,
                style: {
                    backgroundColor: '#fff1f0',
                    border: "1px solid #ffa39e",
                }
            });
        }
    }

    handleCardDisplay(value) {
        if (!value) {
            loaderFocus();
            return false;
        } else {
            return true;
        }
    }

    render() {
        let { sortedInfo } = this.state;
        sortedInfo = sortedInfo || {};

        const columns = [
            {
                title: prepareTitle('0', <span role="button" >{this.props.label_keys.date}</span>),
                dataIndex: 'transactionDate',
                key: 'transactionDate',
                width: '25%',
                align: 'left',
                sorter: (a, b) => new Date(a.transactionDate) - new Date(b.transactionDate),
                sortOrder: sortedInfo.columnKey === 'transactionDate' && sortedInfo.order,
                render: text => { 
                    return <div style={{color: "#666666"}}>{text}</div>
                }
            },
            {
                title: prepareTitle('0', <span role="button">{this.props.label_keys.merchantName}</span>),
                dataIndex: 'merchantName',
                key: 'merchantName',
                width: '35%',
                align: 'left',
                sorter: (a, b) => {
                    let merchantName = a.merchantName.toLowerCase();
                    let merchantName2 = b.merchantName.toLowerCase();
                    return merchantName < merchantName2 ? -1 : 1
                },
                sortOrder: sortedInfo.columnKey === 'merchantName' && sortedInfo.order,
            },
            {
                title: prepareTitle('0', <span role="button">{this.props.label_keys.status}</span>),
                dataIndex: 'transactionStatus',
                key: 'transactionStatus',
                width: '35%',
                align: 'center',
                sorter: (a, b) => {
                    let transactionStatus = a.transactionStatus.toLowerCase();
                    let transactionStatus2 = b.transactionStatus.toLowerCase();
                    return transactionStatus < transactionStatus2 ? -1 : 1
                },
                sortOrder: sortedInfo.columnKey === 'transactionStatus' && sortedInfo.order,
                render: text => {
                    if (text === "Pending") return <div className="pending-trans">{this.props.label_keys.pending}</div>
                    else return <div className="posted-trans">{this.props.label_keys.posted}</div>
                }
            },
            {
                title: prepareTitle('0', <span role="button">{this.props.label_keys.transactionAmount}</span>),
                dataIndex: 'transactionAmount',
                key: 'transactionAmount',
                width: '40%',
                align: 'right',
                sorter: (a, b) => {
                    let aValue = a.transactionAmount.replace(/[+$, ]/g, '')
                    let bValue = b.transactionAmount.replace(/[+$, ]/g, '')
                    let txn = Number(aValue[0] + aValue.slice(3))
                    let txn2 = Number(bValue[0] + bValue.slice(3))
                    return txn - txn2
                },
                sortOrder: sortedInfo.columnKey === 'transactionAmount' && sortedInfo.order,
                render: text => {
                    if (text[0] === "+") return <div style={{ color: "#19398A", fontWeight: "bold" }}>{text}</div>
                    else return <div style={{ fontWeight: "bold", color: '#333333' }}>{text}</div>
                }
            }
        ];
        let details = this.props.cardDetails || null
        return (
            details && <Drawer
                role="dialog"
                aria-modal="true"
                aria-labelledby="card-details"
                width={700}
                closable={false}
                maskClosable={false}
                onClose={this.props.onCLoseDetails}
                visible={this.props.detailsVisible}
                keyboard={this.state.datepickerOpen ? false : true} >

                <div className="create-head">
                    <span id="card-details" role='heading' aria-level="2">{this.state.selectedRow.name}</span>
                    <Icon type="close-circle" role="button" id="closeBtn" aria-label="Close" tabIndex={0} 
                    onKeyDown={e => {
                        if (e.keyCode === 13) {
                            this.state.otpCom === false ?
                                this.props.onCLoseDetails(false) : this.onClose()
                        }
                        if(e.key === "Tab" && e.shiftKey){
                            setTimeout(() =>{
                                document.getElementById("deactivateBtn").focus();
                            })
                        }
                    }} onClick={() => this.state.otpCom === false ?
                        this.props.onCLoseDetails(false) : this.onClose()} />
                    <div></div>
                </div>
                {this.state.isloading ? <div className="dark-spinner loader-color"><Spin tip={this.props.label_keys.loading} /></div> : null}

                {this.state.otpCom === false ? <div>
                    {this.state.errorViewCard === false ? <>
                        {this.handleCardDisplay(this.state.viewCardDetails && this.state.showSpin === false) ?
                            <div role={this.state.unMasked ? "presentation" :
                            (this.state.isFocused ? "button" : null)} tabindex={this.state.unMasked ? -1 :0} 
                            aria-label={this.state.unMasked ? null : 
                                (this.state.showOtp ? this.state.viewCardDetails.cardType === 'mastercard' ? 'master card': 'visa card'
                                    :`${this.state.viewCardDetails.cardType === 'mastercard' ? 'master card': 'visa card'} 
                             ending with ${this.state.viewCardDetails.cardNo.substring(this.state.viewCardDetails.cardNo.length - 4, this.state.viewCardDetails.cardNo.length)}  expire date  
                            ${this.state.viewCardDetails.cardExpiryDate.substring(0, 2)}/${this.state.viewCardDetails.cardExpiryDate.substring(8, 10)}
                            CVV masked`)}
                             className={`card-details preview-card allActive-card-preview detail-card ${this.state.viewCardDetails.cardType === 'mastercard' ? 'card-img-master' : 'card-img'} ${this.state.showOtp === true ? 'card-blur' : ''} ${this.props.selectedRow && this.props.selectedRow.serviceInterface && this.props.selectedRow.serviceInterface.includes("Elan EasyPay") && this.state.showOtp === false ? 'elan': ''}`}
                             onKeyUp={(e) =>{
                                if(e.keyCode === 13){
                                    if(this.state.unMasked === false)
                                        this.setState({ showOtp: !this.state.showOtp, thirdWrongOtp: false })
                                    else
                                        this.setState({ showSpin: true }, () => this.viewCardList(true, null));
                                }
                            }} 
                            
                                onClick={() => {
                                    if(this.state.unMasked === false)
                                        this.setState({ showOtp: !this.state.showOtp, thirdWrongOtp: false })
                                    else
                                        this.setState({ showSpin: true }, () => this.viewCardList(true, null));
                                }} >
                                {this.state.showOtp === false ? <div aria-hidden={this.state.unMasked === true ? false : true}>
                                    <div  className="card-head">
                                        <span style={{ paddingRight: '29%' }}></span>
                                    </div>
                                    <div  className="card-num" style={{marginTop:this.props.selectedLang === "French" ? '20%' : '25%'}} >
                                        <span  style={{letterSpacing: this.state.unMasked === true ? 2.2: null}}>{this.state.unMasked === true ? <span><span className="visually-hidden">{this.state.viewCardDetails.cardType === 'mastercard' ? 'master card number ': 'visa card number '}</span>
                                            {`${this.state.viewCardDetails.cardNo.substring(0, 4)}  ${this.state.viewCardDetails.cardNo.substring(4, 8)} ${this.state.viewCardDetails.cardNo.substring(8, 12)} ${this.state.viewCardDetails.cardNo.substring(12, 16)}`}</span>
                                            : 
                                            <span style={{letterSpacing: 2.2}}>{this.state.viewCardDetails.cardNo}</span>}</span>
                                    </div>
                                    <Row className="card-footer">
                                        <Col span={9}>
                                            <span >{this.props.label_keys.expiration}</span><br />
                                            <span>{this.state.viewCardDetails.cardExpiryDate.substring(0, 2)}/{this.state.viewCardDetails.cardExpiryDate.substring(8, 10)}</span>

                                        </Col>
                                        <Col offset={2} span={6} className="card-down-footer">
                                            <span>{this.props.label_keys.cvv}</span><br />
                                            <span style={{letterSpacing: 2.2}}>{this.state.viewCardDetails.cvv}</span>
                                        </Col>
                                    </Row>
                                </div> : <div className="blur-card" 
                                 style={this.state.thirdWrongOtp ? { padding: '8%' } : null}>
                                    {this.state.thirdWrongOtp ? <span style={{ color: 'red' }}>
                                        <Icon aria-label='Error' type="warning" /> &nbsp;<b>
                                            {this.props.label_keys.regenerateOtp}</b><br /><br /></span> : null}
                                    <span style={{display: this.props.roles === "Super Admin" || this.props.roles === 'Customer Support' ? "none" : null}} ><b>{this.props.label_keys.cardDetailsMsg}</b></span>
                                    <div >
                                        {this.props.roles === "Super Admin" || this.props.roles === 'Customer Support' ?
                                            <Button tabindex={0} ghost className={`send-otp-btn ${this.props.selectedRow && this.props.selectedRow.serviceInterface && this.props.selectedRow.serviceInterface.includes("Elan EasyPay") ? 'elan': ''}`}
                                            onFocus={() => this.setState({isFocused: false})}
                                            onBlur={() => this.setState({isFocused: true})}
                                            onKeyUp={(e) =>{
                                                // console.log("Send otp event::::", e);
                                                if(e.keyCode === 13 || e.key === "Enter"){
                                                    // console.log("Send otp::::", e);       
                                                    this.onViewCardDetailsSA()
                                                }
                                        }} onClick={this.onViewCardDetailsSA}>View Card Details</Button>
                                    :
                                        <Button tabindex={0} ghost className='send-otp-btn'
                                            onFocus={() => this.setState({isFocused: false})}
                                            onBlur={() => this.setState({isFocused: true})}
                                            onKeyUp={(e) =>{
                                                console.log("Send otp event::::", e);
                                                if(e.keyCode === 13 || e.key === "Enter"){
                                                    console.log("Send otp::::", e);       
                                                    this.sendOTP()
                                                }
                                        }} onClick={this.sendOTP}>{this.props.label_keys.sendOtp}</Button>}
                                    </div>
                                </div>}
                            </div> :<Spin className="preview-spin" size="large" />}
                    </> : <div tabindex={0} className={`card-details preview-card detail-card ${this.state.viewCardDetails && this.state.viewCardDetails.cardType === 'mastercard' ? 'card-img-master' : 'card-img'} ${this.props.selectedRow && this.props.selectedRow.serviceInterface && this.props.selectedRow.serviceInterface.includes("Elan EasyPay") ? 'elan': ''}`}>
                    <p className="card-loading-error"><Icon type="warning" aria-hidden="true" aria-label={null} tabindex={-1} /> &nbsp;{this.props.label_keys.cardLoadingErrorMsg}</p>
                        <Button tabindex={0} className={`retry-card ${this.props.theme === 'elan'? 'elan-retry': ''}`} ghost 
                         onKeyUp={(e) =>{
                            if(e.keyCode === 13)
                                this.viewCardList(true, null)
                        }} onClick={() => this.viewCardList(true, null)}>{this.props.label_keys.retry}</Button>
                    </div>}
                    {(this.state.viewCardDetails && this.state.showSpin === false) && <span className="preview-card-bottom">
                        {this.state.showOtp === false ?( (this.props.roles === 'Super Admin' || this.props.roles === 'Customer Support') && this.props.selectedRow && this.props.selectedRow.serviceInterface && this.props.selectedRow.serviceInterface.includes("Elan EasyPay") ? 'Tap on the card to view Easy Pay Card details.': this.props.label_keys.viewUnmaskedCardMsg ): this.props.label_keys.viewMaskedCardMsg}
                    </span>}
                </div> : <> {this.state.showSpin === false ?
                    <div  className="preview-otp-body detail-card">
                        <div><span>{this.props.label_keys.sendOtpMsg}</span></div>
                        {this.state.wrongOTP === true ?
                            <div className="preview-otp-error-text"  role='alert'>
                                <Icon aria-hidden="true" aria-label={null} tabindex={-1} type="warning" />    
                                <span><span className="visually-hidden">Error </span>{this.props.label_keys.wrongOtpMsg}</span>
                            </div> : <div  className="preview-otp-error-empty"></div>}
                        <div><span  className={`verification-text ${this.state.wrongOTP === true && 'error-text'}`}><b>{this.props.label_keys.verificationCode}</b></span></div>
                        <div  className="otp-input">
                            <Input aria-label="OTP first digit"  aria-required="true" autocomplete="new-password" maxLength='1' type={this.state.show === false && 'password'} value={this.state.otp1} onChange={e => this.setState({ otp1: e.target.value })}
                                onKeyUp={e => {
                                    (e.keyCode !== 8 && e.keyCode !== 46 && e.keyCode !== 9 && e.keyCode !== 16) && document.getElementById("otp2").focus()
                                }}
                                id="otp1" className={this.state.wrongOTP === true ? "error-border" : (this.state.otp1 !== "" && "blue-border")} />
                            <Input aria-label="OTP second digit" aria-required="true" autocomplete="new-password" maxLength='1' type={this.state.show === false && 'password'} value={this.state.otp2} onChange={e => this.setState({ otp2: e.target.value })}
                                onKeyUp={e => {
                                    (e.keyCode !== 8 && e.keyCode !== 46 && e.keyCode !== 9 && e.keyCode !== 16) && document.getElementById("otp3").focus()
                                    if (e.keyCode === 8) document.getElementById("otp1").focus()
                                }}
                                id="otp2" className={this.state.wrongOTP === true ? "error-border" : (this.state.otp2 !== "" && "blue-border")} />
                            <Input aria-label="OTP third digit" aria-required="true" autocomplete="new-password" maxLength='1' type={this.state.show === false && 'password'} value={this.state.otp3} onChange={e => this.setState({ otp3: e.target.value })}
                                onKeyUp={e => {
                                    (e.keyCode !== 8 && e.keyCode !== 46 && e.keyCode !== 9 && e.keyCode !== 16) && document.getElementById("otp4").focus()
                                    if (e.keyCode === 8) document.getElementById("otp2").focus()
                                }}
                                id="otp3" className={this.state.wrongOTP === true ? "error-border" : (this.state.otp3 !== "" && "blue-border")} />
                            <Input aria-label="OTP fourth digit" aria-required="true" autocomplete="new-password" maxLength='1' type={this.state.show === false && 'password'} value={this.state.otp4} onChange={e => this.setState({ otp4: e.target.value })}
                                onKeyUp={e => {
                                    (e.keyCode !== 8 && e.keyCode !== 46 && e.keyCode !== 9 && e.keyCode !== 16) && document.getElementById("otp5").focus()
                                    if (e.keyCode === 8) document.getElementById("otp3").focus()
                                }}
                                id="otp4" className={this.state.wrongOTP === true ? "error-border" : (this.state.otp4 !== "" && "blue-border")} />
                            {/* XW-61 issue - invalid OTP attempts for unlimited times 
                                    added two more input fields for user to enter 5th and 6th otp digits*/}
                            <Input aria-label="OTP fifth digit" aria-required="true" autocomplete="new-password" maxLength='1' type={this.state.show === false && 'password'} value={this.state.otp5} onChange={e => this.setState({ otp5: e.target.value })}
                                onKeyUp={e => {
                                    (e.keyCode !== 8 && e.keyCode !== 46 && e.keyCode !== 9 && e.keyCode !== 16) && document.getElementById("otp6").focus()
                                    if (e.keyCode === 8) document.getElementById("otp4").focus()
                                }}
                                id="otp5" className={this.state.wrongOTP === true ? "error-border" : (this.state.otp5 !== "" && "blue-border")} />
                            <Input aria-label="OTP sixth digit" aria-required="true" autocomplete="new-password" maxLength='1' type={this.state.show === false && 'password'} value={this.state.otp6} onChange={e => this.setState({ otp6: e.target.value })}
                                onKeyUp={e => {
                                    (e.keyCode !== 8 && e.keyCode !== 46 && e.keyCode !== 9 && e.keyCode !== 16) && document.getElementById("otp6").blur()
                                    if (e.keyCode === 8) document.getElementById("otp5").focus()
                                }}
                                id="otp6" className={this.state.wrongOTP === true ? "error-border" : (this.state.otp6 !== "" && "blue-border")} />
                        </div>
                        {(this.state.otp1 !== '' || this.state.otp2 !== '' || this.state.otp3 !== '' || this.state.otp4 !== '' || this.state.otp5 !== '' || this.state.otp6 !== '') &&
                            <p tabindex={0} className="show" style={{color: "#666666"}}
                            role="button" aria-label={this.state.show === true ? this.props.label_keys.hide : this.props.label_keys.show}
                                onKeyUp={e => {
                                if (e.keyCode === 13) {
                                    this.setState({ show: !this.state.show })
                                }
                                }}
                                onClick={() => this.setState({ show: !this.state.show })}>
                                {this.state.show === true ? this.props.label_keys.hide : this.props.label_keys.show}
                            </p>}
                        <br /><br />
                        <div className={`resend-otp ${!this.state.activeResend ? 'resend-padding' : ''}`}>
                            {!this.state.activeResend && <Icon type="loading" className="count-down" />}
                            {!this.state.activeResend && <Counter />}
                             <Button className="resend-otp-button"
                                disabled={!this.state.activeResend}
                                onKeyUp={(e) =>{
                                    if(e.keyCode === 13)
                                        this.sendOTP()
                                }}
                                onClick={this.sendOTP}>{this.props.label_keys.resendCode}</Button>
                            <Button className="otp-button" type="primary"
                                disabled={(this.state.otp1 === '' || this.state.otp2 === '' || this.state.otp3 === '' || this.state.otp4 === '' || this.state.otp5 === '' || this.state.otp6 === '') ? true : false}
                                onKeyUp={(e) =>{
                                    if(e.keyCode === 13)
                                        this.submitOTP()
                                }}
                                onClick={() => this.submitOTP()}>
                                {this.props.label_keys.submit}
                            </Button>
                        </div>
                    </div> : <Spin className="preview-spin" size="large" />}</>}
                <div className="tab-div" style={{ marginLeft: '28%' }}>
                    <Tabs className="activeCards-tabs" value={this.state.activeTab} onChange={this.changeTab}>
                        <Tab disableRipple id="cardDetailTab" aria-controls="cardDetail_tab_panel" value='1'
                        //  onClick={this.detailBtnClick} 
                            style={{ marginRight: !this.state.trxButtonSelected ? '-2%' : null,zIndex : !this.state.trxButtonSelected ?2 : null}}
                          className={(this.props.selectedLang === 'French' ? `preview-button-fr` : `preview-button`)+ ` card-detail-button ${!this.state.trxButtonSelected && `preview-selected-button allActive-card-detail`}`} label={<div className='card-tabs-button'>{this.props.label_keys.cardDetails}</div>}/>
                        <Tab disableRipple id="transactionTab" aria-controls="transaction_tab_panel" value='2'
                        // onClick={this.trxBtnClick}
                         style={{ marginLeft: this.state.trxButtonSelected ? '-2%' : null,zIndex : this.state.trxButtonSelected ?2 : null}}
                         className={(this.props.selectedLang === 'French' ? `preview-button-fr` : `preview-button`)+` card-detail-button ${this.state.trxButtonSelected && `preview-selected-button allActive-card-detail-trans`}`} label={<div className='card-tabs-button'>{this.props.label_keys.transactions}</div>}/>
                    </Tabs>
                </div>
                {this.state.trxButtonSelected ?
                    <><br />
                    <div aria-relevant="text" 
                    aria-live={sortedInfo.order === "ascend" || sortedInfo.order === "descend" ?"polite" : "off"} 
                    aria-atomic="true" className="visually-hidden">{ this.state.sortOrderText}  </div>
               
                        <Row>
                            <Col span={6} className="transaction-head"><span role="heading" aria-level="2">{this.props.label_keys.transactions} ({this.state.trxData.length})</span></Col>
                            <Col span={7} offset={11} className="transaction-details">{this.props.label_keys.availableCredit} &nbsp;&nbsp; <span className="transaction-head">{this.state.availableBalance ?
                                <span>{this.state.availableBalance}</span> : <span><span className="visually-hidden">No Value</span><span className="dash-value" aria-hidden="true">——</span></span>}</span></Col>
                        </Row><br />
                        <Card role="tabpanel" id='transaction_tab_panel' aria-labelledby='transactionTab' value='2' className='container ant-card-pv trx-card tab-link' >
                            <Row>
                                <Col>
                                    <Table className='Req-table userlist trxTable'
                                        tabIndex={-1}
                                        key={0}
                                        columns={columns}
                                        dataSource={this.state.trxData}
                                        loading={false}
                                        locale={{ emptyText: this.props.label_keys.noTransactions }}
                                        scroll={{ x: 650 }}
                                        onChange={this.handleChange}
                                        pagination={{
                                            defaultPageSize: 8,
                                            itemRender: this.itemRender,
                                            position: "bottom",
                                            showTitle: false,
                                            showTotal: (total, range) => `${this.props.label_keys.showing} ${range[0]}-${range[1]} ${this.props.label_keys.of} ${total} `,
                                            onChange: this.pageChanger,
                                        }}
                                    />
                                </Col>
                            </Row>
                        </Card>
                    </> :
                    <><div role="tabpanel" id='cardDetail_tab_panel' value='1'  className="preview-details">
                        <div className="preview-head">
                            <span role="heading" aria-level="2"  aria-labelledby='cardDetailTab'>{this.props.label_keys.cardDetails}:</span>
                            {   //this.state.editHide
                                (this.props.type && this.props.type === "bulkCard") || (this.props.roles && (this.props.roles === 'Super Admin' || this.props.roles === 'Customer Support'))
                                ? null :
                                !this.state.editEnable &&
                                    <div style={{ textAlign: 'right', marginTop: this.props.theme === "elan" ? "-3.2%" :"-4%" }}/* className="col-sm-1 col-sm-offset-3 edit-icon-details" */>
                                        <span 
                                            onClick={(e) => {
                                                this.setState({ editEnable : true})
                                                // setTimeout(() => {
                                                //     // console.log("document.getElementById('credit-limit-input').focus()", document.getElementById('credit-limit-input').focus());
                                                //     document.getElementById('credit-limit-input-field').focus()}, 100);
                                            }}
                                            onKeyDown={(e) =>{
                                                if(e.keyCode === 13){
                                                    this.setState({ editEnable : true});
                                                    // setTimeout(() => {
                                                    //     // console.log("document.getElementById('credit-limit-input').focus()", document.getElementById('credit-limit-input').focus());
                                                    //     document.getElementById('credit-limit-input-field').focus()}, 100);
                                                }
                                            }}
                                        ><i tabIndex={0} role="button"  aria-label="Edit" className="icon-Edit" id="edit-btn-icon"
                                        style={{ color: "#19398A", fontSize: "18px", fontWeight: 600 }} /></span>

                                    </div>
                            }
                        </div>
                        
                        <Row className="preview-body" style={{marginTop: this.props.type && this.props.type === "bulkCard" ? null : 10 }}>
                            <Col span={12}>
                                <div className="card-title" >{this.props.label_keys.availableCredit}</div>
                                <div className="card-value" ><span style={{ color: "#19398A", fontWeight: "bold" }}>{this.state.availableBalance ?
                                    <span>{this.state.availableBalance}</span> : <span><span className="visually-hidden">No Value</span><span className="dash-value" aria-hidden="true">——</span></span>}</span></div>
                            </Col>
                            
                            <Col span={12} sm={{span: 6, offset: 2}} md={{span: 12, offset: 0}} lg={{span: 12, offset: 0}}>
                                <div className="card-title" >{this.props.label_keys.field1}</div>
                                <div className="card-value" >{this.state.selectedRow.field1 ?
                                    <span>{this.state.selectedRow.field1}</span> : <span><span className="visually-hidden">No Value</span><span className="dash-value" aria-hidden="true">——</span></span>}
                                </div>
                            </Col>
                        </Row>
                        <Row className={`preview-body ${this.state.editEnable? 'edit-enable': ''}`}>
                            
                            <Col span={12}>
                                <div className="card-title" >{this.props.label_keys.provisionedCredit}</div>
                                { this.state.editEnable ?
                                     <div className="card-value" >
                                        <div><span className="credit-limit-lable">$</span>
                                        <Input required ref={"provisionItem"} id='credit-limit-input-field' maxLength={7}
                                            aria-label={this.props.label_keys.provisionedCredit}
                                            className={`${(this.state.error && this.state.error.creditLimit === true ) ? 'credit-limit-field error-border' : ('credit-limit-field')}`}
                                            value={this.state.creditLimit}
                                            aria-describedby={this.state.error && this.state.error.creditLimit === true ? "credit-limit-error" : ""}
                                            onChange={e => {
                                                let error = {};
                                                if(this.state.error)
                                                    error = this.state.error;
                                                if(e.target.value.replace(/[^0-9]/g, '') === '' || e.target.value.replace(/[^0-9]/g, '') <= 0){
                                                    error["creditLimit"] = true
                                                }else{
                                                    if(error && error.creditLimit){
                                                        delete error['creditLimit'];
                                                    }
                                                }
                                                this.setState({ creditLimit: e.target.value.replace(/[^0-9]/g, ''), creditLimitEdit: ( e.target.value === this.props.selectedRow.creditLimit || (error && error.creditLimit === true) )? false: true, error: error })}} />
                                        </div>
                                        {this.state.error && this.state.error.creditLimit === true &&
                                            <div id="credit-limit-error" className="credit-limit-error"><span className="visually-hidden">Error:</span>
                                            <span  className="suggestion-text error-text">{this.props.label_keys.provisionedCreditLimitError}</span></div>}
                                    </div>
                                    
                                    :
                                    <div className="card-value" ><span>{this.props.selectedRow && this.props.selectedRow.creditLimit ?
                                    <span>${this.props.selectedRow.creditLimit}</span> : <span><span className="visually-hidden">No Value</span><span className="dash-value" aria-hidden="true">——</span></span>}</span></div>
                                }
                            </Col>
                            <Col span={12} sm={{span: 6, offset: 2}} md={{span: 12, offset: 0}} lg={{span: 12, offset: 0}}>
                                <div className="card-title" >{this.props.label_keys.field2}</div>
                                <div className="card-value" >{this.props.selectedRow && this.state.selectedRow.field2 ?
                                    <span>{this.state.selectedRow.field2}</span> : <span><span className="visually-hidden">No Value</span><span className="dash-value" aria-hidden="true">——</span></span>}
                                </div>
                            </Col>
                        </Row>
                        <Row className={`preview-body ${this.state.editEnable? 'edit-enable': ''}`}>
                            { this.state.editEnable ?
                                <Col span={12} style={{marginTop: '2%'}}>
                                    <Col span={6} style={{width: this.props.selectedLang === "French"? '30%': null}}>
                                        <div className="card-title" >{this.props.label_keys.validFrom}</div>
                                        <div className="card-value" >
                                            <Tooltip title={<div><span className="visually-hidden">{this.state.error && this.state.error.validFrom ? "Error ": null}</span>{this.props.label_keys.dateWithinCreationTooltip}</div>}
                                                placement='bottom' className="valid-from-tooltip"
                                                trigger={['hover', 'focus']} mouseEnterDelay={0} 
                                                id={`valid-from-tooltip`}
                                                onVisibleChange={() => {this.setState({tooltipVisible : !this.state.tooltipVisible})}}
                                                >
                                                <div>
                                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                        <DatePicker
                                                            className={`${(this.state.error && this.state.error.validFrom === true ) ? 'valid-from-field error-border' : ('valid-from-field')}`}
                                                            shouldDisableDate={(current) => this.disableDate(current)}
                                                            disablePast
                                                            disableHighlightToday
                                                            disableRipple
                                                            components={{
                                                                OpenPickerIcon: CalendarTodayOutlinedIcon,
                                                            }}
                                                            PopperProps={{
                                                                className: "datepicker-desktop"
                                                            }}
                                                            DialogProps={{
                                                                className: "datepicker-mobile"
                                                            }}
                                                            
                                                            OpenPickerButtonProps={{
                                                                "aria-describedby": this.state.tooltipVisible ? "valid-from-tooltip": null,
                                                            }}
                                                            getOpenDialogAriaText={(date, utils) => `Valid From Choose date, selected date is ${utils.format(utils.date(date), 'fullDate')}`}    
                                                            inputProps={{
                                                                tabindex: "-1",
                                                                readOnly: true,
                                                                "aria-describedby": this.state.tooltipVisible ? "valid-from-tooltip": null,
                                                                id: "valid-from-field-input",
                                                                "aria-label":"card valid from",
                                                                sx: {
                                                                    "&::placeholder": {
                                                                      color: "#666666",
                                                                      fontSize: "12px",
                                                                      opacity: 1.5,
                                                                    }
                                                                }
                                                            }}
                                                            InputProps={{ disableUnderline: true }}
                                                            value={this.state.validFrom}
                                                            onOpen={() => {
                                                                this.setState({datepickerOpen: true})
                                                                setTimeout(() => {
                                                                    const val = document.getElementsByClassName('MuiCalendarPicker-root');
                                                                    // console.log("MUI date picker:::", val);
                                                                    if(val.length > 0){
                                                                        
                                                                        // console.log("MUI date picker first child:::", val[0].firstChild.getAttribute('class'));
                                                                        const val2 = document.getElementsByClassName(val[0].firstChild.getAttribute('class'));
                                                                        // console.log("MUI date picker first child:::", val2);
                                                                        if(val2[0]){
                                                                            // console.log("MUI date picker first child's first child:::", val2[0].firstChild);
                                                                            val2[0].firstChild.setAttribute('aria-live', 'assertive');
                                                                            val2[0].firstChild.setAttribute('aria-atomic', 'true');
                                                                        }
                                                                    }
                                                                    const selected = document.getElementsByClassName('MuiPickersDay-root Mui-selected MuiPickersDay-dayWithMargin')
                                                                    if(selected.length > 0){
                                                                        selected[0].focus()
                                                                    }
                                                                        
                                                                }, 1000);
                                                            
                                                            }}
                                                            onClose={() => this.setState({datepickerOpen: false, open: false})}
                                                            onAccept={(e) => {let validValue; 
                                                                let error = {};
                                                                try{
                                                                    if(this.state.error)
                                                                        error = this.state.error;
                                                                    validValue = dateFormat(e, 'mm/dd/yyyy');
                                                                    if(!this.checkValidToRule(validValue)){
                                                                        error['validTo'] = true
                                                                    }else{
                                                                        if(error && error.validTo){
                                                                            delete error['validTo'];
                                                                        }
                                                                    }
                                                                }catch(e){
                                                                    validValue = null;
                                                                }
                                                                this.setState({ validFrom: validValue, validFromEdit:( validValue === null ||  e === null || (error && error.validTo === true)) ? false: true, error: error })
                                                            }}
                                                            onChange={(e) => {
                                                                console.log("Value change::", e);
                                                            }}
                                                            renderInput={(params) => <TextField {...params}  variant="standard"  
                                                            id="cardValidFrom" required
                                                            className={`${(this.state.error && this.state.error.validFrom === true ) ? 'valid-from-field error-border' : ('valid-from-field')}`}
                                                            />} />
                                                    </LocalizationProvider>
                                                </div>
                                            </Tooltip>
                                        </div>
                                    </Col>
                                    <Col className="valid-to-col" xs={{span: 6, offset: 16}} sm={{span: 6, offset: 8}} md={{span: 6, offset: 7}} lg={{span: 6, offset: 5}} style={{width: '45%'}}>
                                        <div className="card-title" >{this.props.label_keys.validTill}</div>
                                        <div className="card-value" >
                                            <Tooltip title={<div><span className="visually-hidden">{this.state.error && this.state.error.validTo ? "Error ": null}</span>{this.props.label_keys.dateWithinCreationTooltip}</div>} 
                                                placement={this.state.error && this.state.error.validTo === true? 'top' :'bottom'} className="valid-to-tooltip"
                                                trigger={['hover', 'focus']} mouseEnterDelay={0} id='valid-till-tooltip'
                                                onVisibleChange={() => {this.setState({tooltipVisible : !this.state.tooltipVisible})}}
                                                >
                                                    <div>
                                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                            <DatePicker
                                                                className={`${(this.state.error && this.state.error.validTo === true ) ? 'valid-to-field error-border' : ('valid-to-field')}`}
                                                                shouldDisableDate={(current) => this.disableDate(current, "ValidTo")}
                                                                // disablePast
                                                                disableHighlightToday
                                                                disableRipple
                                                                components={{
                                                                    OpenPickerIcon: CalendarTodayOutlinedIcon,
                                                                }}
                                                                PopperProps={{
                                                                    className: "datepicker-desktop"
                                                                }}
                                                                DialogProps={{
                                                                    className: "datepicker-mobile"
                                                                }}
                                                                OpenPickerButtonProps={{
                                                                    "aria-describedby": this.state.tooltipVisible ? `${this.state.error && this.state.error.validTo? "valid-to-error " : ''} valid-till-tooltip`: null,
                                                                }}
                                                                getOpenDialogAriaText={(date, utils) => `Valid To Choose date, selected date is ${utils.format(utils.date(date), 'fullDate')}`}
                                                                inputProps={{
                                                                    tabindex: "-1",
                                                                    readOnly: true,
                                                                    "aria-describedby": this.state.tooltipVisible ? `${this.state.error && this.state.error.validTo? "valid-to-error " : ''} valid-till-tooltip`: null,
                                                                    id: "valid-to-field-input",
                                                                    "aria-label":"card valid to",
                                                                    sx: {
                                                                        "&::placeholder": {
                                                                            color: "#666666",
                                                                            fontSize: "12px",
                                                                            opacity: 1.5,
                                                                        }
                                                                    }
                                                                }}    
                                                                InputProps={{ disableUnderline: true }}
                                                                value={this.state.validTo}
                                                                open={this.state.open}
                                                                onOpen={() => {
                                                                    this.setState({datepickerOpen: true, open : true})
                                                                    setTimeout(() => {
                                                                        const val = document.getElementsByClassName('MuiCalendarPicker-root');
                                                                        // console.log("MUI date picker:::", val);
                                                                        if(val.length > 0){
                                                                            
                                                                            // console.log("MUI date picker first child:::", val[0].firstChild.getAttribute('class'));
                                                                            const val2 = document.getElementsByClassName(val[0].firstChild.getAttribute('class'));
                                                                            // console.log("MUI date picker first child:::", val2);
                                                                            if(val2[0]){
                                                                                // console.log("MUI date picker first child's first child:::", val2[0].firstChild);
                                                                                val2[0].firstChild.setAttribute('aria-live', 'assertive');
                                                                                val2[0].firstChild.setAttribute('aria-atomic', 'true');
                                                                            }
                                                                            const selected = document.getElementsByClassName('MuiPickersDay-root Mui-selected MuiPickersDay-dayWithMargin')
                                                                            if(selected.length > 0){
                                                                                selected[0].focus()
                                                                            }
                                                                        }
                                                                            
                                                                    }, 1000);
                                                                
                                                                }}
                                                                onClose={() => this.setState({datepickerOpen: false, open : false})}
                                                                onAccept={(e) => {
                                                                    let validDate;
                                                                    let error = {};
                                                                    try{
                                                                        if(this.state.error)
                                                                            error = this.state.error;
                                                                        validDate = dateFormat(e, 'mm/dd/yyyy')
                                                                        if(error && error.validTo){
                                                                            delete error['validTo'];
                                                                        }
                                                                    }catch(err){
                                                                        validDate = null

                                                                    }
                                                                    this.setState({ validTo: validDate, validToEdit: (validDate === null||  e === null)? false: true, error: error })
                                                                }}
                                                                onChange={(e)=> {
                                                                    console.log("Value valid to::", e);
                                                                    // this.setState({ validTo: validDate, validToEdit: (validDate === null||  e === null)? false: true, error: '' }, () => console.log("Value::", e, this.state.datepickerOpen))
                                                                }}
                                                                renderInput={(params) => <TextField {...params}  variant="standard" 
                                                                id="cardValidTo" required
                                                                className={`${(this.state.error && this.state.error.validTo === true ) ? 'valid-to-field error-border' : ('valid-to-field')}`}
                                                                />} />
                                                        </LocalizationProvider>
                                                    </div>
                                            </Tooltip>
                                            {this.state.error && this.state.error.validTo === true &&
                                                <div id="valid-to-error" className="credit-limit-error"><span className="visually-hidden">Error:</span>
                                                <span  className="suggestion-text error-text" style={{fontSize: 12}}>{this.props.label_keys.dateGreaterThanValidFrom}</span></div>}
                                    
                                            {/* <span>{dateFormat(this.state.selectedRow.validFrom, 'mm/dd/yyyy')} - {this.state.selectedRow.validTo ? dateFormat(this.state.selectedRow.validTo, 'mm/dd/yyyy') : dateFormat(this.state.selectedRow.validTill, 'mm/dd/yyyy')}</span> */}
                                        </div>
                                    </Col>
                                </Col>
                            :
                            <Col  span={12}>
                                <div className="card-title" >{this.props.label_keys.validity}</div>
                                <div className="card-value" >
                                    <span>{this.props.selectedRow && dateFormat(this.props.selectedRow.validFrom, 'mm/dd/yyyy')} <span aria-hidden="true">-</span><span className="visually-hidden"> to </span> {this.props.selectedRow && (this.props.selectedRow.validTo ? dateFormat(this.props.selectedRow.validTo, 'mm/dd/yyyy') : dateFormat(this.props.selectedRow.validTill, 'mm/dd/yyyy'))}</span>
                                </div>
                            </Col>
                            }
                            <Col span={12} sm={{span: 6, offset: 2}} md={{span: 12, offset: 0}} lg={{span: 12, offset: 0}} style={{marginTop: this.state.editEnable? '-1%' : null}}>
                                <div className="card-title" >{this.props.label_keys.notes}</div>
                                <div className="card-value" ><span>{this.props.selectedRow && this.state.selectedRow.notes ?
                                    <span>{this.state.selectedRow.notes}</span> : <span><span className="visually-hidden">No Value</span><span className="dash-value" aria-hidden="true">——</span></span>}</span></div>
                            </Col>
                        </Row>
                        <Row className={`preview-body ${this.state.editEnable? 'edit-enable': ''}`}>
                            <Col span={12}>
                                <div className="card-title" >{this.props.label_keys.dateCreated}</div>
                                <div className="card-value" ><span>{this.state.viewCardDetails && this.state.viewCardDetails.cardCreatedDate ?
                                    <span>{dateFormat(this.state.viewCardDetails.cardCreatedDate, 'mm/dd/yyyy')}</span> : <span><span className="visually-hidden">No Value</span><span className="dash-value" aria-hidden="true">——</span></span>}</span></div>
                            </Col>
                            
                            
                            <Col className={`${this.state.error && this.state.error.validTo ? 'billing-add': ''}`} span={12} sm={{span: 6, offset: 2}} md={{span: 12, offset: 0}} lg={{span: 12, offset: 0}} >
                            {/* EW-3235 : removed the style attritube to fix bussiness number and phonenumber allignment issue.
                            style={{marginTop: this.state.editEnable? (this.state.error && this.state.error.validTo ? '-6%' :'-3%') : null}} */}
                                <div className="card-title" >{this.props.label_keys.billingAddress}</div>
                                <div className="card-value" >
                                    <span>{this.state.viewCardDetails && this.state.viewCardDetails.companyAddress && this.state.unMasked
                                        ? <span>
                                            {this.state.viewCardDetails.companyAddress.flat}&nbsp;
                                            {this.state.viewCardDetails.companyAddress.streetAddress}&nbsp;
                                            {this.state.viewCardDetails.companyAddress.city}&nbsp;
                                            {this.state.viewCardDetails.companyAddress.state}&nbsp;
                                            {this.state.viewCardDetails.companyAddress.country}&nbsp;
                                            {this.state.viewCardDetails.companyAddress.zipCode}
                                        </span> : <span><span className="visually-hidden">No Value</span><span className="dash-value" aria-hidden="true">——</span></span>}</span>
                                </div>
                            </Col>
                        </Row>
                        <Row className={`preview-body ${this.state.editEnable? 'edit-enable': ''}`}>
                            <Col span={12}>
                                <div className="card-title" >{this.props.label_keys.companyAcctId}</div>
                                <div className="card-value" ><span>{this.props.selectedRow && this.state.viewCardDetails?.accountID != "--" ?
                                    <span>{this.state.viewCardDetails?.accountID} </span> : <span><span className="visually-hidden">No Value</span><span className="dash-value" aria-hidden="true">——</span></span>}</span></div>
                            </Col>
                            <Col className={`${this.state.error && this.state.error.validTo ? 'billing-add': ''}`} span={12} sm={{span: 6, offset: 2}} md={{span: 12, offset: 0}} lg={{span: 12, offset: 0}}>
                                     <div className="card-title" >{this.props.label_keys.businessNumber}</div>
                                <div className="card-value"><span>{this.props.selectedRow && this.state.selectedRow.osn && this.state.viewCardDetails?.businessNumber != "--"?
                                    <span>{this.state.viewCardDetails?.businessNumber}</span> : <span><span className="visually-hidden">No Value</span><span className="dash-value" aria-hidden="true">——</span></span>}</span></div>
                            </Col>
                            { this.state.editEnable ?
                                <Col span={12} className="edit-enableDiv"  style={{marginLeft:'46%'}}>
                                    <button className={`card-detail-cancel ant-btn ${this.props.selectedLang === "French"? "french" : ''}`} id='cancel-btn'
                                    onClick={e => {
                                        this.setState({
                                            error: null,
                                            editEnable: false,
                                            validFromEdit: false, 
                                            validToEdit: false,
                                            creditLimitEdit: false,
                                            creditLimit: this.props.selectedRow.creditLimit,
                                            validFrom: dateFormat(this.props.selectedRow.validFrom, 'mm/dd/yyyy'),
                                            validTo:  this.props.selectedRow.validTo ? dateFormat( this.props.selectedRow.validTo, 'mm/dd/yyyy') : dateFormat( this.props.selectedRow.validTill, 'mm/dd/yyyy'),
                                         }, () => document.getElementById('edit-btn-icon').focus())}}>{this.props.label_keys.cancel}</button>
                                    <button className={`card-detail-save ant-btn ant-btn-primary ${this.props.selectedLang === "French"? "french" : ''}`}
                                    disabled={(!this.state.error || (this.state.error && Object.keys(this.state.error).length === 0 )) && (this.state.creditLimitEdit || this.state.validFromEdit || this.state.validToEdit) && (this.state.creditLimit !== this.props.selectedRow.creditLimit || (this.state.validFrom !== null && (dateFormat( this.state.validFrom, 'mm/dd/yyyy') !== dateFormat(this.props.selectedRow.validFrom, 'mm/dd/yyyy'))) || (this.state.validTo !== null && (dateFormat( this.state.validTo, 'mm/dd/yyyy') !== (this.state.selectedRow.validTo ? dateFormat(this.state.selectedRow.validTo, 'mm/dd/yyyy') : dateFormat(this.state.selectedRow.validTill, 'mm/dd/yyyy')))))
                                    ? false : true} 
                                     onClick={e => this.onSaveCardDetails()}>{this.props.label_keys.saveChanges}</button>
                                </Col>
                                :null

                            }
                        </Row>
                    </div>

                        <div className="preview-details" >
                            <div className="preview-head" role="heading" aria-level="2">{this.props.label_keys.cardHolderDetails}:</div>
                            <Row className="preview-body" style={{marginTop: this.props.type && this.props.type === "bulkCard" ? null : 10 }}>
                                <Col span={12}>
                                    <div className="card-title" >{this.props.label_keys.name}</div>
                                    <div className="card-value" ><span>{this.props.selectedRow && this.state.selectedRow.name ? this.state.selectedRow.name : <span><span className="visually-hidden">No Value</span><span className="dash-value" aria-hidden="true">——</span></span>}</span></div>
                                </Col>
                                <Col span={12}>
                                    <div className="card-title" >{this.props.label_keys.employeeId}</div>
                                    <div className="card-value" ><span>{this.props.selectedRow && this.state.selectedRow.employeeId ? this.state.selectedRow.employeeId : <span><span className="visually-hidden">No Value</span><span className="dash-value" aria-hidden="true">——</span></span>}</span></div>
                                </Col>

                            </Row>
                            <Row className={`preview-body ${this.state.editEnable? 'edit-enable': ''}`}>
                                <Col span={12}>
                                    <div className="card-title" >{this.props.label_keys.email}</div>
                                    <div className="card-value" ><span>{this.props.selectedRow && this.state.selectedRow.emailId ? this.state.selectedRow.emailId : <span><span className="visually-hidden">No Value</span><span className="dash-value" aria-hidden="true">——</span></span>}</span></div>
                                </Col>
                                <Col span={12}>
                                    <div className="card-title" >{this.props.label_keys.mobileNumber}</div>
                                    {/*  className="card-value" <div><span>{viewPhoneNumber(details.PHONE_NO)}</span></div> */}
                                    <div className="card-value" ><span>{this.props.selectedRow && this.state.selectedRow.mobileNumber ? viewPhoneNumber(this.state.selectedRow.mobileNumber) : <span><span className="visually-hidden">No Value</span><span className="dash-value" aria-hidden="true">——</span></span>}</span></div>
                                </Col>
                            </Row>
                        </div></>}

                <div className="deactivate-button">
                    <Button ref={this.deactivateBtnRef} id="deactivateBtn" tabindex={0} className="send-card"
                        onKeyDown={(e) => {
                            if(e.key ==="Tab" && !e.shiftKey){
                                setTimeout(() =>{
                                        document.getElementById("closeBtn").focus();
                                }, 100)
                            }
                            if(e.key === "Enter")
                                this.handleOpen()
                        }}
                        onClick={this.handleOpen}
                        disabled={(this.props.roles && this.props.roles === 'Customer Support')? true : false}
                    >{this.props.label_keys.deactivate}</Button>
                    <div tabIndex={0} aria-hidden="true" className="visually-hidden"></div>
                    {/* <Button className="save-card"
                        onClick={() => this.setState({ confirmVisible: true })}></Button> */}
                    {/* {this.state.viewCardDetails && this.state.viewCardDetails.cardAssignmentStatus + '' === 2 + '' ?
                        <Button className="send-card"
                            onClick={() => {
                                this.sendCard(this.state.viewCardDetails.cardAssignmentId)
                                this.props.onCLoseDetails(false)
                            }}>{this.props.label_keys.sendCard}</Button> : null} */}
                </div>

                { this.state.confirmVisible &&
                    <Modal className="confirm-visible  deactivate-modal"
                        title={<h2 style={{fontSize: '16px', fontWeight:"700", color: "#161a80"}}>{this.props.label_keys.confirmRequired}</h2>}
                        visible={this.state.confirmVisible} cancelButtonProps={{style: { paddingLeft: 0, paddingRight: 0, color: "#19398a", borderColor: "#19398a", fontSize: 14, fontWeight: 400 }}}
                        onOk={() => this.deactiveCard(this.props.selectedRow.cardRef)} okButtonProps={{style: {marginLeft: "10%", paddingLeft: 0, paddingRight: 0, backgroundColor: "#19398a", borderColor: "#19398a", fontSize: 14, fontWeight: 400  }}}
                        onCancel={() => this.setState({ confirmVisible: false, confirmError: false, confirmSuccess: false }, () => document.getElementById("deactivateBtn").focus())}
                        okText={this.props.label_keys.deactivate}
                        onLoad={this.defaultFocus()}
                        cancelText={this.props.label_keys.cancel}
                        cancelButtonProps={{className: "cancel-btn-ant"}} >
                        <div tabindex={-1} >
                            <p>{this.props.label_keys.confirmDeactivateCardMsg}</p>
                        </div>
                    </Modal>
                }
                {this.state.confirmError &&
                    <Modal className="confirm-visible  deactivate-modal"
                        title={<h2 style={{fontSize: '16px', fontWeight:"700", color: "#161a80"}}>{this.props.label_keys.confirmRequired}</h2>}
                        visible={this.state.confirmError}
                        onOk={() => this.deactiveCard(this.props.selectedRow.cardRef)} cancelButtonProps={{className: "cancel-btn-ant", style: { paddingLeft: 0, paddingRight: 0, color: "#19398a", borderColor: "#19398a", fontSize: 14, fontWeight: 400 }}}
                        onCancel={() => {
                            this.setState({ confirmVisible: false, confirmError: false, confirmSuccess: false })
                            this.props.onCLoseDetails(true)
                        }}
                        okText={this.props.label_keys.retry} okButtonProps={{style: { marginLeft: "10%", paddingLeft: 0, paddingRight: 0, backgroundColor: "#19398a", borderColor: "#19398a", fontSize: 14, fontWeight: 400  }}}
                        cancelText={this.props.label_keys.cancel}
                        onLoad={this.defaultFocus()} >
                        <div tabIndex={-1} className="error-deactivate"  ref={"successDeactivateItem"}>
                        
                            <Icon type="warning" aria-label="Error" />
                            <label>{this.props.label_keys.deactivateCardErrorMsg}</label>
                        </div>
                    </Modal>
                }

                {   this.state.confirmSuccess &&
                     <Modal className="confirm-visible  deactivate-modal success-box"
                        visible={this.state.confirmSuccess}
                        onOk={() => {
                            this.setState({ confirmVisible: false, confirmError: false, confirmSuccess: false })
                            this.props.onCLoseDetails(true)
                        }}
                        title={<b className="visually-hidden" id="deactivateCardSuccessMsg">{this.props.label_keys.deactivateCardSuccessMsg}</b>}
                        okText={this.props.label_keys.ok}
                        cancelText=" " 
                        footer={[<Button type="primary" onClick={() => {this.setState({ confirmVisible: false, confirmError: false, confirmSuccess: false }); this.props.onCLoseDetails(true)}}>Ok</Button>]}
                        onLoad={this.defaultFocus()}>
                        <div tabIndex={-1} className="success-deactivate"  ref={"successDeactivateItem"}>
                            <Icon type="check-circle" aria-label="Success" /><br/>
                            <label>{this.props.label_keys.successDeactivatedCard}</label>
                        </div>
                    </Modal>
                }


            </Drawer>
        )
    }
}

function mapStateToProps(state, props) {
    return {
        userDetails: state.authenticationReducer.userDetails,
        token: state.authenticationReducer.token,
        theme: state.authenticationReducer.theme,
        app_labels: state.adduserReducer.app_labels,
        ic_label_keys: state.instantCardMultiLanReducer.label_keys,
        selectedLanguage: state.adduserReducer.selectedLanguage,
    }
}

export default connect(mapStateToProps, null)(CradDetailComponent)
