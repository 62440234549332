import React from 'react';
// import ReactDOM from 'react-dom';
import { Router, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import history from './history';
import Createtrip from './createtrip/createtrip.js';
import CreatetripPending from "./createtrip/createtripPending.js";
import Dashboard from "./dashboard/dashboard";
import CreateCard from "./createtrip/createCard";
import AuthComponent from './authComponent';
import PrivateRoute from './PrivateRoute';
import CreatetripAfterContinue from './createtrip/createtripafterContinue';
import AdminComponent from './Adminscrrencomp/AdminPage.js';
import SuperAdminComponent from './Adminscrrencomp/SuperAdminPAge';
import CardAdminComponent from './Adminscrrencomp/CardAdminPage';
import DashboardComponent from './ProvisionerScreen/dashboard';
import CSRComponent from './csr';
import InitialAppTutorial from './initialAppTourComponent';
import ToMobileApp from './toMobileApp';
import PageNotFoundComponent from './pageNotFoundComponent'
import { attemptAutoLogin, loginRequest } from '../actions/authenticationActions';
import ElanComponent from './Elan/ElanComponent';
const { elanRelatedUrls } = require('../data/constants.js')
const ElanTheme = React.lazy(() => import('../themes/ElanTheme'));
const IcTheme = React.lazy(() => import('../themes/IcTheme'));

class App extends React.Component {

    // constructor(props) {
    //     super(props)
    // }

    componentDidMount() {
        this.props.attemptAutoLogin()
    }

    render() {
        let isElanUrlFlag = window.location.hostname.includes(elanRelatedUrls.elanUrlCheck)
        const ThemeSelector = ({ children }) => {
            return (
                <>
                    <React.Suspense fallback={<></>}>
                        {(this.props.theme == 'elan') && <ElanTheme/>}
                        {(this.props.theme == 'ic') && <IcTheme />}
                    </React.Suspense>
                    {children}
                </>
            )
        }

        return (
            <Router history={history}>
                 <ThemeSelector>
                <Switch>
                    <PrivateRoute path="/dashboard" component={Dashboard} exact />
                    <PrivateRoute path="/pendingTrip" component={CreatetripPending} exact />
                    <PrivateRoute path="/admin" component={AdminComponent} exact />
                    <PrivateRoute path="/superadmin" component={SuperAdminComponent} exact />
                    <PrivateRoute path="/superadmin/activecards" component={SuperAdminComponent} />
                    <PrivateRoute path="/customersupport" component={SuperAdminComponent} exact />
                    <PrivateRoute path="/customersupport/activecards" component={SuperAdminComponent} />
                    <PrivateRoute path="/cardadmin" component={CardAdminComponent} exact />
                    <PrivateRoute path="/carddashboard" component={DashboardComponent} exact />
                    <PrivateRoute path="/continue" component={CreatetripAfterContinue} exact />
                    <PrivateRoute path="/newtrip" component={Createtrip} exact />
                    <PrivateRoute path="/newcard" component={CreateCard} exact />
                    <PrivateRoute path="/csr" component={CSRComponent} exact />
                    <PrivateRoute path="/tutorial" component={InitialAppTutorial} exact />
                    <Route path="/mobileapp" component={ToMobileApp} />
                    <Route path={[
                                "/login",
                                '/forgotPassword',
                                '/resetPassword',
                                '/multifactor',
                                '/register',
                                '/openSourceLicenses',
                                '/'
                            ]} exact component={isElanUrlFlag ? ElanComponent : AuthComponent} />
                    <Route component={PageNotFoundComponent} />
                </Switch>
                </ThemeSelector>
            </Router>
        )

    }

}
const mapStateToProps = (state) => {
    return {
        // isAuthenticating: state.authenticationReducer.isAuthenticating,
        isAuthenticated: state.authenticationReducer.isAuthenticated,
        theme: state.authenticationReducer.theme
        // isPasswordResetting: state.authenticationReducer.isPasswordResetting,
        // authenticationError: state.authenticationReducer.authenticationError,
        // credentialsResetError: state.authenticationReducer.credentialsResetError,
        // credentialsResetSuccess: state.authenticationReducer.credentialsResetSuccess
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        loginRequest: () => {
            dispatch(loginRequest())
        },
        attemptAutoLogin: () => {
            dispatch(attemptAutoLogin())
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);