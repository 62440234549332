/* eslint-disable no-unused-expressions */
import React from 'react';
import { connect } from 'react-redux';
import $ from 'jquery';
import { notification, Spin, Modal, Collapse, Icon, Select,Row,Col,Button} from 'antd';
import { loaderFocus } from '../../../utils/accessability';
import 'antd/dist/antd.css';
import '../../../styling/companysetup.css';
import PolicyUpload from '../uploadPolicy';
import RenderColumnComponent from './renderColumn'

import axios from 'axios';
const { Panel } = Collapse;
const confirm = Modal.confirm;
const { URLs, errorMessages } = require("../../../data/constants.js");
const Option = Select.Option;

class CompSetupComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            company: [],
            companyList: [],
            retrieve: false,
            spinFlag: false,
            uploadPolicyflag: false,
            uploadComapnyId: '',
            policyType: '2',
            panValue: [],
            activeKeys: [], // assume everything is open initially
            fullPan: [],
            allServiceInterfaces: [],
            referenceData: [],
            showSwitchNew: false,
            formFieldRef: [],
            instaDetails: {},
            custCodeMandatory: [],
            errorFlag: false,
            clickedClose: false,
            addNewBtnFlag: false,
            isClose:false,
            selectedCompanyValue:null,
            selectSearchItem : null,
            placeholderCompany:undefined,
            placeholderOsn :undefined

        }
    }

    /**
     * @param  {} {this.fetchAllCompany(
     * life cycle method called on mount of component
     */
    componentDidMount() {
        this.fetchAllCompany();
        this.getReferenceData();
    }

    /**
    * It will Fetch List of All Active Companies
    */
    fetchAllCompany = (val={}) => {
        axios.get(URLs.tripUrl + "registeredCompanies", {
            responseType: 'json',
            headers: {
                "Authorization": 'Bearer ' + this.props.token.access_token
            }
        }).then(response => {
            this.setState({ retrieve: true });
            if (response.data.serviceStatus.statusCode === 200) {
                var newObj = [];
                var count = 1;
                var stateArray = []
                var chromeRiverKey = []
                response.data.data.list.forEach((item) => {
                    item.key = count;
                    item.companyNamecopy = item.companyName;
                    item.saveColor = "#B4B4B4";
                    item.editColor = "#0A41C5";
                    item.cancelColor = "#B4B4B4";
                    // item.companyPan = 'XXXXXXXXXXXX' + item.companyPan;
                    item.companyAcctId = item.companyAcctId;
                    item.disableColor = "#0A41C5";
                    item.apiConsumerCheck = (item.externalSystemId === '' || item.externalSystemId === null) ? false : true
                    count++;
                    newObj.push(item);
                    // let stateObj = {};
                    // stateObj.maskedPan = ''
                    // stateObj.actualPan = ''
                    // stateArray.push(stateObj);
                    item.serviceInterface && item.serviceInterface[0] && item.serviceInterface[0] === "Chrome River" ? chromeRiverKey[item.key] = true : chromeRiverKey[item.key] = false
                })
                let activeKeys = newObj.map(el => el.key + '');
                this.setState({
                    companyList: newObj,
                    // panValue: stateArray,
                    activeKeys,
                    custCodeMandatory: chromeRiverKey,
                    allServiceInterfaces: response.data.data.interfaceServices
                });
                // console.log("valval",val);
                if (val.companyId !== null ) {
                    if (this.state.placeholderOsn !== undefined) {
                        let filteredCompany = newObj.filter((item) => item.companyShortName === val?.companyShortName);
                        if (Object.keys(val).length !== 0) {
                            this.setState({ selectedCompanyValue: filteredCompany })
                        }
                    } else {
                        let filteredCompanyId = newObj.find((item) => item.companyId === val?.companyId);
                        if (Object.keys(val).length !== 0) {
                            this.setState({ selectedCompanyValue: [filteredCompanyId] })
                        }
                    }
                }
                else{
                    let filteredCompanyId = newObj.find((item) => item.companyName === val?.companyName);
                        if (Object.keys(val).length !== 0) {
                            this.setState({ selectedCompanyValue: [filteredCompanyId] })
                        }
                }

            }
            else if (response.data.serviceStatus.statusCode === 210) {
            
                notification.error({
                    message: <span  role='alert' aria-live="assertive" aria-atomic="true" ><span className="visually-hidden">Error </span>{response.data.serviceStatus.message}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }
        }).catch(error => {
            this.setState({ retrieve: true });
            if (error.response !== undefined && error.response.data !== null && error.response.data !== undefined && error.response.data.serviceStatus !== undefined) {
                notification.error({
                    message: <span  role='alert' aria-live="assertive" aria-atomic="true" ><span className="visually-hidden">Error </span>{error.response.data.serviceStatus.message}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }
            else notification.error({
                message: <span  role='alert' aria-live="assertive" aria-atomic="true" ><span className="visually-hidden">Error </span>{errorMessages.generic_error}</span>,
                duration: 3,
                style: {
                    backgroundColor: '#fff1f0',
                    border: "1px solid #ffa39e",
                }
            })
        })
    }

    getReferenceData = () => {
        axios.get(URLs.tripUrl + "getReferenceData/InstaCardFieldList", {
            responseType: 'json',
            headers: {
                "Authorization": 'Bearer ' + this.props.token.access_token
            }
        }).then(response => {
            this.setState({ referenceData: response.data.referenceData })
            response.data.referenceData.data.map(item => {
                let instaDetails = this.state.instaDetails;
                instaDetails[item.companyID] = item.status;
                this.setState({ instaDetails })
                return true
            })
        }).catch(error => notification.error({
            message: <span  role='alert' aria-live="assertive" aria-atomic="true" ><span className="visually-hidden">Error </span>{error.message}</span>,
            duration: 3,
            style: {
                backgroundColor: '#fff1f0',
                border: "1px solid #ffa39e",
            }
        })
        )
    }

    hideUploadModal = () => this.setState({ uploadPolicyflag: false })

    handler = formFieldRef => this.setState({ formFieldRef: formFieldRef })

    uploadPolicy = (record, e) => this.setState({ uploadPolicyflag: true, uploadComapnyId: record.companyId })

    /**
     * @param  {} record
     * @param  {} e
     * it disables the company and users of that particular company
     */
    disableCompany = (record, e) => {
        if (record.companyId === 'new') {
            const dataSource = [...this.state.companyList];
            this.setState({ companyList: dataSource.filter(item => item.key !== record.key) });
        }
        else {
            var current = this;
            let obj = {
                "companyId": record.companyId.toString()
            }
            confirm({
                okText: 'YES,DISABLE',
                cancelText: 'CANCEL',
                title: record.companyName,
                okButtonProps: {
                    className:"check",
                    style: {
                        padding: '0px'
                    }
                },
                content: 'ARE YOU SURE YOU WANT TO DISABLE THIS COMPANY FROM EXPENSE WIZARD?',
                onOk() {
                    current.setState({ spinFlag: true })
                    loaderFocus(".loader-color .ant-spin-text");  
                    axios.post(URLs.tripUrl + "disableCompany", obj, {
                        responseType: 'json',
                        headers: {
                            "Authorization": 'Bearer ' + current.props.token.access_token
                        }
                    }).then(response => {
                        
                        if (response.data.serviceStatus.statusCode === 200) {
                            current.fetchAllCompany();
                            current.getReferenceData();
                            current.props.getCompanyLists();
                            setTimeout(() => {
                                if(current.state.placeholderOsn === undefined){
                                    let filteredValue = current.state.companyList.find(item=>item.companyId === record.companyId)
                                    current.setState({selectedCompanyValue:[filteredValue],spinFlag: false})
                                }else{
                                    let filteredValue = current.state.companyList.filter(item => item.companyShortName === record.companyShortName)
                                    current.setState({ selectedCompanyValue: filteredValue, spinFlag: false });
                                }
                                notification.success({
                                    message: <span  role='alert' aria-live="assertive" aria-atomic="true" ><span className="visually-hidden">Success </span>{response.data.serviceStatus.message}</span>,
                                    duration: 3,
                                    style: {
                                        backgroundColor: '#dff0d8',
                                        color: "green",
                                        border: "1px solid green",
                                    }
                                });
                            }, 3000);
                        }
                        else if (response.data.serviceStatus.statusCode === 210) {
                             current.setState({ spinFlag: false})
                            notification.error({
                                message: <span  role='alert' aria-live="assertive" aria-atomic="true" ><span className="visually-hidden">Error </span>{response.data.serviceStatus.message}</span>,
                                duration: 3,
                                style: {
                                    backgroundColor: '#fff1f0',
                                    border: "1px solid #ffa39e",
                                }
                            });
                        }
                    }).catch(error => {
                        current.setState({ spinFlag: false })
                        if (error.response !== undefined && error.response.data !== null && error.response.data !== undefined && error.response.data.serviceStatus !== undefined) {
                            notification.error({
                                message: <span  role='alert' aria-live="assertive" aria-atomic="true" ><span className="visually-hidden">Error </span>{error.response.data.serviceStatus.message}</span>,
                                duration: 3,
                                style: {
                                    backgroundColor: '#fff1f0',
                                    border: "1px solid #ffa39e",
                                }
                            });
                        }
                        else {
                            notification.error({
                                message: <span  role='alert' aria-live="assertive" aria-atomic="true" ><span className="visually-hidden">Error </span>{errorMessages.generic_error}</span>,
                                duration: 3,
                                style: {
                                    backgroundColor: '#fff1f0',
                                    border: "1px solid #ffa39e",
                                }
                            });
                        }
                    })
                },
                onCancel() { },
            });
        }
    }
    enableCompany = company => {
        this.setState({ spinFlag: true })
        loaderFocus(".loader-color .ant-spin-text");  
        let obj = {
            "companyId": company?.companyId
        };
        axios.post(URLs.companyUrl + "activateCompany", obj, {
            headers: {
                "Authorization": 'Bearer ' + this.props.token.access_token
            }
        }).then(response => {
            if (response.data.serviceStatus.statusCode === 200) {
                this.fetchAllCompany();
                this.getReferenceData();
                setTimeout(() => {
                    if(this.state.placeholderOsn === undefined){
                        let filteredValue = this.state.companyList.find(item => item.companyId === company.companyId)
                        this.setState({ selectedCompanyValue: [filteredValue], spinFlag: false });
                    }else{
                        let filteredValue = this.state.companyList.filter(item => item.companyShortName === company.companyShortName)
                        this.setState({ selectedCompanyValue: filteredValue, spinFlag: false });
                    }
                    notification.success({
                        message: <span role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Success </span>{response.data.serviceStatus.message}</span>,
                        duration: 3,
                        style: {
                            backgroundColor: '#dff0d8',
                            color: "green",
                            border: "1px solid green",
                        }
                    });
                }, 3000);
            }
            else {
                this.setState({ spinFlag: false })
                notification.error({
                    message: <span  role='alert' aria-live="assertive" aria-atomic="true" ><span className="visually-hidden">Error </span>{errorMessages.generic_error}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
                setTimeout(() => {
                    this.fetchAllCompany()
                    this.getReferenceData()
                }, 3000);
            }
        }).catch(error => {
            this.setState({ spinFlag: false })
            notification.error({
                message: <span  role='alert' aria-live="assertive" aria-atomic="true" ><span className="visually-hidden">Error </span>{errorMessages.generic_error}</span>,
                duration: 3,
                style: {
                    backgroundColor: '#fff1f0',
                    border: "1px solid #ffa39e",
                }
            });
        })
    }

    addNewData = () => {
        const { companyList } = this.state;
        const newData = [{
            key: this.state.companyList.length + 1,
            companyId: "new",
            activeStatus: true,
            companyShortName: "",
            companyName: "",
            // companyPan: "",
            companyAcctId:"",
            emailId: "",
            externalSystemId: "",
            serviceInterfaceFlags: {isApiConsumer: false},
            phoneNo: "",
            saveColor: "#0A41C5",
            editColor: "#B4B4B4",
            cancelColor: "#0A41C5",
            disableColor: "#B4B4B4",
        }];
        let newActiveKeys = this.state.activeKeys.slice()
        // push the new key here
        newActiveKeys.push(newData[0].key + '')
        // let stateArray = this.state.panValue;
        // let stateObj = {};
        // stateObj.maskedPan = ''
        // stateObj.actualPan = ''
        // stateArray.push(stateObj);
        let fieldObj = []
        for (var i in this.state.referenceData.referenceData) {
            let obj = {
                formFieldRefID: this.state.referenceData.referenceData[i].REF_ID,
                formFieldStatus: false
            }
            fieldObj.push(obj)
        }
        this.setState({
            selectedCompanyValue: newData,
            placeholderOsn: undefined,
            placeholderCompany: undefined,
            // panValue: stateArray,
            activeKeys: newActiveKeys,
            showSwitchNew: false,
            formFieldRef: fieldObj,
            addNewBtnFlag: true
        });
    }

    highlightInvalidField = (element1, key) => {
        var class1 = element1 + key;
        var element = element1 + key;
        let label1 = $(element).closest('.compDetailsRow').find(class1)
        label1.addClass('has-error')
        $(`${element} input`).focus()
    }

    clearInvalidField = (element) => {
        let label = $(element).closest('.compDetailsRow').find('.has-error');
        label.removeClass('has-error')
    }

    /**
    * @param  {} record
    * adding new company
    */
    createCompany = (record) => {
        this.setState({ spinFlag: true })
        loaderFocus(".loader-color .ant-spin-text");  
        let obj = {
            "companyId": record.companyId,
            "companyName": record.companyName,
            "companyShortName": record.companyShortName,
            "chromeRiverCustCd": record.chromeRiverCustCd,
            "emailId": record.emailId,
            "phoneNo": record.phoneNo,
            // "companyPan": record.companyPan,
            "companyAcctId": record.companyAcctId,
            "serviceInterface": record.serviceInterface,
            "formFieldRef": this.state.formFieldRef,
            "externalSystemId": record.externalSystemId,
            "serviceInterfaceFlags": {"isApiConsumer": record.apiConsumerCheck},
        };
        //console.log("record::: create company", record)
        axios.post(URLs.tripUrl + "createCompany", obj, {
            headers: {
                "Authorization": 'Bearer ' + this.props.token.access_token
            }
        }).then(response => {
            this.setState({ spinFlag: false, showSwitchNew: false })
            if (response.data.serviceStatus.statusCode === 200) {
                setTimeout(() => {
                    this.fetchAllCompany(record);
                    this.getReferenceData();
                    this.props.getCompanyLists();
                }, 500);
                notification.success({
                    message: <span  role='alert' aria-live="assertive" aria-atomic="true" ><span className="visually-hidden">Success </span>{response.data.serviceStatus.message}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#dff0d8',
                        color: "green",
                        border: "1px solid green",
                    }
                });
            }
            else {
                this.setState({ spinFlag: false })
                this.fetchAllCompany();
                this.getReferenceData();
                notification.error({
                    message: <span  role='alert' aria-live="assertive" aria-atomic="true" ><span className="visually-hidden">Error </span>{errorMessages.generic_error}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }
        }).catch(error => {
            this.setState({ spinFlag: false, showSwitchNew: false })
            this.fetchAllCompany();
            this.getReferenceData();
            this.props.getCompanyLists();
            if (error.response !== undefined && error.response.data !== null && error.response.data !== undefined && error.response.data.serviceStatus !== undefined) {
                notification.error({
                    message: <span  role='alert' aria-live="assertive" aria-atomic="true" ><span className="visually-hidden">Error </span>{error.response.data.serviceStatus.message}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            } else notification.error({
                message: <span  role='alert' aria-live="assertive" aria-atomic="true" ><span className="visually-hidden">Error </span>{errorMessages.generic_error}</span>,
                duration: 3,
                style: {
                    backgroundColor: '#fff1f0',
                    border: "1px solid #ffa39e",
                }
            })
        })
    }

    CollapseFunction = (activeKeys) => {
        // check if the changed panel was collapsed previously
        // it so, then this change has expanded it
        let activeKeys1 = activeKeys.slice()
        this.setState(() => ({ activeKeys: activeKeys1 }))
    }

    closedReset = () => this.setState({ clickedClose: false })

    onActiveCompany = (item) => {
        return (
            <div>
                <button className="pseudoButtonIcon" disabled={this.props.roles.toLowerCase() === 'customer support'? true : false}
                 onClick={(e) => {
                    this.clearInvalidField(e.target);
                    this.fetchAllCompany();
                    this.getReferenceData();
                    this.props.getCompanyLists();
                    this.setState({ clickedClose: true })
                    if (item.companyId === 'new') {
                        const dataSource = [...this.state.companyList];
                        const newActiveKeys = this.state.activeKeys.slice()
                        this.setState({
                            companyList: dataSource.filter(itemnew => itemnew.key !== item.key),
                            activeKeys: newActiveKeys.filter(itemnew => itemnew.key !== item.key),
                            formFieldRef: [], company: []
                        });
                    }
                }} ><i role='img' aria-label="icon-Cancel" className={`icon-Cancel ${this.props.roles.toLowerCase() === 'customer support'? ' disabled-icon': ''}`} title='Cancel new row' style={{ color: item.cancelColor, fontSize: "17px", marginLeft: "6%" }} /></button>

                <button className="pseudoButtonIcon" disabled={this.props.roles.toLowerCase() === 'customer support'? true : false}
                     onClick={(e) => {
                    if (item.saveColor === "#0A41C5") {
                        if (item.companyName === "" || item.companyName == null) {
                            this.highlightInvalidField('.companyNameClass', item.key)
                            notification.warning({
                                message: <span  role='alert' aria-live="assertive" aria-atomic="true" ><span className="visually-hidden">Error </span>{errorMessages.error_req_companyName}</span>,
                                duration: 3,
                                style: {
                                    backgroundColor: '#fffbe6',
                                    border: "1px solid #ffe58f",
                                }
                            });
                            return;
                        }
                        if (item.companyShortName === "" || item.companyShortName == null) {
                            this.highlightInvalidField('.companyShortNameClass', item.key)
                            notification.warning({
                                message: <span  role='alert' aria-live="assertive" aria-atomic="true" ><span className="visually-hidden">Error </span>{errorMessages.error_req_compShortName}</span>,
                                duration: 3,
                                style: {
                                    backgroundColor: '#fffbe6',
                                    border: "1px solid #ffe58f",
                                }
                            });
                            return;
                        }
                        else if (item.companyShortName.length < 2 || item.companyShortName.length > 6) {
                            this.highlightInvalidField('.companyShortNameClass', item.key)
                            notification.warning({
                                message: <span  role='alert' aria-live="assertive" aria-atomic="true" ><span className="visually-hidden">Error </span>{errorMessages.error_val_compShortName}</span>,
                                duration: 3,
                                style: {
                                    backgroundColor: '#fffbe6',
                                    border: "1px solid #ffe58f",
                                }
                            });
                            return;
                        }
                        if (this.state.custCodeMandatory[item.key]) {
                            if (item.chromeRiverCustCd === "" || item.chromeRiverCustCd == null) {
                                this.highlightInvalidField('.companyCrcustCodeClass', item.key)
                                notification.warning({
                                    message: <span  role='alert' aria-live="assertive" aria-atomic="true" ><span className="visually-hidden">Error </span>{errorMessages.error_req_chromeRiverCustCd}</span>,
                                    duration: 3,
                                    style: {
                                        backgroundColor: '#fffbe6',
                                        border: "1px solid #ffe58f",
                                    }
                                });
                                return;
                            }
                        }
                        if (item.chromeRiverCustCd && item.chromeRiverCustCd !== undefined && item.chromeRiverCustCd.length > 10) {
                            this.highlightInvalidField('.companyCrcustCodeClass', item.key)
                            notification.warning({
                                message: <span  role='alert' aria-live="assertive" aria-atomic="true" ><span className="visually-hidden">Error </span>{errorMessages.error_val_chromeRiverCustCd}</span>,
                                duration: 3,
                                style: {
                                    backgroundColor: '#fffbe6',
                                    border: "1px solid #ffe58f",
                                }
                            });
                            return;
                        }
                        if (item.companyAcctId === "" || item.companyAcctId == null) {
                            this.highlightInvalidField('.companyAcctId', item.key)
                            notification.warning({
                                message: <span  role='alert' aria-live="assertive" aria-atomic="true" ><span className="visually-hidden">Error </span>{errorMessages.error_req_compAcctId}</span>,
                                duration: 3,
                                style: {
                                    backgroundColor: '#fffbe6',
                                    border: "1px solid #ffe58f",
                                }
                            });
                            return;
                        }
                        if (item.companyAcctId.length !== 12 || isNaN(item.companyAcctId) || item.companyAcctId.indexOf(' ') >= 0) {
                            this.highlightInvalidField('.companyAcctId', item.key)
                            notification.warning({
                                message: <span  role='alert' aria-live="assertive" aria-atomic="true" ><span className="visually-hidden">Error </span>{errorMessages.error_val_compAcctId}</span>,
                                duration: 3,
                                style: {
                                    backgroundColor: '#fffbe6',
                                    border: "1px solid #ffe58f",
                                }
                            });
                            return;
                        }
                        if (item.companyId === "new" && !item.serviceInterface) {
                            notification.warning({
                                message: <span  role='alert' aria-live="assertive" aria-atomic="true" ><span className="visually-hidden">Error </span>{errorMessages.error_req_serviceInterface}</span>,
                                duration: 3,
                                style: {
                                    backgroundColor: '#fffbe6',
                                    border: "1px solid #ffe58f",
                                }
                            });
                            return;
                        }
                        if (item.emailId === "" || item.emailId == null) {
                            this.highlightInvalidField('.emailIdClass', item.key)
                            notification.warning({
                                message: <span  role='alert' aria-live="assertive" aria-atomic="true" ><span className="visually-hidden">Error </span>{errorMessages.error_req_emailId}</span>,
                                duration: 3,
                                style: {
                                    backgroundColor: '#fffbe6',
                                    border: "1px solid #ffe58f",
                                }
                            });
                            return;
                        }
                        let emailRegExp = /^\w+([+.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,64})+$/;
                        if (emailRegExp.test(item.emailId) === false) {
                            this.highlightInvalidField('.emailIdClass', item.key)
                            notification.warning({
                                message: <span  role='alert' aria-live="assertive" aria-atomic="true" ><span className="visually-hidden">Error </span>{errorMessages.error_incorrectEmail}</span>,
                                duration: 3,
                                style: {
                                    backgroundColor: '#fffbe6',
                                    border: "1px solid #ffe58f",
                                }
                            });
                            return;
                        }
                        let regexp = /(\d)\1{9}/g;
                        if (item.phoneNo.length !== 10 || item.phoneNo.indexOf("555") === 0 || regexp.test(item.phoneNo) === true || (/^[0-9]*$/).test(item.phoneNo) === false) {
                            this.highlightInvalidField('.phoneNoClass', item.key)
                            notification.warning({
                                message: <span  role='alert' aria-live="assertive" aria-atomic="true" ><span className="visually-hidden">Error </span>{errorMessages.error_val_compNumber}</span>,
                                duration: 3,
                                style: {
                                    backgroundColor: '#fffbe6',
                                    border: "1px solid #ffe58f",
                                }
                            });
                            return;
                        }
                        if (item.phoneNo === "" || item.phoneNo == null) {
                            this.highlightInvalidField('.phoneNoClass', item.key)
                            notification.warning({
                                message: <span  role='alert' aria-live="assertive" aria-atomic="true" ><span className="visually-hidden">Error </span>{errorMessages.error_req_compNumber}</span>,
                                duration: 3,
                                style: {
                                    backgroundColor: '#fffbe6',
                                    border: "1px solid #ffe58f",
                                }
                            });
                            return;
                        }

                        if (item.apiConsumerCheck === true && (item.externalSystemId === '' || item.externalSystemId === null)) {
                            this.highlightInvalidField('.external-System-Id', item.key)
                            notification.warning({
                                message: <span  role='alert' aria-live="assertive" aria-atomic="true" ><span className="visually-hidden">Error </span>Please provide the External ID</span>,
                                duration: 3,
                                style: {
                                    backgroundColor: '#fffbe6',
                                    border: "1px solid #ffe58f",
                                }
                            });
                            return;
                        }
                        item.companyId === "new" ? item.companyId = null : null
                        this.createCompany(item);
                        const newData = [...this.state.companyList];
                        /*  delete record.editable; */
                        item.editable = false;
                        item.editColor = "#0A41C5";
                        item.saveColor = "#B4B4B4";
                        item.cancelColor = "#B4B4B4";
                        item.disableColor = '#0A41C5';
                        this.setState({ companyList: newData, editableCompanyAcctId: false, clickedClose: true, addNewBtnFlag: false });
                        this.cacheData = newData.map(item => ({ ...item }));
                    }
                }
                } ><i role='img' aria-label="icon-Save" className={`icon-Save ${this.props.roles.toLowerCase() === 'customer support'? ' disabled-icon': ''}`} title='Save' style={{ color: item.saveColor, fontSize: "17px" }} /></button>
                <button className="pseudoButtonIcon" disabled={this.props.roles.toLowerCase() === 'customer support'? true : false}
                     onClick={(e) => {
                    if (item.companyId !== "new") {
                        if (item.editColor === "#0A41C5") {
                            const newData = [...this.state.companyList];
                            item.editable = true;
                            item.editColor = "#B4B4B4";
                            item.saveColor = "#0A41C5";
                            item.cancelColor = "#0A41C5";
                            item.disableColor = '#B4B4B4';
                            this.setState({
                                companyList: newData, editableCompanyAcctId: false,
                                formFieldRef: [], company: []
                            });
                        }
                        else {
                            this.clearInvalidField(e.target);
                            const newData = [...this.state.companyList];
                            item.editColor = "#0A41C5"
                            item.saveColor = "#B4B4B4";
                            item.cancelColor = "#B4B4B4";
                            item.disableColor = '#0A41C5';
                            item.editable = false;
                            this.setState({
                                companyList: newData, editableCompanyAcctId: false,
                                formFieldRef: [], company: []
                            });
                        }
                    }

                }}>
                    <i role='img' aria-label="icon-Edit" className={`icon-Edit ${this.props.roles.toLowerCase() === 'customer support'? ' disabled-icon': ''}`} title='Edit' style={{ color: item.editColor, fontSize: "17px", marginLeft: "6%" }} /></button>
                <button className="pseudoButtonIcon" disabled={this.props.roles.toLowerCase() === 'customer support'? true : false}
                     onClick={(e) => {
                    if (item.disableColor === '#0A41C5') {
                        this.clearInvalidField(e.target);
                        this.disableCompany(item, e);
                        this.setState({ formFieldRef: [] })
                    }
                }
                }><i role='img' aria-label="icon-Delete" className={`icon-Delete-or-Disable ${this.props.roles.toLowerCase() === 'customer support'? ' disabled-icon': ''}`} title='Disable company' style={{ color: item.disableColor, fontSize: "17px", marginLeft: "6%" }} /></button>
            </div>
        )
    }
    handleCompanyListChange = company => {
        this.state.selectedCompanyValue && this.state.selectedCompanyValue.map(data => data.companyId === company?.companyId && this.setState({ company }))
    }

    companyChange = (value, option) => {
        let filteredCompany = this.state.companyList.find((item)=>item.companyId === value);
        this.setState({selectSearchItem: null,selectedCompanyValue:[filteredCompany],placeholderCompany:value,placeholderOsn:undefined,addNewBtnFlag:false});
    }
    osnChange =(value)=> {
        let filteredOsn = this.state.companyList.filter((item)=>item.companyShortName === value);
        this.setState({selectSearchItem: null,selectedCompanyValue:filteredOsn,placeholderOsn:value,placeholderCompany:undefined,addNewBtnFlag:false});
    }

    handleSearch = search => {
        this.setState({ placeholderOsn:undefined,placeholderCompany:undefined,selectSearchItem: search });
    }   
    renderLabel = (item,val) => {
        let label = val === "companyName" ? item.companyName : item.companyShortName;
        let searchString = this.state.selectSearchItem;
        if (searchString) {
            let index = label.toLowerCase().indexOf(searchString.toLowerCase());
            if (index !== -1) {
                let length = searchString.length;
    
                let prefix = label.substring(0, index);
                let suffix = label.substring(index + length);
                let match = label.substring(index, index + length);
    
                return (
                    <span>{prefix}<span className="searchString" style={{color: "#19398A", fontWeight: 600}}>{match}</span>{suffix}
                    </span>
                );
            }
        }
        return (
          <span>
              {label}
          </span>
        );
      }
    companyNameSort = (list) => {
        let resultlist = list.sort((a, b) => {
            let companyname = a.companyName.toLowerCase().trim(),
                companyname2 = b.companyName.toLowerCase().trim();
            return companyname < companyname2 ? -1 : 1
        });
        return resultlist;
    }
    removeDuplicateOSN = (companyOsnList) => {
        const filteredArr = companyOsnList.reduce((acc, current) => {
            const listOfOsn = acc.find(item => item.companyShortName === current.companyShortName);
            if (!listOfOsn) {
                return acc.concat([current]);
            } else {
                return acc;
            }
        }, []);
        let resultlist = filteredArr.sort((a, b) => {
            let companyShortName = a.companyShortName.toLowerCase().trim(),
            companyShortName2 = b.companyShortName.toLowerCase().trim();
            return companyShortName < companyShortName2 ? -1 : 1
        });
        return resultlist;
    }
    render() {
        return (
            <div className="companySetupclass" style={{paddingTop:"50px"}}>
                <div className="row">
                    <div style={{ textAlign: "right", paddingRight: "2%" }} >
                        <Button  icon="plus-circle" type="primary" ghost disabled={this.props.roles.toLowerCase() === 'customer support'? true : this.state.addNewBtnFlag} className="addNewCompanyButton" onClick={this.addNewData}>Add new company data</Button>
                    </div>
                </div>
                {this.state.uploadPolicyflag ? <PolicyUpload action={this.state.uploadPolicyflag} hideUploadModal={this.hideUploadModal} companyId={this.state.uploadComapnyId} policyType={parseInt(this.state.policyType)} /> : null}
                {this.state.spinFlag ? <div className="dark-spinner loader-color"><Spin tip="Loading..." /></div> : null}
                <Row align="middle">
                <br /><br />
                <Row gutter={[16, 16]} >
                  <Col span={6} offset={10} style={{paddingLeft: '1.5%'}}>
                    <span className='active-cards-spn'>Company setup</span>
                  </Col>
                </Row>
                <Row>
                  <Col span={6} offset={10}>
                    <span className='select-comp-txt-set'>Select a company to setup</span>
                    <br /><br />
                  </Col>
                </Row>
                <Row>
                  <Col span={5} offset={6} >
                  <Select className="active-select-comp super-admin-select"
                        onChange={this.companyChange}
                        onDropdownVisibleChange={open => {
                            this.setState({ isClose: !open })
                        }}
                        defaultActiveFirstOption={this.state.isClose}
                        optionFilterProp="children"
                        showSearch
                        onSearch={this.handleSearch}
                        filterOption={(input, option) => option.props.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        style={{ width: '100%', border: '1px solid #D2D2E0', borderRadius: '4px', height: '37px' }}
                        placeholder="Select a company"
                        value = {this.state.placeholderCompany || undefined}>
                        {this.companyNameSort(this.state.companyList).map(item => {
                            return <Option value={item.companyId} label={item.companyName}>{this.renderLabel(item,"companyName")}</Option>
                        })}
                    </Select>
                  </Col>
                  <Col span={1} offset={1} style={{paddingTop:"0.5em",width:"3%",marginLeft:"2%"}}>
                    <span className='select-comp-txt'>Or</span>
                  </Col>
                  <Col span={5} >
                  <Select className="active-select-comp super-admin-select"
                        onChange={this.osnChange}
                        onDropdownVisibleChange={open => {
                            this.setState({ isClose: !open })
                        }}
                        defaultActiveFirstOption={this.state.isClose}
                        optionFilterProp="children"
                        showSearch
                        onSearch={this.handleSearch}
                        filterOption={(input, option) => option.props.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        style={{ width: '100%', border: '1px solid #D2D2E0', borderRadius: '4px', height: '37px' }}
                        placeholder="Select OSN"
                        value = {this.state.placeholderOsn || undefined}
                        >
                        {this.removeDuplicateOSN(this.state.companyList).map(item => {
                            return <Option value={item.companyShortName} label={item.companyShortName}>{this.renderLabel(item,"companyShortName")}</Option>
                        })}
                    </Select>
                  </Col>
                </Row>
              </Row>
              <br/>
              {this.state.selectedCompanyValue === null && <><br/><br/><br/><br/><br/><br/></>}
                {this.state.selectedCompanyValue !== null ? <Collapse activeKey={this.state.activeKeys} style={{ border: "0" }} onChange={this.CollapseFunction}>
                    {this.state.selectedCompanyValue && this.state.selectedCompanyValue.map(company => {
                        return (<Panel header={<div className="row" style={{ marginLeft: "2%" }}>
                            <div className='col-xs-10 col-xs-offset-0 col-sm-4 col-sm-offset-0'>
                                <span className="companyNameList">{company.companyId === "new" ? 'New Company' : company.companyName}</span>
                            </div>
                            <div className='col-xs-2 col-xs-offset-0 col-sm-1 col-sm-offset-7'>
                                {this.state.activeKeys.some(el => el === company?.key) ? <Icon type="up" style={{ color: "blue" }} />
                                    : <Icon type="down" style={{ color: "blue" }} />}
                            </div>
                        </div>
                        }
                            key={company?.key}
                            style={this.state.activeKeys.some(el => el === company?.key) ? { border: "0" } : null}
                            showArrow={false}>
                            <div className="compFunctionalRow">
                                <div className="row">
                                    <div className='col-xs-8 col-xs-offset-3 col-sm-4 col-sm-offset-8 col-md-3 col-md-offset-9 col-lg-2 col-lg-offset-10 aligntextClass' >
                                        {company?.activeStatus ? this.onActiveCompany(company) :
                                            <div className="aligntextClass">
                                                <button disabled={this.props.roles.toLowerCase() === 'customer support' ? true : false} type="button" style={{ fontSize: '16px', right: '20%' }} className="button-createtrip" onClick={() => this.enableCompany(company)}><b>{this.props.app_labels.enableCompany}</b></button>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <RenderColumnComponent
                                company={company}
                                referenceData={this.state.referenceData}
                                uploadPolicy={this.uploadPolicy}
                                app_labels={this.props.app_labels}
                                custCodeMandatory={this.state.custCodeMandatory}
                                instaDetails={this.state.instaDetails}
                                // panValue={this.state.panValue}
                                formFieldRef={this.state.formFieldRef}
                                allServiceInterfaces={this.state.allServiceInterfaces}
                                addNewData={this.addNewData}
                                handler={formFieldRef => this.handler(formFieldRef)}
                                handleCompanyListChange={obj => this.handleCompanyListChange(obj)}
                                clickedClose={this.state.clickedClose}
                                closedReset={() => this.closedReset()}
                                roles={this.props.roles}
                            />
                        </Panel>)
                    })}
                </Collapse>:<><br/><br/><br/><br/><br/><br/></>}
            </div>
        )
    }
}

function mapStateToProps(state, props) {
    return {
        token: state.authenticationReducer.token,
        userDetails: state.authenticationReducer.userDetails,
    };
}
export default connect(mapStateToProps)(CompSetupComponent);