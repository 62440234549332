import React from 'react';
import { Card } from 'antd';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import MediaQuery from 'react-responsive';
import '../../styling/carousel.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { connect } from 'react-redux';

const { Meta } = Card;
function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div>
            <MediaQuery minDeviceWidth={800}>
                <div
                    className={`${className} custom-next-arrow`}
                    style={{ ...style, display: "block", background: "#f2f2f2", marginRight: "0%" }}
                    onClick={onClick}
                >
                    <i className="icon-Drop-down"></i> 
                </div>
            </MediaQuery>
            <MediaQuery maxDeviceWidth={530} minDeviceWidth={480}>
                <div
                    className={`${className} custom-next-arrow`}
                    style={{ ...style, display: "block", background: "#f2f2f2", marginRight: "-6%" }}
                    onClick={onClick}
                >
                 <i className="icon-Drop-down"></i>
                </div>
            </MediaQuery>
            <MediaQuery maxDeviceWidth={479}>
                <div
                    className={`${className} custom-next-arrow`}
                    style={{ ...style, display: "block", background: "#f2f2f2", marginRight: "0%" }}
                    onClick={onClick}
                >
                 <i className="icon-Drop-down"></i>
                </div>
            </MediaQuery>
            <MediaQuery maxDeviceWidth={799} minDeviceWidth={531}>
                <div
                    className={`${className} custom-next-arrow`}
                    style={{ ...style, display: "block", background: "#f2f2f2", marginRight: "%" }}
                    onClick={onClick}
                > 
                <i className="icon-Drop-down"></i>
                </div>
            </MediaQuery>
        </div>
    );
}
function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div>
            <MediaQuery minDeviceWidth={800}>
                <div
                    className={`${className} custom-prev-arrow`}
                    style={{ ...style, display: "block", background: "#f2f2f2", marginRight: "0%" }}
                    onClick={onClick}
                >
                    <i className="icon-Drop-down"></i> 
                </div>
            </MediaQuery>
            <MediaQuery maxDeviceWidth={799}>
                <div
                    className={`${className} custom-prev-arrow`}
                    style={{ ...style, display: "block", background: "#f2f2f2" }}
                    onClick={onClick}
                >
                <i className="icon-Drop-down"></i>
                </div>
            </MediaQuery>

        </div>
    );
}


class AdminCarousel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            index_carousel: null,
            allCards: [{
                title: 'Create User Profile',
                labelKeys: this.props.app_labels.createUserProfile,
                className: 'icon-Create-Trip-Profile card-cover-class'
            }, {
                title: 'View and Edit Profiles',
                labelKeys: this.props.app_labels.viewAndEditProfile,
                className: 'icon-View-and-edit-Profile card-cover-class'
            }, {
                title: 'Active Cards',
                labelKeys: this.props.app_labels.activeCards,
                className: 'icon-Card-Settings card-cover-class'
            }, {
                title: 'Card Settings',
                className: 'icon-Card-Settings card-cover-class'
            }, {
                title: 'Receipt Rules',
                className: 'icon-Recept-Rules card-cover-class'
            }, {
                title: 'Company Setup',
                className: 'icon-Company_Setup card-cover-class'
            }, {
                title: 'Reports',
                className: 'icon-Reports card-cover-class'
            }, {
                title: 'Expense Mapping',
                className: 'icon-Expence-Mapping card-cover-class'
            }, {
                title: 'Delegation',
                className: 'iconSpecial icon-Delegation-Black card-cover-class'
            }, {
                title: 'Batch Job',
                className: 'icon-Create-Trip-Profile card-cover-class'
            }, {
                title: 'Settings',
                labelKeys: this.props.app_labels.settings,
                className: 'profile-Setting card-cover-class'
            }, {
                title: 'App Version',
                className: 'iconSpecial icon-Delegation-Black card-cover-class'
            }]
        }
    }
    /* 
    componentDidMount() {
        this.setState({ index_carousel: this.props.index })
    } */

    componentDidUpdate(prevProps){
        if(prevProps.app_labels !== this.props.app_labels){
            let allCards =  [{
                title: 'Create User Profile',
                labelKeys: this.props.app_labels.createUserProfile,
                className: 'icon-Create-Trip-Profile card-cover-class'
            }, {
                title: 'View and Edit Profiles',
                labelKeys: this.props.app_labels.viewAndEditProfile,
                className: 'icon-View-and-edit-Profile card-cover-class'
            }, {
                title: 'Active Cards',
                labelKeys: this.props.app_labels.activeCards,
                className: 'icon-Card-Settings card-cover-class'
            }, {
                title: 'Card Settings',
                className: 'icon-Card-Settings card-cover-class'
            }, {
                title: 'Receipt Rules',
                className: 'icon-Recept-Rules card-cover-class'
            }, {
                title: 'Company Setup',
                className: 'icon-Company_Setup card-cover-class'
            }, {
                title: 'Reports',
                className: 'icon-Reports card-cover-class'
            }, {
                title: 'Expense Mapping',
                className: 'icon-Expence-Mapping card-cover-class'
            }, {
                title: 'Delegation',
                className: 'iconSpecial icon-Delegation-Black card-cover-class'
            }, {
                title: 'Batch Job',
                className: 'icon-Create-Trip-Profile card-cover-class'
            }, {
                title: 'Settings',
                labelKeys: this.props.app_labels.settings,
                className: 'profile-Setting card-cover-class'
            }, {
                title: 'App Version',
                className: 'iconSpecial icon-Delegation-Black card-cover-class'
            }];

            this.setState({allCards});
        }
    }

    slideChange = (index) => {
        this.props.selectedItems(index);
    }

    /**
     * Checks if the card is valid
     * @param {*} card 
     */
    isCardValid(card) {
        if(this.props.userrole === "Customer Support"){
            if (card.title === 'Card Settings' || card.title === 'Reports'
            || card.title === 'Create User Profile' || card.title === 'Receipt Rules' 
            || card.title === 'Delegation' || card.title === 'Expense Mapping'
            || card.title === 'Batch Job') {
                return false
            }
        }
        else if (card.title === 'Card Settings'
            || card.title === 'Company Setup'
            //|| card.title === 'Expense Mapping'
            //|| card.title === 'Batch Job'
            || card.title === 'App Version') {
                if (this.props.userrole !== "Super Admin"  ) {
                    return false
                }
        }
        return true
    }

    /**
     * checks if the card is valid for superadmin
     * @param {*} card 
     */
    isCardValidSuperAdmin(card) {
        if(this.props.userrole === "Customer Support"){
            if (card.title === 'Card Settings'|| card.title === 'Create User Profile'
            || card.title === 'Receipt Rules' || card.title === 'Delegation' || card.title === 'Reports' 
            || card.title === 'Expense Mapping'|| card.title === 'Batch Job') {
                return false
            }
        }
        else if (card.title === 'Card Settings') {
                if (this.props.userrole === "Super Admin") {
                    return false
                }
        }
        return true
    }

    /**
     * checks if the card is valid for card company admin
     * @param {*} card 
     */
    isCardValidCardCompanyAdmin(card) {
        if (card.title === 'Settings'
            || card.title === 'Create User Profile' || card.title === 'Active Cards'
            || card.title === 'View and Edit Profiles') {
            return true
        }
        return false
    }


    /**
     * Defines rendering of cards based on validity of the card
     * @param {*} current 
     */
    renderCards(current) {
        let result = []
        this.state.allCards.forEach((card) => {
            if (this.props.userrole === "Card Company Admin") {
                if (this.isCardValidCardCompanyAdmin(card)) {
                    let index = result.length
                    result.push(
                        <div key={index + 1}>
                            <Card tabIndex={0} role="button" className={`card-class ${this.props.selectedLanguage === 'FR'? 'french-option '+card.title: ''}`} bordered={true} 
                                onClick={(e) => {
                                    console.log('slide', index)
                                    current.slider.slickGoTo(index)
                                    this.slideChange(index + 1);
                                }}
                                onKeyDown={(e) => {
                                    if(e.keyCode === 13){
                                        console.log('slide', index)
                                        current.slider.slickGoTo(index)
                                        this.slideChange(index + 1);
                                    }
                                }}
                                cover={ card.title === 'Settings' ? <img src={require('../../images/setting-svg.svg').default} className='setting-card' aria-hidden="true" 
                                style={{height: '48px', marginTop: '5%', marginBottom: '-3%'}} /> :
                                <i aria-hidden="true" role="img" className={card.className} />}>
                                <Meta title={card.labelKeys} />
                            </Card>
                        </div>
                    )
                }
            } else {
            if (this.isCardValid(card) && this.isCardValidSuperAdmin(card)) {
                let index = result.length
                result.push(
                    <div key={index + 1}>
                        <Card className="card-class" bordered={true} onClick={(e) => {
                            console.log('slide', index)
                            current.slider.slickGoTo(index)
                            this.slideChange(index + 1);
                        }
                        }
                            cover={ card.title === 'Settings' ? <img src={require('../../images/setting-svg.svg').default} className='setting-card' aria-hidden="true" 
                            style={{height: '48px', marginTop: '5%', marginBottom: '-3%'}} /> :
                            <i aria-hidden="true" role="img" className={card.className} />}>
                            <Meta title={card.title} />
                        </Card>
                    </div>
                )
            }
            }
        })
        return result
    }
    render() {
        const current = this;
        const settings = {
            dots: false,
            className: "center",
            initialSlide: this.props.initialSlide,
            infinite: this.props.userrole === "Card Company Admin"? false: true,
            centerMode: this.props.userrole === "Card Company Admin"? false : true,
            centerPadding: "0px",
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 1,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />,
            /*  afterChange: function (index) {
                 //current.slideChange(current.state.index_carousel);
                 console.log(
                     `Slider Changed to: ${index + 1}`
                 );
             }, */
            responsive: [
                {
                    breakpoint: 1026,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: false
                    }
                },
                {
                    breakpoint: 1025,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        //initialSlide: 2
                    }
                },
                {
                    breakpoint: 700,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        //initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        centerMode: false,
                        focusOnSelect: true,
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        return (
            <div className="test slider-section">
                <Slider style={{ marginRight: "4%" }} ref={slider => (current.slider = slider)} {...settings}>
                    {
                        this.renderCards(current)
                    }
                </Slider>
            </div >
        );
    }

}

function mapStateToProps(state, props) {
    return {
        userDetails: state.authenticationReducer.userDetails,
        token: state.authenticationReducer.token,
        app_labels: state.adduserReducer.app_labels,
        theme: state.authenticationReducer.theme,
        selectedLanguage: state.adduserReducer.selectedLanguage,
    };
}
export default connect(mapStateToProps)(AdminCarousel);