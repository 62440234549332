import React from 'react';
import { connect } from 'react-redux';
import { Select,Table, Spin, Radio, Modal, notification, DatePicker } from 'antd';
import { loaderFocus } from '../../utils/accessability';
import moment from 'moment';
import $ from 'jquery';
import '../../styling/delegation.css';
import { delegateAccess, getActiveHm } from '../../actions/delegateAction';
const RadioGroup = Radio.Group;
const Option = Select.Option;
const confirm = Modal.confirm;
const { errorMessages } = require('../../data/constants.js');
// const customStyles_dropdown = {
//     option: (provided, state) => ({
//         ...provided,
//         color: "#636466 !important",
//         fontFamily: 'U.S.BankCircularWeb-Regular !important',
//     }),
//     control: (base, state) => ({
//         ...base,
//         fontFamily: 'U.S.BankCircularWeb-Regular !important',
//         fontSize: '22px !important',
//         color: '#636466 !important',
//         border: "0 !important",
//         boxShadow: "0 !important",
//         "&:hover": {
//             border: "0 !important",
//             borderBottom: "1px solid lightgrey !important"
//         },
//         borderBottom: "1px solid lightgrey !important"
//     }),
//     singleValue: (provided, state) => {
//         return { ...provided };
//     }
// }

class DelegationDashBoard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            delegateTo: undefined,
            selectedDate: null,
            radiovalue: "T",
            hiringManagerList: [],
            spinFlag: false,
            delegatedToObj: [],
        }
        this.baseState= this.state
    }
    async componentDidMount() {
        this.props.getActiveHiringManager(this.props.userDetails.companyId)
        this.calendarIconFix()
    }
    componentDidUpdate(prevProps, prevState) {
        if (!(prevState.radiovalue === "T") && this.state.radiovalue === "T") {
            this.calendarIconFix()
        }
    }

    /**
     * Sets the value based on the slected value
     */
    onRadioChange = (e) => {
        this.setState({
            radiovalue: e.target.value,
        });
    }
    clearInvalidField(element) {
        let label = $(element).siblings('.text-class1')
        label = label.length === 0 ? $(element).find('.text-class1') : label
        label = label.length === 0 ? $(element).closest('.form-group').find('.text-class1') : label
        label.removeClass('has-error')
    }
    calendarIconFix() {
        let element = $('.anticon.anticon-calendar.ant-calendar-picker-icon')
        element.empty()
        element.removeClass().addClass('icon-Calendar')
    }
    /**Can not select days before today and today
    * @param  {} current
    */
    disabledDate = (current) => {
        return current && current < moment().startOf('day');
    }

    /** Handle the selected date  */
    handeldatechange = (e) => {
        let datePicker = document.getElementsByClassName('datePicker');
        this.clearInvalidField(datePicker)
        this.setState({ selectedDate: e._d.toString() });
    }

    /**
     * sets the delegateTo value based on selection
     */
    delegateToChange = (value) => {
        let delegateTo = document.getElementsByClassName('delegateTo');
        this.clearInvalidField(delegateTo)
        this.setState({ delegateTo: value });
    }

    /**
     * Validation of all the entered values
     */
    inputValidation = () => {
        const delegateTo = document.getElementsByClassName('delegateTo');
        const datePicker = document.getElementsByClassName('datePicker');
        [delegateTo,
            datePicker].forEach(el => this.clearInvalidField(el))
        if (this.props.delegateArray.length === 0) {
            notification.warning({
                message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Error </span>{errorMessages.error_delegationTripSelect}</span>,
                duration: 3,
                style: {
                    backgroundColor: '#fffbe6',
                    border: "1px solid #ffe58f",
                }
            });
            return false;
        }
        if (this.state.delegateTo === null || this.state.delegateTo === "" || this.state.delegateTo === undefined) {
            notification.warning({
                message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Error </span>{errorMessages.error_req_delegationTo}</span>,
                duration: 3,
                style: {
                    backgroundColor: '#fffbe6',
                    border: "1px solid #ffe58f",
                }
            });
            let label = $(delegateTo).closest('.text-class1')
            label.addClass('has-error');
            return false;
        }
        if ((this.state.selectedDate === null || this.state.selectedDate === "" || this.state.selectedDate === undefined) && this.state.radiovalue === "T") {
            notification.warning({
                message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Error </span>{errorMessages.error_req_delEndDate}</span>,
                duration: 3,
                style: {
                    backgroundColor: '#fffbe6',
                    border: "1px solid #ffe58f",
                }
            });
            let label = $(datePicker).find('.text-class1')
            label.addClass('has-error');
            return false;
        }
        return true

    }
    stateUpdation = (value) => {
        this.setState({ spinFlag: value })
    }

    /* cancels thedelegation of the trip */
    cancelDelegation = () => {
        this.setState(this.baseState)
        this.props.cancelDelegation(false)
    }

    /**
     * Saves the delgation and updates in the db
     */
    saveDelegate = () => {
        var current = this;
        let validationFlag = this.inputValidation();
        let newDelegateArray = this.props.delegateArray.slice();
        var tripId = [];
        if (validationFlag) {
            newDelegateArray.map((e1) => {
                tripId.push(e1.tripId)
                return [];
            })
        }
        else {
            return
        }
        confirm({
            title: <span className="cardHeading">{this.props.app_labels.delegate}  {newDelegateArray.length}  {this.props.app_labels.travelersTo} {this.state.delegatedToObj[0].firstName}  {this.state.delegatedToObj[0].lastName}?</span>,
            okText: 'Yes',
            cancelText: 'No',
            onOk() {
                current.stateUpdation(true);
                loaderFocus(".loader-color .ant-spin-text");
                let obj = {
                    "noOfIds": newDelegateArray.length,
                    "travelerId": current.props.userDetails.travelerId,
                    "tripId": tripId,
                    "delegateId": current.state.delegateTo,
                    "enddate": current.state.radiovalue === "P" ? new Date("December 31, 9999 11:13:00") : current.state.selectedDate,
                }
                current.props.delegation(obj, current.stateUpdation, current.cancelDelegation);

            },
            onCancel() { },
        });

        return;
    }
    render() {
        const columns = [
            {
                title: <span className='tableHeader'>{this.props.app_labels.firstName1}</span>,
                dataIndex: "firstName",
                align: "center",
                width: '20%',
                render: firstName => (
                    <div className='tableContentDelegation' style={{ marginLeft: "0%" }} >
                        <span >{firstName}</span>
                    </div >
                ),
            },
            {
                title: <span className='tableHeader'>{this.props.app_labels.lastName1}</span>,
                dataIndex: "lastName",
                align: "center",
                width: '20%',
                render: lastName => (
                    <div className='tableContentDelegation' style={{ marginLeft: "0%" }} >
                        <span >{lastName}</span>
                    </div>
                ),
            },
            {
                title: <span className='tableHeader'>{this.props.app_labels.travelerId}</span>,
                dataIndex: "cadreId",
                align: "center",
                width: '20%',
                render: cadreId => (
                    <div className='tableContentDelegation' style={{ marginLeft: "0%" }} >
                        <span >{cadreId}</span>
                    </div>
                ),
            },
            {
                title: <span className='tableHeader'>{this.props.app_labels.startDate}</span>,
                dataIndex: "estimatedStartDate",
                align: "center",
                width: '20%',
                render: estimatedStartDate => (
                    <div className='tableContentDelegation' style={{ marginLeft: "0%" }} >
                        <span >{estimatedStartDate}</span>
                    </div>
                ),
            },
            {
                title: <span className='tableHeader'>{this.props.app_labels.destination1}</span>,
                dataIndex: "destination",
                align: "center",
                width: '20%',
                render: destination => (
                    <div className='tableContentDelegation' style={{ marginLeft: "0%" }} >
                        <span >{destination}</span>
                    </div>
                ),
            },

        ];

        return (
            <div className="container-fluid">
                <div className='action-filte-title' style={{ paddingLeft: "3%", paddingTop: "2%", color: '#636466' }}>{this.props.app_labels.delegateTravelers}</div>
                <div className="dash-content-box pending-actions">
                    <div style={{ overflow: "auto" }}>
                    <Table className='delegationTablecclass'
                        size="middle"
                        columns={columns}
                        scroll={{ x: 930, y: 411 }}
                        locale={{ emptyText: this.props.app_labels.clickTraveler }}
                        dataSource={this.props.delegateArray}
                        pagination={false}
                    />
                    </div>
                </div>
                {this.state.spinFlag ? <div className="dark-spinner loader-color"><Spin tip="Uploading..." /></div> : null}
                <div className="dash-content-box">
                    <div className="row">
                        <div className="col-sm-4 col-sm-offset-1 cardsetrule" >
                            <span className="text-class1">{this.props.app_labels.delegateTo}<span className="required"> *</span></span><br /><br />
                            <div aria-label="Delegate to" role="list" >
                            {/* <Select className='delegateTo' value={this.state.delegateTo}
                                    onChange={this.delegateToChange}
                                    styles={customStyles_dropdown}
                                    isSearchable={false}
                                    classNamePrefix="react-select"
                                    placeholder={this.props.app_labels.selectHM}
                                    options={
                                        this.props.hiringManagerList.filter(opt =>
                                            this.props.userDetails.travelerId !== opt.travelerId).map(item => {

                                                return (
                                                    { value: item.travelerId, label: item.firstName + " " + item.lastName }
                                                )

                                            })
                                    }
                                    theme={theme => ({
                                        ...theme,
                                        borderRadius: 0,
                                        colors: {
                                            ...theme.colors,
                                            primary25: "#e6f7ff",
                                            primary: "lightgrey"
                                        }
                                    })} /> */}
                                    <Select className='delegateTo' value={this.state.delegateTo}
                                        onChange={this.delegateToChange}
                                        disabled={this.state.disableFlag}
                                        style={{ width: '100%' }}
                                        placeholder="Select HM">
                                        {this.props.hiringManagerList.map(item =>
                                            <Option value={item.travelerId}>{item.firstName} {item.lastName}</Option>
                                        )}
                                    </Select>
                            </div>
                        </div>
                        <div className="col-sm-6 col-sm-offset-1  cardsetrule" >
                            <div className="form-group">
                            <span className="text-class1">Duration<span className="required"> *</span></span><br /><br />
                                <RadioGroup size="large" onChange={this.onRadioChange}
                                    value={this.state.radiovalue}>
                                    <Radio value={"T"}><span className="text-class">{this.props.app_labels.delegateTemporarily}</span></Radio>
                                    <Radio value={"P"}><span className="text-class">{this.props.app_labels.delegatePermanently}</span></Radio>
                                </RadioGroup>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {this.state.radiovalue === "T" ? <div style={{ backgroundColor: 'transparent' }} className="col-sm-4 col-sm-offset-1 delegatecontainer cardsetrule">
                            <span className="text-class1"> {this.props.app_labels.untilTheEndOf}<span className="required"> *</span></span><br /><br />
                            <DatePicker
                                placeholder={this.props.app_labels.selectDate}
                                className="datePicker"
                                allowClear={false}
                                style={{ width: "100%" }}
                                format="MM/DD/YYYY"
                                disabledDate={this.disabledDate}
                                onChange={(e) => {
                                    this.handeldatechange(e)
                                }}
                            />
                        </div> : null}
                    </div>
                </div>
                <div className='button-div-box'>
                    <div className="row">
                        <div className="col-sm-4 col-sm-offset-0 col-md-4 col-md-offset-0 " style={{ textAlign: 'left', marginTop: '36px' }}>
                            <button className="button-createtrip-ghost width-class button-wrapclass buttonBottomPadding" onClick={this.cancelDelegation} ><b>{this.props.app_labels.createCardCancel}</b></button>
                        </div>
                        <div className="col-sm-4 col-sm-offset-4 col-md-4 col-md-offset-4" style={{ textAlign: 'right' }} >
                            <button className="button-createtrip button-wrapclass buttonBottomPadding" onClick={this.saveDelegate} disabled={this.props.delegateArray.length === 0} ><b>{this.props.app_labels.delegate}</b></button>
                        </div>
                    </div>
                </div>
            </div>
        )

    }

}
function mapStateToProps(state, props) {
    return {
        token: state.authenticationReducer.token,
        userDetails: state.authenticationReducer.userDetails,
        hiringManagerList: state.getuserActionsReducer.activeHiringManagerList,
        app_labels: state.adduserReducer.app_labels,
    };
}
function mapDispatchToProps(dispatch) {
    return {
        delegation: (obj, stateUpdation, cancelDelegation) => {
            dispatch(delegateAccess(obj, stateUpdation, cancelDelegation));
        },
        getActiveHiringManager: (companyId) => {
            dispatch(getActiveHm(companyId))

        }

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(DelegationDashBoard);