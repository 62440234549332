import initialState from './initialState';

export default function (state = initialState, action) {
  switch (action.type) {
    case 'RESET_TRANSACTION_DETAILS':
      return {
        ...state,
        csrTransactionIsFetching: false,
        csrTransactionData: null,
        csrTransactionError: null,
        csrTransactionDetailsUpdateError: null,
        csrTransactionDetailsUpdateSuccess: false,
        csrTransactionDetailsIsUpdating: false,
      }
    case 'FETCH_TRANSACTION_DETAILS_REQUEST':
      return {
        ...state,
        csrTransactionIsFetching: true,
        csrTransactionDetailsUpdateError: null,
        csrTransactionDetailsUpdateSuccess: false
      }
    case 'FETCH_TRANSACTION_DETAILS_SUCCESS':
      return {
        ...state,
        csrTransactionIsFetching: false,
        csrTransactionError: null,
        csrTransactionData: action.payload
      }
    case 'FETCH_TRANSACTION_DETAILS_FAILURE':
      return {
        ...state,
        csrTransactionIsFetching: false,
        csrTransactionError: action.error,
        csrTransactionData: null
      }
    case 'UPDATE_TRANSACTION_DETAILS_REQUEST':
      return {
        ...state,
        csrTransactionDetailsIsUpdating: true,
        csrTransactionDetailsUpdateError: null,
        csrTransactionDetailsUpdateSuccess: false
      }
    case 'UPDATE_TRANSACTION_DETAILS_SUCCESS':
      return {
        ...state,
        csrTransactionDetailsIsUpdating: false,
        csrTransactionDetailsUpdateError: null,
        csrTransactionDetailsUpdateSuccess: true
      }
    case 'UPDATE_TRANSACTION_DETAILS_FAILURE':
      return {
        ...state,
        csrTransactionDetailsIsUpdating: false,
        csrTransactionDetailsUpdateError: action.error,
        csrTransactionDetailsUpdateSuccess: false
      }
    default: return state
  }
}