import actionTypes from '../data/actionTypes'
import { refreshToken, setRefreshTokenTimer } from '../actions/authenticationActions'
const REFRESH_TIME_MARGIN = 180

export default ({ dispatch, getState }) => next => action => {
    // check if this is the proper action to schedule the next refresh
    if (action.type === actionTypes.LOGIN_SUCCESS || action.type === actionTypes.REFRESH_TOKEN_SUCCESS) {
        let {
            token,
            tokenTimestamp,
            refreshTokenTimer
        } = getState().authenticationReducer;
        // calculate the next scheduled refresh time
        let elapsedTimeSinceTokenAcquisition = (Date.now() / 1000) - tokenTimestamp

        let nextRefreshTime = elapsedTimeSinceTokenAcquisition > (token.expires_in - REFRESH_TIME_MARGIN)
            ? 0
            : (tokenTimestamp + token.expires_in - REFRESH_TIME_MARGIN - (Date.now() / 1000)) 
        
        // clear any existing timer
        clearTimeout(refreshTokenTimer)
        let timeOutId = setTimeout(() => {
            dispatch(refreshToken())
        }, nextRefreshTime * 1000)
        // save the current timer
        dispatch(setRefreshTokenTimer(timeOutId))
    }
    return next(action)
}