export function phoneNumberFormat(value) {
    var input = value
    input = input.replace(/\D/g,'')
    input = input.substring(0,10)
    var size = input.length
    if(size < 4) input = input
    else if(size < 7) input = input.substring(0,3)+' '+input.substring(3,6)
    else input = input.substring(0,3)+' '+input.substring(3,6)+' '+input.substring(6,10)
    return input
}

export function viewPhoneNumber(value) {
    let digits = value.replace(/\D/g,'')
    let last10 = digits.substr(-10)
    let last10Position = digits.search(last10)
    let countryCode = digits.substr(0, last10Position)

    let input = `${countryCode} ${last10}`

    let number = input.trim().toString().split(' ')[1]
        ? '+' + (input.trim().toString().split(' ')[0] + ' (' + input.trim().toString().split(' ')[1].slice(0, 3) + ') ' + input.trim().toString().split(' ')[1].slice(3, 6) + '-' + input.trim().toString().split(' ')[1].slice(6))
        : '('+(input.trim().toString().split(' ')[0].slice(0, 3) + ') ' + input.trim().toString().split(' ')[0].slice(3, 6) + '-' + input.trim().toString().split(' ')[0].slice(6))
    return number
}
