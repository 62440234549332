import React from 'react';
import { connect } from 'react-redux';
import $ from 'jquery';
import axios from 'axios';
import { notification, AutoComplete, Input } from 'antd';
const { Search } = Input
const Option = AutoComplete.Option;
const { URLs, errorMessages } = require('../../data/constants.js');

class AccountingCodeAutosuggest extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      results: [],
      searchQuery: null,
      page: 1,
      count: 0,
      loadmore: false,
      msg: ""
    }

    // Create refs
    this.inputField = React.createRef()
  }

  /**
   * @param  {} value
   * search depending upon the entered string 
   */
  handleSearch(value) {
    if (value) {
      this.setState({ searchQuery: value});
      axios.get(`${URLs.comUrl}allocation/search?searchTerm=${value}&pageNum=${this.state.count}`, {
        responseType: 'json',
        headers: {
          "Authorization": 'Bearer ' + this.props.token.access_token
        }
      }).then(response => {
        if(response.data && response.data.serviceStatus.statusCode === 200) {
          this.setState({ results: response.data.allocationDetails.allocations, msg: null })
          if(this.state.page < response.data.allocationDetails.meta.totalPages) {
            this.setState({ 
              page: this.state.page + 1, 
              count: this.state.count + 100, 
              loadmore: true
            })
          }
        }
        if(response.data && response.data.serviceStatus.statusCode === 204) {
          this.setState({ results: [], msg: response.data.serviceStatus.message })
        }
        this.props.notFound(this.state.msg)
      }).catch((err) => {
        notification.warning({
          message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Error </span>{errorMessages.generic_error}</span>,
          duration: 3,
          style: {
            backgroundColor: '#fffbe6',
            border: "1px solid #ffe58f",
          }
        });
      })
    }
  }

  /**
   * @param  {} result
   * rendering the autosuggestion dropdown
   */
  renderResult(result, index) {
    return <Option key={result.allocationId} value={result.allocationId}>
      <div>
        <b>{`${result.allocationName}, ${result.allocationId}`}</b>
      </div>
    </Option>
  }

  /**
   * handles loading of accounting code
   * @param {*} element 
   */
  handleLoadmore(element) {
    // let loadObj =  {allocationName: "Loading..."}
    let scrollTopTemp = $(element).scrollTop()
    let scrollHeightTemp = $(element)[0].scrollHeight;
    let scrollinnerHeightTemp = $(element).innerHeight();

    if (scrollTopTemp + scrollinnerHeightTemp >= scrollHeightTemp){
      if (this.state.loadmore) {
        this.setState({loadmore: false});
        axios.get(`${URLs.comUrl}allocation/search?searchTerm=${this.state.searchQuery}&pageNum=${this.state.count}`, {
          responseType: 'json',
          headers: {
            "Authorization": 'Bearer ' + this.props.token.access_token
          }
        }).then(response => {
          if(response.data && response.data.serviceStatus.statusCode === 200) {
            this.setState({ results: response.data.allocationDetails.allocations });
            if(this.state.page < response.data.allocationDetails.meta.totalPages) {
              this.setState({ 
                page: this.state.page + 1, 
                count: this.state.count + 100,
                loadmore: true
              })
            }
          }
        }).catch((err) => {
          notification.warning({
            message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Error </span>{this.props.app_labels.couldNotcompleteReq}</span>,
            duration: 3,
            style: {
              backgroundColor: '#fffbe6',
              border: "1px solid #ffe58f",
            }
          });
        })
      }
    }
  }

  render() {
    return (
      <div onScroll={() => this.handleLoadmore('.ant-select-dropdown-menu-root')}>
        <AutoComplete
          backfill={true}
          dataSource={this.state.results.map(this.renderResult.bind(this))}
          disabled={this.props.disableFlag || this.props.newDisableType}
          onChange={(value) => {
            this.props.clearInvalidField()
            this.props.handleAccountingCodeInput1(value)
          }}
          onSelect={(value) => {
            this.props.clearInvalidField()
            this.props.handleAccountingCodeInput(value, this.state.results)
          }}
          optionLabelProp="value"
          dropdownMatchSelectWidth={false}
        /* value={this.props.value} */
        >
          <Search
            placeholder={this.props.app_labels.enterAccCode}
            aria-label='Accounting Code'
            autocomplete="off"
            ref={(ele) => this.props.setInputRef(ele)}
            className={this.props.className || 'airport-autocomplete-input'}
            onKeyPress={(event) => {
              if (event.key.replace(/[^\w\-.]/g, '') === '') event.preventDefault();
            }}
            onSearch={value => this.handleSearch(value) } />
        </AutoComplete>
      </div>
    )
  }
}

function mapStateToProps(state, props) {
  return {
    token: state.authenticationReducer.token,
  };
}

export default connect(mapStateToProps)(AccountingCodeAutosuggest);