import React, { useState } from 'react';
import { Form, Input, Tooltip, AutoComplete, Dropdown, Select } from 'antd'
import moment from 'moment-timezone';
import { schema } from './validationSchema'
import { data } from '../phoneData';
// import WarningSign from "../../images/bulk/WarningSign@2x.png"
import WarningSign from "../../images/bulk/warningIconNew.svg"
var dateFormat = require('dateformat');
const FormItem = Form.Item;
const { Option } = Select;

const EditableContext = React.createContext();
export const EditableRow = ({ form, index, ...props }) => (
  <EditableContext.Provider value={form}>
    <tr {...props} />
  </EditableContext.Provider>
);

let values = Object.values(data);

export const EditableFormRow = Form.create()(EditableRow);

export class EditableCell extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      tooltipVisible: false,
      ariaDescribedBy: null,
      isToolTipVisible:false
    }
  }

  getDropdown = () => {
    let option = [];    
    for (let i = 0; i < values.length; i++) {
      option.push(<option tabindex={0} aria-label={values[i].code} value={values[i].code}>{values[i].code}</option>);
    }
    return option;
  }

  handleChange = (event) => {
    const keyName = this.props.dataIndex;
    const obj = this.props.record;
    if(obj.mobileNumber){
     if(obj.mobileNumber.indexOf("@")> -1){
      let mNumber = obj.mobileNumber.split('@');
      obj.mobileNumber = mNumber[1];  
     }
    }
    obj[keyName] = event.target.value;
    this.props.handleSave(obj);
  }

  handleDrop = (e) => {
    const obj = this.props.record;
    if(obj.mobileNumber){
      if(obj.mobileNumber.indexOf("@")> -1){
       let mNumber = obj.mobileNumber.split('@');
       obj.mobileNumber = mNumber[1];  
      }
     }
    obj['cCode'] = e;
    this.props.handleSave(obj);
  }

  handleAutoComplete = (keyName) => {
    switch (keyName) {
      case 'name':
        return 'given-name'
      case 'lastName':
        return 'family-name'
      case 'emailId':
        return 'email'
      case 'mobileNumber':
        return 'tel-local'
      default:
      return false;
    }

  }

  getInput = (keyName) => {
    let validFromDisabled = (keyName === 'validFrom' || keyName === 'status') ? true : false;    
    if (keyName === 'cCode') {
      // let list = this.getDropdown();      
      return (
        <select aria-label="Select country code" 
           style={{
            border: "1px solid #666666",
            borderRadius: "4px"             
          }}
          onChange={(e) => { this.handleDrop(e.target.value) }}
            >{this.getDropdown()}
        </select>
      )
    }
    if(keyName === 'field1' || keyName === 'field2' || keyName === 'notes' ){
      return <Input aria-describedby={this.state.ariaDescribedBy} tabindex={0} aria-label={keyName} onPressEnter={this.handleChange} onChange={this.handleChange} onKeyup={this.handleChange} onMouseLeave={this.handleChange} maxLength={50}/>;
    }
    if(keyName === 'employeeId' ){
      return <Input aria-describedby={this.state.ariaDescribedBy} tabindex={0} aria-label="Employee Id" onPressEnter={this.handleChange} onChange={this.handleChange} onKeyup={this.handleChange} onMouseLeave={this.handleChange} maxLength={50}/>;
    }
    else {
      return <Input required aria-describedby={this.state.ariaDescribedBy} tabindex={0} disabled={validFromDisabled} aria-label={keyName === "name" ? "first name" : keyName} autoComplete = {this.handleAutoComplete(keyName)} onPressEnter={this.handleChange} onChange={this.handleChange} onKeyup={this.handleChange} onMouseLeave={this.handleChange} />;
    }

  };

  checkValidityRule = (record, cardValidityRule) => {
    let fromDate = moment(dateFormat(record['validFrom'], "mm/dd/yyyy"), 'MM/DD/YYYYY');
    let toDate = moment(dateFormat(record['validTill'], "mm/dd/yyyy"), 'MM/DD/YYYYY');
    let days = toDate.diff(fromDate, 'days');
    return (days <= cardValidityRule && Math.sign(days) !== -1) ? true : false;
  }

  checkCardValidityDates = (record) => {
    let today = new Date(moment(new Date()).tz('America/Chicago').format('MM/DD/YYYY'));
    let date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

    // let fromDate = moment(record['validFrom']);
    // let toDateFrom = moment(date);
    // let fromDays = toDateFrom.diff(fromDate, 'days');
    // let isValidFromDate = fromDays > 0 ? false : true;

    let tillDate = moment(record['validTill']);
    let toDateTill = moment(date);
    let tillDays = toDateTill.diff(tillDate, 'days');
    let isValidTillDate = tillDays > 0 ? false : true

    let fromDateChk = moment(record['validFrom']);
    let tillDateChk = moment(record['validTill']);
    let bothDaysDiff = tillDateChk.diff(fromDateChk, 'days');
    let isBothValid = bothDaysDiff > 0 && bothDaysDiff <= 365 ? true : false;
    // if (isValidFromDate && isValidTillDate && isBothValid)
    if (isValidTillDate && isBothValid)
      return true;
    else
      return false;
  }

  getCellData = (keyName, value, creditLimitRule, cardValidityRule, record, approvalEnabled) => {
    if(this.props.activeStatusTab === '4'){
      return <div >{value}</div>;
    }
    else{  
      if (keyName !== undefined) {
        let passedVal = schema[keyName](value[2]);
        if (passedVal) {
          if (keyName === 'creditLimit' && value[2] !== null && isNaN(value[2]))
            return <div><Tooltip title={this.props.label_keys.pleaseValidNo}
            trigger={['hover', 'focus']} mouseEnterDelay={0}
            onVisibleChange={() => {this.setState({tooltipVisible : !this.state.tooltipVisible,isToolTipVisible : !this.state.isToolTipVisible})}}
            visible={this.state.isToolTipVisible}
            id={`tooltip-creditLimit-error-${record.id}`}>
              
              <div  role="button" tabindex={0}  
              aria-describedby={this.state.tooltipVisible ?`tooltip-creditLimit-error-${record.id}` : null} onKeyDown={(event) => {
                if (event.key === "Escape") {
                  this.setState({ isToolTipVisible: false })
                }
              }}>
                <img  
                  alt="Error" src={WarningSign} 
                  style={{ height: '20px', width: '20px',marginRight :"2px" }}
                />
                {value}
              </div>
              </Tooltip></div>;
          else if ((keyName === 'validTill') && !this.checkCardValidityDates(record))
            return <div  > <Tooltip title={this.props.label_keys.tillDate} mouseEnterDelay={0}
            id={`tooltip-valid-untill-error-${record.id}`} trigger={['hover', 'focus']}
            visible={this.state.isToolTipVisible} onVisibleChange={() => {this.setState({tooltipVisible : !this.state.tooltipVisible,isToolTipVisible : !this.state.isToolTipVisible})}}>
              <div  role="button" tabindex={0} 
              aria-describedby={this.state.tooltipVisible ? `tooltip-valid-untill-error-${record.id}` : null} onKeyDown={(event) => {
                if (event.key === "Escape") {
                  this.setState({ isToolTipVisible: false })
                }
              }}>
                <img alt="Error" src={WarningSign}
              style={{ height: '20px', width: '20px' ,marginRight :"2px" }} />{value}
              </div></Tooltip></div>; 
          else
            return <div >{value}</div>;
        }
        else {
          //let message = this.props.label_keys.invalidData;
          let message;
          if (keyName === 'validTill' || keyName === 'validFrom')
            message = this.props.label_keys.pleaseDateFormat;
          if (keyName === 'name' || keyName === 'lastName')
            message = this.props.label_keys.invalidData;
          if (keyName === 'emailId')
            message = this.props.label_keys.pleaseValidEmail;
          if (keyName === 'creditLimit')
            message = this.props.label_keys.invalidCreditLimit;
          if (keyName === 'mobileNumber')
            message = this.props.label_keys.pleaseMobileNumber;
          if ( keyName === 'employeeId' || keyName === 'cCode' || keyName === 'notes' || keyName === 'field1' || keyName === 'field2')
            message = this.props.label_keys.invalidData;
            let amessage= keyName+" value is "+ message;
          return message && <div><Tooltip title={message}
          onVisibleChange={() => {this.setState({tooltipVisible : !this.state.tooltipVisible,isToolTipVisible : !this.state.isToolTipVisible})}}
          visible={this.state.isToolTipVisible}
          trigger={['hover', 'focus']} mouseEnterDelay={0}
          id={`tooltip-err-${keyName}-${record.id}`}>
            <div role="button" tabindex={0}
              aria-describedby={this.state.tooltipVisible ? `tooltip-err-${keyName}-${record.id}` : null} onKeyDown={(event) => {
                if (event.key === "Escape") {
                  this.setState({ isToolTipVisible: false })
                }
              }}>
            <img  alt="Error" 
            src={WarningSign} style={{ height: '20px', width: '20px' ,marginRight :"2px" }} />
            {value}</div></Tooltip></div>;
        }
      } else {
        return <div >{value}</div>;
      }
    }

  }

  validate = (rule, value, callback) => {
    if (this.props.dataIndex === 'name' || this.props.dataIndex === 'lastName') {
       let nm= value.replace(/^\s+|\s+$/g,'');

      if  (!(/^[^"]+$/.test(nm)) || nm.length >50){
        this.setState({ariaDescribedBy:`${this.props.dataIndex}-${this.props.id}` })
        callback(<span id={`${this.props.dataIndex}-${this.props.id}`} aria-label={this.props.label_keys.invalidData}>{this.props.label_keys.invalidData}</span>);        
    
      }else{
        this.setState({ariaDescribedBy: null })
      }
    }
    if (this.props.dataIndex === 'emailId') {
      if (!/^\w+([+.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,64})+$/.test(value)){
        this.setState({ariaDescribedBy:`${this.props.dataIndex}-${this.props.id}` })
        callback(<span id={`${this.props.dataIndex}-${this.props.id}`} aria-label={this.props.label_keys.invalidEmail1}>{this.props.label_keys.invalidEmail1}</span>);
    
      }else{
        this.setState({ariaDescribedBy:null })
      }
    }
    if (this.props.dataIndex === 'creditLimit') {
      if (isNaN(value) || value === ''){
        this.setState({ariaDescribedBy:`${this.props.dataIndex}-${this.props.id}` })
        callback(<span id={`${this.props.dataIndex}-${this.props.id}`} aria-label={this.props.label_keys.invalidData}>{this.props.label_keys.invalidData}</span>);
    
      }else{
        this.setState({ariaDescribedBy:null })
      }
    }
    if (this.props.dataIndex === 'cCode') {
      let values = Object.values(data);
      let countryCodesList = values.map(x => x.code);
      if (!countryCodesList.includes(value))
        callback(<span id={`${this.props.dataIndex}-${this.props.id}`} aria-label={this.props.label_keys.invalidData}>{this.props.label_keys.invalidData}</span>);
    }

    if (this.props.dataIndex === 'mobileNumber') {
      if (!/^\d{10}$/.test(value)){
        this.setState({ariaDescribedBy:`${this.props.dataIndex}-${this.props.id}` })
        callback(<span id={`${this.props.dataIndex}-${this.props.id}`} aria-label={this.props.label_keys.invalidData}>{this.props.label_keys.invalidData}</span>);
      }else{
        this.setState({ariaDescribedBy:null })
      }  
    }
    if (this.props.dataIndex === 'employeeId') {
      if (!/^.{0,50}$/.test(value) ||  value.length > 50) {
        this.setState({ariaDescribedBy:`${this.props.dataIndex}-${this.props.id}` })
        callback(<span id={`${this.props.dataIndex}-${this.props.id}`} aria-label={this.props.label_keys.invalidData}>{this.props.label_keys.invalidData}</span>);
    
      }else{
        this.setState({ariaDescribedBy:null })
      }
    }
    if (this.props.dataIndex === 'validFrom' || this.props.dataIndex === 'validTill') {
      if (!/^\d{4}-\d{2}-\d{2}$/.test(value)){ //yyyyy-mm-dd
        this.setState({ariaDescribedBy:`${this.props.dataIndex}-${this.props.id}` })
        callback(<span id={`${this.props.dataIndex}-${this.props.id}`} aria-label={this.props.label_keys.invalidData}>{this.props.label_keys.invalidData}</span>);
      }else{
        this.setState({ariaDescribedBy:null })
      }
    }
    if (this.props.dataIndex === 'notes' || this.props.dataIndex === 'field1' || this.props.dataIndex === 'field2') {
      if(value != '' && value.length>50) {
        this.setState({ariaDescribedBy:`${this.props.dataIndex}-${this.props.id}` })   
       callback(<span id={`${this.props.dataIndex}-${this.props.id}`} aria-label={this.props.label_keys.invalidData}>{this.props.label_keys.invalidData}</span>);
    //   if (!/^[0-9A-Za-z ]{2,60}$/.test(value) || value === undefined)
    //     callback(this.props.label_keys.invalidData);
      }else{
        this.setState({ariaDescribedBy:null })
      }
    }
  }
  render() {
    const {
      editing,
      dataIndex,
      title,
      inputType,
      record,
      index,
      creditLimitRule,
      cardValidityRule,
      approvalEnabled,
      ...restProps
    } = this.props;
    return (
      <EditableContext.Consumer>
        {(form) => {
          const { getFieldDecorator } = form;
          return (
            <td {...restProps}>
              {editing ? (
                <FormItem style={{ margin: 0 }}>
                  {getFieldDecorator(dataIndex, {
                    rules: [{
                      required: true,
                      validator: this.validate
                    }],
                    initialValue: record[dataIndex],
                  })(this.getInput(dataIndex, record))}
                </FormItem>
              ) : this.getCellData(dataIndex, restProps.children, creditLimitRule, cardValidityRule, record, approvalEnabled)}
            </td>
          );
        }}
      </EditableContext.Consumer>
    );
  }

}
