const app_labels = {
    /**********create trip labels***********/
    department: 'Department',
    role: "Role",
    typeofTraveler: "Type of Traveler",
    firstName: "Traveler First Name",
    lastName: "Traveler Last Name",
    emailId: "Email ID",
    mobileNum: "Mobile Number",
    altmobileNum: "Alternate Mobile Number",
    travelerId: "Traveler ID",
    source: "Travel Origin",
    destination: "Travel Destination",
    estimatedTravelDate: "Estimated Travel Dates",
    accountingCode: "Accounting Code",
    entityTypeID1: "Entity Type ID 1",
    entityTypeID2: "Entity Type ID 2",
    selectAirnHotel: "Select Air and Hotel as needed",
    cardLimit: "Credit Limit",
    mileageRate: "Mileage Rate",
    cardValidityTerms: "Card validity terms",
    creditamtRange: "Credit Amount Range",
    validityPeriod: "Validity period(in days)",
    cardNeeded: "Create Expense Wizard card",
    cardNotNeeded: "Expense Wizard card not needed",
    saveDraft: "Save draft",
    createCard: "Create cards",
    SavenContinue: "Save and continue",
    createTripHeading: "Enter New Trip Information",
    createTripProgressBarHeading: "Create Profile and Assign Card",
    cardDetails: "Card Details",
    cancelTrip: "Cancel trip",
    confirmCancelTrip: "Do you want to Cancel this Trip?",
    flightCheckbox: "Flight",
    hotelCheckbox: "hotel",
    cardAssignedTrip: "This trip has a card assigned.",
    cardInfo: "There are no open transactions on the card, however, login to the mobile app and ensure to close any transactions.",
    cancelTripConfirmation: "You may proceed in canceling the trip, do you want to?",
    uploadItinerary: "Upload Itinerary",
    sendInfoToTraveller: "Send info to traveler",
    validThru: "VALID THRU",
    cvv: "CVV",
    viewCardDetails: "View card details",
    hideCardDetails: "Hide Card Details",
    travelDetails: "Travel Details",
    flightItinerary: "Flight Itinerary",
    pendingUpload: "Pending Upload",
    hotelReservations: "Hotel Reservations",
    overallTripJourney: "Overall Trip Journey",
    actualOrigin: "Actual Origin",
    actualDestination: "Actual Destination",
    actualTripDates: "Actual Trip Dates",
    confirmTripDetails: "Confirm",
    save: "Save",
    sendToTraveler: "Send to traveler",
    travelerBooksTickets: "Traveler books tickets",
    confirmCreateCard: "Do You Want To Create Card?",
    createCardWarning: "(Card once generated cannot be undone)",
    createCardCancel: "Cancel",
    createCardConfirm: "Confirm",
    dragnDropFlight: "Drag and drop flight itinerary",
    clickToBrowse: "Click to browse and upload manually",
    dragnDropHotel: "Drag and drop hotel reservation",

    /*****************Dashboard labels*********/
    dashboard: "DASHBOARD",
    delegation: "DELEGATION",
    allTrips: "All Trips",
    sortbyDate: "Sort By Date",
    filteByAllStatus: "Filter By All Status",
    travelDate: "Travel Date",
    destination1: "Destination",
    actionFilters: "Action Filters",
    pendingActions: "Pending Actions",
    createNewTrip: "Create new trip",
    delegateTravelers: "Delegate Travelers",
    selectAll: "Select All",
    tripsSelected: "Trip(s) selected",
    confirmCloseTrip: "Do you want to Close this Trip?",
    confirmDeleteFileAll: "Do you want to delete all file?",
    confirmDeleteFileOne: "Do you want to delete this file?",
    cardExpiration: "Card Expiration",
    cardDeactivation: "Card Deactivation",
    selectCompanyName: "Select Company Name",
    policyDocument: "Policy Document",
    fromDate: "From Date",
    toDate: "To Date",
    hotelDetails: "Hotel Details",
    tripDetails: "Trip details",
    mobileNo: "Mobile No",
    alternateNo: "Alternate No",
    presentCardLimit: "Present card limit",
    delegatedFrom: "This trip has been delegated from",
    cardStatus: "Card Status",
    notSpecified: "Card details not specified",
    virtualCard: "Virtual Card Assigned",
    flightDetails: "Flight Details",
    origin: "Origin",
    location: "Location",
    continue: "Continue",
    firstName1: "First Name",
    lastName1: "Last Name",
    startDate: "Travel Start Date",
    endDate: "Travel End Date",
    delegateTo: "Delegate to",
    delegateTemporarily: "Delegate Temporarily",
    delegatePermanently: "Delegate Permanently",
    untilTheEndOf: "Until the end of",
    delegate: "Delegate",
    travelersTo: "Traveler(s) to",
    byAllStatus: "By All status",
    name: "Name",
    byAllAction: "By All Action",
    cards: "Cards",
    toBeAssigned: "to be Assigned",
    pending: "Pending",
    itineraryUpload: "Itinerary Upload",
    travelers: "Travelers",
    toBeNotified: "to be Notified",
    tripClosure: "Trip Closure",


    /**************reports**********/
    reportHeading: "Reports Input",
    reportHeader: "Admin report links",
    startDateHeader: "Start date",
    endDateHeader: "End date",
    report1: "Manager’s and Employee’s with cancelled trips",
    report2: "Travelers using EXPENSE WIZARD card for Personal Expenses",
    report3: "Travelers with 'Info Awaited' transactions",
    report4: "Budget Spend for month of 'Dec 2018'(month year) for Company US Bank",
    report5: "Trips with pending closure",

    /**************admin Screen label**********/
    uploadExcel: "UPLOAD EXCEL",
    downloadTemplate: "DOWNLOAD TEMPLATE",
    dragnDropFile: "Drag and Drop Excel file",
    cancelCapital: "CANCEL",
    uploadCapital: "UPLOAD",
    saveCapital: "SAVE",
    expenseWizardId: "Expense Wizard ID",
    jobRole: "Select job role",
    companyName: "Company Name",
    reportTo: "Report to",
    createMore: "+ CREATE MORE",
    applicableCompanyNames: "Applicable Company Names",
    expenseWizardCategory: "Expense Wizard Category",
    crExpenseCategory: "CR Expense Category",
    formFlags: "Form Flags",
    form1: "Form1",
    form2: "Form2",
    form3: "Form3",
    form4: "Form4",
    form5: "Form5",
    na: "NA",
    typeOfField: "Type of field",
    question: "Question",
    questionApplicable: "Question Applicable",
    reset: "RESET",
    userDefineFields: "User Define Fields",
    profileDetails: "Profile Details",
    assignRole: "Assign Role",
    deleteTrip: "Delete profile",
    enableProfile: "Enable Profile",
    enableCompany: "Enable Company",
    mobile: "Mobile",
    filterBy: "Filter By",
    company: "Company",
    roles: "Roles",
    reset1: "Reset",
    back: "BACK",
    sortByName: "Sort By Name",
    cardSettings: "Card Settings",
    appVersion: "App Version",
    mileageSettings: "Mileage Settings",
    settings: "Settings",
    configWarning: "This config changes will apply to all the companies.",
    expirationRule: "Set Card Expiration Rule",
    deactivateRule: "Set Card Deactivate Rule",
    daysFromTravel: "Days from Travel End Date",
    companySetup: "Company Setup",
    serviceInterface: "Service Interface",
    addNewCompanyData: "+ Add New Company Data",
    disabled: "DISABLED",
    companyShortName: "Company short name",
    phoneNumber: "Phone number",
    billingAddress: "Billing Address",
    // pan: "PAN",
    companyAcctId: "Account ID",
    customerCode: "Customer Code",
    uploadPolicyDoc: "Upload policy document",
    delegation1: "Delegation",
    delegateFrom: "Delegate from",
    more: "+ More",
    askForReceipt: "Ask for Receipt Upload at",
    amountCriteria: "When amount equal or greater than",
    receiptRequired: "Receipt Required",
    receiptRule: "Receipt Rules",
    defineNewReceiptRule: "+ Define New Receipt rule",
    dragnDropPolicy: "Drag and drop Policy document",
    instantCard: 'Instant Card',
    nonMandatory: 'Non-mandatory Attributes',
    country: 'Country',
    state: 'State',
    city: 'City',
    flat: 'Flat',
    streetAddress: 'Street Address',
    zipcode: 'Zipcode',
    swichToggle: 'Switch on toggle to make it mandatory',

    /**************auth component label**********/
    resetPassword: "Reset Password",
    multiFactor: "Multi-factor Authentication",
    pleaseClick: "Please click",
    here: "here",
    toTryAgain: "to try again.",
    passwordResetInfo: "Your password has been reset. Please",
    login: "login",
    passwordResetInfo1: "with your new password",

    /**************form component label**********/
    submit: "Submit",
    logout: "Logout",
    loggingIn: "Logging in",
    login1: "Login",
    forgotPassword: "Forgot password?",
    sendOtp: "Send OTP",
    reseting: "Resetting",


    /***************Create trip placeholders */
    selectTravelerType: "Select Traveler type",
    selectTraveleDepartment: "Select traveler Department",
    selectRole: 'Select Traveler Role',
    selectFirstName: "Enter Traveler First Name",
    selectLastName: "Enter Traveler Last Name",
    selectEmail: 'Enter traveler email',
    SelectPh: "Enter 10 digit number",
    selectID: "Enter traveler Id",
    selectOrigin: "Enter traveler Origin",
    selectDestination: "Enter traveler Destination",
    from: "From",
    to: "To",
    enterAccCode: "Enter Accounting Code",
    selectEntity1: "Select Entity Type 1",
    selectEntity2: "Select Entity Type 2",


    /***********error messages**********/
    generic_error: "Couldn't complete your request",
    error_userCreation: "Couldn't create new user",
    error_cardCreation: "Couldn't retrieve card details",
    error_cardLimit: "Couldn't retrieve card limit",
    error_pendingAction: "Couldn't retrieve pending action trip details",
    error_userProfile: "Couldn't create use profile. Please try again.",
    error_uploadExcel: "Upload Excel file",
    error_fetchCompany: "Couldn't retrieve company",
    error_fetchVersion: "Couldn't retrieve version",
    error_req_expenseWizardId: "Expense Wizard ID required",
    error_req_reportToId: "Report to : Expense Wizard ID required",
    error_req_jobRole: "Select job role",
    error_req_emailId: "Email address required",
    error_req_phnum: "Phone number required",
    error_req_companyName: "Company name required",
    error_req_firstName: "First name required",
    error_req_lasttName: "Last name required",
    error_req_editName: "Enter both first and last names",
    error_req_country: "Select country",
    error_req_state: "Select state",
    error_req_city: "Select city",
    error_req_streetAddress: "Select streetAddress",
    error_req_zipCode: "Select zipCode",
    error_companyAddress:"Couldn't retrieve company address details",
    error_req_company: "Select company",
    error_req_expCat: "Expense category field mandatory",
    error_req_crExpCat: "CR expense category field mandatory",
    error_req_transactionType: "Transaction type field mandatory",
    error_req_userDefinedField: "User defined field mandatory",
    error_req_question: "Question field mandatory",
    error_req_compShortName: "Company short name required",
    error_req_compNumber: "Company contact number required",
    error_req_compPan: "Company PAN required",
    error_val_compPan: "Please enter 16 digit pan number",
    error_val_compNumber: "Enter valid phone number",
    error_val_compShortName: "Company short name should have 2 to 6 characters",
    error_req_delegationFrom: "Please select from",
    error_req_delegationTo: "Please select to",
    error_req_delEndDate: "Please select date",
    error_addingNewRule: "Error adding new rule",
    error_req_ruleCondition: "Rule condition required",
    error_addExpCat: "Error adding new expense category",
    error_updateProfile: "Failed to update user profile",
    error_deleteProfile: "Failed to delete user profile",
    error_enableProfile: "Failed to enable user profile",
    error_setCardDetails: "Set card details for selected company",
    error_incorrectEmail: "Incorrect email address",
    error_fetchCandidateId: "Couldn't retrieve candidate ID",
    error_invalidPhno: "Invalid phone number",
    //create trip messages//
    error_val_cardLimit: "Card limit can not be 0 or empty",
    error_req_travelerType: "Traveler type required",
    error_req_travelerName: "Traveler name required",
    error_req_travelerLastName: "Traveler Last Name required",
    error_req_travelerId: "Traveler ID required",
    error_req_origin: "Travel origin required",
    error_req_destination: "Travel destination required",
    error_req_travelDate: "Enter start and end trip",
    error_req_actualOrigin: "Actual origin required",
    error_req_actualDestination: "Actual destination required",
    error_req_actualTripDates: "Actual trip dates required",
    error_onlyPdf: "Upload PDF file",
    error_fileSize: "File size can't be more than 5mb",
    success_uploadCompl: "Upload completed",
    error_noFileFound: "No file found",
    error_selectTravelerType: "Select traveler type",
    error_travelerDetails: "Couldn't retrieve traveler details",
    error_noProfileFound: "No profiles found",
    error_noTripsFound: "No trips found",
    error_searching: "Searching error",
    error_delegationTripSelect: "Select trips",
    error_req_chromeRiverCustCd: "Customer code required",
    error_val_chromeRiverCustCd: "Customer code cant be more than 10 characters",
    error_req_accountingcode: "Accounting code required",
    error_req_entityID1: "Entity Type ID1 required",
    error_req_entityID2: "Entity Type ID2 required",
    error_accountingcode: "Accounting code can't be more than 20 characters",
    confirm_errorMessage: "Please check this box to confirm that you have verified the accuracy of the trip start and end dates",
    //Login messages//
    otpVerificationGenericErrorMessage: "Couldn't verify one-time password",
    otpSendingGenericErrorMessage: "'Couldn't send one-time password",
    forgotPasswordGenericErrorMessage: "Forgot password error",
    sendOtpSuccessMessage: "A one-time password (OTP) has been sent to your registered email address or mobile number. Please enter it below to reset your password.",
    mfaInfoMessage: "A one-time password (OTP) has been sent to your registered email address or mobile number. Please enter it below continue.",
    loginGenericErrorMessage: "Login failed",
    usernamePrompt: "Enter username",
    passwordPrompt: "Enter password",
    messageTypePrompt: "'Enter message type",
    otpTypeMessage: "Enter one-time password",
    selectMessageType: "Select message type",
    confirmPasswordPrompt: "Confirm password",
    credentialsStrengthError: "Password doesn't meet strength requirements",
    error_val_otp: "One-time password should be a 6 digit number",  // corrected otp validation message for 6 didit input
    fetchCSRTransactionDetailsGenericErrorMessage: "Couldn't retrieve transaction details",
    updateCSRTransactionDetailsGenericErrorMessage: "Unable to update transaction details",
    unableToResetPasswordErrorMessage: "Unable to reset password.",
    messageOtpPrompt: "A one-time password (OTP) will be sent to your registered email address or mobile number.",
    startDategreater: "End date should be greater than start date.",
    noDateSelected: "Select start and end date.",
    /**tool tip texts**/
    sendtoTravelerOnhover: "This will be enabled when the Hotel/Flight itineraries are uploaded if the Hotel/Flight option is selected and the Confirm Trip Details is checked OR if the “Traveler books tickets” option is checked",
    travelerBooksTicketsToolTip: "Check this box if traveler is booking the flight and/or hotel using the card. The rest of the create trip process of confirming the trip dates will be skipped and passed on to the traveler.",
    /**batch job error messages */
    selectJob: "Please select job role",

    /*********Trip status */
    PendingComplaince: 'Pending Complaince',
    Saved: 'Saved',
    AwaitingTravel: 'Awaiting Travel',
    InProgress: 'In Progress',
    PendingExpenseReporting: 'Pending Expense Reporting',
    Complete: 'Complete',
    ArchivedTrip: 'Archived',
    Cancelled: 'Cancelled',
    PendingClosure: 'Pending Closure',
    Closed: 'Closed',
    Upcoming: 'Upcoming',

    /***********userActions descriptions */
    uploadflightItinerary: "Upload Flight Itinerary",
    uploadhotelItinerary: "Upload Hotel Reservation",
    travelerNotified: "Traveler to be Notified",
    assignCreditCard: "Assign Card",

    /****delegation dashboard placeholders */
    clickTraveler:"Click on traveler profile(s) to add them towards delegation",
    selectHM: "Select HM",
    selectDate: "Select Date"

}

module.exports = {
    app_labels,
} 
