import axios from 'axios';
import { notification } from 'antd';
import React from 'react';
const { URLs, errorMessages } = require('../data/constants.js');
function getUserAction(data) {
    return {
        type: "USER_ACTION",
        data
    }
}

export function fetchingUserActionDbCompletes(data) {
    return {
        type: "IS_DB_USRACTION_FETCHING",
        data
    }
}

export function userDeleteSuccess(data){
    return{
        type:"USER_DELETE_SUCCESS",
        data
    }
}

export function userDeleteError(data){
    return{
        type:"USER_DELETE_ERROR",
        data
    }
}

export function resetUserDelete(){
    return{
        type:"RESET_USER_DELETE"
    }
}

/**
 * Axios request to fetch the user actions
 */
export function getUserActions() {
    return (dispatch, getState) => {
        let {
            token,
        } = getState().authenticationReducer;
        dispatch(fetchingUserActionDbCompletes(true));

        axios.get(URLs.tripUrl + 'getUserActions'/*?buster=' + new Date().getTime()*/, {
            responseType: 'json',
            headers: {
                "Authorization": 'Bearer ' + token.access_token
            }
        }).then(result => {
            dispatch(fetchingUserActionDbCompletes(false));
            if (result.data.serviceStatus.statusCode === 210) {
                notification.error({
                    message: <span  role='alert' aria-live="assertive" aria-atomic="true" ><span className="visually-hidden">Error </span>{result.data.serviceStatus.message}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }
            else if (result.data.serviceStatus.statusCode === 200) {
                dispatch(getUserAction(result.data));
            }
            else {
                notification.error({
                    message: <span  role='alert' aria-live="assertive" aria-atomic="true" ><span className="visually-hidden">Error </span>{result.data.serviceStatus.message}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }
        }).catch((error) => {
            dispatch(fetchingUserActionDbCompletes(false));
            if (error.response !== undefined && error.response.data !== null && error.response.data !== undefined && error.response.data.serviceStatus !== undefined) {
                notification.error({
                    message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{error.response.data.serviceStatus.message}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }
            else {
                notification.error({
                    message: <span  role='alert' aria-live="assertive" aria-atomic="true" ><span className="visually-hidden">Error </span>{errorMessages.generic_error}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }
        })
    }
}

export function deleteUserAction(email) {
    return function (dispatch, getState) {
    //    dispatch(userDeleteSuccess({data: {"statusCode":200,"message":"User Deleted Successfully"}}))
        let {
            token,
        } = getState().authenticationReducer
        return axios.post(URLs.deleteUserUrl, { "email":email}, {
            headers: {
                "Authorization": 'Bearer ' + token.access_token
            }
        })
        .then((response) => dispatch(userDeleteSuccess(response)))
        .catch((err) => { 
            dispatch(userDeleteError(err.response))
            //console.log(err)
        
        })
    }
}
