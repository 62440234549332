import axios from 'axios';
import React from 'react';
import { notification } from 'antd';
const { URLs,errorMessages } = require('../data/constants.js')
function getOneTrip(data) {
    return {
        type: "GET__ONETRIP",
        data
    }
}
export function fetchingCompletes(data) {
    return {
        type: "IS_FETCHING",
        data
    }
}

/**
 * uses axios request to fetch a particular trip based on TripID
 * @param {*} tripID 
 */
export function getParticularTrip(tripID) {
    return (dispatch, getState) => {
        let {
            token
        } = getState().authenticationReducer;

        axios.get(URLs.tripUrl + 'getTripDetails/' + tripID, {
            responseType: 'json',
            headers: {
                "Authorization": 'Bearer ' + token.access_token
            }
        }).then(result => {
            if (result.data.serviceStatus.statusCode === 210) {
                notification.error({
                    message: <span  role='alert' aria-live="assertive" aria-atomic="true" ><span className="visually-hidden">Error </span>{result.data.serviceStatus.message}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
                dispatch(fetchingCompletes(false));
            }
            else if (result.data.serviceStatus.statusCode === 200) {
                var resultarray = [];
                resultarray.push(result.data.tripDetails);
                dispatch(getOneTrip(resultarray));
                dispatch(fetchingCompletes(false));

            }
            else {
                dispatch(fetchingCompletes(false));
                notification.error({
                    message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{result.data.serviceStatus.message}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }
        }).catch((error) => {
            dispatch(fetchingCompletes(false));
            if (error.response !== undefined && error.response.data !== null && error.response.data !== undefined && error.response.data.serviceStatus !== undefined) {
                notification.error({
                    message: <span  role='alert' aria-live="assertive" aria-atomic="true" ><span className="visually-hidden">Error </span>{error.response.data.serviceStatus.message}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }
            else {
                notification.error({
                    message: <span  role='alert' aria-live="assertive" aria-atomic="true" ><span className="visually-hidden">Error </span>{errorMessages.generic_error}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }
        })
    }
}
