import React, { Component } from "react";
import ReactDOM from 'react-dom';
import { connect } from 'react-redux'
import axios from 'axios'
import { Row, Col, Icon, Button, notification, Drawer, Input, Modal, Result, Spin } from 'antd'
import { loaderFocus} from '../../utils/accessability';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import {TextField} from '@mui/material';
import moment from 'moment-timezone'
import CardPreview from './CardPreview'
import PhoneCode from '../phonecode'
import { phoneNumberFormat } from './phoneNumberFormat'
import isApproveBtn from '../../utils/approveButtonValidator';
import '../../styling/otpselect.css';
const { URLs } = require('../../data/constants.js')
var dateFormat = require('dateformat')
const { TextArea } = Input;
const todayDate = moment(new Date()).tz('America/Chicago').format('MM/DD/YYYY')

class CreateCard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            hover: false,
            startDate: todayDate,
            enddate: moment(this.getDate(), 'MM/DD/YYYY'),
            cardLimit: 1,
            field1: '',
            field2: '',
            notes: '',
            firstName: '',
            lastNme: '',
            employeeID: '',
            emailID: '',
            phoneNumber: '',
            phoneNumberMasked: '',
            error: null,
            previewVisible: false,
            createDetails: null,
            phoneCode: '+1',
            reqId: null,
            approvalButton: false,
            modalVisible: false,
            reqNo: '',
            disableBtn: false,
            datepickerOpen: false,
            focusOnClose: true,
            previousActiveElement: null
        }

    }

    closeIconItem = React.createRef();

    componentDidMount() {
        this.props.userDetails.singleCardApproval && this.setState({ approvalButton: true })
        this.setState({ enddate: moment(this.getDate(), 'MM/DD/YYYY') })
        this.approvalButton()

        
    }

    componentDidUpdate(){

        if (this.state.modalVisible) {
            setTimeout(() => {
                const modal = document.querySelector('.ant-modal-root .ant-modal-wrap');
                const modalDiv = document.querySelector('.ant-modal.sent-for-approval-modal');
                // const closeDiv = document.querySelector('.ant-modal-close');
                // const div = document.getElementById("closeBtn")
                if (modal) {
                    modal.setAttribute('aria-label', "Send for Approval")
                    modal.focus();
                }
                if(modalDiv){
                    modalDiv.setAttribute('role', 'dialog');
                }
                // if (div) {
                //     div.setAttribute("tabindex", "0")
                // }
            },100)
        }
        if(this.props.createVisible && this.state.focusOnClose){
            if (this.props.previousActiveElement && !this.state.previousActiveElement){
                this.setState({previousActiveElement: this.props.previousActiveElement})
            }
            setTimeout(() => {
                const cls = document.getElementById("closeIconItem");
                // if (cls) cls.focus()
                this.setState({ focusOnClose: false})
            }, 100);
        }
    }

    handleFocusCloseModal = () => {
        if(this.state.modalVisible) {
            document.addEventListener('keydown', (e) => {
                if (e.keyCode === 13) {
                    this.handleCloseModal()
                }

                // if (e.key === "Tab" || e.key === "Shift") {
                //     const cls = document.getElementById("closeBtn")
                //     if (cls) {
                //         setTimeout(() => {
                //             cls.focus()
                //         }, 100) 
                //     }
                // }
            })
        }
    }

    handleCloseModal = () => {
        const btn = this.state.previousActiveElement;
        if (document.body.contains(btn)) btn.focus()
        this.setState({modalVisible: false})
    }

    approvalButton() {
        let Approvalobj = []
        let obj = {

            'validFrom': this.state.startDate,
            'validTill': this.state.enddate,
            'creditLimit': this.state.cardLimit,
        }
        Approvalobj.push(obj)
        let approvalThresholds = this.props.userDetails.approvalThresholds;
        let approvalFlowEnabled = this.props.userDetails.singleCardApproval;

        if (approvalFlowEnabled) {
            if (isApproveBtn(approvalThresholds, this.props.userDetails.cardLimitRule, this.props.userDetails.cardValidityRule, Approvalobj)) {
                this.setState({ approvalButton: true })
            }
            else {
                this.setState({ approvalButton: false })
            }
        } else {
            this.setState({ approvalButton: false })
        }
    }

    getDate() {
        let date = new Date(todayDate)
        date.setDate(date.getDate() + 7)
        return date
    }

    disableDate(current) {
        let startDate = new Date(todayDate)
        if (startDate) return current <= moment(startDate).startOf('day')
        else return true
    }


    createCrad() {
        this.setState({ disableBtn: true, })
        loaderFocus(".loader-color .ant-spin-text"); 
        let error = []

        let nameReg = /^[^"]+$/;
        if (nameReg.test(this.state.firstName) === false ) error.firstName = true
        if (nameReg.test(this.state.lastNme) === false ) error.lastNme = true

        let numberReg = /^\d+$/;
        if (numberReg.test(this.state.cardLimit) === false || this.state.cardLimit < 1) error.cardLimit = true

        let emailRegExp = /^\w+([+.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,64})+$/
        if (emailRegExp.test(this.state.emailID) === false) error.emailID = true

        if (this.state.emailID === this.props.userDetails.email) error.emailID1 = true

        if (this.state.phoneNumberMasked.length < 12) error.phoneNumber = true
        let phnNumber = this.state.phoneNumberMasked.split(' ').join('')

        
        this.setState({ error })

        if (error && !error.cardLimit && !error.emailID && !error.phoneNumber && !error.emailID1 && !error.firstName && !error.lastNme) {
            let obj = {
                cardData: {
                    validFrom: todayDate,
                    validTo: dateFormat(this.state.enddate, 'mm/dd/yyyy'),
                    cardLimit: this.state.cardLimit,
                    field1: this.state.field1,
                    field2: this.state.field2,
                    notes: this.state.notes
                },
                userData: {
                    employeeId: this.state.employeeID,
                    userEmail: this.state.emailID,
                    firstName: this.state.firstName,
                    lastName: this.state.lastNme,
                    mobileNumber: `${this.state.phoneCode} ${phnNumber}`
                }
            }

            axios.post(URLs.cardUrl + '/create', obj, {
                responseType: 'json',
                headers: {
                    "Authorization": 'Bearer ' + this.props.token.access_token,
                    "Content-Type": 'application/json',
                }
            }).then(response => {
                this.setState({ disableBtn: false })
                if (response.data.serviceStatus.statusCode === 211 && response.data.serviceStatus.message === "User Role Restricted") {
                    notification.error({
                        message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>
                        {this.props.label_keys.userRoleRestrictedMsg}</span>,
                        duration: 3,
                        style: {
                            backgroundColor: '#fff1f0',
                            border: "1px solid #ffa39e",
                        }
                    });
                    document.getElementById('createCardBtn').focus();
                } else {
                    this.props.setFocus? this.props.onCLoseCreatePreview() :this.props.onCLoseCreate()
                    this.props.reloadParent()
                    this.setState({
                        createDetails: response.data.carddata,
                        previewVisible: true,
                        startDate: todayDate,
                        enddate: moment(this.getDate(), 'MM/DD/YYYY'),
                        cardLimit: 1,
                        field1: '',
                        field2: '',
                        notes: '',
                        firstName: '',
                        lastNme: '',
                        employeeID: '',
                        emailID: '',
                        phoneNumber: '',
                        phoneNumberMasked: '',
                        error: null,
                        phoneCode: '+1'
                    })
                    notification.success({
                        message: <span role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Success </span>
                        {this.props.label_keys.cardCreateSuccessMsg}</span>,
                        duration: 3,
                        style: {
                            backgroundColor: '#dff0d8',
                            color: "green",
                            border: "1px solid green",
                        }
                    })
                }

            }).catch(error => {
                this.setState({ disableBtn: false })
                notification.error({
                    message: <span role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>
                        {this.props.label_keys.couldNotcompleteReq}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                })
                
                document.getElementById('createCardBtn').focus();
            })
        }
        else{
            
            this.setState({ disableBtn: false })
            if(error.cardLimit)
                this.errorFocus("creditLimit");
            else if(error.firstName)
                this.errorFocus("firstName");
            else if(error.lastNme)
                this.errorFocus("lastName");
            else if(error.emailID || error.emailID1)
                this.errorFocus("email");
            else if(error.phoneNumber)
                this.errorFocus("phone");
        }
    }

    AddCardDataRequest = () => {
        try{
            let error = []

            let numberReg = /^\d+$/;
            if (numberReg.test(this.state.cardLimit) === false || this.state.cardLimit < 1) error.cardLimit = true

            let emailRegExp = /^\w+([+.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,64})+$/
            if (emailRegExp.test(this.state.emailID) === false) error.emailID = true

            if (this.state.phoneNumberMasked.length < 12) error.phoneNumber = true
            let phnNumber = this.state.phoneNumberMasked.split(' ').join('')

            this.setState({ error })

            if (error && !error.cardLimit && !error.emailID && !error.phoneNumber) {
                let obj = {
                    cardData: {
                        validFrom: todayDate,
                        validTill: dateFormat(this.state.enddate, 'mm/dd/yyyy'),
                        creditLimit: this.state.cardLimit,
                        field1: this.state.field1,
                        field2: this.state.field2,
                        notes: this.state.notes,
                        employeeId: this.state.employeeID,
                        emailId: this.state.emailID,
                        name: this.state.firstName,
                        lastName: this.state.lastNme,
                        mobileNumber: `${this.state.phoneCode} ${phnNumber}`,
                        isPassed: true
                    },

                }
                let bulkdataObj = {
                    requester_id: this.props.userDetails.userId,
                    provisioner_id: this.props.userDetails.userId,
                    company_id: this.props.userDetails.companyId,
                    type: 'Single',
                    cardDetails: [obj.cardData]
                };


                axios.post(URLs.cardUrl + '/addCardRequestData', bulkdataObj, {
                    responseType: 'json',
                    headers: {
                        "Authorization": 'Bearer ' + this.props.token.access_token
                    }
                }).then(result => {
                    if (result.data.serviceStatus.statusCode === 200) {
                        this.setState({ reqId: result.data.cardData_id })
                        this.sentForApproval()
                    }
                    else {

                        notification.error({
                            message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>
                                {this.props.label_keys.couldNotcompleteReq}</span>,
                            duration: 3,
                            style: {
                                backgroundColor: '#fff1f0',
                                border: "1px solid #ffa39e",
                            }
                        });
                        document.getElementById('approveBtn').focus();
                    }
                }).catch((error) => {
                    this.setState({ disableBtn: false })
                    if (error.response !== undefined && error.response.data !== null && error.response.data !== undefined && error.response.data.serviceStatus !== undefined) {

                        notification.error({
                            message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>
                                {this.props.label_keys.couldNotcompleteReq}</span>,
                            duration: 3,
                            style: {
                                backgroundColor: '#fff1f0',
                                border: "1px solid #ffa39e",
                            }
                        });
                        document.getElementById('approveBtn').focus();
                    }
                    else {
                        notification.error({
                            message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>
                                {this.props.label_keys.couldNotcompleteReq}</span>,
                            duration: 3,
                            style: {
                                backgroundColor: '#fff1f0',
                                border: "1px solid #ffa39e",
                            }
                        });
                        document.getElementById('approveBtn').focus();
                    }
                })
            }else{
                
                this.setState({ disableBtn: false })
                if(error.cardLimit)
                    this.errorFocus("creditLimit");
                else if(error.firstName)
                    this.errorFocus("firstName");
                else if(error.lastNme)
                    this.errorFocus("lastName");
                else if(error.emailID || error.emailID1)
                    this.errorFocus("email");
                else if(error.phoneNumber)
                    this.errorFocus("phone");
            }
        }catch(error){

            this.setState({ disableBtn: false })
            notification.error({
                message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>
                    {this.props.label_keys.couldNotcompleteReq}</span>,
                duration: 3,
                style: {
                    backgroundColor: '#fff1f0',
                    border: "1px solid #ffa39e",
                }
            });
            document.getElementById('approveBtn').focus();

        }
    }

    sentForApproval = () => {

        
        this.setState({ disableBtn: true, })
        loaderFocus(".loader-color .ant-spin-text"); 
        let request = {
            card_ids: [this.state.reqId]
        };
        axios.post(URLs.cardUrl + `/sendForCardApproval`, request, {
            responseType: 'json',
            headers: {
                "Authorization": 'Bearer ' + this.props.token.access_token
            }
        }).then(response => {
            if (response.data.serviceStatus.statusCode === 211 && response.data.serviceStatus.message === "User Role Restricted") {
            this.setState({ disableBtn: false, })
            notification.error({
                    message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>
                        {this.props.label_keys.userRoleRestrictedMsg}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            } else {
                this.setState({ modalVisible: true, reqNo: response.data.requestNo, disableBtn: false })
                this.onCLose()
                this.props.reloadParent()
            }
        }
        ).catch(error => {
            this.setState({ disableBtn: false, })
            notification.error({
                message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>
                {this.props.label_keys.couldNotcompleteReq}</span>,
                duration: 3,
                style: {
                    backgroundColor: '#fff1f0',
                    border: "1px solid #ffa39e",
                }
            })
        }
        )
    };


    onCLose() {
        this.setState({
            createDetails: null,
            previewVisible: false,
            startDate: todayDate,
            enddate: moment(this.getDate(), 'MM/DD/YYYY'),
            cardLimit: 1,
            field1: '',
            field2: '',
            notes: '',
            firstName: '',
            lastNme: '',
            employeeID: '',
            emailID: '',
            phoneNumber: '',
            phoneNumberMasked: '',
            error: null,
            phoneCode: '+1',
            datepickerOpen: false,
            focusOnClose: true,
        }, () => this.approvalButton())
        this.props.onCLoseCreate()
    }

    
        // Accessbility To make default focus on element.
    errorFocus = (item) => {        
            if (item === "creditLimit") {
                if (ReactDOM.findDOMNode(this.refs.creditLimitItem))
                    ReactDOM.findDOMNode(this.refs.creditLimitItem).focus();
            } else if (item === "firstName") {
                if (ReactDOM.findDOMNode(this.refs.firstNameItem))
                    ReactDOM.findDOMNode(this.refs.firstNameItem).focus();
            } else if(item === "lastName"){
                if (ReactDOM.findDOMNode(this.refs.lastNameItem))
                    ReactDOM.findDOMNode(this.refs.lastNameItem).focus();
            } else if (item === "email") {
                if (ReactDOM.findDOMNode(this.refs.emailItem))
                    ReactDOM.findDOMNode(this.refs.emailItem).focus();
            } else if(item === "phone"){
                if (ReactDOM.findDOMNode(this.refs.phoneItem))
                    ReactDOM.findDOMNode(this.refs.phoneItem).focus();
            }
        
    }

    // Accessbility To invoke click method on tab enter.
    onKeyDown = (event) => {
        if (event.key === "Tab" && !event.shiftKey) {
            setTimeout(() => {
                    document.getElementById("closeIconItem").focus();
            }, 100);
            
        }
    }


    render() {
        let disable = (this.state.disableBtn || this.state.startDate === '' || this.state.enddate === '' || this.state.cardLimit === '' || this.state.firstName === '' ||
            this.state.lastNme === '' || this.state.emailID === '' || this.state.phoneNumberMasked === '' || this.state.enddate === null) ?
            true : false

        return (
            <Drawer
                aria-labelledby="create_singleCard"
                role="dialog"
                width={700}
                closable={false}
                maskClosable={false}
                keyboard={this.state.datepickerOpen ? false : true}
                onClose={() => this.onCLose()}
                visible={this.props.createVisible}
                style={{display:this.props.createVisible ? "block": "none"}}
            >
                <div  className="create-head">
                    <span id="create_singleCard" role="heading" aria-level="2" >{this.props.label_keys.createCard} </span>
                    <Icon role="button" aria-label="Close" tabIndex={0} id="closeIconItem"
                    type="close-circle" 
                    onKeyDown={e => {
                        if (e.keyCode === 13) {
                            this.onCLose()
                        }
                        if(e.key === "Tab" && e.shiftKey){
                            if(disable){
                                setTimeout(() => {
                                    document.getElementById("phone-input").focus();
                                }, 100);
                            }else{
                                if(this.state.approvalButton){
                                    setTimeout(() => {
                                        document.getElementById("approveBtn").focus();
                                    }, 100);
                                }else{
                                    setTimeout(() => {
                                        document.getElementById("createCardBtn").focus();
                                    }, 100);
                                }
                            }
                        }
                    }}  onClick={() => this.onCLose()} />
                    <div></div>
                </div><br />
                {this.state.disableBtn ? <div className="dark-spinner loader-color"><Spin tip={this.props.label_keys.loading} /></div> : null}
                <div className="create-card-details">
                    <div role="heading" aria-level="3" className="create-card-details-head" >
                        {this.props.label_keys.cardDetails}<span aria-hidden="true">:</span>
                    </div><br />
                    <Row gutter={[16, {xs: 24, sm: 24, md: 24, lg: 24, xl: 12, xxl: 12}]}>
                        <Col xs={12} sm={12} md={12} lg={4} xl={4} xxl={4} className="create-card-details-body card-valid-from">
                            <div style={{ paddingBottom: '1.7%'}}>{this.state.startDate !== '' ? <span className="create-card-Placeholder">{this.props.label_keys.cardValidFrom}</span> : <span>&nbsp;</span>}</div>
                            <span className="disable-input credit-card-startDate" ><span className="visually-hidden">Read only </span>
                                {this.state.startDate}
                            </span>
                            <CalendarTodayOutlinedIcon className="calendar"/>
                            {/* <Icon  className="calendar" type="calendar" /> */}
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={4} xl={4} xxl={4} className="create-card-details-body">
                            <div >{this.state.enddate !== '' ? <span aria-labelledby="cardValidTo" className="create-card-Placeholder">{this.props.label_keys.cardValidTo} *</span> : <span>&nbsp;</span>}</div>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    className={`${(this.state.enddate !== '' || this.state.enddate !== null) && 'blue-border date-picker'}`}
                                    shouldDisableDate={(current) => this.disableDate(current)}
                                    disableHighlightToday
                                    components={{
                                        OpenPickerIcon: CalendarTodayOutlinedIcon,
                                    }}
                                    PopperProps={{
                                        className: "datepicker-desktop", "aria-label": "Choose Date"
                                      }}
                                    DialogProps={{
                                        className: "datepicker-mobile", "aria-label": "Choose Date"
                                      }}
                                    getOpenDialogAriaText={(date, utils) => `Card Valid To Choose date, selected date is ${utils.format(utils.date(date), 'fullDate')}`}    
                                    inputProps={{
                                        tabindex: "-1",
                                        readOnly: true,
                                        "aria-label":"card valid to"
                                    }}
                                    value={this.state.enddate}
                                    onOpen={() => {
                                        this.setState({datepickerOpen: true})
                                        setTimeout(() => {
                                            const val = document.getElementsByClassName('MuiCalendarPicker-root');
                                            console.log("MUI date picker:::", val);
                                            if(val.length > 0){
                                                
                                                console.log("MUI date picker first child:::", val[0].firstChild.getAttribute('class'));
                                                const val2 = document.getElementsByClassName(val[0].firstChild.getAttribute('class'));
                                                console.log("MUI date picker first child:::", val2);
                                                if(val2[0]){
                                                    console.log("MUI date picker first child's first child:::", val2[0].firstChild);
                                                    val2[0].firstChild.setAttribute('aria-live', 'assertive');
                                                    val2[0].firstChild.setAttribute('aria-atomic', 'true');
                                                }
                                            }
                                                
                                        }, 1000);
                                    
                                    }}
                                    onClose={() => this.setState({datepickerOpen: false})}
                                    onChange={e => this.setState({ enddate: e }, () => { this.approvalButton() })}
                                    renderInput={(params) => <TextField {...params}  variant="standard" 
                                    style={{borderColor: '#666666'}}
                                    color="primary"  focused 
                                    id="cardValidTo"
                                    className={`${(this.state.enddate !== '' || this.state.enddate !== null) && 'blue-border date-picker'}`} />} />
                            </LocalizationProvider>
                            {/* <div><span className="suggestion-text">7 days from the start date</span></div> */}
                        </Col>
                    </Row>
                    <Row>
                        <Col span={4} className="create-card-details-body">
                            <div>{this.state.cardLimit !== '' ? <span className={`${this.state.error && this.state.error.cardLimit === true && "error-text"} create-card-Placeholder`}>{this.props.label_keys.cardLimit} *</span> : <span> &nbsp;</span>}</div>
                            <Input required placeholder={`${this.props.label_keys.cardLimit} *`} 
                                ref={"creditLimitItem"}
                                type='number'
                                aria-label={this.props.label_keys.cardLimit}
                                aria-describedby={ this.state.error && this.state.error.cardLimit === true ? "card-limit-error" : null}
                                className={`${(this.state.error && this.state.error.cardLimit === true) ? 'error-border' : (this.state.cardLimit !== '' && 'blue-border')}`}
                                value={this.state.cardLimit}
                                style={{borderColor: '#666666'}}
                                onChange={e => this.setState({ cardLimit: e.target.value, error: '' }, () => { this.approvalButton() })} />
                            {this.state.cardLimit !== null && this.state.cardLimit !== '' && <label className="limit-lable">$</label>}
                            {this.state.error && this.state.error.cardLimit === true &&
                                <Icon aria-hidden="true" className="error-icon" type="warning" style={{ marginLeft: '85%' }} />}
                            {this.state.error && this.state.error.cardLimit === true &&
                                <div id="card-limit-error"><span className="visually-hidden">Error:</span>
                                <span  className="suggestion-text error-text">{this.props.label_keys.invalidCreditLimit}</span></div>}
                        </Col>
                        <Col span={4} className="create-card-details-body">
                            <div >{this.state.field1 !== '' ? <span className="create-card-Placeholder">{this.props.label_keys.field1}</span> : <span>&nbsp;</span>}</div>
                            <Input placeholder={this.props.label_keys.field1}
                                aria-label={this.props.label_keys.field1}
                                className={`${this.state.field1 !== '' && 'blue-border'}`}
                                maxLength={50}
                                style={{borderColor: '#666666'}}
                                value={this.state.field1}
                                onChange={e => this.setState({ field1: e.target.value })} />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={4} className="create-card-details-body">
                            <div >{this.state.field2 !== '' ? <span className="create-card-Placeholder">{this.props.label_keys.field2}</span> : <span>&nbsp;</span>}</div>
                            <TextArea placeholder={this.props.label_keys.field2}
                                aria-label={this.props.label_keys.field2}
                                rows={2}
                                className={`${this.state.field2 !== '' && 'blue-border'}`}
                                maxLength={50}
                                value={this.state.field2}
                                style={{borderColor: '#666666'}}
                                onChange={e => this.setState({ field2: e.target.value })} />
                        </Col>
                        <Col span={4} className="create-card-details-body">
                            <div >{this.state.notes !== '' ? <span className="create-card-Placeholder">{this.props.label_keys.notes}</span> : <span>&nbsp;</span>}</div>
                            <TextArea placeholder={this.props.label_keys.notes}
                                aria-label={this.props.label_keys.notes}
                                aria-describedby={this.state.notes.length === 0 ? "max-char no-of-char" : null}
                                rows={2}
                                className={`${this.state.notes !== '' && 'blue-border'}`}
                                maxLength={50}
                                value={this.state.notes}
                                style={{borderColor: '#666666'}}
                                onChange={e => this.setState({ notes: e.target.value })} />
                            <div >
                                <span id="max-char" className="suggestion-text create-card-Placeholder">{this.props.label_keys.maxCharacters}</span>
                                <span id="no-of-char" aria-live={`${this.state.notes.length%10 === 0 ? "assertive" :"off"}`} aria-atomic={true} className="suggestion-text" style={{ marginLeft: '81%'}}>
                                    {this.state.notes.length}/50 <span  className="visually-hidden"> Characters </span>
                                </span>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="create-holder-details">
                    <div role="heading" aria-level="3"  className="create-holder-details-head" >
                        {this.props.label_keys.cardHolderDetails} <span aria-hidden="true">:</span>
                    </div>
                    <Row>
                        <Col span={4} className="create-holder-details-body" >
                            <div >{this.state.firstName !== '' ? <span className={`${this.state.error && this.state.error.firstName === true  && "error-text"} create-card-Placeholder`}>{this.props.label_keys.firstName} *</span> : <span>&nbsp;</span>}</div>
                            <Input required placeholder={`${this.props.label_keys.firstName} *`} ref={"firstNameItem"}
                                aria-label={this.props.label_keys.firstName}
                                className={`${(this.state.error && this.state.error.firstName === true ) ? 'error-border' : (this.state.firstName !== '' && 'blue-border')}`}
                                value={this.state.firstName}
                                style={{borderColor: '#666666'}}
                                aria-describedby={this.state.error && this.state.error.firstName === true ? "first-name-error" : null}
                                onChange={e => this.setState({ firstName: e.target.value })}
                                autocomplete="given-name" />
                                {this.state.error && this.state.error.firstName === true &&
                                <div id="first-name-error"><span className="visually-hidden">Error:</span>
                                    <span  className="suggestion-text error-text">{this.props.label_keys.invalidData}</span>
                                </div>}
                            
                        </Col>
                        <Col span={4} className="create-holder-details-body">
                            <div >{this.state.lastNme !== '' ? <span className={`${this.state.error && this.state.error.lastNme === true  && "error-text"} create-card-Placeholder`}>{this.props.label_keys.lastName} *</span> : <span>&nbsp;</span>}</div>
                            <Input required placeholder={`${this.props.label_keys.lastName} *`} ref={"lastNameItem"}
                             aria-label={this.props.label_keys.lastName}
                             style={{borderColor: '#666666'}}
                             className={`${(this.state.error && this.state.error.lastNme === true ) ? 'error-border' : (this.state.lastNme !== '' && 'blue-border')}`}
                                value={this.state.lastNme}
                                aria-describedby={ this.state.error && this.state.error.lastNme === true ? "last-name-error": null}
                                onChange={e => this.setState({ lastNme: e.target.value })}
                                autocomplete="family-name" />
                                {this.state.error && this.state.error.lastNme === true &&
                                <div id="last-name-error"><span className="visually-hidden">Error:</span>
                                <span className="suggestion-text error-text">{this.props.label_keys.invalidData}</span></div>}
                            
                        </Col>
                    </Row>
                    <Row>
                        <Col span={4} className="create-holder-details-body">
                            <div>{this.state.employeeID !== '' ? <span className="create-card-Placeholder">{this.props.label_keys.employeeId}</span> : <span>&nbsp;</span>}</div>
                            <Input placeholder={this.props.label_keys.employeeId}
                                aria-label={this.props.label_keys.employeeId}
                                style={{borderColor: '#666666'}}
                                className={`${this.state.employeeID !== '' && 'blue-border'}`}
                                value={this.state.employeeID}
                                onChange={e => this.setState({ employeeID: e.target.value })} />
                        </Col>
                        <Col span={4} className="create-holder-details-body">
                            <div >{this.state.emailID !== '' ? <span className={`${this.state.error && (this.state.error.emailID === true || this.state.error.emailID1 === true) && "error-text"} create-card-Placeholder`}>{this.props.label_keys.emailID} *</span> : <span>&nbsp;</span>}</div>
                            <Input required placeholder={`${this.props.label_keys.emailID} *`} ref={"emailItem"}
                                aria-label ={this.props.label_keys.emailID}
                                style={{borderColor: '#666666'}}
                                aria-describedby={this.state.error && (this.state.error.emailID === true || this.state.error.emailID1 === true) ?"email-error" : null}
                                className={`${(this.state.error && (this.state.error.emailID === true || this.state.error.emailID1 === true)) ? 'error-border' : (this.state.emailID !== '' && 'blue-border')}`}
                                value={this.state.emailID}
                                onChange={e => this.setState({ emailID: e.target.value })}
                                autocomplete="email" />
                            {this.state.error && (this.state.error.emailID === true || this.state.error.emailID1 === true) &&
                                <Icon aria-hidden="true" className="error-icon " type="warning" />}
                            {this.state.error && this.state.error.emailID === true &&
                                <div id="email-error"><span className="visually-hidden">Error:</span><span  className="suggestion-text error-text">{this.props.label_keys.invalidEmail}</span></div>}
                            {this.state.error && this.state.error.emailID1 === true &&
                                <div id="email-error"><span className="visually-hidden">Error:</span><span  className="suggestion-text error-text">{this.props.label_keys.ownRequestError}</span></div>}
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={4} xl={4} xxl={4}  className="create-holder-details-body phone-number-div">
                            {this.state.phoneNumber !== '' ? <p className={`suggestion-text ${this.state.error && this.state.error.phoneNumber === true && "error-text"} create-card-Placeholder`}>{this.props.label_keys.mobileNumber} *</p> : <span>&nbsp;</span>}
                            <PhoneCode showFirst={['US', 'IN']} label='select country code for mobile number'
                                className={`${(this.state.error && this.state.error.phoneNumber === true) ? 'error-border' : (this.state.phoneNumber !== '' && 'blue-border')}`}
                                onSelect={code => this.setState({ phoneCode: code })}
                                value={this.state.phoneCode} />
                            <Input required placeholder={`${this.props.label_keys.mobileNumber} *`}
                                ref={"phoneItem"} id="phone-input"
                                aria-label={this.props.label_keys.mobileNumber}
                                aria-describedby={this.state.error && this.state.error.phoneNumber === true ? "phn-error" : null}
                                className={`${(this.state.error && this.state.error.phoneNumber === true) ? 'error-border' : (this.state.phoneNumber !== '' && 'blue-border')} create-card-Placeholder`}
                                value={this.state.phoneNumberMasked}
                                style={{borderColor: '#666666', marginLeft: '5px'}}
                                onKeyDown={e =>{
                                    console.log("Event:::", disable);
                                    if(e.key === "Tab" && !e.shiftKey && disable){
                                        setTimeout(() => {
                                            document.getElementById("closeIconItem").focus();
                                        }, 100);
                                    }
                                }}
                                onChange={e => this.setState({ phoneNumberMasked: phoneNumberFormat(e.target.value), phoneNumber: e.target.value })}
                                autocomplete="tel-local" />
                            {this.state.error && this.state.error.phoneNumber === true &&
                                <Icon aria-hidden="true" className="error-icon" type="warning" />}
                            {this.state.error && this.state.error.phoneNumber === true &&
                                <div id="phn-error"><span className="visually-hidden">Error:</span><span  className="suggestion-text error-text">{this.props.label_keys.mustBe10Digit}</span></div>}
                        </Col>
                        <Col span={4}></Col>
                    </Row>
                </div>
                <div className="create-button">
                    {this.state.approvalButton ?
                        <Button tabindex={0} id="approveBtn" disabled={disable} onKeyDown={this.onKeyDown.bind(this)} onClick={() => this.AddCardDataRequest()}>{this.props.label_keys.sendForApproval}</Button>
                        : <Button tabindex={0} id="createCardBtn" disabled={disable} onKeyDown={this.onKeyDown.bind(this)} onClick={() => this.createCrad()} className="createCardBtnCss">{this.props.label_keys.create}</Button>
                    }
                    <div tabindex={0} className="visually-hidden" aria-hidden="true"></div>
                </div>
                {this.state.createDetails && <CardPreview
                    createDetails={this.state.createDetails}
                    previewVisible={this.state.previewVisible}
                    onCLosePreview={() => {
                        this.setState({ previewVisible: !this.state.previewVisible, createDetails: null })
                        const btn = this.state.previousActiveElement;
                        if (document.body.contains(btn)) btn.focus()
                        this.props.reloadUserList()

                    }}
                    label_keys={this.props.label_keys} />}
                {this.state.modalVisible && <Modal  visible={this.state.modalVisible} className="sent-for-approval-modal"
                closeIcon={
                    <div className="create-head approval-close" style={{marginTop: '2px',
                    marginRight: '0px'}}>
                        <Icon role="button" tabIndex={-1} id="closeBtn" aria-label="Close" type="close-circle" onFocus={() => this.handleFocusCloseModal()} onClick={() => this.handleCloseModal()} /></div>
                }
                onCancel={() => this.setState({ modalVisible: false })} footer={[]}>
                    <Result 
                        icon={<Icon type="check-circle" theme="filled" aria-hidden='true'  />}
                        status="success"
                        subTitle={<b style={{color: '#666666'}}>{this.props.label_keys.successApproval}</b>}
                        extra={[
                            <div className="create-holder-details-head" ><span aria-hidden="true">{this.props.label_keys.requestNo}</span><span className="visually-hidden">{this.props.label_keys.requestNumber}</span> :  {this.state.reqNo}</div>
                        ]}
                    />
                </Modal>}
            </Drawer>
        )
    }
}


function mapStateToProps(state, props) {
    return {
        userDetails: state.authenticationReducer.userDetails,
        token: state.authenticationReducer.token
    }
}

export default connect(mapStateToProps, null)(CreateCard)
