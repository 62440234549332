import initialState from '../initialState';
// import store from '../../store/store.js';

const createprofileReducer = (state = initialState, action) => {
	
    switch (action.type) {

    	case 'CREATE_PROFILE':
			return Object.assign({}, state, {profileCreated : true});
		
		case 'CREATE_MORE':
			return Object.assign({},state,{profileCreated:action.data});

    	default:
            return state;
    }
};

export default createprofileReducer;