import React from 'react';
import { connect } from 'react-redux';
import '../../styling/viewEditProfile.css';
import '../../styling/companysetup.css'
import moment from 'moment';
import { delegateAccess, getActiveHm } from '../../actions/delegateAction';
import $ from 'jquery';
import { Select, notification, DatePicker, Modal, Spin } from 'antd';
import { loaderFocus } from '../../utils/accessability';
const Option = Select.Option;
const confirm = Modal.confirm;
const { errorMessages } = require('../../data/constants.js');
// const { app_labels } = require('../../data/english_label');
class DelegateHiringManager extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            companyName: undefined,
            selectedDate: '',
            delegateFrom: undefined,
            delegateTo: undefined,
            disableFlag: false,
            spinFlag: false,
            dateFlag : true,
        }
        this.baseState = this.state
    }

    /**
     * Lifecycle method that runs on mount of the component
     */
    componentDidMount() {
        if (this.props.roles === "Company Admin") {
            this.props.getActiveHiringManager(this.props.companyDetails.companyId);
            this.setState({ companyName: this.props.companyDetails.companyName })
        }

        // prepare the icons
        let element = $('.ant-select-arrow-icon')
        element.empty()
        element.removeClass().addClass('icon-Drop-down')
        let element1 = $('.anticon.anticon-calendar.ant-calendar-picker-icon')
        element1.empty()
        element1.removeClass().addClass('icon-Calendar')
    }
    componentDidUpdate() {
        let element1 = $('.anticon.anticon-calendar.ant-calendar-picker-icon')
        element1.empty()
        element1.removeClass().addClass('icon-Calendar')
    }
    /**
     * @param  {} element
     *removing the error class applied during validation on change of event
     */
    clearInvalidField(element) {
        let label = $(element).siblings('.text-class1')
        label = label.length === 0 ? $(element).find('.text-class1') : label
        label = label.length === 0 ? $(element).closest('.form-group').find('.text-class1') : label
        label.removeClass('has-error')
    }

    /**
     * on selecting a company name, calls the getActiveHiringManager method based on the selected name
     */
    companyChange = (value) => {
        this.setState({ companyName: value })
        let companyClass = document.getElementsByClassName('companyClass');
        this.clearInvalidField(companyClass)
        this.props.getActiveHiringManager(value);
    }

    /**
     * onclick of reset,need to reset the Form
     */
    resetForm = () => {
        this.setState({
            dateFlag : false,
        },() => {
            this.setState(this.baseState)
        })
        
        if (this.props.roles === "Company Admin") {
            this.setState({ companyName: this.props.companyDetails.companyName })
        }
    }

    createMore = () => {
        this.props.roles === "Company Admin" ? this.setState({ disableFlag: false, delegateFrom: undefined, delegateTo: undefined, companyName: this.props.companyDetails.companyName }) :
            this.setState({ disableFlag: false, delegateFrom: undefined, delegateTo: undefined, companyName: undefined })
    }
    /**Can not select days before today and today
    * @param  {} current
    */
    disabledDate = (current) => {
        return current && current < moment().startOf('day');
    }

    /**
     * Handles changed dates
     */
    handeldatechange = (e) => {
        let datePicker = document.getElementsByClassName('datePicker');
        this.clearInvalidField(datePicker)
        this.setState({ selectedDate: e._d.toString() });
    }

    /* sets the value of DelegateFrom*/
    delegateFromChange = (value) => {
        let delegateFrom = document.getElementsByClassName('delegateFrom');
        this.clearInvalidField(delegateFrom)
        this.setState({ delegateFrom: value });
    }

    /* sets the value of DelegateTo*/
    delegateToChange = (value) => {
        let delegateTo = document.getElementsByClassName('delegateTo');
        this.clearInvalidField(delegateTo)
        this.setState({ delegateTo: value });
    }

    /**
     * Validates all the entered values
     */
    inputValidation = () => {
        const companyClass = document.getElementsByClassName('companyClass');
        const delegateFrom = document.getElementsByClassName('delegateFrom');
        const delegateTo = document.getElementsByClassName('delegateTo');
        const datePicker = document.getElementsByClassName('datePicker');
        [companyClass,
            delegateFrom,
            delegateTo,
            datePicker].forEach(el => this.clearInvalidField(el))

        if (this.state.companyName === null || this.state.companyName === "" || this.state.companyName === undefined) {
            notification.warning({
                message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Error </span>{errorMessages.error_req_company}</span>,
                duration: 3,
                style: {
                    backgroundColor: '#fffbe6',
                    border: "1px solid #ffe58f",
                }
            });
            let label = $(companyClass).siblings('.text-class1')
            label.addClass('has-error');
            return false;
        }
        if (this.state.delegateFrom === null || this.state.delegateFrom === "" || this.state.delegateFrom === undefined) {
            notification.warning({
                message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Error </span>{errorMessages.error_req_delegationFrom}</span>,
                duration: 3,
                style: {
                    backgroundColor: '#fffbe6',
                    border: "1px solid #ffe58f",
                }
            });
            let label = $(delegateFrom).siblings('.text-class1')
            label.addClass('has-error');
            return false;
        }
        if (this.state.delegateTo === null || this.state.delegateTo === "" || this.state.delegateTo === undefined) {
            notification.warning({
                message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Error </span>{errorMessages.error_req_delegationTo}</span>,
                duration: 3,
                style: {
                    backgroundColor: '#fffbe6',
                    border: "1px solid #ffe58f",
                }
            });
            let label = $(delegateTo).siblings('.text-class1')
            label.addClass('has-error');
            return false;
        }
        if (this.state.selectedDate === null || this.state.selectedDate === "" || this.state.selectedDate === undefined) {
            notification.warning({
                message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Error </span>{errorMessages.error_req_delEndDate}</span>,
                duration: 3,
                style: {
                    backgroundColor: '#fffbe6',
                    border: "1px solid #ffe58f",
                }
            });
            let label = $(datePicker).siblings('.text-class1')
            label.addClass('has-error');
            return false;
        }
        return true

    }
    stateUpdation = (value) => {
        this.setState({ spinFlag: value, disableFlag: true })
    }
    cancelDelegation = () => { }


    /**
     * On click of save Delegate, updates the delegation state 
     * Cancels the delegation on click of cancel button
     */
    saveDelegate = () => {
        var current = this;
        if (this.inputValidation() !== true) return
        confirm({
            title: 'Confirm Delegation',
            okText: 'Yes',
            cancelText: 'No',
            onOk() {
                current.stateUpdation(true);
                loaderFocus(".loader-color .ant-spin-text");
                let obj = {
                    "travelerId": current.state.delegateFrom,
                    "delegateId": current.state.delegateTo,
                    "enddate": current.state.selectedDate,
                }
                current.props.delegation(obj, current.stateUpdation, current.cancelDelegation);

            },
            onCancel() { },
        });

    }
    render() {
        return (
            <div>
                <div className="delegatecontainer"  >
                    <div className="row admin_heading">
                        <b>{this.props.app_labels.delegation1}</b><br /><hr className="hrStyleclass" />
                    </div>
                    <div className="row" style={{ marginLeft: "4%" }} >
                        <div className="col-sm-3 col-sm-offset-0 cardsetrule">
                            <span className="text-class1">{this.props.app_labels.companyName}</span><br /><br />
                            {this.props.roles === "Company Admin" ? <span className="input-text">{this.props.activeCompanyName[0]} </span> :
                                <Select className='companyClass' value={this.state.companyName}
                                    onChange={this.companyChange}
                                    disabled={this.state.disableFlag}
                                    style={{ width: '100%' }}
                                    placeholder="Select company">
                                    {this.props.activeCompanyName.map(item =>
                                        <Option value={item.companyId}>{item.companyName}</Option>
                                    )}
                                </Select>}
                        </div>
                    </div>
                    <div className="row" style={{ marginLeft: "4%" }} >
                        <div className="col-sm-3 col-sm-offset-0 cardsetrule">
                            <span className="text-class1">{this.props.app_labels.delegateFrom}</span><br /><br />
                            <Select className='delegateFrom' value={this.state.delegateFrom}
                                onChange={this.delegateFromChange}
                                disabled={this.state.disableFlag}
                                style={{ width: '100%' }}
                                placeholder="select HM">
                                {this.props.hiringManagerList.map(item =>
                                    <Option value={item.travelerId}>{item.firstName} {item.lastName}</Option>
                                )}
                            </Select>
                        </div>
                        <div className="col-sm-3 col-sm-offset-1 cardsetrule" >
                            <span className="text-class1">{this.props.app_labels.delegateTo}</span><br /><br />
                            <Select className='delegateTo' value={this.state.delegateTo}
                                onChange={this.delegateToChange}
                                disabled={this.state.disableFlag}
                                style={{ width: '100%' }}
                                placeholder="Select HM">
                                {this.props.hiringManagerList.map(item =>
                                    <Option value={item.travelerId}>{item.firstName} {item.lastName}</Option>
                                )}
                            </Select>
                        </div>
                        {this.state.spinFlag ? <div className="dark-spinner loader-color"><Spin tip="Loading..." /></div> : null}
                        <div className="col-sm-3 col-sm-offset-1 cardsetrule" >
                            <span className="text-class1"> {this.props.app_labels.untilTheEndOf}</span><br /><br />
                            {
                                this.state.dateFlag ?
                                    <DatePicker
                                        placeholder="select date"
                                        className="datePicker"
                                        disabled={this.state.disableFlag}
                                        style={{ width: "100%" }}
                                        format="MM/DD/YYYY"
                                        disabledDate={this.disabledDate}
                                        onChange={(e) => {
                                            this.handeldatechange(e)
                                        }}
                                    /> : null
                            }
                        </div>
                    </div><br /><br />
                </div>
                <div className="row">
                    <div className="col-sm-2 col-sm-offset-5 col-md-2 col-md-offset-6" style={{ textAlign: 'right' }}>
                        <button className="buttonRightPadding button-wrapclass delegate-button-createtrip-ghost" style={{ marginTop: '13%', marginBottom: '1%' }} onClick={this.resetForm}><b>{this.props.app_labels.reset}</b></button>
                    </div>
                    <div className="col-sm-2 col-sm-offset-1 col-md-2 col-md-offset-1">
                        {this.state.disableFlag === false ?
                            <button className="button-createtrip" style={{ marginBottom: "8%" }} onClick={this.saveDelegate} ><b>{this.props.app_labels.saveCapital}</b></button>
                            : <button className="button-createtrip" style={{ marginBottom: "8%" }} onClick={this.createMore} ><b>{this.props.app_labels.more}</b></button>}
                    </div>
                </div>
            </div>
        )
    }
}
function mapStateToProps(state, props) {
    return {
        token: state.authenticationReducer.token,
        hiringManagerList: state.getuserActionsReducer.activeHiringManagerList,
    };
}
function mapDispatchToProps(dispatch) {
    return {
        delegation: (obj, stateUpdation, cancelDelegation) => {
            dispatch(delegateAccess(obj, stateUpdation, cancelDelegation));
        },
        getActiveHiringManager: (companyId) => {
            dispatch(getActiveHm(companyId))

        }

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(DelegateHiringManager);