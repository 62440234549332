import axios from 'axios';
import React from 'react';
import { notification } from 'antd';
import { getParticularTrip } from './getparticulartripActions';
import history from '../components/history';
const { URLs , errorMessages } = require('../data/constants.js')

const SendInfo = (dispatch) => new Promise((resolve, reject) => {
  dispatch(sendinfo(true));
  resolve();
})

export function sendinfo(data) {
  return {
    type: "SEND_INFO",
    data
  }
}
export function sendInfomationToTraveller(obj, tripId) {
  return (dispatch, getState) => {
    SendInfo(dispatch).then(() => {
      dispatch(
        sendInfomationToTraveller1(obj, tripId)
      )
    })
  }
}

/**
 * Axios call to notify the traveler of the trip detail
 * @param {*} obj 
 * @param {*} tripId 
 */
export function sendInfomationToTraveller1(obj, tripId) {
  return (dispatch, getState) => {
    let {
      token,
    } = getState().authenticationReducer;

    axios.post(URLs.comUrl + 'notifyTraveler', obj, {
      responseType: 'json',
      headers: {
        "Authorization": 'Bearer ' + token.access_token,
        "Content-Type": 'application/json',
      }
    }).then(result => {
      dispatch(getParticularTrip(tripId));
      dispatch(sendinfo(false));
      if (result.data.serviceStatus.statusCode === 210) {
        notification.error({
          message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{result.data.serviceStatus.message}</span>,
          duration: 3,
          style: {
            backgroundColor: '#fff1f0',
            border: "1px solid #ffa39e",
          }
        });
      }
      else if (result.data.serviceStatus.statusCode === 200) {
        notification.success({
          message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Success </span>{result.data.displayMessage}</span>,
          duration: 3,
          style: {
            backgroundColor: '#dff0d8',
            color: "green",
            border: "1px solid green",
          }
        });
        history.push('./dashboard')
      }
      else {
        notification.error({
          message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{result.data.serviceStatus.message}</span>,
          duration: 3,
          style: {
            backgroundColor: '#fff1f0',
            border: "1px solid #ffa39e",
          }
        });
      }

    }).catch(function (error) {
      dispatch(sendinfo(false));
      if (error.response !== undefined && error.response.data !== null && error.response.data !== undefined && error.response.data.serviceStatus !== undefined) {
        notification.error({
          message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{error.response.data.serviceStatus.message}</span>,
          duration: 3,
          style: {
            backgroundColor: '#fff1f0',
            border: "1px solid #ffa39e",
          }
        });
      }
      else {
        notification.error({
          message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{errorMessages.generic_error}</span>,
          duration: 3,
          style: {
            backgroundColor: '#fff1f0',
            border: "1px solid #ffa39e",
          }
        });
      }
    });

  }
}