const progressBarHoverText_Blue = {
    createTrip: "Create Trip",
    createCard: "Assign Card",
    flighItenary: "Upload Flight Reservation",
    hotelItenary: "Upload Hotel Reservation",
    sendInfo: "Notify Traveler",
    completeTrip: "Complete the trip",
    closeTrip: "Close The Trip",
    createProfile: "Create Profile and Assign Card",
    uploadItenerary: "Upload Itinerary"
}
const userActionDescription = {
    flightItinerary: "Upload Flight Itinerary",
    hotelItinerary: "Upload Hotel Reservation",
    travelerNotified: "Traveler to be Notified",
    assignCreditCard: "Assign Card",
    tripClosure: "Trip Closure"
}
/* const tripStatus = {
    saved: 'Saved',
    cancelled: 'Cancelled',
    awaitingTravel: 'Awaiting Travel',
    inProgress: 'In Progress',
    pendingExpReport: 'Pending Expense Reporting',
    pendingClosure: 'Pending Closure',
    archived: 'Archived',
} */
const errorMessages = {
    generic_error: "Couldn't complete your request",
    error_userCreation: "Couldn't create new user",
    error_cardCreation: "Couldn't retrieve card details",
    error_companyAddress:"Couldn't retrieve company address details",
    error_cardLimit: "Couldn't retrieve card limit",
    error_pendingAction: "Couldn't retrieve pending action trip details",
    error_userProfile: "Couldn't create use profile. Please try again.",
    error_uploadExcel: "Upload Excel file",
    error_fetchCompany: "Couldn't retrieve company",
    error_fetchVersion: "Couldn't retrieve version",
    error_req_expenseWizardId: "Expense Wizard ID required",
    error_req_reportToId: "Report to : Expense Wizard ID required",
    error_req_jobRole: "Select job role",
    error_req_emailId: "Email address required",
    error_req_phnum: "Phone number required",
    error_req_companyName: "Company name required",
    error_req_firstName: "First name required",
    error_req_lasttName: "Last name required",
    error_req_editName: "Enter both first and last names",
    error_req_company: "Select company",
    error_req_expCat: "Expense category field mandatory",
    error_req_crExpCat: "CR expense category field mandatory",
    error_req_transactionType: "Transaction type field mandatory",
    error_req_userDefinedField: "User defined field mandatory",
    error_req_question: "Question field mandatory",
    error_req_compShortName: "Company short name required",
    error_req_compNumber: "Company contact number required",
    error_req_compAcctId: "Account ID required",
    error_val_compAcctId: "Please enter 12 digit account id",
    error_val_compNumber: "Enter valid phone number",
    error_val_compShortName: "Company short name should have 2 to 6 characters",
    error_req_delegationFrom: "Please select Delegate from",
    error_req_delegationTo: "Please select Delegate to",
    error_req_delEndDate: "Please select date",
    error_addingNewRule: "Error adding new rule",
    error_req_ruleCondition: "Rule condition required",
    error_addExpCat: "Error adding new expense category",
    error_updateProfile: "Failed to update user profile",
    error_deleteProfile: "Failed to delete user profile",
    error_enableProfile: "Failed to enable user profile",
    error_setCardDetails: "Set card details for selected company",
    error_incorrectEmail: "Incorrect email address",
    error_fetchCandidateId: "Couldn't retrieve candidate ID",
    error_invalidPhno: "Invalid phone number",
    //create trip messages//
    error_val_cardLimit: "Card limit can not be 0 or empty",
    error_req_travelerType: "Traveler type required",
    error_req_travelerName: "Traveler name required",
    error_req_travelerLastName: "Traveler Last Name required",
    error_req_travelerId: "Traveler ID required",
    error_req_origin: "Travel origin required",
    error_req_destination: "Travel destination required",
    error_req_travelDate: "Enter start and end trip",
    error_req_actualOrigin: "Actual origin required",
    error_req_actualDestination: "Actual destination required",
    error_req_actualTripDates: "Actual trip dates required",
    error_onlyPdf: "Upload PDF file",
    error_fileSize: "File size can't be more than 5mb",
    success_uploadCompl: "Upload completed",
    error_noFileFound: "No file found",
    error_selectTravelerType: "Select traveler type",
    error_travelerDetails: "Couldn't retrieve traveler details",
    error_noProfileFound: "No profiles found",
    error_noTripsFound: "No trips found",
    error_searching: "Searching error",
    error_delegationTripSelect: "Select trips",
    error_req_chromeRiverCustCd: "Customer code required",
    error_val_chromeRiverCustCd: "Customer code cant be more than 10 characters",
    error_req_serviceInterface: "Service Interface required",
    error_req_accountingcode: "Accounting code required",
    error_req_entityID1: "Entity Type ID1 required",
    error_req_entityID2: "Entity Type ID2 required",
    error_accountingcode: "Accounting code can't be more than 20 characters",
    confirm_errorMessage: "Please check this box to confirm that you have verified the accuracy of the trip start and end dates",
    //Login messages//
    otpVerificationGenericErrorMessage: "Couldn't verify one-time password",
    otpSendingGenericErrorMessage: "'Couldn't send one-time password",
    forgotPasswordGenericErrorMessage: "Forgot password error",
    sendOtpSuccessMessage: "A one-time password (OTP) has been sent to your registered email address. Please enter it below to reset your password.",
    mfaInfoMessage: "A one-time password (OTP) has been sent to your registered email address. Please enter it below continue.",
    loginGenericErrorMessage: "Login failed",
    usernamePrompt: "Enter username",
    passwordPrompt: "Enter password",
    messageTypePrompt: "'Enter message type",
    otpTypeMessage: "Enter one-time password",
    selectMessageType: "Select message type",
    messagePasswordSuggestion: "Your password should have minimum 8 alphanumeric characters, with at least one uppercase, one lowercase, a number and a special character (e.g. - Demo@123)",
    confirmPasswordPrompt: "Confirm password",
    credentialsStrengthError: "Password doesn't meet strength requirements",
    error_val_otp: "One-time password should be a 6 digit number", // corrected otp validation message for 6 didit input
    fetchCSRTransactionDetailsGenericErrorMessage: "Couldn't retrieve transaction details",
    updateCSRTransactionDetailsGenericErrorMessage: "Unable to update transaction details",
    unableToResetPasswordErrorMessage: "Unable to reset password.",
    messageOtpPrompt: "A one-time password (OTP) will be sent to your registered email address.",
    startDategreater: "End date should be greater than start date.",
    noDateSelected: "Select start and end date.",
    /**tool tip texts**/
    sendtoTravelerOnhover: "This will be enabled when the Hotel/Flight itineraries are uploaded if the Hotel/Flight option is selected and the Confirm Trip Details is checked OR if the “Traveler books tickets” option is checked",
    travelerBooksTicketsToolTip: "Check this box if traveler is booking the flight and/or hotel using the card. The rest of the create trip process of confirming the trip dates will be skipped and passed on to the traveler.",
    /**batch job error messages */
    selectJob: "Please select job role"
}
const CADRE_AUTH_URL = window.location.host.split(":")[0]
const CADRE_SERVICES_URL = window.location.host.split(":")[0] 


const protocal = window.location.protocol;

const AUTH_CONTEXT_PATH = "/ewizauth"
const SERVICE_CONTEXT_PATH = "/ewizcore"

//const urlHost = ['10.232.3.27']
const URLs = {
    tokenUrl: protocal + "//" + CADRE_AUTH_URL + AUTH_CONTEXT_PATH + "/oauth/",
    deleteUserUrl: protocal + "//" + CADRE_AUTH_URL + AUTH_CONTEXT_PATH + "/user/deleteUserAccount",
    adminUrl: protocal + "//" + CADRE_AUTH_URL + AUTH_CONTEXT_PATH + "/admin/",
    userUrl: protocal + "//" + CADRE_AUTH_URL + AUTH_CONTEXT_PATH + "/user/",
    mfaUrl: protocal + "//" + CADRE_AUTH_URL + AUTH_CONTEXT_PATH + "/mfa/",
    tripUrl: protocal + "//" + CADRE_SERVICES_URL + SERVICE_CONTEXT_PATH + "/trip/",
    fileUrl: protocal + "//" + CADRE_SERVICES_URL + SERVICE_CONTEXT_PATH + "/file/",
    reportUrl: protocal + "//" + CADRE_SERVICES_URL + SERVICE_CONTEXT_PATH + "/reports/",
    comUrl: protocal + "//" + CADRE_SERVICES_URL + SERVICE_CONTEXT_PATH + "/com/",
    companyUrl: protocal + "//" + CADRE_SERVICES_URL + SERVICE_CONTEXT_PATH + "/company/",
    core_url: protocal + "//" + CADRE_SERVICES_URL,
    csrUrl: protocal + "//" + CADRE_SERVICES_URL + SERVICE_CONTEXT_PATH + "/csr",
    cardUrl: protocal + "//" + CADRE_SERVICES_URL + SERVICE_CONTEXT_PATH + "/card",
    transactionQuestionnaireUrl: protocal + "//" + CADRE_SERVICES_URL + SERVICE_CONTEXT_PATH + "/basic/trans",
    transactionUrl: protocal + "//" + CADRE_SERVICES_URL + SERVICE_CONTEXT_PATH + "/trans",
    trxUrl: protocal + "//" + CADRE_SERVICES_URL + SERVICE_CONTEXT_PATH + "/txn",
    historyServiceUrl: (travelerId, transactionId) => protocal + "//" + CADRE_SERVICES_URL + `/cbhistoryservices/users/${travelerId}/interactions/${transactionId}/messages/options`
}

/*********** TripStatus *************/
const tripStatusConstants = {
    Saved: 'Saved',
    AwaitingTravel: 'Awaiting Travel',
    InProgress: 'In Progress',
    PendingExpenseReporting: 'Pending Expense Reporting',
    Complete: 'Complete',
    ArchivedTrip: 'Archived',
    Cancelled: 'Cancelled',
    PendingClosure: 'Pending Closure',
    Closed: 'Closed',
    Upcoming: 'Upcoming',
}

/*********** TripStatus *************/
const deleteUserConstants = {
    deleteSuccess: "Profile successfully deleted.",
    deleteError: "Error in user deletion.",
    deleteTitle: "Delete this user profile?",
    deleteMsg: "This action can't be undone.",
    deleteMsgDesc: "Unprocessed requests will be removed.",
    deleteMsgDesc1: "No impact to active cards.",
    deleteMsgDesc2: "All active cards related to this user will be deactivated."


}

/*********** ServiceInterfaces *************/
const serviceInterfaces = {
    chromeRiver: 1,
    instaCard: 2,
    elanEasyPay: 3,
}

const serviceInterfacesName = {
    chromeRiver: "Chrome River",
    instaCard: "Instant Card",
    elanEasyPay: "Elan EasyPay",
}

const serviceInterfaceId = {
    chromeRiver: 1,
    instaCard: 2,
    elanEasyPay: 3,
}
/*********** rolee *************/
const roleIds = {
    Manager: 1,
    Traveler: 2,
    CompanyAdmin: 3,
    SuperAdmin: 4,
    CSR: 5,
    CardCompanyAdmin: 6,
    Provisioner: 7,
    CardUser: 8,
    Approver: 9
}
/***** Provisioner Sort Param *****/
const sortParam = {
    firstName: 'firstName',
    lastName: 'lastName'
}

/***For Bulk Card feature enable/disable***/ 
const bulkFeature ={
    enable : true
}

const elanRelatedUrls = {
    elanUrlCheck: 'elan-solutions.elaneasypay',
    // elanUrlCheck : 'localhost',
    securityPolicyUrl: 'www.elanpaymentsolutions.com/online-privacy-and-security.html',
    TnCUrl: 'www.elanpaymentsolutions.com/paymentsolutions/easypayagreement.html',
}

const icRelatedUrls = {
    securityPolicyUrl: 'www.usbank.com/about-us-bank/privacy/security.html',
    securityPolicyUrlFrench: 'www.usbankcanada.com/fr/privacy-agreement.html',
    TnCUrl: 'www.usbank.com/corporate-and-commercial-banking/treasury-payment-solutions/corporate-payment-services/virtual-credit-cards/instant-card/agreement.html',
    TnCUrlFrench: 'www.usbankcanada.com/fr/index/commercial-payment-solutions/instant-card/agreement.html',
}

module.exports = {
    progressBarHoverText_Blue,
    userActionDescription,
    URLs,
    errorMessages,
    tripStatusConstants,
    serviceInterfaces,
    roleIds,
    serviceInterfacesName,
    serviceInterfaceId,
    sortParam,
    bulkFeature,
    elanRelatedUrls,
    icRelatedUrls,
    deleteUserConstants
}
