import initialState from './initialState';

const gettripReducer = (state = initialState, action) => {

	switch (action.type) {

		case 'GET__TRIP':
			return Object.assign({}, state, { getAlltripData: action.data.trips });

		case 'IS_DB_FETCHING':
			return Object.assign({}, state, { isDbFetching: action.data });

		case 'IS_DB_USRTRIP_FETCHING':
			return Object.assign({}, state, { isDbuserTripFetching: action.data });

		case 'IS_DB_USRACTION_FETCHING':
			return Object.assign({}, state, { isDbuserActionFetching: action.data });

		case 'IS_DB_CARD_FETCHING':
			return Object.assign({}, state, { isDbCardFetching: action.data });
		default:
			return state;
	}
};

export default gettripReducer;