import React from 'react';
import '../../styling/cardsettings.css';
// import '../../styling/createtrip.css';
import axios from 'axios';
import { connect } from 'react-redux';
import { Checkbox, notification } from 'antd';
const { URLs, errorMessages } = require('../../data/constants.js');

/**
* renders when app version tab is clicked on superadmin screen
*/
class AppVersion extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: "",
            iOSVersion: null,
            androidVersion: null,
            forceUpdate: false,
            valueChange: false,
            erroriOS: false,
            errorAndroid: false,
            /* added new state variables to store IC app versions and related data seperately */
            iOSVersionInsta: null,
            androidVersionInsta: null,
            forceUpdateInsta: false,
            erroriOSInsta: false,
            errorAndroidInsta: false,
        }
    }
    /**
     * @param  {} {this.getCompanyLists(
     * lifecycle method
     */
    componentDidMount() {
        axios.get(URLs.comUrl + 'getAppVersion', {
            responseType: 'Json',
            headers: {
                "Authorization": 'Bearer ' + this.props.token.access_token
            }
        }).then(response => {
            if (response.data.serviceStatus.statusCode === 200) {
                response.data.data.forEach(e => {
                    if (e.type === "MOBILE_CONFIG") { // added condition for XW app version update in state variable
                        e.code === "iOSAppVersion" &&
                            this.setState({ iOSVersion: e.value })
                        e.code === "AndroidAppVersion" &&
                            this.setState({ androidVersion: e.value })
                        e.code === "ForceUpdate" &&
                            this.setState({ forceUpdate: e.value === '1' || e.value === 1 ? true : false })
                    }
                    if (e.type === "InstaCard_CONFIG") { // added condition for IC app version update in state variable
                        e.code === "iOSAppVersion" &&
                            this.setState({ iOSVersionInsta: e.value })
                        e.code === "AndroidAppVersion" &&
                            this.setState({ androidVersionInsta: e.value })
                        e.code === "ForceUpdate" &&
                            this.setState({ forceUpdateInsta: e.value === '1' || e.value === 1 ? true : false })
                    }
                })
            } else {
                notification.error({
                    message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{errorMessages.error_fetchVersion}</span>,
                    duration: 0,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }
        }).catch(error => {
            notification.error({
                message: <span  role='alert' aria-live="assertive" aria-atomic="true" ><span className="visually-hidden">Error </span>{errorMessages.generic_error}</span>,
                duration: 0,
                style: {
                    backgroundColor: '#fff1f0',
                    border: "1px solid #ffa39e",
                }
            })
        })
    }

    /**
     * validates the App version entered
     * @param {*} data 
     */
    validateVersion(data) {
        let regEx = /^(\d{1,2})(\.\d{1,2}){0,2}$/;
        if (data == null || !regEx.test(data)) return false;
        data = data.replace(" ", "");
        if (data === "") return false;
        return true;
    }

    /**
     * on click of save button, calls the axios post request to update the database
     */
    handleSave() {
        let errorMessage = "";
        if (!this.validateVersion(this.state.iOSVersion)) {
            errorMessage = "Invalid iOS version for Expense Wizard";
        }

        if (!this.validateVersion(this.state.androidVersion)) {
            errorMessage += errorMessage !== "" ? " and " : "";
            errorMessage += "Invalid android version for Expense Wizard";
        }

        // added app version validation for IC verson input fields
        if (!this.validateVersion(this.state.iOSVersionInsta)) {
            errorMessage += errorMessage !== "" ? " and " : "";
            errorMessage += "Invalid iOS version for Instant Card";
        }

        if (!this.validateVersion(this.state.androidVersionInsta)) {
            errorMessage += errorMessage !== "" ? " and " : "";
            errorMessage += "Invalid android version for Instant Card";
        }
        if (errorMessage) {
            notification.error({
                message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{errorMessage}</span>,
                duration: 3,
                style: {
                    backgroundColor: '#fff1f0',
                    border: "1px solid #ffa39e",
                }
            });
            return;
        }

        let obj = [
            {
                "version": this.state.iOSVersion,
                "code": "iOSAppVersion",
                "type": "MOBILE_CONFIG"
            },
            {
                "version": this.state.androidVersion,
                "code": "AndroidAppVersion",
                "type": "MOBILE_CONFIG"
            },
            {
                "version": this.state.forceUpdate === true ? 1 : 0,
                "code": "ForceUpdate",
                "type": "MOBILE_CONFIG"
            },
            //now sending IC app version data seperately from XW to update in DB
            {
                "version": this.state.iOSVersionInsta,
                "code": "iOSAppVersion",
                "type": "InstaCard_CONFIG"
            },
            {
                "version": this.state.androidVersionInsta,
                "code": "AndroidAppVersion",
                "type": "InstaCard_CONFIG"
            },
            {
                "version": this.state.forceUpdateInsta === true ? 1 : 0,
                "code": "ForceUpdate",
                "type": "InstaCard_CONFIG"
            }
        ]

        axios.post(URLs.comUrl + "/updateAppVersion", obj, {
            headers: {
                "Authorization": 'Bearer ' + this.props.token.access_token
            }
        }).then(response => {
            if (response.data.serviceStatus.statusCode === 200) {
                notification.success({
                    message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Success </span>{"Data saved successfully"}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#dff0d8',
                        color: "green",
                        border: "1px solid green",
                    }
                });
                this.setState({ valueChange: false })
            } else {
                notification.error({
                    message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{errorMessages.generic_error}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }
        }).catch(error => {
            if (error.response && error.response.data && error.response.data.serviceStatus) {
                notification.error({
                    message: <span  role='alert' aria-live="assertive" aria-atomic="true" ><span className="visually-hidden">Error </span>{error.response.data.serviceStatus.message}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            } else {
                notification.error({
                    message: <span  role='alert' aria-live="assertive" aria-atomic="true" ><span className="visually-hidden">Error </span>{errorMessages.generic_error}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }
        })
    }

    render() {
        return (
            <div>
                <div className="cardsettingclass" >
                    <div className="row admin_heading">
                        <b>{this.props.app_labels.appVersion}</b><br /><hr className="hrStyleclass" />
                    </div>
                    <div>
                        <div className="row admin_heading">
                            <span>Expense Wizard</span><br /><hr />
                        </div>
                        <div className="row cardsetrule1">
                            <div className="col-sm-3 col-sm-offset-1 col-md-3 col-md-offset-1 col-lg-3 col-lg-offset-1" style={{ width: "fit-content" }}>
                                <label> iOS Version : </label>
                            </div>
                            <div className="col-sm-2 col-sm-offset-0 col-md26 col-md-offset-0 col-lg-2 col-lg-offset-0" style={{ paddingLeft: "46px" }}>
                                <input type="text"
                                    className={!this.state.erroriOS ? 'editabletextbox' : 'errorInput'}
                                    value={this.state.iOSVersion}
                                    disabled={this.props.roles.toLowerCase() === 'customer support'? true : false}
                                    onBlur={() => !this.validateVersion(this.state.iOSVersion) ?
                                        this.setState({ erroriOS: true }) :
                                        this.setState({ erroriOS: false })}
                                    onChange={e => this.setState({
                                        iOSVersion: e.target.value,
                                        valueChange: true
                                    })} />
                            </div>
                            <div className="col-sm-3 col-sm-offset-1 col-md-3 col-md-offset-1 col-lg-3 col-lg-offset-1" style={{ width: "fit-content" }}>
                                <label> Android Version : </label>
                            </div>
                            <div className="col-sm-2 col-sm-offset-0 col-md-2 col-md-offset-0 col-lg-2 col-lg-offset-0">
                                <input className={!this.state.errorAndroid ? 'editabletextbox' : 'errorInput'}
                                    value={this.state.androidVersion}
                                    disabled={this.props.roles.toLowerCase() === 'customer support'? true : false}
                                    onBlur={() => !this.validateVersion(this.state.androidVersion) ?
                                        this.setState({ errorAndroid: true }) :
                                        this.setState({ errorAndroid: false })}
                                    onChange={e => this.setState({
                                        androidVersion: e.target.value,
                                        valueChange: true
                                    })} />
                            </div>
                        </div>
                        <div className="row cardsetrule1" style={{ paddingBottom: "1%" }}>
                            <div className="col-sm-3 col-sm-offset-1 col-md-3 col-md-offset-1 col-lg-3 col-lg-offset-1" style={{ width: "fit-content" }}>
                                <Checkbox
                                    disabled={this.props.roles.toLowerCase() === 'customer support'? true : false}
                                    checked={this.state.forceUpdate}
                                    value={this.state.forceUpdate}
                                    onChange={e => this.setState({
                                        forceUpdate: !this.state.forceUpdate,
                                        valueChange: true
                                    })} >
                                    Force Update
                            </Checkbox>
                            </div>
                        </div>
                    </div>
                    <div>
                        {/* added instant card app version update section in appVersion screen for superadmin */}
                        <div className="row admin_heading">
                            <span>Instant Card</span><br /><hr />
                        </div>
                        <div className="row cardsetrule1">
                            <div className="col-sm-3 col-sm-offset-1 col-md-3 col-md-offset-1 col-lg-3 col-lg-offset-1" style={{ width: "fit-content" }}>
                                <label> iOS Version : </label>
                            </div>
                            <div className="col-sm-2 col-sm-offset-0 col-md26 col-md-offset-0 col-lg-2 col-lg-offset-0" style={{ paddingLeft: "46px" }}>
                                <input type="text"
                                    disabled={this.props.roles.toLowerCase() === 'customer support'? true : false}
                                    className={!this.state.erroriOSInsta ? 'editabletextbox' : 'errorInput'}
                                    value={this.state.iOSVersionInsta}
                                    onBlur={() => !this.validateVersion(this.state.iOSVersionInsta) ?
                                        this.setState({ erroriOSInsta: true }) :
                                        this.setState({ erroriOSInsta: false })}
                                    onChange={e => this.setState({
                                        iOSVersionInsta: e.target.value,
                                        valueChange: true
                                    })} />
                            </div>
                            <div className="col-sm-3 col-sm-offset-1 col-md-3 col-md-offset-1 col-lg-3 col-lg-offset-1" style={{ width: "fit-content" }}>
                                <label> Android Version : </label>
                            </div>
                            <div className="col-sm-2 col-sm-offset-0 col-md-2 col-md-offset-0 col-lg-2 col-lg-offset-0">
                                <input className={!this.state.errorAndroidInsta ? 'editabletextbox' : 'errorInput'}
                                    value={this.state.androidVersionInsta}
                                    disabled={this.props.roles.toLowerCase() === 'customer support'? true : false}
                                    onBlur={() => !this.validateVersion(this.state.androidVersionInsta) ?
                                        this.setState({ errorAndroidInsta: true }) :
                                        this.setState({ errorAndroidInsta: false })}
                                    onChange={e => this.setState({
                                        androidVersionInsta: e.target.value,
                                        valueChange: true
                                    })} />
                            </div>
                        </div>
                        <div className="row cardsetrule1" style={{ paddingBottom: "1%" }}>
                            <div className="col-sm-3 col-sm-offset-1 col-md-3 col-md-offset-1 col-lg-3 col-lg-offset-1" style={{ width: "fit-content" }}>
                                <Checkbox
                                    disabled={this.props.roles.toLowerCase() === 'customer support'? true : false}
                                    checked={this.state.forceUpdateInsta}
                                    value={this.state.forceUpdateInsta}
                                    onChange={e => this.setState({
                                        forceUpdateInsta: !this.state.forceUpdateInsta,
                                        valueChange: true
                                    })} >
                                    Force Update
                            </Checkbox>
                            </div>
                        </div>
                    </div><br /><br />
                </div>
                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-sm-offset-0 col-md-12 col-md-offset-0" style={{ textAlign: 'right' }}>
                        <button className="focusbutton button-createtrip button-wrapclass"
                            disabled={this.props.roles.toLowerCase() === 'customer support'? true : false}
                            style={{ margin: "5%" }}
                            onClick={() => this.handleSave()}
                            disabled={!this.state.valueChange} >
                            <b>{this.props.app_labels.save}</b>
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}
function mapStateToProps(state, props) {
    return {
        userDetails: state.authenticationReducer.userDetails,
        token: state.authenticationReducer.token,
    };
}
export default connect(mapStateToProps)(AppVersion);