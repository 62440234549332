import React from 'react';
import axios from 'axios'
import '../../styling/suggestion.css';
import { connect } from 'react-redux';
import { notification, AutoComplete } from 'antd'
import { loaderFocus } from '../../utils/accessability';
const Option = AutoComplete.Option;
const { URLs, errorMessages } = require('../../data/constants.js');
class TravellerAutosuggest extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      results: [],
    }
    // Create refs
    this.inputField = React.createRef()
  }

  /**
   * @param  {} value
   * fetch the employee or candidate details from backend corresponding to the string entered
   */
  handleSearch(value) {
    if (this.props.type === "") {
      notification.warning({
        message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Error </span>{errorMessages.error_selectTravelerType}</span>,
        duration: 3,
        style: {
          backgroundColor: '#fffbe6',
          border: "1px solid #ffe58f",
        }
      });
      return;
    }
    if (value.length >= 3) {
      this.props.spinFlagUpdate(true)
      loaderFocus(".loader-color .ant-spin-text")
      let obj = {
        "firstName": value,
        "requestType": "name",
        "searchType": this.props.type.toLowerCase(),
        "travelerIds": [],
      }
      axios.post(URLs.userUrl + "search", obj, {
        responseType: 'json',
        headers: {
          "Authorization": 'Bearer ' + this.props.token.access_token
        }
      }).then(response => {
        this.props.spinFlagUpdate(false)
        this.setState({ results: response.data.travelers });
      }).catch((err) => {
        this.props.spinFlagUpdate(false)
        notification.warning({
          message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Error </span>{errorMessages.error_travelerDetails}</span>,
          duration: 3,
          style: {
            backgroundColor: '#fffbe6',
            border: "1px solid #ffe58f",
          }
        });
      })
    }
  }

  /**
   * @param  {} result
   * @param  {} index
   * renders the dropdown of autosuggest with the value that has been passing
   */
  renderResult(result, index) {
    return <Option value={`${result.travelerId}`} text={`${result.firstName}`} originalObj={result}>
      <div>
        {result.firstName + ', ' + result.emailId}
      </div>
    </Option>
  }

  render() {
    return (
      <AutoComplete
        dataSource={this.state.results.map(this.renderResult.bind(this))}
        defaultActiveFirstOption={false}
        onSearch={(value) => {
          this.handleSearch(value)
        }
        }
        disabled={this.props.disableFlag || this.props.newDisableType}
        onChange={(value, option) => {
          this.setState({ results: [] });
          this.props.clearInvalidField()
          option.props.hasOwnProperty('text') ?
            this.props.handleTravellerSuggestionInput(option.props.text, this.props.type) :
            this.props.handleTravellerSuggestionInput(value, this.props.type)
        }}
        onSelect={(value, option) => {
          this.setState({ results: [] })
          this.props.getSuggestionValue(option.props.originalObj, this.props.type)
        }}
        optionLabelProp="text"
        dropdownMatchSelectWidth={false}
        value={this.props.value}
      >
        <input type="text"
          placeholder={this.props.app_labels.selectFirstName}
          aria-label=" Traveler First Name"
          autocomplete="off"
          onKeyPress={(event) => {
            if (event.key.replace(/[^\w\-.]/g, '') === '') event.preventDefault();
          }
          }
          ref={(ele) => this.props.setInputRef(ele)}
          className={this.props.className || 'airport-autocomplete-input'}
        />
      </AutoComplete >

    )
  }
}
function mapStateToProps(state, props) {
  return {
    token: state.authenticationReducer.token,
    app_labels: state.adduserReducer.app_labels,
  };
}

export default connect(mapStateToProps)(TravellerAutosuggest);