import React, { useState, useEffect } from 'react';
import { unmountComponentAtNode } from 'react-dom'
import { Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';
import IdleTimer from 'react-idle-timer';
import history from './history';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { logOut } from '../actions/logoutactions';
import '../styling/appintro.css';
import { setTimeout } from 'timers';

function AutoLoginLoader() {
    return (
        <Row style={{
            fontFamily: 'Segoe UI !important',
            paddingTop: '15px'
        }}>
            <Col
                span={24}
                style={{
                    fontSize: '24px',
                    fontWeight: 'bold',
                    textAlign: 'center'
                }}
            >
                Please wait...
            </Col>
        </Row>
    )
}

function isTokenValid({ token, tokenTimestamp, authenticationError, isAuthenticated }) {
    if (authenticationError) return false;

    if (token &&
        tokenTimestamp &&
        token.expires_in &&
        !(isNaN(+token.expires_in)) &&
        (+tokenTimestamp + +token.expires_in > Date.now() / 1000)
        && isAuthenticated === true
    )
        return true;
    else return false;
}

const PrivateRoute = ({ component: Component, authenticationProperties, logOutFun, ...rest }) => {
    const [flag, setFlag] = useState(false);
    const [callBack, setCallback] = useState(false);
  
    useEffect(() => {
        if(callBack && flag) {
            sessionStorage.clear()
            logOutFun()
            history.push('/login')
            
            document.body.classList.remove('react-confirm-alert-body-element')

            const target = document.getElementById('react-confirm-alert')
            target && unmountComponentAtNode(target)
            target && target.parentNode && target.parentNode.removeChild(target)

            const svg = document.getElementById('react-confirm-alert-firm-svg')
            svg && svg.parentNode && svg.parentNode.removeChild(svg)
            document.body.children[0].classList.remove('react-confirm-alert-blur')
        } else {
            setTimeout(() => {
                setCallback(false)
            }, 0)
        }
    });

    function alertFocus(element) {
        var focusableEls = element.querySelectorAll('button');
        var firstFocusableEl = focusableEls[0];
        var lastFocusableEl = focusableEls[focusableEls.length - 1];
        var KEYCODE_TAB = 9;

        element.addEventListener('keydown', function (e) {
            var isTabPressed = (e.key === 'Tab' || e.keyCode === KEYCODE_TAB);

            if (!isTabPressed) {
                return;
            }

            if (e.shiftKey) /* shift + tab */ {
                if (document.activeElement === firstFocusableEl) {
                    lastFocusableEl.focus();
                    e.preventDefault();
                }
            } else /* tab */ {
                if (document.activeElement === lastFocusableEl) {
                    firstFocusableEl.focus();
                    e.preventDefault();
                }
            }
        });
    }

    const handlePrompt = () =>{
            const target = document.getElementById('timer');
            target && target.focus();
            target && alertFocus(target);
    }
    return (<div>
            <IdleTimer
                onIdle={() => {
                    setFlag(true)
                    setTimeout(() => {
                        setCallback(true)   
                    },1000 * 60)
                    confirmAlert({
                        closeOnEscape: true,
                        closeOnClickOutside: false,
                        customUI: ({ onClose }) => {
                            return (
                            <div className='custom-ui' id="timer" tabIndex={-1} role='dialog' aria-label='session timeout'>
                                <h2 style={{marginTop:"20px"}} role="presentation">Your session will expire in 60 Seconds</h2>
                                <div>Press Yes to continue or No to logout</div>
                                <button
                                    className='ant-btn focusbutton btn-custom'
                                    onClick={() => {
                                        sessionStorage.clear()
                                        logOutFun()
                                        history.push('/login')
                                        onClose()
                                    }}>No</button>
                                <button
                                    className='ant-btn focusbutton btn-custom ant-btn-primary'
                                    onClick={() => {
                                        setFlag(false)
                                        setCallback(false)
                                        onClose()
                                    }}>Yes</button>
                            </div>
                            )
                        }
                })
            }}
                debounce={250}
                onPrompt ={handlePrompt()}
                timeout={1000 * 60 * 10}
               /> 
            <Route
                {...rest}
                render={props =>
                    authenticationProperties.isAuthenticating === true || authenticationProperties.isAuthenticating === undefined ?
                        <AutoLoginLoader /> :
                        isTokenValid(authenticationProperties) === true ? (
                            <Component {...props} />
                        ) : (
                                <Redirect
                                    to={{
                                        pathname: '/login',
                                        state: { from: props.location },
                                    }}
                                />
                            )
                }
            />
    </div>)

};

const mapStateToProps = (state) => {
    return {
        authenticationProperties: {
            token: state.authenticationReducer.token,
            tokenTimestamp: state.authenticationReducer.tokenTimestamp,
            authenticationError: state.authenticationReducer.authenticationError,
            isAuthenticating: state.authenticationReducer.isAuthenticating,
            isAuthenticated: state.authenticationReducer.isAuthenticated
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        logOutFun: () => dispatch(logOut())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);