import React, { Component } from "react";
import { Table, Row, Col } from 'antd';
import 'antd/dist/antd.css'
import { connect } from "react-redux";
import RequestDetails from "./RequestDetails";
import { getBulkData } from '../../actions/getBulkData.js';
import prepareTitle from '../../utils/accessability';

class RequestsTableDataComponents extends Component {
    constructor(props) {
        super(props)

        this.state = {
            data: [],
            activeTab: props.activeTab,
            visible: false,
            selectedLang: "EN",
            selectedRow: {},
            sortedInfo: null,
            sortOrderText: '',
            prevActiveElement: null
        }
    }

    componentDidMount(){
        const divUp = document.querySelectorAll(".anticon.anticon-caret-up.ant-table-column-sorter-up.off");
        const divDown = document.querySelectorAll(".anticon.anticon-caret-down.ant-table-column-sorter-down.off");
        // console.log("div:::", divUp)
        if(divUp[0]){
            divUp.forEach(div => {
                div.setAttribute('role', 'img')
                div.setAttribute('aria-hidden', 'true')
            })
        }
        if(divDown[0]){

            divDown.forEach(div => {
                div.setAttribute('role', 'img')
                div.setAttribute('aria-hidden', 'true')
            })

        }

        const divSearch = document.getElementsByClassName("anticon anticon-search ant-input-search-icon");
        
        if(divSearch[0]){
            divSearch[0].setAttribute('role', 'img')
            divSearch[0].setAttribute('aria-hidden', 'true')
        }


    }

    requestDetails(text) {
        let count=0;
        const a = [...this.props.data]
        this.setState({prevActiveElement: document.activeElement})
        if (this.state.activeTab == "Requests") {
            for (let i of a) {
                if (i.type.toLowerCase() === "bulk" && i.reqNo == text) {
                    console.log("Request number and title", i.reqNo, i.title);
                    
                    let title = `${this.props.label_keys.request} #${i.reqNo} ${ i.title ?`- ${i.title}` : ''}` 
                    count ++                    
                    this.props.setTitle(title);
                    this.props.getBulkDataFunc(this.props.label_keys,text)                
                    this.props.bulkVisible()
    }
    }
        }
        count === 0 && this.setState({ visible: !this.state.visible })  
    }

    handleChange = (pagination, filters, sorter) => {
        this.setState({
            filteredInfo: filters,
            sortedInfo: sorter,
            sortOrderText: `Table sorted by ${sorter.columnKey}, in ${sorter.order}ing order`,
        });
    }

    componentDidUpdate() {
        let activeDescendant = null;

        var ulDiv = document.querySelectorAll(".ant-pagination.ant-table-pagination");
        if(ulDiv[0]){
            ulDiv.forEach(div => {
                // console.log("Last element:::", div.lastElementChild , div.lastElementChild.className ==="ant-pagination-options");
                if(div.lastElementChild.className ==="ant-pagination-options")
                    div.prepend(div.lastElementChild)
            })
        }

        const icon = document.querySelectorAll('.anticon.anticon-down.ant-select-arrow-icon');
        if (icon) {
            icon.forEach(el => {
                el.setAttribute('role', 'img')
                el.setAttribute('aria-hidden', 'true')
            })
        }

        const paginationDiv = document.querySelectorAll('.ant-pagination.ant-table-pagination');
        if(paginationDiv[0]){
            paginationDiv.forEach(div => {
                if(!div.getAttribute('role'))
                    div.setAttribute('role', 'presentation');
            })
        }

        const ellipsis = document.querySelectorAll('.ant-pagination-item-ellipsis');
        if(ellipsis[0]){
            ellipsis.forEach(div =>{
                if(!div.getAttribute('aria-hidden'))
                    div.setAttribute('aria-hidden', 'true');
            })
        }

        const prevFiveDiv = document.querySelectorAll('.ant-pagination-jump-prev.ant-pagination-jump-prev-custom-icon');
        if(prevFiveDiv[0] ){
            prevFiveDiv.forEach(div => {
                if(!div.getAttribute('role')){
                    div.setAttribute('role', 'link');
                    div.setAttribute('aria-label', "Previous 5 pages");
                }
            })
        }

        const prevFiveDivIcon = document.querySelectorAll('.anticon.anticon-double-left.ant-pagination-item-link-icon');
        if(prevFiveDivIcon[0] ){
            prevFiveDivIcon.forEach(div => {
                if(div.getAttribute('aria-label'))
                    div.removeAttribute('aria-label');
            })
        }

        const nextFiveDiv = document.querySelectorAll('.ant-pagination-jump-next.ant-pagination-jump-next-custom-icon');
        if(nextFiveDiv[0] ){
            nextFiveDiv.forEach(div => {
                if(!div.getAttribute('role')){
                    div.setAttribute('role', 'link');
                    div.setAttribute('aria-label', "Next 5 pages");
                }
            })
        }

        const nextFiveDivIcon = document.querySelectorAll('.anticon.anticon-double-right.ant-pagination-item-link-icon');
        if(nextFiveDivIcon[0] ){
            nextFiveDivIcon.forEach(div => {
                if(div.getAttribute('aria-label'))
                    div.removeAttribute('aria-label');
            })
        }

        const prevPageDiv = document.querySelectorAll('.ant-pagination-prev');
        if(prevPageDiv[0] ){
            prevPageDiv.forEach(div => {
                if(!div.getAttribute('role')){
                    div.setAttribute('role', 'link');
                    div.setAttribute('aria-label', "Previous page");
                }
            })
        }

        const nextPageDiv = document.querySelectorAll('.ant-pagination-next');
        if(nextPageDiv[0] ){
            nextPageDiv.forEach(div => {
                if(!div.getAttribute('role')){
                    div.setAttribute('role', 'link');
                    div.setAttribute('aria-label', "Next page");
                }
            })
        }

       const dropDown = document.querySelectorAll('.ant-select-selection.ant-select-selection--single');
        if(dropDown[0] ){
            dropDown.forEach(div => {
                if(!div.getAttribute('aria-label') && !div.firstElementChild.innerHTML.includes("Active&nbsp;Cards"))
                    div.setAttribute('aria-label', 'Select rows');
                
                
                div.addEventListener('keyup', function(){
                    // console.log("Event on click fired");
                    const comboboxOption = document.querySelectorAll('.ant-select-dropdown-menu-item');
                    // console.log("Div:::", comboboxOption);
                    if(comboboxOption[0]){
                        var i = 0
                        comboboxOption.forEach(el =>{
                            i += 1
                            if(!el.getAttribute('id'))
                                el.setAttribute('id', `option-${i}`);
                            el.removeAttribute('aria-selected');
                        })
                    }
                    let optionDiv = document.querySelectorAll('.ant-select-dropdown-menu-item.ant-select-dropdown-menu-item-active');
                    // console.log("Div:::", optionDiv);
                    if(optionDiv[0]){
                        activeDescendant = optionDiv[0].getAttribute('id')
                        //  console.log("active descendant::", activeDescendant);   
                        div.setAttribute('aria-activedescendant', activeDescendant);
                    }                               
                });
            })
            
        }
    }

    onSizeChange = (e) =>{
        this.props.OnPageSizeChange(this.props.current, e.target.value);
    }

    

    render() {
        let { sortedInfo } = this.state;
        sortedInfo = sortedInfo || {};

        const columns = [
            {
                title: prepareTitle('0',<span role="button" >{this.props.label_keys.requestNumber}</span>),
                dataIndex: 'reqNo',
                key: 'requestNumber',
                sorter: (a, b) => a.reqNo - b.reqNo,
                sortOrder: sortedInfo.columnKey === 'requestNumber' && sortedInfo.order,
                render: text => <div role="button" tabIndex={0}                 
                 onKeyUp={e => {
                    if (e.keyCode === 13) {
                        this.requestDetails(text)
                    }
                }}                
                onClick={() => {
                    this.requestDetails(text)
                    }}><a><u>{text}</u></a></div>
            },{
                title: prepareTitle('0',<span role="button" style={{alignItems:"center"}} >{this.props.label_keys.title}</span>),
                dataIndex: 'title',
                key: 'title',
                className: 'title',
                sorter: (a, b) => {
                    let title = !a.title ? '' :a.title.toLowerCase();
                    let title2 = !b.title ? '' : b.title.toLowerCase();
                    return title < title2 ? -1 : 1
                },
                sortOrder: sortedInfo.columnKey === 'title' && sortedInfo.order,
                render: text => <div>{!text ? '-' : text }</div>
            },
            {
                title: prepareTitle('0', <span role="button" >{this.props.label_keys.type}</span>),
                dataIndex: 'type',
                key: 'type',
                sorter: (a, b) => {
                    let type = a.type.toLowerCase();
                    let type2 = b.type.toLowerCase();
                    return type < type2 ? -1 : 1
                },
                sortOrder: sortedInfo.columnKey === 'type' && sortedInfo.order,
            },
            {
                title: prepareTitle('0',<span role="button" >{this.props.label_keys.requestOwner}</span>),
                dataIndex: 'req_owner',
                key: 'request_owner',
                sorter: (a, b) => {
                    let req_owner1 = a.req_owner.toLowerCase();
                    let req_owner2 = b.req_owner.toLowerCase();
                    return req_owner1 < req_owner2 ? -1 : 1
                },
                sortOrder: sortedInfo.columnKey === 'request_owner' && sortedInfo.order,
            },
            {
                title: prepareTitle('0', <span role="button" >{this.props.label_keys.requestDate}</span>),
                key: 'requestedDate',
                dataIndex: 'requestedDate',
                sorter: (a, b) => {
                    return Number(new Date(a.requestedDate)) - Number(new Date(b.requestedDate));
                },
                sortOrder: sortedInfo.columnKey === 'requestedDate' && sortedInfo.order,
            },
            {                
                title: prepareTitle('0',<span role="button" >
                <span aria-hidden="true">{this.props.label_keys.noOfCards}</span>
                <span className="visually-hidden">Number of Cards</span></span>),
                dataIndex: "noOfCards",
                key: "numberOfCards",
                sorter: (a, b) => {
                    let noOfCards = a.noOfCards;
                    let noOfCards2 = b.noOfCards;
                    return noOfCards < noOfCards2 ? -1 : 1
                },
                sortOrder: sortedInfo.columnKey === 'numberOfCards' && sortedInfo.order,
            },
            {
                title: prepareTitle('0', <span role="button" >{this.props.label_keys.requestStatus}</span>), 
                key: 'status',
                dataIndex: 'status',
                sorter: (a, b) => {
                    let status = a.status.toLowerCase();
                    let status2 = b.status.toLowerCase();
                    return status < status2 ? -1 : 1
                },
                sortOrder: sortedInfo.columnKey === 'status' && sortedInfo.order,
                render: (text, record) => text !== "In Progress" || (text === "In Progress" && record.type === 'Single') ?
                <div style={{color: "#333333", fontWeight: 600}}>{text}</div>
                :
                // (text === "In Progress" && record.type === 'Single' ?
                // <div style={{color: "#333333", fontWeight: 600}}>Awaiting action</div>
                // :
                <div style={{color: "#333333", fontWeight: 600}}>{record.noOfProcessedCards ? record.noOfProcessedCards : 0} of {record.noOfCards} Processed</div>
                // )
            },
        ];

        return (
            <div id={this.props.id}>
                <div aria-relevant="text" aria-live={sortedInfo.order === "ascend" || sortedInfo.order === "descend" ?"polite" : "off"} 
                aria-atomic="true" className="visually-hidden">{
                    sortedInfo.order === "ascend" || sortedInfo.order === "descend" ?
                      <div><span>Table sorted by {sortedInfo.columnKey}</span>
                        <span>in {sortedInfo.order}ing order</span></div> : null} </div>
               
                <Table className='Req-table userlist'
                rowKey={obj => obj.reqNo}
                columns={columns}
                dataSource={this.props.data}
                onChange={this.handleChange}
                locale={{emptyText: this.props.loading ? this.props.label_keys.loading : this.props.label_keys.dataUnavailable}}
                // loading={this.props.loading}
                scroll={{ x: 1000 }}
                pagination={{
                    total:this.props.data.length,
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    current: this.props.current,
                    itemRender:this.props.itemRender,
                    pageSizeOptions: [ "10", "20", "50", "100"],
                    position :"bottom",
                    showTitle:false,
                    showTotal:(total, range) => <Row > 
                        <Col className="total-text-pagination" xs={{span: 12, offset: 24}} sm={{span: 12, offset: 24}} md={{span: 12, offset: 24}} lg={12} xl={12} xxl={12}>{this.props.label_keys.showing} {range[0]}-{range[1]} {this.props.label_keys.of} {total} </Col>
                        <Col className="page-count-span" xs={{span: 8}} sm={{span: 6}} md={{span: 6}} lg={6} xl={6} xxl={6} >{this.props.label_keys.page} {this.props.current} {this.props.label_keys.of} {this.props.TotalPage}</Col></Row>,
                    onChange:this.props.pageChanger,
                    onShowSizeChange:this.props.OnPageSizeChange
                }}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: event => {
                            this.setState({ selectedRow: record})
                        }, // click row

                    }
                }}
            />

                 
                {this.state.visible ?
                    <RequestDetails
                        createVisible={this.state.visible}
                        onCLoseCreate={() => {
                            if(this.state.visible){
                                if (document.body.contains(this.state.prevActiveElement)) {
                                    this.state.prevActiveElement.focus();
                              }
                            }
                            this.setState({ visible: !this.state.visible })
                            this.setState({prevActiveElement: null});
                            // this.props.getProvisionerRequestData()
                        }}
                        getProvisionerRequestData={this.props.getProvisionerRequestData}
                        reloadUserList={() => this.props.removeSearch()}
                        label_keys={this.props.label_keys}
                        selectedRow={this.state.selectedRow}
                        activeTab={this.state.activeTab}
                    />
                    : null}
            </div>);
    }
}


function mapStateToProps(state, props) {
    return {
        userDetails: state.authenticationReducer.userDetails,
        token: state.authenticationReducer.token,
        iclanguageList: state.instantCardMultiLanReducer.iclanguageList,
        
       
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getBulkDataFunc: (label_keys,req) => {
            dispatch(getBulkData(label_keys,req));
        },
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(RequestsTableDataComponents);
