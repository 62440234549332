/* eslint-disable no-unused-expressions */
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { notification, Checkbox, Radio, Col, DatePicker, Layout, Spin, Modal } from 'antd';
import 'antd/dist/antd.css';
// import '../../styling/createtrip.css';
import '../../styling/suggestion.css';
import '../../styling/focus.css';
import axios from 'axios';
import $ from 'jquery'
//import { createCard } from '../../actions/createcardActions';
import { connect } from 'react-redux';
import { createtrip, savetrip, getCardLimit } from '../../actions/createtripActions.js';
import { tripStatus } from '../../actions/changetripstatusAction';
import MediaQuery from 'react-responsive';
import HeaderComponent from '../headercomponent';
import { loaderFocus } from '../../utils/accessability';
import 'react-responsive-ui/style.css';
import PhoneCode from '../phonecode.js';
import CreateCard from './createCard';
import moment from 'moment';
//import Autosuggest from 'react-autosuggest';
import TravellerAutosuggest from './TravellerAutosuggest'
import Greybar from '../../images/greyPb.svg';
import Bluebar from '../../images/bluePb.svg';
//import Fuse from "fuse.js"
import AirportAutosuggest from './AirportAutosuggest'
import AccountingCodeAutosuggest from './AccountingCodeAutosuggest'
import Select from 'react-select';
//const { airports } = require('../../data/airport.js');
const { progressBarHoverText_Blue } = require('../../data/constants.js')
//const { app_labels } = require('../../data/english_label.js');
const RadioGroup = Radio.Group;
const { Header, Content, Footer } = Layout;
const confirm = Modal.confirm;
const { RangePicker } = DatePicker;
const { URLs, errorMessages } = require('../../data/constants.js');
const customStyles_dropdown = {
  option: (provided, state) => ({
    ...provided,
    color: "#636466 !important",
    zIndex: 999,
    fontFamily: 'U.S.BankCircularWeb-Regular !important',
  }),
  control: (base, state) => ({
    ...base,
    backgroundColor: "#fff",
    fontFamily: 'U.S.BankCircularWeb-Regular !important',
    fontSize: '22px !important',
    color: '#636466 !important',
    border: "0 !important",
    boxShadow: "0 !important",
    "&:hover": {
      border: "0 !important",
      borderBottom: "1px solid lightgrey !important"
    },
    background: "#fff",
    zIndex: 999,
    borderBottom: "1px solid lightgrey !important"
  }),
  singleValue: (provided, state) => {
    return { ...provided };
  }
}
var dateFormat = require('dateformat');
/* var _ = require('lodash') */
// var ac;
// function renderSuggestion(suggestion) {
//   return (
//     <span>{suggestion.FIRST_NAME},{suggestion.EMAIL}</span>
//   );
// }
class Createtrip extends Component {

  constructor(props) {
    super(props);

    this.state = {
      bookedByTraveler: false,
      autosuggestSpin: false,
      mincardLimit: 0,
      disableFlag: false,
      savedraftspin: false,
      spin: false,
      startdatetemp: null,
      enddatetemp: null,
      radiovalue: "Yes",
      cardlimit: this.props.cardLimitvalue,
      countrycode: [],
      code1: "+1",
      code2: "+1",
      traveldetails: [],
      newTravellerName: "",
      newTravellerlastName: "",
      newTravellerType: "",
      travellerID: "",
      newTravellerEmail: "",
      phNo: "",
      phNo2: "",
      origin: "",
      origintemp: "",
      destination: "",
      destinationtemp: "",
      budget: "",
      hotel: "",
      flight: "",
      cardNeeded: false,
      accountingCode: "",
      accountingCodetemp: "",
      entityID1: "",
      entityID2: "",
      entityType1: "",
      entityType2: "",
      entityList1: [],
      entityList2: [],
      value: '',
      suggestions: [],
      phNosuggestion: "",
      travellerUniqueId: null,
      department: "",
      role: "",
      newDisableType: true,
      hasError: false,
      fieldDisplayId1: false,
      fieldDisplayId2: false,
      accountErrorMessage: null
    }
    // preserve the initial state in a new object
    this.baseState = this.state
    this.handlenewTrip = this.handlenewTrip.bind(this)
    this.onChangecheck = this.onChangecheck.bind(this)
    this.handeldatechange = this.handeldatechange.bind(this)
    this.onRadioChange = this.onRadioChange.bind(this)
    this.cardlimitChange = this.cardlimitChange.bind(this)
    this.handlenewTripandCard = this.handlenewTripandCard.bind(this)

    // Create refs
    this.travelername = React.createRef()
    this.travelerlastname = React.createRef()
    this.empid = React.createRef()
    this.email = React.createRef()
    this.phno = React.createRef()
    this.phno2 = React.createRef()
    this.accountingCode = React.createRef()
    this.origin = React.createRef()
    this.destination = React.createRef()
    this.travelDateRangePicker = React.createRef()
    this.cardLimit = React.createRef()
  }

  /**
   * lifecycle method called on mount of the component
   */
  componentDidMount() {
    this.props.cardLimit();
    this.email.current.disabled = true;

    let element = $('.anticon.anticon-calendar.ant-calendar-picker-icon')
    element.empty()
    element.removeClass().addClass('icon-Calendar')
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.cardLimitvalue !== nextProps.cardLimitvalue) {
      this.setState({ cardlimit: nextProps.cardLimitvalue, mincardLimit: nextProps.cardLimitvalue })
    }
    if (this.props.travelleruniqueIdnew !== nextProps.travelleruniqueIdnew) {
      this.setState({ travellerUniqueId: nextProps.travelleruniqueIdnew })
    }
  }
  /**Prevents negative value in required fields
   * @param  {} e
   */
  restrictMinus = (e) => {
    var inputKeyCode = e.keyCode ? e.keyCode : e.which;
    if (inputKeyCode != null) {
      if (inputKeyCode === 45 || inputKeyCode === 101) e.preventDefault();
    }
  }
  /****Auto suggestion on Employee search
   * @param  {} suggestion
   */
  getSuggestionValue = (suggestion, type) => {
    var phonenumber = suggestion.phoneNo.split(" ");
    if (phonenumber.length === 1) {
      phonenumber[0] = +1;
      phonenumber[1] = this.formatMobileNumber(suggestion.phoneNo);
    }
    this.travelerlastname.current.disabled = true;
    this.empid.current.disabled = true;
    this.email.current.disabled = true;
    /* this.phno.current.disabled = true; */

    if (type === "Employee") {
      this.setState({ role: suggestion.crRole, department: suggestion.crDepartment, code1: phonenumber[0], newTravellerlastName: suggestion.lastName, newTravellerName: suggestion.firstName, newTravellerEmail: suggestion.emailId, phNosuggestion: this.formatMobileNumber(phonenumber[1]), travellerID: suggestion.cadreId, travellerUniqueId: suggestion.travelerId })
    }
    if (type === "Candidate") {
      this.setState({ role: suggestion.crRole, department: suggestion.crDepartment, code1: phonenumber[0], newTravellerlastName: suggestion.lastName, newTravellerName: suggestion.firstName, newTravellerEmail: suggestion.emailId, phNosuggestion: this.formatMobileNumber(phonenumber[1]), travellerID: suggestion.cadreId, travellerUniqueId: suggestion.travelerId })
    }

    return suggestion.firstName;
  }
  /**
   * @param  {} newValue
   * when first name is cleared all the other fields will cleared (when autosuggestion is in picture)
   */
  onChangeofValue = (newValue) => {
    this.clearInvalidField(this.travelername.current)
    if (newValue === "" || newValue === null) {
      this.setState({ code1: "+1", newTravellerName: "", newTravellerlastName: "", newTravellerEmail: "", phNosuggestion: "", travellerID: "", travellerUniqueId: null });
      this.travelerlastname.current.disabled = false;
      this.empid.current.disabled = true;
      this.email.current.disabled = false;
      this.phno.current.disabled = false;
    }
    if (this.state.newTravellerType === 'Candidate') {
      this.empid.current.disabled = true;
    }
    this.setState({
      value: newValue, newTravellerName: newValue
    });
  }

  /*****************Auto suggestion ends************** */

  /**
   * on click of ok, trip status becomes cancelled
   */
  cancelTrip = () => {
    var current = this;
    confirm({
      title: <div className="cardHeading">{this.props.app_labels.confirmCancelTrip}</div>,
      okText: 'Yes',
      cancelText: 'No',
      onOk() {
        current.props.changeTripStatus(current.props.tripId, "Cancelled", null);
        //current.setState(current.baseState);
        window.location.reload();
      },
      onCancel() { },
    });
  }

  /*
  *smart search for origin and destination, setting whetehr its origin or destination field
   */
  handleTravelPlaceInput1(value, type) {
    if (type === 'origin')
      this.setState(() => ({ origintemp: value }))
    else if (type === 'destination')
      this.setState(() => ({ destinationtemp: value }))
  }

  /**
   * Sets the origin and destination values
   * @param {*} value 
   * @param {*} type 
   */
  handleTravelPlaceInput(value, type) {
    if (type === 'origin')
      this.setState(() => ({ origin: value }))
    else if (type === 'destination')
      this.setState(() => ({ destination: value }))
  }

  /**
   * handles the accounting code values
   * @param {*} value 
   */
  handleAccountingCodeInput1(value) {
    this.setState(() => ({ accountingCodetemp: value, hasError: false, fieldDisplayId1: false, fieldDisplayId2: false }))
  }

  /**
   * Provides value for options in entity type 1
   */
  optionEntityType1() {
    let arr = []
    this.state.entityList1.forEach(item => {
      arr.push({ "value": item.entityCode, "label": item.entityCode })
    })
    return arr
  }

  /**
   * Provides value for options in entity type 2
   */
  optionEntityType2() {
    let arr = []
    this.state.entityList2.forEach(item => {
      arr.push({ "value": item.entityCode, "label": item.entityCode })
    })
    return arr
  }

  /**
   * handles the accounting code, calls the axios get request based on entity Ids
   * @param {*} value 
   * @param {*} obj 
   */
  handleAccountingCodeInput(value, obj) {
    this.setState(() => ({ accountingCode: value, hasError: false }))
    let valueObj = {}
    var entityList1 = []
    var entityList2 = []
    obj.forEach(item => {
      if (value === item.allocationId) {
        valueObj = item
      }
    })
    if (valueObj.entityTypeID1 && (valueObj.entityTypeID1 !== null || valueObj.entityTypeID1 !== undefined)) {
      this.setState(() => ({ entityID1: valueObj.entityTypeID1 }))
      axios.get(URLs.comUrl + "entity/search", {
        params: {
          'entityTypeId': valueObj.entityTypeID1
        },
        responseType: 'json',
        headers: {
          "Authorization": 'Bearer ' + this.props.token.access_token
        }
      }).then(response => {
        if (response.data.serviceStatus.statusCode === 200) {
          response.data.allocationEntity[0].response.entities.forEach(item => {
            entityList1.push(item)
          })
          this.setState({ entityList1, fieldDisplayId1: true })
        }
        else {
          notification.error({
            message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Error </span>{response.data.serviceStatus.message}</span>,
            duration: 3,
            style: {
              backgroundColor: '#fff1f0',
              border: "1px solid #ffa39e",
            }
          });
        }
      }).catch((error) => {
        if (error.response !== undefined && error.response.data !== null && error.response.data !== undefined && error.response.data.serviceStatus !== undefined) {
          notification.error({
            message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Error </span>{error.response.data.serviceStatus.message}</span>,
            duration: 3,
            style: {
              backgroundColor: '#fff1f0',
              border: "1px solid #ffa39e",
            }
          });
        }
        else {
          notification.error({
            message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Error </span>{errorMessages.generic_error}</span>,
            duration: 3,
            style: {
              backgroundColor: '#fff1f0',
              border: "1px solid #ffa39e",
            }
          });
        }
      })
    }
    if (valueObj.entityTypeID2 && (valueObj.entityTypeID2 !== null || valueObj.entityTypeID2 !== undefined)) {
      this.setState(() => ({ entityID2: valueObj.entityTypeID2 }))
      axios.get(URLs.comUrl + "entity/search", {
        params: {
          'entityTypeId': valueObj.entityTypeID2
        },
        responseType: 'json',
        headers: {
          "Authorization": 'Bearer ' + this.props.token.access_token
        }
      }).then(response => {
        if (response.data.serviceStatus.statusCode === 200) {
          response.data.allocationEntity[0].response.entities.forEach(item => {
            entityList2.push(item)
          })
          this.setState({ entityList2, fieldDisplayId2: true })
        }
        else {
          notification.error({
            message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Error </span>{response.data.serviceStatus.message}</span>,
            duration: 3,
            style: {
              backgroundColor: '#fff1f0',
              border: "1px solid #ffa39e",
            }
          });
        }
      }).catch((error) => {
        if (error.response !== undefined && error.response.data !== null && error.response.data !== undefined && error.response.data.serviceStatus !== undefined) {
          notification.error({
            message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Error </span>{error.response.data.serviceStatus.message}</span>,
            duration: 3,
            style: {
              backgroundColor: '#fff1f0',
              border: "1px solid #ffa39e",
            }
          });
        }
        else {
          notification.error({
            message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Error </span>{errorMessages.generic_error}</span>,
            duration: 3,
            style: {
              backgroundColor: '#fff1f0',
              border: "1px solid #ffa39e",
            }
          });
        }
      })
    }
  }

  /**
   * Handles the traveler value
   * @param {*} value 
   * @param {*} type 
   */
  handleTravellerSuggestionInput(value, type) {
    this.onChangeofValue(value)
    this.setState({ newTravellerName: value });
  }

  /**Save draft(creates the trip and doesnt create card and doesnt proceed to next page) 
   * @param  {} e
   */
  handleSaveDraft = (e) => {
    e.preventDefault()
    if (this.validateFormFields() !== true) return
    var flight = false;
    var hotel = false;
    for (var i = 0; i < this.state.traveldetails.length; i++) {
      if (this.state.traveldetails[i] === "travelbooking") {
        flight = true;
      }
      else if (this.state.traveldetails[i] === "hotelbooking") {
        hotel = true;
      }
    }
    var travellerType = this.state.newTravellerType;
    var tempTravellerName = this.state.newTravellerName;
    var travellerlastName = this.travelerlastname.current.value;
    var travellerID = this.empid.current.value;
    var travellerEmail = this.email.current.value;
    var phNo = this.state.code1 + " " + this.phno.current.value.trim().split(" ").join("");
    var phNo2 = null;
    if (this.phno2.current.value !== "") {
      let newAltPhNo = this.phno2.current.value.trim().split(" ").join("")
      phNo2 = this.state.code2 + " " + newAltPhNo;
      let validAltPhNo = this.validatePhoneNumber(newAltPhNo);
      if (validAltPhNo === false) {
        this.highlightInvalidField(this.phno2.current)
        return;
      }
    }
    var accountingcode = this.state.accountingCode;
    var entityType1 = "";
    var entityType2 = "";
    var entityId1 = "";
    var entityId2 = "";
    var origin = this.state.origin;
    var destination = this.state.destination;
    var startDate = this.state.startdatetemp;
    var endDate = this.state.enddatetemp;
    if (this.state.fieldDisplayId1) {
      entityType1 = this.state.entityType1;
      entityId1 = this.state.entityID1;
    }
    if (this.state.fieldDisplayId2) {
      entityType2 = this.state.entityType2;
      entityId2 = this.state.entityID2;
    }
    var budget;
    var cardNeeded;
    if (this.state.radiovalue === "Yes") {
      cardNeeded = true;
      if (!this.state.cardlimit || parseInt(this.state.cardlimit) === 0) {
        notification.warning({
          message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Error </span>{this.props.app_labels.error_val_cardLimit}</span>,
          duration: 3,
          style: {
            backgroundColor: '#fffbe6',
            border: "1px solid #ffe58f",
          }
        });
        this.highlightInvalidField(this.cardLimit.current)
        return;
      }
      this.setState({ cardNeeded: cardNeeded });
      budget = this.state.cardlimit;
    } else {
      cardNeeded = false;
      this.setState({ cardNeeded: cardNeeded });
      budget = 0;
    }
    let travellerObj = {
      "cadreId": travellerID,
      "firstName": tempTravellerName,
      "lastName": travellerlastName,
      "emailId": travellerEmail,
      "phoneNo": phNo,
      /* "altPhoneNo": phNo2, */
      "travelerType": travellerType,
      "source": origin,
      "destination": destination,
      "startDate": dateFormat(startDate, 'yyyy-mm-dd'),
      "endDate": dateFormat(endDate, 'yyyy-mm-dd'),
      "budget": budget,
      "hotelApplicable": hotel,
      "flightApplicable": flight,
      "creditCardNo": null,
      'travelerNotified': false,
      "crDepartment": this.state.department,
      "crRole": this.state.role,
      "cardNeeded": cardNeeded,
      "accountingCode": accountingcode,
      "tripId": this.props.tripId,
      "travelerId": this.state.travellerUniqueId,
      "bookedByTraveler": this.state.bookedByTraveler,
      "save": true,
      "entityCode1": entityType1,
      "entityTypeCode1": entityId1,
      "entityCode2": entityType2,
      "entityTypeCode2": entityId2,
    }
    if (phNo2 !== null) {
      travellerObj.altPhoneNo = phNo2
    }
    this.props.savenewtrip(travellerObj, this.saveDraftSpin);

    this.empid.current.disabled = true;
    this.travelerlastname.current.disabled = true;
    this.email.current.disabled = true;
    this.phno.current.disabled = true;
    this.phno2.current.disabled = true;
  }

  disableOnCardFail = () => {
    this.empid.current.disabled = true;
    this.travelerlastname.current.disabled = true;
    this.email.current.disabled = true;
    this.phno.current.disabled = true;
    this.phno2.current.disabled = true;
    this.setState({ disableFlag: true })
  }
  /**************save drafts ends************ */

  /**create card modal is enabled**/
  showModal = () => this.setState({ visible: true });

  /**create card modal is disabled**/
  onCloseModal = () => this.setState({ visible: false });

  /**when user changes card limit we are updating it on state variable
   * @param  {} event
   * @param  {} maskedvalue
   * @param  {} floatvalue
   */
  cardlimitChange(event, maskedvalue, floatvalue) {
    if ((event.target.value + "").length <= 6) {
      this.setState({ cardlimit: parseInt(event.target.value) });
      return;
    }
  }
  /**
   * @param  {} =>{axios.get(URLs.comUrl+"candidate/id"
   * generating candidiate Id for new Candidate
   */
  uniqueIdgen = (event, value) => {
    if (event === 'onChange') {
      this.setState({ travellerID: value })
      return;
    }
    if (this.state.newTravellerEmail.trim() === "") {
      return;
    }
    this.setState({ savedraftspin: true, disableFlag: true })
    loaderFocus(".loader-color .ant-spin-text");
    axios.get(URLs.adminUrl + "cadreId", {
      params: {
        'type': this.state.newTravellerType.toLowerCase(),
        'email': this.state.newTravellerEmail
      },
      responseType: 'json',
      headers: {
        "Authorization": 'Bearer ' + this.props.token.access_token
      }
    }).then(response => {
      if (response.data.serviceStatus.statusCode === 200) {
        if (!response.data.data.isNewUser) {
          var phonenumber = response.data.data.phoneNo.split(" ");
          if (phonenumber.length === 1) {
            phonenumber[0] = +1;
            phonenumber[1] = response.data.data.phoneNo;
          }
          this.setState({
            newTravellerlastName: response.data.data.lastName,
            newTravellerName: response.data.data.firstName, newTravellerEmail: response.data.data.email,
            phNosuggestion: this.formatMobileNumber(phonenumber[1]),
            code1: phonenumber[0],
            travellerID: response.data.data.cadreId, savedraftspin: false, disableFlag: false,
            travellerUniqueId: response.data.data.travelerId,
          })
        } else {
          if (!response.data.data.cadreId) {
            this.empid.current.disabled = false;
            this.setState({ savedraftspin: false, disableFlag: false })
            this.phno.current.focus()
          }
          this.setState({ travellerID: response.data.data.cadreId, savedraftspin: false, disableFlag: false });
          /*  this.email.current.focus() */
        }

      }
      else if (response.data.serviceStatus.statusCode === 210) {
        this.saveDraftSpin(false, false)
        this.email.current.focus()
        notification.error({
          message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Error </span>{response.data.serviceStatus.message}</span>,
          duration: 3,
          style: {
            backgroundColor: '#fff1f0',
            border: "1px solid #ffa39e",
          }
        });
      }
      else {
        this.saveDraftSpin(false, false);
        this.email.current.focus()
        notification.error({
          message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Error </span>{this.props.app_labels.error_fetchCandidateId}</span>,
          duration: 3,
          style: {
            backgroundColor: '#fff1f0',
            border: "1px solid #ffa39e",
          }
        });
      }
    }).catch((error) => {
      this.saveDraftSpin(false, false);
      this.email.current.focus()
      if (error.response !== undefined && error.response.data !== null && error.response.data !== undefined && error.response.data.serviceStatus !== undefined) {
        notification.error({
          message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Error </span>{error.response.data.serviceStatus.message}</span>,
          duration: 3,
          style: {
            backgroundColor: '#fff1f0',
            border: "1px solid #ffa39e",
          }
        });
      }
      else {
        notification.error({
          message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Error </span>{this.props.app_labels.couldNotcompleteReq}</span>,
          duration: 3,
          style: {
            backgroundColor: '#fff1f0',
            border: "1px solid #ffa39e",
          }
        });
      }
    })
  }

  /**changing traveler type will clear the form fields
   * @param  {} e
  **/
  travelerTypeChange = (value) => {
    this.empid.current.disabled = true;
    this.travelername.current.disabled = false;
    this.travelerlastname.current.disabled = false;
    this.email.current.disabled = false;
    this.phno.current.disabled = false;
    this.accountingCode.current.disabled = false;
    // this.accountingCode.current.props.value = "";
    if (value.value === "Employee") {
      //this.empid.current.disabled = false;
      document.getElementById("myForm").reset();
    }
    this.phno.current.value = "";
    this.phno2.current.value = "";
    this.accountingCode.current.value = "";
    this.setState({ newDisableType: false, role: "", department: "", bookedByTraveler: false, newTravellerType: value.value, startdatetemp: null, enddatetemp: null, code1: "+1", newTravellerName: "", newTravellerlastName: "", newTravellerEmail: "", phNosuggestion: "", travellerID: "", accountingCode: "", origin: "", destination: "", travellerUniqueId: null });
  }

  /**changing the selection of card nedded or not
   * @param  {} e
   */
  onRadioChange = (e) => this.setState({ radiovalue: e.target.value });

  /********hotel and flight selection(checkbox)********* */
  onChangecheck(checkedValues) {
    this.setState({ traveldetails: checkedValues });
  }

  /**updating state variables on change of date 
   * @param  {} e
   */
  handeldatechange(e) {
    if (e.length !== 0) {
      this.setState({ startdatetemp: e[0]._d.toString() });
      this.setState({ enddatetemp: e[1]._d.toString() });
    }
  }

  /**Can not select days before today and today
   * @param  {} current
   */
  disabledDate = (current) => {
    return current && current < moment().startOf('day');
  }

  /**creating new trip with card creation
   * @param  {} creditCardNo
   */
  handlenewTripandCard(startdate, selectedtrip1) {
    /*    this.setState({}) */
    var entityType1 = "";
    var entityType2 = "";
    var entityId1 = "";
    var entityId2 = "";
    if (this.state.fieldDisplayId1) {
      entityType1 = this.state.entityType1;
      entityId1 = this.state.entityID1;
    }
    if (this.state.fieldDisplayId2) {
      entityType2 = this.state.entityType2;
      entityId2 = this.state.entityID2;
    }
    let travellerObj = {
      "cadreId": this.state.travellerID,
      "firstName": this.state.newTravellerName,
      "lastName": this.state.newTravellerlastName,
      "emailId": this.state.newTravellerEmail,
      "phoneNo": this.state.phNo,
      "travelerType": this.state.newTravellerType,
      "source": this.state.origin,
      "destination": this.state.destination,
      "startDate": dateFormat(this.state.startdatetemp, 'yyyy-mm-dd'),
      "endDate": dateFormat(this.state.enddatetemp, 'yyyy-mm-dd'),
      "budget": this.state.budget,
      "hotelApplicable": this.state.hotel,
      "flightApplicable": this.state.flight,
      // "creditCardNo": creditCardNo,
      'travelerNotified': false,
      "cardNeeded": this.state.cardNeeded,
      "crDepartment": this.state.department,
      "crRole": this.state.role,
      "accountingCode": this.state.accountingCode,
      "tripId": this.props.tripId,
      "travelerId": this.state.travellerUniqueId,
      "bookedByTraveler": this.state.bookedByTraveler,
      "save": false,
      "entityCode1": entityType1,
      "entityTypeCode1": entityId1,
      "entityCode2": entityType2,
      "entityTypeCode2": entityId2,
    }
    /* this.props.createCardnew(travellerObj, startdate, this.updatestate); */
    if (this.state.phNo2 !== null) {
      travellerObj.altPhoneNo = this.state.phNo2
    }
    this.props.newtrip(travellerObj, this.saveDraftSpin, this.disableOnCardFail);
    this.setState({ visible: false });
  }

  /**
   * @param  {} element
   *highlighting the field when its epmty or not valid
   */
  highlightInvalidField(element) {
    let label = $(element).siblings('.text-class')
    label = label.length === 0 ? $(element).find('.text-class') : label
    label = label.length === 0 ? $(element).closest('.form-group').find('.text-class') : label
    label.addClass('has-error')
    element.focus()
  }

  /**
  * @param  {} element
  *removing the error class applied during validation on change of event
  */
  clearInvalidField(element) {
    let label = $(element).siblings('.text-class')
    label = label.length === 0 ? $(element).find('.text-class') : label
    label = label.length === 0 ? $(element).closest('.form-group').find('.text-class') : label
    label.removeClass('has-error')
  }

  /**
   * @param  {} element
   Form validation
   */
  validateFormFields() {
    var travellerlastName = this.travelerlastname.current.value;
    var travellerType = this.state.newTravellerType;
    var travellerID = this.empid.current.value;
    var travellerEmail = this.email.current.value;
    var tphno = this.phno.current.value.trim().split(" ").join("");;
    var origin = this.state.origin;
    var destination = this.state.destination;
    var tempTravellerName;
    var startDate = this.state.startdatetemp;
    var endDate = this.state.enddatetemp;
    var accountingcode = this.state.accountingCode;
    var entityId1 = this.state.entityType1;
    var entityId2 = this.state.entityType2;
    tempTravellerName = this.state.newTravellerName;
    // Remove error classes from previous validation, start afresh
    const travelertypeSelector = document.getElementsByClassName('travelertypeSelector');
    const optionEntityType1 = document.getElementsByClassName('optionEntityType1');
    const optionEntityType2 = document.getElementsByClassName('optionEntityType2');
    [this.travelername.current,
    this.travelerlastname.current,
      travelertypeSelector,
    this.empid.current,
    this.email.current,
    this.phno.current,
    this.phno2.current,
    this.origin.current,
    this.destination.current,
    this.accountingCode.current,
      optionEntityType1,
      optionEntityType2,
    this.travelDateRangePicker.current,
    this.cardLimit.current].forEach(el => this.clearInvalidField(el))

    if (!(travellerType && travellerType.trim().length > 0)) {
      notification.warning({
        message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Error </span>{this.props.app_labels.error_req_travelerType}</span>,
        duration: 3,
        style: {
          backgroundColor: '#fffbe6',
          border: "1px solid #ffe58f",
        }
      });
      let label = $(travelertypeSelector).siblings('.text-class')
      label.addClass('has-error');
      return false;
    }

    if (!(tempTravellerName && tempTravellerName.trim().length > 0)) {
      notification.warning({
        message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Error </span>{this.props.app_labels.error_req_travelerName}</span>,
        duration: 3,
        style: {
          backgroundColor: '#fffbe6',
          border: "1px solid #ffe58f",
        }
      });
      this.highlightInvalidField(this.travelername.current)
      return false;
    }

    if (!(travellerlastName && travellerlastName.trim().length > 0)) {
      notification.warning({
        message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Error </span>{this.props.app_labels.error_req_travelerLastName}</span>,
        duration: 3,
        style: {
          backgroundColor: '#fffbe6',
          border: "1px solid #ffe58f",
        }
      });
      this.highlightInvalidField(this.travelerlastname.current)
      return false;
    }

    if (!(travellerID && travellerID.trim().length > 0)) {
      notification.warning({
        message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Error </span>{this.props.app_labels.error_req_travelerId}</span>,
        duration: 3,
        style: {
          backgroundColor: '#fffbe6',
          border: "1px solid #ffe58f",
        }
      });
      this.highlightInvalidField(this.empid.current)
      return false;
    }

    if (!(travellerEmail && travellerEmail.trim().length > 0)) {
      notification.warning({
        message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Error </span>{this.props.app_labels.error_req_emailId}</span>,
        duration: 3,
        style: {
          backgroundColor: '#fffbe6',
          border: "1px solid #ffe58f",
        }
      });
      this.highlightInvalidField(this.email.current)
      return false;
    }

    let emailRegExp = /^\w+([+.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,64})+$/;
    if (emailRegExp.test(travellerEmail) === false) {
      notification.warning({
        message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Error </span>{this.props.app_labels.error_incorrectEmail}</span>,
        duration: 3,
        style: {
          backgroundColor: '#fffbe6',
          border: "1px solid #ffe58f",
        }
      });
      this.highlightInvalidField(this.email.current)
      return;
    }

    if (!(accountingcode && accountingcode.trim().length > 0)) {
      notification.warning({
        message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Error </span>{this.props.app_labels.error_req_accountingcode}</span>,
        duration: 3,
        style: {
          backgroundColor: '#fffbe6',
          border: "1px solid #ffe58f",
        }
      });
      this.setState({ hasError: true })
      this.highlightInvalidField(this.accountingCode.current)
      return false;
    }

    if (!(tphno && tphno.trim().length > 0)) {
      notification.warning({
        message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Error </span>{this.props.app_labels.error_req_phnum}</span>,
        duration: 3,
        style: {
          backgroundColor: '#fffbe6',
          border: "1px solid #ffe58f",
        }
      });
      this.highlightInvalidField(this.phno.current)
      return false;
    }

    let validPhNo = this.validatePhoneNumber(tphno);
    if (validPhNo === false) {
      this.highlightInvalidField(this.phno.current)
      return;
    }

    if (this.phno2.current.value !== "") {
      let newAltPhNo = this.phno2.current.value.trim().split(" ").join("");
      let validAltPhNo = this.validatePhoneNumber(newAltPhNo);
      if (validAltPhNo === false) {
        this.highlightInvalidField(this.phno2.current)
        return;
      }
    }

    if (!(origin && origin.trim().length > 0)) {
      notification.warning({
        message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Error </span>{this.props.app_labels.error_req_origin}</span>,
        duration: 3,
        style: {
          backgroundColor: '#fffbe6',
          border: "1px solid #ffe58f",
        }
      });
      this.highlightInvalidField(this.origin.current)
      return false;
    }

    if (!(destination && destination.trim().length > 0)) {
      notification.warning({
        message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Error </span>{this.props.app_labels.error_req_destination}</span>,
        duration: 3,
        style: {
          backgroundColor: '#fffbe6',
          border: "1px solid #ffe58f",
        }
      });
      this.highlightInvalidField(this.destination.current)
      return false;
    }

    if (!(startDate && startDate.length > 0) || !(endDate && endDate.length > 0)) {
      notification.warning({
        message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Error </span>{this.props.app_labels.error_req_travelDate}</span>,
        duration: 3,
        style: {
          backgroundColor: '#fffbe6',
          border: "1px solid #ffe58f",
        }
      });
      this.highlightInvalidField(this.travelDateRangePicker.current)
      return;
    }

    if (this.state.fieldDisplayId1 === true) {
      if (!(entityId1 && entityId1.trim().length > 0)) {
        notification.warning({
          message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Error </span>{this.props.app_labels.error_req_entityID1}</span>,
          duration: 3,
          style: {
            backgroundColor: '#fffbe6',
            border: "1px solid #ffe58f",
          }
        });
        let label = $(optionEntityType1).siblings('.text-class')
        label.addClass('has-error');
        return false;
      }
    }

    if (this.state.fieldDisplayId2 === true) {
      if (!(entityId2 && entityId2.trim().length > 0)) {
        notification.warning({
          message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Error </span>{this.props.app_labels.error_req_entityID2}</span>,
          duration: 3,
          style: {
            backgroundColor: '#fffbe6',
            border: "1px solid #ffe58f",
          }
        });
        let label = $(optionEntityType2).siblings('.text-class')
        label.addClass('has-error');
        return false;
      }
    }

    return true
  }

  /**creating new trip with out card creation(i.e SAVE AND CONTINUE)
   * @param  {} e
   */
  handlenewTrip(e) {
    e.preventDefault()
    if (this.validateFormFields() !== true) return
    var flight = false;
    var hotel = false;
    for (var i = 0; i < this.state.traveldetails.length; i++) {
      if (this.state.traveldetails[i] === "travelbooking") {
        flight = true;
      }
      else if (this.state.traveldetails[i] === "hotelbooking") {
        hotel = true;
      }
    }
    e.preventDefault();
    var travellerlastName = this.travelerlastname.current.value;
    var travellerType = this.state.newTravellerType;
    var travellerID = this.empid.current.value;
    var travellerEmail = this.email.current.value;
    var tphno = this.phno.current.value.trim().split(" ").join("");
    var accountingcode = this.state.accountingCode;
    var entityType1 = "";
    var entityType2 = "";
    var entityId1 = "";
    var entityId2 = "";
    var origin = this.state.origin;
    var destination = this.state.destination;
    var tempTravellerName = this.state.newTravellerName;
    var phNo = this.state.code1 + " " + tphno;
    var phNo2 = null;
    if (this.phno2.current.value !== "") {
      let tphno2 = this.phno2.current.value.trim().split(" ").join("");
      phNo2 = this.state.code2 + " " + tphno2;
      let validAltPhNo = this.validatePhoneNumber(tphno2);
      if (validAltPhNo === false) {
        this.highlightInvalidField(this.phno2.current)
        return;
      }
    }
    var startDate = this.state.startdatetemp;
    var endDate = this.state.enddatetemp;
    if (this.state.fieldDisplayId1) {
      entityType1 = this.state.entityType1;
      entityId1 = this.state.entityID1;
    }
    if (this.state.fieldDisplayId2) {
      entityType2 = this.state.entityType2;
      entityId2 = this.state.entityID2;
    }
    var budget;
    var cardNeeded;
    if (this.state.radiovalue === "Yes") {
      cardNeeded = true;
      if (!this.state.cardlimit || parseInt(this.state.cardlimit) === 0) {
        notification.warning({
          message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Error </span>{this.props.app_labels.error_val_cardLimit}</span>,
          duration: 3,
          style: {
            backgroundColor: '#fffbe6',
            border: "1px solid #ffe58f",
          }
        });
        this.highlightInvalidField(this.cardLimit.current)
        return;
      }
      this.setState({ cardNeeded: cardNeeded });
      budget = this.state.cardlimit;
    } else {
      cardNeeded = false;
      this.setState({ cardNeeded: cardNeeded });
      budget = 0;
    }
    this.setState({ newTravellerlastName: travellerlastName, newTravellerName: tempTravellerName, hotel: hotel, flight: flight, budget: budget, origin: origin, destination: destination, phNo2: phNo2, phNo: phNo, newTravellerEmail: travellerEmail, travellerID: travellerID, newTravellerType: travellerType, accountingCode: accountingcode, cardNeeded: cardNeeded });
    if (document.getElementById("submitform").value === "createcard") {
      this.showModal();
    }
    else {
      let travellerObj = {
        "cadreId": travellerID,
        "firstName": tempTravellerName,
        "lastName": travellerlastName,
        "emailId": travellerEmail,
        "phoneNo": phNo,
        "travelerType": travellerType,
        "source": origin,
        "destination": destination,
        "startDate": dateFormat(startDate, 'yyyy-mm-dd'),
        "endDate": dateFormat(endDate, 'yyyy-mm-dd'),
        "budget": budget,
        "hotelApplicable": hotel,
        "flightApplicable": flight,
        "creditCardNo": null,
        "crDepartment": this.state.department,
        "crRole": this.state.role,
        'travelerNotified': false,
        "cardNeeded": cardNeeded,
        "accountingCode": accountingcode,
        "tripId": this.props.tripId,
        "travelerId": this.state.travellerUniqueId,
        "bookedByTraveler": this.state.bookedByTraveler,
        "save": false,
        "entityCode1": entityType1,
        "entityTypeCode1": entityId1,
        "entityCode2": entityType2,
        "entityTypeCode2": entityId2,
      }
      if (phNo2 !== null) {
        travellerObj.altPhoneNo = phNo2
      }
      this.props.newtrip(travellerObj, this.saveDraftSpin, this.disableOnCardFail);
    }
    /* if (document.getElementById("submitform").value !== "createcard") {
      this.setState({ spin: true });
    } */
  }
  /**validating phone number entered by user
   * @param  {} phoneNumber
   */
  validatePhoneNumber = (phoneNumber) => {
    let valid = false;
    try {
      let regexp = /^\d{10}$/;

      if (regexp.test(phoneNumber) === true) {
        valid = true;
      } else {
        valid = false;
      }

      if (phoneNumber.length !== 10 || phoneNumber.indexOf("555") === 0) {
        valid = false
      }
      else {
        valid = true;
      }
    } catch (e) {
      valid = false;
    }
    if (valid) {
      return true
    } else {
      notification.warning({
        message: <span role='alert' aria-live="assertive" ><span className="visually-hidden">Error </span>{this.props.app_labels.error_invalidPhno}</span>,
        duration: 3,
        style: {
          backgroundColor: '#fffbe6',
          border: "1px solid #ffe58f",
        }
      });
      return false;
    }
  }

  updatestate = (value) => this.setState({ spin: value });

  autoSuggestSpinState = (value) => {
    this.setState({ autosuggestSpin: value })
  };

  saveDraftSpin = (value, disableFlagvalue) => {
    this.setState({ savedraftspin: value, disableFlag: disableFlagvalue })
  };

  isDigit(character) {
    return '0123456789'.indexOf(character) === -1 ? false : true
  }

  formatMobileNumber(input) {
    input = input.substring(0, 12)
    let result = []
    // let divisionCount = 0
    for (let i = 0; i < input.length; i++) {
      if (!this.isDigit(input[i])) {
        continue
      }
      result.push(input[i])
    }
    this.setState({
      phNo: result.join('')
    })
    switch (true) {
      case result.length > 6 && result.length <= 10:
        result.splice(3, 0, ' ')
        result.splice(7, 0, ' ')
        break;
      case result.length > 3 && result.length <= 6:
        result.splice(3, 0, ' ')
        break;
        default:
    }
    return result.join('')
  }

  formatAltMobileNumber(input) {
    input = input.substring(0, 12)
    let result = []
    // let divisionCount = 0
    for (let i = 0; i < input.length; i++) {
      if (!this.isDigit(input[i])) {
        continue
      }
      result.push(input[i])
    }
    this.setState({
      phNo2: result.join('')
    })
    switch (true) {
      case result.length > 6 && result.length <= 10:
        result.splice(3, 0, ' ')
        result.splice(7, 0, ' ')
        break;
      case result.length > 3 && result.length <= 6:
        result.splice(3, 0, ' ')
        break;
        default: 
    }
    return result.join('')
  }

  render() {
    // const { value, suggestions } = this.state;
    // const inputProps = {
    //   value,
    //   onChange: this.onChangeofValue
    // };
    if (this.props.redirect) {
      if (this.props.activeCard) {
        Modal.info({
          title: 'This Trip has been assigned with already existing card',
          onOk() { },
        });
      }
      return <Redirect to='/pendingTrip' />;
    }

    return (
      this.state.spin === false ?
        <div className="create-trip-container">
          <div>
            <Layout>
              <div><HeaderComponent roles={"Manager"} /></div>
              <Layout className="App">
                <Header style={{ backgroundColor: "#f2f2f2" }}><span className="App-title"><b>{this.props.app_labels.createTripHeading}</b></span></Header>
                <Content style={{ margin: "-5px 0px 0", width: "100%" }}>
                  <div /* className="container-fluid" */>
                    <form className="form-inline" id="myForm">
                      <div style={{ padding: 24, borderRadius: "15px", background: "#fff", minHeight: 360 }}>
                        <MediaQuery minDeviceWidth={770}>
                          <div className="row">
                            <div className="col-sm-4 col-sm-offset-0">
                              <img alt="Create Profile" src={Bluebar} style={{ width: "100%" }} title={progressBarHoverText_Blue.createProfile} />
                            </div>
                            <div className="col-sm-4 col-sm-offset-0 progressbar-margin">
                              <img alt="Upload Itenerary" src={Greybar} style={{ width: "100%" }} title={progressBarHoverText_Blue.uploadItenerary} />
                            </div>
                            <div className="col-sm-4 col-sm-offset-0 progressbar-margin">
                              <img alt="Send Info"  src={Greybar} style={{ width: "100%" }} title={progressBarHoverText_Blue.sendInfo} />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-4 col-sm-offset-1">
                              <span className="progressbar-text">{this.props.app_labels.createTripProgressBarHeading}</span>
                            </div>
                          </div>
                        </MediaQuery>
                        <br></br>
                        <div className="row">

                        </div>

                        {this.state.autosuggestSpin ? <div className="dark-spinner loader-color"><Spin tip="Loading..." /></div> : null}
                        <MediaQuery minDeviceWidth={765}> <br /><br /></MediaQuery>
                        <div className="row">
                          {/* Traveller Type */}
                          <div className="col-sm-4 col-md-3 spacebtwn-columns">
                            <div className="form-group" style={{ display: "block"}}>
                              <label className="text-class" for='travelerTypeLabel'>{this.props.app_labels.typeOfTraveler}</label><br />
                              <div aria-label="Type of Traveler" role="list" >
                                <Select 
                                  className='travelertypeSelector' 
                                  id='travelerTypeLabel' 
                                  isDisabled={this.state.disableFlag} onChange={(value) => {
                                  let travelertypeSelector = document.getElementsByClassName('travelertypeSelector');
                                  this.clearInvalidField(travelertypeSelector)
                                  this.travelerTypeChange(value)
                                }}
                                  styles={customStyles_dropdown}
                                  classNamePrefix="react-select"
                                  isSearchable={false}
                                  placeholder={this.props.app_labels.selectTravelerType}
                                  options={[
                                    { value: 'Employee', label: 'Employee' },
                                    { value: 'Candidate', label: 'Candidate' },
                                  ]}
                                  theme={theme => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                      ...theme.colors,
                                      primary25: "#e6f7ff",
                                      primary: "lightgrey"
                                    }
                                  })}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-4 col-md-3 spacebtwn-columns">
                            <div className="form-group">
                              <span className="text-class">{this.props.app_labels.department}</span><br /><br />
                              <input value={this.state.department}
                                type="text"
                                aria-label="Department"
                                placeholder={this.props.app_labels.selectTraveleDepartment}
                                disabled={this.state.disableFlag}
                                style={{ paddingTop: "2%" }}
                                onChange={(e) => {
                                  this.setState({ department: e.target.value })
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-sm-4 col-md-3 spacebtwn-columns">
                            <div className="form-group">
                              <span className="text-class">{this.props.app_labels.role} </span><br /><br />
                              <input value={this.state.role}
                                type="text"
                                aria-label="Role"
                                placeholder={this.props.app_labels.selectRole}
                                disabled={this.state.disableFlag}
                                style={{ paddingTop: "2%" }}
                                onChange={(e) => {
                                  this.setState({ role: e.target.value })
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <MediaQuery minDeviceWidth={765}> <br /><br /></MediaQuery>
                        <div className="row" >
                          {/* First Name */}
                          <div className="col-sm-4 col-md-3 spacebtwn-columns">
                            <div className="form-group">
                              <div>
                                <label><span className="text-class">{this.props.app_labels.firstName_AdminView} </span>
                                  {/* <span className="required">*</span> */}</label><br />
                                <TravellerAutosuggest
                                 app_labels = {this.props.app_labels}
                                  spinFlagUpdate={this.autoSuggestSpinState}
                                  disableFlag={this.state.disableFlag}
                                  newDisableType={this.state.newDisableType}
                                  getSuggestionValue={this.getSuggestionValue}
                                  handleTravellerSuggestionInput={(value, type) => this.handleTravellerSuggestionInput(value, type)}
                                  setInputRef={(ele) => {
                                    this.travelername.current = ele
                                  }}
                                  value={this.state.newTravellerName}
                                  type={this.state.newTravellerType}
                                  className='travelplace'
                                  clearInvalidField={() => this.clearInvalidField(this.travelername.current)}
                                />
                              </div>
                            </div>
                          </div>
                          {/* Last name */}
                          <div className="col-sm-4 col-md-3 spacebtwn-columns">
                            <div className="form-group">
                              <span className="text-class">{this.props.app_labels.lastName_AdminView} </span>
                              {/* <span className="required">*</span> */}
                              <br /><br />
                              <input value={this.state.newTravellerlastName}
                                placeholder={this.props.app_labels.selectLastName}
                                type="text"
                                aria-label="Traveler Last Name"
                                style={{ paddingTop: "2%" }}
                                ref={this.travelerlastname}
                                onKeyPress={(event) => {
                                  if (event.key.replace(/[^\w\-.]/g, '') === '') event.preventDefault();
                                }
                                }
                                onChange={(e) => {
                                  this.setState({ newTravellerlastName: e.target.value })
                                  this.clearInvalidField(e.target)
                                }}
                              />
                            </div>
                          </div>
                          {/* Email ID */}
                          <div className="col-sm-4 col-md-3 spacebtwn-columns">
                            <div className="form-group">
                              <span className="text-class">{this.props.app_labels.email} </span>
                              <span className="required">*</span>
                              <br /><br />
                              <input value={this.state.newTravellerEmail}
                                disabled={this.state.disableFlag}
                                type="text"
                                aria-label="Email ID"
                                placeholder={this.props.app_labels.selectEmail} 
                                ref={this.email}
                                onBlur={() => {
                                  this.uniqueIdgen('onBlur', null);
                                }}
                                onChange={(e) => {
                                  /*  if ((this.empid.current.value === "" || this.empid.current.value === null || this.empid.current.value === undefined)) {
                                     this.uniqueIdgen();
                                   } */
                                  this.clearInvalidField(e.target);
                                  this.setState({ newTravellerEmail: e.target.value.trim() })
                                }
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <MediaQuery minDeviceWidth={765}> <br /><br /></MediaQuery>
                        <div className="row">
                          {/* Phone Number */}
                          <div className="col-sm-4 col-md-3 spacebtwn-columns">
                            <div className="form-group">
                              <span className="text-class">{this.props.app_labels.mobileNumber} </span>
                              <span className="required">*</span>
                              <br /><br />
                              <div>
                                <PhoneCode className='phoneCode' onSelect={code => this.setState({ code1: code })}
                                  showFirst={['US', 'IN']}
                                  defaultvalue={this.state.code1}
                                />
                                <input value={this.state.phNosuggestion}
                                  placeholder={this.props.app_labels.SelectPh}
                                  onChange={(e) => {
                                    this.clearInvalidField(e.target)
                                    e.target.value = this.formatMobileNumber(e.target.value)
                                    this.setState({ phNosuggestion: e.target.value })
                                  }}
                                  style={{ width: "73%", height: '2%' }}
                                  type="text"
                                  aria-label="Mobile Number"
                                  onKeyPress={this.restrictMinus}
                                  ref={this.phno}
                                />
                              </div>
                            </div>
                          </div>
                          {/* Alternative number */}
                          <div className="col-sm-4 col-md-3 spacebtwn-columns">
                            <div className="form-group">
                              <span className="text-class">{this.props.app_labels.altMobileNumber} </span>
                              <br /><br />
                              <PhoneCode className='phoneCode' onSelect={code => this.setState({ code2: code })}
                                showFirst={['US', 'IN']}
                                defaultValue='select county' />
                              <span>
                                <input style={{ width: "73%", height: '2%' }}
                                  placeholder={this.props.app_labels.SelectPh}
                                  type="text"
                                  aria-label="Alternate Contact Number"
                                  onKeyPress={this.restrictMinus}
                                  onChange={(e) => {
                                    this.clearInvalidField(e.target)
                                    /* let cursorPosition = e.target.selectionEnd
                                    e.target.setSelectionRange(cursorPosition, cursorPosition) */
                                    e.target.value = this.formatAltMobileNumber(e.target.value)

                                  }}
                                  ref={this.phno2}
                                />
                              </span>
                            </div>
                          </div>
                          <div className="col-sm-4 col-md-3 spacebtwn-columns"   >
                            <div className="form-group">
                              <span className="text-class">{this.props.app_labels.travelerId} </span>
                              <span className="required">*</span>
                              <br /><br />
                              <input value={this.state.travellerID}
                                placeholder={this.props.app_labels.selectID}
                                type="text"
                                aria-label="Traveler ID"
                                ref={this.empid}
                                onChange={(e) => {
                                  if ((e.target.value !== "" || e.target.value !== null || e.target.value !== undefined)) {
                                    this.uniqueIdgen('onChange', e.target.value);
                                  }
                                  this.clearInvalidField(e.target);
                                  this.setState({ travellerID: e.target.value })
                                }
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <MediaQuery minDeviceWidth={765}> <br /><br /></MediaQuery>
                        <div className="row">
                          <div className="col-sm-4 col-md-3 col-md-offset-0 spacebtwn-columns">
                            <div className="form-group">
                              <span className="text-class">{this.props.app_labels.source} </span><span className="required">*</span>
                              <br /><br />
                              <AirportAutosuggest
                               app_labels = {this.props.app_labels}
                                disableFlag={this.state.disableFlag}
                                handleTravelPlaceInput1={(value, type) => this.handleTravelPlaceInput1(value, type)}
                                handleTravelPlaceInput={(value, type) => this.handleTravelPlaceInput(value, type)}
                                setInputRef={(ele) => {
                                  this.origin.current = ele
                                }}
                                value={this.state.origintemp}
                                type='origin'
                                labelName='Travel Origin'
                                className='travelplace'
                                id='autocomplete'
                                clearInvalidField={() => this.clearInvalidField(this.origin.current)}
                              />
                            </div>
                          </div>
                          <div className="col-sm-4 col-md-3 spacebtwn-columns" >
                            <div className="form-group">
                              <span className="text-class">{this.props.app_labels.destination} </span> <span className="required">*</span>
                              <br /><br />
                              <AirportAutosuggest
                               app_labels = {this.props.app_labels}
                                disableFlag={this.state.disableFlag}
                                handleTravelPlaceInput1={(value, type) => this.handleTravelPlaceInput1(value, type)}
                                handleTravelPlaceInput={(value, type) => this.handleTravelPlaceInput(value, type)}
                                setInputRef={(ele) => {
                                  this.destination.current = ele
                                }}
                                value={this.state.destinationtemp}
                                type='destination'
                                className='travelplace'
                                labelName='Travel Destination'
                                id='autocompleteDest'
                                clearInvalidField={() => this.clearInvalidField(this.destination.current)}
                              />
                            </div>
                          </div>
                          <div className="col-sm-4 col-md-3 spacebtwn-columns">
                            <div className="form-group" tabIndex="0" ref={this.travelDateRangePicker}>
                              <label className="text-class">{this.props.app_labels.estimatedTravelDate} <span className="required">*</span></label>
                              <br />
                              <label for="EstimatedTravelDates">
                                <RangePicker allowClear={false}
                                  disabled={this.state.disableFlag}
                                  style={{ width: "100%", paddingTop: "2%" }}
                                  disabledDate={this.disabledDate}
                                  id='EstimatedTravelDates'
                                  className="datepick-bgclass"
                                  placeholder={[this.props.app_labels.from, this.props.app_labels.to]}
                                  format="MM/DD/YYYY"
                                  onChange={(e) => {
                                    this.clearInvalidField(this.travelDateRangePicker.current)
                                    this.handeldatechange(e)
                                  }}
                                /></label></div>
                          </div>
                        </div>
                        
                        <MediaQuery minDeviceWidth={765}> <br /><br /></MediaQuery>
                        <div className="row">
                          <div className="col-sm-4 col-md-3 spacebtwn-columns">
                            <div className="form-group" style={{ display: "block"}}>
                              <span className="text-class">{this.props.app_labels.accountingCode} </span>
                              <br /><br />
                              <AccountingCodeAutosuggest
                                app_labels = {this.props.app_labels}
                                disableFlag={this.state.disableFlag}
                                newDisableType={this.state.newDisableType}
                                handleAccountingCodeInput1={value => this.handleAccountingCodeInput1(value)}
                                handleAccountingCodeInput={(value, obj) => this.handleAccountingCodeInput(value, obj)}
                                notFound={(msg) => this.setState(() => ({ accountErrorMessage: msg }))}
                                setInputRef={(ele) => this.accountingCode.current = ele }
                                value={this.state.accountingCodetemp}
                                labelName='Accounting Code'
                                className='travelplace'
                                id='autocomplete'
                                clearInvalidField={() => this.clearInvalidField(this.accountingCode.current)}
                              />
                            </div>
                            {this.state.accountErrorMessage && 
                            <span style={{ color: 'red' }}>{this.state.accountErrorMessage}</span>}
                          </div>
                          {this.state.fieldDisplayId1 &&
                            <div className="col-sm-4 col-md-3 spacebtwn-columns">
                              <div className="form-group">
                                <label className="text-class" for='entityType1Label'>{this.props.app_labels.entityTypeID1} <span className="required">*</span></label><br />
                                <div aria-label="Entity Type 1" role="list" >
                                  <Select className='optionEntityType1' id='entityType1Label' 
                                  isDisabled={this.state.disableFlag}
                                  onChange={value => {
                                    let optionEntityType1 = document.getElementsByClassName('optionEntityType1');
                                    this.clearInvalidField(optionEntityType1)
                                    this.setState({ entityType1: value.value })
                                  }}
                                  placeholder={this.props.app_labels.selectEntity1}
                                    styles={customStyles_dropdown}
                                    classNamePrefix="react-select"
                                    isSearchable={false}
                                    options={this.optionEntityType1()}
                                    theme={theme => ({
                                      ...theme,
                                      borderRadius: 0,
                                      colors: {
                                        ...theme.colors,
                                        primary25: "#e6f7ff",
                                        primary: "lightgrey"
                                      }
                                    })}
                                  />
                                </div>
                              </div>
                            </div>}
                          {this.state.fieldDisplayId2 &&
                            <div className="col-sm-4 col-md-3 spacebtwn-columns" >
                              <div className="form-group">
                                <label className="text-class" for='entityType2Label'>{this.props.app_labels.entityTypeID2} <span className="required">*</span></label><br />
                                <div aria-label="Entity Type 2" role="list" >
                                  <Select className='optionEntityType2' id='entityType2Label' 
                                  isDisabled={this.state.disableFlag}
                                  onChange={value => {
                                    let optionEntityType2 = document.getElementsByClassName('optionEntityType2');
                                    this.clearInvalidField(optionEntityType2)
                                    this.setState({ entityType2: value.value })
                                  }}
                                    placeholder={this.props.app_labels.selectEntity2}
                                    styles={customStyles_dropdown}
                                    classNamePrefix="react-select"
                                    isSearchable={false}
                                    options={this.optionEntityType2()}
                                    theme={theme => ({
                                      ...theme,
                                      borderRadius: 0,
                                      colors: {
                                        ...theme.colors,
                                        primary25: "#e6f7ff",
                                        primary: "lightgrey"
                                      }
                                    })}
                                  />
                                </div>
                              </div>
                            </div>}
                          {/* <div className="col-sm-4 col-md-3 col-md-offset-0 spacebtwn-columns" >
                            <div className="form-group">
                             
                            </div>
                          </div> */}
                        </div>
                        <MediaQuery minDeviceWidth={765}> <br /><br /></MediaQuery>
                        <div className="row">
                          <div className="col-sm-4 col-md-3 spacebtwn-columns">
                            <div className="form-group"><label for='airHotelcheckbox' className="text-class">{this.props.app_labels.selectAirnHotel}</label>
                              <br /><Checkbox.Group style={{ width: '100%', marginLeft: "9%", marginTop: '4%' }}
                                disabled={this.state.disableFlag}
                                id='airHotelcheckbox'
                                onChange={this.onChangecheck}>
                                <div className="row">
                                  <Col className="checkbox-bgcolor" span={8}>
                                    <Checkbox aria-label="Flight Booking" value="travelbooking"><i role='img' aria-label="Flight" title="Flight booking" className="icon-Airplane-blue-size-1" style={{ fontWeight: "bold", fontSize: "18px" }} ><span style={{ display: "none" }}>{this.props.app_labels.flightCheckbox}</span></i></Checkbox>
                                  </Col>
                                  <Col span={8}>
                                    <Checkbox aria-label="Hotel Booking" value="hotelbooking"><i role='img' aria-label="Hotel" title="Hotel booking" className="icon-Buildings-blue-size-1" style={{ fontWeight: "bold", fontSize: "18px" }} ><span style={{ display: "none" }}>{this.props.app_labels.hotelCheckbox}</span></i></Checkbox>
                                  </Col>
                                </div>
                              </Checkbox.Group>
                            </div>
                          </div>
                        </div>
                        <br />
                        <MediaQuery minDeviceWidth={765}> <br /><br /></MediaQuery>
                        <div className="row">
                          <div className="col-sm-10 col-md-10 col-md-offset-0 col-sm-offset-0 spacebtwn-columns">
                            <span className="cardDetails">{this.props.app_labels.cardDetails}</span><span className="required">*</span><br /><hr className="dash-filelist-line" style={{ width: "102%" }} /><br />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-10 spacebtwn-columns col-sm-offset-0">
                            <div className="form-group">
                              <RadioGroup size="large" onChange={this.onRadioChange}
                                value={this.state.radiovalue}
                                disabled={this.state.disableFlag}>
                                <Radio value={"Yes"}><span className="text-class">{this.props.app_labels.cardNeeded}</span></Radio>
                                <Radio value={"No"}><span className="text-class">{this.props.app_labels.cardNotNeeded}</span></Radio>
                              </RadioGroup>
                              <br /><br /><br />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-4 col-sm-offset-0 col-md-3 col-md-offset-1 spacebtwn-columns">
                            {
                              this.state.radiovalue === "Yes" ?
                                <div className="form-group">
                                  <span className="text-class">{this.props.app_labels.cardLimit}</span> <br />
                                  <div className="dollar">
                                    <input type="number"
                                      min={0}
                                      aria-label='Card Limit'
                                      disabled={this.state.disableFlag}
                                      value={this.state.cardlimit}
                                      onChange={(e) => {
                                        this.clearInvalidField(e.target)
                                        this.cardlimitChange(e)
                                      }}
                                      ref={this.cardLimit}
                                    />
                                  </div>
                                  <br /><br /><br />
                                </div> :
                                null
                            }
                          </div>
                        </div>
                      </div>
                      <div className="row buttonTopPadding">
                        <div className="col-xs-12 col-sm-3 col-sm-offset-0 col-md-3 col-md-offset-0" style={{ textAlign: 'right' }}>
                          {
                            this.props.tripId === "" || this.props.tripId === null ?
                              null :
                              <button type="button" className="focusbutton button-wrapclass button-createtrip-ghost" onClick={this.cancelTrip}
                              ><b>{this.props.app_labels.cancelTrip}</b>
                              </button>
                          }
                        </div>
                        <div className="col-xs-12 col-sm-9 col-sm-offset-0 col-md-9 col-md-offset-0" style={{ textAlign: 'right' }}>
                          {
                            this.props.tripId === "" || this.props.tripId === null ?
                              <button type="button" onClick={this.handleSaveDraft} className="focusbutton button-wrapclass buttonRightPadding button-createtrip-ghost" disabled={this.props.savedraftspin}>
                                <b>{this.props.app_labels.saveDraft}</b>
                              </button>
                              : null
                          }
                          {/*  </div>
                        <div className="col-sm-3 col-sm-offset-0" style={{ textAlign: 'right' }}> */}
                          {
                            this.state.radiovalue === "Yes" ?
                              <span>
                                <button type="button"
                                  id="submitform"
                                  value="createcard"
                                  className="focusbutton button-createtrip button-wrapclass"
                                  onClick={this.handlenewTrip} >
                                  <b>{this.props.app_labels.createCard}</b>
                                </button>
                              </span>
                              : null
                          }

                          {
                            this.state.radiovalue === "No" ?
                              <span>
                                <button type="button"
                                  id="submitform"
                                  value="saveandconti"
                                  className="focusbutton button-createtrip button-wrapclass"
                                  onClick={this.handlenewTrip}>
                                  <b>{this.props.app_labels.SavenContinue}</b>
                                </button>
                              </span>
                              : null
                          }
                        </div>
                      </div>
                    </form>
                  </div>
                  {
                    this.state.visible === true ?
                      <CreateCard app_labels = {this.props.app_labels} action={this.state.visible} startdate={this.state.enddatetemp} hideModal={this.onCloseModal} handlenewTripandCard={this.handlenewTripandCard} newtripobj={this.state.newtripobj} />
                      : null
                  }
                </Content>

                {this.state.savedraftspin === true ? <div className="dark-spinner loader-color"><Spin tip="Loading..." /></div> : null}

              </Layout>
              <Footer id='footer'></Footer>
            </Layout>
          </div>
        </div > : <div className="dark-spinner loader-color"><Spin tip="Loading..." /></div>
    );
  }
}
function mapStateToProps(state, props) {
  return {
    resmessage: state.createtripReducer.createtripmessage,
    tripId: state.createtripReducer.tripId,
    redirect: state.createtripReducer.redirect,
    token: state.authenticationReducer.token,
    activeCard: state.createtripReducer.activeCard,
    /* savetrip: state.createtripReducer.savetrip, */
    cardLimitvalue: state.createtripReducer.cardLimitvalue,
    travelleruniqueIdnew: state.createtripReducer.travelleruniqueId,
    app_labels: state.adduserReducer.app_labels,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    newtrip: (travellerObj, updatestate, disableOnCardFail) => {
      dispatch(createtrip(travellerObj, updatestate, disableOnCardFail));
    },
    savenewtrip: (travellerObj, saveDraftSpin) => {
      dispatch(savetrip(travellerObj, saveDraftSpin));
    },
    changeTripStatus: (tripId, status, cadreID) => {
      dispatch(tripStatus(tripId, status, cadreID));
    },
    /*  createCardnew: (travellerObj, startDate, updatestate) => {
            dispatch(createCard(travellerObj, startDate, updatestate));
          }, */
    cardLimit: () => {
      dispatch(getCardLimit());
    }
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Createtrip);
