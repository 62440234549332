import React from 'react'
import { Row, Col } from 'antd'
import TravelerDetails from "./TravelerDetails"
import TripDetails from './TripDetails'

class TripTravelerInformationContainer extends React.Component {
  render() {
    return (
      <Col 
        xs={{span: 24}} 
        sm={{span: 24}} 
        md={{span: 8}} 
        lg={{span: 8}} 
        xl={{span: 6}}
      >
        <Row>
          <Col 
            xs={{span: 24}} 
            sm={{span: 12}} 
            md={{span: 24}} 
            lg={{span: 24}} 
            xl={{span: 24}}
          >
            <TravelerDetails travelerData={this.props.travelerData}/>
          </Col>
          <Col 
            xs={{span: 24}} 
            sm={{span: 12}} 
            md={{span: 24}} 
            lg={{span: 24}} 
            xl={{span: 24}}
          > 
            <TripDetails tripData={this.props.tripData}
              downloadFile={this.props.downloadFile}
            />
          </Col>
          {/* <Col 
            xs={{span: 24}} 
            sm={{span: 24}} 
            md={{span: 24}} 
            lg={{span: 24}} 
            xl={{span: 6}}
          >
            <Footer />
          </Col> */}
        </Row>
      </Col>
    )
  }
}

export default TripTravelerInformationContainer
