import axios from 'axios';
import React from 'react';
import { notification } from 'antd';
const { URLs,errorMessages } = require('../../data/constants.js');
/**********getExpense category and All*************/
export function getExpenseCategoryAll(updateCatAllSpin, updateCatAllState) {
    return (dispatch, getState) => {
        let {
            token,
        } = getState().authenticationReducer;

        axios.get(URLs.tripUrl + "getCompanyExpenseCategory/All", {
            responseType: 'json',
            headers: {
                "Authorization": 'Bearer ' +  token.access_token
            }
        }).then(response => {
            updateCatAllSpin(true);
            if (response.data.serviceStatus.statusCode === 200) {
                updateCatAllState(response.data.data);
            }
            else if (response.data.serviceStatus.statusCode === 210) {
                notification.error({
                    message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{response.data.serviceStatus.message}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }
        }).catch((error) => {
            updateCatAllSpin(true);
            if (error.response !== undefined && error.response.data !== null && error.response.data !== undefined && error.response.data.serviceStatus !== undefined) {
                notification.error({
                    message: <span  role='alert' aria-live="assertive" aria-atomic="true" ><span className="visually-hidden">Error </span>{error.response.data.serviceStatus.message}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }
            else {
                notification.error({
                    message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{errorMessages.generic_error}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }
        })
    }
}


/*******************transaction type all****************/
export function getTransactionTypeAll(updateTypeAllSpin, updateTypeAllState) {
    return (dispatch, getState) => {
        let {
            token,
        } = getState().authenticationReducer;
        axios.get(URLs.tripUrl + "getTransactionTypes/All", {
            responseType: 'json',
            headers: {
                "Authorization": 'Bearer ' +  token.access_token
            }
        }).then(response => {
            updateTypeAllSpin(true);
            if (response.data.serviceStatus.statusCode === 200) {
                updateTypeAllState(response.data.data);
            }
            else if (response.data.serviceStatus.statusCode === 210) {
                notification.error({
                    message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{response.data.serviceStatus.message}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }
        }).catch((error) => {
            updateTypeAllSpin(true);
            if (error.response !== undefined && error.response.data !== null && error.response.data !== undefined && error.response.data.serviceStatus !== undefined) {
                notification.error({
                    message: <span  role='alert' aria-live="assertive" aria-atomic="true" ><span className="visually-hidden">Error </span>{error.response.data.serviceStatus.message}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }
            else {
                notification.error({
                    message: <span  role='alert' aria-live="assertive" aria-atomic="true" ><span className="visually-hidden">Error </span>{errorMessages.generic_error}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }
        })
    }
}