import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Modal, Slider, Button } from 'antd';
import Filter from '../../images/provisioner/filter.png'
import cancelBtn from '../../images/provisioner/cancelBtn.svg'
import filterBtn from '../../images/provisioner/filterBtn.svg'
let marks = {
  0: '0$',
  10000: '10000$'
}

export class FilterComp extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isModalVisible: false,
      range: [],
    }
  }

  // componentDidMount() {
  //   if (this.props) {
  //     console.log(this.props)
  //     this.setState({left: this.props.min, right: this.props.max})
  //   }
  // }

  componentDidUpdate() {
    if(this.state.isModalVisible){
        setTimeout(() => {
          const div = document.getElementsByClassName('ant-slider-handle');
          
          if (div && !div[0].getAttribute('aria-label')) {
            div[0].setAttribute('aria-label', 'Credit limit min');
            div[1].setAttribute('aria-label', 'Credit limit max');
          }
             
            // this.modalBtnRef.current.buttonNode.focus();
        }, 500)
    }
}

  


  render() {
    const showModal = () => {
      this.props.showFilter();
      this.setState({ isModalVisible: true });
      // setTimeout(() =>{document.getElementsByClassName('ant-slider-handle')[0].focus()}, 500)
    };

    const handleOk = () => {
      var range = [this.props.left, this.props.right]
      this.setState({ isModalVisible: false })
      return (
        <img role="button" tabIndex={0} src={filterBtn} alt="Apply filter" onClick={
          this.props.filterRange(range)
        } />
      )

    };

    const handleCancel = () => {

      return (
        <img role="button" tabIndex={0} src={cancelBtn} alt="cancel" onClick={this.setState({ isModalVisible: false })}
        />
      )
    };


    let setFilterRange = (value) => {

      marks = {
        [value[0]]: value[0] + '$',
        [value[1]]: value[1] + '$'
      };
      this.setState({ range: value })

    }
    const minValue = this.props.min;
    const maxValue = this.props.max;
    const left = this.props.left;
    const right = this.props.right;

    return (
      <>
        <Button role="button" tabIndex={0} type="primary filter-comp filter-myAct" onClick={showModal} style={{ height: "auto", backgroundColor: '#19398A', borderColor: '#19398A' }}>
          <img className="filter" src={Filter} alt="Filter" />
        </Button>
        <Modal  className="confirm-visible filter-modal actCard-filter-zoom" style={{marginLeft:"33%"}}
            title={<h2 style={{fontSize: '16px', fontWeight:"700", color: "#161a80"}}>{this.props.label_keys.filter}</h2>}
                        
            visible={this.state.isModalVisible} 
            onOk={handleOk} 
            onCancel={handleCancel} 
            cancelText={this.props.label_keys.cancel}
            okText={this.props.label_keys.applyFilter}>
          <br />
          <div>
            <p className="messageCard-zoom" style={{textAlign: "left"}}>{this.props.label_keys.creditLimit} </p>
            <Slider 
              range className="messageCard-slider"
              tooltipVisible={this.state.isModalVisible ? true : false}
              tooltipPlacement="bottom"
              min={minValue} 
              max={maxValue} 
              value={[left, right]}
              onChange={(e) => this.props.handleSliderChange(e)}
              />
          </div>
          <br />
        </Modal>
      </>
    )
  }
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(FilterComp)

