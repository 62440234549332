/* App.js */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Row, Col, Select } from 'antd'
import moment from 'moment';
import GraphIntervalSelect from './GraphIntervalSelect';
const { Option } = Select;

class DashboardGraph extends Component {
    constructor(props) {
        super(props);

        this.state = {
            SelectedGraphOption: "Current Week",
            graphDropdown: "weekly",
            interval: null
        };
    }

    componentDidUpdate() {
        let weeklyData = []
        let montlyData = []
        let weeklyInterval
        let monthlyInterval
        let interval
        if (this.props.creditLimit) {
            this.props.creditLimit && this.props.creditLimit['weekly'].forEach(data => {
                weeklyData.push(data.creditLimit)
            })
            this.props.creditLimit && this.props.creditLimit['monthly'].forEach(data => {
                montlyData.push(data.creditLimit)
            })
            weeklyInterval = Math.round((Math.max.apply(null, weeklyData) + 5) / 5)
            monthlyInterval = Math.round((Math.max.apply(null, montlyData) + 5) / 5)
            interval = {
                'weekly': weeklyInterval,
                'monthly': monthlyInterval
            }
            interval && (!this.state.interval || this.state.interval['weekly'] !== interval['weekly'] || this.state.interval['monthly'] !== interval['monthly']) && this.setState({ interval })
        }
    }

    handleChange = (event) => {
        this.setState({ SelectedGraphOption: event.target.textContent,
            graphDropdown: (event.target.textContent === "Weekly" || event.target.textContent.includes("Week")) ? 'weekly' : 'monthly' })
        
    }

    render() {
        return (<>
            <Row gutter={[16, {xs: 24, sm: 24, md:24, lg: 24, xl: 12, xxl: 12}]}>
                <Col xs={1} sm={1} md={6}  lg= {7} xl= {7} xxl= {7}>
                    <div role='heading' aria-level="2" className="graphContent">{this.props.label_keys.creditLimitApproved}</div>
                </Col>
                <Col className="dropDownArrow" xs={{span: 4, offset:12}} sm={{span: 4, offset:12}} md={{span: 4, offset:8}} lg={{span: 4, offset:12}} xl={{span: 4, offset:12}} xxl={{span: 4, offset:12}}>
                    {/* <Select className="dropDownArrow" aria-label="Card Creation Stuatus"
                     value={this.state.graphDropdown} onChange={this.handleChange}>
                        <Option value="weekly"><div  className="cardContent1">{this.props.label_keys.currentWeek}</div></Option>
                        <Option value="monthly"><div  className="cardContent1">{this.props.label_keys.currentMonth}</div></Option>
                    </Select> */}
                    <GraphIntervalSelect className="dropDownArrow-approver" 
                    value={this.state.SelectedGraphOption}
                        ariaLabel="Credit limit approved status" week={this.props.label_keys.currentWeek}
                        month={this.props.label_keys.currentMonth} onChange={this.handleChange}
                    />
                </Col>
            </Row><br />
            <Row style={{ height: '190px' }}>
                {this.state.interval &&
                    <Col span={1} style={{ textAlign: 'right' }}>
                        <div className="visually-hidden">Y-axis represents Credit limit</div>
                        <Row>{this.state.interval[this.state.graphDropdown] * 5}</Row>
                        <Row className="margin-space">{this.state.interval[this.state.graphDropdown] * 4}</Row>
                        <Row className="margin-space">{this.state.interval[this.state.graphDropdown] * 3}</Row>
                        <Row className="margin-space">{this.state.interval[this.state.graphDropdown] * 2}</Row>
                        <Row className="margin-space">{this.state.interval[this.state.graphDropdown]}</Row>
                        <Row className="margin-space">0</Row>
                    </Col>}
                {this.props.creditLimit && this.state.interval && this.props.creditLimit[this.state.graphDropdown].map((e) => (
                    <Col span={1} offset={this.state.graphDropdown === "weekly" ? 2 : 3}>
                        <div className="bar" style={{ height: e.creditLimit * (165 / (this.state.interval[this.state.graphDropdown] * 5)), backgroundColor: "#0A41C5" }}></div>
                    </Col>
                ))}
            </Row>
            <Row style={{ height: '12px', fontSize: '12px' }}>
                <Col span={2}></Col>
                <span className="visually-hidden">{`x-axis represents current ${this.state.graphDropdown === "weekly" ? "week" : "month"}`}</span>
                {this.props.creditLimit && this.props.creditLimit[this.state.graphDropdown].map((e) => (
                    this.state.graphDropdown === "weekly" ? <Col className="dashboard-chart-x" xs={{span: 3}} sm={{span: 3}} md={{span: 3}} lg={3} xl={3} xxl={3}
                    >
                        <span className="visually-hidden">{e.creditLimit> 0 ? `${e.creditLimit} credit limit approved`: ''}</span>&nbsp;                          
                        {(moment(e.day, "DD/MM/YYYY").toString()).substring(8, 10)} {(moment(e.day, "DD/MM/YYYY").toString()).substring(4, 7)} {(moment(e.day, "DD/MM/YYYY").toString()).substring(13, 15)}
                    </Col> : <Col xs={{span: 4}} sm={{span: 4}} md={{span: 4}} lg={4} xl={4} xxl={4}
                        style={{ textAlign: "center",overflow: 'auto' }}><span className="visually-hidden">{e.creditLimit> 0 ? `${e.creditLimit} credit limit approved`: ''}</span>&nbsp;{this.props.label_keys.week} {e.week}</Col>
                ))}
            </Row>
            <br />
            <Row className="cardContent1">
                <Col span={8} style={{width: '35%'}}>
                    <span className="dot3"></span>&nbsp; <span className="visually-hidden">Blue represents </span>&nbsp;
                    {this.props.label_keys.creditLimitApproved}
                </Col>
            </Row></>
        )
    }
}

function mapStateToProps(state, props) {
    return {
        userDetails: state.authenticationReducer.userDetails,
        token: state.authenticationReducer.token
    }
}

export default connect(mapStateToProps, null)(DashboardGraph)