import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Checkbox, Radio, Col, Spin, Button, Layout, Modal } from 'antd';
import { loaderFocus } from '../../utils/accessability';
import { createCard } from '../../actions/createcardActions';
import 'antd/dist/antd.css';
// import '../../styling/createtrip.css';
import { connect } from 'react-redux';
import { createtrip } from '../../actions/createtripActions.js';
import { tripStatus } from '../../actions/changetripstatusAction';
import MediaQuery from 'react-responsive';
import HeaderComponent from '../headercomponent';
import 'react-responsive-ui/style.css';
import CreateCard from './createCard';
import Greybar from '../../images/greyPb.svg';
import Bluebar from '../../images/bluePb.svg';
const { progressBarHoverText_Blue,tripStatusConstants } = require('../../data/constants.js')
const confirm = Modal.confirm;
var dateFormat = require('dateformat');
const { Header, Content, Footer } = Layout;
var phoneNoString = "";
var phoneNoString1 = "";
class CreatetripAfterContinue extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      cancelTripStatus: false,
      spin: false
    }
    this.handlenewTripandCard = this.handlenewTripandCard.bind(this)
  }
  componentDidMount() {
    if (!this.props.userDetails.roles.includes('Manager')) {
      window.history.back();
    }
  }
  /**Enable card creation pop up*/
  showModal = () => {
    this.setState({
      visible: true,
    });

  }
  /**disable card creation pop up*/
  onCloseModal = () => {
    this.setState({ visible: false });
  };
  /**creating new trip with card creation
   * 
   * @param  {} creditCardNo
   * @param  {} selectedtrip1
   */
  handlenewTripandCard(startdate, selectedtrip1) {
    let travellerObj = {
      "cadreId": selectedtrip1.cadreId,
      "firstName": selectedtrip1.firstName,
      "lastName": selectedtrip1.lastName,
      "emailId": selectedtrip1.emailId,
      "phoneNo": selectedtrip1.phoneNo,
      "altPhoneNo": selectedtrip1.altPhoneNo,
      "travelerType": selectedtrip1.travelerType,
      "source": selectedtrip1.source,
      "destination": selectedtrip1.destination,
      "startDate": selectedtrip1.estimatedStartDate,
      "endDate": selectedtrip1.estimatedEndDate,
      "budget": selectedtrip1.budgetAmt,
      "hotelApplicable": selectedtrip1.hotelApplicable,
      "flightApplicable": selectedtrip1.flightApplicable,
      //"creditCardNo": creditCardNo,
      'travelerNotified': false,
      "cardNeeded": selectedtrip1.cardNeeded,
      "accountingCode": selectedtrip1.accountingCode,
      "tripId": selectedtrip1.tripId,
      "travelerId": selectedtrip1.travelerId,
      "bookedByTraveler": false,
      "save": false,
      "crRole": selectedtrip1.crRole,
      "crDepartment": selectedtrip1.crDepartment
    }
    if (selectedtrip1.altPhoneNo !== null) {
      travellerObj.altPhoneNo = selectedtrip1.altPhoneNo
    }
    this.props.newtrip(travellerObj, this.updatestate, this.disableOnCardFail);
    this.setState({ visible: false });
    /* this.props.createCardnew(travellerObj, startdate, this.updatestate);
    this.setState({ spin: true, visible: false }); */
  }

  updatestate = (value, diableFlag) => {
    this.setState({ spin: value });
  }
  /**Creating trip when card is not needed or not selected
   * @param  {} selectedtrip1
   */
  SaveContinue = (selectedtrip1) => {
    let travellerObj = {
      "cadreId": selectedtrip1.traveler.travelerId,
      "firstName": selectedtrip1.traveler.firstName,
      "lastName": selectedtrip1.traveler.lastName,
      "emailId": selectedtrip1.traveler.emailId,
      "phoneNo": selectedtrip1.traveler.phoneNo,
      "altPhoneNo": selectedtrip1.traveler.altPhoneNo,
      "travelerType": selectedtrip1.traveler.travelerType,
      "source": selectedtrip1.source,
      "destination": selectedtrip1.destination,
      "startDate": selectedtrip1.estimatedStartDate,
      "endDate": selectedtrip1.estimatedEndDate,
      "budget": selectedtrip1.budgetAmt,
      "hotelApplicable": selectedtrip1.hotelApplicable,
      "flightApplicable": selectedtrip1.flightApplicable,
      "creditCardNo": null,
      'travelerNotified': false,
      "cardNeeded": selectedtrip1.cardNeeded,
      "accountingCode": selectedtrip1.accountingCode,
      "tripId": selectedtrip1.tripId,
      "travelerId": selectedtrip1.traveler.travelerId,
      "bookedByTraveler": false,
    }
    this.props.newtrip(travellerObj, this.updatestate);
  }

  /**Formatting the mobile number by adding space */
  phoneNoFormat = (value, e) => {
    phoneNoString = value.toString()
    phoneNoString1 = ""
    for (let i = phoneNoString.length-1; i > phoneNoString.length-5; i--) {
        if(phoneNoString[i] === ' ') {
            continue
        }
        else {
            phoneNoString1 = phoneNoString[i] + phoneNoString1
        }
    }
    phoneNoString1 = " " + phoneNoString1
    for (let i = phoneNoString.length-5; i > phoneNoString.length-8; i--) {
        if(phoneNoString[i] === ' ') {
            continue
        }
        else {
            phoneNoString1 = phoneNoString[i] + phoneNoString1
        }
    }
    phoneNoString1 = " " + phoneNoString1
    for (let i = phoneNoString.length-8; i > phoneNoString.length-11; i--) {
        if(phoneNoString[i] === ' ') {
            continue
        }
        else {
            phoneNoString1 = phoneNoString[i] + phoneNoString1
        }
    }
    if(phoneNoString.length > 10) {
        phoneNoString1 = " " + phoneNoString1
        for (let i = phoneNoString.length-11; i >= 0; i--) {
            if(phoneNoString[i] === ' ') {
                continue
            }
            else {
                phoneNoString1 = phoneNoString[i] + phoneNoString1
            }
        }
    }
    return phoneNoString1
}

  render() {
    if (this.props.location.continue !== undefined) {
      var selectedtrip = this.props.location.continue;
      var flight = selectedtrip.flightApplicable;
      var hotel = selectedtrip.hotelApplicable;
    }else{
      loaderFocus(".loader-color .ant-spin-text");  
    }

    if (this.props.redirect) {
      if (this.props.activeCard) {
        Modal.info({
          title: 'This Trip has been assigned with already existing card',
          onOk() { },
        });
      }
      return <Redirect to='/pendingTrip' />;
    }

    return (
      <div>
        {selectedtrip !== undefined /* && this.state.spin === false */ ?
          <div>
            <Layout>
              <div><HeaderComponent /></div>
              <Layout className="App">
                <Header style={{ backgroundColor: "#f2f2f2" }}><span className="App-title"><b>{this.props.app_labels.createTripHeading}</b></span></Header>
                <Content style={{ margin: "-5px 0px 0", width: "100%" }}>
                  {/* <MediaQuery minDeviceWidth={980}> */}
                  <div style={{ padding: 24, borderRadius: "15px", background: "#fff", minHeight: 360 }}>
                    <MediaQuery minDeviceWidth={770}>
                      <div className="row">
                        <div className="col-sm-4 col-sm-offset-0">
                          <img alt="" src={Bluebar} style={{ width: "100%" }} title={progressBarHoverText_Blue.createProfile} />
                        </div>
                        <div className="col-sm-4 col-sm-offset-0 progressbar-margin">
                          <img alt="" src={Greybar} style={{ width: "100%" }} title={progressBarHoverText_Blue.uploadItenerary} />
                        </div>
                        <div className="col-sm-4 col-sm-offset-0 progressbar-margin">
                          <img alt="" src={Greybar} style={{ width: "100%" }} title={progressBarHoverText_Blue.sendInfo} />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-4 col-sm-offset-1">
                          <span className="progressbar-text">{this.props.app_labels.createTripProgressBarHeading}</span>
                        </div>
                      </div>
                    </MediaQuery>
                    <br /><br />

                    <div className="row">
                      <div className="col-sm-3 col-sm-offset-1">
                        <span className="text-class">Department</span> <br /><br />
                        <span className="input-text">{selectedtrip.traveler.crDepartment}</span>
                      </div>
                      <div className="col-sm-3 spacebtwn-columns rowspace"  >
                        <span className="text-class">Role</span> <br /><br />
                        <span className="input-text"> {selectedtrip.traveler.crRole}</span>
                      </div>
                      <div className="col-sm-3 spacebtwn-columns rowspace">
                        <span className="text-class">{this.props.app_labels.typeOfTraveler}</span>  <br /><br />
                        <span className="input-text"> {selectedtrip.traveler.travelerType}</span>
                      </div>
                    </div>

                    <div className="row spacebtwn-rows">
                      <div className="col-sm-3 col-sm-offset-1">
                        <span className="text-class">{this.props.app_labels.firstName_AdminView}</span> <br /><br />
                        <span className="input-text">{selectedtrip.traveler.firstName}</span>
                      </div>
                      <div className="col-sm-3 spacebtwn-columns rowspace"  >
                        <span className="text-class">{this.props.app_labels.lastName_AdminView}</span> <br /><br />
                        <span className="input-text">{selectedtrip.traveler.lastName}</span>
                      </div>
                      <div className="col-sm-3 spacebtwn-columns rowspace">
                        <span className="text-class">{this.props.app_labels.email}</span>  <br /><br />
                        <span className="input-text"> {selectedtrip.traveler.emailId}</span>
                      </div>
                    </div>

                    {this.state.spin ? <div className="dark-spinner loader-color"><Spin tip="Assigning card..." /></div> : null}
                    
                    <div className="row spacebtwn-rows">
                      <div className="col-sm-3 col-sm-offset-1">
                        <span className="text-class">{this.props.app_labels.mobileNumber}</span> <br /><br />
                        <span className="input-text">{selectedtrip.traveler.phoneNo  && selectedtrip.traveler.phoneNo !== 'N.A.' ? this.phoneNoFormat(selectedtrip.traveler.phoneNo) : 'N.A.'}</span>
                      </div>
                      <div className="col-sm-3 spacebtwn-columns rowspace">
                        <span className="text-class">{this.props.app_labels.altMobileNumber}</span> <br /><br />
                        <span className="input-text">{selectedtrip.traveler.altPhoneNo && selectedtrip.traveler.altPhoneNo !== 'N.A.' ? this.phoneNoFormat(selectedtrip.traveler.altPhoneNo) : 'N.A.'}</span>
                      </div>
                      <div className="col-sm-3 spacebtwn-columns rowspace">
                        <span className="text-class">{this.props.app_labels.travelerId}</span> <br /><br />
                        <span className="input-text">{selectedtrip.traveler.cadreId}</span>
                      </div>
                    </div>

                    <div className="row spacebtwn-rows">
                      <div className="col-sm-3 col-sm-offset-1">
                        <span className="text-class">{this.props.app_labels.source}</span> <br /><br />
                        <span className="input-text"> {selectedtrip.source}</span>
                      </div>
                      <div className="col-sm-3 spacebtwn-columns rowspace">
                        <span className="text-class">{this.props.app_labels.destination}</span><br /><br />
                        <span className="input-text">  {selectedtrip.destination}</span>
                      </div>
                      <div className="col-sm-3 spacebtwn-columns rowspace">
                        <span className="text-class">{this.props.app_labels.estimatedTravelDate}</span> <br /><br />
                        <span className="input-text">{dateFormat(selectedtrip.estimatedStartDate, "mm/dd/yyyy")}</span><span className="input-text" style={{ fontSize: "20px"}}>&nbsp;&nbsp;to</span>
                        <span className="input-text">{dateFormat(selectedtrip.estimatedEndDate, "mm/dd/yyyy")}</span>
                      </div>
                    </div><br /><br />

                    <div className="row">
                      <div className="col-sm-3 col-sm-offset-1">
                        <span className="text-class">{this.props.app_labels.selectAirnHotel}</span>
                        <br /> <br />
                        <div className="row">
                          <div className="col-sm-10 col-sm-offset-1" style={{ marginLeft: "3%" }}>
                            <Col span={8}><Checkbox checked={flight} value="travelbooking"><i role='img' aria-label="Flight" className="icon-Airplane-blue-size-1" style={{ fontWeight: "bold", fontSize: "18px" }} /></Checkbox></Col>
                            <Col span={8}><Checkbox checked={hotel} value="hotelbooking"><i role='img' aria-label="Hotel" className="icon-Buildings-blue-size-1" style={{ fontWeight: "bold", fontSize: "18px" }} /></Checkbox></Col>
                          </div>
                        </div><br /><br />
                      </div>
                      <div className="col-sm-3 spacebtwn-columns rowspace" >
                        <span className="text-class">{this.props.app_labels.accountingCode}</span> <br /><br />
                        <span className="input-text"> {selectedtrip.accountingCode}</span>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-10 col-sm-offset-1" >
                        {selectedtrip.cardNeeded === true ?
                          <div> <Radio value={"Yes"} defaultChecked={true}><span className="text-class" style={{ fontWeight: "normal" }}>{this.props.app_labels.cardNeeded}</span></Radio></div>
                          :
                          <div><Radio value={"No"} defaultChecked={true}><span className="text-class" style={{ fontWeight: "normal" }}>{this.props.app_labels.cardNotNeeded}</span></Radio></div>
                        }
                        <br /><br /><br />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-11 col-sm-offset-1">
                        <span className="cardDetails">{this.props.app_labels.cardDetails}</span><span className="required">*</span><br /><hr className="dash-filelist-line" style={{ width: "102%" }} /><br />
                        {selectedtrip.cardNeeded === true ? <div className="text-class">{this.props.app_labels.cardLimit} <br /><span className="input-text">${selectedtrip.budgetAmt}</span> <br /><br /><br /></div> : null}
                      </div>
                    </div>
                    {this.state.cancelTripStatus === false ? <div className="row buttonTopPadding">
                      <div className="col-sm-3 col-sm-offset-0">
                        <Button className='button-wrapclass' type="primary"
                          disabled={selectedtrip.tripActionStatus.description.toLowerCase() === tripStatusConstants.PendingClosure.toLowerCase() ||
                            selectedtrip.tripActionStatus.description.toLowerCase() === tripStatusConstants.PendingExpenseReporting.toLowerCase() ||
                            selectedtrip.tripActionStatus.description.toLowerCase() === tripStatusConstants.InProgress.toLowerCase()}
                          ghost onClick={(e) => {
                            e.preventDefault();
                            var current = this;
                            confirm({
                              title: <div className="cardHeading">{this.props.app_labels.confirmCancelTrip}</div>,
                              okText: 'Yes',
                              cancelText: 'No',
                              onOk() {
                                current.props.changeTripStatus(selectedtrip.tripId, "Cancelled", null);
                                current.setState({ cancelTripStatus: true })
                              },
                              onCancel() { },
                            });
                          }
                          }><b>{this.props.app_labels.cancelTrip}</b></Button>
                      </div>
                      <div className="col-sm-9 col-sm-offset-0" style={{ textAlign: 'right' }}>
                        {selectedtrip.cardNeeded === true ? <div><button className="focusbutton button-createtrip button-wrapclass" onClick={this.showModal} ><b>{this.props.app_labels.createCard}</b></button></div> : null}
                        {this.state.visible === true ? <CreateCard app_labels = {this.props.app_labels} action={this.state.visible} startdate={selectedtrip.estimatedStartDate} hideModal={this.onCloseModal} handlenewTripandCard={this.handlenewTripandCard} newtripobj={selectedtrip} /> : null}
                        {selectedtrip.cardNeeded === false ? <div> <button className="focusbutton button-createtrip button-wrapclass" onClick={this.SaveContinue(selectedtrip)}><b>{this.props.app_labels.SavenContinue}</b></button></div> : null}
                      </div>
                    </div> : null}

                  </div>

                </Content>
              </Layout>
              <Footer id='footer'></Footer>
            </Layout>
          </div> : <div className="dark-spinner loader-color"><Spin tip="Loading..." /></div>}
      </div>
    );
  }
}
function mapStateToProps(state, props) {
  return {
    redirect: state.createtripReducer.redirect,
    activeCard: state.createtripReducer.activeCard,
    userDetails: state.authenticationReducer.userDetails,
    app_labels: state.adduserReducer.app_labels,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    newtrip: (obj, updatestate, disableOncardFail) => {
      dispatch(createtrip(obj, updatestate, disableOncardFail));
    },
    createCardnew: (travellerObj, startDate, updatestate) => {
      dispatch(createCard(travellerObj, startDate, updatestate));
    },
    changeTripStatus: (tripId, status) => {
      dispatch(tripStatus(tripId, status));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreatetripAfterContinue);
