import { data } from '../phoneData';


export const schema = {
  name: function (value) {
    let nm= value.replace(/^\s+|\s+$/g,'');
    if(nm.length > 50 || !(/^[^"]+$/.test(nm)))
    return false;
    else
    return true;
  },
  lastName: function (value) {
    let nm= value.replace(/^\s+|\s+$/g,'');
    if(nm.length > 50 || !(/^[^"]+$/.test(nm)))
    return false;
    else
    return true;
  },
  emailId: function (value) {
    return (/^\w+([+.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,64})+$/.test(value));
  },
  cCode: function (value) {
    let values = Object.values(data);
    let countryCodesList = values.map(x => x.code);
    if(value.indexOf("+")> -1)
     return countryCodesList.includes(value);
    else
     return countryCodesList.includes("+"+value);

  },
  mobileNumber: function (value) {
    return (/^\d{10}$/.test(value));
  },
  validFrom: function (value) {
    return /^\d{4}-\d{2}-\d{2}$/.test(value);//yyyy-mm-dd
  },
  validTill: function (value) {
    return /^\d{4}-\d{2}-\d{2}$/.test(value);////yyyy-mm-dd
  },
  creditLimit: function (value) {
    return !isNaN(value) && parseInt(value) == value && value > 0;
  },
  field1: function (value) {
    // return /^[0-9A-Za-z ]{2,60}$/.test(value);
    if (value && (value != '' && value.length > 50))
      return false;
    else
      return true;

  },
  field2: function (value) {
    // return /^[0-9A-Za-z ]{2,60}$/.test(value);
    if (value && (value != '' && value.length > 50))
      return false;
    else
      return true;

  },
  notes: function (value) {
    //return /^[0-9A-Za-z ]{2,60}$/.test(value);
    if(value && (value != '' && value.length > 50))
      return false;
    else
      return true;
  },
  employeeId: function (value) {
    //return /^[0-9A-Za-z ]{2,30}$/.test(value);
    let eId= value && value.trim()
    if (eId && (eId != '' && eId.length > 50))
    return false;
  else
    return true;
  },
  status: function (value) {
    return true
  }

};
