import React, { useState } from 'react';
import { Form, Input, Tooltip, AutoComplete, Dropdown, Select } from 'antd'
import moment from 'moment-timezone';
// import { schema } from './validationSchema'
import { data } from '../phoneData';
import { TextField } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import WarningSign from "../../images/bulk/WarningSign@2x.png"
import { parse } from 'qs';
var dateFormat = require('dateformat');
const FormItem = Form.Item;
const { Option } = Select;

const ActiveCardsEditableContext = React.createContext();
export const EditableRow = ({ form, index, ...props }) => (
  <ActiveCardsEditableContext.Provider value={form}>
    <tr {...props} />
  </ActiveCardsEditableContext.Provider>
);

let values = Object.values(data);

export const EditableFormRowActiveCards = Form.create()(EditableRow);

export class EditableCellActiveCards extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      tooltipVisible: false,
      ariaDescribedBy: null,
      creditLimitData: null,
      updatedData: [],
      validFromEdit: false,
      validToEdit: false,
      error: null,
      validFromDateUpdate: null,
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.editing !== this.props.editing) {
      if (!this.props.editing) {
        // console.log("this.props.editing");
        this.setState({ updatedData: [] });
      }
    }
  }

  handleChange = (event) => {
    let activeData = this.state.updatedData;
    const keyName = this.props.dataIndex;
    
    let validFrom = keyName === 'validFrom' ? event : null;
    let validTo = keyName === 'validTo' ? event : null;
    let creditLimit = keyName === 'creditLimit' ? (event.target.value).replace(/[^0-9]/g, '') : null;

    let data = this.props.updatedActiveData.filter(item => this.props.record.cardRef === item.cardRef);
    // console.log("data::", data, this.props.updatedActiveData);
    if(data && data.length > 0){
      validTo = validTo === null ? data[0].endDate : validTo;
      validFrom = validFrom === null ? data[0].startDate : validFrom;
      creditLimit = creditLimit === null ? data[0].budgetAmount : creditLimit;
    } 

    let cardDetailsObj = {
      startDate: validFrom,
      endDate: validTo,
      cardRef: this.props.record.cardRef,
      budgetAmount: creditLimit,
      osn: this.props.record.osn
    }

    // console.log("cardDetailsObj:::", cardDetailsObj)
    let dataList = activeData.filter(item => cardDetailsObj.cardRef !== item.cardRef);
    dataList.push(cardDetailsObj)
    this.setState({ updatedData: dataList, validFromDateUpdate: keyName === 'validFrom' ? event : this.props.record.validFrom })

    let errorObj = this.validateObj(cardDetailsObj);

    if ((cardDetailsObj.startDate !== null || cardDetailsObj.startDate !== '') && (cardDetailsObj.endDate !== null || cardDetailsObj.endDate !== '') && (cardDetailsObj.budgetAmount !== null || cardDetailsObj.budgetAmount !== '')) {
      this.props.handleSave(cardDetailsObj, errorObj);
    }
  }

  validateObj = cardDetailsObj =>{
    let errObj = {cardRef: cardDetailsObj.cardRef, cL: false, vF: false, vT: false};

    if(cardDetailsObj.budgetAmount !== null && !this.validate('creditLimit', cardDetailsObj.budgetAmount)){
      errObj.cL = true;
    }
    if(cardDetailsObj.startDate !== null && !this.validate('validFrom', cardDetailsObj.startDate)){
      errObj.vF = true;
    }else{
      if(cardDetailsObj.startDate !== null && cardDetailsObj.endDate !== null && !this.checkValidToRule(cardDetailsObj.startDate, cardDetailsObj.endDate))
        errObj.vT = true;
      else if(cardDetailsObj.startDate !== null  && cardDetailsObj.endDate === null && !this.checkValidToRule(cardDetailsObj.startDate, this.props.record.validTo))
        errObj.vT = true;
      
    }
    if(cardDetailsObj.endDate !== null && !this.validate('validTo', cardDetailsObj.endDate)){
      errObj.vT = true;
    }
    return errObj;
  }

  getDate() {
    let validFrom = this.props.record.validFrom;
    // let error = false;
    if (this.props.updatedActiveData && this.props.updatedActiveData.length > 0) {
      let data = this.props.updatedActiveData.filter(item => item.cardRef === this.props.record.cardRef);
      if (data && data.length > 0) {
        if (data[0].startDate !== null) {
          validFrom = data[0].startDate;
        }
      }
    }
    let date = new Date(validFrom)
    date.setDate(date.getDate() + 2)
    return date
  }

  disableDate(current, type) {
    let startDate;
    if (type === "ValidTo") {
      let fromDate = moment(dateFormat(this.getDate(), "mm/dd/yyyy"), 'MM/DD/YYYYY');
      let todayDate = moment(dateFormat(new Date(), "mm/dd/yyyy"), 'MM/DD/YYYYY');
      let days = fromDate.diff(todayDate, 'days');
      if (days > 0)
        startDate = new Date(moment(this.getDate()).tz('America/Chicago').format('MM/DD/YYYY'))
      else
        startDate = new Date(moment(new Date()).tz('America/Chicago').format('MM/DD/YYYY'))
    }
    else
      startDate = new Date(moment(new Date()).tz('America/Chicago').format('MM/DD/YYYY'))
    
    
    let date = new Date(dateFormat(this.props.record.cardCreatedDate? this.props.record.cardCreatedDate : this.props.record.cardCreatedTime, "mm/dd/yyyy"));
    let futureDate = dateFormat(date.setDate(date.getDate() + 364), "mm/dd/yyyy");

    if (startDate) return (current < moment(startDate).startOf('day') || current > moment(futureDate).startOf('day'))
    else return false
  }

  updateValidFrom = (valueUpdate) => {
    this.setState({ validFromDateUpdate: valueUpdate })
  }

  getInput = (keyName, record) => {
    // console.log("this.state.updatedData:::", this.state.updatedData)
    if (keyName === 'creditLimit') {
      let error = null;
      let creditLimit = record.creditLimit;
      if (this.state.updatedData && this.state.updatedData.length > 0) {
        let data = this.state.updatedData.filter(item => item.cardRef === record.cardRef);
        if (data && data.length > 0) {
          creditLimit = (data[0].budgetAmount === null ? record.creditLimit : data[0].budgetAmount);
        }
      }
      if(!this.validate(keyName, creditLimit)){
        error = true;
      }

      return <div className="card-value"><span className="credit-limit-lable-mul">$</span><Input aria-label="Provisioned Credit Limit" required id={`credit-limit-input-${this.props.record.cardRef}`} maxLength={7}
        className={`${(error) ? 'credit-limit-field error-border' : ('credit-limit-field')}`}
        value={creditLimit}
        aria-describedby={error ? "credit-limit-error" : ""}
        onChange={this.handleChange}
      />
        {error &&
          <div id="credit-limit-error" className="credit-limit-error"><span className="visually-hidden">Error:</span>
          <span  className="suggestion-text error-text">{this.props.label_keys.provisionedCreditLimitError}</span></div>}
      </div>
    }
    if (keyName === 'validTo') {
      let validTo = this.props.record.validTo;
      console.log("Initial validTo:::", validTo)
      let error = false;
      console.log("Updated data::", this.props.updatedActiveData);
      if (this.props.updatedActiveData && this.props.updatedActiveData.length > 0) {
        let data = this.props.updatedActiveData.filter(item => item.cardRef === record.cardRef);
        console.log("Valid To Data ::data:::", data)
        if (data && data.length > 0) {
          if (data[0].endDate !== null) {
            validTo = data[0].endDate;
            if(data[0].startDate !== null){
              if(!this.checkValidToRule(data[0].startDate, validTo)){
                error = true;
              }
            }else{
              if(!this.checkValidToRule(this.props.record.validFrom, validTo)){
                error = true;
              }
            }
            // if (!this.validate(keyName, validTo) || (data[0].startDate !== null && !this.checkValidToRule(data.startDate, validTo)))
            //   error = true;
          }else{
            if(data[0].startDate !== null){
              if(!this.checkValidToRule(data[0].startDate, validTo)){
                error = true;
              }
            }
          }
        }
      }

      return <div className="card-value" >
        <Tooltip title={<div><span className="visually-hidden">{this.props.error && this.props.error.validTo ? "Error " : null}</span>{this.props.label_keys.dateWithinCreationTooltip}</div>}
          placement={error ? 'top' :'bottom'} className="valid-to-tooltip"
          trigger={['hover', 'focus']} mouseEnterDelay={0} id='valid-till-tooltip'
          onVisibleChange={() => { this.setState({ tooltipVisible: !this.state.tooltipVisible }) }}
        >
          <div>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                className={`${(error) ? 'valid-to-field error-border' : ('valid-to-field')}`}
                shouldDisableDate={(current) => this.disableDate(current, "ValidTo")}
                disableHighlightToday
                disableRipple
                components={{
                  OpenPickerIcon: CalendarTodayOutlinedIcon,
                }}
                PopperProps={{
                  className: "datepicker-desktop"
                }}
                DialogProps={{
                  className: "datepicker-mobile"
                }}
                OpenPickerButtonProps={{
                  "aria-describedby": this.state.tooltipVisible ? `${error ? "valid-to-error " : ''} valid-till-tooltip`: null,
                }}
                getOpenDialogAriaText={(date, utils) => `Valid To Choose date, selected date is ${utils.format(utils.date(date), 'fullDate')}`}
                inputProps={{
                  tabindex: "-1",
                  readOnly: true,
                  "aria-describedby": this.state.tooltipVisible ? `${error ? "valid-to-error " : ''} valid-till-tooltip`: null,
                  id: "valid-to-field",
                  "aria-label": "card valid to",
                  sx: {
                    "&::placeholder": {
                      color: "#666666",
                      fontSize: "12px",
                      opacity: 1.5,
                    }
                  }
                }}
                InputProps={{ disableUnderline: true }}
                value={validTo}
                onOpen={() => {
                  setTimeout(() => {
                    const val = document.getElementsByClassName('MuiCalendarPicker-root');
                    if (val.length > 0) {
                      const val2 = document.getElementsByClassName(val[0].firstChild.getAttribute('class'));
                      if (val2[0]) {
                        val2[0].firstChild.setAttribute('aria-live', 'assertive');
                        val2[0].firstChild.setAttribute('aria-atomic', 'true');
                      }
                      const selected = document.getElementsByClassName('MuiPickersDay-root Mui-selected MuiPickersDay-dayWithMargin')
                      if (selected.length > 0) {
                        selected[0].focus()
                      }
                    }

                  }, 1000);

                }}
                onAccept={(e) => {
                  let validDate;
                  try {
                    validDate = dateFormat(e, 'mm/dd/yyyy')
                  } catch (err) {
                    validDate = null

                  }
                  // this.setState({ validTo: validDate, validToEdit: (validDate === null||  e === null)? false: true, error: '' })
                  this.handleChange(validDate)
                }}
                onChange={(e) => {
                  console.log("Value valid to::", e);
                  // this.setState({ validTo: validDate, validToEdit: (validDate === null||  e === null)? false: true, error: '' }, () => console.log("Value::", e, this.state.datepickerOpen))
                }}
                renderInput={(params) => <TextField {...params} variant="standard"
                  id="cardValidTo" required
                  className={`${(error) ? 'valid-to-field error-border' : ('valid-to-field')}`}
                />} />
            </LocalizationProvider>
          </div>
        </Tooltip>
        {error === true &&
          <div id="valid-to-error" className="credit-limit-error" style={{textAlign: 'left'}}><span className="visually-hidden">Error:</span>
          <span  className="suggestion-text error-text" style={{fontSize: 12}}>{this.props.label_keys.dateGreaterThanValidFrom}</span></div>}
                                    
        {/* <span>{dateFormat(this.state.selectedRow.validFrom, 'mm/dd/yyyy')} - {this.state.selectedRow.validTo ? dateFormat(this.state.selectedRow.validTo, 'mm/dd/yyyy') : dateFormat(this.state.selectedRow.validTill, 'mm/dd/yyyy')}</span> */}
      </div>;
    }
    if (keyName === 'validFrom') {
      let validFrom = this.props.record.validFrom;
      let error = false;
      if (this.state.updatedData && this.state.updatedData.length > 0) {
        let data = this.state.updatedData.filter(item => item.cardRef === record.cardRef);
        if (data && data.length > 0) {
          if (data[0].startDate !== null) {
            validFrom = data[0].startDate;
            if (!this.validate(keyName, validFrom)) {
              error = true;
            }
          }
        }
      }

      return <div className="card-value" >
        <Tooltip title={<div><span className="visually-hidden">{error ? "Error " : null}</span>{this.props.label_keys.dateWithinCreationTooltip}</div>}
          placement='bottom' className="valid-from-tooltip"
          trigger={['hover', 'focus']} mouseEnterDelay={0}
          id={`valid-from-tooltip`}
          onVisibleChange={() => { this.setState({ tooltipVisible: !this.state.tooltipVisible }) }}
        >
          <div>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                className={`${(error) ? 'valid-from-field error-border' : ('valid-from-field')}`}
                shouldDisableDate={(current) => this.disableDate(current)}
                disablePast
                disableHighlightToday
                disableRipple
                components={{
                  OpenPickerIcon: CalendarTodayOutlinedIcon,
                }}
                PopperProps={{
                  className: "datepicker-desktop"
                }}
                DialogProps={{
                  className: "datepicker-mobile"
                }}
                OpenPickerButtonProps={{
                  "aria-describedby": this.state.tooltipVisible ? "valid-from-tooltip": null,
                }}
                getOpenDialogAriaText={(date, utils) => `Valid From Choose date, selected date is ${utils.format(utils.date(date), 'fullDate')}`}
                inputProps={{
                  tabindex: "-1",
                  readOnly: true,
                  "aria-describedby": this.state.tooltipVisible ? "valid-from-tooltip": null,
                  // commented below line due to A11Y issues
                  //id: "valid-from-field",
                  "aria-label": "card valid from",
                  sx: {
                    "&::placeholder": {
                      color: "#666666",
                      fontSize: "12px",
                      opacity: 1.5,
                    }
                  }
                }}
                InputProps={{ disableUnderline: true }}
                value={validFrom}
                onOpen={() => {
                  setTimeout(() => {
                    const val = document.getElementsByClassName('MuiCalendarPicker-root');
                    if (val.length > 0) {
                      const val2 = document.getElementsByClassName(val[0].firstChild.getAttribute('class'));
                      if (val2[0]) {
                        val2[0].firstChild.setAttribute('aria-live', 'assertive');
                        val2[0].firstChild.setAttribute('aria-atomic', 'true');
                      }
                    }
                    const selected = document.getElementsByClassName('MuiPickersDay-root Mui-selected MuiPickersDay-dayWithMargin')
                    if (selected.length > 0) {
                      selected[0].focus()
                    }

                  }, 1000);

                }}
                onAccept={(e) => {
                  let validValue;
                  try {
                    validValue = dateFormat(e, 'mm/dd/yyyy')
                  } catch (e) {
                    validValue = null

                  }
                  this.handleChange(validValue)
                }}
                onChange={(e) => {
                  console.log("Value change::", e)
                }}
                renderInput={(params) => <TextField {...params} variant="standard"
                  id="cardValidFrom" required
                  className={`${(error) ? 'valid-from-field error-border' : ('valid-from-field')}`}
                />} />
            </LocalizationProvider>
          </div>
        </Tooltip>
      </div>;
    }


  };

  checkValidityRule = (record, cardValidityRule) => {
    let fromDate = moment(dateFormat(this.props.record.validFrom, "mm/dd/yyyy"), 'MM/DD/YYYYY');
    let toDate = moment(dateFormat(this.props.record.validTo, "mm/dd/yyyy"), 'MM/DD/YYYYY');
    let days = toDate.diff(fromDate, 'days');
    return (days <= cardValidityRule && Math.sign(days) !== -1) ? true : false;
  }

  checkCardValidityDates = (dateValue) => {
    let today = new Date(moment(new Date()).tz('America/Chicago').format('MM/DD/YYYY'));
    let date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

    let dateChk = moment(dateValue);
    let toDateTill = moment(date);
    let tillDays = toDateTill.diff(dateChk, 'days');
    let isValidTillDate = tillDays > 0 ? false : true
    let cardCreatedDateChk = moment(this.props.record.cardCreatedDate? this.props.record.cardCreatedDate : this.props.record.cardCreatedTime);
    let bothDaysDiff = dateChk.diff(cardCreatedDateChk, 'days');
    let isBothValid = bothDaysDiff >= 0 && bothDaysDiff < 365 ? true : false;

    if (isValidTillDate && isBothValid) {
      return true;
    }
    else {
      return false;
    }
  }

  checkCardValidityDates = (dateValue) => {
    let today = new Date(moment(new Date()).tz('America/Chicago').format('MM/DD/YYYY'));
    let date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

    let dateChk = moment(dateValue);
    let toDateTill = moment(date);
    let tillDays = toDateTill.diff(dateChk, 'days');
    let isValidTillDate = tillDays > 0 ? false : true
    let cardCreatedDateChk = moment(this.props.record.cardCreatedDate? this.props.record.cardCreatedDate : this.props.record.cardCreatedTime);
    let bothDaysDiff = dateChk.diff(cardCreatedDateChk, 'days');
    let isBothValid = bothDaysDiff >= 0 && bothDaysDiff < 365 ? true : false;

    if (isValidTillDate && isBothValid) {
      return true;
    }
    else {
      return false;
    }
  }

  checkValidToRule = (validFrom, validTo) => {
      let fromDate = moment(dateFormat(validFrom, "mm/dd/yyyy"), 'MM/DD/YYYYY');
      let toDate = moment(dateFormat(validTo, "mm/dd/yyyy"), 'MM/DD/YYYYY');
      let days = toDate.diff(fromDate, 'days');
      
      console.log("CheckValidToRule Result:::", days > 0 && days <= 365 ? true : false)
      return days > 0 && days <= 365 ? true : false;
  }

  getCellData = (keyName, value, record) => {
    return <div >{value}</div>;

  }

  validate = (keyName, value) => {
    
    if (keyName === 'creditLimit') {
      
      let numberReg = /^[1-9]\d*$/;
      if((value === '' || value === null || numberReg.test(value) === false || value < 1 )){
          return false;
      }else{
        return true;
      }
    }

    if(keyName === "validFrom"){
      if(!this.checkCardValidityDates(value))
        return false;
      else
        return true;
        
    }

    if(keyName === "validTo"){
      if(!this.checkCardValidityDates(value))
        return false;
      else
        return true;
    }
    
  }
  render() {
    // console.log("Props:::",  this.props.record);
    const {
      editing,
      dataIndex,
      title,
      inputType,
      record,
      index,
      cardRef,
      ...restProps
    } = this.props;
    return (
      <ActiveCardsEditableContext.Consumer>
        {(form) => {
          const { getFieldDecorator } = form;
          return (
            <td {...restProps}>
              {editing ? (
                <FormItem style={{ margin: 0, width: dataIndex === 'validTo'? '115%': null }}>
                  {(this.getInput(dataIndex, record))}
                </FormItem>
              ) : this.getCellData(dataIndex, restProps.children, record)}
            </td>
          );
        }}
      </ActiveCardsEditableContext.Consumer>
    );
  }

}
