import React, { Component } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import { Row, Col, Card, notification, Button } from 'antd'
// import '../../styling/createtrip.css'
import CreateCard from './CreateCard'
import DashboardChart from './DashboardChart'
import DashboardGraph from './DashboardGraph'
import markGroup163 from '../../images/provisioner/Mask Group 163.svg'
import markGroup164 from '../../images/provisioner/Mask Group 164.svg'
const { URLs, bulkFeature } = require('../../data/constants.js')

class DashboardCardComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            graphData: null,
            rejected: null,
            requests: null,
            sentForApproval: null,
            cardsCreated: null,
            createVisible: false,
            approvalApproved: null,
            approvalRejected: null,
            approvalPending: null,
            approvalgraphData: null,
            creditLimit: null,
            previousActiveElement: null
        }
    }

    componentDidMount() {
        this.loadDashBoardData();
    }

    componentDidUpdate() {
        if(!this.state.createVisible){
            if(this.state.previousActiveElement){
                if (document.body.contains(this.state.previousActiveElement)) {
                    // setTimeout(() => {
                        this.state.previousActiveElement.focus();
                    // }, 100);
                    this.setState({previousActiveElement: null});
                }
            }
        }
    }

    loadDashBoardData = () => {
        if (this.props.userDetails.roles.includes("Provisioner")) {
            axios.get(URLs.cardUrl + `/getProvisionerDashboardData`, {
                headers: {
                    "Authorization": 'Bearer ' + this.props.token.access_token
                }
            }).then(res => {
                res.data.data.graphData && res.data.data.graphData['weekly'].reverse()
                res.data.data &&
                    this.setState({
                        graphData: res.data.data.graphData,
                        rejected: res.data.data.rejected,
                        requests: res.data.data.requests,
                        sentForApproval: res.data.data.sentForApproval,
                        cardsCreated: res.data.data.cardCreated
                    })
            }).catch(err => notification.error({
                message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{this.props.label_keys.couldNotcompleteReq}</span>,
                duration: 3,
                style: {
                    backgroundColor: '#fff1f0',
                    border: "1px solid #ffa39e",
                }
            }));
            if(this.state.previousActiveElement){
                if (document.body.contains(this.state.previousActiveElement)) {
                    setTimeout(() => {
                        const cls = this.state.previousActiveElement;
                        if (cls) cls.focus();
                    }, 100);
                    this.setState({previousActiveElement: null});
                }
            }
        }
        if (this.props.userDetails.roles.includes("Approver")) {
            let userType = this.props.userDetails.roles.includes("Provisioner") && this.props.userDetails.roles.includes("Approver") ? "Both" : ""
            axios.get(URLs.cardUrl + `/getApproverDashboarddata?accessType=` + userType, {
                headers: {
                    "Authorization": 'Bearer ' + this.props.token.access_token
                }
            }).then(res => {
                res.data.data.graphData.approvalStatus && res.data.data.graphData.approvalStatus['weekly'].reverse()
                res.data.data.graphData.creditLimit && res.data.data.graphData.creditLimit['weekly'].reverse()
                res.data.data &&
                    this.setState({
                        approvalgraphData: res.data.data.graphData.approvalStatus,
                        approvalApproved: res.data.data.approved,
                        approvalRejected: res.data.data.rejected,
                        approvalPending: res.data.data.pending,
                        creditLimit: res.data.data.graphData.creditLimit
                    })
            }).catch(err => notification.error({
                message: <span  role='alert' aria-live="assertive" aria-atomic="true" ><span className="visually-hidden">Error </span>{this.props.label_keys.couldNotcompleteReq}</span>,
                duration: 3,
                style: {
                    backgroundColor: '#fff1f0',
                    border: "1px solid #ffa39e",
                }
            }))
        }
    }
    parentRender = () => {
        this.loadDashBoardData();
    }

    render() {
        return (          
            <div role="main"><>
            {this.props.userDetails.roles.includes("Provisioner") &&
            <div> 
                {this.props.userDetails.roles.includes("Approver") && this.props.userDetails.roles.includes("Provisioner") && <span id="request" className="visually-hidden">{this.props.label_keys.myRequests} &nbsp;</span>}
                <Row aria-labelledby={this.props.userDetails.roles.includes("Approver") && this.props.userDetails.roles.includes("Provisioner") ? "request": null} role="list" gutter={16}>
                    {this.props.userDetails.requestCardFeature &&
                        <Col role="listitem" xs={24} sm={24} md={12} lg={6} xl={6} xxl={6} className="pt-10  mt-10">
                            <Card className="user-body" style={{ cursor: 'pointer' }}
                            role="button"  tabIndex={0}
                            aria-labelledby='title req-single single req-bulk bulk'
                            onKeyUp={e => {
                                if (e.keyCode === 13) {
                                    this.props.tabsClick(this.props.label_keys.myRequests)
                                }
                            }} onClick={() => this.props.tabsClick(this.props.label_keys.myRequests)}>
                                <Row>
                                    <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8} >
                                        <img src={require(`../../images/provisioner/${this.props.theme}/requests.svg`).default} alt='' />
                                    </Col>
                                    <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16} className="inside-card">
                                        <div id='title' className="cardContent"> {this.props.label_keys.requests} </div>
                                        <Row>
                                            <Col span={6}>
                                            <div id='req-single' className="card-count">{this.state.requests && this.state.requests['single'] ? this.state.requests['single'] : 0}</div>
                                        {bulkFeature.enable && <div id='single' className="cardContent1" > {this.props.label_keys.single} <span className="visually-hidden">Card</span> </div>}
                                            </Col>
                                            {bulkFeature.enable && <Col span={8} offset={6}>
                                                <div id='req-bulk' className="card-count" >{this.state.requests && this.state.requests['bulk'] ? this.state.requests['bulk'] : 0}</div>
                                                <div id='bulk' className="cardContent1"  > {this.props.label_keys.bulk} <span className="visually-hidden">Card</span></div>
                                            </Col>}
                                        </Row>
                                    
                                    </Col>
                                </Row>
                            </Card>
                        </Col>}
                    {(this.props.userDetails.bulkCardApproval || this.props.userDetails.singleCardApproval) &&
                        <Col role="listitem" xs={24} sm={24} md={12} lg={6} xl={6} xxl={6} className="pt-10  mt-10">
                            <Card className="user-body" style={{ cursor: 'pointer' }} 
                            role="button" aria-labelledby='title-2 req-single-2 single-2 req-bulk-2 bulk-2' tabIndex={0} onKeyUp={e => {
                                if (e.keyCode === 13) {
                                    this.props.tabsClick(this.props.label_keys.myRequests, 'Sent for Approval')
                                }
                            }} onClick={() => this.props.tabsClick(this.props.label_keys.myRequests, 'Sent for Approval')}>
                                <Row>
                                    <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8} >
                                        <img src={require(`../../images/provisioner/${this.props.theme}/sentForApproval.svg`).default} alt='' />
                                    </Col>
                                    <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16} className="inside-card">
                                        <div id='title-2' className="cardContent"> {this.props.label_keys.sentForApproval} </div>
                                        <Row>
                                            <Col span={6}>
                                                <div id='req-single-2' className="card-count">{this.state.sentForApproval && this.state.sentForApproval['single'] ? this.state.sentForApproval['single'] : 0}</div>
                                                {bulkFeature.enable && <div id='single-2' className="cardContent1" > {this.props.label_keys.single} <span className="visually-hidden">Card</span> </div>}
                                            </Col>
                                            {bulkFeature.enable && <Col span={8} offset={6}>
                                                <div id='req-bulk-2' className="card-count">{this.state.sentForApproval && this.state.sentForApproval['bulk'] ? this.state.sentForApproval['bulk'] : 0}</div>
                                                <div id='bulk-2'  className="cardContent1"> {this.props.label_keys.bulk} <span className="visually-hidden">Card</span> </div>
                                            </Col>}
                                        </Row>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>}
                    <Col role="listitem" xs={24} sm={24} md={12} lg={6} xl={6} xxl={6} className="pt-10  mt-10">
                        <Card className="user-body" style={{ cursor: 'pointer' }} role="button"
                        aria-labelledby='title-3 req-single-3 single-3 req-bulk-3 bulk-3' tabIndex={0} onKeyUp={e => {
                            if (e.keyCode === 13) {
                                this.props.tabsClick(this.props.userDetails.viewActiveCardEnable ? this.props.label_keys.myActiveCards : this.props.label_keys.activeCards)
                            }
                        }} onClick={() => this.props.tabsClick(this.props.userDetails.viewActiveCardEnable ? this.props.label_keys.myActiveCards : this.props.label_keys.activeCards)}>
                            <Row>
                                <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8} >
                                    <img src={require(`../../images/provisioner/${this.props.theme}/cardsCreated.svg`).default} alt='' />
                                </Col>
                                <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16} className="inside-card">
                                    <div id='title-3' className="cardContent"> {this.props.label_keys.cardsCreated} <span className="greyContent"> (30 {this.props.label_keys.days}) </span> </div>
                                    <Row>
                                        <Col span={6}>
                                            <div id='req-single-3' className="card-count" >{this.state.cardsCreated && this.state.cardsCreated['single'] ? this.state.cardsCreated['single'] : 0}</div>
                                            {bulkFeature.enable && <div id='single-3' className="cardContent1" > {this.props.label_keys.single} <span className="visually-hidden">Card</span></div>}
                                        </Col>
                                        {bulkFeature.enable && <Col span={8} offset={this.state.cardsCreated && this.state.cardsCreated['bulk'].length > 4 ? 4 : 6}>
                                            <div id='req-bulk-3' className="card-count" >{this.state.cardsCreated && this.state.cardsCreated['bulk'] ? this.state.cardsCreated['bulk'] : 0}</div>
                                            <div id='bulk-3' className="cardContent1" > {this.props.label_keys.bulk} <span className="visually-hidden">Card</span></div>
                                        </Col>}
                                    </Row>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    {(this.props.userDetails.bulkCardApproval || this.props.userDetails.singleCardApproval) &&
                        <Col role="listitem" xs={24} sm={24} md={12} lg={6} xl={6} xxl={6} className="pt-10  mt-10">
                            <Card className="user-body" style={{ cursor: 'pointer' }} role="button" tabIndex={0} 
                            aria-labelledby='title-4 req-single-4 single-4 req-bulk-4 bulk-4'
                            onKeyUp={e => {
                                if (e.keyCode === 13) {
                                    this.props.tabsClick(this.props.label_keys.myRequests, 'Rejected')
                                }
                            }} onClick={() => this.props.tabsClick(this.props.label_keys.myRequests, 'Rejected')}>
                                <Row>
                                    <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8} >
                                        <img src={require(`../../images/provisioner/${this.props.theme}/rejected.svg`).default} alt='' />
                                    </Col>
                                    <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16} className="inside-card">
                                        {this.props.userDetails.roles.includes("Approver") ?
                                            <div id='title-4' className="cardContent" > {this.props.label_keys.requestsRejected} <span className="greyContent"> (30 {this.props.label_keys.days}) </span> </div>
                                            : <div id='title-4' className="cardContent"  > {this.props.label_keys.rejected} <span className="greyContent"> (30 {this.props.label_keys.days}) </span> </div>}
                                        <Col span={6}>
                                            <div id='req-single-4' className="card-count">{this.state.rejected && this.state.rejected['single'] ? this.state.rejected['single'] : 0}</div>
                                            {bulkFeature.enable && <div id='single-4' className="cardContent1"  > {this.props.label_keys.single} <span className="visually-hidden">Card</span> </div>}
                                        </Col>
                                        {bulkFeature.enable && <Col span={8} offset={6}>
                                            <div id='req-bulk-4' className="card-count">{this.state.rejected && this.state.rejected['bulk'] ? this.state.rejected['bulk'] : 0}</div>
                                            <div id='bulk-4' className="cardContent1"  > {this.props.label_keys.bulk} <span className="visually-hidden">Card</span> </div>
                                        </Col>}
                                    </Col>
                                </Row>
                            </Card>
                        </Col>}
                </Row>
            </div> }
            {this.props.userDetails.roles.includes("Approver") && this.props.userDetails.roles.includes("Provisioner") && <br />}
            {this.props.userDetails.roles.includes("Approver") && 
            <div> 
                {this.props.userDetails.roles.includes("Approver") && this.props.userDetails.roles.includes("Provisioner") && <span id="approve" className="visually-hidden">{this.props.label_keys.myApprovals} &nbsp;</span>}
                <Row aria-labelledby="approve" role='list' gutter={16}>
                    <Col role="listitem" xs={24} sm={24} md={12} lg={6} xl={6} xxl={6} className="pt-10  mt-10" >
                        <Card
                            className="user-body" style={{ cursor: 'pointer' }} role="button" tabIndex={0}
                            
                            aria-labelledby='title-5 req-single-5 single-5 req-bulk-5 bulk-5'
                            onKeyUp={e => {
                                if (e.keyCode === 13) {
                                    this.props.tabsClick(this.props.label_keys.myApprovals)
                                }
                            }} onClick={() => this.props.tabsClick(this.props.label_keys.myApprovals)}>
                            <Row>
                                <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8} >
                                    <img src={require(`../../images/provisioner/${this.props.theme}/pendingApproval.svg`).default} alt='' />
                                </Col>
                                <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16} className="inside-card">
                                    <div id='title-5' className="cardContent"> {this.props.label_keys.pendingApproval} </div>
                                    <Row>
                                        <Col span={6}>
                                            <div id='req-single-5' className="card-count">{this.state.approvalPending && this.state.approvalPending['single'] ? this.state.approvalPending['single'] : 0}</div>
                                            {bulkFeature.enable && <div id='single-5' className="cardContent1"   > {this.props.label_keys.single} <span className="visually-hidden">Card</span></div>}
                                        </Col>
                                        {bulkFeature.enable && <Col span={8} offset={6}>
                                            <div id='req-bulk-5' className="card-count">{this.state.approvalPending && this.state.approvalPending['bulk'] ? this.state.approvalPending['bulk'] : 0}</div>
                                            <div id='bulk-5' className="cardContent1" > {this.props.label_keys.bulk} <span className="visually-hidden">Card</span></div>
                                        </Col>}
                                    </Row>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col role="listitem" xs={24} sm={24} md={12} lg={6} xl={6} xxl={6} className="pt-10  mt-10" >
                        <Card className="user-body" tabIndex={0} role="button" 
                            aria-labelledby='title-6 req-single-6 single-6 req-bulk-6 bulk-6' >
                            <Row>
                                <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8} >
                                    <img src={require(`../../images/provisioner/${this.props.theme}/approved.svg`).default} alt='' />
                                </Col>
                                <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16} className="inside-card">
                                    <div id='title-6'  className="cardContent"> {this.props.label_keys.approved} <span className="greyContent"> (30 {this.props.label_keys.days}) </span> </div>
                                    <Row>
                                        <Col span={6}>
                                            <div id='req-single-6' className="card-count">{this.state.approvalApproved && this.state.approvalApproved['single'] ? this.state.approvalApproved['single'] : 0}</div>
                                            {bulkFeature.enable && <div id='single-6' className="cardContent1"  > {this.props.label_keys.single} <span className="visually-hidden">Card</span></div>}
                                        </Col>
                                        {bulkFeature.enable && <Col span={8} offset={6}>
                                            <div id='req-bulk-6' className="card-count">{this.state.approvalApproved && this.state.approvalApproved['bulk'] ? this.state.approvalApproved['bulk'] : 0}</div>
                                            <div id='bulk-6' className="cardContent1"  > {this.props.label_keys.bulk} <span className="visually-hidden">Card</span></div>
                                        </Col>}
                                    </Row>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col role="listitem" xs={24} sm={24} md={12} lg={6} xl={6} xxl={6} className="pt-10  mt-10" >
                        <Card className="user-body" style={{ cursor: 'pointer' }} role="button"
                            aria-labelledby='title-7 req-single-7 single-7 req-bulk-7 bulk-7'
                        tabIndex={0} onKeyUp={e => {
                            if (e.keyCode === 13) {
                                this.props.tabsClick(this.props.label_keys.myApprovals, 'Rejected')
                            }
                        }} onClick={() => this.props.tabsClick(this.props.label_keys.myApprovals, 'Rejected')}>
                            <Row>
                                <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8} >
                                    <img src={require(`../../images/provisioner/${this.props.theme}/rejected.svg`).default} alt='' />
                                </Col>
                                <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16} className="inside-card">
                                    {this.props.userDetails.roles.includes("Provisioner") ?
                                        <div id='title-7' className="cardContent"> {this.props.label_keys.approvalsRejected} <span className="greyContent"> (30 {this.props.label_keys.days}) </span> </div>
                                        : <div id='title-7' className="cardContent"> {this.props.label_keys.rejected} <span className="greyContent"> (30 {this.props.label_keys.days}) </span> </div>}
                                    <Col span={6}>
                                        <div id='req-single-7' className="card-count">{this.state.approvalRejected && this.state.approvalRejected['single'] ? this.state.approvalRejected['single'] : 0}</div>
                                        {bulkFeature.enable && <div id='single-7' className="cardContent1"> {this.props.label_keys.single} <span className="visually-hidden">Card</span></div>}
                                    </Col>
                                    {bulkFeature.enable && <Col span={8} offset={6}>
                                        <div id='req-bulk-7' className="card-count">{this.state.approvalRejected && this.state.approvalRejected['bulk'] ? this.state.approvalRejected['bulk'] : 0}</div>
                                        <div id='bulk-7' className="cardContent1" > {this.props.label_keys.bulk} <span className="visually-hidden">Card</span></div>
                                    </Col>}
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </div>}
            <br /><br />
            <Row gutter={16}>
                {this.props.userDetails.roles.includes("Provisioner") && <>
                    <Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={6}>
                        <Card className="create-cardContent"  >
                            <Row>
                                <Col>
                                <span role="heading" aria-level="2">{this.props.label_keys.cardCreation} </span>
                                </Col>
                            </Row>
                            <Row>
                                <Col style={{ textAlign: 'center' }}>
                                    <img src={markGroup163} alt='' />
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col className="card-tag" >
                                    {this.props.label_keys.singleCardRequest}
                                </Col>
                            </Row>
                            <Row style={{ top: '38px' }}>
                                <Col offset={7}>
                                    <button className="dash_button" style={{ marginTop: '10px', marginLeft: '30px' }}  
                                    // onKeyDown={e => {
                                    //     if (e.keyCode === 13) {
                                    //         this.setState({ createVisible: true, previousActiveElement: document.activeElement })
                                    //     }
                                    // }} 
                                    onClick={() => { this.setState({ createVisible: true, previousActiveElement: document.activeElement }) }}><span>{this.props.label_keys.createCard}</span></button>
                                    {/* <img src={button1} style={{marginTop:'10px'}} onClick={() => { this.setState({ createVisible: true }) }} alt='Card Creation' /> */}
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </>}
                {this.props.userDetails.roles.includes("Provisioner") && bulkFeature.enable &&
                    <Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={6} >
                        <Card className="create-cardContent"  >
                            <Row>
                                <Col>
                                <span role="heading" aria-level="2">{this.props.label_keys.bulkCardCreation}</span>
                                </Col>
                            </Row>
                            <Row>
                                <Col style={{ textAlign: 'center' }}>
                                    <img src={markGroup164} alt='' />
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col className="card-tag" >
                                    {this.props.label_keys.multipleCardRequest}
                                </Col>
                            </Row>
                            <Row style={{ top: '38px' }}>
                                <Col offset={6} >
                                    <button  className="dash_button" style={ { marginTop: '10px', marginLeft: this.props.label_keys.bulkCardCreation.split(' ').length> 3 ?'-10%': '5%' }} onKeyUp={e => {
                                        if (e.keyCode === 13) {
                                            this.props.tabsClick(this.props.label_keys.bulkCardCreation)
                                        }
                                    }} onClick={() => this.props.tabsClick(this.props.label_keys.bulkCardCreation)} ><span>{this.props.label_keys.createBulkCard}</span></button>
                                </Col>
                            </Row>
                        </Card>
                    </Col>}
                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                    <Card style={{ borderRadius: "20px" }}>
                        <DashboardChart
                            graphData={this.state.graphData}
                            approvalgraphData={this.state.approvalgraphData}
                            label_keys={this.props.label_keys}
                        />
                    </Card>
                </Col>
                {this.props.userDetails.roles.includes("Approver") && !this.props.userDetails.roles.includes("Provisioner") && <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} >
                    <Card style={{ borderRadius: "20px" }}>
                        <DashboardGraph
                            creditLimit={this.state.creditLimit}
                            label_keys={this.props.label_keys}
                        />
                    </Card>
                </Col>}
            </Row>
             
            <CreateCard
                createVisible={this.state.createVisible}
                onCLoseCreate={() => this.setState({ createVisible: !this.state.createVisible })}
                label_keys={this.props.label_keys}
                reloadParent={this.parentRender}
                previousActiveElement={this.state.previousActiveElement}
            />
            
        </>
        
        </div>)
    }
}

function mapStateToProps(state, props) {
    return {
        userDetails: state.authenticationReducer.userDetails,
        token: state.authenticationReducer.token,
        theme: state.authenticationReducer.theme
    }
}

export default connect(mapStateToProps, null)(DashboardCardComponent)