import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Dropdown, Icon } from 'antd';
import { MenuItem, Menu, Button } from '@mui/material';
import 'antd/dist/antd.css'
// import '../../styling/provisioner.css'
// const { bulkFeature } = require('../../data/constants.js')

// Accessbility To invoke click method on tab enter.
function onKeyDown(event) {
    if (event.keyCode === 13) {
        // event.preventDefault()
        // document.activeElement.click();
    }

}

function ExportBtn(props) {

    const [visible, setvisible] = useState(false);
    const [previousActiveElement, setPreviousActiveElement] = useState(null)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        const btn = document.getElementById("export-button")
        setAnchorEl(event.currentTarget);
        setPreviousActiveElement(btn);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };


    return (
        <>
            <div style={{ fontStyle: 'U.S.BankCircularWeb-Regular' }}>
                <Button
                    className="export-button allActive-zoom-export"
                    id="export-button"
                    tabIndex={0}
                    disableRipple
                    disableElevation
                    // style={{ backgroundColor: '#39bf71', height: '40px', 
                    // fontSize:'16px', top:'7px', fontWeight: "500", marginLeft:"10px", 
                    //  textTransform: 'none',
                    // fontFamily: "U.S.BankCircularWeb-Bold", letterSpacing:'1px'  }}
                    aria-controls={open ? 'export-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : 'false'}
                    variant="contained"
                    onClick={handleClick}
                    endIcon={<Icon aria-hidden="true" role="img" style={{ color: 'white', marginLeft: '20px', fontSize: 13 }} type="down" />}
                >
                    <span style={{ marginRight: '5px' }}>{props.label_keys.export}</span>
                </Button>
                <Menu
                    id="export-menu"
                    className="export-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClick={handleClose}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'export-button',
                    }}
                    PaperProps={{
                        style: {
                            width: props.selectedLang === "English" ? '17%' :'20%',
                        },
                    }}
                >
                    <MenuItem key="0" className="export-menuItem" disabled={props.selected}
                        onKeyDown={onKeyDown.bind(this)}
                        onClick={() => {
                            handleClose();
                            props.onExport(previousActiveElement);
                            setTimeout(() => { setvisible(!visible) }, 100);
                        }}>
                        <div style={{ color: '#333333', fontSize: 14, fontFamily: 'U.S.BankCircularWeb-Regular', fontWeight: props.selected ? null : 600 }}>{props.label_keys.exportAllCards}</div>
                    </MenuItem>
                    <MenuItem className="export-menuItem" key="1" disabled={!props.selected}
                        onKeyDown={onKeyDown.bind(this)}
                        onClick={() => {
                            // props.tabsClick(props.label_keys.bulkCardCreation)
                            handleClose();
                            props.onExport(previousActiveElement);
                            setTimeout(() => { setvisible(!visible) }, 100);
                        }}>
                        <div style={{ color: '#333333', fontSize: 14, fontFamily: 'U.S.BankCircularWeb-Regular', fontWeight: props.selected ? 600 : null }} >{props.label_keys.exportSelectedCards}</div>
                    </MenuItem>
                </Menu>
            </div>
        </>
    )
}

function mapStateToProps(state, props) {
    return {
        userDetails: state.authenticationReducer.userDetails,
        token: state.authenticationReducer.token,
        iclanguageList: state.instantCardMultiLanReducer.iclanguageList,
        //   app_labels: state.adduserReducer.app_labels,
        //   label_keys: state.instantCardMultiLanReducer.label_keys
    }
}

const mapDispatchToProps = dispatch => {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ExportBtn)

