import React, { Component } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import qs from 'qs'
import { Row, Col, Icon, Button, notification, Drawer, Spin, Input } from 'antd'
import Unassign from '../../images/provisioner/Unassigned.svg'
import { Counter } from './Counter'
import { viewPhoneNumber } from './phoneNumberFormat'

const { URLs } = require('../../data/constants.js')
var dateFormat = require('dateformat')

/*
* renders when user wants to view the card just after that card gets created.
*/
class CardPreview extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showOtp: false,
            previewDetails: null,
            cardHolderDetails: null,
            otpCom: false,
            wrongOTP: false,
            otp1: '',
            otp2: '',
            otp3: '',
            otp4: '',
            /* XW-61 issue - invalid OTP attempts for unlimited times 
            added two more state variable to store sth and 6th otp digits*/
            otp5: '',
            otp6: '',
            show: false,
            unMasked: false,
            activeResend: false,
            thirdWrongOtp: false,
            focusOnClose: true,
            isFocused: true
        }
    }

    /*
     * lifecycle method, its called on mount of the component
     */
    componentDidMount() {
        this.viewCard(true, null)
        this.getCardHolder();
    }

    componentDidUpdate(){
        if(this.props.previewVisible && this.state.focusOnClose){
            setTimeout(() => {
                // document.getElementById("cardDiv").focus();
                this.setState({ focusOnClose: false})
            }, 100);
        }
    }

    /*
    *To get the card details of a perticular card
    */
    viewCard(isMasked, otp) {
        (this.props.createDetails.cardUserId && this.props.createDetails) &&
            axios.get(URLs.cardUrl + `/viewCardList/V1/?cardReferenceNumber=${this.props.createDetails.cardRefId}&isMasked=${isMasked}&OTP=${otp}`, {
                responseType: 'json',
                headers: {
                    "Authorization": 'Bearer ' + this.props.token.access_token
                }
            }).then(response => {
                isMasked === false && this.setState({ unMasked: true, otpCom: false, wrongOTP: false, previewDetails: null })
                this.setState({ previewDetails: response.data.creditcarddata[0] })
            })
                .catch(error => {
                    error.response.data.serviceStatus.type && error.response.data.serviceStatus.type === "otp_error" && this.setState({ wrongOTP: true, otp1: '', otp2: '', otp3: '', otp4: '', otp5: '', otp6: '' })
                    this.setState({ unMasked: false })
                    /* XW-61 issue - invalid OTP attempts for unlimited times
                        now redirecting to OTP screen on third wrong attempt of otp */
                    error.response.data.serviceStatus.type && error.response.data.serviceStatus.type === "authorization_error" && this.setState({ wrongOTP: true, otpCom: false, showOtp: true, thirdWrongOtp: true })
                    notification.error({
                        message: <span  role='alert' aria-live="assertive" aria-atomic="true" ><span className="visually-hidden">Error </span>{this.props.label_keys.couldNotcompleteReq}</span>,
                        duration: 3,
                        style: {
                            backgroundColor: '#fff1f0',
                            border: "1px solid #ffa39e",
                        }
                    })
                })
    }

    /*
     *To get the list of card user for the logged in provisioner
     */
    getCardHolder() {
        this.props.createDetails.cardUserId && axios.get(URLs.cardUrl + `/provisionList/${this.props.createDetails.cardUserId}?searchResultSize=1&pageNum=1`, {
            responseType: 'json',
            headers: {
                "Authorization": 'Bearer ' + this.props.token.access_token
            }
        }).then(response => this.setState({ cardHolderDetails: response.data.data }))
            .catch(error => {
                this.setState({ cardHolderDetails: null })
                notification.error({
                    message: <span  role='alert' aria-live="assertive" aria-atomic="true" ><span className="visually-hidden">Error </span>{this.props.label_keys.couldNotcompleteReq}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                })
            })
    }

    /*
    *To assign the created card to cardUser
     */
    // sendCard = (cardAssignmentId) => {
    //     axios.put(URLs.cardUrl + `/assign/${cardAssignmentId}`, null, {
    //         responseType: 'json',
    //         headers: {
    //             "Authorization": 'Bearer ' + this.props.token.access_token
    //         }
    //     }).then(response => notification.success({
    //         message: <span  role='alert' aria-live="assertive" aria-atomic="true">{this.props.label_keys.assignCardSuccessMsg}</span>,
    //         duration: 3,
    //         style: {
    //             backgroundColor: '#dff0d8',
    //             color: "green",
    //             border: "1px solid green",
    //         }
    //     })
    //     )
    //         .catch(error => notification.error({
    //             message: <span  role='alert' aria-live="assertive" aria-atomic="true">{this.props.label_keys.couldNotcompleteReq}</span>,
    //             duration: 3,
    //             style: {
    //                 backgroundColor: '#fff1f0',
    //                 border: "1px solid #ffa39e",
    //             }
    //         })
    //         )
    // }

    /*
    *To generate otp in order to view the card details
     */
    sendOTP = () => {
        /* XW-61 issue - invalid OTP attempts for unlimited times 
            added two more state variable to store sth and 6th otp digits*/
        this.setState({ otpCom: true, wrongOTP: false, otp1: '', otp2: '', otp3: '', otp4: '', otp5: '', otp6: '', activeResend: false, thirdWrongOtp: false })
        let obj = { username: this.props.userDetails.email, OTP_PREFERENCE: 'email' }
        axios.post(URLs.mfaUrl + 'sendOTP', qs.stringify(obj), {
            headers: {
                "Authorization": "Basic Q2FkcmUtV2ViOmNhZHJl",
            }
        }).then(res => setTimeout(() => this.setState({ activeResend: true }), 30000))
            .catch(err => notification.error({
                message: <span  role='alert' aria-live="assertive" aria-atomic="true" ><span className="visually-hidden">Error </span>{this.props.label_keys.sendOtpErrorMsg}</span>,
                duration: 3,
                style: {
                    backgroundColor: '#fff1f0',
                    border: "1px solid #ffa39e",
                }
            })
            )
    }

    /*
    *To verify the otp and get the card details
     */
    submitOTP = () => {
        /* XW-61 issue - invalid OTP attempts for unlimited times 
            added two more state variable to store sth and 6th otp digits*/
        let otp = this.state.otp1 + this.state.otp2 + this.state.otp3 + this.state.otp4 + this.state.otp5 + this.state.otp6
        this.viewCard(false, otp)
    }

    // Accessbility To invoke click method on tab enter.
    onKeyDown = (event) => {
        if (event.keyCode === 9) {
            console.log("Active element :::: ", document.activeElement);
            // if(document.activeElement ===)
        }
    }


    render() {
        return (
            <Drawer
                aria-labelledby="card-preview"
                role="dialog"
                aria-modal="true"
                width={700}
                closable={false}
                maskClosable={false}
                onClose={this.props.onCLosePreview}
                visible={this.props.previewVisible} >
                <div className="create-head">
                    <b id="card-preview" role='heading' aria-level="2">{this.props.label_keys.preview}</b>
                    {this.state.otpCom === true && <Icon type="close-circle" aria-label="Close"
                    role="button" id="closeBtn"
                    tabIndex={0} onKeyDown={e => {
                        if (e.keyCode === 13) {
                            this.setState({ otpCom: false, showOtp: false, thirdWrongOtp: false });
                        }
                        if(e.key === "Tab" && e.shiftKey){
                            setTimeout(() =>{
                                document.getElementById("close-btn").focus();
                            })
                        }
                    }}
                    onClick={() => this.setState({ otpCom: false, showOtp: false, thirdWrongOtp: false })} />}
                    <div></div>
                </div>

                {this.state.otpCom === false ? <div>
                    {this.state.previewDetails ?
                        <div tabindex={this.state.unMasked? -1 :0} id="cardDiv" 
                        role={this.state.unMasked ? "presentation" :
                            (this.state.isFocused ? "button" : null)}  className={`card-details preview-card detail-card ${this.state.previewDetails.cardType === 'mastercard' ? 'card-img-master' : 'card-img'} ${this.state.showOtp === true ? 'card-blur' : ''} ${this.state.theme === 'elan' ? 'elan' : 'ic'}`}
                        aria-label={this.state.unMasked ? null :(this.state.showOtp? this.state.previewDetails.cardType === 'mastercard' ? 'master card': 'visa card'
                            :`${this.state.previewDetails.cardType === 'mastercard' ? 'master card': 'visa card'} ending with ${this.state.previewDetails.cardNo.substring(this.state.previewDetails.cardNo.length - 4, this.state.previewDetails.cardNo.length)}  expire date  
                       ${this.state.previewDetails.cardExpiryDate.substring(0, 2)}/${this.state.previewDetails.cardExpiryDate.substring(8, 10)}
                       CVV masked`)}
                            onKeyDown={(e) => { 
                                if (e.keyCode === 13) {
                                    this.state.unMasked === false && this.setState({ showOtp: !this.state.showOtp, thirdWrongOtp: false })
                        
                                }
                                if(e.key === "Tab" && e.shiftKey && this.state.isFocused){
                                    setTimeout(() =>{
                                        document.getElementById("close-btn").focus();
                                    })
                                }
                                
                            }}
                            onClick={() => this.state.unMasked === false && this.setState({ showOtp: !this.state.showOtp, thirdWrongOtp: false })} >
                            {this.state.showOtp === false ?
                                <div aria-hidden={this.state.unMasked === true ? false : true}>
                                    <div className="card-head" >
                                            <span style={{ paddingRight: '29%' }}></span>
                                    </div>
                                    <div className="card-num" >
                                        <span style={{letterSpacing: this.state.unMasked === true ? 2.2: null}}>{this.state.unMasked === true ? <span><span className="visually-hidden">{this.state.previewDetails.cardType === 'mastercard' ? 'master card number ' : 'visa card number '}</span>
                                            {`${this.state.previewDetails.cardNo.substring(0, 4)}  ${this.state.previewDetails.cardNo.substring(4, 8)} ${this.state.previewDetails.cardNo.substring(8, 12)} ${this.state.previewDetails.cardNo.substring(12, 16)}`}</span>
                                            : <span style={{letterSpacing: 2.2}}>{this.state.previewDetails.cardNo}</span>}</span>
                                    </div>
                                    <Row className="card-footer" >
                                        <Col span={9}>
                                            <span >{this.props.label_keys.expiration}</span><br />
                                            <span >{this.state.previewDetails.cardExpiryDate.substring(0, 2)}/{this.state.previewDetails.cardExpiryDate.substring(8, 10)}</span>

                                        </Col>
                                        <Col offset={2} span={6} className="card-down-footer">
                                            <span >{this.props.label_keys.cvv}</span><br />
                                            <span >{this.state.previewDetails.cvv}</span>
                                        </Col>
                                    </Row>
                                </div> :
                                <div className="blur-card" style={this.state.thirdWrongOtp ? { padding: '8%' } : null}>
                                    {this.state.thirdWrongOtp ? <span style={{ color: 'red' }}>
                                        <Icon aria-label='Error' type="warning"  /> &nbsp;<b>{this.props.label_keys.regenerateOtp}</b><br /><br /></span> : null}
                                    <span ><b>{this.props.label_keys.cardDetailsMsg}</b></span>
                                    <div>
                                        <Button ghost tabindex={0} className='send-otp-btn'
                                        onFocus={() => this.setState({isFocused: false})}
                                        onBlur={() => this.setState({isFocused: true})}
                                         onKeyDown={(e) =>{
                                            if(e.keyCode === 13)
                                                this.sendOTP()

                                        }}  onClick={this.sendOTP}>{this.props.label_keys.sendOtp}</Button>
                                    </div>
                                </div>
                            }
                        </div> : <Spin className="preview-spin" size="large"  />}
                    {(this.state.previewDetails && this.state.unMasked === false) && <span className="preview-card-bottom">
                        {this.state.showOtp === false ? this.props.label_keys.viewUnmaskedCardMsg : this.props.label_keys.viewMaskedCardMsg}
                    </span>}
                </div> :
                    <div className="preview-otp-body">
                        <div><span >{this.props.label_keys.sendOtpMsg}</span></div>
                        {this.state.wrongOTP === true ?
                            <div role="alert" style={{color: "#666666"}} className="preview-otp-error-text">
                                <Icon aria-hidden="true" type="warning" tabindex={-1} />
                                <span className="visually-hidden">Error </span>
                                {this.props.label_keys.wrongOtpMsg}
                            </div> : <div  className="preview-otp-error-empty"></div>}
                        <div><span  className={`verification-text ${this.state.wrongOTP === true && 'error-text'}`}><b>{this.props.label_keys.verificationCode}</b></span></div>
                        <div className="otp-input">
                            <Input aria-label="OTP first digit" aria-required="true" autocomplete="new-password" maxLength='1' type={this.state.show === false && 'password'} value={this.state.otp1} onChange={e => this.setState({ otp1: e.target.value })}
                                onKeyUp={e => {
                                    (e.keyCode !== 8 && e.keyCode !== 46 && e.keyCode !== 9 && e.keyCode !== 16) && document.getElementById("otp2").focus()
                                }}
                                id="otp1" className={this.state.wrongOTP === true ? "error-border" : (this.state.otp1 !== "" && "blue-border")} />
                            <Input aria-label="OTP second digit" aria-required="true" autocomplete="new-password" maxLength='1' type={this.state.show === false && 'password'} value={this.state.otp2} onChange={e => this.setState({ otp2: e.target.value })}
                                onKeyUp={e => {
                                    (e.keyCode !== 8 && e.keyCode !== 46 && e.keyCode !== 9 && e.keyCode !== 16) && document.getElementById("otp3").focus()
                                    if (e.keyCode === 8) document.getElementById("otp1").focus()
                                }}
                                id="otp2" className={this.state.wrongOTP === true ? "error-border" : (this.state.otp2 !== "" && "blue-border")} />
                            <Input aria-label="OTP third digit" aria-required="true" autocomplete="new-password" maxLength='1' type={this.state.show === false && 'password'} value={this.state.otp3} onChange={e => this.setState({ otp3: e.target.value })}
                                onKeyUp={e => {
                                    (e.keyCode !== 8 && e.keyCode !== 46 && e.keyCode !== 9 && e.keyCode !== 16) && document.getElementById("otp4").focus()
                                    if (e.keyCode === 8) document.getElementById("otp2").focus()
                                }}
                                id="otp3" className={this.state.wrongOTP === true ? "error-border" : (this.state.otp3 !== "" && "blue-border")} />
                            <Input aria-label="OTP fourth digit" aria-required="true" autocomplete="new-password" maxLength='1' type={this.state.show === false && 'password'} value={this.state.otp4} onChange={e => this.setState({ otp4: e.target.value })}
                                onKeyUp={e => {
                                    (e.keyCode !== 8 && e.keyCode !== 46 && e.keyCode !== 9 && e.keyCode !== 16) && document.getElementById("otp5").focus()
                                    if (e.keyCode === 8) document.getElementById("otp3").focus()
                                }}
                                id="otp4" className={this.state.wrongOTP === true ? "error-border" : (this.state.otp4 !== "" && "blue-border")} />
                            {/* XW-61 issue - invalid OTP attempts for unlimited times 
                                    added two more input fields for user to enter 5th and 6th otp digits*/}
                            <Input aria-label="OTP fifth digit" aria-required="true" autocomplete="new-password" maxLength='1' type={this.state.show === false && 'password'} value={this.state.otp5} onChange={e => this.setState({ otp5: e.target.value })}
                                onKeyUp={e => {
                                    (e.keyCode !== 8 && e.keyCode !== 46 && e.keyCode !== 9 && e.keyCode !== 16) && document.getElementById("otp6").focus()
                                    if (e.keyCode === 8) document.getElementById("otp4").focus()
                                }}
                                id="otp5" className={this.state.wrongOTP === true ? "error-border" : (this.state.otp5 !== "" && "blue-border")} />
                            <Input aria-label="OTP sixth digit" aria-required="true" autocomplete="new-password" maxLength='1' type={this.state.show === false && 'password'} value={this.state.otp6} onChange={e => this.setState({ otp6: e.target.value })}
                                onKeyUp={e => {
                                    (e.keyCode !== 8 && e.keyCode !== 46 && e.keyCode !== 9 && e.keyCode !== 16) && document.getElementById("otp6").blur()
                                    if (e.keyCode === 8) document.getElementById("otp5").focus()
                                }}
                                id="otp6" className={this.state.wrongOTP === true ? "error-border" : (this.state.otp6 !== "" && "blue-border")} />
                        </div>
                        {(this.state.otp1 !== '' || this.state.otp2 !== '' || this.state.otp3 !== '' || this.state.otp4 !== '' || this.state.otp5 !== '' || this.state.otp6 !== '') &&
                            <p className="show" tabindex={0} role="button" style={{color: "#666666"}}
                            aria-label={this.state.show === true ? this.props.label_keys.hide : this.props.label_keys.show}
                            onKeyUp={e => {
                              if (e.keyCode === 13) {
                                this.setState({ show: !this.state.show })
                              }
                            }}
                                onClick={() => this.setState({ show: !this.state.show })}>
                                {this.state.show === true ? this.props.label_keys.hide : this.props.label_keys.show}
                            </p>}
                            <br/><br/>
                        <div  className={`resend-otp ${!this.state.activeResend ? 'resend-padding' : ''}`}>
                            {!this.state.activeResend && <Icon type="loading" className="count-down" />}
                            {!this.state.activeResend && <Counter />}
                            <Button className="resend-otp-button" onKeyUp={(e) =>{
                                            if(e.keyCode === 13)
                                                this.sendOTP()

                                        }} 
                                disabled={!this.state.activeResend}
                                onClick={this.sendOTP}>{this.props.label_keys.resendCode}</Button>
                            <Button  className="otp-button" type="primary"
                                disabled={(this.state.otp1 === '' || this.state.otp2 === '' || this.state.otp3 === '' || this.state.otp4 === '' || this.state.otp5 === '' || this.state.otp6 === '') ? true : false}
                                onKeyUp={(e) =>{
                                    if(e.keyCode === 13)
                                        this.submitOTP()

                                }} 
                                onClick={() => this.submitOTP()}>
                                {this.props.label_keys.submit}
                            </Button>
                        </div>
                    </div>}


                <div className="preview-details" >
                   <div className="preview-head" role="heading" aria-level="3" >{this.props.label_keys.cardDetails}<span>:</span></div>
                    <Row className="preview-body" >
                        <Col span={12}>
                            <div ><b>{this.props.label_keys.availableCredit}</b></div>
                            <div  >{(this.state.cardHolderDetails !== null && this.state.cardHolderDetails.length > 0 && this.state.cardHolderDetails[0].BUDGET_AMT) ?
                                <span style={{ color: "#19398A", fontWeight: "bold" }}>${this.state.cardHolderDetails[0].BUDGET_AMT}.00 </span> : <span><span className="visually-hidden">No Value </span><span className="dash-value" aria-hidden="true">——</span></span>}
                            </div>
                        </Col>
                        
                        <Col span={12}>
                            <div ><b>{this.props.label_keys.field1}</b></div>
                            <div >{(this.state.cardHolderDetails !== null && this.state.cardHolderDetails.length > 0 && this.state.cardHolderDetails[0].FIELD1) ?
                                <span>{this.state.cardHolderDetails[0].FIELD1}</span> : <span><span className="visually-hidden">No Value </span><span className="dash-value" aria-hidden="true">——</span></span>}
                            </div>
                        </Col>
                    </Row>
                    <Row className="preview-body">
                        
                        <Col span={12}>
                            <div ><b>{this.props.label_keys.provisionedCredit}</b></div>
                            <div  >{(this.state.cardHolderDetails !== null && this.state.cardHolderDetails.length > 0 && this.state.cardHolderDetails[0].BUDGET_AMT) ?
                                <span >${this.state.cardHolderDetails[0].BUDGET_AMT}</span> : <span><span className="visually-hidden">No Value </span><span className="dash-value" aria-hidden="true">——</span></span>}
                            </div>
                        </Col>
                        <Col span={12}>
                            <div ><b>{this.props.label_keys.field2}</b></div>
                            <div>{(this.state.cardHolderDetails !== null && this.state.cardHolderDetails.length > 0 && this.state.cardHolderDetails[0].FIELD2) ?
                                <span>{this.state.cardHolderDetails[0].FIELD2}</span> : <span><span className="visually-hidden">No Value </span><span className="dash-value" aria-hidden="true">——</span></span>}
                            </div>
                        </Col>
                    </Row>
                    <Row className="preview-body">
                        
                        <Col span={12}>
                            <div ><b>{this.props.label_keys.validity}</b></div>
                            <div >{this.state.previewDetails && this.state.previewDetails.cardCreatedDate ?
                                <span>{dateFormat(this.state.previewDetails.cardCreatedDate, 'mm/dd/yyyy')} - {dateFormat(this.state.previewDetails.piExpiryDate, 'mm/dd/yyyy')}</span>
                                : <span><span className="visually-hidden">No Value </span><span className="dash-value" aria-hidden="true">——</span></span>}
                            </div>
                        </Col>
                        <Col span={12}>
                            <div ><b>{this.props.label_keys.notes}</b></div>
                            <div >{(this.state.cardHolderDetails !== null && this.state.cardHolderDetails.length > 0 && this.state.cardHolderDetails[0].NOTES) ?
                                <span>{this.state.cardHolderDetails[0].NOTES}</span> : <span><span className="visually-hidden">No Value </span><span className="dash-value" aria-hidden="true">——</span></span>}
                            </div>
                        </Col>
                    </Row>
                    <Row className="preview-body">
                        <Col span={12}>
                            <div ><b>{this.props.label_keys.dateCreated}</b></div>
                            <div >{(this.state.previewDetails  &&  this.state.previewDetails.cardCreatedDate) ?
                                <span>{dateFormat(this.state.previewDetails.cardCreatedDate, 'mm/dd/yyyy')}</span> : <span><span className="visually-hidden">No Value </span><span className="dash-value" aria-hidden="true">——</span></span>}</div>
                        </Col>
                        <Col span={12}>
                            <div><b>{this.props.label_keys.billingAddress}</b></div>
                            <div>
                                <span>{this.state.unMasked === true && this.state.previewDetails && this.state.previewDetails.companyAddress
                                    ? <span>
                                        {this.state.previewDetails.companyAddress.flat}&nbsp;
                                    {this.state.previewDetails.companyAddress.streetAddress}&nbsp;
                                    {this.state.previewDetails.companyAddress.city}&nbsp;
                                    {this.state.previewDetails.companyAddress.state}&nbsp;
                                    {this.state.previewDetails.companyAddress.country}&nbsp;
                                    {this.state.previewDetails.companyAddress.zipCode}
                                    </span> : <span><span className="visually-hidden">No Value </span><span className="dash-value" aria-hidden="true">——</span></span>}</span>
                            </div>
                        </Col>
                    </Row>
                    <Row className="preview-body">
                        <Col span={12}>
                            <div ><b>{this.props.label_keys.companyAcctId}</b></div>
                            <div >{(this.state.previewDetails && this.state.previewDetails.accountID != "--") ?
                                <span>{this.state.previewDetails.accountID}</span> : <span><span className="visually-hidden">No Value </span><span className="dash-value" aria-hidden="true">——</span></span>}</div>
                        </Col>
                    </Row>
                </div>

                <div className="preview-details">
                    <div className="preview-head" role="heading" aria-level="3">{this.props.label_keys.cardHolderDetails}<span>:</span></div>
                    <Row className="preview-body">
                        <Col span={12}>
                            <div ><b>{this.props.label_keys.name}</b></div>
                            <div >{(this.state.cardHolderDetails !== null && this.state.cardHolderDetails.length > 0 && this.state.cardHolderDetails[0].FIRST_NAME) ?
                                <span >{this.state.cardHolderDetails[0].FIRST_NAME} {this.state.cardHolderDetails[0].LAST_NAME}</span> : <span><span className="visually-hidden">No Value </span><span className="dash-value" aria-hidden="true">——</span></span>}
                            </div>
                        </Col>
                        <Col span={12}>
                            <div ><b>{this.props.label_keys.employeeId}</b></div>
                            <div >{(this.state.cardHolderDetails !== null && this.state.cardHolderDetails.length > 0 && this.state.cardHolderDetails[0].Employee_ID) ?
                                <span >{this.state.cardHolderDetails[0].Employee_ID}</span> : <span><span className="visually-hidden">No Value </span><span className="dash-value" aria-hidden="true">——</span></span>}
                            </div>
                        </Col>
                    </Row>
                    <Row className="preview-body">
                        <Col span={12}>
                            <div ><b>{this.props.label_keys.emailID}</b></div>
                            <div >{(this.state.cardHolderDetails !== null && this.state.cardHolderDetails.length > 0 && this.state.cardHolderDetails[0].EMAIL) ?
                                <span>{this.state.cardHolderDetails[0].EMAIL}</span> : <span><span className="visually-hidden">No Value </span><span className="dash-value" aria-hidden="true">——</span></span>}
                            </div>
                        </Col>
                        <Col span={12}>
                            <div ><b>{this.props.label_keys.mobileNumber}</b></div>
                            <div >{(this.state.cardHolderDetails !== null && this.state.cardHolderDetails.length > 0 && this.state.cardHolderDetails[0].PHONE_NO) ?
                                <span>{viewPhoneNumber(this.state.cardHolderDetails[0].PHONE_NO)}</span> : <span><span className="visually-hidden">No Value </span><span className="dash-value" aria-hidden="true">——</span></span>}
                            </div>
                        </Col>
                    </Row>
                </div>

                <div className="create-button">
                    {/* <Button className="save-card"
                        onClick={() => {
                            this.props.onCLosePreview()
                            notification.success({
                                message: <span  role='alert' aria-live="assertive" aria-atomic="true">{this.props.label_keys.saveCardSuccessMsg}</span>,
                                duration: 3,
                                style: {
                                    backgroundColor: '#dff0d8',
                                    color: "green",
                                    border: "1px solid green",
                                }
                            })

                        }}>{this.props.label_keys.saveForLater}</Button> */}
                    <Button className="send-card" ref={"closeBtnItem"} id="close-btn"
                        onKeyDown={e =>{
                            if(e.keyCode === 13){
                                this.setState({focusOnClose: true});
                                this.props.onCLosePreview()
                            }
                            if(e.key === "Tab" && !e.shiftKey){
                                if(this.state.otpCom){
                                    setTimeout(() => {
                                        document.getElementById("closeBtn").focus();
                                    }, 100);
                                }else{
                                    setTimeout(() => {
                                        document.getElementById("cardDiv").focus();
                                    }, 100);
                                }
                            }
                        }}
                        onClick={() => {
                            // this.sendCard(this.state.previewDetails.cardAssignmentId)
                            this.setState({focusOnClose: true});
                            this.props.onCLosePreview()
                        }}>{this.props.label_keys.close}</Button>
                        <div tabIndex={0} className="visually-hidden" aria-hidden="true"></div>
                </div>
            </Drawer>
        )
    }
}

function mapStateToProps(state, props) {
    return {
        userDetails: state.authenticationReducer.userDetails,
        token: state.authenticationReducer.token,
        theme: state.authenticationReducer.theme,
        app_labels: state.adduserReducer.app_labels,
    }
}

export default connect(mapStateToProps, null)(CardPreview)
