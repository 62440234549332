import initialState from './initialState';
// import store from '../store/store.js';


const instantCardMultiLanReducer = (state = initialState, action) => {
    switch (action.type) {

        case 'IC_MULTI_LAN_REQUEST':
            return {
                ...state,
                isICMulLanLabelFetch: true
            }
        case 'IC_MULTI_LAN_REQUEST_SUCCESS':
            return {
                ...state,
                isICMulLanLabelFetch: false,
                label_keys: action.response,
            }
        case 'IC_MULTI_LAN_REQUEST_COMP':
            return {
                ...state,
                isICMulLanLabelFetch: false
            }
        case 'IC_LIST_LANGUAGE':
            return {
                ...state,
                isICMulLanLabelFetch: false,
                iclanguageList: action.response
            }
        default:
            return state;
    }
};

export default instantCardMultiLanReducer;