import initialState from './initialState';

const getparticulartripReducer = (state = initialState, action) => {

	switch (action.type) {

		case 'GET__ONETRIP':
			return Object.assign({}, state, { 
				travellerFirstName: action.data[0].traveler.firstName, 
				travelerLastName: action.data[0].traveler.lastName, 
				travellerType: action.data[0].traveler.travelerType, 
				travellerId: action.data[0].traveler.cadreId, 
				travellerEmail: action.data[0].traveler.emailId, 
				phno: action.data[0].traveler.phoneNo, 
				phno2: action.data[0].traveler.altPhoneNo, 
				startdate: action.data[0].estimatedStartDate, 
				enddate: action.data[0].estimatedEndDate, 
				origin: action.data[0].source, 
				destination: action.data[0].destination, 
				budget: action.data[0].budgetAmt, 
				flight: action.data[0].flightApplicable, 
				hotel: action.data[0].hotelApplicable, 
				accountingCode: action.data[0].accountingCode, 
				cardNumber: action.data[0].creditCardNo, 
				creditCardDetails: action.data[0].creditCard, 
				flightstatus: action.data[0].flightStatus, 
				hotelstatus: action.data[0].hotelStatus, 
				cardstatus: action.data[0].cardStatus, 
				flightfile: action.data[0].flight, 
				hotelfile: action.data[0].hotel, 
				travelerNotifield: action.data[0].travelerNotified, 
				actualStartdate: action.data[0].actualStartDate, 
				actualEnddate: action.data[0].actualEndDate, 
				bookedByTraveler: action.data[0].bookedByTraveler === null ? false : action.data[0].bookedByTraveler, 
				department: action.data[0].traveler.crDepartment, 
				role: action.data[0].traveler.crRole,
				tripstatusDescription: action.data[0].tripActionStatus.description,
				entityCode: action.data[0].entityCode
			 });

		case 'IS_FETCHING':
			return Object.assign({}, state, { isFetching: action.data });

		case 'SEND_INFO':
			return Object.assign({}, state, { sendInfo: action.data });



		default:
			return state;
	}
};

export default getparticulartripReducer;