import React from 'react';
import 'antd/dist/antd.css';
import { Tabs } from 'antd';
import ExpenseCategoryComponent from './viewExpenseCategory';
const TabPane = Tabs.TabPane;
class MainExpCategoryComponent extends React.Component {
constructor(props){
    super(props);
    this.state={
        activeKey:"1",
    }
}
    callback = (key) => {
       this.setState({activeKey:key});
    }
    render() {
        return (
            <div className="careteuser_class">
                <Tabs defaultActiveKey="1" onTabClick={this.callback} style={{ marginLeft: "2%" }}>
                    <TabPane tab="Expense Wizard Expense Management" key="1">
                        <ExpenseCategoryComponent app_labels = {this.props.app_labels} activeKey={this.state.activeKey} activeCompanyName={this.props.activeCompanyName} expenseCat={this.props.expenseCat} transactionType={this.props.transactionType} />
                    </TabPane>
                </Tabs>
            </div>
        );
    }
}

export default (MainExpCategoryComponent);