import axios from 'axios';
import React from 'react';
import qs from 'qs';
import { notification } from 'antd';
import { createtrip } from './createtripActions';
import { loaderFocus } from '../utils/accessability';

const { URLs , errorMessages } = require('../data/constants.js');
var dateFormat = require('dateformat');
function getCard(data) {
  return {
    type: "ASSIGN__CARD",
    data
  }
}
export function fetchingCardDbCompletes(data) {
  return {
    type: "IS_DB_CARD_FETCHING",
    data
  }
}

/**
 * Handles creation of card using axios post request
 * @param {*} travellerObj 
 * @param {*} startdate 
 * @param {*} updatestate 
 */
export function createCard(travellerObj, startdate, updatestate) {
  const obj = {
    "endDate": dateFormat(travellerObj.endDate, 'yyyy-mm-dd'),
    "cadreId": travellerObj.cadreId
  }
  return (dispatch, getState) => {
    let {
      token,
    } = getState().authenticationReducer;


    axios.post(URLs.tripUrl + 'requestCard', qs.stringify(obj), {
      responseType: 'json',
      headers: {
        "Authorization": 'Bearer ' + token.access_token
      }
    }).then(result => {

      if (result.data.serviceStatus.statusCode === 210) {
        notification.error({
          message: <span  role='alert' aria-live="assertive" aria-atomic="true" ><span className="visually-hidden">Error </span>
          {result.data.serviceStatus.message}</span>,
          duration: 3,
          style: {
            backgroundColor: '#fff1f0',
            border: "1px solid #ffa39e",
          }
        });
      }
      else if (result.data.serviceStatus.statusCode === 200) {
        dispatch(getCard(result.data));
        notification.success({
          message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Success </span>
          {result.data.serviceStatus.message}</span>,
          duration: 3,
          style: {
            backgroundColor: '#dff0d8',
            color: "green",
            border: "1px solid green",
          }
        });
        travellerObj.creditCardNo = result.data.payload;
        dispatch(createtrip(travellerObj, updatestate));
      }
      else {
        notification.error({
          message: <span  role='alert' aria-live="assertive" aria-atomic="true" ><span className="visually-hidden">Error </span>
          {result.data.serviceStatus.message}</span>,
          duration: 3,
          style: {
            backgroundColor: '#fff1f0',
            border: "1px solid #ffa39e",
          }
        });
      }
    }).catch((error) => {
      if (error.response !== undefined && error.response.data !== null && error.response.data !== undefined && error.response.data.serviceStatus !== undefined) {
        notification.error({
          message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>
          {error.response.data.serviceStatus.message}</span>,
          duration: 3,
          style: {
            backgroundColor: '#fff1f0',
            border: "1px solid #ffa39e",
          }
        });
      }
      else {
        notification.error({
          message: <span  role='alert' aria-live="assertive" aria-atomic="true" ><span className="visually-hidden">Error </span>{errorMessages.generic_error}</span>,
          duration: 3,
          style: {
            backgroundColor: '#fff1f0',
            border: "1px solid #ffa39e",
          }
        });
      }
    })
  }
}

/**
 * Fetches the card details for viewing the card
 * EW-61 - issue - invalid OTP attempts for unlimited times
 * sending otp directly to viewCard method to verify it
 */
export function viewCard(tripId, setCardDetails, setactionOnFail, otp) { 
  return (dispatch, getState) => {
    let {
      token,
    } = getState().authenticationReducer;
    dispatch(fetchingCardDbCompletes(true));
    loaderFocus(".loader-color .ant-spin-text");
    axios.get(URLs.cardUrl + '/viewCard/V1/' + tripId + `?isMasked=false&OTP=${otp}`, { //added otp in reqest query
      responseType: 'json',
      headers: {
        "Authorization": 'Bearer ' + token.access_token
      }
    }).then(result => {
      dispatch(fetchingCardDbCompletes(false));
      if (result.data.serviceStatus.statusCode === 210) {
        setactionOnFail();
        notification.error({
          message: <span  role='alert' aria-live="assertive" aria-atomic="true" ><span className="visually-hidden">Error </span>{result.data.serviceStatus.message}</span>,
          duration: 3,
          style: {
            backgroundColor: '#fff1f0',
            border: "1px solid #ffa39e",
          }
        });
      }
      else if (result.data.serviceStatus.statusCode === 200) {
        setCardDetails(result.data.creditcarddata)
      }
      else {
        setactionOnFail();
        notification.error({
          message: <span  role='alert' aria-live="assertive" aria-atomic="true" ><span className="visually-hidden">Error </span>{errorMessages.error_cardCreation}</span>,
          duration: 3,
          style: {
            backgroundColor: '#fff1f0',
            border: "1px solid #ffa39e",
          }
        });
      }
    }).catch((error) => {
      dispatch(fetchingCardDbCompletes(false));
      if (error.response !== undefined && error.response.data !== null && error.response.data !== undefined && error.response.data.serviceStatus !== undefined) {
        setactionOnFail(error.response.data.serviceStatus); //sending error response on wrong otp attempt to get the third wrong attempt
        notification.error({
          message: <span  role='alert' aria-live="assertive" aria-atomic="true" ><span className="visually-hidden">Error </span>{error.response.data.serviceStatus.message}</span>,
          duration: 3,
          style: {
            backgroundColor: '#fff1f0',
            border: "1px solid #ffa39e",
          }
        });
      }
      else {
        setactionOnFail();
        notification.error({
          message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{errorMessages.generic_error}</span>,
          duration: 3,
          style: {
            backgroundColor: '#fff1f0',
            border: "1px solid #ffa39e",
          }
        });
      }

    })
  }
}