import React, { Component } from 'react'
import ReactDOM from 'react-dom';
import { connect } from 'react-redux'
import $ from 'jquery';
import { Card, Row, Col, Table, notification, Icon, Select, Button, Modal, Slider, Spin } from 'antd';
import { Input } from "antd/lib/index";
import XLSX from "xlsx";
import moment from 'moment'
import CradDetailComponent from '../components/ProvisionerScreen/CradDetailComponent'
import 'antd/dist/antd.css'
import axios from "axios";
import prepareTitle,{ loaderFocus,checkBoxStatusForCards } from '../utils/accessability';
import ExportBtn from './ExportBtn';
import ProfileDetails from './profileDetails';
import Filter from '../images/provisioner/filter.png';
import Tick from '../images/bulk/tick.svg'
import history from './history';
import { viewPhoneNumber } from './ProvisionerScreen/phoneNumberFormat'
import Edit from "../../src/images/bulk/Edit@2x.png";
import { EditableFormRowActiveCards, EditableCellActiveCards } from "./ProvisionerScreen/activeCardEditable";
import { message } from 'antd';

// var dateFormat = require('dateformat');
const { URLs } = require('../data/constants')
const { Search } = Input;
const Option = Select.Option;
class AllActiveCards extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      data1: [],
      detailsVisible: false,
      activeCardCount: '0',
      searchTerm: '',
      filterTable: null,
      modalFilterVisible: false,
      maxValue: 0,
      minValue: 0,
      left: 0,
      right: 400,
      selectedRows: [],
      selectedRowKeys: [],
      selectedRecord: {},
      updatedActiveData: [],
      index: 0,
      filterData: [],
      current: 1,
      TotalPage: null,
      isLoading: false,
      sortOrderText: '',
      sortedInfo: null,
      prevActiveElement: null,
      display: false,
      exportAllCardsModalVisible: false,
      currentDataSource: [],
      previousActiveElement: null,
      deactivateConfirmationModal: false,
      confirmSuccess: false,
      confirmError: false,
      loadGrid: false,
      profileDetailsVisible: false,
      profileType: '',
      size: 8,
      searchTerm: null,
      companyList: [],
      companyName: null,
      companyId: null,
      selectSearchItem : null,
      editingKey: [],
      selectedRows: [],
      selectedRowKeys: [],
      cols: [],
      rows: [],
      editClick: false,
      isClose: true,
      detailUpdateSuccess: false,
      okClicked: false,
      validToEdit: false,
      validTo: false,
      error: null,
      disableSaveChanges: true,
      updatedErrorList: []
    }
    this.searchBox = React.createRef();
    this.okBtnRef = React.createRef();
    this.cancelBtnRef = React.createRef();
    // this.defaultFocus = this.defaultFocus.bind(this);
  }
  

  componentDidMount() {
    // this.setState({ isLoading: true })
    if(this.props.roles === 'Super Admin' || this.props.roles === 'Customer Support'){
      history.push(this.props.roles === 'Customer Support'? "/customersupport/activecards" :"/superadmin/activecards");
      window.onpopstate = () => {
        this.redirect();
      };

      let list = this.props.activeCompanyName;
      list.sort((a, b) => {
        let companyname = a.companyName.toLowerCase(),
        companyname2 = b.companyName.toLowerCase();
        return companyname < companyname2 ? -1 : 1
      });
      this.setState({ companyList: list })

    }else{
      this.setState({ isLoading: true, companyId: this.props.companyId, companyName: this.props.userDetails.companyName }, () => this.fetchActiveCardDetails());
      loaderFocus(".loader-color .ant-spin-text"); 
    }
    if(this.props.roles !== 'Super Admin' && this.props.roles !== 'Card Company Admin'){
      if (window.location.hostname.includes('elan-solutions.elaneasypay'))
        document.title = "All Active cards: Elan Easy Pay";
      else
        document.title = "All Active cards: US Bank Instant Card";
    }
    if (ReactDOM.findDOMNode(this.refs.itemtest)) {
      //alert('yes')
      ReactDOM.findDOMNode(this.refs.itemtest).focus();
    }
  }

  componentDidUpdate() {

    var ulDiv = document.querySelectorAll(".ant-pagination.ant-table-pagination");
    if (ulDiv[0]) {
      ulDiv.forEach(div => {
        // console.log("Last element:::", div.lastElementChild , div.lastElementChild.className ==="ant-pagination-options");
        if (div.lastElementChild.className === "ant-pagination-options")
          div.prepend(div.lastElementChild)
      })
    }
    const divUp = document.querySelectorAll(".anticon.anticon-caret-up.ant-table-column-sorter-up.off");
    const divDown = document.querySelectorAll(".anticon.anticon-caret-down.ant-table-column-sorter-down.off");
    // console.log("div:::", divUp)
    if (divUp[0]) {
      divUp.forEach(div => {
        div.setAttribute('role', 'img')
        div.setAttribute('aria-hidden', 'true')
      })
    }
    if (divDown[0]) {

      divDown.forEach(div => {
        div.setAttribute('role', 'img')
        div.setAttribute('aria-hidden', 'true')
      })

    }

    const divSearch = document.getElementsByClassName("anticon anticon-search ant-input-search-icon");

    if (divSearch[0]) {
      divSearch[0].setAttribute('role', 'img')
      divSearch[0].setAttribute('aria-hidden', 'true')
    }
    const icon = document.querySelectorAll('.anticon.anticon-down.ant-select-arrow-icon');
    if (icon) {
      icon.forEach(el => {
        el.setAttribute('role', 'img')
        el.setAttribute('aria-hidden', 'true')
      })
    }
    const paginationDiv = document.querySelectorAll('.ant-pagination.ant-table-pagination');
    if (paginationDiv[0]) {
      paginationDiv.forEach(div => {
        if (!div.getAttribute('role'))
          div.setAttribute('role', 'presentation');
      })
    }

    const ellipsis = document.querySelectorAll('.ant-pagination-item-ellipsis');
    if (ellipsis[0]) {
      ellipsis.forEach(div => {
        if (!div.getAttribute('aria-hidden'))
          div.setAttribute('aria-hidden', 'true');
      })
    }

    const prevFiveDiv = document.querySelectorAll('.ant-pagination-jump-prev.ant-pagination-jump-prev-custom-icon');
    if (prevFiveDiv[0]) {
      prevFiveDiv.forEach(div => {
        if (!div.getAttribute('role')) {
          div.setAttribute('role', 'link');
          div.setAttribute('aria-label', "Previous 5 pages");
        }

      })
    }

    const prevFiveDivIcon = document.querySelectorAll('.anticon.anticon-double-left.ant-pagination-item-link-icon');
    if (prevFiveDivIcon[0]) {
      prevFiveDivIcon.forEach(div => {
        if (div.getAttribute('aria-label'))
          div.removeAttribute('aria-label');
      })
    }

    const nextFiveDiv = document.querySelectorAll('.ant-pagination-jump-next.ant-pagination-jump-next-custom-icon');
    if (nextFiveDiv[0]) {
      nextFiveDiv.forEach(div => {
        if (!div.getAttribute('role')) {
          div.setAttribute('role', 'link');
          div.setAttribute('aria-label', "Next 5 pages");
        }
      })
    }

    const nextFiveDivIcon = document.querySelectorAll('.anticon.anticon-double-right.ant-pagination-item-link-icon');
    if (nextFiveDivIcon[0]) {
      nextFiveDivIcon.forEach(div => {
        if (div.getAttribute('aria-label'))
          div.removeAttribute('aria-label');
      })
    }

    const prevPageDiv = document.querySelectorAll('.ant-pagination-prev');
    if (prevPageDiv[0]) {
      prevPageDiv.forEach(div => {
        if (!div.getAttribute('role')) {
          div.setAttribute('role', 'link');
          div.setAttribute('aria-label', "Previous page");
        }
      })
    }

    const nextPageDiv = document.querySelectorAll('.ant-pagination-next');
    if (nextPageDiv[0]) {
      nextPageDiv.forEach(div => {
        if (!div.getAttribute('role')) {
          div.setAttribute('role', 'link');
          div.setAttribute('aria-label', "Next page");
        }
      })
    }



    const dropDown = document.querySelectorAll('.ant-select-selection.ant-select-selection--single');
    if (dropDown[0]) {
      dropDown.forEach(div => {
        if (!div.getAttribute('aria-label') && !div.firstElementChild.innerHTML.includes("Active&nbsp;Cards"))
          div.setAttribute('aria-label', 'Select rows');
        div.addEventListener('keyup', function () {
          const comboboxOption = document.querySelectorAll('.ant-select-dropdown-menu-item');
          
          if (comboboxOption[0]) {
            var i = 0
            comboboxOption.forEach(el => {
              i += 1
              if (!el.getAttribute('id'))
                el.setAttribute('id', `option-${i}`);
              el.removeAttribute('aria-selected');
            })
          }
          let optionDiv = document.querySelectorAll('.ant-select-dropdown-menu-item.ant-select-dropdown-menu-item-active');
          
          if (optionDiv[0]) {
            let activeDescendant = optionDiv[0].getAttribute('id')   
            div.setAttribute('aria-activedescendant', activeDescendant);
          }
        });
      })

    }

  


    if (this.state.modalFilterVisible) {
      const modal = document.querySelector('.ant-modal-wrap');
      if (modal) {
        setTimeout(() => {
          modal.setAttribute('aria-labelledby', 'slider_title');
        }, 100)
      }

      setTimeout(() => {
        const div = document.getElementsByClassName('ant-slider-handle');
        if (div && !div[0].getAttribute('aria-label')) {
          div[0].setAttribute('aria-label', 'Credit limit min');
          div[1].setAttribute('aria-label', 'Credit limit max');
        }
      }, 500)
    }

  }

  componentWillUnmount() {
    if(this.props.roles === 'Super Admin' || this.props.roles === 'Customer Support'){
      window.removeEventListener("popstate", this.redirect);
      window.sessionStorage.getItem("LOGIN_STATUS") && history.replace(this.props.roles === 'Customer Support'? "/customersupport": "/superadmin")
    }
  }

  redirect =() =>{
      this.setState({companyId: null});
      history.forward();
  }


  fetchActiveCardDetails() {
    this.setState({ loadGrid: true })
    axios.get(URLs.companyUrl + `companyActiveCards/` + this.state.companyId, {
      headers: {
        "Authorization": 'Bearer ' + this.props.token.access_token
      }
    }).then(res => {
      res.data.data &&
        this.setState({
          data: res.data.data,
          data1: res.data.data,
          currentDataSource: res.data.data,
          activeCardCount: res.data.data.length,
          TotalPage: (Math.ceil(res.data.data.length / this.state.size)),
          isLoading: false,
          loadGrid: false,
          display: res.data.data && res.data.data.length > 0 ? true : false
        }, () => (this.state.searchTerm !== '' && this.state.searchTerm !== null) && this.onSearch(this.state.searchTerm));
        
        // console.log("Selected::", this.state.selectedRecord)
        if(this.state.selectedRecord && this.state.selectedRecord.cardRef){
          let selectedRecord = res.data.data.filter((row) => row.cardRef === this.state.selectedRecord.cardRef);
          // console.log("Selected Record:::", selectedRecord[0]);
          this.setState({selectedRecord: selectedRecord[0]});
        }

      setTimeout(() => {
        const div = document.querySelectorAll('.ant-checkbox-input');

        if (div[0]) {
          div.forEach(el => {
            if (!el.getAttribute('aria-label')) {
              el.setAttribute('aria-label', 'Select all active cards');
              // el.setAttribute('id', 'Select-all-checkbox');
            }
          })
        }


        // this.modalBtnRef.current.buttonNode.focus();
      }, 500)
    })
      .catch(error => {
        this.setState({ isLoading: false, loadGrid: false })
        if (error.response !== undefined && error.response.data !== null && error.response.data !== undefined && error.response.data.serviceStatus !== undefined) {
          notification.error({
            message: <span role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{this.props.label_keys.couldNotcompleteReq}</span>,
            duration: 3,
            style: {
              backgroundColor: '#fff1f0',
              border: "1px solid #ffa39e",
            }

          });

        }
        else {
          notification.error({
            message: <span role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{this.props.label_keys.couldNotcompleteReq}</span>,
            duration: 3,
            style: {
              backgroundColor: '#fff1f0',
              border: "1px solid #ffa39e",
            }
          });
        }
      }

      )
  }

  /** Filter Methods */
  getMaxCardLimitValue = () => {
    let currentData = this.state.data1;

    // const currentData = this.state.activeTab === '1' ? this.props.passedData : this.props.failedData;
    let maxValue = Math.max.apply(null,
      currentData.map(function (o) { return o.creditLimit; }));
    return maxValue;
  }

  getMinCardLimitValue = () => {
    let currentData = this.state.data1;
    // const currentData = this.state.activeTab === '1' ? this.props.passedData : this.props.failedData;
    let minValue = Math.min.apply(null,
      currentData.map(function (o) { return o.creditLimit; }));
    return minValue;
  }
  handleEditMode = () => {
    if (this.state.selectedRowKeys.length > 0) {
      // this.setState({ editClick: true });
      this.setState({ editableMode: true });
      let editingkeyAdd = [];
      for (let i = 0; i < this.state.selectedRowKeys.length; i++) {
        editingkeyAdd.push(this.state.selectedRowKeys[i]);
      }
      this.edit(editingkeyAdd);
    }
    else
      (message.error({ content: <span role="alert" ><span className="visually-hidden">Error </span>{this.props.label_keys.selectRecordToEdit}</span> }))



  }
  isEditing = record => {
    // console.log("record:::", record.cardRef)
    for (let i = 0; i < this.state.editingKey.length; i++) {
      if (record.cardRef === this.state.editingKey[i])
        return true;
    }
  }

  edit(key) {
    this.setState({ editingKey: key })
  }
   onSaveCardDetails = () => {
    this.setState({ editableMode: false })
    this.edit({});
    if(this.state.updatedActiveData){
      let cardDetailsList = { cardDetails: this.state.updatedActiveData }

      axios.post(URLs.cardUrl + '/updateCardData', cardDetailsList, {
        responseType: 'json',
        headers: {
          "Authorization": 'Bearer ' + this.props.token.access_token
        }
      }).then(result => {
        // console.log(result);
        if (result.data.serviceStatus.statusCode === 200 && result.data.requestNo && result.data.requestNo.cardStatus && result.data.requestNo.cardStatus.length > 0 && result.data.requestNo.cardStatus[0].status === "Success") {
          // let validFrom = this.state.validFrom;
          // let validTo = this.state.validTo;
          // let error = { creditLimit: false, validFrom: false, validTo: false };
          // if ((this.state.validToEdit && (dateFormat(validTo, 'mm/dd/yyyy') !== (this.state.selectedRow.validTo ? dateFormat(this.state.selectedRow.validTo, 'mm/dd/yyyy') : dateFormat(this.state.selectedRow.validTill, 'mm/dd/yyyy'))))) {
          //   if (!this.checkCardValidityDates(validTo) || !this.checkValidToRule())
          //     console.log("error.validTo :::", error.validTo)
          //   error.validTo = true;
          // }
  
          this.fetchActiveCardDetails();
          this.setState({ updatedActiveData: [], detailUpdateSuccess: true })
        } else {
          notification.error({
            message: <span role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>
              {this.props.label_keys.couldNotcompleteReq}</span>,
            duration: 3,
            style: {
              backgroundColor: '#fff1f0',
              border: "1px solid #ffa39e",
            }
          });
          this.setState({ updatedActiveData: [] })
        }
      })
        .catch(error => {
          console.log(error);
          notification.error({
            message: <span role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>
              {this.props.label_keys.couldNotcompleteReq}</span>,
            duration: 3,
            style: {
              backgroundColor: '#fff1f0',
              border: "1px solid #ffa39e",
            }
          });
          this.setState({ updatedActiveData: [] })
        })
    }
  }


  showFilter = () => {
    let maxValue = this.getMaxCardLimitValue();
    let minValue = this.getMinCardLimitValue();
    this.setState({ minValue: minValue, maxValue: maxValue, modalFilterVisible: true });
    // setTimeout(() => { document.getElementsByClassName('ant-slider-handle')[0].focus() }, 500)
  }

  handleFilterCancel = () => {
    this.setState({ modalFilterVisible: false });
  };

  filterData = () => {
    let filterData;
    let filteredValue = this.state.data1;
    if (filteredValue.length > 0) {
      filterData = filteredValue.filter((row) => row.creditLimit >= this.state.left && row.creditLimit <= this.state.right)
      this.setState({ data: filterData,currentDataSource: filterData, modalFilterVisible: false, TotalPage: Math.ceil(filterData.length / this.state.size), current: 1, display: filterData && filterData.length > 0 ? true : false })
      // this.setPageSize(Math.ceil(filterData.length / this.state.size));
    }
  }

  defaultFocus = () => {
    if (this.state.prevActiveElement) {
      if (document.body.contains(this.state.prevActiveElement)) {
        // setTimeout(() => {
        this.state.prevActiveElement.focus();
        // this.setState({
        //   prevActiveElement: null
        // })
        // }, 100);
      }
    } else {

      if (ReactDOM.findDOMNode(this.refs.itemtest)) {
        //alert('yes')
        ReactDOM.findDOMNode(this.refs.itemtest).focus();
      }
    }
  }

  onSearch = (value) => {
    let cards = this.state.data1;
    let cardsArray;
    let keys = ['name', 'cardNumber', 'employeeId', 'creditLimit', 'validFrom', 'validTo', 'field1', 'cardCreatedTime', 'creatorname', 'approvername', 'emailId']

    if(this.props.roles === 'Super Admin' || this.props.roles === 'Customer Support'){
      keys.push('companyname');
    }
    this.setState({ searchTerm: value });

    if (value === "") {
      this.setState({ data: this.state.data1,currentDataSource: this.state.data1, TotalPage: Math.ceil(cards.length / this.state.size), current: 1 })
    } else if (value) {
      cardsArray = cards.filter(o =>
        keys.some(k =>
          String(k === 'creditLimit' ? "$" + o[k] : o[k])
            .toLowerCase()
            .includes(value.toLowerCase())
        )
      );
      this.setState({ data: cardsArray,currentDataSource: cardsArray, TotalPage: Math.ceil(cardsArray.length / this.state.size), current: 1, display: cardsArray.length > 0 ? true : false });
    }

  }
  updateDetails = () => {
    this.fetchActiveCardDetails()
  }

  handleChange = (pagination, filters, sorter, currentDataSource) => {
    this.setState({
      sortedInfo: sorter,
      currentDataSource: currentDataSource.currentDataSource,
      sortOrderText: `Table sorted by ${sorter.columnKey}, in ${sorter.order}ing order`,
    });

  }

  itemRender = (current, type, originalElement) => {
    if (type === 'prev') {
      return <a><Icon type="arrow-left" aria-label={null} /></a>

    }
    if (type === 'next') {
      return <a><Icon type="arrow-right" aria-label={null} /></a>;
    }
    return originalElement;

  }

  pageChanger = (page, defaultPageSize) => {


    let TotalPage = Math.ceil(this.state.data.length / defaultPageSize)
    this.setState({ current: page, TotalPage: TotalPage })

  }

  OnPageSizeChange = (current, size) => {
    let TotalPage = Math.ceil(this.state.data.length / size)
    this.setState({ current: current, TotalPage: TotalPage, size: size })
  }

  cardDetails() {

    this.setState({
      prevActiveElement: document.activeElement,
      detailsVisible: !this.state.detailsVisible
    })


    // console.log("Active element ::: ", this.state.prevActiveElement, document.activeElement);
  }

  handleCancel = () => {
    this.setState({ selectedRowKeys: [], selectedRows: [] });
    $('.search-input input').focus(); 
  }

  submitForDeactivateCard = async () => {
    this.setState({ isLoading: true });
    loaderFocus(".loader-color .ant-spin-text"); 
    let deactivateSuccess = []
    let deactivateFail = [];

    this.setState({ deactivateConfirmationModal: false, confirmError: false });

    
    if (this.state.selectedRowKeys.length > 0) {
      for (let i = 0; i < this.state.selectedRows.length; i++) {
        try {
          let result = await axios.get(URLs.cardUrl + `/closeActiveCard/${this.state.selectedRows[i].cardRef}`, {
            responseType: 'json',
            headers: {
              "Authorization": 'Bearer ' + this.props.token.access_token
            }
          })
          if (result) {
            if (result.data.serviceStatus.statusCode === 200) {
              deactivateSuccess.push(this.state.selectedRows[i]);
            } else {
              deactivateFail.push(this.state.selectedRows[i]);
            }
          }
        } catch (error) {
          deactivateFail.push(this.state.selectedRows[i]);
        }

      }
      // console.log("DeactivateFaile::", deactivateFail, deactivateSuccess);
      // if(deactivateFail.length === 0){
      //   console.log("Deactivated Successfully");
      //   this.setState({ deactivateConfirmationModal: false, isLoading: false, confirmSuccess: true, selectedRow: [], selectedRowKeys: []})
      //   this.fetchActiveCardDetails();
      // }
      if (deactivateFail.length === this.state.selectedRowKeys.length) {
        this.setState({ confirmError: true, isLoading: false })
        this.fetchActiveCardDetails();
      } else {
        this.setState({ isLoading: false, confirmSuccess: true, selectedRow: [], selectedRowKeys: [] })
        this.fetchActiveCardDetails();
      }
    }
  }

  onSelectChange = (selectedRowKeys) => {
    let result = [];
    setTimeout(() => {
      if(selectedRowKeys && !(selectedRowKeys.length > 0)){
        this.setState({editableMode: false, updatedActiveData: [], disableSaveChanges: true});
          this.edit([]);
      }
      this.setState({ selectedRowKeys });
      if (this.state.editableMode === true) {
        let editingkeyAdd = [];
        for (let i = 0; i < selectedRowKeys.length; i++) {
          editingkeyAdd.push(selectedRowKeys[i]);
          let updatedData = this.state.updatedActiveData.filter(item => selectedRowKeys[i] === item.cardRef);
          this.setState({ updatedActiveData: updatedData });
        }
        this.edit(editingkeyAdd);
      }
      for (let i = 0; i < this.state.selectedRowKeys.length; i++) {
        result.push(this.state.data.find(obj => {
          return obj.cardRef === this.state.selectedRowKeys[i];
        }))
      }
      if (result === []) {
        this.setState({ deactivateBtnEnable: false })
      } else {
        this.setState({ deactivateBtnEnable: true })
      }
    }, 500);
  }


  onExport = (prevEle) => {
    this.setState({ exportAllCardsModalVisible: true, previousActiveElement: prevEle });
    // console.log("this.props.previousActiveElement:::", prevEle)
  }


  handleExportCards = () => {

    let records = this.state.selectedRowKeys.length === 0 ? this.state.currentDataSource : this.state.selectedRows;
    let fileName = this.state.companyName + "_" + moment().format("MM_DD_YYYY_HH_mm_ss") + "_" + this.props.label_keys.activeCards + ".xlsx";

    const newData = records.map(obj => {
      if(this.props.roles === 'Super Admin' || this.props.roles === 'Customer Support')
        return {
          'NAME': obj.name,
          'CARD NUMBER': obj.cardNumber,
          'EMPLYOEE ID': obj.employeeId,
          'PROVISIONED CREDIT LIMIT': '$' + obj.creditLimit,
          'VALID FROM': obj.validFrom,
          'VALID TO': obj.validTo,
          'FIELD1': obj.field1,
          'FIELD2': obj.field2,
          'NOTES': obj.notes,
          'DATE CREATED': obj.cardCreatedTime,
          'CREATED BY': obj.creatorname,
          'APPROVED BY': !obj.approvername || obj.approvername === ' ' ? "Not applicable" : obj.approvername,
          'EMAIL ID': obj.emailId,
          'COMPANY NAME': obj.companyname,
          'MOBILE NUMBER': viewPhoneNumber(obj.mobileNumber),
          'ACCOUNT ID': `${obj.osn} ${this.props.theme === "elan" || (obj.serviceInterface && obj.serviceInterface.includes("Elan EasyPay"))? 'EP' : 'IC'}`,
          'BILLING ADDRESS': `${obj.FLAT !== null ? obj.FLAT : ''} ${obj.STREET_ADDRESS !== null ? obj.STREET_ADDRESS: null} ${obj.CITY !== null ? obj.CITY: ''} ${obj.companystate !== null ? obj.companystate : ''} ${obj.COUNTRY !== null ? obj.COUNTRY : ''} ${obj.ZIP_CODE !== null ? obj.ZIP_CODE : ''}`,
          'BUSINESS NUMBER': viewPhoneNumber(obj.businessNumber)
        }
      else
        return {
          'NAME': obj.name,
          'CARD NUMBER': obj.cardNumber,
          'EMPLYOEE ID': obj.employeeId,
          'PROVISIONED CREDIT LIMIT': '$' + obj.creditLimit,
          'VALID FROM': obj.validFrom,
          'VALID TO': obj.validTo,
          'FIELD1': obj.field1,
          'FIELD2': obj.field2,
          'NOTES': obj.notes,
          'DATE CREATED': obj.cardCreatedTime,
          'CREATED BY': obj.creatorname,
          'APPROVED BY': !obj.approvername || obj.approvername === ' ' ? "Not applicable" : obj.approvername,
          'EMAIL ID': obj.emailId,
          'MOBILE NUMBER': viewPhoneNumber(obj.mobileNumber),
          'ACCOUNT ID': `${obj.osn} ${this.props.theme === "elan" || (obj.serviceInterface && obj.serviceInterface.includes("Elan EasyPay"))? 'EP' : 'IC'}`,
          'BILLING ADDRESS': `${obj.FLAT !== null ? obj.FLAT : ''} ${obj.STREET_ADDRESS !== null ? obj.STREET_ADDRESS: null} ${obj.CITY !== null ? obj.CITY: ''} ${obj.companystate !== null ? obj.companystate : ''} ${obj.COUNTRY !== null ? obj.COUNTRY : ''} ${obj.ZIP_CODE !== null ? obj.ZIP_CODE : ''}`,
          'BUSINESS NUMBER': viewPhoneNumber(obj.businessNumber)
        }
    })

    let binaryWS = XLSX.utils.json_to_sheet(newData);
    let wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, binaryWS, 'Active Cards')
    XLSX.writeFile(wb, fileName);
    this.setState({ exportAllCardsModalVisible: false });
    setTimeout(() => { this.state.selectedRowKeys && this.setState({ selectedRowKeys: [], selectedRows: [] }) }, 1000);
    // console.log("document.body.contains(this.state.previousActiveElement)", document.body.contains(this.state.previousActiveElement))
    if (document.body.contains(this.state.previousActiveElement)) {
      this.state.previousActiveElement.focus()
    }
  }

  showProfileDetails = (type) => {
    this.setState({
      prevActiveElement: document.activeElement,
      profileDetailsVisible: true,
      profileType: type,
    })
  }
  handleSave = (row,errorObj)=> {
    // console.log("row:::", row)
    // this.setState({ editClick: false });
    let activeData = this.state.updatedActiveData;
    let index = activeData.filter(item => row.cardRef !== item.cardRef);
    index.push(row)

    let errorList = this.state.updatedErrorList;
    let errorData = errorList.filter(item => row.cardRef !== item.cardRef);
    if (errorObj.cL || errorObj.vF || errorObj.vT) {
      errorData.push(errorObj)
    }

    this.setState({ updatedActiveData: index, updatedErrorList: errorData, disableSaveChanges: errorData && errorData.length > 0 ? true : false });

    // console.log("item", index)
    // console.log("updatedActiveData", this.state.updatedActiveData)
  };

  companyChange = (value, option) => {
    this.setState({ companyId: value, companyName: option.props.label, isLoading: true, selectSearchItem: null}, () => this.fetchActiveCardDetails());
    loaderFocus(".loader-color .ant-spin-text"); 
  }

  

  handleSearch = search =>{
    this.setState({ selectSearchItem: search});
  }
  handleCancelEditMode = () => {
    this.setState({ editableMode: false,updatedActiveData: [], disableSaveChanges: true  });
    this.edit({});
  }

  renderLabel = (item) => {

    let label = item.companyName;
    let searchString = this.state.selectSearchItem;

    if (searchString) {

        let index = label.toLowerCase().indexOf(searchString.toLowerCase());

        if (index !== -1) {

            let length = searchString.length;

            let prefix = label.substring(0, index);
            let suffix = label.substring(index + length);
            let match = label.substring(index, index + length);

            return (
                <span>{prefix}<span className="searchString" style={{color: "#19398A", fontWeight: 600}}>{match}</span>{suffix}
                </span>
            );
        }
    }
    return (
      <span>
          {label}
      </span>
    );
  }




  render() {
    let { sortedInfo, selectedRowKeys } = this.state;
    sortedInfo = sortedInfo || {};

    let deactivateBtnEnable = this.state.selectedRowKeys && this.state.selectedRowKeys.length !== 0;

    const operations = (
      <>
      <Row >
        <Col span={this.props.roles === 'Super Admin' || this.props.roles === 'Customer Support' ? 16 : 12} style={{ marginLeft: this.props.roles === 'Super Admin' || this.props.roles === 'Customer Support' ? '24%': null }}>
          { this.props.roles === 'Super Admin' || this.props.roles === 'Customer Support'?
            <>
              <span style={{color: '#333333'}}>{this.props.label_keys.companyName}</span> &nbsp;
              <Select className="active-select-comp" onChange={this.companyChange}
                optionFilterProp="children" onDropdownVisibleChange={open => {
                  this.setState({isClose: !open})
                  }}
                  defaultActiveFirstOption={this.state.isClose}
                showSearch onSearch={this.handleSearch}
                filterOption={(input, option) => option.props.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                value={this.state.companyName}
                style={{ width: '30%', border: '1px solid #D2D2E0', borderRadius: '4px', height: '38px' }} placeholder="Select Company">
                  {this.state.companyList.map(item => {
                      return <Option value={item.companyId} label={item.companyName}>{this.renderLabel(item)}</Option>
                  })}
              </Select>
            </> : null
          }
          <Search
            tabIndex={0}
            className="search-input search-input-suffix all-active-search all-active-card-search"
            aria-label="Search"
            prefix={<Icon type="search" role="img" aria-hidden="true" />}
            suffix={<div style={{ display: 'none' }}>-</div>}
            style={{
              height: '38px', minHeight: '20px', border: 'none', width: this.props.roles === 'Super Admin' || this.props.roles === 'Customer Support' ? '34%': null,
              borderTopLeftRadius: '4px', borderBottomLeftRadius: '4px', borderTopRightRadius: '4px', borderBottomRightRadius: '4px',position:this.props.roles === 'Super Admin' || this.props.roles === 'Customer Support' ? null: 'absolute',left: this.props.roles === 'Super Admin' || this.props.roles === 'Customer Support' ? null :'-34%'
            }}
            placeholder={this.props.label_keys.search}
            onChange={(e) => {

              if (e.target.value === "" || e.target.value === null) {

                this.onSearch('');

              }

            }}
            autocomplete="off"
            onSearch={(value) => this.onSearch(value)}
          />
       
        </Col>
        &nbsp;&nbsp;&nbsp;&nbsp;
        {(this.props.roles !== 'Super Admin' && this.props.roles !== 'Customer Support') ? <Button type={`primary filter-comp ${this.state.selectedRowKeys && !this.state.selectedRowKeys.length > 0? 'disabled-edit-icon': ''}`} className="edit-btn edit-btn-zoom myAct_edit-btn edit400" ref={"provisionItem"} id="credit-limit-input" style={{
          // left: '68%',
          padding: '2px', backgroundColor: '#19398A',borderColor: '#19398A', width: '38px',height:'38px',left:this.props.theme==="elan"?'-36%':'-31%',paddingTop:'7px'
        }} onClick={() => {
          this.handleEditMode()
          // this.setState({ multiCardSuccessModal: true }, console.log("this.state.multiCardSuccessModal)", this.state.multiCardSuccessModal))
        }} disabled={!this.state.selectedRowKeys.length > 0}>
          <div style={{ textAlign: 'right', outlineColor: 'transparent', marginTop: this.props.theme === "elan" ? "-5%" : "-6%" }}/* className="col-sm-1 col-sm-offset-3 edit-icon-details" */>
            <span
              onClick={(e) => {
                // this.setState({ editEnable: true })
                setTimeout(() => {
                  // console.log("document.getElementById('credit-limit-input').focus()", document.getElementById('credit-limit-input').focus());
                  // document.getElementById('edit-btn').focus()
                }, 100);
              }}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  // this.setState({ editEnable: true });
                  setTimeout(() => {
                    // console.log("document.getElementById('credit-limit-input').focus()", document.getElementById('credit-limit-input').focus());
                    // document.getElementById('edit-btn').focus()
                  }, 100);
                }
              }}
            >
              <i role="img" aria-label="Edit" className="icon-Edit" id="edit-btn"
                style={{ color: "white", fontSize: "19px", fontWeight: 600, padding: '6px', outlineColor: 'transparent' }} /></span>
          </div>
        </Button>: null}

        <Col span={2} style={{ marginLeft: (this.props.roles === 'Super Admin' || this.props.roles === 'Customer Support') ? '-9.5%':"10%" }}>
          <Button className ="filtered_btn" type="primary filter-comp all-active-filter" style={{ backgroundColor: '#19398A', borderColor: '#19398A', height: '38px', left: (this.props.roles === 'Super Admin' || this.props.roles === 'Customer Support') ? '-5%' : null }} onClick={this.showFilter} >            
          <img className="filter" style={{
              width: "38px",
            height: "38px", marginTop: this.props.theme === 'elan' ? '-4%' : '-1px',
          }} src={Filter} alt={this.props.label_keys.filter} />
          </Button>
        </Col>
        <Col  span={(this.props.roles === 'Super Admin' || this.props.roles === 'Customer Support') ? 2 : 2} style={{ marginLeft: (this.props.roles === 'Super Admin' || this.props.roles === 'Customer Support') ? '-5%': "-1%" }}>
          <ExportBtn  className='export_btn' label_keys={this.props.label_keys} selectedLang={this.props.selectedLang === "French" ? "French" : "English"} selected={this.state.selectedRowKeys && this.state.selectedRowKeys.length !== 0} onExport={(prevEle) => this.onExport(prevEle)} />
        </Col>
        </Row>
        <Modal className="confirm-visible filter-modal actCard-filter-zoom" role='dialog'
          title={<h2 style={{ fontSize: '16px', fontWeight: "700", color: "#161a80" }}>{this.props.label_keys.filter}</h2>}
          visible={this.state.modalFilterVisible} onCancel={this.handleFilterCancel}
          onOk={this.filterData} cancelText={this.props.label_keys.cancel}
          okText={<span style={{ textTransform: this.props.theme !== "elan" ? "capitalize" : "uppercase" }}>{this.props.label_keys.applyFilter}</span>}>
          <div tabindex={-1} style={{ paddingBottom: 20 }}>
            <p className="message-zoom" id="slider_title" style={{ textAlign: "left" }}>{this.props.label_keys.provisionedCreditLimitRange}</p>
            <Slider
              range className="message-zoom"
              tooltipVisible={this.state.modalFilterVisible ? true : false}
              tooltipPlacement="bottom"
              min={this.state.minValue}
              max={this.state.maxValue}
              value={[this.state.left, this.state.right]}
              onChange={e => this.setState({ left: e[0], right: e[1] })}
            />

          </div>
        </Modal>
      </>
    );

    const footer = <Row className="deactivate-footer" style={{ backgroundColor: 'white' }}>
      <Col span={16}>&nbsp;</Col>
      <Col span={8}
        style={{ zIndex: '2', marginTop: "-3.7%" }}>
        <Col span={10}>
          {(this.state.display) &&
            <Button className="cancel-btn-ant allActive-cancel" role="button" aria-label="Cancel" onClick={this.handleCancel}
              style={deactivateBtnEnable ? { color: '#19398A', borderColor: '#19398A' } : { color: '#666666' }}
            >{this.props.label_keys.cancel}</Button>
          }
        </Col>
        <Col span={12} offset={1} className="deactivate-col">
          {
            (this.state.display) &&
            <Button id="deactivate-btn" className="create-card-btn allActive-zoom-deact" style={{ fontWeight: "normal", fontSize: "16px", backgroundColor: '#19398A', borderColor: '#19398A' }} role="button"
              type="primary" disabled={this.props.roles === 'Customer Support'? true : !deactivateBtnEnable}
              onClick={() => this.setState({ deactivateConfirmationModal: true, previousActiveElement: document.activeElement, })}
            >{this.props.label_keys.deactivate}</Button>
          }
        </Col>
      </Col>
    </Row>

    const activeCardsDatarowSelection = {
      onSelectAll: (selected, selectedRows) => {
        // console.log("Seleceted Rows:::", selectedRows);
        this.setState({ selectedRows });
        if(selectedRows && !(selectedRows.length > 0)){
          this.setState({editableMode: false, updatedActiveData: [], disableSaveChanges: true});
            this.edit([]);
        }

      },
      selectedRowKeys,
      onSelect: (record, selected, selectedRows, nativeEvent) => {
        // console.log("Seleceted Rows onSelect:::", selectedRows);
        this.setState({ selectedRows });
        if (document.getElementById(record.cardRef)) {
          document.getElementById(record.cardRef).setAttribute('aria-checked', selected);
        }

      },
      onChange: this.onSelectChange,
      getCheckboxProps: record => ({ // Column configuration not to be checked        
        'aria-checked': false,
        name: !record.name ? "text" : record.name,
        'aria-label': record.name,
        role: "checkbox",
        id: record.cardRef,
        onKeyDown: checkBoxStatusForCards('#cards-tab-panel')
      }),
    };

    const columns = [
      {
        title: prepareTitle('0', <span role="button" >{this.props.label_keys.name}</span>),
        dataIndex: 'name',
        key: 'name',
        sorter: (a, b) => {
          let name = a.name.toLowerCase();
          let name2 = b.name.toLowerCase();
          return name < name2 ? -1 : 1
        },
        sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order,
        render: text => <div
          tabIndex={0}
          onKeyDown={e => {
            if (e.keyCode === 13) {
              this.cardDetails()
            }
          }}
          role="button"
          onClick={() => {
            this.cardDetails()
          }}><a style={{ color: '#19398A' }}><u>{text}</u></a></div>
      },
      {
        title: prepareTitle('0', <span role="button" >{this.props.label_keys.cardNumber}</span>),
        dataIndex: 'cardNumber',
        key: 'cardNumber',
        align: 'left',
        width: '11%',
        sorter: (a, b) => {
          let cdN1 = a.cardNumber;
          let cdN2 = b.cardNumber;
          return cdN1 < cdN2 ? -1 : 1
        },
        sortOrder: sortedInfo.columnKey === 'cardNumber' && sortedInfo.order,
        render: text => !text || text === " " ? <div>-</div> :
          <div><span className="visually-hidden">card number ending with {text.substring(15,19)}</span><span aria-hidden='true'>{text}</span></div>
      },
      {
        title: prepareTitle('0', <span role="button" >{this.props.label_keys.employeeId}</span>),
        dataIndex: 'employeeId',
        key: 'employeeId',
        sorter: (a, b) => {
          let eid = a.employeeId;
          let eid1 = b.employeeId;
          return eid < eid1 ? -1 : 1
        },
        sortOrder: sortedInfo.columnKey === 'employeeId' && sortedInfo.order,
      },
      {
        title: prepareTitle('0', <span role="button" >{this.props.label_keys.provisionedCredit}</span>),
        dataIndex: 'creditLimit',
        key: 'creditLimit',
        align: 'center',
        width: '10%',
        editable: 'true',
        sorter: (a, b) => a.creditLimit - b.creditLimit,
        sortOrder: sortedInfo.columnKey === 'creditLimit' && sortedInfo.order,
        render: text => <div>${text}</div>
      },
      {
        title: prepareTitle('0', <span role="button" >{this.props.label_keys.validFrom}</span>),
        dataIndex: 'validFrom',
        key: 'validFrom',
        editable: 'true',
        align: 'center',
        sorter: (a, b) => {
          return Number(new Date(a.validFrom)) - Number(new Date(b.validFrom));
        },
        sortOrder: sortedInfo.columnKey === 'validFrom' && sortedInfo.order,
      },
      {
        title: prepareTitle('0', <span role="button" >{this.props.label_keys.validTill}</span>),
        dataIndex: 'validTo',
        key: 'validTo',
        editable: 'true',
        align: 'center',
        sorter: (a, b) => {
          return Number(new Date(a.validTo)) - Number(new Date(b.validTo));
        },
        sortOrder: sortedInfo.columnKey === 'validTo' && sortedInfo.order,
      },
      {
        title: prepareTitle('0', <span role="button" >{this.props.label_keys.field1}</span>),
        dataIndex: 'field1',
        key: 'field1',
        sorter: (a, b) => {
          let field = a.field1.toLowerCase();
          let field2 = b.field1.toLowerCase();
          return field < field2 ? -1 : 1
        },
        sortOrder: sortedInfo.columnKey === 'field1' && sortedInfo.order,
      },
      {
        title: prepareTitle('0', <span role="button" >{this.props.label_keys.dateCreated}</span>),
        dataIndex: 'cardCreatedTime',
        key: 'dateCreated',
        sorter: (a, b) => {
          return Number(new Date(a.cardCreatedTime)) - Number(new Date(b.cardCreatedTime));
        },
        sortOrder: sortedInfo.columnKey === 'dateCreated' && sortedInfo.order,
      },
      {
        title: prepareTitle('0', <span role="button" >{this.props.label_keys.createdBy}</span>),
        dataIndex: 'creatorname',
        key: 'createdBy',
        sorter: (a, b) => {
          let cdN1 = a.creatorname.toLowerCase();
          let cdN2 = b.creatorname.toLowerCase();
          return cdN1 < cdN2 ? -1 : 1
        },
        sortOrder: sortedInfo.columnKey === 'createdBy' && sortedInfo.order,
        render: text => !text || text === " " ? <i style={{ color: "#666666" }}> {this.props.label_keys.notApplicable} </i> :
          <div
            tabIndex={0}
            role="button"
            onKeyDown={e => {
              if (e.keyCode === 13) {
                this.showProfileDetails("Provisioner")
              }
            }}
            onClick={() => {
              this.showProfileDetails("Provisioner")
            }}><a style={{ color: '#19398A' }}><u>{text}</u></a></div>
      },
      {
        title: prepareTitle('0', <span role="button" >{this.props.label_keys.approvedBy}</span>),
        dataIndex: 'approvername',
        key: 'approvedBy',
        sorter: (a, b) => {
          let cdN1 = a.approvername.toLowerCase();
          let cdN2 = b.approvername.toLowerCase();
          return cdN1 < cdN2 ? 1 : -1
        },
        sortOrder: sortedInfo.columnKey === 'approvedBy' && sortedInfo.order,
        render: text => !text || text === " " ? <i style={{ color: "#666666" }}> Not applicable </i> :
          <div
            tabIndex={0}
            role="button"
            onKeyDown={e => {
              if (e.keyCode === 13) {
                this.showProfileDetails("Approver")
              }
            }}
            onClick={() => {
              this.showProfileDetails("Approver")
            }}><a style={{ color: '#19398A' }}><u>{text}</u></a></div>
      },
      {
        title: prepareTitle('0', <span role="button" >{this.props.label_keys.emailID}</span>),
        dataIndex: 'emailId',
        key: 'emailId',
        align: 'left',
        width: '13%',
        sorter: (a, b) => {
          let email = a.emailId.toLowerCase();
          let email2 = b.emailId.toLowerCase();
          return email < email2 ? -1 : 1
        },
        sortOrder: sortedInfo.columnKey === 'emailId' && sortedInfo.order,
      },
      
      { 
        title: prepareTitle('0', <span role="button" >{this.props.label_keys.companyName}</span>),
        dataIndex: 'companyname',
        key: 'companyname',
        align: 'left',
        width: '13%',
        sorter: (a, b) => {
          let companyname = a.companyname.toLowerCase();
          let companyname2 = b.companyname.toLowerCase();
          return companyname < companyname2 ? -1 : 1
        },
        sortOrder: sortedInfo.columnKey === 'companyname' && sortedInfo.order,
      } ,
    ];
    // console.log(columns)


    const components = {
      body: {
        row: EditableFormRowActiveCards,
        cell: EditableCellActiveCards
      }
    };
    const _columnArray = columns.map(col => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          cardRef: record.cardRef,
          editing: this.isEditing(record),
          handleSave: this.handleSave,
          // saveCreditLimit: this.saveCreditLimit,
          label_keys: this.props.label_keys,
          updatedActiveData: this.state.updatedActiveData,
          updatedErrorList: this.state.updatedErrorList
        })
      };
    });

    return (
      <div role="main">
        <div aria-relevant="text"
          aria-live={sortedInfo.order === "ascend" || sortedInfo.order === "descend" ? "polite" : "off"}
          aria-atomic="true" className="visually-hidden">{
            sortedInfo.order === "ascend" || sortedInfo.order === "descend" ?
              <div><span>Table sorted by {sortedInfo.columnKey}</span>
                <span> in {sortedInfo.order}ing order</span></div> : null}  </div>

        <div id='itemtest' ref={'itemtest'}  >
          {this.state.isLoading ? <div className="dark-spinner loader-color">
            <Spin tip={this.props.label_keys.loading} /></div> : null}
          {((this.props.roles === 'Super Admin' || this.props.roles === 'Customer Support') && this.state.companyId === null) ?
            
            <Card className='container ant-card-pv tab-link allActive-zoom-200'>
              <Row className='select-comp-container' align="middle">
                <Row gutter={[16, 16]} >
                  <Col span={6} offset={10} style={{paddingLeft: '1.5%'}}>
                    <span className='active-cards-spn'>{this.props.label_keys.activeCards}</span>
                  </Col>
                </Row>
                <Row>
                  <Col span={8} offset={9}>
                    <span className='select-comp-txt'>Select company to view Active Cards</span>
                    <br /><br />
                  </Col>
                </Row>
                <Row>
                  <Col span={10} offset={8} style={{paddingLeft: '2.1%'}}>
                    <Select className="active-select-comp super-admin-select"
                     onChange={this.companyChange} onDropdownVisibleChange={open => {
                      this.setState({isClose: !open})
                      }}
                      defaultActiveFirstOption={this.state.isClose}
                      optionFilterProp="children" showSearch onSearch={this.handleSearch} 
                      filterOption={(input, option) => option.props.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      style={{ width: '62%', border: '1px solid #D2D2E0', borderRadius: '4px', height: '35px' }} placeholder="Select Company">
                        {this.state.companyList.map(item => {
                          return <Option value={item.companyId} label={item.companyName}>{this.renderLabel(item)}</Option>
                        })}
                    </Select>
                  </Col>
                </Row>
              </Row>

            </Card>

            :

            <Card className='container ant-card-pv tab-link allActive-zoom-200' >
              <Row className={`allActiveCards-extra ${this.props.roles === 'Super Admin' || this.props.roles === 'Customer Support' ? 'super-admin' : ''} zoom-400`}>
              {!this.state.editableMode ?
                    <Col>{operations}</Col>
                  :
                  <Col offset={10} span={12} style={{ marginLeft: this.props.selectedLang === "French" ? '15%' : (this.props.theme === 'elan' && !this.props.ReqFlag ? '30%' : '27%'),marginTop:this.props.theme === 'elan' && !this.props.ReqFlag ? '5%' : '3%'}}>
                    <Col span={11} >
                      <Button className="cancel-btn-ant bulkUpload-cancl cancel_btn" onClick={() => this.handleCancelEditMode()} style={{ marginLeft: this.props.selectedLang === "French" ? 
 '-35%' : '-60%',padding:'0px 40px',fontSize:'14px' ,borderColor:'#19398A'}}>{this.props.label_keys.cancel}</Button>
                    </Col>
                    <Col span={11} offset={2}>
                      <Button className="save_btn"style={{backgroundColor:'#19398A',fontSize:'14px' }} type="primary bulkUpload-save" onClick={e => this.onSaveCardDetails()}  disabled={this.state.disableSaveChanges}>{this.props.label_keys.saveChanges}</Button>
                    </Col>
                  </Col>}
              </Row>
              <Row className="activeCards-extra allActive-title"
                style={{
                  marginBottom: '5px', paddingTop: '10px', paddingLeft: '20px', float: 'left', width: '100%', textTransform: 'none',
                }}
              >
                <Col span={12} className="allActive-title-col" style={{ marginTop: this.props.roles === 'Super Admin' || this.props.roles === 'Customer Support' ? '-2.5%' : (this.props.theme === 'elan'? '-3%' :'-1.5%') }}>
                  <div id="cards-tab" className="cards-tab allActive-zoom-400">
                    <div role="heading" aria-level="2" style={{ color: '#19398A', fontSize: 18, fontWeight: 600 }}>{((this.props.roles === "Card Company Admin" || this.props.roles === "Super Admin" || this.props.roles === 'Customer Support') ? this.props.label_keys.activeCards : this.props.label_keys.allCards) + ' (' + this.state.data.length + ')'}  </div>
                    <div style={{ color: '#666666', fontSize: 13 }} aria-live="polite" aria-atomic='true'><span style={{ textTransform: 'capitalize' }}>{this.props.label_keys.selected}</span> {this.state.selectedRowKeys.length} {this.props.label_keys.of} {this.state.data.length}</div>
                  </div>
                </Col>

               

              </Row>
              <div id='cards-tab-panel' style={{ marginTop: this.props.roles === 'Super Admin' || this.props.roles === 'Customer Support' ? '2%' : '3%' }}>
                <Table className='Req-table userlist  allActiveCards'
                  rowKey={obj => obj.cardRef}
                  sortDirections={["descend", "ascend"]}
                  columns={this.props.roles === "Super Admin" || this.props.roles === 'Customer Support' ? columns : _columnArray.filter(col => col.dataIndex !== 'companyname')}
                  components={components}
                  rowSelection={activeCardsDatarowSelection}
                  rowClassName={() => "editable-row"}
                  dataSource={this.state.data}
                  //loading={this.state.loadGrid}
                  locale={{ emptyText: this.props.label_keys.dataUnavailable }}
                 //rowSelection={activeCardsDatarowSelection}
                  scroll={{ x: 2000 }}
                  onChange={this.handleChange}
                  pagination={{
                    defaultPageSize: "8",
                    current: this.state.current,
                    showSizeChanger: true,
                    itemRender: this.itemRender,
                    pageSizeOptions: ["8", "20", "50", "100"],
                    position: "bottom",
                    showTitle: false,
                    showTotal: (total, range) => <div> <span className="total-text-pagination allActive-pagination-zoom">{this.props.label_keys.showing} {range[0]}-{range[1]} {this.props.label_keys.of} {total} </span>
                      <span className="page-count-span allActive-page-zoom">{this.props.label_keys.page} {Math.ceil(range[1]/this.state.size)} {this.props.label_keys.of} {this.state.TotalPage}</span></div>,				
                    onChange: this.pageChanger,
                    onShowSizeChange: this.OnPageSizeChange
                  }}
                  onRow={(record, rowIndex) => {
                    return {
                      onClick: event => {
                        // console.log("selected row::", record);
                        this.setState({ selectedRecord: record })
                      },
                    }
                  }}
                />
                <div>{footer}</div>
              </div>

            </Card>
          }


          {this.state.detailsVisible === true && <CradDetailComponent
            updateDetails={this.updateDetails}
            cardDetails={this.state.data1}
            detailsVisible={this.state.detailsVisible}
            selectedRow={this.state.selectedRecord}
            mobileNumber={this.state.data1.mobileNumber}
            selectedLang={this.props.selectedLang}
            view={"AllActiveCards"}
            roles={this.props.roles}
            okClicked={this.state.okClicked}
            onCLoseDetails={reloadFlag => {
              if (this.state.detailsVisible) {
                if (document.body.contains(this.state.prevActiveElement)) {
                  this.state.prevActiveElement.focus();
                }
              }
              this.setState({
                detailsVisible: false,
                prevActiveElement: null,
              })
              const divPagination = document.querySelectorAll(".ant-pagination-item");
              if(divPagination[0]){
                  divPagination.forEach(div => {
                      div.setAttribute('tabindex', "0");
                  })
              }
      
              const divPaginationPrev = document.querySelectorAll(".ant-pagination-prev");
              if(divPaginationPrev[0]){
                  divPaginationPrev.forEach(div => {
                      div.setAttribute('tabindex', "0");
                  })
              }
      
              const divPaginationNext = document.querySelectorAll(".ant-pagination-next");
              if(divPaginationNext[0]){
                  divPaginationNext.forEach(div => {
                      div.setAttribute('tabindex', "0");
                  })
              }
              const divPaginationPrevDis = document.querySelectorAll(".ant-pagination-disabled");
              if(divPaginationPrevDis[0]){
                divPaginationPrevDis.forEach(div => {
                      div.setAttribute('tabindex', "-1");
                  })
              }
      
            }}
            updateSuccess={() => {this.setState({ detailUpdateSuccess: true, okClicked: false})}}

            label_keys={this.props.label_keys} />}

          {this.state.profileDetailsVisible === true &&
            <ProfileDetails
              profileDetailsVisible={this.state.profileDetailsVisible}
              profileType={this.state.profileType}
              selectedRow={this.state.selectedRecord}
              selectedLang={this.props.selectedLang}
              label_keys={this.props.label_keys}
              onCloseProfileDetail={() => {
                if (this.state.profileDetailsVisible) {
                  if (document.body.contains(this.state.prevActiveElement)) {
                    this.state.prevActiveElement.focus();
                  }
                }
                this.setState({ profileDetailsVisible: false, prevActiveElement: null })
              }}
            />
          }

          {this.state.exportAllCardsModalVisible &&
            <Modal className="custom-modal custom-modal-bulk-card" role="dialog"
              visible={this.state.exportAllCardsModalVisible}
              closable={false}
              aria-labelledby="title" style={{ marginTop: "12%" }}
              title={<b className="visually-hidden" id="title">Export Cards</b>}
              footer={[
                <div key='export-cards'>
                  <button className="cancel-custom-btn ant-btn " style={{ color: "#19398a", borderColor: "#19398a", fontSize: 14, fontWeight: 400 }}
                    // ref={this.okBtnRef}
                    onKeyDown={e => {
                      if (e.key === 'Escape') {
                        // this.handleVisibleChange()
                        // console.log('document.body.contains(:::', document.body.contains(this.state.previousActiveElement))
                        if (document.body.contains(this.state.previousActiveElement)) {
                          this.state.previousActiveElement.focus()
                        }
                        this.setState({ exportAllCardsModalVisible: false });
                        // this.state.selectedRowKeys && this.setState({selectedRowKeys : [], selectedRow: []});

                      }
                    }}
                    onClick={() => {
                      if (document.body.contains(this.state.previousActiveElement)) {
                        this.state.previousActiveElement.focus()
                      }
                      this.setState({ exportAllCardsModalVisible: false })
                      // this.state.selectedRowKeys && this.setState({selectedRowKeys : [], selectedRow: []})
                    }} >{this.props.label_keys.cancel}</button>
                  <button className="ok-custom-btn ant-btn ant-btn-primary " style={{ marginLeft: "10%", backgroundColor: "#19398a", borderColor: "#19398a", fontSize: 14, fontWeight: 400 }}
                    onKeyDown={e => {
                      if (e.key === 'Escape') {
                        // this.handleVisibleChange()
                        if (document.body.contains(this.state.previousActiveElement)) {
                          this.state.previousActiveElement.focus()
                        }
                        this.setState({ exportAllCardsModalVisible: false })
                      }
                    }}
                    onClick={() => this.handleExportCards()}>{this.props.label_keys.confirm}</button>
                </div>
              ]}
            >
              <div className="custom-modal-content">
                {/* <p>{this.props.label_keys.createdsentForApproval}</p> */}
                <p>{this.props.label_keys.exporting} {this.state.selectedRowKeys.length > 0 ? this.state.selectedRowKeys.length : this.state.currentDataSource.length} {this.props.label_keys.of} {this.state.currentDataSource.length} {this.props.label_keys.cards_insmall}</p>
              </div>
            </Modal>
          }

          {this.state.deactivateConfirmationModal &&
            <Modal className="confirm-visible deactivate-modal deact-modal-zoom" role="dialog"
              visible={this.state.deactivateConfirmationModal}
              closable={false} style={{ marginLeft: "30%" }}
              aria-labelledby="title"
              title={<h2 style={{ fontSize: '16px', fontWeight: "700", color: "#161a80" }}>{this.props.label_keys.confirmRequired}</h2>}
              footer={[
                <div key='export-cards'>
                  <button className="cancel-custom-btn ant-btn " style={{ color: "#19398a", borderColor: "#19398a", fontSize: 14, fontWeight: 400 }}
                    // ref={this.okBtnRef}
                    onKeyDown={e => {
                      if (e.key === 'Escape') {
                        // this.handleVisibleChange()
                        // console.log('document.body.contains(:::', document.body.contains(this.state.previousActiveElement))
                        if (document.body.contains(this.state.previousActiveElement)) {
                          this.state.previousActiveElement.focus()
                        }
                        this.setState({ deactivateConfirmationModal: false });
                        // this.state.selectedRowKeys && this.setState({selectedRowKeys : [], selectedRow: []});

                      }
                    }}
                    onClick={() => {
                      if (document.body.contains(this.state.previousActiveElement)) {
                        this.state.previousActiveElement.focus()
                      }
                      this.setState({ deactivateConfirmationModal: false })
                      // this.state.selectedRowKeys && this.setState({selectedRowKeys : [], selectedRow: []})
                    }} >{this.props.label_keys.cancel}</button>
                  <button className="ok-custom-btn ant-btn ant-btn-primary " style={{ marginLeft: "10%", backgroundColor: "#19398a", borderColor: "#19398a", fontSize: 14, fontWeight: 400 }}
                    onKeyDown={e => {
                      if (e.key === 'Escape') {
                        // this.handleVisibleChange()
                        if (document.body.contains(this.state.previousActiveElement)) {
                          this.state.previousActiveElement.focus()
                        }
                        this.setState({ deactivateConfirmationModal: false })
                      }
                    }}
                    onClick={() => this.submitForDeactivateCard()}>{this.props.label_keys.deactivate}</button>
                </div>
              ]}
            >
              <div className="custom-modal-content">
                <p aria-hidden="true">{this.props.label_keys.areYouSureDeactivateSelected}{this.state.selectedRowKeys.length > 0 && this.state.selectedRowKeys.length}{this.props.label_keys.selectedCards}</p>
                <p className="visually-hidden">{`Are you sure you want to deactivate the ${this.state.selectedRowKeys.length > 0 && this.state.selectedRowKeys.length} cards selected?`}</p>
              </div>
            </Modal>
          }

          {this.state.confirmSuccess &&
            <Modal className="custom-modal custom-modal-bulk-card" role="dialog"
              visible={this.state.confirmSuccess}
              closable={false}
              aria-labelledby="title"
              title={<b className="visually-hidden" id="title">Deactivate Success</b>}
              footer={[<Button ref={this.modalBtnRef} type="primary" style={{ backgroundColor: '#19398A', fontWeight: 400, borderColor: '#19398A' }} onKeyDown={(e) => this.handleEsc(e, 'request')} onClick={() => {
                this.setState({ confirmSuccess: false });
                $('.search-input input').focus(); 
                // this.props.onCLoseDetails(true)
              }}>Ok</Button>]}
            >
              <div className="custom-modal-content">
                <img src={Tick} alt='success' /><br />
                <label aria-hidden="true">{this.props.label_keys.selectedCardsDeactivateSuccess}</label><span className="visually-hidden">Cards successfully deactivated.</span>
              </div>
            </Modal>
          }

          {this.state.confirmError &&
            <Modal className="confirm-visible  deactivate-modal"
              title={<h2 style={{ fontSize: '16px', fontWeight: "700", color: "#161a80" }}>{this.props.label_keys.confirmRequired}</h2>}
              visible={this.state.confirmError} style={{ marginLeft: "30%" }}
              onOk={() => this.submitForDeactivateCard()} cancelButtonProps={{ style: { paddingLeft: 0, paddingRight: 0, color: "#19398a", borderColor: "#19398a", fontSize: 14, fontWeight: 400 } }}
              onCancel={() => {
                if (document.body.contains(this.state.previousActiveElement)) {
                  this.state.previousActiveElement.focus()
                }
                this.setState({ confirmError: false })
                // this.props.onCLoseDetails(true)
              }}
              okText={this.props.label_keys.retry} okButtonProps={{ style: { marginLeft: "10%", paddingLeft: 0, paddingRight: 0, backgroundColor: "#19398a", borderColor: "#19398a", fontSize: 14, fontWeight: 400 } }}
              cancelText={this.props.label_keys.cancel}
              onLoad={this.defaultFocus()} >
              <div tabIndex={-1} className="error-deactivate" ref={"successDeactivateItem"}>

                <Icon type="warning" aria-label="Error" />
                <label>{this.props.label_keys.deactivateCardErrorMsg}</label>
              </div>
            </Modal>
          }

          
          {this.state.detailUpdateSuccess &&
              <Modal className="custom-modal custom-modal-bulk-card" role="dialog"
              visible={this.state.detailUpdateSuccess}
              closable={false}
              aria-labelledby="title"
              title={<b className="visually-hidden" id="title">Changes Update Success</b>}
              footer={[<Button ref={this.modalBtnRef} type="primary" style={{ backgroundColor: '#19398A', fontWeight: 400, borderColor: '#19398A' }} onKeyDown={(e) => this.setState({ detailUpdateSuccess: false, okClicked: true })} onClick={() => {
                  this.setState({ detailUpdateSuccess: false, okClicked: true });
                  setTimeout(() => {
                  $('.search-input input').focus(); 
                  }, 100);
                  // this.props.onCLoseDetails(true)
              }}>Ok</Button>]}
              >
              <div className="custom-modal-content">
                  <img src={Tick} alt='success' /><br />
                  <label>{this.props.label_keys.changesUpdatedSuccessfully}<br/>{this.props.label_keys.creditLimitWillUpdate}</label>
                  {/* <span className="visually-hidden">Cards successfully deactivated.</span> */}
              </div>
              </Modal>
          }
        </div>
      </div>
    )
  }
}


function mapStateToProps(state, props) {
  return {
    userDetails: state.authenticationReducer.userDetails,
    token: state.authenticationReducer.token,
    iclanguageList: state.instantCardMultiLanReducer.iclanguageList,
    theme: state.authenticationReducer.theme
  }
}

const mapDispatchToProps = dispatch => {
  return {

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AllActiveCards)

