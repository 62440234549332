import React, { Component } from 'react'
import ReactDOM from 'react-dom';
import { connect } from 'react-redux'
import axios from 'axios'
import { Row, Col, Icon, Button, notification, Drawer, Input,  Modal, Spin } from 'antd'
import 'antd/dist/antd.css';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {TextField} from '@mui/material';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import moment from 'moment-timezone';
import { loaderFocus } from '../../utils/accessability';
import isApproveBtn from '../../utils/approveButtonValidator';
import CardPreview from './CardPreview'
import EditIcon from '../../images/provisioner/Edit.svg'
import { viewPhoneNumber } from './phoneNumberFormat'
const { URLs } = require('../../data/constants.js');
const { TextArea } = Input;
var dateFormat = require('dateformat')

const todayDate = moment(new Date()).tz('America/Chicago').format('MM/DD/YYYY')

class RequestDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            validFrom: new Date(moment(new Date).tz('America/Chicago').format('MM/DD/YYYY')),
            expiryDate: moment(new Date).tz('America/Chicago').format('MM/DD/YYYY'),
            cardLimit: 0,
            field1: "",
            field2: "",
            notes: "",
            selectedRow: props.selectedRow,
            activeTab: props.activeTab,
            data: [],
            isCardDetailsEditable: false,
            isModalVisible: false,
            remarks: "",
            approvalButton: false,
            error: '',
            createDetails: null,
            id: '',
            errMsg: false,
            disableBtn: false,
            disabledCreate: false,
            status: null,
            refresh: false,
            datepickerOpen: false,
        }

        this.rejectionAreaRef = React.createRef();
    }

    componentDidMount() {
        //Getting request data by requestid
        this.getData()
    }
    
    getData = () => {
        let style = {
            backgroundColor: '#fff1f0',
            border: "1px solid #ffa39e",
        };
        let requestNo = this.state.selectedRow.reqNo ? this.state.selectedRow.reqNo : this.state.selectedRow.requestNo
        axios.get(URLs.cardUrl + `/getRequestData?request_id=${requestNo}`, {
            headers: {
                "Authorization": 'Bearer ' + this.props.token.access_token
            }
        }).then(res => {
            res.data.data &&
                this.setState({
                    data: res.data.data,
                    validFrom: new Date(moment(res.data.data.validFrom).format('MM/DD/YYYY')),
                    expiryDate: moment(res.data.data.expiryDate).format('MM/DD/YYYY'),
                    cardLimit: res.data.data.cardLimit,
                    field1: res.data.data.field1,
                    field2: res.data.data.field2,
                    notes: res.data.data.notes,
                    id: res.data.data.id,
                    status: res.data.data.status
                }, () => this.props.userDetails.singleCardApproval && this.approvalButton());
                res.data.data.status === "In Progress" && this.setState({disabledCreate: true})
            if(this.state.refresh){
                if(res.data.data.status === "Completed"){
                    
                    notification.success({
                        message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Success </span>{this.props.label_keys.cardCreateSuccessMsg}</span>,
                        duration: 3,
                        style: {
                            backgroundColor: '#dff0d8',
                            color: "green",
                            border: "1px solid green",
                        }
                    })
                }else if(res.data.data.status === "Process Failed"){
                    
                    notification.error({
                        message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>
                            {this.props.label_keys.couldNotcompleteReq}
                        </span>,
                        duration: 3,
                        style: style
                    })
                }else{
                    notification.success({
                        message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Success </span>{this.props.label_keys.cardCreationRequestSubmitted}</span>,
                        duration: 3,
                        style: {
                            backgroundColor: '#dff0d8',
                            color: "green",
                            border: "1px solid green",
                        }
                    })
                }
            }


        })
            .catch(err => {
                notification.error({
                    message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>
                        {this.props.label_keys.couldNotcompleteReq}
                    </span>,
                    duration: 3,
                    style: style
                })
            });
    }

    handleCloseKeyDownFocus = (e) => {
        
        if((this.state.activeTab === '1' || this.state.activeTab === '2' || this.state.activeTab === "Sent For Approval" || this.state.activeTab === "Rejected")  && (e.key === "Tab" )){
            setTimeout(() =>{
                document.getElementById("close-btn").focus();
            }, 100)
        }
        if (this.props.createVisible === true && e.key === "Tab" && e.shiftKey) {
            const cls = document.querySelector('#create-focus');
            cls.focus()
        }
    }

    handleKeyDownFocus = (e) => {
        if((this.state.activeTab === 'Requests')  && (e.key === "Tab" && !e.shiftKey )){
            setTimeout(() =>{
                document.getElementById("close-btn").focus();
            }, 100)
        }
        // if (this.props.createVisible === true && e.key === "Tab") {
        //     const cls = document.querySelector('#drawer-close');
        //     cls.focus()
        // }

        // if (this.props.createVisible === true && e.key === "Tab" && e.shiftKey) {
        //     const ele = document.querySelector('#reject_focus');
        //     setTimeout(() => {
        //         ele.focus()
        //     }, 100)
        // }
    }

    disableDate(current) {
        let startDate = new Date(todayDate)
        if (moment(current).format('YYYY-MM-DD') === this.state.data.expiryDate) return false;
        else if (startDate) return current <= moment(startDate).startOf('day')
        else return true
        // return current && (current > moment().add(12, "month") || current < moment());
    }

    componentDidUpdate() {
        // if (this.props.createVisible === true) {
        //     const closeBtn = document.querySelector('.anticon.anticon-close-circle.drawer-close');
        //     closeBtn.focus()
        // } 

        // if(this.state.isModalVisible){
        //         setTimeout(() => {
        //             this.rejectionAreaRef.current.focus();
        //         }, 100)
        // }
    }

    rejectRequest = () => {
        this.setState({ disableBtn: true })
        loaderFocus(".loader-color .ant-spin-text"); 
        if (this.state.remarks === '' || !(/\S/.test(this.state.remarks))) {
            this.setState({ errMsg: true, disableBtn: false })
        } else {
            let approvalStatusRequest = {
                approver_id: null,
                approval_status: "Rejected",
                request_id: this.state.selectedRow.reqNo,
                remarks: this.state.remarks,
                flag: "pending"
            };

            //reject logic
            axios.put(URLs.cardUrl + `/updateProvRejectionStatus`, approvalStatusRequest, {
                responseType: 'json',
                headers: {
                    "Authorization": 'Bearer ' + this.props.token.access_token
                }
            }).then(response => {
                this.setState({ disableBtn: false })
                if (response.status === 200) {
                    notification.success({
                        message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Success </span>
                        {this.props.label_keys.cardDetailsUploadSuccess}</span>,
                        duration: 3,
                        style: {
                            backgroundColor: '#dff0d8',
                            color: "green",
                            border: "1px solid green",
                        }
                    })
                    this.props.onCLoseCreate();
                }
                else if (response.status === 201) {
                    notification.success({
                        message: <span  role='alert' aria-live="assertive" aria-atomic="true" ><span className="visually-hidden">Success </span>{this.props.label_keys.approvedAlready}</span>,
                        duration: 3,
                        style: {
                            backgroundColor: '#dff0d8',
                            color: "green",
                            border: "1px solid green",
                        }
                    })
                    this.props.onCLoseCreate();
                }
                else {
                    notification.error({
                        message: <span  role='alert' aria-live="assertive" aria-atomic="true" ><span className="visually-hidden">Error </span>
                        {this.props.label_keys.couldNotcompleteReq}</span>,
                        duration: 3,
                        style: {
                            backgroundColor: '#fff1f0',
                            border: "1px solid #ffa39e",
                        }
                    })
                }

            }).catch(error => {
                this.setState({ disableBtn: false })
                notification.error({
                    message: <span  role='alert' aria-live="assertive" aria-atomic="true" ><span className="visually-hidden">Error </span>
                    {this.props.label_keys.couldNotcompleteReq}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                })
            }
            );
            this.setState({ isModalVisible: false });
        }
    };

    approvalButton() {
        let Approvalobj = []
        let obj = {
            'validFrom': this.state.validFrom,
            'validTill': this.state.expiryDate,
            'creditLimit': this.state.cardLimit,
        }
        Approvalobj.push(obj)
        let approvalThresholds = this.props.userDetails.approvalThresholds;
        let approvalFlowEnabled = this.props.userDetails.singleCardApproval;

        if (approvalFlowEnabled) {
            if (isApproveBtn(approvalThresholds, this.props.userDetails.cardLimitRule, this.props.userDetails.cardValidityRule, Approvalobj)) {
                this.setState({ approvalButton: true })
            }
            else {
                this.setState({ approvalButton: false })
            }
        } else {
            this.setState({ approvalButton: false })
        }
    }

    createCrad() {
        let error = []
        let numberReg = /^\d+$/;
        if (numberReg.test(this.state.cardLimit) === false || this.state.cardLimit < 1) error.cardLimit = true
        this.setState({ error, disableBtn: true })
        loaderFocus(".loader-color .ant-spin-text"); 
        if (error && !error.cardLimit) {
            let data = []
            let obj = {
                cardData: {
                    validFrom: dateFormat(new Date(moment(new Date).tz('America/Chicago').format('MM/DD/YYYY')), 'yyyy-mm-dd'),
                    validTo: dateFormat(this.state.expiryDate, 'yyyy-mm-dd'),
                    cardLimit: this.state.cardLimit,
                    field1: this.state.field1,
                    field2: this.state.field2,
                    notes: this.state.notes,
                    type: 'Single'
                },
                userData: {
                    employeeId: this.state.data.employeeId,
                    userEmail: this.state.data.emailId,
                    firstName: this.state.data.firstName,
                    lastName: this.state.data.lastName,
                    mobileNumber: this.state.data.mobileNumber,
                    request_id: this.state.selectedRow.reqNo,
                    id: this.state.id
                }
            }
            data.push(obj)
            axios.put(URLs.cardUrl + '/createBulkCard', data, {
                responseType: 'json',
                headers: {
                    "Authorization": 'Bearer ' + this.props.token.access_token,
                    "Content-Type": 'application/json',
                }
            }).then(response => {
                // this.setState({ disableBtn: false })
                // this.onCLose()
                if (response.data.serviceStatus.statusCode === 200) {
                    this.setState({disabledCreate: true, refresh: true});

                    setTimeout(() => {
                        this.getData();
                        this.onCLose();
                        this.setState({ disableBtn: false });
                        this.props.getProvisionerRequestData();
                    }, 5000);

                } else {
                    
                    this.setState({ disableBtn: false })
                    this.onCLose()
                    notification.error({
                        message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{this.props.label_keys.unableToCreateCard}</span>,
                        duration: 3,
                        style: {
                            backgroundColor: '#fff1f0',
                            border: "1px solid #ffa39e",
                        }
                    })

                }
            }).catch(error => {
                this.setState({ disableBtn: false })
                notification.error({
                    message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>
                    {this.props.label_keys.couldNotcompleteReq}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                })
            }
            )
        }
    }

    sentForApproval = () => {
        this.setState({ disableBtn: true })
        loaderFocus(".loader-color .ant-spin-text"); 
        let request = {
            card_ids: [this.state.data.id]
        };
        axios.post(URLs.cardUrl + `/sendForCardApproval`, request, {
            responseType: 'json',
            headers: {
                "Authorization": 'Bearer ' + this.props.token.access_token
            }
        }).then(response => {
            this.setState({ disableBtn: false })
            this.props.onCLoseCreate();
            notification.success({
                message: <span  role='alert' aria-live="assertive" aria-atomic="true" ><span className="visually-hidden">Success </span>{this.props.label_keys.successApproval}</span>,
                duration: 3,
                style: {
                    backgroundColor: '#dff0d8',
                    color: "green",
                    border: "1px solid green",
                }
            }
            );
            
            this.props.getProvisionerRequestData();
        }
        ).catch(error => {
            this.setState({ disableBtn: false })
            notification.error({
                message: <span  role='alert' aria-live="assertive" aria-atomic="true" ><span className="visually-hidden">Error </span>{this.props.label_keys.couldNotcompleteReq}</span>,
                duration: 3,
                style: {
                    backgroundColor: '#fff1f0',
                    border: "1px solid #ffa39e",
                }
            })
        }
        )
    };

    onCLose() {
        this.setState({
            validFrom: new Date(moment(new Date).tz('America/Chicago').format('MM/DD/YYYY')),
            expiryDate: new Date(moment(new Date).tz('America/Chicago').format('MM/DD/YYYY')),
            cardLimit: 0,
            field1: "",
            field2: "",
            notes: "",
            selectedRow: {},
            activeTab: "",
            data: [],
            isCardDetailsEditable: false,
            remarks: "",
            status: null,
        });
        this.props.onCLoseCreate()

    }
    render() {
        let isSingleCard = this.state.selectedRow.type === "Single";
        let isBulkCard = this.state.selectedRow.type === "Bulk";
        return (
            <Drawer
                aria-labelledby="req_singleCard"
                role="dialog"
                width={700}
                closable={false}
                maskClosable={false}
                keyboard={this.state.datepickerOpen ? false : true}
                onClose={() => this.onCLose()}
                visible={this.props.createVisible}
            >
                <div className="create-head ">
                    <span id="req_singleCard" role="heading" aria-level="2" >{this.props.label_keys.requestDetails}</span>
                    <Icon role="button" id="close-btn" className="drawer-close" aria-label="Close" tabIndex={0} type="close-circle" onKeyUp={e => {
                        if (e.keyCode === 13) {
                            this.onCLose()
                        }
                        
                    }} onClick={() => this.onCLose()} onKeyDown={(e) => {
                        this.handleCloseKeyDownFocus(e)}} />
                    
                    {/* <div tabindex={0} id="drawer-close" className="visually-hidden" aria-hidden="true"></div> */}
                </div><br />
                {this.state.disableBtn ? <div className="dark-spinner loader-color"><Spin tip={this.props.label_keys.loading} /></div> : null}
                <div  className=" create-holder-details create-head-req">
                    <div role="heading" aria-level="3" className="create-holder-details-head create-details-head">
                        {this.props.label_keys.details}
                    </div>

                    {isBulkCard ?
                        <Row>
                            <Col span={10}></Col>
                            <Col span={4}>
                                {
                                    this.state.selectedRow.type === this.props.label_keys.bulk && this.state.activeTab === this.props.label_keys.sentForApproval
                                        ? <img src={require(`../../images/provisioner/${this.props.theme}/sentForApproval.svg`).default} alt={""} />
                                        : null
                                }

                                {
                                    (this.state.selectedRow.type === "Bulk" && this.state.activeTab === "Rejected") ?
                                        <img src={require(`../../images/provisioner/${this.props.theme}/rejected.svg`).default} alt={""} /> : null
                                }
                            </Col>
                            <Col span={10}></Col>
                        </Row> : null}<br />
                    <Modal className="create-holder-details confirm-visible"
                        title={<h2 style={{fontSize: '16px', fontWeight:"700", color: "#161a80"}}>{this.props.label_keys.rejectionRemarks}</h2>}
                        okText={this.props.label_keys.submit}
                        visible={this.state.isModalVisible}
                        onOk={this.rejectRequest}
                        onCancel={() => {
                            this.setState({ isModalVisible: false, errMsg: false, remarks: "" })
                        }}
                        cancelText={this.props.label_keys.cancel}>
                        <TextArea  required style={{ width: "100%" }} aria-label="Rejection Remarks"
                            // ref={this.rejectionAreaRef}
                            rows={4}
                            maxLength={500}
                            aria-describedby="error_remarks"
                            value={this.state.remarks}
                            onChange={(event) => this.setState({ remarks: event.target.value, errMsg: false })} />
                        {this.state.errMsg && this.rejectionAreaRef.current.focus()}
                        {this.state.errMsg && <div id="error_remarks"  style={{ color: "#C92323", margin: '0px' }}>
                            <span className="visually-hidden">Error:</span>
                            <span>{this.props.label_keys.pleaseEnterRemarks}</span></div>}
                    </Modal>
                    <Row gutter={[16, 16]} justify='center'>
                        <Col className="gutter-row " span={8} flex>
                            <div className="card-title" >{this.props.label_keys.requestNumber}</div>
                            <div  className="card-value" >{this.state.data.reqNo}</div>
                        </Col>
                        <Col span={8} className="gutter-row " flex>
                            <div  className="card-title" >{this.props.label_keys.requestOwner}</div>
                            <div  className="card-value" >{this.state.data.reqOwner}</div>
                        </Col>
                        <Col span={8} className="gutter-row " flex>
                            <div className="card-title" >{this.props.label_keys.requestDate}</div>
                            <div  className="card-value" >{this.state.selectedRow.requestedDate ? this.state.selectedRow.requestedDate : this.state.selectedRow.requestDate}</div>
                        </Col>

                    </Row>
                    {/* Card-Holder-Details */}
                    <Row gutter={[16, 16]} justify='center'>
                        <Col className="gutter-row " span={8} flex>
                            <div  className="card-title card-holder-title" >{this.props.label_keys.type}</div>
                            <div  className="card-value" >{this.state.selectedRow.type}</div>
                        </Col>
                        {isSingleCard ?
                            <Col span={8} className="gutter-row " flex>
                                <div  className="card-title" >{this.props.label_keys.cardHolder}</div>
                                <div  className="card-value" > {this.state.data.firstName} {this.state.data.lastName} </div>
                            </Col> : <>
                                <Col span={8} className="gutter-row " flex>
                                    <div  className="card-title" aria-hidden="true" >{this.props.label_keys.noOfCards}</div><span className="visually-hidden">Number of cards</span>
                                    <div  className="card-value" > {this.state.data.noOfCards} </div>
                                </Col>
                                <Col span={8} className="gutter-row " flex>
                                    <div  className="card-title" >{this.props.label_keys.creditLimit}</div>
                                    <div  className="card-value" > {this.state.data.creditLimit} </div>
                                </Col>
                            </>
                        }
                        {this.state.selectedRow.type === "Single" ?
                            <Col span={8} className="gutter-row " flex>
                                <div  className="card-title" >{this.props.label_keys.status}</div>
                                <div  className="card-value" className={
                                    this.state.status === "Rejected" ? 'text-red' : 'text-green'
                                }>{this.state.status}</div>
                            </Col> : null}
                    </Row>
                    <Row gutter={[16, 16]} justify='center'>
                        {this.state.selectedRow.type === "Bulk" ?
                            <Col span={8} className="gutter-row " flex>
                                <div  className="card-title" >{this.props.label_keys.status}</div>
                                <div  className="card-value" className={
                                    this.state.selectedRow.status === "Rejected" ? 'text-red' : 'text-green'
                                }>{this.state.selectedRow.status}</div>
                            </Col> : null}
                    </Row>
                </div>
                <div className="create-holder-details">

                    {this.state.selectedRow.status === 'Rejected' ?
                        (<Col span={24} style={{ marginBottom: '20px' }}>
                            <div style={{ color: "#C92323" }}><b>{this.props.label_keys.rejectionReason}</b></div>
                            <div style={{ color: "#666666" }} >{this.state.data.remarks}</div>
                        </Col>)
                        : null}
                </div>
                {isSingleCard ? <>
                    <div className="create-holder-details">
                        <div role="heading" aria-level="3" className="create-holder-details-head">
                            {this.props.label_keys.cardDetails}
                        </div>

                        {(this.state.activeTab === "Requests") ?

                            <div tabindex={0} className="create-holder-details-head pull-right" id="editCardDetails-btn"
                                role='button'
                                aria-label="Edit card details"
                                style={{ cursor: 'pointer' }}
                                onKeyDown={e => {
                                    if (e.keyCode === 13) {
                                        this.setState({ isCardDetailsEditable: !this.state.isCardDetailsEditable })
                                    }
                                    
                                    if(e.key === "Tab" && this.state.disabledCreate && !this.state.isCardDetailsEditable){
                                        this.handleKeyDownFocus (e);
                                    }
                                }}
                                onClick={() => this.setState({ isCardDetailsEditable: !this.state.isCardDetailsEditable })}>
                                <img src={EditIcon} alt={""} />{this.props.label_keys.editDetails}
                            </div> : null}
                        <Row>
                            <Col span={4} className="create-holder-details-body">
                                <div style={{paddingBottom: '2.5%'}}>{this.state.data.validFrom !== '' ?
                                    <span>{this.props.label_keys.cardValidFrom} *</span> :
                                    <span>&nbsp;</span>}</div>
                                {/* <DatePicker
                                    className={`${(this.state.validFrom !== '' || this.state.validFrom !== null) && 'blue-border'}`}
                                    disabled={!this.state.isCardDetailsEditable}
                                    format={'MM/DD/YYYY'}
                                    onChange={e => this.setState({ validFrom: e })}
                                    value={moment(this.state.validFrom, 'MM/DD/YYYY')} disabled /> */}
                                    <span className="req-details disable-input" style={{backgroundColor: '#f5f5f5', cursor: 'not-allowed'}}>
                                        {moment(this.state.validFrom).format('MM/DD/YYYY')}
                                    </span>
                                    <CalendarTodayOutlinedIcon className="calendar"/>




                            </Col>
                            <Col span={4} className="create-holder-details-body">
                                <div >{this.state.data.expiryDate !== '' ?
                                    <span>{this.props.label_keys.cardValidTo} *</span> :
                                    <span>&nbsp;</span>}</div>


                                {/* <DatePicker
                                    className={`${(this.state.expiryDate !== '' || this.state.expiryDate !== null) && 'blue-border'}`}
                                    disabled={!this.state.isCardDetailsEditable}
                                    format={'MM/DD/YYYY'}
                                    disabledDate={current => {
                                        return current && (current > moment().add(12, "month") || current < moment());
                                    }}
                                    onChange={e => this.setState({ expiryDate: e })}
                                    value={moment(this.state.expiryDate, 'MM/DD/YYYY')} /> */}

                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        disabled={!this.state.isCardDetailsEditable}
                                        className={`${(this.state.expiryDate !== '' || this.state.expiryDate !== null) && 'blue-border datepicker'}`}
                                        // shouldDisableDate={(current) => this.disableDate(current)}
                                        disableHighlightToday
                                        components={{
                                            OpenPickerIcon: CalendarTodayOutlinedIcon,
                                        }}
                                        PopperProps={{
                                            className: "datepicker-desktop", "aria-label": "Choose Date"
                                        }}
                                        DialogProps={{
                                            className: "datepicker-mobile", "aria-label": "Choose Date"
                                        }}
                                        inputProps={{ 
                                            tabindex: "-1",
                                            readOnly: true,
                                            "aria-label":"card valid to"
                                        }}
                                        getOpenDialogAriaText={(date, utils) => `Card Valid To Choose date, selected date is ${utils.format(utils.date(date), 'fullDate')}`}    
                                    
                                        value={this.state.expiryDate}
                                        onOpen={() => {
                                            this.setState({datepickerOpen: true})
                                            setTimeout(() => {
                                                const val = document.getElementsByClassName('MuiCalendarPicker-root');
                                                
                                                if(val.length > 0){
                                                    const val2 = document.getElementsByClassName(val[0].firstChild.getAttribute('class'));
                                                    if(val2[0]){
                                                        val2[0].firstChild.setAttribute('aria-live', 'assertive');
                                                        val2[0].firstChild.setAttribute('aria-atomic', 'true');
                                                    }
                                                }
                                                    
                                            }, 1000);
                                        
                                        }}
                                        onClose={() => this.setState({datepickerOpen: false})}
                                        onChange={e => {
                                            if(e > moment(todayDate).startOf('day')){
                                                this.setState({ expiryDate: e })
                                            }
                                        }}
                                        renderInput={(params) => <TextField {...params}  variant="standard" 
                                        color="primary"  focused 
                                        id="cardValidTo"
                                        className={`${(this.state.expiryDate !== '' || this.state.expiryDate !== null) && 'blue-border'}`} />} />
                                </LocalizationProvider>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={4} className="create-holder-details-body">
                                <div >{this.state.cardLimit !== '' ? <span className={`${this.state.error && this.state.error.cardLimit === true && "error-text"}`}>{this.props.label_keys.cardLimit} *</span> : <span>&nbsp;</span>}</div>
                                <Input required placeholder={`${this.props.label_keys.cardLimit} *`} id='cardlimit-input'
                                    type='number'
                                    disabled={!this.state.isCardDetailsEditable}
                                    aria-describedby = 'card-limit-error'
                                    className={`${(this.state.error && this.state.error.cardLimit === true) ? 'error-border' : (this.state.cardLimit !== '' && 'blue-border')}`}
                                    value={this.state.cardLimit}
                                    onChange={e => this.setState({ cardLimit: e.target.value, error: '' }, () => { this.approvalButton() })} />
                                {this.state.cardLimit !== null && this.state.cardLimit !== '' && <label className="limit-lable">$</label>}
                                {this.state.error && this.state.error.cardLimit === true &&
                                    <Icon aria-hidden='true' role="img" className="error-icon" type="warning" style={{ marginLeft: '81%' }} />}
                                {this.state.error && this.state.error.cardLimit === true &&
                                    <div id="card-limit-error"><span className="visually-hidden">Error:</span>
                                    <span className="suggestion-text error-text">{this.props.label_keys.invalidCreditLimit}</span></div>}
                            </Col>
                            <Col span={4} className="create-holder-details-body">
                                <div>{this.state.data.field1 !== '' ? <span>{this.props.label_keys.field1}</span> :
                                    <span>&nbsp;</span>}</div>
                                <Input placeholder={this.props.label_keys.field1}
                                    className={`${this.state.data.field1 !== '' && 'blue-border'}`}
                                    value={this.state.field1}
                                    onChange={e => this.setState({ field1: e.target.value })}
                                    disabled={!this.state.isCardDetailsEditable} />
                            </Col>

                        </Row>
                        <Row>
                            <Col span={4} className="create-holder-details-body">
                                <div >{this.state.data.field2 !== '' ? <span>{this.props.label_keys.field2}</span> :
                                    <span>&nbsp;</span>}</div>
                                <Input placeholder={this.props.label_keys.field2}
                                    className={`${this.state.data.field2 !== '' && 'blue-border'}`}
                                    value={this.state.field2}
                                    onChange={e => this.setState({ field2: e.target.value })}
                                    disabled={!this.state.isCardDetailsEditable} />
                            </Col>

                            <Col span={4} className="create-holder-details-body">
                                <label for='notes-input' >{this.props.label_keys.notes}</label>
                                <TextArea id='notes-input' rows={4} value={this.state.notes}
                                    disabled={!this.state.isCardDetailsEditable}
                                    onChange={e => this.setState({ notes: e.target.value })} />
                            </Col>
                        </Row>
                    </div>
                    <div  className="create-holder-details">
                        <div role="heading" aria-level="3" className="create-holder-details-head">
                            {this.props.label_keys.cardHolderDetails}:
                        </div>
                        <Row>
                            <Col span={4} className="create-holder-details-body">
                                <div  className="card-title create-holder-details-fName" >{this.state.name !== '' ? <span>{this.props.label_keys.firstName} *</span> :
                                    <span>&nbsp;</span>}</div>
                                <div  className="card-value create-holder-details-value" >{this.state.data.firstName}</div>
                            </Col>
                            <Col span={4} className="create-holder-details-body">
                                <div  className="card-title create-holder-details-fName" >{this.state.lastNme !== '' ? <span>{this.props.label_keys.lastName} *</span> :
                                    <span>&nbsp;</span>}</div>
                                <div  className="card-value create-holder-details-value" >{this.state.data.lastName}</div>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={4} className="create-holder-details-body">
                                <div  className="card-title create-holder-details-fName" >{this.state.employeeID !== '' ? <span>{this.props.label_keys.employeeId}</span> :
                                    <span>&nbsp;</span>}</div>
                                <div  className="card-value create-holder-details-value" >{this.state.data.employeeId}</div>
                            </Col>
                            <Col span={4} className="create-holder-details-body">
                                <div  className="card-title create-holder-details-fName" >{this.state.data.emailID !== '' ? <span
                                    className={`${this.state.error && this.state.error.emailID === true && "error-text"}`}>{this.props.label_keys.emailID} *</span> :
                                    <span>&nbsp;</span>}</div>
                                <div  className="card-value create-holder-details-value" >{this.state.data.emailId}</div>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={4} className="create-holder-details-body phone-number-div">
                                {this.state.phoneNumber !== '' ?
                                    <p className={`card-title create-holder-details-fName suggestion-text ${this.state.error && this.state.error.phoneNumber === true && "error-text"}`}>{this.props.label_keys.mobileNumber} *</p> :
                                    <span>&nbsp;</span>}
                                <div  className="card-value create-holder-details-value">{this.state.data.phoneCode}</div>
                                {this.state.data.mobileNumber ? <div className="card-value create-holder-details-value">{viewPhoneNumber(this.state.data.mobileNumber)}</div > : <span className="card-value">---</span>}
                            </Col>
                            <Col span={4}></Col>
                        </Row>
                    </div>
                </> : null}

                <div className="create-button">
                    {(this.state.activeTab === "Requests") ?
                        (!this.state.isCardDetailsEditable) ?
                            <>
                                <Button
                                   id="reject_focus" disabled={this.state.disabledCreate}  onClick={() => this.setState({ isModalVisible: true })}>{this.props.label_keys.reject}</Button> &nbsp; &nbsp; &nbsp; &nbsp;
                                 {this.state.approvalButton ?
                                    <Button onClick={() => this.sentForApproval()} disabled={this.state.disabledCreate} onKeyDown={(e) => this.handleKeyDownFocus (e)}>{this.props.label_keys.sendForApproval}</Button>
                                    : <Button onClick={() => this.createCrad()} disabled={this.state.disabledCreate} onKeyDown={(e) => this.handleKeyDownFocus (e)}>{this.props.label_keys.create}</Button>
                                }
                            </>
                            : <>
                                <Button
                                    onClick={() => this.saveCardDetails()}>{this.props.label_keys.save}</Button> &nbsp; &nbsp; &nbsp; &nbsp;
                                <Button onKeyDown={(e) => this.handleKeyDownFocus (e)}
                                    onClick={() => {
                                        if(this.state.error && this.state.error.cardLimit){
                                            let error = [];
                                            error.cardLimit = false;
                                            this.setState({error})
                                        }
                                        this.setState({ isCardDetailsEditable: !this.state.isCardDetailsEditable,  }, () => this.getData())
                                        if(this.state.disabledCreate){
                                            document.getElementById('editCardDetails-btn').focus();
                                        }
                                    }}>{this.props.label_keys.cancel}</Button>
                            </> : null}
                            <div tabindex={0} id="create-focus" className="visually-hidden" aria-hidden="true"></div>
                </div>
            </Drawer>
        )
    }

    saveCardDetails() {
        let error = []

        let numberReg = /^\d+$/;
        if (numberReg.test(this.state.cardLimit) === false || this.state.cardLimit < 1) error.cardLimit = true

        this.setState({ error })

        if (error && !error.cardLimit) {
            this.approvalButton()
            let newData = {
                id: this.state.data.id,
                firstName: this.state.data.firstName,
                lastName: this.state.data.lastName,
                mobileNumber: this.state.data.mobileNumber,
                employeeId: this.state.data.employeeId,
                cardLimit: this.state.cardLimit,
                validFrom: this.state.validFrom,
                expiryDate: this.state.expiryDate,
                emailId: this.state.data.emailId,
                field1: this.state.field1,
                field2: this.state.field2,
                notes: this.state.notes,
                remarks: this.state.data.remarks,
                reqNo: this.state.data.reqNo,
                reqOwner: this.state.data.reqOwner,
                requestDate: this.state.data.requestDate,
                status: this.state.data.status,
                type: this.state.data.type,
                userId: this.state.data.userId
            }

            let newCardRequestData = {
                data: [{
                    id: this.state.data.id,
                    name: this.state.data.firstName,
                    lastName: this.state.data.lastName,
                    mobileNumber: this.state.data.mobileNumber,
                    employeeId: this.state.data.employeeId,
                    creditLimit: this.state.cardLimit,
                    validTill: moment(this.state.expiryDate).format('yyyy-MM-DD'),
                    projectName: "",
                    emailId: this.state.data.emailId,
                    isPassed: true,
                    field1: this.state.field1,
                    field2: this.state.field2,
                    notes: this.state.notes,
                }]
            }

            axios.post(URLs.cardUrl + `/saveRequestData`, newCardRequestData, {
                responseType: 'json',
                headers: {
                    "Authorization": 'Bearer ' + this.props.token.access_token
                }
            }).then(response => {
                if (response.data.serviceStatus.statusCode === 200) {
                    notification.success({
                        message: <span  role='alert' aria-live="assertive" aria-atomic="true" ><span className="visually-hidden">Success </span>{this.props.label_keys.cardDetailsUpdatedSuccess}</span>,
                        duration: 3,
                        style: {
                            backgroundColor: '#dff0d8',
                            color: "green",
                            border: "1px solid green",
                        }
                    })
                } else {
                    notification.error({
                        message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{this.props.label_keys.couldNotcompleteReq}</span>,
                        duration: 3,
                        style: {
                            backgroundColor: '#fff1f0',
                            border: "1px solid #ffa39e",
                        }
                    })
                }

            }).catch(error =>
                notification.error({
                    message: <span  role='alert' aria-live="assertive" aria-atomic="true" ><span className="visually-hidden">Error </span>
                    {this.props.label_keys.couldNotcompleteReq}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                }));

            this.setState({ data: newData, isCardDetailsEditable: !this.state.isCardDetailsEditable });
            if(this.state.disabledCreate){
                document.getElementById('editCardDetails-btn').focus();
            }
        }
        else{
            document.getElementById('cardlimit-input').focus();
        }
    }


}

function mapStateToProps(state, props) {
    return {
        userDetails: state.authenticationReducer.userDetails,
        token: state.authenticationReducer.token,
        theme: state.authenticationReducer.theme

    }
}

export default connect(mapStateToProps, null)(RequestDetails)

