import initialState from './initialState';

const myApprovalReducer = (state = initialState, action) => {

    switch (action.type) {

        case 'REJECT_REQUEST':            
            return Object.assign({}, state, { updateApprovalResponseObj: action.data.data.data, saved: action.saved });       
        default:
            return state;
    }
};

export default myApprovalReducer;