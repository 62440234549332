export default function isTokenValid({ token, tokenTimestamp }) {
    
    if (token &&
        tokenTimestamp &&
        token.expires_in &&
        !(isNaN(+token.expires_in)) &&
        (+tokenTimestamp + +token.expires_in > Date.now() / 1000)
    )
        return true;
    else return false;
}