import initialState from './initialState';


const createtripReducer = (state = initialState, action) => {

        switch (action.type) {

                case 'NEWTRIP':
                        console.log("inside login reducer function")
                        return Object.assign({}, state, { tripId: action.data.data.tripId, travelleruniqueId: action.data.data.travelerId, redirect: action.data.data.redirect, activeCard: action.data.data.activeCard });


                case 'ASSIGN__CARD':
                        console.log(action.data)
                        return Object.assign({}, state, { cvv: action.data.cvv, cardNumber: action.data.cardNumber, cardExpiryDate: action.data.cardExpiryDate, cardStartDate: action.data.cardStartDate, fullcardNumber: action.data.payload, cardAssigned: true });


                case 'RE_DIRECT':
                        console.log(action.data)
                        return Object.assign({}, state, { redirect: false, cardAssigned: false, activeCard: false });


                case 'CLEAR_TRIPID':
                        console.log(action.data)
                        return Object.assign({}, state, { tripId: null });

                case 'SET_TRIPID':
                        console.log(action.data)
                        return Object.assign({}, state, { tripId: action.data });

                case 'CARD_LIMIT':
                        console.log(action.data)
                        return Object.assign({}, state, { cardLimitvalue: action.data });

                case 'SAVE_TRIP':
                        console.log(action.data)
                        return Object.assign({}, state, { savetrip: true, tripId: action.data.data.tripId, travelleruniqueId: action.data.data.travelerId });

                default:
                        return state;
        }
};

export default createtripReducer;