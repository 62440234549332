import React, { Component, Fragment } from 'react';
import { Form, Icon, Input, Button, Row, Col } from 'antd';
import '../styling/login.css';
import '../FontIcons/style.css';
import { errorMessages } from '../data/constants';
const FormItem = Form.Item;
class MFAFormComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      otpDigits: [null, null, null, null],
      validationError: null
    }

    this.otpDigitsInputs = [null, null, null, null]
  }

  componentDidMount() {
    this.otpDigitsInputs[0].node.focus()
  }

  clearError(){
    this.setState(() => ({
      validationError: null
    }))
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div>
        <Row>
        <Col xs={{ span: 24, offset: 0 }} md={{ span: 19, offset: 5 }} lg={{ span: 14, offset: 10 }}>
         {/*  <Col xs={{ span: 8, offset: 18 }} md={{ span: 8, offset: 18 }} lg={{ span: 14, offset: 10 }}> */}
            <Form onSubmit={(e) => {
              e.preventDefault();
              let otp = ''
              for (let i = 0; i < this.state.otpDigits.length; i++) {
                let otpDigit = this.props.form.getFieldValue(`otp-digit-${i}`);
                if (!(otpDigit >= '0' && otpDigit <= '9')) {
                  this.setState(() => ({
                    validationError: errorMessages.error_val_otp
                  }))
                  return
                }
                otp += otpDigit
              }
              this.props.onSubmit({ otp })
            }} className="mfa-form">
              {
                (() => {
                  let otpDigitsInputs = []
                  for (let i = 0; i < this.otpDigitsInputs.length; i++) {
                    otpDigitsInputs.push(
                      <Col span={6} key={i}>
                        <FormItem>
                          {getFieldDecorator(`otp-digit-${i}`)(
                            <Input
                              autoComplete="off"
                              maxLength="1"
                              aria-label={`OTP digit ${i}`}
                              style={{ textAlign: 'center', width: '80%' }}
                              ref={node => {
                                this.otpDigitsInputs[i] = {
                                  id: i,
                                  node
                                }
                              }}
                              onChange={(e) => {
                                this.clearError()
                                let value = e.target.value
                                this.props.form.setFieldsValue({
                                  [`otp-digit-${i}`]: value,
                                });
                                if (value >= '0' && value <= '9') {
                                  if (i < this.otpDigitsInputs.length - 1) this.otpDigitsInputs[i + 1].node.focus()
                                }
                              }}
                            />
                          )}
                        </FormItem>
                      </Col>
                    )
                  }
                  return (
                    <Row>
                      {otpDigitsInputs}
                    </Row>
                  )
                })()
              }

              <FormItem style={{ textAlign: 'center' }}>
                <Button aria-label="continue" type="primary" htmlType="submit"
                  className="login-form-button login-button"
                 /*  style={{ backgroundColor: '#0A41C5', borderRadius: '3rem', color: 'white', borderColor: "#0A41C5", width: "190px", marginLeft: "-7%" }} */
                  disabled={this.props.isAuthenticating}>
                  {
                    this.props.isAuthenticating === true ?
                      (
                        <Fragment>
                          <Icon type="loading" style={{ color: 'white' }} />
                        </Fragment>
                      ) : <span>{this.props.app_labels.continue}</span>
                  }
                </Button>
              </FormItem>
            </Form>
          </Col>
        </Row>
        <Row>
          <Col xs={{ span: 24, offset: 0 }} md={{ span: 19, offset: 5 }} lg={{ span: 14, offset: 10 }}>
            <div style={{ color: "#C92323" }}>
              {this.state.validationError}
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}


const WrappedMFAFormComponent = Form.create()(MFAFormComponent);

export default WrappedMFAFormComponent

