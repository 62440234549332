import React from "react";
import axios from 'axios';
// import { BrowserRouter as Router, Link } from 'react-router-dom';
import MediaQuery from 'react-responsive';
import { connect } from 'react-redux';
import { Row, Col, Input, Select, notification, Spin, Menu, Layout, Table, Divider, Dropdown, Icon } from 'antd';
import { loaderFocus } from '../../../utils/accessability';
// import { SIGCHLD } from "constants";
import '../../../styling/viewEditProfile.css';
import '../../../styling/carousel.css';
import AdminCarousel from '../adminCarousel';
import EditProfile from './editProfile';
import InfiniteScroll from 'react-infinite-scroll-component';
import $ from 'jquery';
import { FilterByRoleBtn, FilterByCompanyBtn } from "./filterByRoleBtn";
import { resetUserDelete } from "../../../actions/userAction";
const Search = Input.Search;
const { Option } = Select;
const { URLs, errorMessages } = require('../../../data/constants.js');
// const { app_labels } = require('../../../data/english_label');
const { Sider, Content } = Layout;
class ViewandEditProfile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            profileList: [],
            selectedFilter: null,
            searchFlag: false,
            searchArray: [],
            userSearchArray: [],
            requestSent: false,
            UserProfileData: [],
            sortColor: "#636466",
            filteredArray: [],
            userfilteredArray: [],
            currentkey: "1",
            newFilterDropDown: [],
            newFilterDropDown1: [],
            spinFlag: false,
            searchInput: "",
            sortFlag: false,
            profileChangeFlag: false,
            isDeleted: null,
            visible: false,
            clicked: false,
            selectedFilterValue: [],
            selectedFilterValueCompany: [],
            selectedFilterValueRole: []
        }
        this.handleClick = this.handleClick.bind(this);
    }
    /**
     * @param  {} {this.FetchAllProfile(
     * lifecycle method, its called on mount of the component
     */
    componentDidMount() {
        this.FetchAllProfile();

        let element = $('.ant-select-arrow-icon')
        element.empty()
        element.removeClass().addClass('icon-drop-down')
        let element1 = $('.ant-table-thead')
        element1.empty()
    }
    componentDidUpdate() {

        // TODO : The below lines of code added into CreateUserProfileComponent component and commented below in order to fix A11Y issues. in future everything is fine it can be removed from here.

        // const presentation = document.querySelector(".ant-layout-content");
        // const banner = document.querySelector(".ant-layout-content>div");
        // const main = document.querySelector(".container-fluid");
        // banner.setAttribute("role", "banner");
        // presentation.setAttribute("role", "presentation")
        // main.setAttribute("role", "main")
        if (this.state.selectedFilter != null) {
            let element = $('.ant-select-arrow-icon')
            element.empty()
            element.removeClass().addClass('icon-drop-down')
        }

        const divSearch = document.getElementsByClassName("anticon anticon-search ant-input-search-icon");

        if (divSearch[0]) {
            divSearch[0].setAttribute('role', 'img')
            divSearch[0].setAttribute('aria-hidden', 'true')
            divSearch[0].classList.add('search-icon-prop')
        }

        const ul = document.querySelectorAll(".ant-menu.ant-menu-light");
        if (ul) {
            ul.forEach(el => {
                el.querySelector("li").setAttribute("role", "button")
                el.removeAttribute("role");
            })
        }
        let tableElement = $('.dashboardTable table');
        tableElement.attr('role', 'presentation');
        let listElement = $('.dashboardTable tbody');
        listElement.attr("role", "list");
        listElement.attr('tabIndex', -1);
        let listitemElement = $('.dashboardTable tbody tr');
        listitemElement.attr("role", "listitem");
        let parentlistitemElement = $('.dashboardTable tbody tr ul');
        parentlistitemElement.attr("role", "presentation");
    }

    /**
     * update the flag
     */
    updateDeleteFlag = (value) => {
        this.setState({ spinFlag: value });
    }

    handleDelete = (userId) => {
        let item = [];
        
        if (this.state.selectedFilter != null || (this.state.selectedFilterValue && this.state.selectedFilterValue.length)) {
            item = [...this.state.userfilteredArray]
            item = item.filter((item) => item.userId !== userId)
            this.setState({ userfilteredArray: item })
        } else {
            if (this.state.searchArray.length === 0) {
                item = [...this.state.UserProfileData]
                item = item.filter((item) => item.userId !== userId)
                this.setState({ UserProfileData: item })

            } else if (this.state.userSearchArray) {
                item = [...this.state.userSearchArray]
                item = item.filter((item) => item.userId !== userId)
                this.setState({ userSearchArray: item })
            }
        }


        
    }


    /**
    * Fetching all the Existing User Profile
    */
    FetchAllProfile = () => {
        this.updateDeleteFlag(true);
        loaderFocus(".loader-color .ant-spin-text")
        var obj = {
            "requestType": this.props.userrole.toLowerCase() === 'super admin' || this.props.userrole.toLowerCase() === 'customer support' ? 'superadmin' : 'fetchUserProfiles',
        }
        axios.post(URLs.userUrl + "search", obj, {
            headers: {
                "Authorization": 'Bearer ' + this.props.token.access_token
            }
        }).then(response => {
            if (response.data.serviceStatus.statusCode === 200) {
                var count = 1;
                var newObj = [];
                response.data.travelers.forEach((item) => {
                    item.key = count;
                    count++;
                    newObj.push(item);
                })
                this.setState({ profileList: newObj, UserProfileData: newObj.slice(0, 10), spinFlag: false }, () => {
                    if (this.state.selectedFilterValue && this.state.selectedFilterValue.length !== 0)
                        this.filterRoleComp(null, null);
                })
            }
            else if (response.data.serviceStatus.statusCode === 210) {
                this.updateDeleteFlag(false);
                notification.error({
                    message: <span role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{this.props.app_labels.couldNotcompleteReq}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }
            else {
                this.updateDeleteFlag(false);
                notification.error({
                    message: <span role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{this.props.app_labels.couldNotcompleteReq}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }

        }).catch(error => {
            this.updateDeleteFlag(false);
            if (error.response !== undefined && error.response.data !== null && error.response.data !== undefined && error.response.data.serviceStatus !== undefined) {
                notification.error({
                    message: <span role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{this.props.app_labels.couldNotcompleteReq}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }
            else {
                notification.error({
                    message: <span role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{this.props.app_labels.couldNotcompleteReq}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }
        })
    }

    /**To display texts in correct foramt(First letter will be in Capital)
    * @param  {} text
    */
    capitalize = (text) => {
        var s = text.toLowerCase();
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
    }

    /**on Cick of corresponding trip in list view will open the corresponing trip details
      * @param  {} e
      */
    handleClick = (value) => {
        this.setState({
            currentkey: value,
            profileChangeFlag: true
        });
        let profileDetailsFocus = $('.ViewEditProfileClass').find('.admin_heading b');
        profileDetailsFocus.attr('tabIndex',-1);
        profileDetailsFocus.focus();
        this.props.resetUserDelete();

    }

    /**
     * On click of search , calls the axios request with userRole and the value to be searched as obj
     */
    onSearch = (value) => {
        this.props.resetUserDelete();
        if (value.trim().length === 0) {
            this.setState({ currentkey: "1" });
            return
        }
        this.setState({ searchFlag: true })
        loaderFocus(".loader-color .ant-spin-text");
        var obj = {
            "requestType": this.props.userrole.toLowerCase() === 'super admin' || this.props.userrole.toLowerCase() === 'customer support' ? 'superadmin' : 'fetchUserProfiles',
            "filter": {
                "name": value
            }

        }
        axios.post(URLs.userUrl + "search", obj, {
            headers: {
                "Authorization": 'Bearer ' + this.props.token.access_token
            }
        }).then(response => {
            if (response.data.serviceStatus.statusCode === 200) {
                var count = 1;
                var newObj = [];
                response.data.travelers.forEach((item) => {
                    item.key = count;
                    count++;
                    newObj.push(item);
                })
                this.setState({ userSearchArray: newObj.slice(0, 10), searchArray: newObj, searchFlag: false, currentkey: "1", filteredArray: [], selectedFilter: null, selectedFilterValue: [], selectedFilterValueCompany: [], selectedFilterValueRole: [] });
                if (response.data.travelers.length === 0) {
                    notification.warning({
                        message: <span role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{this.props.app_labels.error_noProfileFound}</span>,
                        duration: 3,
                        style: {
                            backgroundColor: '#fffbe6',
                            border: "1px solid #ffe58f",
                        }
                    });
                }
            }
            else {
                this.setState({ searchFlag: false });
                notification.error({
                    message: <span role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{this.props.app_labels.error_searching}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }

        })
            .catch(err => {
                this.setState({ searchFlag: false });
                notification.error({
                    message: <span role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{this.props.app_labels.error_searching}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            })
    }

    /**
     * Sorts based on first Name
     */
    firstNameSort(a, b) {
        let firstName1 = a.firstName.toLowerCase();
        let firstName2 = b.firstName.toLowerCase();
        if (firstName1 < firstName2) {
            return -1;
        }
        if (firstName1 > firstName2) {
            return 1;
        }
        return 0;
    }

    /**
     * finds the current key
     */
    findCurrentKey = (object) => {
        if (this.state.currentkey === object.key)
            return object;

    }

    /**
     * BAsed on current key, it fetches the profile of the current user
     */
    currentUserProfile = (array) => {
        if (array.length === 0) {
            return
        }
        let object = array.find(this.findCurrentKey);
        if (!object) {
            this.setState({ currentkey: array[0].key })
            return (
                <EditProfile
                    app_labels={this.props.app_labels}
                    updateDeleteFlag={this.updateDeleteFlag}
                    FetchAllProfile={this.FetchAllProfile}
                    cadreRoles={this.props.cadreRoles}
                    data={array[0]}
                    profileChangeFlag={this.state.profileChangeFlag}
                    isDeleted={this.state.isDeleted}
                    updateEditedProfileData={
                        (data) => this.updateEditedProfileData(data)
                    }
                    theme={this.props.theme}
                    spinFunc={(value) => {
                        this.setState({ spinFlag: value })
                    }}
                    roles={this.props.userrole}
                    handleDelete ={this.handleDelete}
                />
            )
        }
        else {
            return (
                <EditProfile
                    app_labels={this.props.app_labels}
                    updateDeleteFlag={this.updateDeleteFlag}
                    FetchAllProfile={this.FetchAllProfile}
                    cadreRoles={this.props.cadreRoles}
                    data={object}
                    profileChangeFlag={this.state.profileChangeFlag}
                    isDeleted={this.state.isDeleted}
                    updateEditedProfileData={
                        (data) => this.updateEditedProfileData(data)
                    }
                    handleDelete ={this.handleDelete}
                    theme={this.props.theme}
                    spinFunc={(value) => {
                        this.setState({ spinFlag: value })
                    }}
                    roles={this.props.userrole} />
            )
        }
    }

    /**
     * Upon editing, updates the current user profile, 
     * else retreives the exisiting data when left unedited
     */
    currentUserProfilenew = (array) => {
        if (array.length === 0) {
            return
        }
        let object = array.find(this.findCurrentKey);
        if (!object) {
            this.setState({ currentkey: array[0].key })
            return (
                <EditProfile
                    app_labels={this.props.app_labels}
                    updateDeleteFlag={this.updateDeleteFlag}
                    FetchAllProfile={this.FetchAllProfile}
                    cadreRoles={this.props.cadreRoles}
                    data={array[0]}
                    profileChangeFlag={this.state.profileChangeFlag}
                    isDeleted={this.state.isDeleted}
                    onSearch={this.onSearch}
                    searchInput={this.state.searchInput}
                    updateEditedProfileData={
                        (data) => this.updateEditedProfileData(data)
                    }
                    handleDelete ={this.handleDelete}
                    theme={this.props.theme}
                    spinFunc={(value) => {
                        this.setState({ spinFlag: value })
                    }}
                    roles={this.props.userrole} />
            )
        }
        else {
            return (
                <EditProfile
                    app_labels={this.props.app_labels}
                    updateDeleteFlag={this.updateDeleteFlag}
                    FetchAllProfile={this.FetchAllProfile}
                    cadreRoles={this.props.cadreRoles}
                    data={object}
                    profileChangeFlag={this.state.profileChangeFlag}
                    isDeleted={this.state.isDeleted}
                    onSearch={this.onSearch}
                    searchInput={this.state.searchInput}
                    updateEditedProfileData={
                        (data) => this.updateEditedProfileData(data)
                    }
                    handleDelete ={this.handleDelete}
                    theme={this.props.theme}
                    spinFunc={(value) => {
                        this.setState({ spinFlag: value })
                    }}
                    roles={this.props.userrole} />
            )
        }
    }

    /**
     * Fetches the updated data
     * @param {*} newData 
     */
    updateEditedProfileData(newData) {
        let index = -1;
        let data = this.state.userfilteredArray;
        for (let i = 0; i < data.length; i++) {
            if (data.travelerId === newData.travelerId) {
                index = i;
                break;
            }
        }
        if (index !== -1) {
            data[index] = newData;
            this.setState({
                userfilteredArray: data
            })
        }
    }

    /**
     * Fetches the filtered data
     */
    getFilteredData(tempArray, value, roleLength, compLength) {

        return new Promise((resolve, reject) => {
            let newArray = [];
            try {
                /*  if (this.state.selectedFilter == "Company") {
                   newArray = tempArray.filter(e1 => value.some(item => e1.companyName == item))
               }
               else {
                   newArray = tempArray.filter(e1 => e1.roles.some(item => value.includes(item)))
               } */
                if (this.props.userrole.toLowerCase() === 'super admin' || this.props.userrole.toLowerCase() === 'customer support' || this.props.userrole.toLowerCase() === 'card company admin') {
                    if ((roleLength !== 0) && (compLength !== 0)) {
                        for (let i = 0; i < tempArray.length; i++) {
                            if (tempArray[i].roles.some(item => value.includes(item)) && value.includes(tempArray[i].companyName)) {
                                newArray.push(tempArray[i]);
                            }
                        }
                    } else {
                        if (compLength !== 0) {
                            for (let i = 0; i < tempArray.length; i++) {
                                if (value.includes(tempArray[i].companyName)) {
                                    newArray.push(tempArray[i]);
                                }
                            }
                        }
                        else {
                            if (roleLength !== 0) {
                                // console.log("Filter role:::", this.state.selectedFilterValueRole)
                                for (let i = 0; i < tempArray.length; i++) {
                                    if (tempArray[i].roles.some(item => value.includes(item))) {
                                        newArray.push(tempArray[i]);
                                    }
                                }
                            }
                        }
                    }
                }
                else {

                    if (this.state.selectedFilter === "Company") {
                        for (let i = 0; i < tempArray.length; i++) {
                            if (value.some(item => tempArray[i].companyName === item)) {
                                newArray.push(tempArray[i]);
                            }

                        }
                    }
                    else {
                        for (let i = 0; i < tempArray.length; i++) {
                            if (tempArray[i].roles.some(item => value.includes(item)) || value.includes(tempArray[i].companyName)) {
                                newArray.push(tempArray[i]);
                            }
                        }
                    }
                }

                return resolve(newArray)
            } catch (e) {
                return resolve(undefined)
            }

        })

    }

    /**
     * fetches more data according to the searched value if available
     */
    fetchMoreSearchData = () => {
        this.setState({ requestSent: true });
        setTimeout(() => {
            if (this.state.userSearchArray.length === this.state.searchArray.length) {
                this.setState({ requestSent: false });
            }
            var count = this.state.userSearchArray.length + 10;
            var moredata = this.state.searchArray.slice(this.state.userSearchArray.length, count)
            this.setState({
                userSearchArray: this.state.userSearchArray.concat(moredata), requestSent: false

            });
        }, 1500);
    }

    /**
     * fetches more data according to filteredDate
     */
    fetchMoreFilteredDate = () => {
        this.setState({ requestSent: true });
        setTimeout(() => {
            if (this.state.userfilteredArray.length === this.state.filteredArray.length) {
                this.setState({ requestSent: false });
            }
            var count = this.state.userfilteredArray.length + 10;
            var moredata = this.state.filteredArray.slice(this.state.userfilteredArray.length, count)
            this.setState({
                userfilteredArray: this.state.userfilteredArray.concat(moredata), requestSent: false

            });
        }, 1500);
    }


    /**
     * fetches more user profile data
     */
    fetchMoreData = () => {
        this.setState({ requestSent: true });
        setTimeout(() => {
            if (this.state.UserProfileData.length === this.state.profileList.length) {
                this.setState({ requestSent: false });
            }
            var count = this.state.UserProfileData.length + 10;
            var moredata = this.state.profileList.slice(this.state.UserProfileData.length, count)
            this.setState({
                UserProfileData: this.state.UserProfileData.concat(moredata), requestSent: false

            });
        }, 1500);

    }

    filterRoleComp = async (value, type) => {
        let selectedValue = this.state.selectedFilterValue;
        let compSelected = this.state.selectedFilterValueCompany;
        let roleSelected = this.state.selectedFilterValueRole;
        let newarray = [];
        let tempArray = [];


        if (type === "company") {
            if ((this.state.selectedFilterValueCompany && this.state.selectedFilterValueCompany.length !== 0) && (this.state.selectedFilterValue && this.state.selectedFilterValue.includes(value))) {
                selectedValue = this.state.selectedFilterValue.filter(i => i !== value)
                compSelected = this.state.selectedFilterValueCompany.filter(i => i !== value)
            } else {
                selectedValue.push(value)
                compSelected.push(value)
            }

            this.setState({ selectedFilterValue: selectedValue, selectedFilterValueCompany: compSelected })

        } else if (type === "role") {

            if ((this.state.selectedFilterValueRole && this.state.selectedFilterValueRole.length !== 0) && (this.state.selectedFilterValue && this.state.selectedFilterValue.includes(value))) {
                selectedValue = this.state.selectedFilterValue.filter(i => i !== value)
                roleSelected = this.state.selectedFilterValueRole.filter(i => i !== value)
                // console.log("removed:::",value, "from:::", selectedValue, roleSelected)
            } else {
                selectedValue.push(value)
                roleSelected.push(value)
            }

            this.setState({ selectedFilterValue: selectedValue, selectedFilterValueRole: roleSelected })
        }



        if (this.state.searchArray.length === 0) {
            tempArray = this.state.profileList
        }
        else {
            tempArray = this.state.searchArray
        }
        if (selectedValue.length !== 0) {
            this.setState(() => ({ spinFlag: true }))
            loaderFocus(".loader-color .ant-spin-text");
            newarray = await this.getFilteredData(tempArray, selectedValue, roleSelected.length, compSelected.length)
            if (newarray !== undefined) {
                setTimeout(() => {
                    this.setState({ spinFlag: false, filteredArray: newarray, userfilteredArray: newarray.slice(0, 10) });
                }, 0)
            }
            else {
                this.setState({ spinFlag: false })
            }
        }
        else {
            this.setState({ spinFlag: false, filteredArray: tempArray, userfilteredArray: tempArray.slice(0, 10) });
        }
    }

    render() {
        var filterRoles = [];
        // let cadreRoles = [{"roleId":1,"roleDesc":"Manager"},{"roleId":2,"roleDesc":"Traveler"},{"roleId":3,"roleDesc":"Company Admin"},{"roleId":5,"roleDesc":"CSR"},{"roleId":6,"roleDesc":"Card Company Admin"},{"roleId":7,"roleDesc":"Provisioner"},{"roleId":9,"roleDesc":"Approver"}];
        this.props.cadreRoles.forEach(item => {
            filterRoles.push({ text: item.roleDesc, value: item.roleDesc })
        })
        // cadreRoles.forEach(item => {
        //     filterRoles.push({ text: item.roleDesc, value: item.roleDesc })
        // })

        filterRoles.sort((a, b) => {
            let value = a.value.toLowerCase(),
                value2 = b.value.toLowerCase();
            return value < value2 ? -1 : 1
        });

        let companyList = this.props.activeCompanyName;
        companyList.sort((a, b) => {
            let companyname = a.companyName.toLowerCase(),
                companyname2 = b.companyName.toLowerCase();
            return companyname < companyname2 ? -1 : 1
        });

        const columns = [
            {
                /* title: <span className="sort-filter">Sort By Name</span>, */
                dataIndex: "userRoles",
                width: 40,
                render: (text, object) => (
                    <div >
                        <Menu
                            theme={"light"}
                            /* onClick={this.handleClick} */
                            defaultSelectedKeys={[1]}
                            defaultOpenKeys={["1"]}
                            selectedKeys={[this.state.currentkey]}
                            mode="inline"
                            /*  style={{ backgroundColor: "white", padding: "0 3px" }}> */
                            style={{ backgroundColor: "white" }}>

                            <Menu.Item key={object.key} aria-label={`${this.props.app_labels.profileDetails}  ${object.firstName.length > 20 ? object.firstName.slice(0, 19) + '...' : object.firstName.concat(object.lastName).length > 20 ? object.firstName + '...' : object.firstName + ' ' + object.lastName}`} tabindex={0} style={{ height: "20%" }} onClick={() =>
                                this.handleClick(object.key)
                            }
                                onKeyUp={e => {
                                    if (e.keyCode === 13) {
                                        this.handleClick(object.key)
                                    }
                                }}>
                                <div className="row menuitemFocus">
                                    <div className="col-sm-12 col-sm-offset-0" style={{ marginLeft: "3%" }}>
                                        <div className="row row_center" >
                                            {/* <div className="col-xs-4 col-sm-4 col_center">
                                                <Avatar className="avatar-class" size="large"><b>{object.initials}</b>
                                                </Avatar>
                                            </div> */}
                                            { /*Removed capitalize EW-817*/ /*<span className='dashboardName' title={this.capitalize(object.firstName) + ' ' + this.capitalize(object.lastName)}>{object.firstName.length > 20 ? object.firstName.slice(0, 19) + '...' : object.firstName.concat(object.lastName).length > 20 ? this.capitalize(object.firstName) + '...' : this.capitalize(object.firstName) + ' ' + this.capitalize(object.lastName)}</span>*/}
                                            <div className="col-xs-9 col-sm-9" style={{ wordWrap: 'break-word' }}>
                                                <span className='dashboardName' id={`dashboardName-${object.key}`} style={{ fontSize: 17 }} title={object.firstName + ' ' + object.lastName}>{object.firstName.length > 20 ? object.firstName.slice(0, 19) + '...' : object.firstName.concat(object.lastName).length > 20 ? object.firstName + '...' : object.firstName + ' ' + object.lastName}</span>
                                                <br /><Row className="margin-top-sider"><Col span={12}>{this.props.app_labels.expenseWizardId} </Col>  <Col className="dashboardValue" span={12}>{object.cadreId.trim()}</Col></Row>
                                                <Row className="margin-top-sider"><Col span={12}>{this.props.app_labels.email}</Col>   <Col className="dashboardValue" span={12}>{object.emailId.trim()}</Col></Row>
                                                <Row className="margin-top-sider"><Col span={12}>{this.props.app_labels.mobile} </Col>  <Col className="dashboardValue" span={12}>{object.phoneNo.trim()}</Col></Row>
                                                <Row className="margin-top-sider"><Col span={12}>{this.props.app_labels.assignRole}</Col>  <Col className="dashboardValue" span={12}>{object.roles.length === 0 ? null : object.roles[0]}</Col></Row>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Menu.Item>
                        </Menu>
                    </div>
                ),
            }]
        return (
            <div className="container-fluid ViewEditProfileClass" style={{ background: "#F2F2F5" }}>
                <Layout>
                    <Layout style={{ boxShadow: " 0px 0px 5px 5px #f2f2f2" }}>
                        {/* <Layout style={{ zIndex: 100, position: "absolute", top: 0, marginLeft: -16, width: "100%", backgroundColor: "transparent", pointerEvents: "none" }}> */}
                        {/* <MediaQuery maxDeviceWidth={799}> */}
                        {/* <Sider
                                    width={"76%"}
                                    style={{ pointerEvents: "all", borderTopLeftRadius: 8 }}
                                    breakpoint="lg"
                                    collapsedWidth="0"
                                    theme="light" >
                                    <div className="row">
                                        <div className="col-sm-11 col-sm-offset-1 profilelist">
                                            {this.props.app_labels.profileList} ({this.state.UserProfileData.length})
                                        </div>
                                        <br /> 
                                        <div className="col-sm-11 col-sm-offset-0 searchinput profile" >
                                            <Search 
                                                className={`${this.props.theme == 'elan' ? "search-box" : ""}`}
                                                aria-label="Search profiles"
                                                placeholder="Search profiles"
                                                onChange={(e) => {
                                                    if (e.target.value === "" || e.target.value === null) {
                                                        this.setState({ searchInput: e.target.value, userSearchArray: [], searchArray: [], filteredArray: this.state.profileList, userfilteredArray: this.state.UserProfileData, selectedFilter: null });
                                                    }
                                                    else {
                                                        this.setState({ searchInput: e.target.value.trim() })
                                                    }
                                                }}
                                                onSearch={this.onSearch}

                                            />
                                        </div>
                                    </div><br />
                                    <div className="row editprofileDropdown">
                                        <div className="col-sm-6 col-sm-offset-1">
                                            <span className="sort-filter" style={{ marginRight: "2%" }}>{this.props.app_labels.filterBy}</span> {this.state.selectedFilter != null ? <span style={{ marginRight: "4%", fontWeight: "bold" }}>{this.state.selectedFilter}</span> : null}&nbsp;&nbsp;
                                        <Select style={{ width: 0 }}
                                                value={this.state.selectedFilter}
                                                dropdownRender={menu => (
                                                    <div>
                                                        {menu}
                                                        <Divider style={{ margin: '4px 0' }} />

                                                    </div>
                                                )}
                                                onChange={(value) => {
                                                    let tempArray
                                                    if (this.state.searchArray.length === 0) {
                                                        tempArray = this.state.profileList;
                                                    }
                                                    else {
                                                        tempArray = this.state.searchArray;
                                                    }
                                                    if (value === "Roles") {
                                                        this.setState({ userfilteredArray: tempArray.slice(0, 10), filteredArray: tempArray, newFilterDropDown1: filterRoles, selectedFilter: value })
                                                    }
                                                    else if (value === "Company") {
                                                        this.setState({ userfilteredArray: tempArray.slice(0, 10), filteredArray: tempArray, newFilterDropDown: this.props.activeCompanyName, selectedFilter: value })
                                                    }
                                                    else {
                                                        this.setState({ userfilteredArray: [], filteredArray: [], newFilterDropDown: [], newFilterDropDown1: [], selectedFilter: null })
                                                    }
                                                }
                                                }>
                                                {this.props.userrole.toLowerCase() === 'super admin' ? <Option value="Company">{this.props.app_labels.company}</Option> : null}
                                                <Option value="Roles">{this.props.app_labels.roles}</Option>
                                                <Option value="Reset">{this.props.app_labels.reset1}</Option>
                                            </Select>
                                        </div>
                                        <div className="col-sm-5 col-sm-offset-0">
                                            <span style={{ marginRight: "4%" }}
                                                className="sort-filter"
                                                onClick={() => {
                                                    if (this.state.sortFlag === true) {
                                                        this.setState({ spinFlag: true, sortFlag: false })
                                                        // {
                                                        //     this.state.sortColor != "#0A41C5" ?
                                                        //         this.setState({ sortColor: "#0A41C5" }) : this.setState({ sortColor: "#636466" })
                                                        // }
                                                        if (this.state.searchArray.length === 0 && this.state.selectedFilter == null) {
                                                            let newArray = this.state.profileList.sort(this.firstNameSort).reverse()
                                                            this.setState({ profileList: newArray, UserProfileData: newArray.slice(0, 10) })
                                                        }
                                                        else if (this.state.searchArray.length !== 0 && this.state.selectedFilter == null) {
                                                            let newArray1 = this.state.searchArray.sort(this.firstNameSort).reverse()
                                                            this.setState({ searchArray: newArray1, userSearchArray: newArray1.slice(0, 10) })
                                                        }
                                                        else {
                                                            let newArray2 = this.state.filteredArray.sort(this.firstNameSort).reverse()
                                                            this.setState({ filteredArray: newArray2, userfilteredArray: newArray2.slice(0, 10) })
                                                        }
                                                    } else {
                                                        this.setState({ spinFlag: true, sortFlag: true })
                                                        // {
                                                        //     this.state.sortColor != "#0A41C5" ?
                                                        //         this.setState({ sortColor: "#0A41C5" }) : this.setState({ sortColor: "#636466" })
                                                        // }
                                                        if (this.state.searchArray.length === 0 && this.state.selectedFilter == null) {
                                                            let newArray = this.state.profileList.sort(this.firstNameSort)
                                                            this.setState({ profileList: newArray, UserProfileData: newArray.slice(0, 10) })
                                                        }
                                                        else if (this.state.searchArray.length !== 0 && this.state.selectedFilter == null) {
                                                            let newArray1 = this.state.searchArray.sort(this.firstNameSort)
                                                            this.setState({ searchArray: newArray1, userSearchArray: newArray1.slice(0, 10) })
                                                        }
                                                        else {
                                                            let newArray2 = this.state.filteredArray.sort(this.firstNameSort)
                                                            this.setState({ filteredArray: newArray2, userfilteredArray: newArray2.slice(0, 10) })
                                                        }
                                                    }
                                                    setTimeout(() => {
                                                        this.setState({ spinFlag: false });
                                                    }, 0)
                                                }
                                                }>{this.props.app_labels.sortByName} &nbsp;<i className="icon-drop-down" style={{ color: this.state.sortColor, fontWeight: "bold" }}></i></span>
                                        </div>
                                    </div>
                                    <div className="row editprofileDropdown">
                                        <div className="col-sm-10 col-sm-offset-1">
                                            {this.state.selectedFilter != null ? <Select mode="multiple"
                                                showArrow={true}
                                                allowClear={false}
                                                className="select1"
                                                placeholder="Select filters here"
                                                style={{ width: '100%', marginLeft: '2%' }}
                                                dropdownRender={menu => (
                                                    <div>
                                                        {menu}
                                                        <Divider style={{ margin: '4px 0' }} />

                                                    </div>
                                                )}
                                                onChange={async (value) => {
                                                    let newarray = [];
                                                    let tempArray = [];
                                                    if (this.state.searchArray.length === 0) {
                                                        tempArray = this.state.profileList
                                                    }
                                                    else {
                                                        tempArray = this.state.searchArray
                                                    }
                                                    if (value.length !== 0) {
                                                        this.setState(() => ({ spinFlag: true }))
                                                        newarray = await this.getFilteredData(tempArray, value)
                                                        if (newarray !== undefined) {
                                                            setTimeout(() => {
                                                                this.setState({ spinFlag: false, filteredArray: newarray, userfilteredArray: newarray.slice(0, 10) });
                                                            }, 0)
                                                        }
                                                        else {
                                                            this.setState({ spinFlag: false })
                                                        }
                                                    }
                                                    else {
                                                        this.setState({ spinFlag: false, filteredArray: tempArray, userfilteredArray: tempArray.slice(0, 10) });
                                                    }



                                                }
                                                }  >
                                                {this.state.selectedFilter === "Company" ?
                                                    this.state.newFilterDropDown.map(item =>
                                                        <Option value={item.companyName}>{item.companyName}</Option>
                                                    )
                                                    : this.state.newFilterDropDown1.map(item =>
                                                        <Option value={item.value}>{item.text}</Option>
                                                    )}
                                            </Select> : null}
                                        </div>
                                    </div>
                                    <div className="row" style={{ marginTop: "2%" }}>
                                        <div id="viewtable" className="col-sm-12 col-sm-offset-0">
                                            <div id="scrolablediv" style={{ height: "100vh", overflow: "auto" }}>
                                                <InfiniteScroll
                                                    dataLength={this.state.selectedFilter != null ? this.state.userfilteredArray.length :
                                                        this.state.searchArray.length === 0 ? this.state.UserProfileData.length : this.state.userSearchArray.length}
                                                    next={this.state.selectedFilter != null ? this.fetchMoreFilteredDate :
                                                        this.state.searchArray.length === 0 ? this.fetchMoreData : this.fetchMoreSearchData}
                                                    hasMore={true}
                                                    scrollableTarget="scrolablediv"
                                                    loader={this.state.requestSent === true ? <div style={{ textAlign: "center" }}><h5>Loading...</h5></div> : null}
                                                >
                                                    <Table
                                                        size="large"
                                                        className='dashboardTable'
                                                        columns={columns}
                                                        dataSource={this.state.selectedFilter != null ? this.state.userfilteredArray :
                                                            this.state.searchArray.length === 0 ? this.state.UserProfileData : this.state.userSearchArray}
                                                        pagination={false}
                                                    />
                                                </InfiniteScroll>
                                            </div>
                                        </div>
                                    </div>
                                </Sider> */}
                        {/* </MediaQuery> */}
                        {/* </Layout> */}
                        {/* <MediaQuery minDeviceWidth={800}> */}
                        <Sider
                            className="viewAndEdit"
                            breakpoint="lg"
                            width={this.props.userrole.toLowerCase() === 'super admin' || this.props.userrole.toLowerCase() === 'customer support' ? '400px' : "370px"} /*23%*/
                            collapsedWidth="0"
                            theme="light"
                            onBreakpoint={(broken) => {/*  console.log(broken);  */ }}
                            onCollapse={(collapsed, type) => { /* console.log(collapsed, type);  */ }} >
                            <div style={{ background: "#FFFFFF", boxShadow: "0px 3px 6px #00000029", borderTopLeftRadius: 8 }}>
                                <div className="row">
                                    <div className="col-sm-11 col-sm-offset-1 profilelist" role="heading" aria-level="2">
                                        {this.props.app_labels.profileList} ({this.state.selectedFilter != null || (this.state.selectedFilterValue && this.state.selectedFilterValue.length !== 0) ? this.state.filteredArray.length : (this.state.searchArray.length === 0 ? this.state.profileList && this.state.profileList.length : this.state.searchArray.length)})
                                    </div>
                                    <br />
                                    <div className="col-sm-11 col-sm-offset-0 searchinput profile">
                                        <Search className="search-input"
                                            className={`${this.props.theme == 'elan' ? "search-box" : ""}`}
                                            aria-label="Search profile"
                                            placeholder={this.props.app_labels.searchProfile}
                                            onChange={(e) => {
                                                if (e.target.value === "" || e.target.value === null) {
                                                    this.setState({ searchInput: e.target.value, userSearchArray: [], searchArray: [], filteredArray: this.state.profileList, userfilteredArray: this.state.UserProfileData, selectedFilter: null, selectedFilterValue: [], selectedFilterValueCompany: [], selectedFilterValueRole: [] });
                                                }

                                                else {
                                                    this.setState({ searchInput: e.target.value.trim() })
                                                }
                                            }}
                                            onSearch={this.onSearch}

                                        />
                                    </div>
                                </div><br />
                                <div className="row editprofileDropdown" style={{ marginBottom: "3%" }}>
                                    {this.props.userrole.toLowerCase() === 'super admin' || this.props.userrole.toLowerCase() === 'customer support' ?
                                        <div className="col-sm-5 col-sm-offset-1">
                                            <FilterByCompanyBtn
                                                app_labels={this.props.app_labels}
                                                activeCompanyName={companyList}
                                                userRole={this.props.userrole}
                                                onSelectValue={(value) => this.filterRoleComp(value, "company")}
                                                selectedFilterValue={this.state.selectedFilterValueCompany}
                                            />
                                        </div> : null
                                    }
                                    {this.props.userrole.toLowerCase() === 'card company admin' || this.props.userrole.toLowerCase() === 'super admin' || this.props.userrole.toLowerCase() === 'customer support' ?

                                        <div className={this.props.userrole.toLowerCase() === 'super admin' || this.props.userrole.toLowerCase() === 'customer support' ? "col-sm-4 col-sm-offset-1" : "col-sm-6 col-sm-offset-1"}
                                            style={{ marginLeft: this.props.userrole.toLowerCase() === 'super admin' || this.props.userrole.toLowerCase() === 'customer support' ? '-55px' : null }}
                                        >
                                            <FilterByRoleBtn
                                                app_labels={this.props.app_labels}
                                                filterRoles={filterRoles}
                                                userRole={this.props.userrole}
                                                onSelectValue={(value) => this.filterRoleComp(value, "role")}
                                                selectedFilterValue={this.state.selectedFilterValueRole}
                                            />
                                        </div>
                                        :
                                        <div className={this.props.userrole.toLowerCase() === 'super admin' || this.props.userrole.toLowerCase() === 'customer support' ? "col-sm-4 col-sm-offset-1" : "col-sm-6 col-sm-offset-1"}>
                                            <span className="sort-filter" style={{ marginRight: "2%" }}>{this.props.app_labels.filterBy}</span> {this.state.selectedFilter != null ? <span style={{ marginRight: "4%", fontWeight: "bold" }}>{this.state.selectedFilter}</span> : null}&nbsp;&nbsp;
                                            <Select style={{ width: 0 }}
                                                value={this.state.selectedFilter}
                                                dropdownRender={menu => (
                                                    <div>
                                                        {menu}
                                                        <Divider style={{ margin: '4px 0' }} />

                                                    </div>
                                                )}
                                                onChange={(value) => {
                                                    let tempArray
                                                    if (this.state.searchArray.length === 0) {
                                                        tempArray = this.state.profileList;
                                                    }
                                                    else {
                                                        tempArray = this.state.searchArray;
                                                    }
                                                    if (value === "Roles") {
                                                        this.setState({ userfilteredArray: tempArray.slice(0, 10), filteredArray: tempArray, newFilterDropDown1: filterRoles, selectedFilter: value })
                                                    }
                                                    else if (value === "Company") {
                                                        this.setState({ userfilteredArray: tempArray.slice(0, 10), filteredArray: tempArray, newFilterDropDown: this.props.activeCompanyName, selectedFilter: value })
                                                    }
                                                    else {
                                                        this.setState({ userfilteredArray: [], filteredArray: [], newFilterDropDown: [], newFilterDropDown1: [], selectedFilter: null })
                                                    }
                                                }
                                                }>
                                                {this.props.userrole.toLowerCase() === 'super admin' || this.props.userrole.toLowerCase() === 'customer support' ? <Option value="Company">{this.props.app_labels.company}</Option> : null}
                                                <Option value="Roles">{this.props.app_labels.roles}</Option>
                                                <Option value="Reset">{this.props.app_labels.reset1}</Option>

                                            </Select>

                                        </div>

                                    }
                                    <div className={this.props.userrole.toLowerCase() === 'super admin' || this.props.userrole.toLowerCase() === 'customer support' ? "col-sm-4 col-sm-offset-0" : "col-sm-5 col-sm-offset-0"} style={{ marginLeft: "-5%" }}>
                                        <div className="visually-hidden" aria-live="assertive">{this.state.clicked === true ? `Profile list sorted by name in ${this.state.sortFlag ? ' ascending' : 'descending'} order` : null}</div>

                                        <button style={{
                                            width: this.props.userrole.toLowerCase() === 'super admin' || this.props.userrole.toLowerCase() === 'customer support' ? "90px" : "100%", color: "#333333", border: "1px solid #666666", borderRadius: 4, padding: '0px 0px 0px 0px', fontSize: this.props.userrole.toLowerCase() === 'super admin' || this.props.userrole.toLowerCase() === 'customer support' ? 11 : null,
                                            height: this.props.userrole.toLowerCase() === 'super admin' || this.props.userrole.toLowerCase() === 'customer support' ? 30 : null, marginLeft: this.props.userrole.toLowerCase() === 'super admin' || this.props.userrole.toLowerCase() === 'customer support' ? 5 : null
                                        }}
                                            className="sort-filter"
                                            onClick={() => {
                                                this.setState({ clicked: true })
                                                if (this.state.sortFlag === true) {
                                                    this.setState({ spinFlag: true, sortFlag: false })
                                                    loaderFocus(".loader-color .ant-spin-text");
                                                    // {
                                                    //     this.state.sortColor != "#0A41C5" ?
                                                    //         this.setState({ sortColor: "#0A41C5" }) : this.setState({ sortColor: "#636466" })
                                                    // }
                                                    if (this.state.searchArray.length === 0 && this.state.selectedFilter == null && !(this.state.selectedFilterValue && this.state.selectedFilterValue.length !== 0)) {
                                                        let newArray = this.state.profileList.sort(this.firstNameSort).reverse()
                                                        this.setState({ profileList: newArray, UserProfileData: newArray.slice(0, 10) })
                                                    }
                                                    else if (this.state.searchArray.length !== 0 && this.state.selectedFilter == null && !(this.state.selectedFilterValue && this.state.selectedFilterValue.length !== 0)) {
                                                        let newArray1 = this.state.searchArray.sort(this.firstNameSort).reverse()
                                                        this.setState({ searchArray: newArray1, userSearchArray: newArray1.slice(0, 10) })
                                                    }
                                                    else {
                                                        let newArray2 = this.state.filteredArray.sort(this.firstNameSort).reverse()
                                                        this.setState({ filteredArray: newArray2, userfilteredArray: newArray2.slice(0, 10) })
                                                    }
                                                } else {
                                                    this.setState({ spinFlag: true, sortFlag: true })
                                                    loaderFocus(".loader-color .ant-spin-text");
                                                    // {
                                                    //     this.state.sortColor != "#0A41C5" ?
                                                    //         this.setState({ sortColor: "#0A41C5" }) : this.setState({ sortColor: "#636466" })
                                                    // }
                                                    if (this.state.searchArray.length === 0 && this.state.selectedFilter == null && !(this.state.selectedFilterValue && this.state.selectedFilterValue.length !== 0)) {
                                                        let newArray = this.state.profileList.sort(this.firstNameSort)
                                                        this.setState({ profileList: newArray, UserProfileData: newArray.slice(0, 10) })
                                                    }
                                                    else if (this.state.searchArray.length !== 0 && this.state.selectedFilter == null && !(this.state.selectedFilterValue && this.state.selectedFilterValue.length !== 0)) {
                                                        let newArray1 = this.state.searchArray.sort(this.firstNameSort)
                                                        this.setState({ searchArray: newArray1, userSearchArray: newArray1.slice(0, 10) })
                                                    }
                                                    else {
                                                        let newArray2 = this.state.filteredArray.sort(this.firstNameSort)
                                                        this.setState({ filteredArray: newArray2, userfilteredArray: newArray2.slice(0, 10) })
                                                    }
                                                }
                                                setTimeout(() => {
                                                    this.setState({ spinFlag: false, clicked: false });
                                                }, 500)
                                            }}

                                            onKeyDown={(e) => {
                                                if (e.keyCode === 13) {
                                                    this.setState({ clicked: true })
                                                    if (this.state.sortFlag === true) {
                                                        this.setState({ spinFlag: true, sortFlag: false })
                                                        loaderFocus(".loader-color .ant-spin-text");
                                                        // {
                                                        //     this.state.sortColor != "#0A41C5" ?
                                                        //         this.setState({ sortColor: "#0A41C5" }) : this.setState({ sortColor: "#636466" })
                                                        // }
                                                        if (this.state.searchArray.length === 0 && this.state.selectedFilter == null && !(this.state.selectedFilterValue && this.state.selectedFilterValue.length !== 0)) {
                                                            let newArray = this.state.profileList.sort(this.firstNameSort).reverse()
                                                            this.setState({ profileList: newArray, UserProfileData: newArray.slice(0, 10) })
                                                        }
                                                        else if (this.state.searchArray.length !== 0 && this.state.selectedFilter == null && !(this.state.selectedFilterValue && this.state.selectedFilterValue.length !== 0)) {
                                                            let newArray1 = this.state.searchArray.sort(this.firstNameSort).reverse()
                                                            this.setState({ searchArray: newArray1, userSearchArray: newArray1.slice(0, 10) })
                                                        }
                                                        else {
                                                            let newArray2 = this.state.filteredArray.sort(this.firstNameSort).reverse()
                                                            this.setState({ filteredArray: newArray2, userfilteredArray: newArray2.slice(0, 10) })
                                                        }
                                                    } else {
                                                        this.setState({ spinFlag: true, sortFlag: true })
                                                        loaderFocus(".loader-color .ant-spin-text");
                                                        // {
                                                        //     this.state.sortColor != "#0A41C5" ?
                                                        //         this.setState({ sortColor: "#0A41C5" }) : this.setState({ sortColor: "#636466" })
                                                        // }
                                                        if (this.state.searchArray.length === 0 && this.state.selectedFilter == null && !(this.state.selectedFilterValue && this.state.selectedFilterValue.length !== 0)) {
                                                            let newArray = this.state.profileList.sort(this.firstNameSort)
                                                            this.setState({ profileList: newArray, UserProfileData: newArray.slice(0, 10) })
                                                        }
                                                        else if (this.state.searchArray.length !== 0 && this.state.selectedFilter == null && !(this.state.selectedFilterValue && this.state.selectedFilterValue.length !== 0)) {
                                                            let newArray1 = this.state.searchArray.sort(this.firstNameSort)
                                                            this.setState({ searchArray: newArray1, userSearchArray: newArray1.slice(0, 10) })
                                                        }
                                                        else {
                                                            let newArray2 = this.state.filteredArray.sort(this.firstNameSort)
                                                            this.setState({ filteredArray: newArray2, userfilteredArray: newArray2.slice(0, 10) })
                                                        }
                                                    }
                                                    setTimeout(() => {
                                                        this.setState({ spinFlag: false, clicked: false });
                                                    }, 500)
                                                }
                                            }}

                                        >
                                            {this.props.app_labels.sortByName}
                                        </button>
                                    </div>
                                    <br />


                                    {this.state.selectedFilterValue && this.state.selectedFilterValue.length !== 0 ?
                                        <div className="col-sm-11 col-sm-offset-0" style={{ marginTop: "4%", marginLeft: this.props.userrole.toLowerCase() === 'super admin' || this.props.userrole.toLowerCase() === 'customer support' ? "2%" : "5%" }}>
                                            {/* <span> {this.state.selectedFilterValue} </span>  */}
                                            <Row gutter={[12, 3]}>
                                                {
                                                    this.state.selectedFilterValue.map(item => {
                                                        return <Col span={10} offset={1} style={{ width: 'auto', marginLeft: '1px' }}>
                                                            <div style={this.props.userrole.toLowerCase() === 'super admin' || this.props.userrole.toLowerCase() === 'customer support' ? {
                                                                height: 30, borderRadius: 4,
                                                                border: "1px solid #0A41C5", padding: '5px 5px 0px 15px',
                                                                backgroundColor: "#E0E9FF", fontSize: 12
                                                            } :
                                                                {
                                                                    height: 35, borderRadius: 4,
                                                                    marginRight: "5%", marginLeft: "-10%",
                                                                    border: "1px solid #0A41C5", padding: '5px 5px 0px 25px',
                                                                    backgroundColor: "#E0E9FF", fontSize: 14
                                                                }}>
                                                                {item}
                                                                &nbsp;&nbsp;{this.props.userrole.toLowerCase() === 'card company admin' ? <span>&nbsp;</span> : null}
                                                                <Icon type="close" className="remove-filter-icon" aria-label={`Remove ${item} filter`}
                                                                    role="button" tabIndex={0}
                                                                    // style={{marginLeft: "10%"}} 
                                                                    onClick={() => {
                                                                        if (this.state.selectedFilterValueCompany && this.state.selectedFilterValueCompany.includes(item))
                                                                            this.filterRoleComp(item, "company")
                                                                        else
                                                                            this.filterRoleComp(item, "role");
                                                                    }}
                                                                    onKeyUp={(e) => {
                                                                        if (e.keyCode === 13) {
                                                                            if (this.state.selectedFilterValueCompany && this.state.selectedFilterValueCompany.includes(item))
                                                                                this.filterRoleComp(item, "company")
                                                                            else
                                                                                this.filterRoleComp(item, "role");
                                                                        }
                                                                    }} />
                                                            </div>
                                                        </Col>
                                                    })
                                                }
                                            </Row>
                                        </div>
                                        :

                                        <br />
                                    }
                                    {/* <div className="col-sm-8 col-sm-offset-0" style={{marginLeft:"-50%", marginTop:"4%"}}>
                                                <div style={{display: this.state.approvCheck ? null : "none", width: "59%", height: 35, borderRadius: 4, 
                                                 marginLeft: this.state.provCheck ? "10%" : "-54%", border: "1px solid #0A41C5", padding: '5px 5px 0px 25px',
                                                  backgroundColor: "#E0E9FF", fontSize: 14 }}>
                                                    Approver
                                                    &nbsp;&nbsp;&nbsp;
                                                    <Icon type="close" className="remove-filter-icon" aria-label="Remove approver filter" role="button" tabIndex={0} style={{marginLeft: "10%"}} 
                                                    onClick={() => this.setState({approvCheck : false}, () => this.filterRole())}
                                                    onKeyUp={(e) => { e.keyCode === 13 && this.setState({approvCheck : false}, () => this.filterRole())}} />
                                                </div>
                                            </div> */}

                                </div>
                                <div className="row editprofileDropdown">
                                    <div className="col-sm-10 col-sm-offset-1">
                                        {this.state.selectedFilter != null ? <Select mode="multiple"
                                            showArrow={true}
                                            allowClear={false}
                                            className="select1"
                                            placeholder="Select filters here"
                                            style={{ width: '100%', marginLeft: '2%' }}
                                            dropdownRender={menu => (
                                                <div>
                                                    {menu}
                                                    <Divider style={{ margin: '4px 0' }} />

                                                </div>
                                            )}
                                            onChange={async (value) => {

                                                let newarray = [];
                                                let tempArray = [];
                                                if (this.state.searchArray.length === 0) {
                                                    tempArray = this.state.profileList
                                                }
                                                else {
                                                    tempArray = this.state.searchArray
                                                }
                                                if (value.length !== 0) {
                                                    this.setState(() => ({ spinFlag: true }))
                                                    loaderFocus(".loader-color .ant-spin-text");
                                                    newarray = await this.getFilteredData(tempArray, value, null, null)
                                                    if (newarray !== undefined) {
                                                        setTimeout(() => {
                                                            this.setState({ spinFlag: false, filteredArray: newarray, userfilteredArray: newarray.slice(0, 10) });
                                                        }, 0)
                                                    }
                                                    else {
                                                        this.setState({ spinFlag: false })
                                                    }
                                                }
                                                else {
                                                    this.setState({ spinFlag: false, filteredArray: tempArray, userfilteredArray: tempArray.slice(0, 10) });
                                                }

                                            }
                                            }  >
                                            {this.state.selectedFilter === "Company" ?
                                                this.state.newFilterDropDown.map(item =>
                                                    <Option value={item.companyName}>{item.companyName}</Option>
                                                )
                                                : this.state.newFilterDropDown1.map(item =>
                                                    <Option value={item.value}>{item.text}</Option>
                                                )}
                                        </Select> : null}
                                    </div>
                                </div>
                            </div>
                            <div className="row" style={{ marginTop: "2%" }}>
                                <div id="viewtable" className="col-sm-12 col-sm-offset-0">
                                    <div id="scrolablediv" style={{ height: "100vh", overflow: "auto" }}>
                                        <InfiniteScroll
                                            dataLength={this.state.selectedFilter != null || (this.state.selectedFilterValue && this.state.selectedFilterValue.length !== 0) ? this.state.userfilteredArray.length :
                                                this.state.searchArray.length === 0 ? this.state.UserProfileData.length : this.state.userSearchArray.length}
                                            next={this.state.selectedFilter != null || (this.state.selectedFilterValue && this.state.selectedFilterValue.length !== 0) ? this.fetchMoreFilteredDate :
                                                this.state.searchArray.length === 0 ? this.fetchMoreData : this.fetchMoreSearchData}
                                            hasMore={true}
                                            scrollableTarget="scrolablediv"
                                            loader={this.state.requestSent === true ? <div style={{ textAlign: "center" }}><h5>Loading...</h5></div> : null}
                                        >
                                            <Table
                                                size="large"
                                                className='dashboardTable'
                                                columns={columns}
                                                /*  scroll={{ y: '100vh' }} */
                                                dataSource={this.state.selectedFilter != null || (this.state.selectedFilterValue && this.state.selectedFilterValue.length !== 0) ? this.state.userfilteredArray :
                                                    this.state.searchArray.length === 0 ? this.state.UserProfileData : this.state.userSearchArray}
                                                pagination={false}
                                                tabIndex={-1}
                                            />
                                        </InfiniteScroll>
                                    </div>
                                </div>
                            </div>
                        </Sider>
                        {/* </MediaQuery> */}
                        {this.state.spinFlag || this.state.searchFlag ? <div className="dark-spinner loader-color"><Spin tip="Loading..." /></div> : null}
                        <Content role="presentation" className="content-class" style={{ background: "white", borderTopRightRadius: 8 }}>
                            <div className="row">
                                {(this.props.userrole === "Card Company Admin" || this.props.userrole === "Super Admin" || this.props.userrole === 'Customer Support') ? null :
                                    <div className="col-sm-11 col-sm-offset-1" style={{ marginLeft: '3%', zIndex: 1 }}>
                                        <AdminCarousel index={2} initialSlide={1} selectedItems={this.props.selectedItems} userrole={this.props.userrole} />
                                    </div>
                                }
                            </div>
                            <div className="row">
                                {this.state.selectedFilter != null || (this.state.selectedFilterValue && this.state.selectedFilterValue.length !== 0) ?
                                    /* this.state.userfilteredArray.map((object) => {
                                        return <div>{parseInt(this.state.currentkey) === object.key ? <EditProfile updateDeleteFlag={this.updateDeleteFlag} FetchAllProfile={this.FetchAllProfile} cadreRoles={this.props.cadreRoles} data={object} /> : null} </div>
                                    })
                                    : this.state.searchArray == 0 ? this.state.UserProfileData.map((object) => {
                                        return <div>{parseInt(this.state.currentkey) === object.key ? <EditProfile updateDeleteFlag={this.updateDeleteFlag} FetchAllProfile={this.FetchAllProfile} cadreRoles={this.props.cadreRoles} data={object} onSearch={this.onSearch} searchInput={this.state.searchInput} /> : null} </div>
                                    }) : this.state.userSearchArray.map((object) => {
                                        return <div>{parseInt(this.state.currentkey) === object.key ? <EditProfile updateDeleteFlag={this.updateDeleteFlag} FetchAllProfile={this.FetchAllProfile} cadreRoles={this.props.cadreRoles} data={object} onSearch={this.onSearch} searchInput={this.state.searchInput} /> : null} </div>
                                    }) */
                                    
                                    this.currentUserProfile(this.state.userfilteredArray)
                                    : this.state.searchArray.length === 0 ? this.currentUserProfilenew(this.state.UserProfileData)
                                        : this.currentUserProfilenew(this.state.userSearchArray)
                                }
                            </div>
                        </Content>
                    </Layout>
                </Layout>
            </div >

        )
    }
}
function mapStateToProps(state, props) {
    return {
        token: state.authenticationReducer.token,
        theme: state.authenticationReducer.theme
    };
}

function mapDispatchToProps(dispatch) {
    return {
        resetUserDelete: () => {
            dispatch(resetUserDelete());
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ViewandEditProfile);
