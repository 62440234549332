import React from 'react';
import { connect } from 'react-redux';
import $ from 'jquery';
import { notification, Input, Icon, Checkbox, Switch, Drawer } from 'antd';
import MediaQuery from 'react-responsive';
import '../../../styling/companysetup.css';
import axios from 'axios';
const { URLs, errorMessages, serviceInterfaces, serviceInterfacesName, serviceInterfaceId } = require('../../../data/constants.js');

const EditableCell = ({ editable, value, newflag, column, onChange }) => (
    column !== "serviceInterface" ?
        <div>
            {newflag ? <input type="text" className="input-text" placeholder={column === "companyName" ? "Company name here" : column === "companyAcctId" ? "12-digit account id here" : column === "emailId" ? "Email id here" : column === "companyShortName" ? "Short name here" : column === "chromeRiverCustCd" ? "Customer code here" : "Phone number here"} onChange={e => onChange(e, e.target.value)} /> : editable
                ? <Input style={{ margin: '-5px 0', marginTop: "2px", width: '90%', border: "1px solid lightgrey", borderRadius: "5px", backgroundColor: "transparent" }} value={value} onChange={e => onChange(e, e.target.value)} />
                : <span className="input-text">{value}</span>
            }
        </div> : <></>
);

// const EditablePanCell = ({ editable, value, newflag, handleChange, maskedPan, editablePan, handleEditablePan, getFullPan, refreshState }) => (
//     <div>
//         {newflag ? <input
//             aria-label="pan number"
//             className="input-text"
//             name="pan"
//             type="text"
//             value={maskedPan}
//             onChange={(e) => { handleChange(e) }} />
//             : editable ? editablePan ?
//                 <Input
//                     aria-label="pan number"
//                     style={{ margin: '-5px 0', marginTop: "8px", width: '90%', border: "1px solid lightgrey", borderRadius: "5px", backgroundColor: "transparent" }}
//                     name="pan"
//                     type="text"
//                     value={maskedPan}
//                     onChange={(e) => { handleChange(e) }} /> :
//                 <span className="input-text">{value}
//                     <button className="pseudoButtonIcon"
//                         onClick={(e) => handleEditablePan()}>
//                         <i role='img' aria-label="icon-Edit" className="icon-Edit" title='Edit' style={{ color: "blue", fontSize: "17px", marginLeft: "6%" }} />
//                     </button>
//                     <MediaQuery minDeviceWidth={1025}>
//                         <span style={{ marginLeft: '4%' }}>
//                             <Icon title={value.search('X-') === -1 ? 'Hide PAN Number' : 'View PAN Number'}
//                                 style={{ color: '#0a41c5' }}
//                                 type={value.search('X-') === -1 ? 'eye-invisible' : 'eye'}
//                                 onClick={() => {
//                                     setTimeout(() => refreshState(), 30000)
//                                     getFullPan()
//                                 }} />
//                         </span>
//                     </MediaQuery>
//                 </span> :
//                 <div>
//                     <span className="input-text">{value}</span>
//                     <MediaQuery minDeviceWidth={1025}>
//                         <span style={{ marginLeft: '4%' }}>
//                             <Icon title={value.search('X-') === -1 ? 'Hide PAN Number' : 'View PAN Number'}
//                                 style={{ color: '#0a41c5' }}
//                                 type={value.search('X-') === -1 ? 'eye-invisible' : 'eye'}
//                                 onClick={() => {
//                                     setTimeout(() => refreshState(), 30000)
//                                     getFullPan()
//                                 }} />
//                         </span>
//                     </MediaQuery>
//                 </div>
//         }
//     </div>
// )

const EditableCompanyAcctIdCell = ({ editable, value, newflag, handleChange, editableCompanyAcctId, handleEditableCompanyAcctId }) => (
    <div>
        {newflag ? <input
            aria-label="company account id"
            className="input-text"
            name="account_id"
            type="text"
            value={value}
            onChange={(e) => { handleChange(e) }} />
            : editable ? editableCompanyAcctId ?
                <Input
                    aria-label="company account id"
                    style={{ margin: '-5px 0', marginTop: "8px", width: '90%', border: "1px solid lightgrey", borderRadius: "5px", backgroundColor: "transparent" }}
                    name="account_id"
                    type="text"
                    value={value}
                    onChange={(e) => { handleChange(e) }} /> :
                <span className="input-text">{value}
                    <button className="pseudoButtonIcon"
                        onClick={(e) => handleEditableCompanyAcctId()}>
                        <i role='img' aria-label="icon-Edit" className="icon-Edit" title='Edit' style={{ color: "blue", fontSize: "17px", marginLeft: "6%" }} />
                    </button>
                </span> :
                <div>
                    <span className="input-text">{value}</span>
                </div>
        }
    </div>
)

class RenderColumnComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formFieldRef: this.props.formFieldRef,
            instaDetails: this.props.instaDetails,
            custCodeMandatory: this.props.custCodeMandatory,
            showSwitchNew: false,
            company: this.props.company,
            // panValue: this.props.panValue,
            showDrawer: false,
            temp: {},
            fullPan: [],
            editablePan: false,
            instaChecked: this.props.company?.companyId === "new" ? false : this.props.company?.serviceInterface.find(el => el === serviceInterfacesName.instaCard) ? true : false,
            elanChecked: this.props.company?.companyId === "new" ? false : this.props.company?.serviceInterface.find(el => el === serviceInterfacesName.elanEasyPay) ? true : false,
            CustCodeRequiredFlag: false,
            drawerTitle: '',
            editableCompanyAcctId: false,
            apiConsumerChecked:this.props.company?.companyId === "new" ? false : this.props.company?.apiConsumerCheck,
            // this.props.company?.editable || this.props.company?.companyId === "new" ? false : true
        }
    }

    componentDidMount() {
        this.interfaceFunction(false)
    }

    componentDidUpdate() {
        if (this.props.clickedClose) {
            // this.setState({ editablePan: false })
            this.setState({ editableCompanyAcctId: false })
            this.props.closedReset()
        }
    }

    clearInvalidField(element) {
        let label = $(element).closest('.compDetailsRow').find('.has-error');
        label.removeClass('has-error')
    }

    handleChange = (e, value, key, column, companyId, flagCheckbox) => {
       // console.log("comapany:::::", this.props.company)
        e && this.clearInvalidField(e.target);
        if (column === 'serviceInterface') {
            let label1 = $('.companyCrcustCodeClass1').closest('.compDetailsRow').find('.companyCrcustCodeClass1')
            label1.removeClass('has-error')
            let chromeRiverArray = this.props.custCodeMandatory
            value[serviceInterfaces.chromeRiver] === true ? chromeRiverArray[key] = true : chromeRiverArray[key] = false
            this.setState({ custCodeMandatory: chromeRiverArray })
            // let instaDetails = this.state.instaDetails;
            // if (value[serviceInterfaces.instaCard] || value[serviceInterfaces.elanEasyPay]) instaDetails[companyId] = true;
            // else delete instaDetails[companyId];
            // this.setState({ instaDetails: instaDetails });
        }
        const newData = this.props.company;
        if (column === 'serviceInterface') {
            var array = []
            for (var i in value) array[i] = (value[i])
            newData[column] = array
        } else newData[column] = value
        //console.log("newData:::::", newData)
        this.props.handleCompanyListChange(newData)
        if (flagCheckbox === true) {
            var instaChecked
            var elanChecked
            // if (this.props.company?.companyId === "new") {
            instaChecked = value[serviceInterfaces.instaCard]
            elanChecked = value[serviceInterfaces.elanEasyPay]
            // }
            // else {
            //     for (var m in this.props.company?.serviceInterface) {
            //         if (this.props.company?.serviceInterface[m] === serviceInterfacesName.instaCard) instaChecked = false
            //         else {
            //             for (var n in value) {
            //                 if (value[n] === false && n === serviceInterfaceId.instaCard + "") instaChecked = false
            //                 else instaChecked = true
            //             }
            //         }
            //     }
            // }
            this.setState({ instaChecked, elanChecked })
        }
    }

    // renderPanColumns(text, record, column, fullPan) {
    //     let panValue = this.state.panValue;
    //     return (
    //         <EditablePanCell
    //             editable={record.editable}
    //             value={record.fullPan ?
    //                 record.companyPan :
    //                 text.length === 16 ? 'XXXX-XXXX-XXXX-' + text.slice(12, 16) : 'XXXX-XXXX-XXXX-' + text}
    //             newflag={record.companyId === "new" ? true : false}
    //             column={column}
    //             handleChange={(e) => this.handleChange1(e, record.key, column)}
    //             maskedPan={panValue[record.key - 1].maskedPan}
    //             record={this.props.company}
    //             editablePan={this.state.editablePan}
    //             handleEditablePan={() => {
    //                 this.setState({ editablePan: !this.state.editablePan })
    //             }}
    //             getFullPan={() => {
    //                 axios.get(URLs.companyUrl + "viewCompanyPanDetals/" + record.companyId, {
    //                     responseType: 'json',
    //                     headers: {
    //                         "Authorization": 'Bearer ' + this.props.token.access_token
    //                     }
    //                 }).then(response => {
    //                     if (response.data.serviceStatus.statusCode === 200) {
    //                         const newData = [this.state.company, ...this.state.fullPan]
    //                         const target = newData.filter(item => record.key === item.key)[0]
    //                         if (target) {
    //                             target[column] = response.data.fullPan
    //                             target[fullPan] = true
    //                             this.setState({ company: newData })
    //                         }
    //                     }
    //                 }).catch(error => {
    //                     if (error.response.data.serviceStatus.statusCode === 404 ||
    //                         error.response.data.serviceStatus.statusCode === 212) {
    //                         notification.error({
    //                             message: <span role='alert' aria-live="assertive">{error.response.data.serviceStatus.message}</span>,
    //                             duration: 3,
    //                             style: {
    //                                 backgroundColor: '#fff1f0',
    //                                 border: "1px solid #ffa39e",
    //                             }
    //                         });
    //                     } else notification.error({
    //                         message: <span role='alert' aria-live="assertive">{errorMessages.generic_error}</span>,
    //                         duration: 3,
    //                         style: {
    //                             backgroundColor: '#fff1f0',
    //                             border: "1px solid #ffa39e",
    //                         }
    //                     })
    //                 })
    //             }}

    //             refreshState={() => {
    //                 this.setState({ fullPan: [] })
    //                 const newData = [...this.state.company, ...this.state.fullPan];
    //                 const target = newData.filter(item => record.key === item.key)[0];
    //                 if (target) {
    //                     target[fullPan] = false;
    //                     this.setState({ company: newData });
    //                 }
    //             }}
    //         />
    //     );
    // }

    renderCompanyAcctIdColumns(text, record, column) {
        return (
            <EditableCompanyAcctIdCell
                editable={record.editable}
                value={text}
                newflag={record.companyId === "new" ? true : false}
                column={column}
                handleChange={(e) => this.handleChangeAcctId(e, record.key, column)}
                record={this.props.company}
                editableCompanyAcctId={this.state.editableCompanyAcctId}
                handleEditableCompanyAcctId={() => {
                    this.setState({ editableCompanyAcctId: !this.state.editableCompanyAcctId })
                }}
            />
        );
    }

    handleChangeAcctId = (event, recordkey, column) => {
        const acctId = event.target.value
        if (acctId > 12) return
        if (isNaN(acctId)) {
            return;
        }
        this.handleChange(event, acctId, recordkey, column)
    }

    // handleChange1 = (event, recordkey, column) => {
    //     const prevValue = this.state.panValue[recordkey - 1].maskedPan;
    //     const newValue = event.target.value
    //     let actual = this.state.panValue[recordkey - 1].actualPan;
    //     if (newValue.length > 19) return

    //     // typing forward
    //     if (newValue.length > prevValue.length) {
    //         let newChar = newValue.split("").pop();

    //         actual = `${actual}${newChar}`;
    //         /*******only numbers are allowed********/
    //         if (isNaN(actual)) {
    //             return;
    //         }
    //     }
    //     // backspacing / deleting
    //     else {
    //         const charsRemovedCount = prevValue.length - newValue.length;
    //         actual = actual.substr(0, actual.length - charsRemovedCount);
    //     }
    //     let panValue = this.state.panValue;
    //     panValue[recordkey - 1].maskedPan = this.starredMask(actual);
    //     panValue[recordkey - 1].actualPan = actual;
    //     this.setState({ ...panValue });
    //     this.handleChange(event, actual, recordkey, column)
    // }


    // starredMask = (pan, showLast4 = true) => {
    //     // replace numeric digits with X 
    //     let valueHidden = pan.replace(/[\d]/g, "X");

    //     if (valueHidden.length <= 4) {
    //         return valueHidden;
    //     }

    //     if (valueHidden.length <= 8) {
    //         return valueHidden.slice(0, 4) + "-" + valueHidden.slice(4, 8);
    //     }

    //     if (valueHidden.length <= 12) {
    //         return valueHidden.slice(0, 4) + "-" + valueHidden.slice(4, 8) + "-" + valueHidden.slice(8, 12);
    //     }
    //     if (showLast4) {
    //         return (
    //             valueHidden.slice(0, 4) + "-" + valueHidden.slice(4, 8) + "-" + valueHidden.slice(8, 12) + "-" +
    //             pan.substr(12)
    //         );
    //     } else {
    //         return (
    //             valueHidden.slice(0, 4) + "-" + valueHidden.slice(4, 8) + "-" + valueHidden.slice(8, 12) + "-" + valueHidden.slice(12, 16)
    //         );
    //     }
    // }

    onSwitchChange = (refId, checked) => {
        let flag = null
        let formFieldRef = this.state.formFieldRef
        if (formFieldRef.length > 0) {
            for (var i in formFieldRef) if (formFieldRef[i].formFieldRefID === refId) flag = i
        }
        if (flag) formFieldRef[flag].formFieldStatus = checked
        else {
            let object = { formFieldRefID: refId, formFieldStatus: checked }
            formFieldRef.push(object)
        }
        this.setState({ formFieldRef })
        this.props.handler(formFieldRef)
    }

    interfaceFunction = (flag, e, serviceId) => {
       
        let temp = {}
        if (flag === true) {
            this.props.allServiceInterfaces.map(ele => {
                if (ele.SERVICE_INTERFACE_ID === serviceId) temp[ele.SERVICE_INTERFACE_ID] = e.target.checked
                return true
            })
        } else this.props.allServiceInterfaces.map(ele => {
            let val = !(this.props.company?.companyId === "new") && this.props.company?.serviceInterface && this.props.company?.serviceInterface.find(element => element === ele.SERVICE_INTERFACE)
            temp[ele.SERVICE_INTERFACE_ID] = val ? true : false
            return true
        })
        this.setState({ temp })
    }

    onChangeOfCheckbox = (e, service, serviceId, flag, companyId, company, text, custCodeMand) => {
   
        custCodeMand && this.setState({ CustCodeRequiredFlag: e.target.checked })
        this.interfaceFunction(true, e, serviceId)
        let temp1 = this.state.temp
   
        if (company?.serviceInterface)
  
            for (var serInt of company?.serviceInterface) {
                if (serInt === serviceInterfacesName.chromeRiver) temp1[serviceInterfaceId.chromeRiver] = true
                if (serInt === serviceInterfacesName.instaCard) temp1[serviceInterfaceId.instaCard] = true
                if (serInt === serviceInterfacesName.elanEasyPay) temp1[serviceInterfaceId.elanEasyPay] = true
            }
        if (service === serviceInterfacesName.instaCard) this.setState({ instaChecked: e.target.checked })
        temp1[serviceId] = e.target.checked
        this.setState({ temp: temp1 })
        this.handleChange(e, temp1, company?.key, text, companyId, true)
        if (serviceId === 2 && e.target.checked && temp1[3]) {
            document.getElementById(serviceInterfacesName.elanEasyPay + companyId).click()
        }
        if (serviceId === 3 && e.target.checked && temp1[2]) {
            document.getElementById(serviceInterfacesName.instaCard + companyId).click()
        }
    }

    renderColumns(text, record, column) {
        return (
            <EditableCell
                editable={record.editable}
                value={text}
                newflag={record.companyId === "new" ? true : false}
                column={column}
                onChange={(e, value) => this.handleChange(e, value, record.key, column, record.companyId)}
                allServiceInterfaces={this.props.allServiceInterfaces}
            />
        );
    }

    onCheckAPIConsumer = (e) => {
        this.setState({ apiConsumerChecked: e.target.checked })
        let newData = this.props.company
        newData['apiConsumerCheck'] = e.target.checked
       // console.log("newData  onCheckAPIConsumer:::::", newData)
        this.props.handleCompanyListChange(newData)
        if(e.target.checked === false){
            newData['externalSystemId'] = "";
            this.props.handleCompanyListChange(newData);
        }
    }

    render() {
        return (
            <div className="row compDetailsRow">
                <div className="row" style={{ marginTop: '1%' }}>
                    <div className={"col-sm-3 col-sm-offset-1 col-md-3 col-md-offset-1 listAttributes companyNameClass" + this.props.company?.key}>
                        <span>{this.props.app_labels.companyName}</span>
                        {this.props.company?.editable || this.props.company?.companyId === "new" ? <span className="required">*</span> : null}<br /><br />
                        {this.renderColumns(this.props.company?.companyName, this.props.company, 'companyName')}
                    </div>
                    <div className={"col-sm-3 col-sm-offset-1 col-md-3 col-md-offset-1 listAttributes companyShortNameClass" + this.props.company?.key} >
                        <span>{this.props.app_labels.companyShortName}</span>
                        {this.props.company?.editable || this.props.company?.companyId === "new" ? <span className="required">*</span> : null}<br /><br />
                        {this.renderColumns(this.props.company?.companyShortName, this.props.company, 'companyShortName')}
                    </div>
                    <div className={"col-sm-3 col-sm-offset-1 listAttributes companyCrcustCodeClass" + this.props.company?.key}>
                        <span title="chrome river customer code">{this.props.app_labels.customerCode}</span>
                        {((this.props.company?.editable || this.props.companyId === "new") && this.props.custCodeMandatory[this.props.company?.key]) || this.state.CustCodeRequiredFlag ? <span className="required">*</span> : null}<br /><br />
                        {this.renderColumns(this.props.company?.chromeRiverCustCd === undefined || this.props.company?.chromeRiverCustCd == null ? "NA" : this.props.company?.chromeRiverCustCd, this.props.company, 'chromeRiverCustCd')}
                    </div>
                </div><br />
                <div className="row" style={{ marginBottom: "1%" }}>
                    <div className={"col-sm-3 col-sm-offset-1 listAttributes companyAcctId" + this.props.company?.key} >
                        <span>{this.props.app_labels.companyAcctId}</span>
                        {this.props.company?.editable || this.props.company?.companyId === "new" ? <span className="required">*</span> : null}<br /><br />
                        {this.renderColumns(this.props.company?.companyAcctId, this.props.company, 'companyAcctId')}
                    </div>
                    <div className={"col-sm-3 col-sm-offset-1 listAttributes emailIdClass" + this.props.company?.key}>
                        <span>{this.props.app_labels.email}</span>
                        {this.props.company?.editable || this.props.company?.companyId === "new" ? <span className="required">*</span> : null}<br /><br />
                        {this.renderColumns(this.props.company?.emailId, this.props.company, 'emailId')}
                    </div>
                    <div className={"col-sm-3 col-sm-offset-1 col-md-3 col-md-offset-1 listAttributes phoneNoClass" + this.props.company?.key}>
                        <span>{this.props.app_labels.phoneNumber}</span>
                        {this.props.company?.editable || this.props.company?.companyId === "new" ? <span className="required">*</span> : null}<br /><br />
                        {this.renderColumns(this.props.company?.phoneNo, this.props.company, 'phoneNo')}
                    </div>
                </div>
                <div className="row" style={{ marginBottom: "1%" }}>
                    <div className={"listAttributes serviceInterfaceStyle serviceInterfaceClass" + this.props.company?.key}>
                        <span>{this.props.app_labels.serviceInterface}</span>
                        {this.props.company?.editable || this.props.company?.companyId === "new" ? <span className="required">*</span> : null}<br /><br />
                        {this.props.company?.companyId === "new" ?
                            <div className="row">
                                <div className="col-sm-4">
                                    {this.props.allServiceInterfaces.map((item, index) => {
                                        return (<div> {item.SERVICE_INTERFACE === serviceInterfacesName.chromeRiver ? <div><Checkbox className="checkbox-style"
                                            key={index} disabled={this.props.roles.toLowerCase() === 'customer support' ? true : false}
                                            onChange={e => this.onChangeOfCheckbox(e, item.SERVICE_INTERFACE, item.SERVICE_INTERFACE_ID, true, this.props.company?.companyId, this.props.company, 'serviceInterface', true)}
                                            placeholder="Service Interface"
                                            value={item.SERVICE_INTERFACE_ID} >
                                            {item.SERVICE_INTERFACE}
                                        </Checkbox>
                                        </div> : null}</div>)
                                    })}<br />
                                    {this.props.allServiceInterfaces.map((item, index) => (item.SERVICE_INTERFACE === serviceInterfacesName.instaCard || item.SERVICE_INTERFACE === serviceInterfacesName.elanEasyPay) &&
                                        <>
                                            <Checkbox className="checkbox-style" disabled={this.props.roles.toLowerCase() === 'customer support' ? true : false}
                                                id={item.SERVICE_INTERFACE + this.props.company?.companyId}
                                                key={index}
                                                placeholder="Service Interface"
                                                onChange={e => this.onChangeOfCheckbox(e, item.SERVICE_INTERFACE, item.SERVICE_INTERFACE_ID, true, this.props.company?.companyId, this.props.company, 'serviceInterface')}
                                                value={item.SERVICE_INTERFACE_ID}>{item.SERVICE_INTERFACE}</Checkbox>
                                            {item.SERVICE_INTERFACE === serviceInterfacesName.instaCard ?
                                                <span>
                                                    <button disabled={!this.state.instaChecked}
                                                        className="button-createtrip-ghost1"
                                                        onClick={e => this.setState({ showDrawer: !this.state.showDrawer, drawerTitle: 'Instant Card Configuration' })}>
                                                        <span className={`configure-class ${this.state.instaChecked ? '' : 'configure-disabled'}`}><Icon disabled type="setting" /></span>
                                                        <span
                                                            className={`configure-class ${this.state.instaChecked ? '' : 'configure-disabled'}`} >Configuration</span></button>
                                                </span>
                                                : <span>
                                                    <button disabled={!this.state.elanChecked}
                                                        className="button-createtrip-ghost1"
                                                        onClick={e => this.setState({ showDrawer: !this.state.showDrawer, drawerTitle: 'Elan Easy Pay Configuration' })}>
                                                        <span className={`configure-class ${this.state.elanChecked ? '' : 'configure-disabled'}`}><Icon disabled type="setting" /></span>
                                                        <span
                                                            className={`configure-class ${this.state.elanChecked ? '' : 'configure-disabled'}`} >Configuration</span></button>
                                                </span>}
                                        </>)}
                                </div>
                                <div className="col-sm-4 col-sm-offset-1 listAttributes"><Checkbox className="checkbox-style"
                                    // key={index}
                                    onChange={e => this.onCheckAPIConsumer(e)}
                                    // placeholder="API Consumer"
                                    value={this.state.apiConsumerChecked}
                                    defaultChecked={this.props.company?.apiConsumerCheck}
                                >
                                    {/* {item.SERVICE_INTERFACE} */}
                                    {this.props.app_labels.APIConsumer}
                                    {/* API Consumer */}
                                </Checkbox><br /><br />
                                <div className={"external-System-Id" + this.props.company?.key}>
                                    <span className={!this.state.apiConsumerChecked ? 'label-external-sys-id' : null}>{this.props.app_labels.externalSystemId}</span><span className={!this.state.apiConsumerChecked ? 'label-external-sys-id' : 'required'}>*</span><br/>
                                    <input disabled={!this.state.apiConsumerChecked} style={{ marginLeft: 0 }} type="text" className={!this.state.apiConsumerChecked ? "external-sys-id-style-disabled" : "input-text external-sys-id-style external-System-Id"} placeholder={this.props.app_labels.externalSystemIdHere} onChange={e => { console.log("e.target.value:::_____", e.target.value); this.handleChange(e, e.target.value, '', 'externalSystemId') }} value={this.props.company?.externalSystemId} />
                                    </div>
                                    <br /> <span className={!this.state.apiConsumerChecked ? 'label-external-sys-id ' : null}>{this.props.app_labels.roles}</span><span className={!this.state.apiConsumerChecked ? 'label-external-sys-id' : 'required'}>*</span><span className={!this.state.apiConsumerChecked ? 'label-external-sys-id' : null}>&nbsp;{this.props.app_labels.provisioner} ({this.props.app_labels.default})</span>
                                </div>
                            </div> : this.props.company?.editable
                                ? <div className="row">
                                    <div className="col-sm-4">
                                        {this.props.allServiceInterfaces.map((item, index) => {
                                            return (<div> {item.SERVICE_INTERFACE === serviceInterfacesName.chromeRiver ? <Checkbox className="checkbox-style"
                                                key={index}
                                                onChange={e => this.onChangeOfCheckbox(!this.props.company?.serviceInterface || this.props.company?.serviceInterface === undefined || this.props.company?.serviceInterface == null ? "NA" : e, item.SERVICE_INTERFACE, item.SERVICE_INTERFACE_ID, false, this.props.company?.companyId, this.props.company, 'serviceInterface')}
                                                placeholder="Service Interface"
                                                defaultChecked={item.SERVICE_INTERFACE === this.props.company?.serviceInterface.find(el => el === item.SERVICE_INTERFACE) ? true : false}
                                                value={item.SERVICE_INTERFACE_ID} >
                                                {item.SERVICE_INTERFACE}
                                            </Checkbox> : null} </div>)
                                        })}<br />
                                        {this.props.allServiceInterfaces.map((item, index) => (item.SERVICE_INTERFACE === serviceInterfacesName.instaCard || item.SERVICE_INTERFACE === serviceInterfacesName.elanEasyPay) &&
                                            <>
                                                <Checkbox className="checkbox-style"
                                                    id={item.SERVICE_INTERFACE + this.props.company?.companyId}
                                                    key={index}
                                                    onChange={e => this.onChangeOfCheckbox(!this.props.company?.serviceInterface || this.props.company?.serviceInterface === undefined || this.props.company?.serviceInterface == null ? "NA" : e, item.SERVICE_INTERFACE, item.SERVICE_INTERFACE_ID, false, this.props.company?.companyId, this.props.company, 'serviceInterface')}
                                                    placeholder="Service Interface"
                                                    defaultChecked={item.SERVICE_INTERFACE === this.props.company?.serviceInterface.find(el => el === item.SERVICE_INTERFACE) ? true : false}
                                                    value={item.SERVICE_INTERFACE_ID} >
                                                    {item.SERVICE_INTERFACE}
                                                </Checkbox>
                                                {item.SERVICE_INTERFACE === serviceInterfacesName.instaCard ?
                                                    <span>
                                                        <button disabled={!this.state.instaChecked}
                                                            className="button-createtrip-ghost1"
                                                            onClick={e => this.setState({ showDrawer: !this.state.showDrawer, drawerTitle: 'Instant Card Configuration' })}>
                                                            <span className={`configure-class ${this.state.instaChecked ? '' : 'configure-disabled'}`}><Icon disabled type="setting" /></span>
                                                            <span
                                                                className={`configure-class ${this.state.instaChecked ? '' : 'configure-disabled'}`} >Configuration</span></button>
                                                    </span>
                                                    : <span>
                                                        <button disabled={!this.state.elanChecked}
                                                            className="button-createtrip-ghost1"
                                                            onClick={e => this.setState({ showDrawer: !this.state.showDrawer, drawerTitle: 'Elan Easy Pay Configuration' })}>
                                                            <span className={`configure-class ${this.state.elanChecked ? '' : 'configure-disabled'}`}><Icon disabled type="setting" /></span>
                                                            <span
                                                                className={`configure-class ${this.state.elanChecked ? '' : 'configure-disabled'}`} >Configuration</span></button>
                                                    </span>}
                                            </>)}</div>
                                    <div className="col-sm-4 col-sm-offset-1"><Checkbox className="checkbox-style"
                                        // key={index}
                                        onChange={e => this.onCheckAPIConsumer(e)}
                                        // placeholder="API Consumer"
                                        value={this.props.company?.externalSystemId !==null?true:false}
                                        defaultChecked={this.props.company?.externalSystemId === null || this.props.company?.externalSystemId === '' ? false : true}
                                    >
                                        {/* {item.SERVICE_INTERFACE} */}
                                        {this.props.app_labels.APIConsumer}
                                        {/* API Consumer */}
                                    </Checkbox><br /><br />
                                    <div className={"external-System-Id" + this.props.company?.key}>
                                        <span className={!this.state.apiConsumerChecked ? 'label-external-sys-id' : null}>{this.props.app_labels.externalSystemId}</span>
                                        <span className={!this.state.apiConsumerChecked ? 'label-external-sys-id' : 'required'}>*</span>
                                        <input disabled={!this.state.apiConsumerChecked} style={{ marginLeft: 0 }} type="text" className={!this.state.apiConsumerChecked ? "external-sys-id-style-disabled" : "input-text external-sys-id-style external-System-Id"} placeholder={this.props.app_labels.externalSystemIdHere} onChange={e => { console.log("e.target.value:::_____", e.target.value); this.handleChange(e, e.target.value, '', 'externalSystemId') }} value={this.props.company?.externalSystemId} />
                                        </div>
                                        <br /> <span className={!this.state.apiConsumerChecked ? 'label-external-sys-id' : null}>{this.props.app_labels.roles}</span><span className={!this.state.apiConsumerChecked ? 'label-external-sys-id' : 'required'}>*</span><span className={!this.state.apiConsumerChecked ? 'label-external-sys-id' : null}>&nbsp;{this.props.app_labels.provisioner} ({this.props.app_labels.default})</span>
                                    </div>
                                </div> : <div className='row'>
                                    <div className='col-sm-4'>
                                        {this.props.company?.serviceInterface && this.props.company?.serviceInterface.map((data, i) => {
                                            return (<div>
                                                <span key={i} className="input-text">{data}</span>
                                                {data === serviceInterfacesName.instaCard && <>
                                                    <button disabled={!this.state.instaChecked}
                                                        className="button-createtrip-ghost1"
                                                        onClick={e => this.setState({ showDrawer: !this.state.showDrawer, drawerTitle: 'Instant Card Configuration' })}>
                                                        <span className={`configure-class ${this.state.instaChecked ? '' : 'configure-disabled'}`}><Icon disabled type="setting" /></span>
                                                        <span
                                                            className={`configure-class ${this.state.instaChecked ? '' : 'configure-disabled'}`} >Configuration</span></button>
                                                </>}
                                                {data === serviceInterfacesName.elanEasyPay && <>
                                                    <button disabled={!this.state.elanChecked}
                                                        className="button-createtrip-ghost1"
                                                        onClick={e => this.setState({ showDrawer: !this.state.showDrawer, drawerTitle: 'Elan Easy Pay Configuration' })}>
                                                        <span className={`configure-class ${this.state.elanChecked ? '' : 'configure-disabled'}`}><Icon disabled type="setting" /></span>
                                                        <span
                                                            className={`configure-class ${this.state.elanChecked ? '' : 'configure-disabled'}`} >Configuration</span></button>
                                                </>}
                                            </div>)
                                        })}
                                    </div>
                                
                                     {/* EW-3274 : The below section to view the externalSystemId in company setup page for super admin role */}
                                     {(this.props.company?.externalSystemId !== null && this.props.company?.externalSystemId !=="") && <div style ={{padding:0}}className='col-sm-4 col-sm-offset-1 '>
                                     <div style={{fontSize:"18px"}}>{this.props.app_labels.APIConsumer}</div>
                                     <div><span>{this.props.app_labels.externalSystemId} :</span> <span class="input-text" style={{marginLeft: 0}}>{this.props.company?.externalSystemId === null || this.props.company?.externalSystemId === '' ? "NA" : this.props.company?.externalSystemId }</span></div>
                                     <div><span>{this.props.app_labels.roles} : </span> <span style={{fontSize: "18px"}}>{this.props.app_labels.provisioner} &nbsp;({this.props.app_labels.default})</span></div>
                                     </div>}
                                </div>
                        }
                    </div>
                </div>

                {
                    (this.props.company?.companyId === "new" && this.state.showDrawer === true) ?
                        <div>
                            <Drawer
                                title={this.state.drawerTitle}
                                placement="right"
                                closable={false}
                                onClose={() => this.setState({ showDrawer: !this.state.showDrawer })}
                                visible={this.state.showDrawer} >
                                <div className="row row-class">
                                    <div className={" listAttributes list-class"} >
                                        <span style={{ marginLeft: '11%' }} >{this.props.app_labels.nonMandatory}</span><br />
                                    </div>
                                </div>
                                <div className="row" style={{ marginBottom: "1%", }}>
                                    {this.props.referenceData.referenceData && this.props.referenceData.referenceData.map(element => {
                                        return (<div className={"listAttributes switchClass" + this.props.company?.key}>
                                            <span className="element-value" >{element.VALUE}</span>
                                            <Switch disabled={this.props.roles.toLowerCase() === 'customer support' ? true : false}
                                                onClick={(checked) => this.onSwitchChange(element.REF_ID, checked)} />
                                            <div className="switch-sentence">
                                                {this.props.app_labels.swichToggle}
                                            </div>
                                        </div>)
                                    })}
                                </div>
                            </Drawer>
                        </div>
                        : <>
                            {this.state.showDrawer === true ? <>
                                {this.props.company?.editable ? <div>
                                    <Drawer
                                        title={this.state.drawerTitle}
                                        placement="right"
                                        closable={false}
                                        onClose={() => this.setState({ showDrawer: !this.state.showDrawer })}
                                        visible={this.state.showDrawer} >
                                        <div className="row row-class">
                                            <div className={" listAttributes list-class"} >
                                                <span style={{ marginLeft: '11%' }} >{this.props.app_labels.nonMandatory}</span><br />
                                            </div>
                                        </div>
                                        <div className="row" style={{ marginBottom: "1%", }}>
                                            {this.props.referenceData.referenceData && this.props.referenceData.referenceData.map(element => {
                                                return (<div className={"listAttributes switchClass" + this.props.company?.key}>
                                                    <span className="element-value">{element.VALUE}</span>
                                                    <Switch disabled={this.props.roles.toLowerCase() === 'customer support' ? true : false}
                                                        defaultChecked={this.state.instaDetails[this.props.company?.companyId] === true ? true : false}
                                                        onClick={(checked) => this.onSwitchChange(element.REF_ID, checked)} />
                                                    <div className="switch-sentence">
                                                        {this.props.app_labels.swichToggle}
                                                    </div>
                                                </div>)
                                            })}
                                        </div>
                                    </Drawer>
                                </div> : <>
                                    <Drawer
                                        title={this.state.drawerTitle}
                                        placement="right"
                                        closable={false}
                                        onClose={() => this.setState({ showDrawer: !this.state.showDrawer })}
                                        visible={this.state.showDrawer} >
                                        <div className="row row-class" >
                                            <div className={" listAttributes list-class"} >
                                                <span style={{ marginLeft: '11%' }} >{this.props.app_labels.nonMandatory}</span><br />
                                            </div>
                                        </div>
                                        <div className="row" style={{ marginBottom: "1%" }}>
                                            {this.props.referenceData.referenceData && this.props.referenceData.referenceData.map(element => {
                                                return (<div className={"listAttributes switchClass" + this.props.company?.key}>
                                                    <span className="element-value">{element.VALUE}</span>
                                                    <Switch disabled={true}
                                                        defaultChecked={this.state.instaDetails[this.props.company?.companyId] === true ? true : false}
                                                        onClick={(checked) => this.onSwitchChange(element.REF_ID, checked)} />
                                                    <div className="switch-sentence">
                                                        {this.props.app_labels.swichToggle}
                                                    </div>
                                                </div>)
                                            })}
                                        </div>
                                    </Drawer>
                                </>}
                            </> : <></>}
                        </>
                }

                <div className="row">
                    <div className="col-sx-11 col-sx-offset-1 col-sm-4 col-sm-offset-8 col-md-3 col-md-offset-9 col-lg-2 col-lg-offset-10" >
                        {this.props.company?.companyId === "new" || !this.props.company?.activeStatus ? null : <div style={{ marginBottom: "1%" }} className={"upload" + this.props.company?.key}>
                            <br />
                            <div className={`uploadPolicy ${this.props.roles.toLowerCase() === 'customer support' ? ' disabled-icon' : ''}`}
                                onClick={(e) => this.props.roles.toLowerCase() !== 'customer support' && this.props.uploadPolicy(this.props.company, e)} >
                                <b><i role='img' style={{ fontSize: "larger" }} aria-label="icon-Upload attachment" className={`icon-Upload-attachment-02 ${this.props.roles.toLowerCase() === 'customer support' ? ' disabled-icon' : ''}`} /> {this.props.app_labels.uploadPolicyDoc}</b></div>
                        </div>}
                    </div>
                </div>
            </div >
        )
    }
}

function mapStateToProps(state, props) {
    return {
        token: state.authenticationReducer.token,
        userDetails: state.authenticationReducer.userDetails,
    };
}

export default connect(mapStateToProps)(RenderColumnComponent)