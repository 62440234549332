import { applyMiddleware, createStore } from 'redux';
import appReducer from '../reducers/appReducer.js';

import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga'
import logger from 'redux-logger';
import rootSaga from '../sagas'
import refreshTokenScheduler from '../middlewares/refreshTokenScheduler'
import english_labels from '../data/english_label'

let userIdentity;
let app_labels;
let languageList;
let selectedLanguage;
let iclanguageList;
let label_keys;

if (window.sessionStorage) {
    userIdentity = window.sessionStorage.getItem('cadre-user-identity');
    app_labels = window.sessionStorage.getItem('app-labels');
    selectedLanguage = window.sessionStorage.getItem('selected_Language');
    languageList = window.sessionStorage.getItem('language_List');
    app_labels = app_labels ? JSON.parse(app_labels).app_labels : english_labels.app_labels;
    selectedLanguage = selectedLanguage ? JSON.parse(selectedLanguage).selectedLanguage : null;
    languageList = languageList ? JSON.parse(languageList).languageList : null;
    userIdentity = userIdentity ? JSON.parse(userIdentity) : null;

    label_keys = window.sessionStorage.getItem('ic_app-labels');
    iclanguageList = window.sessionStorage.getItem('ic_language_List');
    label_keys = label_keys ? JSON.parse(label_keys).label_keys : null;
    iclanguageList = iclanguageList ? JSON.parse(iclanguageList).iclanguageList : null;
}

const preloadedState = userIdentity ? {
    authenticationReducer: userIdentity,
    adduserReducer: { app_labels, languageList, selectedLanguage },
    instantCardMultiLanReducer: { label_keys, iclanguageList }
} : { adduserReducer: { app_labels, languageList, selectedLanguage }, instantCardMultiLanReducer: { label_keys, iclanguageList } };

const sagaMiddleware = createSagaMiddleware()
var store = applyMiddleware(logger, thunk, refreshTokenScheduler, sagaMiddleware)(createStore)(appReducer, preloadedState);
sagaMiddleware.run(rootSaga)

store.subscribe(function () {
    let state = store.getState().authenticationReducer;
    let labelState = store.getState().adduserReducer;
    let icLabelState = store.getState().instantCardMultiLanReducer;
    if (state.token && state.tokenTimestamp) {
        if (window.sessionStorage) {
            window.sessionStorage.setItem('cadre-user-identity', JSON.stringify({
                token: state.token,
                tokenTimestamp: state.tokenTimestamp
            }));
        }
    }
    if (labelState !== null && labelState.app_labels) {
        if (window.sessionStorage) {
            window.sessionStorage.setItem('app-labels', JSON.stringify({ app_labels: labelState.app_labels }));
        }
    }
    if (labelState !== null && labelState.languageList) {
        if (window.sessionStorage) {
            window.sessionStorage.setItem('language_List', JSON.stringify({ languageList: labelState.languageList }));
        }
    }
    if (labelState !== null && labelState.selectedLanguage) {
        if (window.sessionStorage) {
            window.sessionStorage.setItem('selected_Language', JSON.stringify({ selectedLanguage: labelState.selectedLanguage }));
        }
    }
    if (icLabelState !== null && icLabelState.label_keys) {
        if (window.sessionStorage) {
            window.sessionStorage.setItem('ic_app-labels', JSON.stringify({ label_keys: icLabelState.label_keys }));
        }
    }
    if (icLabelState !== null && icLabelState.iclanguageList) {
        if (window.sessionStorage) {
            window.sessionStorage.setItem('ic_language_List', JSON.stringify({ iclanguageList: icLabelState.iclanguageList }));
        }
    }
})

export default store;