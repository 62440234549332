import React, { Component } from 'react';
import axios from 'axios'
import { connect } from 'react-redux';
import{ FormControlLabel, Checkbox } from '@mui/material' ;
import { flowCheckFlag } from '../../actions/authenticationActions';
import { Row, Col, notification, Button } from 'antd';
import '../../FontIcons/style.css';
import history from '../history';
const { URLs } = require('../../data/constants.js')

class ElanTnC extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tncData: '',
            checkedValue: false,
        }
    }

    componentDidMount() {
        axios.get(URLs.comUrl + 'getTnCInstaCard?LANG_CODE=elan', {
            headers: {
                "Authorization": 'Basic Q2FkcmUtTW9iaWxlOmNhZHJl'
            }
        }).then(res => {
            this.setState({ tncData: res.data.data })
        })
        .catch(err => notification.error({
            message: <span  role='alert' aria-live="assertive" aria-atomic="true">
                <span className="visually-hidden"> Error: </span>
                {this.props.label_keys.couldNotcompleteReq}</span>,
            duration: 3,
            style: {
                backgroundColor: '#fff1f0',
                border: "1px solid #ffa39e",
            }
        }))
        if(window.location.hostname.includes('elan-solutions.elaneasypay'))
            document.title= "Terms and Conditions: Elan Easy Pay" ;
    }

    onCheckChange = (e) => {
        e.target && this.setState({ checkedValue: e.target.checked })
    }

    buttonClick = () => {
        let data = {
            username: this.props.emailId,
            messageType: "email"
        }
        this.props.sendOTP(data)
        this.props.flowCheckFlag(true)
        history.push('/forgotPassword')
    }

    render() {
        return (
            this.state.tncData &&
            <div style={{ marginTop: '-6%' }}>
                <Row>
                    <Col className="headingStyle" role="heading" aria-level="1">{this.props.label_keys.termsConditions}</Col>
                </Row>
                <Row>
                    <Col className="loginMsgText">{this.props.label_keys.pleaseReadTermsConditions}</Col>
                </Row><br />
                <Row>
                    <Col className='scrollable-container' span={20}>
                        <div dangerouslySetInnerHTML={{ __html: this.state.tncData }}></div>
                    </Col>
                </Row><br />
                <Row>
                    <Col span={18}>
                        {/* <Checkbox onChange={this.onCheckChange} style={{ color: '#19398A' }}>
                            {this.props.label_keys.agreeTermsConditions}</Checkbox> */}
                        <FormControlLabel
                            control={
                            <Checkbox checked={this.state.checkedValue} 
                                className='agreeTermsConditions'
                                onChange={this.onCheckChange}
                                name={this.props.label_keys.agreeTermsConditions}
                                style={{ color: '#19398A' }}
                                inputProps={{ className:"agreeTermsConditionsCheckbox", id:'agreeTermsConditions' }} 
                                TouchRippleProps={{ style: { backgroundColor: 'transparent' }}}/>
                            }
                            style={{width: '100%', marginTop: '-6px'}}
                            label={<span className="agreeTermsConditionsLabel"
                            style={{marginLeft: '3px'}}>{this.props.label_keys.agreeTermsConditions}</span>}
                        />
                    </Col>
                </Row><br /><br />
                <Row>
                    <Button className='loginBtnClass' disabled={!this.state.checkedValue} onClick={this.buttonClick}>
                        <span className='loginBtnText'>{this.props.label_keys.continue}</span>
                    </Button>
                </Row>
            </div>
        );
    }
}
const mapDispatchToProps = dispatch => {
    return {
        flowCheckFlag: (flag) => {
            dispatch(flowCheckFlag(flag));
        }
}
}

export default connect(null,mapDispatchToProps)(ElanTnC)