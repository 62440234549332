import React from 'react'
import { Row, Col } from 'antd'
import './header.css'

function Header(props){
  return(
    <Row className='transaction-section-header'>
      <Col>{ props.text || 'placeholder_text' }</Col>
    </Row>
  )
}

export default Header