import React from 'react';
import { notification, Upload, Spin, Modal, Icon, Button, } from 'antd';
import { loaderFocus } from '../../utils/accessability';
import { getUserActions } from '../../actions/userAction';
import { getTrips } from '../../actions/gettripsActions';
import { getAllDetails } from '../../actions/getAllDetails';
import 'antd/dist/antd.css';
import '../../styling/userAction.css';
// import '../../styling/createtrip.css';
import { connect } from 'react-redux';
import MediaQuery from 'react-responsive';
import 'react-responsive-ui/style.css';
import imageCompression from 'browser-image-compression';
import reqwest from "reqwest";
const { URLs } = require('../../data/constants.js');
const Dragger = Upload.Dragger;

class DashHotelFileUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fileList: [],
      dbFetch: false,

    }
  }

  /**upload file
  * @param  {true}} =>{this.setState({dbFetch
  */
  handleUpload = () => {
    const { fileList } = this.state;
    if (fileList.length === undefined || fileList.length === 0) {
      notification.warning({
        message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{this.props.app_labels.error_noFileFound}</span>,
        duration: 3,
        style: {
          backgroundColor: '#fffbe6',
          border: "1px solid #ffe58f",
        }
      });
      return
    }
    this.setState({
      dbFetch: true
    });
    loaderFocus(".loader-color .ant-spin-text"); 
    var options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true
    }
    //const { fileList } = this.state;
    const formData = new FormData();
    let count = 0;
    let fileNames = [];
    fileList.forEach(file => {
      if (file.type === "application/pdf") {
        count++;
        fileNames.push(file.name);
        formData.append("hotelBookingFile", file);
        if (count === fileList.length) {
          formData.append("fileNames", JSON.stringify(fileNames));
          this.uploadFormData(formData);
        }
      } else if (file.type === "image/jpeg" || file.type === "image/png") {
        imageCompression(file, options)
          .then((compressedFile) => {
            count++;
            compressedFile.originalname = "flight.png";

            formData.append("hotelBookingFile", compressedFile);
            fileNames.push(file.name);
            if (count === fileList.length) {
              formData.append("fileNames", JSON.stringify(fileNames));
              this.uploadFormData(formData);
            }
          })
          .catch(function (error) {
            return;
          });
      }else{
        this.setState({
            dbFetch: false
        });
        notification.warning({
            message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{"Upload only pdf, png or jpg files"}</span>,
            duration: 3,
            style: {
                backgroundColor: '#fffbe6',
                border: "1px solid #ffe58f",
            }
        });
        return
    }
      // await uploadToServer(compressedFile);
    });
  };

  uploadFormData(formData) {
    formData.append("tripId", this.props.selectedtrip1.tripId);
    formData.append("attachmentType", "H");
    reqwest({
      url: URLs.fileUrl + "uploadHotelFile",
      headers: {
        "Authorization": 'Bearer ' + this.props.token.access_token
      },
      method: "post",
      processData: false,
      data: formData,
      success: (response) => {
        this.setState({ dbFetch: false })
        if (response.serviceStatus.statusCode === 210) {
          notification.error({
            message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{response.serviceStatus.message}</span>,
            duration: 3,
            style: {
              backgroundColor: '#fff1f0',
              border: "1px solid #ffa39e",
            }
          });
        }
        else if (response.serviceStatus.statusCode === 200) {
          this.setState({
            fileList: [],
          });
          this.props.hideHotelModal();
          notification.success({
            message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Success </span>{this.props.app_labels.success_uploadCompl}</span>,
            duration: 3,
            style: {
              backgroundColor: '#dff0d8',
              color: "green",
              border: "1px solid green",
            }
          });
          this.props.gettrips();
          this.props.getuseractions();
          this.props.getalldetails();
        }
      },
      error: (err) => {
        this.setState({ dbFetch: false })
        notification.error({
          message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{JSON.parse(err.response).serviceStatus.message}</span>,
          duration: 3,
          style: {
            backgroundColor: '#fff1f0',
            border: "1px solid #ffa39e",
          }
        });
      }
    });
  }

  /**Cancel will hide the popup
    * @param  {} =>{this.props.hideFligthModal(
    */
  handleCancel = () => {
    this.props.hideHotelModal();
  }

  render() {
    const uploadprops = {
      name: 'hotelBookingFile',
      multiple: true,
      onRemove: file => {
        this.setState(({ fileList }) => {
          const index = fileList.indexOf(file);
          const newFileList = fileList.slice();
          newFileList.splice(index, 1);
          return {
            fileList: newFileList
          };
        });
      },
      beforeUpload: file => {
        let fileSize = file.size / 1024 / 1024;
        if (fileSize > 5) {
          notification.warning({
            message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{this.props.app_labels.error_fileSize}</span>,
            duration: 3,
            style: {
              backgroundColor: '#fffbe6',
              border: "1px solid #ffe58f",
            }
          });
          return;
        }
        this.setState(({ fileList }) => ({
          fileList: [...fileList, file]
        }));
        return false;
      },
      fileList: this.state.fileList
    };

    return (
      <Modal
        visible={this.props.action}
        /*  afterClose={this.afterClose1} */
        closable={false}
        destroyOnClose={true}
        onCancel={this.handleCancel}
        footer={null}
        className='modal_container'>
        <MediaQuery minDeviceWidth={768}>
          <div className='row row_center'>
            <div className='col-sm-12 col-sm-offset-0 col-xs-12 col-xs-offset-0 col_center'>
              <Dragger {...uploadprops} showUploadList={false}>
                <p className="ant-upload-drag-icon">

                  <i role='img' aria-label='Hotel itinerary' className="icon-Buildings-grey-size-1" style={{ fontSize: "60px" }}></i>
                </p>
                <p className="ant-upload-text" style={{ color: "grey" }} ><b>{this.props.app_labels.dragnDropHotel}</b></p>
                {/* <p className="ant-upload-hint">Support for a single or bulk upload. Strictly prohibit from uploading company data or other band files</p> */}
              </Dragger>
            </div>
          </div>
        </MediaQuery>
        <div className='row upload-class row_center'>
          <div className='col-sm-12 col-sm-offset-0 col-xs-12 col-xs-offset-0 col_center'>
            <Upload {...uploadprops}>
              <Button >
                <Icon type="cloud-upload-o" style={{ fontSize: 25, color: "#112A70" }} /><span className="upload-text">{this.props.app_labels.clickToBrowse}</span>
              </Button>
            </Upload>
          </div>
        </div>
        {this.state.dbFetch ? <div className="dark-spinner loader-color"><Spin tip="Uploading..." /></div> : null}

        <div className='row'>
          <div className='col-sm-6 col-sm-offset-0 col-xs-6 col-xs-offset-0'>
            <Button onClick={this.handleCancel} className="horizontalCentering upload-buttons button-wrapclass1" type="primary" ghost><b>{this.props.app_labels.createCardCancel}</b></Button>
          </div>
          <div className='col-sm-6 col-sm-offset-0 col-xs-6 col-xs-offset-0'>
            <Button disabled={this.state.fileList.length === 0} onClick={this.handleUpload} className="horizontalCentering upload-buttons-save button-wrapclass1" type="primary"><b>{this.props.app_labels.save}</b></Button>
          </div>
        </div>
      </Modal>
    )
  }
}

function mapStateToProps(state, props) {
  return {
    tripdata: state.gettripReducer.getAlltripData,
    token: state.authenticationReducer.token,
    app_labels: state.adduserReducer.app_labels,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    gettrips: () => {
      dispatch(getTrips());
    },
    getuseractions: () => {
      dispatch(getUserActions())
    },
    getalldetails: () => {
      dispatch(getAllDetails());
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DashHotelFileUpload);