export default {
  travellerFirstName: "",
  travelerLastName: "",
  travellerType: "",
  travellerId: "",
  travellerEmail: "",
  travelerNotifield: false,
  bookedByTraveler: false,
  cardLimitvalue: 0,
  phno: "",
  phno2: "",
  startdate: "",
  enddate: "",
  actualStartdate: "",
  actualEnddate: "",
  origin: "",
  destination: "",
  budget: 0,
  flight: false,
  hotel: false,
  redirect: false,
  activeCard: false,
  cardNumber: "",
  cardExpiryDate: "",
  cardStartDate: "",
  fullcardNumber: "",
  cardAssigned: false,
  cvv: "",
  creditCardDetails: [],
  travelleruniqueId: "",
  tripId: "",
  tripstatusDescription: "",
  entityCode: [],
  cardstatus: "",
  flightstatus: "",
  hotelstatus: "",
  flightfile: [],
  hotelfile: [],
  getAlltripData: [],
  pendingTripClosure: 0,
  cardsTobeAssigned: 0,
  deleteUserResponse:null,
  pendingItenaryUpload: 0,
  travelersToBeNotified: 0,
  allTripDetail: [],
  accountingCode: "",
  suggestions: [],
  isFetching: true,
  isDbFetching: false,
  isDbCardFetching: false,
  isDbuserTripFetching: false,
  isDbuserActionFetching: false,
  sendInfo: false,
  activeHiringManagerList: [],
  department: '',
  role: '',
  /*** Related to Adminscreen ****/
  profileCreated: false,
  /*** Related to authentication ***/
  isAuthenticating: undefined,
  authenticationError: null,
  isAuthenticated: undefined,
  isCredentialsResetting: undefined,
  credentialsResetSuccess: null,// credentials
  credentialsResetError: null,// credentials
  isForgettingCredentials: undefined, // credentials
  forgotCredentialsSuccess: null,// Credentials
  forgotCredentialsError: null,// Credentials
  token: null,
  tokenTimestamp: null,
  userDetails: null,
  tempUsername: null,
  sendOTPRequest: null,
  sendOTPSuccess: null,
  sendOTPError: null,
  verifyOTPRequest: null,
  verifyOTPSuccess: null,
  verifyOTPError: null,

  isTokenRefreshing: null,
  tokenRefreshSuccess: null,
  tokenRefreshError: null,
  refreshTokenTimer: null,
  /*********************************/

  /****** Related to csr *******/
  csrTransactionData: undefined,
  csrTransactionError: null,
  csrTransactionIsFetching: false,
  csrTransactionDetailsIsUpdating: false,
  csrTransactionDetailsUpdateError: null,
  csrTransactionDetailsUpdateSuccess: false,
  /*****************************/

  saved: false,

  /****Expense Wizard multiLanguage********/
  isMulLanLabelFetch: false,
  app_labels: null,
  languageList: [],
  selectedLanguage: null,

  /****instant Card multiLanguage********/
  isICMulLanLabelFetch: false,
  label_keys: null,
  iclanguageList: [],
}
