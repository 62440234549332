import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Row, Col, Alert } from 'antd';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import qs from 'qs';
import { login, resetPassword, forgotPassword, forgotPasswordStateReset, sendOTP, activateAccountStateReset, verifyOTP, resetPasswordStateReset } from '../actions/authenticationActions';
import { withRouter } from 'react-router-dom';
import LoginFormComponent from './LoginFormComponent';
import MFAFormComponent from './MFAFormComponent'
import ResetPasswordFormComponent from './ResetPasswordFormComponent';
import OTPValidationFormComponent from './OTPValidationFormComponent';
import OpenSourceLicense from './OpenSourceLicense';
import { errorMessages } from '../data/constants';
import { multiLanguageLabelRetrieve } from '../actions/multilanAction'
import { fetchListLangauages } from '../actions/multilanAction';
import { fetchinstantCardListLangauages, instantCardLabelRetrieve } from '../actions/instantCardMultiLanAction'
const StyledDiv = styled.div`
    width: 200px;
    margin: 50px 50vw;
    margin-top:10vh;
    text-align: center;
    
    padding: 20px;

    @media (min-width: 768px){
        width: 350px;
    }
    @media (min-width: 1024px){
        width: 500px;
    }
`;
/* box-shadow: 3px 3px 5px 5px #e8e8e8; */

const StyledH4 = styled.h4`
    font-size: 24px;
    color: #112A70;
`;

const StyledH2 = styled.h2`
    font-size: 24px;
    color: #112A70;
`;

const StyledH1 = styled.h1`
    font-size: 24px;
    color: #112A70;
`;


class AuthComponent extends Component {

    constructor(props) {
        super(props)
        this.state = {
            selectedLang: 'EN',
        }
    }

    componentDidMount() {
        this.props.fetchListLangauages();
        this.props.multiLanguageLabelRetrieve('EN');
        this.props.fetchinstantCardListLangauages();
        this.props.instantCardLabelRetrieve('Ic');
        let elem = document.querySelector('body');
        elem.classList.add('login-page');
        // console.log("Window::", window.innerWidth, window);
    }

    componentWillUnmount() {
        let elem = document.querySelector('body');
        elem.classList.remove('login-page');
    }

    isUrlMalformed(type = 'resetPassword', url) {
        switch (type) {
            case 'resetPassword':
                let queryString = url;
                return typeof queryString !== 'string'
            default:
                return false
        }
    }

    getRequestTypeForResetPassword(queryString) {
        return !this.isUrlMalformed('resetPassword', queryString) && qs.parse(queryString).type
    }

    render() {
        
         // TODO: Check if already authenticating then show please wait...
        if (this.props.match.url === '/resetPassword') {

            let requestType = this.getRequestTypeForResetPassword(this.props.location.search.slice(1))
            if (!requestType) {
                this.props.history.push('/login')
                return null
            }
            setTimeout(() =>{
                document.getElementById('reset-pass-title').focus();
            }, 100);

            return (this.props.label_keys &&
                <div className="container-header">
                    <Row role="banner">
                        <Col  xs={16} sm={16} md={20} lg={20} xl={20} offset={1} >
                            <span tabIndex={(this.props.match.url === '/login') ? null : 0} 
                                style={{cursor: (this.props.match.url === '/login') ? "auto" :"pointer", marginLeft: "9px"}}
                                role={(this.props.match.url === '/login') ? null : "link"}
                                aria-label={(this.props.match.url === '/login') ? null : "US Bank Instant Card"} onClick={this.redirectToLogin}>
                                <img src={require(`../images/provisioner/ic/logo.svg`).default} height="25px" alt='US Bank' />&nbsp;
                                <span className="instant-card-ic">|&nbsp;&nbsp;{this.props.label_keys[this.state.selectedLang].instantCard}</span>
                                {/* <span style={{ top: '10%', position: 'relative' }}>{this.props.label_keys[this.state.selectedLang].instantCard}</span> */}
                            </span>
                        </Col>
                        <Col xs={6} sm={1} md={1} offset={1}>
                            
                        </Col>
                    </Row>
                    <Card className="box-content" role="main">
                        <Row>
                            <Col className="col-logo-ic" xs={{ span: 0, offset: 0 }} sm={{ span: 0, offset: 0 }} md={{ span: 5, offset: 4 }} lg={{ span: 6, offset: 2 }} style={{ marginTop: '19%' }}>
                            <img src={require(`../images/ic/logo.svg`).default} width= "70%" height="25%" alt='US Bank' />&nbsp;<br/>
                                <span className="instant-card" style={{color: "#19398A",fontSize: 40, fontWeight: 600, letterSpacing: 0, textAlign: 'left'}}>{this.props.label_keys[this.state.selectedLang].instantCard}</span>
                            </Col>
                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 12, offset: 4 }} lg={{ span: 12, offset: 2 }} style={{ marginTop: this.props.credentialsResetSuccess !== true && !this.props.credentialsResetError ? '8%' : '12%' }}>
                                
                                <Row>
                                    <Col xs={{ span: 8, offset: 12 }} md={{ span: 8, offset: 14 }} lg={{ span: 10, offset: 12 }}>
                                        {/*    <div className='loginIconDiv'> */} {/* <span><i className="icon-profile userIcon_font" style={{ fontWeight: "lighter", lineHeight: '2' }} /></span> */}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={{ span: 24, offset: 0 }} md={{ span: 19, offset: 5 }} lg={{ span: 14, offset: 10 }} style={{textAlign: "center"}}>
                                        <StyledH1 id='reset-pass-title' tabIndex={-1}> {this.props.label_keys[this.state.selectedLang].resetPassword}</StyledH1>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={{ span: 24, offset: 0 }} md={{ span: 19, offset: 5 }} lg={{ span: 14, offset: 10 }}>
                                        {
                                            requestType === 'otp' && this.props.credentialsResetSuccess !== true ? (
                                                <Alert
                                                    role='alert'
                                                    
                                                    style={{ marginBottom: '5px' }}
                                                    message={errorMessages.sendOtpSuccessMessage}
                                                    type="info" />
                                            ) : null
                                        }
                                        {
                                            this.props.credentialsResetError ?
                                                <Alert role='alert'
                                                    
                                                    message={
                                                        <div>
                                                            <span>
                                                                {this.props.credentialsResetError.error_description || this.props.credentialsResetError.message || errorMessages.unableToResetPasswordErrorMessage}
                                                                {this.props.app_labels.pleaseClick.split(" ")[0]}
                                                                <Link aria-label="Forgot password click here" to="/forgotPassword">&nbsp;<span ><u>{this.props.app_labels.pleaseClick.split(" ")[1]} {this.props.app_labels.here}</u></span> &nbsp;</Link>
                                                            </span>    
                                                            <span>{this.props.app_labels.toTryAgain}</span>
                                                        </div>
                                                        
                                                    }
                                                    type="error" />
                                                :
                                                this.props.credentialsResetSuccess === true ?
                                                    <Alert
                                                        
                                                        role='alert'
                                                        message={
                                                            <span>
                                                                {this.props.app_labels.passwordResetInfo}
                                                                <Link to="/login">&nbsp;<u>{this.props.app_labels.login}</u>&nbsp;</Link>
                                                                {this.props.app_labels.passwordResetInfo1}
                                                            </span>
                                                        }
                                                        type="success"
                                                    />
                                                    : null
                                        }
                                    </Col>
                                </Row>
                                {
                                    this.props.credentialsResetSuccess !== true && !this.props.credentialsResetError ?
                                        (
                                            <Row>
                                                <Col xs={{ span: 24, offset: 0 }} md={{ span: 19, offset: 5 }} lg={{ span: 14, offset: 10 }}>
                                                    <ResetPasswordFormComponent
                                                        app_labels = {this.props.app_labels}
                                                        onSubmit={(values) => {
                                                            this.props.resetPassword(values);
                                                        }}
                                                        isPasswordResetting={this.props.isPasswordResetting}
                                                        requestType={requestType}
                                                        resetPasswordStateReset={this.props.resetPasswordStateReset}
                                                    />
                                                </Col>
                                            </Row>
                                        )
                                        : null
                                }
                                <br /><br /><br /><br />
                            </Col>
                        </Row>
                    </Card>
                </div>
              
            )
        }
        else if (this.props.match.url === '/multifactor')
            return (this.props.label_keys &&
                <div className="container-header">
                    <Row role="banner">
                        <Col  xs={16} sm={16} md={20} lg={20} xl={20} offset={1} >
                            <span tabIndex={(this.props.match.url === '/login') ? null : 0} 
                                style={{cursor: (this.props.match.url === '/login') ? "auto" :"pointer", marginLeft: "9px"}}
                                role={(this.props.match.url === '/login') ? null : "link"}
                                aria-label={(this.props.match.url === '/login') ? null : "US Bank Instant Card"} onClick={this.redirectToLogin}>
                                <img src={require(`../images/provisioner/ic/logo.svg`).default} height="25px" alt='US Bank' />&nbsp;
                                <span className="instant-card-ic">|&nbsp;&nbsp;{this.props.label_keys[this.state.selectedLang].instantCard}</span>
                                {/* <span style={{ top: '10%', position: 'relative' }}>{this.props.label_keys[this.state.selectedLang].instantCard}</span> */}
                            </span>
                        </Col>
                        <Col xs={6} sm={1} md={1} offset={1}>
                            {/* <Select className='elan-select' value={this.state.selectedLang}
                                showArrow={false}
                                onChange={this.languageChange}>
                                {this.props.iclanguageList && this.props.iclanguageList.map(item =>{
                                    if(item.code == 'EN')  {return <Option value={item.code}>{item.code}</Option>}
                                    else {return <Option value={item.code}  disabled>{item.code}</Option>}
                                } 
                                )}
                                
                            </Select> */}
                            {/* <LanguageSelectBtn onChange={this.languageChange}
                                value={this.state.selectedLang}
                                iclanguageList={this.props.iclanguageList}
                                theme="elan"
                            />  */}
                        </Col>
                    </Row>
                    <Card className="box-content" role="main">
                        <Row>
                            <Col className="col-logo-ic" xs={{ span: 0, offset: 0 }} sm={{ span: 0, offset: 0 }} md={{ span: 5, offset: 4 }} lg={{ span: 6, offset: 2 }} style={{ marginTop: '19%' }}>
                            <img src={require(`../images/ic/logo.svg`).default} width= "70%" height="25%" alt='US Bank' />&nbsp;<br/>
                                <span className="instant-card" style={{color: "#19398A",fontSize: 40, fontWeight: 600, letterSpacing: 0, textAlign: 'left'}}>{this.props.label_keys[this.state.selectedLang].instantCard}</span>
                            </Col>
                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 12, offset: 4 }} lg={{ span: 12, offset: 2 }} style={{ marginTop: '4%' }}>
                                <Row>
                                    <Col xs={{ span: 24, offset: 0 }} md={{ span: 19, offset: 5 }} lg={{ span: 14, offset: 10 }}>
                                        <StyledH1 tabIndex={0}>{this.props.app_labels.multiFactor}</StyledH1>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col  tabIndex={0} xs={{ span: 24, offset: 0 }} md={{ span: 19, offset: 5 }} lg={{ span: 14, offset: 10 }}>
                                        <Alert
                                            
                                            role='alert'
                                            style={{ marginBottom: '5px' }}
                                            message={errorMessages.mfaInfoMessage}
                                            type="info" />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={{ span: 24, offset: 0 }} md={{ span: 19, offset: 5 }} lg={{ span: 14, offset: 10 }}>

                                        {
                                            this.props.authenticationError ?
                                                /* <Alert
                                                    message={this.props.authenticationError.error_description || this.props.authenticationError.message || "Authentication failed."}
                                                    type="error" /> */
                                                <div   role='alert' style={{ color: "#C92323" }}>
                                                    
                                                    {this.props.authenticationError.error_description || this.props.authenticationError.message || errorMessages.loginGenericErrorMessage}</div>
                                                :
                                                null
                                        }
                                    </Col>
                                </Row>
                                <MFAFormComponent
                                    app_labels = {this.props.app_labels}
                                    onSubmit={(values) => {
                                        this.props.login(values, 'mfa');
                                    }}
                                    isAuthenticating={this.props.isAuthenticating} />
                                    <br /><br /><br /><br />
                                
                            </Col>
                        </Row>
                    </Card>
                </div> 
                
            )
        else if (this.props.match.url === '/register' || this.props.match.url === '/forgotPassword') {
            return ( this.props.label_keys &&
                <div className="container-header">
                    <Row role="banner">
                        <Col  xs={16} sm={16} md={20} lg={20} xl={20} offset={1} >
                            <span tabIndex={(this.props.match.url === '/login') ? null : 0} 
                                style={{cursor: (this.props.match.url === '/login') ? "auto" :"pointer", marginLeft: "9px"}}
                                role={(this.props.match.url === '/login') ? null : "link"}
                                aria-label={(this.props.match.url === '/login') ? null : "US Bank Instant Card"} onClick={this.redirectToLogin}>
                                <img src={require(`../images/provisioner/ic/logo.svg`).default} height="25px" alt='US Bank' />&nbsp;
                                <span className="instant-card-ic">|&nbsp;&nbsp;{this.props.label_keys[this.state.selectedLang].instantCard}</span>
                                {/* <span style={{ top: '10%', position: 'relative' }}>{this.props.label_keys[this.state.selectedLang].instantCard}</span> */}
                            </span>
                        </Col>
                        <Col xs={6} sm={1} md={1} offset={1}>
                            {/* <Select className='elan-select' value={this.state.selectedLang}
                                showArrow={false}
                                onChange={this.languageChange}>
                                {this.props.iclanguageList && this.props.iclanguageList.map(item =>{
                                    if(item.code == 'EN')  {return <Option value={item.code}>{item.code}</Option>}
                                    else {return <Option value={item.code}  disabled>{item.code}</Option>}
                                } 
                                )}
                                
                            </Select> */}
                            {/* <LanguageSelectBtn onChange={this.languageChange}
                                value={this.state.selectedLang}
                                iclanguageList={this.props.iclanguageList}
                                theme="elan"
                            />  */}
                        </Col>
                    </Row>
                    <Card className="box-content" role="main">
                        <Row>
                            <Col className="col-logo-ic" xs={{ span: 0, offset: 0 }} sm={{ span: 0, offset: 0 }} md={{ span: 5, offset: 4 }} lg={{ span: 6, offset: 3 }} style={{ marginTop: '19%' }}>
                            <img src={require(`../images/ic/logo.svg`).default} width= "70%" height="25%" alt='US Bank' />&nbsp;<br/>
                                <span className="instant-card" style={{color: "#19398A",fontSize: 40, fontWeight: 600, letterSpacing: 0, textAlign: 'left'}}>{this.props.label_keys[this.state.selectedLang].instantCard}</span>
                            </Col>
                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 12, offset: 4 }} lg={{ span: 12, offset: 1 }} style={{ marginTop: '10%' }}>
                                <Row>
                                    <h1 className="visually-hidden">{this.props.match.url === '/register'? "Register" : "Forgot Password"}</h1>
                                    <OTPValidationFormComponent
                                        app_labels = {this.props.label_keys[this.state.selectedLang]}
                                        type={this.props.match.url.slice(1)}
                                        sendOTPRequest={this.props.sendOTPRequest}
                                        sendOTPSuccess={this.props.sendOTPSuccess}
                                        sendOTPError={this.props.sendOTPError}
                                        verifyOTPRequest={this.props.verifyOTPRequest}
                                        verifyOTPSuccess={this.props.verifyOTPSuccess}
                                        verifyOTPError={this.props.verifyOTPError}
                                        activateAccountStateReset={this.props.activateAccountStateReset}
                                        sendOTP={(e) => this.props.sendOTP(e)}
                                        verifyOTP={(e) => this.props.verifyOTP(e)}
                                        resetPasswordStateReset={this.props.resetPasswordStateReset}
                                    />
                                    <br /><br /><br /><br />
                                </Row>
                                
                            </Col>
                        </Row>
                    </Card>
                </div> 
                
            )
        }
        else if (this.props.match.url === '/openSourceLicenses') {
            return ( this.props.label_keys &&
                <div className="container-header">
                    <Row role="banner">
                        <Col  xs={16} sm={16} md={20} lg={20} xl={20} offset={1} >
                            <span tabIndex={(this.props.match.url === '/login') ? null : 0} 
                                style={{cursor: (this.props.match.url === '/login') ? "auto" :"pointer", marginLeft: "9px"}}
                                role={(this.props.match.url === '/login') ? null : "link"}
                                aria-label={(this.props.match.url === '/login') ? null : "US Bank Instant Card"} onClick={this.redirectToLogin}>
                                <img src={require(`../images/provisioner/ic/logo.svg`).default} height="25px" alt='US Bank' />&nbsp;
                                <span className="instant-card-ic">|&nbsp;&nbsp;{this.props.label_keys[this.state.selectedLang].instantCard}</span>
                                {/* <span style={{ top: '10%', position: 'relative' }}>{this.props.label_keys[this.state.selectedLang].instantCard}</span> */}
                            </span>
                        </Col>
                        <Col xs={6} sm={1} md={1} offset={1}>
                            {/* <Select className='elan-select' value={this.state.selectedLang}
                                showArrow={false}
                                onChange={this.languageChange}>
                                {this.props.iclanguageList && this.props.iclanguageList.map(item =>{
                                    if(item.code == 'EN')  {return <Option value={item.code}>{item.code}</Option>}
                                    else {return <Option value={item.code}  disabled>{item.code}</Option>}
                                } 
                                )}
                                
                            </Select> */}
                            {/* <LanguageSelectBtn onChange={this.languageChange}
                                value={this.state.selectedLang}
                                iclanguageList={this.props.iclanguageList}
                                theme="elan"
                            />  */}
                        </Col>
                    </Row>
                    <Card className="box-content" role="main">
                        <Row>
                            <Col className="col-logo-ic" xs={{ span: 0, offset: 0 }} sm={{ span: 0, offset: 0 }} md={{ span: 5, offset: 4 }} lg={{ span: 6, offset: 3 }} style={{ marginTop: '19%' }}>
                            <img src={require(`../images/ic/logo.svg`).default} width= "70%" height="25%" alt='US Bank' />&nbsp;<br/>
                                <span className="instant-card" style={{color: "#19398A",fontSize: 40, fontWeight: 600, letterSpacing: 0,textAlign: 'left'}}>{this.props.label_keys[this.state.selectedLang].instantCard}</span>
                            </Col>
                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 12, offset: 4 }} lg={{ span: 12, offset: 1 }} style={{ marginTop: '4%' }}>
                                <Row>
                                    <h1 className="visually-hidden">Open Source Licenses</h1>
                                    <OpenSourceLicense
                                        label_keys = {this.props.label_keys[this.state.selectedLang]}
                                    />
                                </Row>
                                
                            </Col>
                        </Row>
                    </Card>
                </div> 
                
            )
        }
        else
            return ( this.props.label_keys &&
                <div className="container-header">
                    <Row role="banner">
                        <Col  xs={16} sm={16} md={20} lg={20} xl={20} offset={1} >
                            <span tabIndex={(this.props.match.url === '/login') ? null : 0} 
                                style={{cursor: (this.props.match.url === '/login') ? "auto" :"pointer", marginLeft: "9px"}}
                                role={(this.props.match.url === '/login') ? null : "link"}
                                aria-label={(this.props.match.url === '/login') ? null : "US Bank Instant Card"} onClick={this.redirectToLogin}>
                                <img src={require(`../images/provisioner/ic/logo.svg`).default} height="25px" alt='US Bank' />&nbsp;
                                <span className="instant-card-ic">|&nbsp;&nbsp;{this.props.label_keys[this.state.selectedLang].instantCard}</span>
                                {/* <span style={{ top: '10%', position: 'relative' }}>{this.props.label_keys[this.state.selectedLang].instantCard}</span> */}
                            </span>
                        </Col>
                        <Col xs={6} sm={1} md={1} offset={1}>
                            {/* <Select className='elan-select' value={this.state.selectedLang}
                                showArrow={false}
                                onChange={this.languageChange}>
                                {this.props.iclanguageList && this.props.iclanguageList.map(item =>{
                                    if(item.code == 'EN')  {return <Option value={item.code}>{item.code}</Option>}
                                    else {return <Option value={item.code}  disabled>{item.code}</Option>}
                                } 
                                )}
                                
                            </Select> */}
                            {/* <LanguageSelectBtn onChange={this.languageChange}
                                value={this.state.selectedLang}
                                iclanguageList={this.props.iclanguageList}
                                theme="elan"
                            />  */}
                        </Col>
                    </Row>
                    <Card className="box-content" role="main">
                        <Row>
                            <Col className="col-logo-ic" xs={{ span: 0, offset: 0 }} sm={{ span: 0, offset: 0 }} md={{ span: 5, offset: 4 }} lg={{ span: 6, offset: 2 }} style={{ marginTop: '19%' }}>
                            <img src={require(`../images/ic/logo.svg`).default} width= "70%" height="25%" alt='US Bank' /><br/>
                                <span className="instant-card" style={{color: "#19398A",fontSize: 40, fontWeight: 600, letterSpacing: 0, textAlign: 'left'}}>{this.props.label_keys[this.state.selectedLang].instantCard}</span>
                            </Col>
                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 12, offset: 4 }} lg={{ span: 12, offset: 2 }} style={{ marginTop: '4%' }}>
                                <Row>
                                    <LoginFormComponent label_keys={this.props.label_keys[this.state.selectedLang]} onSubmit={(values, rmCheck) => {
                                        this.props.login(values, rmCheck);
                                        }}
                                        isAuthenticating={this.props.isAuthenticating}
                                        authenticationError={this.props.authenticationError}
                                        selectedLang={this.state.selectedLang}
                                        sendOTP={(e) => this.props.sendOTP(e)}
                                        credentialsResetError={this.props.credentialsResetError}
                                        credentialsResetSuccess={this.props.credentialsResetSuccess} />
                                </Row>
                                {/* {(this.props.match.url === '/resetPassword') ?
                                    <div>
                                        <Row>
                                            {
                                                requestType === 'otp' && this.props.credentialsResetSuccess !== true ? (
                                                    <Alert
                                                        style={{ marginBottom: '5px' }}
                                                        message={errorMessages.sendOtpSuccessMessage}
                                                        type="info" />
                                                ) : null
                                            }
                                            {
                                                this.props.credentialsResetError || this.props.credentialsResetSuccess === true ? this.props.history.push('/login')
                                                    : null
                                            }
                                        </Row>
                                        {
                                            this.props.credentialsResetSuccess !== true && !this.props.credentialsResetError ?
                                                (
                                                    <Row>
                                                        <ResetPasswordFormComponent
                                                            label_keys={this.props.label_keys[this.state.selectedLang]}
                                                            onSubmit={(values) => {
                                                                this.props.resetPassword(values);
                                                            }}
                                                            isPasswordResetting={this.props.isPasswordResetting}
                                                            requestType={requestType}
                                                            resetPasswordStateReset={this.props.resetPasswordStateReset}
                                                        />
                                                    </Row>
                                                ) : null
                                        }
                                    </div> :
                                    (this.props.match.url === '/forgotPassword') ?
                                        <ElanResetPassword
                                            label_keys={this.props.label_keys[this.state.selectedLang]}
                                            sendOTPRequest={this.props.sendOTPRequest}
                                            sendOTPSuccess={this.props.sendOTPSuccess}
                                            sendOTPError={this.props.sendOTPError}
                                            verifyOTPRequest={this.props.verifyOTPRequest}
                                            verifyOTPSuccess={this.props.verifyOTPSuccess}
                                            verifyOTPError={this.props.verifyOTPError}
                                            activateAccountStateReset={this.props.activateAccountStateReset}
                                            sendOTP={(e) => this.props.sendOTP(e)}
                                            verifyOTP={(e) => this.props.verifyOTP(e)}
                                            resetPasswordStateReset={this.props.resetPasswordStateReset}
                                        /> :

                                        (this.props.match.url === '/register') ?
                                            <RegisterFormComponent
                                                label_keys={this.props.label_keys[this.state.selectedLang]}
                                                selectedLang={this.state.selectedLang}
                                                sendOTP={(e) => this.props.sendOTP(e)}
                                            /> :
                                            <Row>
                                                <LoginFormComponent label_keys={this.props.label_keys[this.state.selectedLang]} onSubmit={(values, rmCheck) => {
                                                    this.props.login(values, rmCheck);
                                                }}
                                                    isAuthenticating={this.props.isAuthenticating}
                                                    authenticationError={this.props.authenticationError}
                                                    selectedLang={this.state.selectedLang}
                                                    sendOTP={(e) => this.props.sendOTP(e)}
                                                    credentialsResetError={this.props.credentialsResetError}
                                                    credentialsResetSuccess={this.props.credentialsResetSuccess} />
                                            </Row> */}
                                {/* } */}
                            </Col>
                        </Row>
                    </Card>
                </div>     
            
            )


    }
}

const mapStateToProps = (state) => {
    return {
        isAuthenticating: state.authenticationReducer.isAuthenticating,
        isAuthenticated: state.authenticationReducer.isAuthenticated,
        isCredentialsResetting: state.authenticationReducer.isCredentialsResetting,
        authenticationError: state.authenticationReducer.authenticationError,
        credentialsResetError: state.authenticationReducer.credentialsResetError,
        credentialsResetSuccess: state.authenticationReducer.credentialsResetSuccess,
        isForgettingCredentials: state.authenticationReducer.isForgettingCredentials,
        forgotCredentialsSuccess: state.authenticationReducer.forgotCredentialsSuccess,
        forgotCredentialsError: state.authenticationReducer.forgotCredentialsError,
        sendOTPRequest: state.authenticationReducer.sendOTPRequest,
        sendOTPSuccess: state.authenticationReducer.sendOTPSuccess,
        sendOTPError: state.authenticationReducer.sendOTPError,
        verifyOTPRequest: state.authenticationReducer.verifyOTPRequest,
        verifyOTPSuccess: state.authenticationReducer.verifyOTPSuccess,
        verifyOTPError: state.authenticationReducer.verifyOTPError,
        app_labels: state.adduserReducer.app_labels,
        iclanguageList: state.instantCardMultiLanReducer.iclanguageList,
        label_keys: state.instantCardMultiLanReducer.label_keys
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchListLangauages: () => {
            dispatch(fetchListLangauages())
        },
        multiLanguageLabelRetrieve: (values) => {
            dispatch(multiLanguageLabelRetrieve(values))
        },
        fetchinstantCardListLangauages: () => {
            dispatch(fetchinstantCardListLangauages())
        },
        instantCardLabelRetrieve: (type) => {
            dispatch(instantCardLabelRetrieve(type))
        },
        login: (values, rmCheck, grantType, touch_token) => {
            dispatch(login(values, grantType, touch_token, rmCheck))
        },
        resetPassword: (values) => {
            dispatch(resetPassword(values))
        },
        forgotPassword: (values) => {
            dispatch(forgotPassword(values))
        },
        forgotPasswordStateReset: () => {
            dispatch(forgotPasswordStateReset())
        },
        resetPasswordStateReset: () => {
            dispatch(resetPasswordStateReset())
        },
        activateAccountStateReset: () => {
            dispatch(activateAccountStateReset())
        },
        sendOTP: (values) => {
            dispatch(sendOTP(values))
        },
        verifyOTP: (values) => {
            dispatch(verifyOTP(values))
        }
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AuthComponent));