import moment from 'moment';

export default function isApproveBtn(thresholdFlag, cardLimitRule, validityDays, data) {

    function sumOfCardLimit(data) {
        return data.reduce((a, b) => a + (parseInt(b['creditLimit']) || 0), 0);
    }

    function dataValidity(data, validityDays) {

        for (let i = 0; i < data.length; i++) {
            if (validityDays !== null) {
                let fromDateChk = moment(data[i]['validFrom']);
                let tillDateChk = moment(data[i]['validTill']);
                let bothDaysDiff = tillDateChk.diff(fromDateChk, 'days');                
                if(bothDaysDiff > validityDays)  return true;
            }
        }
        return false;
        
    }

    switch (thresholdFlag) {
        case 1: return sumOfCardLimit(data) > cardLimitRule ? true : false;
        case 2: return dataValidity(data, validityDays);
        case 3: return (sumOfCardLimit(data) > cardLimitRule) && (dataValidity(data, validityDays));
        case 4: return (sumOfCardLimit(data) > cardLimitRule) || (dataValidity(data, validityDays));
        default: {
            return false;
        }
    }
}