import axios from 'axios';
const { URLs } = require('../data/constants.js')


/**
 * Axios call to post a logoutRequest
 */
export function logOutAPIcall() {
    return function (dispatch, getState) {
        let {
            token,
        } = getState().authenticationReducer
        dispatch(logOutRequest());
        return axios.post(URLs.userUrl + 'logout', null, {
            headers: {
                "Authorization": 'Bearer ' + token.access_token
            }
        })
        .then((response) => window.pendo.clearSession() && response)
        .catch((err) => { throw err })
    }
}

export function logOutRequest(){
    return{
        type:"LOG_OUT_REQUEST"
    }
}

export function logOutSuccess(){
    window.sessionStorage.setItem("LOGIN_STATUS",false);
    return{
        type:"LOG_OUT_SUCCESS",
        data: 'Successfully logged out'
    }
}

export function logOutError(){
    return{
        type:"LOG_OUT_FAIL",
        data: 'Logged out failure'
    }
}

export function logOut() {
    return function (dispatch) {
        dispatch(logOutAPIcall())
        .then((response) => {
            dispatch(logOutSuccess(response));
        }).catch(error => {
            dispatch(logOutError(error));
        })
    }
}