/* eslint-disable no-unused-expressions */
import React from 'react';
// import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
// import store from '../../../store/store.js';
import $ from 'jquery';
import 'antd/dist/antd.css';
import PhoneCode from '../../phonecode.js';
// import '../../../styling/createtrip.css';
import axios from 'axios';
import { CreateNewProfile, CreateMore } from '../../../actions/AdminActions/createprofileActions'
import { notification, Spin, Select } from 'antd';
import { loaderFocus } from '../../../utils/accessability';
const { URLs, errorMessages, roleIds } = require('../../../data/constants.js');
// const { app_labels } = require('../../../data/english_label');
const Option = Select.Option;
var profileobj;
class CreateNewUserProfileComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            phCode1: '1',
            phCode2: '1',
            role: null,
            travelerId: null,
            reportToId: null,
            disableFlag: false,
            dataSave: false,
            company: null,
            companyName: [],
        }
    }
    componentWillUnmount() {
        this.setState({ travelerId: null })
        this.props.createmore(false);
    }
    /**
     * Lifecycle method
     * will be called onMount of the component
     */
    componentDidMount() {
        let URL = "activeRegisteredCompanies";
        var arraynew = [];
        if (this.props.selectedRole === "Company Admin") {
            this.setState({ company: this.props.companyDetails.companyId, })

            //URL = URL + "/" + this.props.userDetails.companyId;
            arraynew.push(this.props.companyDetails);
            this.setState({ companyName: arraynew })
        }
        else {
            axios.get(URLs.companyUrl + URL, {
                responseType: 'Json',
                headers: {
                    "Authorization": 'Bearer ' + this.props.token.access_token
                }
            }).then(response => {
                if (response.data.serviceStatus.statusCode === 200) {
                    arraynew = [];
                    if (response.data.data.length === undefined) {
                        arraynew.push(response.data.data);
                    }
                    else if (response.data.data.length === 1) {
                        arraynew = response.data.data;
                        //this.setState({ company: response.data.data[0].companyId })
                    }
                    else {
                        arraynew = response.data.data;
                    }
                    this.setState({ companyName: arraynew })
                }
                else if (response.data.serviceStatus.statusCode === 210) {
                    /* message.error(response.data.serviceStatus.message); */
                    notification.error({
                        message: <span role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{this.props.app_labels.couldNotcompleteReq}</span>,
                        duration: 3,
                        style: {
                            backgroundColor: '#fff1f0',
                            border: "1px solid #ffa39e",
                        }
                    });
                }
                else {
                    notification.error({
                        message: <span role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{this.props.app_labels.error_fetchCompany}</span>,
                        duration: 3,
                        style: {
                            backgroundColor: '#fff1f0',
                            border: "1px solid #ffa39e",
                        }
                    });
                }
            }).catch(error => {
                if (error.response !== undefined && error.response.data !== null && error.response.data !== undefined && error.response.data.serviceStatus !== undefined) {
                    notification.error({
                        message: <span role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{this.props.app_labels.couldNotcompleteReq}</span>,
                        duration: 3,
                        style: {
                            backgroundColor: '#fff1f0',
                            border: "1px solid #ffa39e",
                        }
                    });
                }
                else {
                    notification.error({
                        message: <span role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{this.props.app_labels.couldNotcompleteReq}</span>,
                        duration: 3,
                        style: {
                            backgroundColor: '#fff1f0',
                            border: "1px solid #ffa39e",
                        }
                    });
                }
            })
        }

        // prepare the icons
        let element = $('.ant-select-arrow-icon')
        element.empty()
        element.removeClass().addClass('icon-Drop-down')

        const div = document.querySelectorAll('.ant-checkbox-input');

        if (div[0]) {
            div.forEach(el => {
                if (!el.getAttribute('aria-label')) {
                    el.setAttribute('aria-label', 'Select all');
                    el.setAttribute('id', 'Select-all-checkbox');
                }
            })
        }

    }

    componentDidUpdate(){
        const icon = document.querySelectorAll('.icon-Drop-down');
        if (icon) {
            icon.forEach(el => {
                el.setAttribute('role', 'img')
                el.setAttribute('aria-hidden', 'true')
            })
        };
        const dropDown = document.querySelectorAll('.ant-select-selection.ant-select-selection--single');
        if (dropDown[0]) {
            dropDown.forEach(div => {
                
                div.addEventListener('keyup', function () {
                    // console.log("Event on click fired");
    
                    let optionDiv = document.querySelectorAll('.ant-select-dropdown-menu-item.ant-select-dropdown-menu-item-active');
                    // console.log("Div:::", optionDiv);
                    if (optionDiv[0]) {
                        let activeDescendant = optionDiv[0].getAttribute('id')
                        //  console.log("active descendant::", activeDescendant);   
                        div.setAttribute('aria-activedescendant', activeDescendant);
                    }
                });
            })

        }
    }

    /**
     * @param  {} e
     * create more allows to create more users
     */
    createMore = (e) => {
        e.preventDefault();
        this.setState({ travelerId: null })
        this.props.createmore(false);
    }
    /**
    * @param  {} element
    highlighting the field when its epmty or not valid
    */
    highlightInvalidField(element) {
        let label = $(element).siblings('.text-class')
        label = label.length === 0 ? $(element).find('.text-class') : label
        label = label.length === 0 ? $(element).closest('.form-group').find('.text-class') : label
        label.addClass('has-error')
        element.focus()
    }

    /**
    * @param  {} element
    *removing the error class applied during validation on change of event
    */
    clearInvalidField(element) {
        let label = $(element).siblings('.text-class')
        label = label.length === 0 ? $(element).find('.text-class') : label
        label = label.length === 0 ? $(element).closest('.form-group').find('.text-class') : label
        label.removeClass('has-error')
    }
    /**
     * @param  {} value
     * sets the selected role to state variable
     */
    roleChange = (value) => {
        let selector = document.getElementsByClassName("roleSelect");
        this.clearInvalidField(selector)
        this.setState({ role: value })
    }
    /**
     * @param  {} value
     * sets the selected company to state variable
     */
    companyChange = (value) => {
        let companySelector = document.getElementsByClassName("companySelect");
        this.clearInvalidField(companySelector)
        this.setState({ company: value })
    }

    /**
    * @param  {} e
    * creates user profile
    */
    createProfile = (e) => {
        var selector = document.getElementsByClassName("roleSelect");
        var companySelector = document.getElementsByClassName("companySelect");
        [
            this.refs.cadreId,
            this.refs.managerId,
            selector,
            companySelector,
            this.refs.email,
            this.refs.firstName,
            this.refs.lastName,
            this.refs.mobNum,
            this.refs.altMobNum].forEach(el => this.clearInvalidField(el))
        e.preventDefault();
        if (this.refs.cadreId.value === "" || this.refs.cadreId.value == null) {
            this.highlightInvalidField(this.refs.cadreId)
            notification.warning({
                message: <span role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{this.props.app_labels.error_req_expenseWizardId}</span>,
                duration: 3,
                style: {
                    backgroundColor: '#fffbe6',
                    border: "1px solid #ffe58f",
                }
            });
            return;
        }
        if (this.state.role === "" || this.state.role == null) {
            let label = $(selector).siblings('.text-class')
            label.addClass('has-error');
            /*  let element = $(selector).find('ant-select-selection__placeholder');
             element.click() 
            message.warning("Please Select Job Role");*/
            notification.warning({
                message: <span role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{this.props.app_labels.error_req_jobRole}</span>,
                duration: 3,
                style: {
                    backgroundColor: '#fffbe6',
                    border: "1px solid #ffe58f",
                }
            });
            return;
        }
        if (this.refs.email.value === "" || this.refs.email.value == null) {
            this.highlightInvalidField(this.refs.email)
            notification.warning({
                message: <span role='alert' aria-live="assertive" aria-atomic="true" ><span className="visually-hidden">Error </span>{this.props.app_labels.error_req_emailId}</span>,
                duration: 3,
                style: {
                    backgroundColor: '#fffbe6',
                    border: "1px solid #ffe58f",
                }
            });
            return;
        }
        let emailRegExp = /^\w+([+.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,64})+$/;
        if (emailRegExp.test(this.refs.email.value) === false) {
            this.highlightInvalidField(this.refs.email)
            notification.warning({
                message: <span role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{this.props.app_labels.error_incorrectEmail}</span>,
                duration: 3,
                style: {
                    backgroundColor: '#fffbe6',
                    border: "1px solid #ffe58f",
                }
            });
            return;
        }
        if (this.refs.firstName.value === "" || this.refs.firstName.value == null) {
            this.highlightInvalidField(this.refs.firstName)
            notification.warning({
                message: <span role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{this.props.app_labels.error_req_firstName}</span>,
                duration: 3,
                style: {
                    backgroundColor: '#fffbe6',
                    border: "1px solid #ffe58f",
                }
            });
            return;
        }

        if (this.refs.lastName.value === "" || this.refs.lastName.value == null) {
            this.highlightInvalidField(this.refs.lastName)
            notification.warning({
                message: <span role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{this.props.app_labels.error_req_lasttName}</span>,
                duration: 3,
                style: {
                    backgroundColor: '#fffbe6',
                    border: "1px solid #ffe58f",
                }
            });
            return;
        }
        if ((this.state.company === "" || this.state.company == null) && this.props.selectedRole === "Super Admin") {
            let label = $(companySelector).siblings('.text-class')
            label.addClass('has-error');
            notification.warning({
                message: <span role='alert' aria-live="assertive" aria-atomic="true" ><span className="visually-hidden">Error </span>{this.props.app_labels.error_req_companyName}</span>,
                duration: 3,
                style: {
                    backgroundColor: '#fffbe6',
                    border: "1px solid #ffe58f",
                }
            });
            return;
        }
        if (this.refs.mobNum.value === "" || this.refs.mobNum.value == null) {
            this.highlightInvalidField(this.refs.mobNum)
            notification.warning({
                message: <span role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{this.props.app_labels.error_req_phnum}</span>,
                duration: 3,
                style: {
                    backgroundColor: '#fffbe6',
                    border: "1px solid #ffe58f",
                }
            });
            return;
        }
        let phoneNoValue = this.refs.mobNum.value
        let phoneNoValue1 = ""
        for (let i = 0; i < phoneNoValue.length; i++) {
            if (phoneNoValue[i] !== ' ') {
                phoneNoValue1 = phoneNoValue1 + phoneNoValue[i]
            }
            else {
                continue;
            }
        }
        let validPhNo = this.validatePhoneNumber(phoneNoValue1);
        if (validPhNo === false) {
            this.highlightInvalidField(this.refs.mobNum)
            return;
        }
        var altphNum = null;
        let altphoneNoValue = this.refs.altMobNum.value
        let altphoneNoValue1 = ""
        if (this.refs.altMobNum.value !== "") {
            for (let i = 0; i < altphoneNoValue.length; i++) {
                if (altphoneNoValue[i] !== ' ') {
                    altphoneNoValue1 = altphoneNoValue1 + altphoneNoValue[i]
                }
                else {
                    continue;
                }
            }
            altphNum = this.state.phCode2 + " " + altphoneNoValue1;
            let validAltPhNo = this.validatePhoneNumber(altphoneNoValue1,);
            if (validAltPhNo === false) {
                this.highlightInvalidField(this.refs.altMobNum)
                return;
            }
        }
        if ((this.state.role !== roleIds.CardCompanyAdmin && this.state.role !== roleIds.Provisioner && this.state.role !== roleIds.Approver) && (this.refs.managerId.value === "" || this.refs.managerId.value == null)) {
            this.highlightInvalidField(this.refs.managerId)
            notification.warning({
                message: <span role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{this.props.app_labels.error_req_reportToId}</span>,
                duration: 3,
                style: {
                    backgroundColor: '#fffbe6',
                    border: "1px solid #ffe58f",
                }
            });
            return;
        }
        this.setState({ dataSave: true })
        loaderFocus(".loader-color .ant-spin-text"); 
        console.log("0000000", this.props)
        profileobj = {
            data: {
                "cadreId": this.refs.cadreId.value,
                "companyId": this.state.company ? this.state.company + "" : this.props.userDetails.companyId + "",
                "username": this.refs.email.value,
                "firstName": this.refs.firstName.value,
                "lastName": this.refs.lastName.value,
                "email": this.refs.email.value,
                "phoneNo": this.state.phCode1 + " " + phoneNoValue1,
                "reportToId": this.refs.managerId.value,
                "roles": [parseInt(this.state.role)],
                "frequentTraveler": true,
                "activeStatus": true,
                "isCrRegistered": false,
                "selectedRole": this.props.selectedRole,
            },
            "type": "employee"
        }
        if (altphNum !== null) {
            profileobj.data.altPhoneNo = altphNum
        }
        this.props.createprofile(profileobj, this.updateState, this.props.app_labels.userCreatedSuccessfully, this.props.app_labels.error_userProfile);
    }

    /**
     * Updates the required state
     */
    updateState = (value) => {
        this.setState({ dataSave: value })
    }
    /**
    * @param  {} phoneNumber
    * validation of phone number
    */
    validatePhoneNumber = (phoneNumber) => {
        let valid = false;
        try {
            /* const phoneUtil = PhoneNumberUtil.getInstance();
               valid = phoneUtil.isValidNumber(phoneUtil.parse(phoneNumber)); */
            let regexp = /(\d)\1{9}/g;
            if (phoneNumber.length !== 10 || phoneNumber.indexOf("555") === 0 || regexp.test(phoneNumber) === true) {
                valid = false
            }
            else {
                valid = true;
            }
        } catch (e) {
            valid = false;
        }
        if (valid) {
            return true
        } else {
            notification.warning({
                message: <span role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{this.props.app_labels.error_invalidPhno}</span>,
                duration: 3,
                style: {
                    backgroundColor: '#fffbe6',
                    border: "1px solid #ffe58f",
                }
            });
            return false;
        }
    }
    /**
   * @param  {} =>{axios.get(URLs.comUrl+"candidate/id"
   * generating candidiate Id for new Candidate
   */
    uniqueIdgen = () => {
        this.setState({ dataSave: true, disableFlag: true })
        loaderFocus(".loader-color .ant-spin-text"); 
        axios.get(URLs.adminUrl + "cadreId", {
            params: {
                'type': 'employee'
            },
            responseType: 'json',
            headers: {
                "Authorization": 'Bearer ' + this.props.token.access_token
            }
        }).then(response => {
            if (response.data.serviceStatus.statusCode === 200) {
                this.setState({ travelerId: response.data.data.cadreId, dataSave: false, disableFlag: false });
                this.refs.email.focus()
                notification.success({
                    message: <span role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Success </span>{response.data.serviceStatus.message}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#dff0d8',
                        color: "green",
                        border: "1px solid green",
                    }
                });
            }
            else if (response.data.serviceStatus.statusCode === 210) {
                this.setState({ dataSave: false, disableFlag: false });
                this.refs.email.focus()
                notification.error({
                    message: <span role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{this.props.app_labels.couldNotcompleteReq}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }
            else {
                this.setState({ dataSave: false, disableFlag: false });
                this.refs.email.focus()
                notification.error({
                    message: <span role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{this.props.app_labels.error_fetchCandidateId}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }
        }).catch((error) => {
            this.setState({ dataSave: false, disableFlag: false });
            this.refs.email.focus()
            if (error.response !== undefined && error.response.data !== null && error.response.data !== undefined && error.response.data.serviceStatus !== undefined) {
                notification.error({
                    message: <span role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{this.props.app_labels.couldNotcompleteReq}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }
            else {
                notification.error({
                    message: <span role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{this.props.app_labels.couldNotcompleteReq}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }
        })
    }

    /**
     * Checks if the character passed is a digit
     * @param {*} character 
     */
    isDigit(character) {
        return '0123456789'.indexOf(character) === -1 ? false : true
    }

    /**
     * Formats the Mobile number as per the covention
     * @param {*} input 
     */
    formatMobileNumber(input) {
        input = input.substring(0, 12)
        let result = []
        // let divisionCount = 0
        for (let i = 0; i < input.length; i++) {
            if (!this.isDigit(input[i])) {
                continue
            }
            result.push(input[i])
        }
        switch (true) {
            case result.length > 6 && result.length <= 10:
                result.splice(3, 0, ' ')
                result.splice(7, 0, ' ')
                break;
            case result.length > 3 && result.length <= 6:
                result.splice(3, 0, ' ')
                break;
            default:
        }
        return result.join('')
    }
    render() {
        if (!this.props.profileCreated) {
            return (
                <div>
                    <div className="create-trip-container">
                        <form className="form-inline" onSubmit={this.createProfile}>
                            <div className="row">
                                <div className="col-sm-3 col-sm-offset-1">
                                    <div className="form-group"><label for="expenseWizardId" className="text-class">{this.props.app_labels.expenseWizardId} <span className="required">*</span></label>
                                        <br /><input id="expenseWizardId" className="createNewUserProfile-Inputs expense-wizard-id" required value={this.state.travelerId} type="text" ref="cadreId" placeholder={this.props.app_labels.enterExpenseWizardId} /* disabled={true} */
                                            onChange={(e) => this.clearInvalidField(e.target)}
                                            onKeyPress={(event) => {
                                                if (event.key.replace(/[^\w\-.]/g, '') === '') event.preventDefault();
                                            }} />
                                    </div>
                                </div>
                                <div className="col-sm-3 col-sm-offset-0 spacebtwn-columns">
                                    <div className="form-group">
                                        <label id="jobRole" className="text-class">{this.props.app_labels.jobRole} <span className="required">*</span></label>
                                        <br />
                                        <Select aria-required="true" aria-labelledby="jobRole" style={{ marginTop: '2%' }} onChange={this.roleChange} className={`roleSelect ${this.props.selectedLanguage === "FR"? 'roleSelect-french': ''}`} placeholder={this.props.app_labels.selectRoleFromList} >
                                            {this.props.cadreRoles.map(item =>
                                                <Option value={item.roleId} id={item.roleId}>{item.roleDesc}</Option>
                                            )}
                                        </Select>
                                    </div>
                                </div>
                                <div className="col-sm-3 spacebtwn-columns">
                                    <div className="form-group"> <label for="emailId" className="text-class">{this.props.app_labels.email} <span className="required">*</span></label>
                                        <br />
                                        <input required id="emailId" type="email" ref="email" placeholder={this.props.app_labels.emailPlaceholder}
                                            disabled={this.state.disableFlag} 
                                            className="createNewUserProfile-Inputs"
                                            autocomplete="email"
                                            onChange={(e) => {
                                                /* if ((this.refs.cadreId.value === "" || this.refs.cadreId.value === null || this.refs.cadreId.value === undefined)) {
                                                    this.uniqueIdgen();
                                                } */
                                                this.clearInvalidField(e.target)
                                            }} />
                                    </div>
                                </div>
                            </div>
                            <div className="row spacebtwn-rows">
                                <div className="col-sm-3 col-sm-offset-1">
                                    <div className="form-group"> <label for="firstName1" className="text-class">{this.props.app_labels.firstName} <span className="required">*</span></label>
                                        <br /><input required id="firstName1" className="createNewUserProfile-Inputs" type="text" ref="firstName" placeholder={this.props.app_labels.enterFirstNameHere}
                                            onKeyPress={(event) => {
                                                if (event.key.replace(/[^\w\-.]/g, '') === '') event.preventDefault();
                                            }
                                            }
                                            onChange={(e) => this.clearInvalidField(e.target)}
                                            autocomplete="given-name" />
                                    </div>
                                </div>
                                <div className="col-sm-3 spacebtwn-columns">
                                    <div className="form-group"> <label for="lastName1" className="text-class">{this.props.app_labels.lastName} <span className="required">*</span></label>
                                        <br />
                                        <input className="createNewUserProfile-Inputs" required id="lastName1" type="text" ref="lastName" placeholder={this.props.app_labels.enterLastNameHere}
                                            onKeyPress={(event) => {
                                                if (event.key.replace(/[^\w\-.]/g, '') === '') event.preventDefault();
                                            }
                                            }
                                            onChange={(e) => this.clearInvalidField(e.target)}
                                            autocomplete="family-name" />
                                    </div>
                                </div>
                                <div className="col-sm-3 spacebtwn-columns">
                                    <div className="form-group">
                                        <label for="companyName" className="text-class">{this.props.app_labels.companyName} <span className="required">*</span></label>
                                        <br />
                                        {this.props.selectedRole !== 'Super Admin' && this.props.selectedRole !== 'Customer Support'? <span className="input-text">{this.props.userDetails.companyName} </span> :
                                            <Select aria-required="true" id="companyName" onChange={this.companyChange}
                                                className="companySelect"
                                                placeholder="Select company name from list"
                                            >
                                                {this.state.companyName.map(item =>
                                                    <Option value={item.companyId}>{item.companyName}</Option>
                                                )}
                                            </Select>
                                        }
                                    </div>
                                </div>
                            </div>
                            {this.state.dataSave ? <div className="dark-spinner loader-color"><Spin tip="Loading..." /></div> : null}
                            <div className="row spacebtwn-rows">
                                <div className="col-sm-3 col-sm-offset-1">
                                    <div className="form-group"> <label for="mobileNumber" className="text-class">{this.props.app_labels.mobileNumber} <span className="required">*</span></label>

                                        <br />
                                        <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                                            <PhoneCode className='phoneCode' onSelect={code => this.setState({ phCode1: code })} showFirst={['US', 'IN']} defaultValue='select country' label='select country code for mobile number' />
                                            <span>
                                                <input className="createNewUserProfile-Inputs phoneNumberInput" required id="mobileNumber" style={{ width: "120%" }} type="text" ref="mobNum" placeholder={this.props.app_labels.enterMobileNumber} onChange={(e) => {
                                                    this.clearInvalidField(e.target)
                                                    e.target.value = this.formatMobileNumber(e.target.value)
                                                }} 
                                                autocomplete="tel-local"/>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-3 spacebtwn-columns">
                                    <div className="form-group"> <label for="altMobileNumber" className="text-class">{this.props.app_labels.altMobileNumber}</label><br />
                                        <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                                            <PhoneCode className="phoneCode" onSelect={code => this.setState({ phCode2: code })} showFirst={['US', 'IN']} defaultValue='select country' label='select country code for alternate mobile number' />
                                            <span>
                                                <input className="createNewUserProfile-Inputs phoneNumberInput" id="altMobileNumber" style={{ width: "120%" }} type="text" ref="altMobNum" placeholder={this.props.app_labels.enterMobileNumber} onChange={(e) => {
                                                    this.clearInvalidField(e.target)
                                                    e.target.value = this.formatMobileNumber(e.target.value)
                                                }} />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-3 spacebtwn-columns">
                                    <div className="form-group">
                                        <label for="reportTo" className="text-class">{this.props.app_labels.reportTo} {(this.state.role !== roleIds.CardCompanyAdmin && this.state.role !== roleIds.Provisioner && this.state.role !== roleIds.Approver) && <span className="required">*</span>}</label>
                                        <br />
                                        <input className="createNewUserProfile-Inputs" required={(this.state.role !== roleIds.CardCompanyAdmin && this.state.role !== roleIds.Provisioner && this.state.role !== roleIds.Approver)? true:false} id="reportTo" value={this.state.reportToId} type="text" ref="managerId" placeholder={this.props.app_labels.enterReportTo} /* disabled={true} */
                                            onChange={(e) => this.clearInvalidField(e.target)}
                                            onKeyPress={(event) => {
                                                if (event.key.replace(/[^\w\-.]/g, '') === '') event.preventDefault();
                                            }} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-2 col-sm-offset-7 col-md-2 col-md-offset-9">
                                    <button type="submit" className="button-createtrip" style={{ marginBottom: "8%", textTransform: 'uppercase' }} onClick={this.createProfile} ><b>{this.props.app_labels.save}</b></button>
                                </div>
                            </div>
                        </form>
                    </div >
                </div >
            );
        }
        else {
            return (
                <div>
                    <div className="create-trip-container">
                        {/*   <div className="row">
                            <div className="col-sm-12 col-sm-offset-0" >
                                <div className="alert alert-success createuserSuccess"><span className="centerText"> New Profile Created!</span></div>
                            </div>
                        </div> */}
                        <div className="row">
                            <div className="col-sm-3 col-sm-offset-1">
                                <div>
                                    <span className="text-class"> {this.props.app_labels.expenseWizardId}</span> <br /><br />
                                    <span className="input-text">{profileobj.data.cadreId}</span>
                                </div>
                            </div>
                            <div className="col-sm-3 spacebtwn-columns">
                                <div>
                                    <span className="text-class">{this.props.app_labels.jobRole}</span><br /><br />
                                    <span className="input-text">{this.props.cadreRoles.map(item => item.roleId === profileobj.data.roles[0] ? item.roleDesc : null)}</span>
                                </div>
                            </div>
                            <div className="col-sm-3 spacebtwn-columns">
                                <div>
                                    <span className="text-class">{this.props.app_labels.email} </span><br /><br />
                                    <span className="input-text">{profileobj.data.email}</span>
                                </div>
                            </div>
                        </div>
                        {this.state.dataSave ? <div className="dark-spinner loader-color"><Spin tip="Loading..." /></div> : null}
                        <div className="row spacebtwn-rows">
                            <div className="col-sm-3 col-sm-offset-1">
                                <div>
                                    <span className="text-class">{this.props.app_labels.firstName} </span><br /><br />
                                    <span className="input-text">{profileobj.data.firstName}</span>
                                </div>
                            </div>
                            <div className="col-sm-3 spacebtwn-columns">
                                <div>
                                    <span className="text-class">{this.props.app_labels.lastName} </span><br /><br />
                                    <span className="input-text">{profileobj.data.lastName}</span>
                                </div>
                            </div>
                            <div className="col-sm-3 spacebtwn-columns">
                                <div>
                                    <span className="text-class">{this.props.app_labels.companyName} </span><br /><br />
                                    <span className="input-text">{this.state.companyName.map(item => parseInt(item.companyId) === parseInt(profileobj.data.companyId) ? item.companyName : null)}</span>
                                </div>
                            </div>
                        </div>

                        <div className="row spacebtwn-rows">
                            <div className="col-sm-3 col-sm-offset-1">
                                <div>
                                    <span className="text-class"> {this.props.app_labels.mobileNumber}</span><br /><br />
                                    <span className="input-text">{profileobj.data.phoneNo}</span>
                                </div>
                            </div>
                            <div className="col-sm-3 spacebtwn-columns">
                                <div>
                                    <span className="text-class">{this.props.app_labels.altMobileNumber}</span><br /><br />
                                    <span className="input-text">{profileobj.data.altPhoneNo}</span>
                                </div>
                            </div>
                            <div className="col-sm-3 spacebtwn-columns">
                                <div>
                                    <span className="text-class"> {this.props.app_labels.reportTo}</span> <br /><br />
                                    <span className="input-text">{profileobj.data.reportToId}</span>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-2 col-sm-offset-7 col-md-2 col-md-offset-9">
                                <button className="button-createtrip" style={{ marginBottom: "8%" }} onClick={this.createMore} ><b style={{textTransform: 'uppercase'}}>+ {this.props.app_labels.createMore}</b></button>
                            </div>
                        </div>

                    </div>
                </div >
            );
        }
    }

}
function mapStateToProps(state, props) {
    return {
        token: state.authenticationReducer.token,
        userDetails: state.authenticationReducer.userDetails,
        profileCreated: state.createprofileReducer.profileCreated,
        selectedLanguage: state.adduserReducer.selectedLanguage,
    };
}
function mapDispatchToProps(dispatch) {
    return {
        createprofile: (obj, updateState, successMessage, errorMessage) => {
            dispatch(CreateNewProfile(obj, updateState, successMessage, errorMessage));
        },
        createmore: (data) => {
            dispatch(CreateMore(data));
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(CreateNewUserProfileComponent);