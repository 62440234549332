import initialState from './initialState';

const getuserActionsReducer = (state = initialState, action) => {
    
    switch (action.type) {
        
        case 'USER_ACTION':

            return Object.assign({}, state, { pendingTripClosure: action.data.userActions.pendingTripClosure, cardsTobeAssigned: action.data.userActions.assignCreditCard, pendingItenaryUpload: action.data.userActions.uploadItenary, travelersToBeNotified: action.data.userActions.travelersToBeNotified });

        case 'ACTIVE_HM':
            return {
                ...state,
                activeHiringManagerList: action.data.data
            }
        case 'USER_DELETE_SUCCESS':
            return {
                ...state,
                deleteUserResponse: action.data.data
            }
            case 'USER_DELETE_ERROR':
                return {
                    ...state,
                    deleteUserResponse: action.data.data
                }
        case 'RESET_USER_DELETE':
            return {
                ...state,
                deleteUserResponse: null
            }
            
            
        default:
            return state;
    }
};

export default getuserActionsReducer;