import React, { Component, useState, useEffect } from "react";
import ReactDOM from 'react-dom';
import { Input, Button, notification, Spin, Card, Modal,  Icon, Row, Col } from 'antd';
import{ FormControlLabel, Checkbox, Tabs, Tab } from '@mui/material' ;
import axios from "axios";
import { connect } from "react-redux";
import RequestsTableDataComponents from "./RequestsTableDataComponents";
import CreateCardBtn from "./CreateCardBtn";
import 'antd/dist/antd.css'
import Filter from '../../images/provisioner/Filter.svg';
import BulkUploadComponent from '../bulk/bulkUpload';
// import ArrowBack from '../../images/bulk/arrowBack.svg';
import prepareTitle,{ loaderFocus } from '../../utils/accessability';

const { Search } = Input;
const { TabPane } = Tabs;
const { URLs, bulkFeature } = require('../../data/constants.js')

class MyRequestComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            searchTerm: "",
            data: {
                requests: [],
                rejected: [],
                sentForApproval: []
            },
            data1: {
                requests: [],
                rejected: [],
                sentForApproval: []
            },
            isModalVisible: false,
            bulkComp :false,
            activeTab: this.props.name === "Sent for Approval" ? this.lblPendingApprovals : this.props.name === "Rejected" ? this.lblRejected : this.lblRequest,
            filterValue: {},
            current: 1,
            TotalPage: 0,
            countPendingApprovals: 0,
            countRequest: 0,
            countRejected: 0,
            inSearch: false,
            title:'',
            loading: false
        };
        this.defaultFocus = this.defaultFocus.bind(this);
        this.pageCountSetter(this.state.activeTab)

    }
    lblPendingApprovals = "Sent for Approval";//this.props.label_keys.sentForApproval
    lblRequest = "Requests";
    lblRejected = "Rejected";



    // operations = (
    //     <div>
    //         <Search
    //             className="search-input"
    //             placeholder={this.props.label_keys.search}
    //             style={{ width: "57%" }}
    //             aria-label="Search trips"
    //             onChange={(e) => {
    //                 if (e.target.value === "" || e.target.value === null) {
    //                     this.setState({ searchTerm: "" });
    //                 } else {
    //                     this.setState({ searchTerm: e.target.value });
    //                 }
    //             }}
    //             onSearch={value => this.onSearch(value)}
    //         />
    //         {bulkFeature.enable ? <FilterModal onApplyFilter={this.onApplyFilter.bind(this)} label_keys={this.props.label_keys} /> : <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>}
    //         <CreateCardBtn tabsClick={(title) => this.props.tabsClick(title)} label_keys={this.props.label_keys} />
    //     </div>
    // );
    // componentDidUpdate(prevProps){
    //     this.pageCountSetter(this.state.activeTab)
    // }


    componentDidMount() {
        this.getProvisionerRequestData();
        if(window.location.hostname.includes('elan-solutions.elaneasypay'))
            document.title="My Requests: Elan Easy Pay";
        else
            document.title="My Requests: US Bank Instant Card";

    }

    componentDidUpdate(prevProps){
        // (prevProps.passedData != this.props.passedData || prevProps.failedData != this.props.failedData) &&
        // (this.props.passedData == 0 && this.props.failedData ==0) && this.getProvisionerRequestData()
        if((prevProps.passedData != this.props.passedData || prevProps.failedData != this.props.failedData) &&
        (this.props.passedData == 0 && this.props.failedData ==0)){
            setTimeout(() =>{ this.props.tabsClick(this.props.label_keys.myRequests)}, 100);
            this.getProvisionerRequestData()
            this.setState({current: 1});
        }
        if(this.props.toMyRequest){
            this.setState({ bulkComp : !this.state.bulkComp});
            this.props.goToMyRequest();
        }
    }

    pageCountSetter = (tabName) => {
        if (tabName === this.lblRequest) {
            this.setState({ TotalPage: (Math.ceil(this.state.countRequest / 10)), current: 1 })
        }
        else if (tabName === this.lblPendingApprovals) {
            this.setState({ TotalPage: (Math.ceil(this.state.countPendingApprovals / 10)), current: 1 })
        }
        else if (tabName === this.lblRejected) {
            this.setState({ TotalPage: (Math.ceil(this.state.countRejected / 10)), current: 1 })
        }
    }

    onApplyFilter(value) {
        let data = this.state.data1;
        let requestsFilter = data.requests;
        let sentForApprovalFilter = data.sentForApproval;
        let rejectedFilter = data.rejected;
        if (value === undefined) {
            this.getProvisionerRequestData();
        }
        else {
            if (this.state.activeTab === this.lblRequest) {
                requestsFilter = this.filterArrayByType(data.requests, value)
            }
            else if (this.state.activeTab === this.lblPendingApprovals) {
                sentForApprovalFilter = this.filterArrayByType(data.sentForApproval, value)
            }
            else if (this.state.activeTab === this.lblRejected) {
                rejectedFilter = this.filterArrayByType(data.rejected, value)
            }

        }
        let filteredData = {
            rejected: rejectedFilter,
            sentForApproval: sentForApprovalFilter,
            requests: requestsFilter
        }

        this.setState({
            data: filteredData,
            countRejected: filteredData.rejected && filteredData.rejected.length ? filteredData.rejected.length : 0,
            countRequest: filteredData.requests && filteredData.requests.length ? filteredData.requests.length : 0,
            countPendingApprovals: filteredData.sentForApproval && filteredData.sentForApproval.length ? filteredData.sentForApproval.length : 0
        }, () => this.pageCountSetter(this.state.activeTab));

    }

    onApplyFilterOnStart() {
        let data = this.state.data1;
        let requestsFilter = data.requests;
        let sentForApprovalFilter = data.sentForApproval;
        let rejectedFilter = data.rejected;
        let value = {
            isSingle: true,
            isBulk: false
        }
        requestsFilter = this.filterArrayByType(data.requests, value)
        sentForApprovalFilter = this.filterArrayByType(data.sentForApproval, value)
        rejectedFilter = this.filterArrayByType(data.rejected, value)

        let filteredData = {
            rejected: rejectedFilter,
            sentForApproval: sentForApprovalFilter,
            requests: requestsFilter
        }

        this.setState({
            data: filteredData,
            countRejected: filteredData.rejected && filteredData.rejected.length ? filteredData.rejected.length : 0,
            countRequest: filteredData.requests && filteredData.requests.length ? filteredData.requests.length : 0,
            countPendingApprovals: filteredData.sentForApproval && filteredData.sentForApproval.length ? filteredData.sentForApproval.length : 0
        }, () => this.pageCountSetter(this.state.activeTab));

    }

    filterArrayByType(data, value) {
        let filteredData = [];
        if (value === undefined || (value.isSingle === false && value.isBulk === false)) {
            return data;
        } else {
            let single = value.isSingle ? "Single" : "";
            let bulk = value.isBulk ? "Bulk" : "";

            let singleArray = (single) ? data.filter(item => item.type === single) : [];
            let bulkArray = (bulk) ? data.filter(item => item.type === bulk) : [];

            filteredData = [...singleArray, ...bulkArray];
            return filteredData;
        }
    }

    getProvisionerRequestData() {
        this.setState({loading: true});
        loaderFocus(".loader-color .ant-spin-text");  
        axios
            .get(
                URLs.cardUrl + `/getProvisionerRequestData`,
                {
                    responseType: "json",
                    headers: {
                        Authorization: "Bearer " + this.props.token.access_token,
                    },
                }
            )
            .then((response) => {
                if (response.data.serviceStatus.statusCode === 200) {
                    this.setState({
                        data: response.data.data,
                        data1: response.data.data,
                        countRejected: response.data.data.rejected.length,
                        countRequest: response.data.data.requests.length,
                        countPendingApprovals: response.data.data.sentForApproval.length,
                        TotalPage: (Math.ceil(response.data.data.requests.length / 10))
                    });
                    if (this.props.name === 'Sent for Approval') {
                        this.tabChange(this.lblPendingApprovals)
                    } else if (this.props.name === 'Rejected') {
                        this.tabChange(this.lblRejected)
                    }
                    !bulkFeature.enable && this.onApplyFilterOnStart()
                } else {
                    this.setState({ selectedUser: null });
                    notification.error({
                        message: (
                            <span role="alert"><span className="visually-hidden">Error </span>
                                {this.props.label_keys.couldNotcompleteReq}
                            </span>
                        ),
                        duration: 3,
                        style: {
                            backgroundColor: "#fff1f0",
                            border: "1px solid #ffa39e",
                        },
                    });
                }
                this.setState({loading: false})
            })
            .catch((error) => {
                this.setState({ selectedUser: null, loading: false });
                notification.error({
                    message: (
                        <span role="alert" ><span className="visually-hidden">Error </span>{this.props.label_keys.couldNotcompleteReq}</span>
                    ),
                    duration: 3,
                    style: {
                        backgroundColor: "#fff1f0",
                        border: "1px solid #ffa39e",
                    },
                });
            });
    }

    /**
     * Searches the trip using axios request based on the name entered
     */
    onSearch = (value) => {
        // if(value){
            let data = this.state.data1;
            let reqArray = data.requests;
            let pendingApprovalArray = data.sentForApproval;
            let rejectedArray = data.rejected;
    
            if (value) {
                if (this.state.activeTab === this.lblRequest) {
                    reqArray = data.requests.filter((req) => req.reqNo.toString() === value || req.req_owner.toString().toLowerCase().search(value.toLowerCase()) !== -1
                        || req.status.toLowerCase().search(value.toLowerCase()) !== -1 || req.type.toLowerCase().search(value.toLowerCase()) !== -1);
                }
    
    
                else if (this.state.activeTab === this.lblPendingApprovals) {
                    pendingApprovalArray = data.sentForApproval.filter((req) => req.reqNo.toString() === value || req.req_owner.toString().toLowerCase().search(value.toLowerCase()) !== -1
                        || req.status.toLowerCase().search(value.toLowerCase()) !== -1 || req.type.toLowerCase().search(value.toLowerCase()) !== -1);
                }
    
    
                else if (this.state.activeTab === this.lblRejected) {
                    rejectedArray = data.rejected.filter((req) => req.reqNo.toString() === value || req.req_owner.toString().toLowerCase().search(value.toLowerCase()) !== -1
                        || req.status.toLowerCase().search(value.toLowerCase()) !== -1 || req.type.toLowerCase().search(value.toLowerCase()) !== -1);
                }
            }
    
            let newData = {
                rejected: rejectedArray,
                sentForApproval: pendingApprovalArray,
                requests: reqArray
            }
    
            this.setState({
                data: newData,
                countRejected: newData.rejected.length,
                countRequest: newData.requests.length,
                countPendingApprovals: newData.sentForApproval.length
            }, () => this.pageCountSetter(this.state.activeTab))
        // }


    };

    setTitle = (title) =>{
        this.setState({title: title});
    }

    bulkVisible=()=>{
        // console.log("Bulk visible", this.state.bulkComp, this.state.title);
        this.state.bulkComp && (this.props.passedData && this.props.passedData.length === 0 || this.props.failedData && this.props.failedData.length === 0) && this.getProvisionerRequestData()
        this.setState({ bulkComp : !this.state.bulkComp});
        setTimeout(() =>{ this.props.tabsClick(this.state.title)}, 100);
    }

    itemRender = (current, type, originalElement) => {
        if (type === 'prev') {
            return <a><Icon aria-label={null} type="arrow-left" /></a>
        }
        if (type === 'next') {
            return <a ><Icon aria-label={null} type="arrow-right" /></a>;
        }
        return originalElement;
    }

    pageChanger = (page, defaultPageSize) => {
        console.log(window.innerWidth, window.outerWidth);
        if (this.state.activeTab === this.lblRequest) {
            let TotalPage = Math.ceil(this.state.data.requests.length / defaultPageSize)
            this.setState({ current: page, TotalPage: TotalPage })
        }
        else if (this.state.activeTab === this.lblPendingApprovals) {
            let TotalPage = Math.ceil(this.state.data.sentForApproval.length / defaultPageSize)
            this.setState({ current: page, TotalPage: TotalPage })
        }
        else if (this.state.activeTab === this.lblRejected) {
            let TotalPage = Math.ceil(this.state.data.rejected.length / defaultPageSize)
            this.setState({ current: page, TotalPage: TotalPage })
        }


    }

    OnPageSizeChange = (current, size) => {
        if (this.state.activeTab === this.lblRequest) {
            let TotalPage = Math.ceil(this.state.data.requests.length / size)
            this.setState({ current: current, TotalPage: TotalPage })
        }
        else if (this.state.activeTab === this.lblPendingApprovals) {
            let TotalPage = Math.ceil(this.state.data.sentForApproval.length / size)
            this.setState({ current: current, TotalPage: TotalPage })
        }
        else if (this.state.activeTab === this.lblRejected) {
            let TotalPage = Math.ceil(this.state.data.rejected.length / size)
            this.setState({ current: current, TotalPage:  TotalPage })
        }

    }
    // Accessbility To make default focus on element.
    defaultFocus = () => {        
        if(!this.state.inSearch){
        if (this.state.activeTab === 'Sent for Approval') {
            if (ReactDOM.findDOMNode(this.refs.sentTab))
                ReactDOM.findDOMNode(this.refs.sentTab).focus();
        } else if (this.state.activeTab === 'Rejected') {
            if (ReactDOM.findDOMNode(this.refs.rejectTab))
                ReactDOM.findDOMNode(this.refs.rejectTab).focus();
        } else if(this.state.activeTab === 'Requests'){
            if (document.getElementById('req-tab'))
                document.getElementById('req-tab').focus();
        }
    }
    }

    onCLose(){
        this.setState({ bulkComp : !this.state.bulkComp})
        this.props.tabsClick(this.props.label_keys.myRequests)
    }

    render() {
        return (<>{(this.props.passedData && this.props.passedData.length>0 || this.props.failedData && this.props.failedData.length>0) && this.state.bulkComp === true ? 
            <> <div className="create-head bulk-close">
            <Icon type="arrow-left" role="button" aria-label="Back" tabIndex={0} className="req-back-btn"
            style={{float: 'left', fontWeight: 400, color: '#ffffff'}} onKeyDown={e => {
                if (e.keyCode === 13) {
                    this.onCLose()
                }
            }}
             onClick={() => this.onCLose()} />
            {/* <img src={ArrowBack} alt="Back" tabIndex={0} role="button" className="req-back-btn"
            style={{float: 'left', fontSize: 22}} onKeyDown={e => {
                if (e.keyCode === 13) {
                    this.onCLose()
                }
            }}
             onClick={() => this.onCLose()} /> */}
            <Icon role="button" aria-label="Close" tabIndex={0} id="closeIconItem" className="req-close-btn"
             type="close-circle"
            onKeyDown={e => {
                if (e.keyCode === 13) {
                    this.onCLose()
                }
            }}
             onClick={() => this.onCLose()} />
            </div>
            <BulkUploadComponent ReqFlag={true} label_keys={this.props.label_keys} selectedLang={this.props.selectedLang} />
            </>:
            <div role="main">
                {this.state.loading ? <div className="dark-spinner loader-color"><Spin tip={this.props.label_keys.loading} /></div> : null}
            <Card style={{ borderRadius: "12px" }} className='container ant-card-pv'>
                <Row gutter={[16, {xs: 30, sm: 30, lg: 24, xl: 12, xxl: 12}]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                        <Row className='myRequest-extra'> 
                            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                                <Search
                                    className="search-input"
                                    id='search-input'
                                    tabIndex={0}
                                    placeholder={this.props.label_keys.search}
                                    style={{ width: "90%" }}
                                    aria-label="Search"
                                    onChange={(e) => {
                                        if (e.target.value === "" || e.target.value === null) {
                                            this.setState({ inSearch:true, searchTerm: "" });
                                        } else {
                                            this.setState({ inSearch:true, searchTerm: e.target.value });
                                        }
                                    }}
                                    onSearch={value => this.onSearch(value)}
                                />
                            </Col>
                            {bulkFeature.enable && <Col xs={6} sm={6} md={2} lg={2} xl={2} xxl={2}><FilterModal  onApplyFilter={this.onApplyFilter.bind(this)} label_keys={this.props.label_keys} /></Col>}
                            <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}><CreateCardBtn getProvisionerRequestData={() => this.getProvisionerRequestData()} tabsClick={(title) => this.props.tabsClick(title)} label_keys={this.props.label_keys} /></Col>
                        </Row>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                        <Tabs style={{ borderBottom:'1px solid #e8e8e8', marginTop: '-50px', marginBottom: '5px',
                        paddingTop: '10px', float: 'left', width: '100%', textTransform: 'none', }} className="request-extra" 
                        value={this.state.activeTab} 
                        // defaultActiveKey={this.lblRequest}
                        onChange={this.tabChange}> 
                            <Tab disableRipple id="req-tab" aria-controls='req-tab-panel' className="req-tab" value={this.lblRequest} label={
                                <span id='pendingTab' ref={'pendingTab'} >
                                    {this.props.label_keys.requests}  {" ("}  {this.state.countRequest}  {")"}
                                </span>}  
                                />
                            {(this.props.userDetails.bulkCardApproval || this.props.userDetails.singleCardApproval) && <Tab disableRipple id="approv-tab" aria-controls='approv-tab-panel' className="approv-tab" value={this.lblPendingApprovals} label={ <span id='sentTab'  
                                ref={'sentTab'}>{this.props.label_keys.sentForApproval + " (" + this.state.countPendingApprovals + ")"} </span> } />
                            }
                            {(this.props.userDetails.bulkCardApproval || this.props.userDetails.singleCardApproval) && <Tab disableRipple id="reject-tab" aria-controls='reject-tab-panel' className="reject-tab" value={this.lblRejected} label={ <span id='rejectTab' 
                                ref={'rejectTab'}
                                    // onKeyDown={e =>{
                                    //     if(e.key === "Tab" && !e.shiftKey){
                                    //         setTimeout(() =>
                                    //         {
                                    //             document.getElementById('search-input').focus();
                                    //         },100);
                                    //     }
                                    // }}
                                >{this.props.label_keys.rejected + " (" + this.state.countRejected + ")"} </span>} /> 
                            }                  
                        </Tabs>
                    </Col>
                </Row>
                <div role="tabpanel" id='req-tab-panel' aria-labelledby='req-tab' value={this.lblRequest}
                    hidden={this.state.activeTab !== this.lblRequest} 
                    // style={{marginTop: '4%'}}
                >
                    <RequestsTableDataComponents
                        activeTab={"Requests"}
                        bulkVisible={this.bulkVisible}
                        data={this.state.data.requests}
                        filterValue={this.state.filterValue}
                        TotalPage={this.state.TotalPage}
                        loading={false}
                        itemRender={this.itemRender} 
                        current={this.state.current}                   
                        OnPageSizeChange={this.OnPageSizeChange}
                        pageChanger={this.pageChanger}
                        getProvisionerRequestData={() => this.getProvisionerRequestData()}
                        // tabsClick={(title) => this.props.tabsClick(title)}
                        setTitle={(title) => this.setTitle(title)}
                        label_keys={this.props.label_keys} />
                </div>
                {(this.props.userDetails.bulkCardApproval || this.props.userDetails.singleCardApproval) &&
                    <div id='approv-tab-panel' aria-labelledby='approv-tab' value={this.lblPendingApprovals}
                    hidden={this.state.activeTab !== this.lblPendingApprovals} 
                    // style={{marginTop: '4%'}}
                    >                    
                        <RequestsTableDataComponents
                            activeTab={"Sent For Approval"}
                            data={this.state.data.sentForApproval}
                            filterValue={this.state.filterValue}
                            TotalPage={this.state.TotalPage}
                            loading={false}
                            itemRender={this.itemRender} 
                            current={this.state.current}
                            pageChanger={this.pageChanger}
                            OnPageSizeChange={this.OnPageSizeChange}
                            getProvisionerRequestData={() => this.getProvisionerRequestData()}
                            label_keys={this.props.label_keys}
                        />
                    </div>
                }
                {(this.props.userDetails.bulkCardApproval || this.props.userDetails.singleCardApproval) &&
                    <div id='reject-tab-panel' aria-labelledby='reject-tab' 
                    // style={{marginTop: '4%'}}
                    value={this.lblRejected} hidden={this.state.activeTab !== this.lblRejected}>
                        <RequestsTableDataComponents
                            activeTab={"Rejected"}
                            data={this.state.data.rejected}
                            filterValue={this.state.filterValue}
                            TotalPage={this.state.TotalPage}
                            loading={false}
                            itemRender={this.itemRender} 
                            current={this.state.current}
                            pageChanger={this.pageChanger}
                            OnPageSizeChange={this.OnPageSizeChange}
                            getProvisionerRequestData={() => this.getProvisionerRequestData()}
                            label_keys={this.props.label_keys} />
                    </div>
                }
                
            </Card>
            </div>}</>
        );
    }

    tabChange = (event, tabName) => {
        console.log("Tab change", tabName);
        if (tabName) {

            this.setState({ activeTab: tabName });
            this.pageCountSetter(tabName);
        }

    }


}

function mapStateToProps(state, props) {
    return {
        userDetails: state.authenticationReducer.userDetails,
        token: state.authenticationReducer.token,
        iclanguageList: state.instantCardMultiLanReducer.iclanguageList,
        passedData: state.bulkcardReducer.passedData,
        failedData: state.bulkcardReducer.failedData,
        failedRecords: state.bulkcardReducer.failedRecords,
    }
}

const mapDispatchToProps = dispatch => {
    return {}
}

const FilterModal = (props) => {
    const [isModalVisible, setIsModalVisible] = useState(false);

    const [isSingle, setIsSingle] = useState(false);
    const [isBulk, setIsBulk] = useState(false);

    const handleOk = () => {
        let fltr = { isSingle: isSingle, isBulk: isBulk };
        props.onApplyFilter(fltr);
        setIsModalVisible(false);
    };

    const defaultFocus = () =>{
        if(document.getElementById('singleType')){
            document.getElementById('singleType').focus();
        }
    }

    const singleType = () => setIsSingle(p => !p);

    const bulkType = () => setIsBulk(b => !b);

    return (
        <>
            <Button role="button"  aria-label="Filter" className="myrequest-filter" style={{ height: "40px" }} type="primary" 
            onClick={() => {
                setIsModalVisible(true)
                // setTimeout(() =>{
                //     document.getElementById('modal-cancel-btn').focus();
                // }, 100) 
            }}
                >
                <img  className="myreq-filter" src={Filter} alt={"Filter"} />
            </Button>
            <Modal className="confirm-visible filter-modal modal-filter-zoom" role="dialog"
                style={{ marginLeft: "33%" }}
                title={<b role="heading" aria-level="h2">{props.label_keys.filter}</b>}
                visible={isModalVisible}
                onOk={handleOk}
                cancelButtonProps={{id: 'modal-cancel-btn'}}
                onCancel={() => setIsModalVisible(false)}
                cancelText={props.label_keys.cancel}
                okText={props.label_keys.applyFilter}>
                <div role="group" aria-labelledby="cardType" >
                    <div  className="filter-type" id="cardType" >{props.label_keys.cardType}</div>
                    <FormControlLabel
                        control={ 
                        <Checkbox checked={isSingle} aria-label="Check Type"
                            disableRipple
                            id="singleType"
                            className='singleType'
                            onChange={e => singleType()}
                            name={props.label_keys.single}
                            style={{ color: '#19398A' }}
                            sx={{ '& .MuiSvgIcon-root': { fontSize: 20,  fontWeight: 200 } }}
                            inputProps={{ className:"singleTypeCheckbox", id:'single-type' }} 
                        />
                        }
                        // style={{width: '100%', marginTop: '-6px'}}
                        label={<span className="singleTypeLabel" 
                        style={{marginLeft: '7px', marginRight: '7px', fontSize: '14px', fontWeight: '400', color: '#666666'}}>
                            {props.label_keys.single}</span>}
                    />
                    <FormControlLabel
                        control={ 
                        <Checkbox  checked={isBulk} aria-label="Check Type"
                            disableRipple 
                            className='bulkType'
                            onChange={e => bulkType()}
                            name={props.label_keys.bulk}
                            style={{ color: '#19398A' }}
                            sx={{ '& .MuiSvgIcon-root': { fontSize: 20, fontWeight: 200 } }}
                            inputProps={{ className:"bulkTypeCheckbox", id:'bulk-type' }} 
                            TouchRippleProps={{ style: { backgroundColor: 'transparent' }}}/>
                        }
                        // style={{width: '100%', marginTop: '-6px'}}
                        label={<span className="bulkTypeLabel"
                         style={{marginLeft: '7px', marginRight: '7px',  fontSize: '14px', fontWeight: '400', color: '#666666'}}>
                            {props.label_keys.bulk}</span>}
                    />
                    {/* <Checkbox  role="checkbox"  aria-checked={isSingle} aria-label="Check Type Single"  onChange={singleType}>{props.label_keys.single}</Checkbox>
                    <Checkbox  role="checkbox"  aria-checked={isBulk} aria-label="Check Type Bulk"  onChange={bulkType}>{props.label_keys.bulk}</Checkbox> */}
                </div>

            </Modal>
        </>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(MyRequestComponent);
