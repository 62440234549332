import { combineReducers } from 'redux'

import createtripReducer from './createtripReducers.js';
import adduserReducer from "./adduserReducer.js";
import getparticulartripReducer from "./getpartiuculartripReducers";
/* import createCardReducer from "./createcardReducer"; */
import gettripReducer from "./gettripReducers";
import getuserActionsReducer from "./userActionsReducer"
import getAllDetailReducer from "./alldetailsReducer";
import authenticationReducer from "./authenticationReducer";
import logoutReducer from "./logoutReducer";
import createprofileReducer from "./AdminReducers/createprofileReducer";
import csrReducer from './csrReducer'
import instantCardMultiLanReducer from './instantCardMultiLanReducer';
import bulkcardReducer from './bulkcardReducer';
import myApprovalReducer from './myApprovalReducer';
const appReducer = combineReducers({

	createtripReducer,
	adduserReducer,
	gettripReducer,
	getparticulartripReducer,
	logoutReducer,
	getuserActionsReducer,
	getAllDetailReducer,
	authenticationReducer,
	createprofileReducer,
	csrReducer,
	instantCardMultiLanReducer,
	bulkcardReducer,
	myApprovalReducer
})

export default appReducer;