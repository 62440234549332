import React, { useState, useRef, useEffect, Component } from "react";
import ReactDOM from 'react-dom';
import { connect } from 'react-redux'
import $ from 'jquery';
import { Table, Button, Spin, notification, Result, Radio, Modal, Slider, Row, Col, message, Card, Icon } from "antd";
import { FormControlLabel, Checkbox, Tabs, Tab } from '@mui/material';
import XLSX from "xlsx";
import 'antd/dist/antd.css'
import '../../styling/provisioner.css'
import { logOut } from '../../actions/logoutactions'
import RejectApprovalComponent from "./RejectApprovalComponent";
import { updateReqStatus } from '../../actions/myApprovalActions.js';
import Filter from '../../images/provisioner/filter.png'
import warningIcon from '../../images/bulk/warningIcon.svg'
import Download from '../../images/bulk/Download.svg'
import axios from 'axios';
import RequestDetails from "../ProvisionerScreen/RequestDetails";
import prepareTitle,{ loaderFocus,checkBoxStatus } from '../../utils/accessability';
import Tick from '../../images/bulk/tick.svg'

import { Input } from "antd/lib/index";
const { URLs, bulkFeature } = require('../../data/constants.js')
const { Search } = Input;
const useFirstRender = () => {
    const ref = useRef(true);
    const firstRender = ref.current;
    ref.current = false;
    return firstRender;
}

class MyApproval extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cols: [],
            rows: [],
            selectedRowKeys: [],
            loading: false,
            visible: false,
            activeTab: this.props.name === 'Rejected' ? "2" : "1",
            sortedInfo: null,
            errorMessage: null,
            filterTable: null,
            dataSave: false,
            modalLoading: false,
            modalVisible: false,
            modalResultVisible: false,
            modalFilterVisible: false,
            pendingData: [],
            rejectedDat: [],
            pendingFilterTable: null,
            rejectedFilterTable: null,
            filterSelectedType: 'Single',
            searchTerm: "",
            maxValue: 0,
            minValue: 0,
            current: 1,
            TotalPage: 0,
            pendingtableData: null,
            rejectedtableData: null,
            left: null,
            right: null,
            dataVisible: false,
            selectedRow: {},
            extraRequest: false,
            firstTimeLoad: true,
            sortOrderText: '',
            isSingle: false,
            isBulk: false,
            previousActiveElement: null,
            approvedClick: false,
            selectedRowKeys2: [],
            pendingData2: [],
            approvedPopupVisible: false
        };
        this.rejectionAreaRef = React.createRef();
        this.defaultFocus = this.defaultFocus.bind(this);
    }

    componentDidUpdate() {
        const divEl = document.querySelectorAll('.ant-checkbox-input');
        if (divEl[0]) {
            divEl.forEach(el => {
                if (!el.getAttribute('aria-label') || el.getAttribute('id') === 'Select-all-checkbox') {
                    if (this.state.activeTab === '1') {
                    
                        el.setAttribute('aria-label', 'Select all pending approval requests');
                    }
                    else {
                        el.setAttribute('aria-label', 'Select all rejected requests');
                    }
                    el.setAttribute('id', 'Select-all-checkbox');
                }
            })
        }

        var ulDiv = document.querySelectorAll(".ant-pagination.ant-table-pagination");
        if(ulDiv[0]){
            ulDiv.forEach(div => {
                // console.log("Last element:::", div.lastElementChild , div.lastElementChild.className ==="ant-pagination-options");
                if(div.lastElementChild.className ==="ant-pagination-options")
                    div.prepend(div.lastElementChild)
            })
        }

        const icon = document.querySelectorAll('.anticon.anticon-down.ant-select-arrow-icon');
        if (icon) {
            icon.forEach(el => {
                el.setAttribute('role', 'img')
                el.setAttribute('aria-hidden', 'true')
            })
        }

        const paginationDiv = document.querySelectorAll('.ant-pagination.ant-table-pagination');
        if (paginationDiv[0]) {
            paginationDiv.forEach(div => {
                if (!div.getAttribute('role'))
                    div.setAttribute('role', 'presentation');
            })
        }

        const ellipsis = document.querySelectorAll('.ant-pagination-item-ellipsis');
        if (ellipsis[0]) {
            ellipsis.forEach(div => {
                if (!div.getAttribute('aria-hidden'))
                    div.setAttribute('aria-hidden', 'true');
            })
        }

        const prevFiveDiv = document.querySelectorAll('.ant-pagination-jump-prev.ant-pagination-jump-prev-custom-icon');
        if (prevFiveDiv[0]) {
            prevFiveDiv.forEach(div => {
                if (!div.getAttribute('role')) {
                    div.setAttribute('role', 'link');
                    div.setAttribute('aria-label', "Previous 5 pages");
                }
            })
        }

        const prevFiveDivIcon = document.querySelectorAll('.anticon.anticon-double-left.ant-pagination-item-link-icon');
        if (prevFiveDivIcon[0]) {
            prevFiveDivIcon.forEach(div => {
                if (div.getAttribute('aria-label'))
                    div.removeAttribute('aria-label');
            })
        }

        const nextFiveDiv = document.querySelectorAll('.ant-pagination-jump-next.ant-pagination-jump-next-custom-icon');
        if (nextFiveDiv[0]) {
            nextFiveDiv.forEach(div => {
                if (!div.getAttribute('role')) {
                    div.setAttribute('role', 'link');
                    div.setAttribute('aria-label', "Next 5 pages");
                }
            })
        }

        const nextFiveDivIcon = document.querySelectorAll('.anticon.anticon-double-right.ant-pagination-item-link-icon');
        if (nextFiveDivIcon[0]) {
            nextFiveDivIcon.forEach(div => {
                if (div.getAttribute('aria-label'))
                    div.removeAttribute('aria-label');
            })
        }

        const prevPageDiv = document.querySelectorAll('.ant-pagination-prev');
        if (prevPageDiv[0]) {
            prevPageDiv.forEach(div => {
                if (!div.getAttribute('role')) {
                    div.setAttribute('role', 'link');
                    div.setAttribute('aria-label', "Previous page");
                }
            })
        }

        const nextPageDiv = document.querySelectorAll('.ant-pagination-next');
        if (nextPageDiv[0]) {
            nextPageDiv.forEach(div => {
                if (!div.getAttribute('role')) {
                    div.setAttribute('role', 'link');
                    div.setAttribute('aria-label', "Next page");
                }
            })
        }


        const dropDown = document.querySelectorAll('.ant-select-selection.ant-select-selection--single');
        if (dropDown[0]) {
            dropDown.forEach(div => {
                if (!div.getAttribute('aria-label'))
                    div.setAttribute('aria-label', 'Select rows');


                div.addEventListener('keyup', function () {
                    // console.log("Event on click fired");
                    const comboboxOption = document.querySelectorAll('.ant-select-dropdown-menu-item');
                    // console.log("Div:::", comboboxOption);
                    if (comboboxOption[0]) {
                        var i = 0
                        comboboxOption.forEach(el => {
                            i += 1
                            if (!el.getAttribute('id'))
                                el.setAttribute('id', `option-${i}`);
                            el.removeAttribute('aria-selected');
                        })
                    }
                    let optionDiv = document.querySelectorAll('.ant-select-dropdown-menu-item.ant-select-dropdown-menu-item-active');
                    // console.log("Div:::", optionDiv);
                    if (optionDiv[0]) {
                        let activeDescendant = optionDiv[0].getAttribute('id')
                        //  console.log("active descendant::", activeDescendant);   
                        div.setAttribute('aria-activedescendant', activeDescendant);
                    }
                });
            })

        }

        // if (this.state.modalVisible) {
        //     setTimeout(() => {
        //         this.rejectionAreaRef.current.focus();
        //     }, 100)
        // }
        if (this.state.modalFilterVisible) {
            setTimeout(() => {
                const div = document.getElementsByClassName('ant-slider-handle');

                if (div[0]) {
                    div[0].setAttribute('aria-label', 'Credit limit min');
                    div[1].setAttribute('aria-label', 'Credit limit max');
                }
                // this.modalBtnRef.current.buttonNode.focus();
            }, 500)
        }
    }

    getData = () => {
        this.setState({loading: true});
        loaderFocus(".loader-color .ant-spin-text");
        let userType = this.props.userDetails.roles.includes("Provisioner") && this.props.userDetails.roles.includes("Approver") ? "Both" : ""
        axios.get(URLs.cardUrl + "/getApprovalsData?accessType=" + userType, {
            responseType: 'Json',
            headers: {
                "Authorization": 'Bearer ' + this.props.token.access_token
            }
        }).then(response => {
            response.data.data &&
                this.setState({
                    pendingData: response.data.data.pending,
                    rejectedData: response.data.data.rejected,
                    pendingtableData: response.data.data.pending,
                    rejectedtableData: response.data.data.rejected,
                    TotalPage: Math.ceil(response.data.data.pending.length / 10)
                })
            this.props.name === 'Rejected' && this.changeTab('', '2')
            !bulkFeature.enable && this.filterDataOnStart()

            // this.updatestate();
            this.setState({ loading: false, dataSave: false });
            if(this.state.approvedClick){
                console.log("Selected row:::", this.state.selectedRowKeys2, "length::", this.state.selectedRowKeys2.length);
                this.setState({approvedPopupVisible: true});
            }
            
        }).catch(error => {
            this.setState({ retrievecomp: false, loading: false, dataSave: false });

            // this.updatestate(false);
            if (error.response !== undefined && error.response.data !== null && error.response.data !== undefined && error.response.data.serviceStatus !== undefined) {
                notification.error({
                    message: <span role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{this.props.label_keys.couldNotcompleteReq}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }
            else {
                notification.error({
                    message: <span role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{this.props.label_keys.couldNotcompleteReq}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }
        })
        if (window.location.hostname.includes('elan-solutions.elaneasypay'))
            document.title = "My Approval: Elan Easy Pay";
        else
            document.title = "My Approval: US Bank Instant Card";

    }

    handleEsc = (e) => {
        if (e.key === 'Escape') {
            this.setState({ approvedClick: false, selectedRowKeys2: [] , approvedPopupVisible: false, pendingData2: []})
        }
    }
    handleApprovedModal = () => {
            this.setState({ approvedClick: false, selectedRowKeys2: [] , approvedPopupVisible: false, pendingData2: []})
           // setTimeout(() => {
                $('.focus-heading').attr('tabIndex','-1').focus();    
             //   }, 100);
    }

    /* Calling endpoint*/
    componentDidMount() {
        const div = document.querySelectorAll('.ant-checkbox-input');
        const divUp = document.querySelectorAll(".anticon.anticon-caret-up.ant-table-column-sorter-up.off");
        const divDown = document.querySelectorAll(".anticon.anticon-caret-down.ant-table-column-sorter-down.off");
        // console.log("div:::", divUp)
        if (divUp[0]) {
            divUp.forEach(div => {
                div.setAttribute('role', 'img')
                div.setAttribute('aria-hidden', 'true')
            })
        }
        if (divDown[0]) {

            divDown.forEach(div => {
                div.setAttribute('role', 'img')
                div.setAttribute('aria-hidden', 'true')
            })

        }

        const divSearch = document.getElementsByClassName("anticon anticon-search ant-input-search-icon");

        if (divSearch[0]) {
            divSearch[0].setAttribute('role', 'img')
            divSearch[0].setAttribute('aria-hidden', 'true')
        }
        this.getData();
    }

    /* Filter Methods*/
    showFilter = () => {
        let maxValue = this.getMaxCardLimitValue();
        let minValue = this.getMinCardLimitValue();
        this.setState({ minValue: minValue, maxValue: maxValue, modalFilterVisible: true });
        // setTimeout(() =>{
        //     document.getElementById('modal-cancel-btn').focus();
        // }, 100) 
    }

    handleChange = (pagination, filters, sorter) => {
        this.setState({
            filteredInfo: filters,
            sortedInfo: sorter,
            sortOrderText: `Table sorted by ${sorter.columnKey}, in ${sorter.order}ing order`,
        });
    }

    handleFilterCancel = () => {
        this.setState({ modalFilterVisible: false, pendingFilterTable: null, rejectedFilterTable: null });
    };

    filterData = () => {
        let data = this.state.activeTab === '1' ? this.state.pendingtableData : this.state.rejectedtableData;
        let single = this.state.isSingle ? "Single" : "";
        let bulk = this.state.isBulk ? "Bulk" : "";
        if (data.length > 0) {
            let filterData =
                data.filter(
                    (d) => this.state.left <= d.creditLimit && d.creditLimit <= this.state.right && (d.type === single || d.type === bulk || (!this.state.isSingle && !this.state.isBulk))
                );

            this.state.activeTab === '1' ?
                this.setState({ modalFilterVisible: false, pendingData: filterData, minValue: 0, max: 0 }, () => this.pageCountSetter()) :
                this.setState({ modalFilterVisible: false, rejectedData: filterData, minValue: 0, max: 0 }, () => this.pageCountSetter())
        } else {
            message.error({ content: <span role="alert" ><span className="visually-hidden">Error </span>{this.props.label_keys.dataNotAvailable}</span> });

        }

    }

    filterDataOnStart = () => {
        let data = this.state.pendingtableData
        let data1 = this.state.rejectedtableData;
        if (data.length > 0) {
            let filterData =
                data.filter(
                    (d) => d.type === this.state.filterSelectedType
                );
            this.setState({ modalFilterVisible: false, pendingData: filterData, minValue: 0, max: 0 }, () => this.pageCountSetter())
        }
        if (data1.length > 0) {
            let filterData =
                data1.filter(
                    (d) => d.type === this.state.filterSelectedType
                );
            this.setState({ modalFilterVisible: false, rejectedData: filterData, minValue: 0, max: 0 }, () => this.pageCountSetter())
        }
    }

    setModalResultVisible = () => {
        this.setState({ modalResultVisible: false });
        if (document.getElementById('approv-tab'))
            document.getElementById('approv-tab').focus();
    };

    /* Download failed records*/

    handleDownload = (data) => {
        let binaryWS = XLSX.utils.json_to_sheet(data);
        let wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, binaryWS, 'Failed Data')
        XLSX.writeFile(wb, 'failedRecords.xlsx');
    }

    onSelectChange = (selectedRowKeys) => {
        this.setState({ modalResultVisible: false, selectedRowKeys });
    }

    handleModalCancel = () => {
        this.setState({ modalVisible: false });
    };

    showModal = () => {
        this.setState({
            modalVisible: true,
        });
    };

    updateState = (value) => {
        this.setState({ dataSave: value });
    }

    /*Search Methods*/
    search = value => {
        const { rows } = this.state;
        const searchData = this.state.activeTab === '1' ? this.state.pendingtableData : this.state.rejectedtableData;
        const filterTable = searchData.filter(o =>
            Object.keys(o).some(k =>
                String(o[k])
                    .toLowerCase()
                    .includes(value.toLowerCase())
            )
        );
        if (this.state.activeTab === '1') {

            this.setState({ pendingData: filterTable }, () => this.pageCountSetter());
        } else {
            this.setState({ rejectedData: filterTable }, () => this.pageCountSetter())
        }



    };

    handleCancel = () => {
        this.setState({ selectedRowKeys: this.state.selectedRowKeys.length > 0 && [] });
        $('.focus-heading').attr('tabIndex','-1').focus();    
    }

    changeTab = (event, value) => {
        //maxValue= this.getMaxCardLimitValue();
        this.setState({
            activeTab: value,
            selectedRowKeys: this.state.selectedRowKeys.length > 0 && []
        }, () => this.pageCountSetter(this.state.activeTab));

    };

    isModalVisible = (value) => {
        this.setState({ modalResultVisible: value });
    }

    /* Calling Approval endpint*/
    handleApproval = (apprlStatus, remarks) => {
        if (this.state.selectedRowKeys.length !== 0) {
            this.setState({ selectedRowKeys2: this.state.selectedRowKeys, pendingData2: this.state.pendingData })
            let needApprovalJson = {};
            let requestArray = [];
            needApprovalJson.card_ids = [];
            let totalRequest = 0;
            for (let i = 0; i < this.state.selectedRowKeys.length; i++) {
                requestArray.push({ "request_id": this.state.selectedRowKeys[i], "type": this.getType(this.state.selectedRowKeys[i]), "provisionerId": this.getProvisionerId(this.state.selectedRowKeys[i]), "remarks": remarks });
                totalRequest += this.getCards(this.state.selectedRowKeys[i])
            }
            let requestJson =
            {
                "approver_id": this.props.userDetails.userId,
                "approval_status": apprlStatus,
                "requestArray": requestArray,
                "flag": this.state.activeTab === '1' ? "pending" : "rejected"
            }
            if (totalRequest <= 500) {
                this.setState({ modalVisible: false });
                this.setState({ dataSave: true , approvedClick: true});
                loaderFocus(".loader-color .ant-spin-text");
                this.props.updateReqStatusFunc(requestJson, this.updateState, this.getData, this.props.label_keys, this.isModalVisible);
                setTimeout(() => {
                    console.log("Executed after 9 sec");
                    this.getData()
                    console.log("Execution completed");
                }, 9000);
            } else {
                this.setState({ extraRequest: true })
            }

            // this.setState({ modalVisible: false });
            // this.setState({ dataSave: true });
            // this.props.updateReqStatusFunc(requestJson, this.updateState, this.getData, this.props.label_keys, this.isModalVisible);
            //this.setState({ modalResultVisible: true });
            //(message.error(this.props.label_keys.selectRecordForApproval))
        }
        this.setState({ selectedRowKeys: [] });

    }

    /* Calling Reject endpint*/
    handleReject = (apprlStatus, remarks) => {
        if (this.state.selectedRowKeys.length !== 0) {

            let requestJson =
            {
                "approver_id": this.props.userDetails.userId,
                "approval_status": apprlStatus,
                "request_id": this.state.selectedRowKeys[0],
                "remarks": remarks,
                "flag": this.state.activeTab === '1' ? "pending" : "rejected"
            }
            this.setState({ modalVisible: false });
            this.props.updateReqStatusFunc(requestJson, this.updateState, this.getData, this.props.label_keys, this.isModalVisible);
        } else {
            message.error({ content: <span role="alert"><span className="visually-hidden">Error </span>{this.props.label_keys.selectRecordForApproval}</span> });

        }
        this.setState({ selectedRowKeys: [] });
            $('.focus-heading').attr('tabIndex','-1').focus();    
    }

    getType = (requestNo) => {
        const searchData = [...this.state.pendingData, ...this.state.rejectedData]
        var result = searchData.find(obj => {
            return obj.requestNo === requestNo;
        })
        return result.type;
    }

    getCards = (requestNo) => {
        const searchData = [...this.state.pendingData, ...this.state.rejectedData]
        var result = searchData.find(obj => {
            return obj.requestNo === requestNo;
        })
        return result.noOfCards;
    }
    getProvisionerId = (requestNo) => {
        const searchData = [...this.state.pendingData, ...this.state.rejectedData]
        var result = searchData.find(obj => {
            return obj.requestNo === requestNo;
        })
        return result.provisionerId;
    }
    getMaxCardLimitValue = () => {
        const currentData = this.state.activeTab === '1' ? this.state.pendingtableData : this.state.rejectedtableData;
        let maxValue = Math.max.apply(null,
            currentData.map(function (o) { return o.creditLimit; }));
        return maxValue;
    }

    getMinCardLimitValue = () => {
        const currentData = this.state.activeTab === '1' ? this.state.pendingtableData : this.state.rejectedtableData;
        let minValue = Math.min.apply(null,
            currentData.map(function (o) { return o.creditLimit; }));
        return minValue;
    }

    /*Pagination methods */
    itemRender = (current, type, originalElement) => {
        if (type === 'prev') {
            return <a><Icon aria-label={null} type="arrow-left" /></a>

        }
        if (type === 'next') {
            return <a><Icon aria-label={null} type="arrow-right" /></a>;
        }
        return originalElement;

    }

    pageChanger = (page, defaultPageSize) => {
        if (this.state.activeTab === '1') {
            let TotalPage = Math.ceil(this.state.pendingData.length / defaultPageSize)
            this.setState({ current: page, TotalPage: TotalPage })

        } else {
            let TotalPage = Math.ceil(this.state.rejectedData.length / defaultPageSize)
            this.setState({ current: page, TotalPage: TotalPage })
        }


    }

    OnPageSizeChange = (current, size) => {
        if (this.state.activeTab === '1') {
            let TotalPage = Math.ceil(this.state.pendingData.length / size)
            this.setState({ current: current, TotalPage: TotalPage })
        } else {
            let TotalPage = Math.ceil(this.state.rejectedData.length / size)
            this.setState({ current: current, TotalPage: TotalPage })
        }

    }

    pageCountSetter = () => {
        if (this.state.activeTab === '1') {
            this.state.pendingData && this.setState({ TotalPage: (Math.ceil(this.state.pendingData.length / 10)), current: 1 })

        }
        else {
            this.state.rejectedData && this.setState({ TotalPage: (Math.ceil(this.state.rejectedData.length / 10)), current: 1 })
        }
    }
    isDataHavingFailedData = (data) => {
        if (data.returnData) {
            for (let i = 0; i < data.returnData.length; i++) {
                if (data.returnData[i].status === 'Process Failed') {
                    return true;
                }
            }
            return false;
        } else {
            return "getCall";
        }
    }

    handleRetry = (data) => {
        if (data.returnData && data.returnData.length !== 0) {
            let needApprovalJson = {};
            let requestArray = [];
            needApprovalJson.card_ids = [];
            for (let i = 0; i < data.returnData.length; i++) {
                if (data.returnData[i].status === 'Process Failed') {
                    requestArray.push({ "request_id": data.returnData[i].request_no, "type": this.getType(data.returnData[i].request_no), "provisionerId": this.getProvisionerId(data.returnData[i].request_no), "remarks": "remarks" });
                }
            }
            let requestJson =
            {
                "approver_id": this.props.userDetails.userId,
                "approval_status": "Approved",
                "requestArray": requestArray,
                "flag": this.state.activeTab === '1' ? "pending" : "rejected"
            }
            this.setState({ modalVisible: false });
            this.setState({ dataSave: true });
            loaderFocus(".loader-color .ant-spin-text");
            this.props.updateReqStatusFunc(requestJson, this.updateState, this.getData, this.props.label_keys, this.isModalVisible);
            //this.setState({ modalResultVisible: true });
            // (message.error(this.props.label_keys.selectRecordForApproval))
        }
    }

    requestDetails() {
        this.setState({ previousActiveElement: document.activeElement, dataVisible: !this.state.dataVisible })
    }
    //For Accessability to make defalut focus on tabbing 
    defaultFocus = () => {
        if (ReactDOM.findDOMNode(this.refs.apprTab) && this.state.firstTimeLoad) {
            ReactDOM.findDOMNode(this.refs.apprTab).focus();
            this.setState({ firstTimeLoad: false })
        }
    }


    render() {
        const { TabPane } = Tabs;
        const { selectedRowKeys, pendingFilterTable, rejectedFilterTable } = this.state;
        let { pendingData, rejectedData } = this.state;
        let { sortedInfo } = this.state;
        pendingData = pendingData || [];
        rejectedData = rejectedData || [];
        sortedInfo = sortedInfo || {};
        const { visible, loading } = this.state;
        let disabled = this.state.selectedRowKeys.length === 1;
        /** Pending Approval tab Footer Button */
        const pendingFooter = <Row className="myApprover-zoom">
            <Col span={16}>&nbsp;</Col>
            <Col span={8} 
                style={{ zIndex: '2', marginTop: '-3.5%'} }>
            <Col span={10} className="mr-10" >
                {(pendingData.length > 0) &&
                    <>
                        <Button className="reject-btn reject-btn-zoom" onClick={this.showModal} style={disabled ? {color: '#0A41C5', borderColor: '#0A41C5' } : null} disabled={!disabled}>{this.props.label_keys.reject}</Button>
                        <Modal className="confirm-visible modalAlign modalAlign-zoom"
                            okButtonProps={{ form: 'category-editor-form', key: 'submit', htmlType: 'submit' }}
                            visible={this.state.modalVisible}
                            onCancel={this.handleModalCancel} cancelButtonProps={{className: 'cancel-btn-ant'}}
                            cancelText={this.props.label_keys.cancel}
                            title={<h2 style={{ fontSize: '16px', fontWeight: "700", color: "#161a80" }}>{this.props.label_keys.rejectionRemarks}</h2>}
                            onOk={this.rejectFormSubmit}
                            footer={[
                            ]}
                        >
                            <RejectApprovalComponent approvalFunc={this.handleReject} reference={this.rejectionAreaRef} onCancel={this.handleModalCancel} label_keys={this.props.label_keys} /></Modal>
                    </>
                }
            </Col><Col span={12} className="mr-10" >
                {(pendingData.length > 0) &&
                    <Button type="primary approve-zoom" style={{ marginLeft: this.props.selectedLang === 'French' ? '10%' : null }} onClick={() => { this.handleApproval('Approved', '') }} disabled={!this.state.selectedRowKeys.length > 0} >{this.props.label_keys.approve}</Button>}

            </Col>
            </Col>
        </Row>

        /** Rejectedc tab Footer Button */
        const rejectedFooter = <Row style={{ marginLeft: this.props.selectedLang === 'French' ? '-2%' : "15%", marginTop: "-3.5%" }}>
            <Col span={16}>&nbsp;</Col>
            {(rejectedData.length > 0) &&

                <Col span={8} style={{ marginLeft: this.props.selectedLang === 'French' ? '-1%' : null }}>
                    <Col span={10} className="mr-10"> <Button onClick={this.handleCancel} className="cancel-btn-ant"
                        // style={{marginLeft:this.props.selectedLang === 'French'? '-5%' : null}}
                        style={this.state.selectedRowKeys.length > 0 ? {color: '#0A41C5', borderColor: '#0A41C5' } : null}
                        disabled={!this.state.selectedRowKeys.length > 0} >{this.props.label_keys.cancel}</Button>
                    </Col>
                    <Col span={12} className="mr-10" >
                        <Button  type="primary" onClick={() => this.handleApproval('Approved', '')} disabled={!this.state.selectedRowKeys.length > 0}>{this.props.label_keys.approve}</Button>
                    </Col>
                </Col>
            }
        </Row>

        /**Table heade for both pending rejected tabs */
        const cols = [
            {
                title: prepareTitle('0', <span role="button" ><span aria-hidden="true">{this.props.label_keys.requestNo}</span>
                <span className="visually-hidden">{this.props.label_keys.requestNumber}</span></span>),
                dataIndex: "requestNo",
                key: "requestNumber",
                align: 'left',
                width: '20%',
                sorter: (a, b) => {
                    return a.requestNo < b.requestNo ? -1 : 1
                },
                sortOrder: sortedInfo.columnKey === 'requestNumber' && sortedInfo.order,
                render: text => <div tabIndex={0} role="button" onKeyUp={e => {
                    if (e.keyCode === 13) {
                        this.requestDetails()
                    }
                }} onClick={() => {
                    this.requestDetails()
                }}><a><u>{text}</u></a></div>
            },
            {
                title: prepareTitle('0', <span role="button" >{this.props.label_keys.type}</span>),
                dataIndex: "type",
                key: "type",
                align: 'left',
                width: '20%',
                sorter: (a, b) => {
                    let name1 = a.type.toLowerCase();
                    let name2 = b.type.toLowerCase();
                    return name1 < name2 ? -1 : 1
                },
                sortOrder: sortedInfo.columnKey === 'type' && sortedInfo.order,
            },

            {
                title: prepareTitle('0', <span role="button" >{this.props.label_keys.provisioner}</span>),
                dataIndex: "provisioner",
                key: "provisioner",
                align: 'left',
                width: '20%',
                sorter: (a, b) => {
                    let name1 = a.provisioner.toLowerCase();
                    let name2 = b.provisioner.toLowerCase();
                    return name1 < name2 ? -1 : 1
                },
                sortOrder: sortedInfo.columnKey === 'provisioner' && sortedInfo.order,

            },
            {
                title: prepareTitle('0', <span role="button" >{this.props.label_keys.requestDate}</span>),
                dataIndex: "requestDate",
                key: "requestDate",
                align: 'left',
                width: '20%',
                sorter: (a, b) => { return Number(new Date(a.requestDate)) > Number(new Date(b.requestDate)) ? -1 : Number(new Date(a.requestDate)) < Number(new Date(b.requestDate)) ? 1 : 0 },
                sortOrder: sortedInfo.columnKey === 'requestDate' && sortedInfo.order,
            },
            {
                title: prepareTitle('0', <span role="button" >
                <span aria-hidden="true">{this.props.label_keys.noOfCards}</span>
                <span className="visually-hidden">Number of Cards</span></span>),
                dataIndex: "noOfCards",
                key: "numberOfCards",
                align: 'left',
                width: '20%',
                sorter: (a, b) => { return a.noOfCards < b.noOfCards ? -1 : 1 },
                sortOrder: sortedInfo.columnKey === 'numberOfCards' && sortedInfo.order,

            },
            {
                title: prepareTitle('0', <span role="button" >{this.props.label_keys.creditLimit}</span>),
                dataIndex: "creditLimit",
                key: "creditLimit",
                align: 'left',
                width: '20%',
                sorter: (a, b) => { return a.creditLimit < b.creditLimit ? -1 : 1 },
                sortOrder: sortedInfo.columnKey === 'creditLimit' && sortedInfo.order,

            },
            {
                title: prepareTitle('0', <span role="button" >{this.props.label_keys.cardHolder}</span>),
                dataIndex: "cardHolder",
                key: "cardHolder",
                align: 'left',
                width: '20%',
                sorter: (a, b) => {
                    let name1 = a.cardHolder.toLowerCase();
                    let name2 = b.cardHolder.toLowerCase();
                    return name1 < name2 ? -1 : 1
                },
                sortOrder: sortedInfo.columnKey === 'cardHolder' && sortedInfo.order,
            },
            {
                title: prepareTitle('0', <span role="button" >{this.props.label_keys.status}</span>),
                dataIndex: 'status',
                key: "status",
                align: 'left',
                width: '25%',
                sorter: (a, b) => {
                    let name1 = a.status.toLowerCase();
                    let name2 = b.status.toLowerCase();
                    return name1 < name2 ? -1 : 1
                },
                sortOrder: sortedInfo.columnKey === 'status' && sortedInfo.order,
            }
        ];
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
            onSelect: (record, selected, selectedRows, nativeEvent) => {
                if (document.getElementById(record.requestNo)) {
                    document.getElementById(record.requestNo).setAttribute('aria-checked', selected);
                }

            },
            getCheckboxProps: record => ({ // Column configuration not to be checked
                name: record.requestNo,
                'aria-label': record.requestNo,
                'aria-checked': false,
                role: "checkbox",
                id: record.requestNo,
                onKeyDown: checkBoxStatus(this.state.activeTab === '1' ? '#approv-tab-panel' : '#reject-tab-panel')
            }),
        };

        const handleSizeChange = e => {
            console.log("handle chage ::", e);
            this.setState({ filterSelectedType: e.target.value });
        };

        /**Filter Search Buttons */
        const operations = <div>
            <><div >
                <Search
                    className="search-input approver-search"
                    style={{ width: '70%' }}
                    placeholder={this.props.label_keys.search}
                    onChange={(e) => {
                        if (e.target.value === "" || e.target.value === null) {
                            this.setState({ searchTerm: "" });
                        } else {
                            this.setState({ searchTerm: e.target.value });
                        }
                    }}
                    autocomplete="off"
                    onSearch={this.search} />
                &nbsp;&nbsp;
                <Button type="primary filter-comp filter-zoom" onClick={this.showFilter} onOk={this.filterData} okText={this.props.label_keys.applyFilter} style={{ height: "auto" }}>
                    <img className="filter" src={Filter} alt="filter" />
                </Button>
            </div>
                <Modal className="confirm-visible filter-modal filter-modal-zoom" role="dialog"
                    title={<b role="heading" aria-level="2">{this.props.label_keys.filter}</b>}
                    cancelButtonProps={{ id: 'modal-cancel-btn' }}
                    visible={this.state.modalFilterVisible} onCancel={this.handleFilterCancel} onOk={this.filterData} okText={this.props.label_keys.applyFilter} cancelText={this.props.label_keys.cancel}>
                    <div role="group" aria-labelledby="type">
                        {bulkFeature.enable && <div > <Row style={{ marginBottom: 8, fontSize: 18 }}>
                            <Col id="type" style={{ float: 'left' }}>{this.props.label_keys.cardType}</Col>
                        </Row>
                            <Row style={{ marginBottom: 18 }}>
                                <Col>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={this.state.isSingle}
                                                aria-label="Check Type"
                                                disableRipple
                                                id="singleType"
                                                className='singleType'
                                                onChange={e => { this.setState({ isSingle: !this.state.isSingle, filterSelectedType: "Single" }) }}
                                                name={this.props.label_keys.single}
                                                style={{ color: '#19398A' }}
                                                sx={{ '& .MuiSvgIcon-root': { fontSize: 20, fontWeight: 200 } }}
                                                inputProps={{ className: "singleTypeCheckbox", id: 'single-type' }}
                                            />
                                        }
                                        // style={{width: '100%', marginTop: '-6px'}}
                                        label={<span className="singleTypeLabel"
                                            style={{ marginLeft: '7px', marginRight: '7px', fontSize: '14px', fontWeight: '400', color: '#666666' }}>
                                            {this.props.label_keys.single}</span>}

                                    />
                                    <FormControlLabel
                                        style={{ marginRight: '-20px' }}
                                        control={
                                            <Checkbox
                                                checked={this.state.isBulk}
                                                aria-label="Check Type"
                                                disableRipple
                                                className='bulkType'
                                                onChange={e => { this.setState({ isBulk: !this.state.isBulk, filterSelectedType: "Bulk" }) }}
                                                name={this.props.label_keys.bulk}
                                                style={{ color: '#19398A' }}
                                                sx={{ '& .MuiSvgIcon-root': { fontSize: 20, fontWeight: 200 } }}
                                                inputProps={{ className: "bulkTypeCheckbox", id: 'bulk-type' }}
                                            />
                                        }
                                        // style={{width: '100%', marginTop: '-6px'}}
                                        label={<span className="bulkTypeLabel"
                                            style={{ marginLeft: '7px', marginRight: '7px', fontSize: '14px', fontWeight: '400', color: '#666666' }}>
                                            {this.props.label_keys.bulk}</span>}
                                    />
                                    {/* <Radio.Group onChange={handleSizeChange}>
                                        <Radio.Button  value="Single">{this.props.label_keys.single}</Radio.Button>&nbsp;&nbsp;
                                        <Radio.Button value="Bulk">{this.props.label_keys.bulk}</Radio.Button>
                                    </Radio.Group> */}
                                </Col>
                            </Row></div>}
                        <Row style={{ marginBottom: 8, fontSize: 18 }}>
                            <Col style={{ float: 'left' }}>{this.props.label_keys.creditLimit}</Col>
                        </Row>
                        <Row style={{ marginBottom: 8 }}>
                            <Col>
                                <Slider
                                    range
                                    aria-label='credit limit'
                                    min={this.state.minValue}
                                    max={this.state.maxValue}
                                    value={[this.state.left, this.state.right]}
                                    onChange={e => this.setState({ left: e[0], right: e[1] })}
                                />

                            </Col>
                        </Row>
                    </div>
                </Modal>

            </>

        </div>;
        const pendingApprovalLabel = this.props.label_keys.pendingApproval + "(" + pendingData.length + ")";
        const RejectedLabel = this.props.label_keys.rejected + "(" + rejectedData.length + ")";
        /**Response Popup  */
        return (
            <>
                <div role="main">
                    {
                        (this.props.responseObj && this.props.responseObj.status && this.props.responseObj.status !== 201 && this.isDataHavingFailedData(this.props.responseObj)) &&
                        (
                            <Modal className="confirm-visible modalAlign" visible={this.state.modalResultVisible}
                                title={<h2 style={{ fontSize: '16px', fontWeight: "700", color: "#161a80" }}>{this.props.label_keys.alert}</h2>} onOk={() => this.handleRetry(this.props.responseObj)}
                                okText={this.props.label_keys.retry} onCancel={this.setModalResultVisible}
                                cancelText={this.props.label_keys.cancel} closable>
                                <><img src={warningIcon} alt="Error" /></><br /><br />
                                <p>{this.props.label_keys.failedCreationPopupMsg} </p>
                            </Modal>
                        )
                    }{

                        (this.props.responseObj && !this.isDataHavingFailedData(this.props.responseObj) && this.isDataHavingFailedData(this.props.responseObj) !== "getCall") &&
                        (
                            <Modal visible={this.state.modalResultVisible}
                                title={<h2 style={{ fontSize: '16px', fontWeight: "700", color: "#161a80" }}>{this.props.label_keys.alert}</h2>} onCancel={this.setModalResultVisible} footer={[]} cancelText={this.props.label_keys.cancel}>
                                <Result
                                    icon={<Icon type="check-circle" theme="filled" aria-hidden='true' />}
                                    status="success"
                                    subTitle={this.props.label_keys.cardCreateSuccessMsg}
                                /></Modal>
                        )
                    }
                    {(this.state.approvedClick && this.state.approvedPopupVisible) &&
                        <>
                            <Modal className="custom-modal custom-modal-bulk-card" role="dialog"
                                visible={this.state.approvedPopupVisible}
                                closable={false}
                                aria-labelledby="title"
                                title={<b className="visually-hidden" id="title">Request Number Approved</b>}
                                footer={[<Button ref={this.modalBtnRef} type="primary" onKeyDown={(e) => this.handleEsc(e)} onClick={this.handleApprovedModal}>Ok</Button>]}
                            >
                                <div className="custom-modal-content">
                                    <img src={Tick} alt='success' />
                                    <p><span>{this.state.selectedRowKeys2.length} of {this.state.pendingData2.length} request(s) has been approved.</span></p>
                                    <span>Card creation in process.</span>
                                </div>
                            </Modal>
                        </>
                    }
                    {this.state.extraRequest &&
                        <>
                            <Modal className="confirm-visible modalAlign" visible={this.state.extraRequest}
                                title={<h2 style={{ fontSize: '16px', fontWeight: "700", color: "#161a80" }}>{this.props.label_keys.alert}</h2>}
                                onOk={() => { this.setState({ extraRequest: false }) }}
                                cancelButtonProps={{ style: { display: 'none' } }}
                                okText={this.props.label_keys.ok} closable>
                                <><img src={warningIcon} alt='Error' /></><br /><br />
                                <p>{this.props.label_keys.selectedCardsExceedsMsg}</p>
                            </Modal>
                        </>}
                    {this.state.dataSave || this.state.loading ? <div className="dark-spinner loader-color"><Spin tip={this.props.label_keys.loading} /></div> : null}
                    <Card style={{ borderRadius: "12px" }} className='container ant-card-pv'>
                        <div aria-relevant="text"
                            aria-live={sortedInfo.order === "ascend" || sortedInfo.order === "descend" ? "polite" : "off"}
                            aria-atomic="true" className="visually-hidden">{
                                sortedInfo.order === "ascend" || sortedInfo.order === "descend" ?
                                  <div><span>Table sorted by {sortedInfo.columnKey}</span>
                                    <span>in {sortedInfo.order}ing order</span></div> : null
                                }  </div>
                        <Row className='myApproval-extra'>
                            <Col span={12}>{operations}</Col>
                        </Row>
                        <Tabs className="approver-extra" value={this.state.activeTab}
                            style={{
                                borderBottom: '1px solid #e8e8e8', marginTop: '-30px', marginBottom: '5px',
                                paddingTop: '10px', float: 'left', width: '100%', textTransform: 'none',
                            }}
                            onChange={this.changeTab}
                            size="small" >
                            <Tab disableRipple id="approv-tab" aria-controls='approv-tab-panel'
                                className="approv-tab" value='1'
                                label={<div>{pendingApprovalLabel}</div>}
                            />
                            <Tab disableRipple id="reject-tab" aria-controls='reject-tab-panel'
                                className="reject-tab"
                                value='2'
                                label={<div>{RejectedLabel} </div>} />

                        </Tabs>

                        <div role="tabpanel" id='approv-tab-panel' aria-labelledby='approv-tab' value='1'
                            hidden={this.state.activeTab !== '1'} style={{ marginTop: '4%' }}>
                            <Table className='Req-table userlist paginationAlign pageArrow'
                                // rowkey="requestNumber"
                                rowKey={obj => obj.requestNo}
                                columns={cols}
                                locale={{ emptyText: this.state.loading? null : this.props.label_keys.dataUnavailable }}
                                rowSelection={rowSelection}
                                onChange={this.handleChange}
                                // loading={this.state.loading}
                                // dataSource={pendingFilterTable !== null ? pendingFilterTable : pendingSearchData !== null ? pendingSearchData : pendingData}
                                dataSource={pendingData}
                                // footer={() => pendingFooter}
                                // style={{ marginLeft: "2%", minWidth: "250px", marginRight: "2%", marginBottom: "2%" }}
                                scroll={{ x: 1500}}
                                pagination={{
                                    defaultPageSize: 10,
                                    showSizeChanger: true,
                                    itemRender: this.itemRender,
                                    current: this.state.current,
                                    pageSizeOptions: ["10", "20", "50", "100"],
                                    position: "bottom",
                                    showTitle: false,
                                    showTotal: (total, range) => <div> <span className="total-text-pagination myApprover-page">{this.props.label_keys.showing} {range[0]}-{range[1]} {this.props.label_keys.of} {total} </span>
                                        <span className="page-count-span-bulk">{this.props.label_keys.page} {this.state.current} {this.props.label_keys.of} {this.state.TotalPage}</span></div>,
                                    onChange: this.pageChanger,
                                    onShowSizeChange: this.OnPageSizeChange
                                }}
                                onRow={(record, rowIndex) => {
                                    return {
                                        onClick: event => {
                                            this.setState({ selectedRow: record })
                                        }, // click row
                                    }
                                }}
                            />
                            <div>{pendingFooter}</div>
                        </div>


                        <div role="tabpanel" id='reject-tab-panel' aria-labelledby='reject-tab' style={{ marginTop: '4%' }}
                            value='2' hidden={this.state.activeTab !== '2'}>
                            <Table className='Req-table userlist paginationAlign pageArrow'
                                rowkey="requestNo"
                                rowKey={obj => obj.requestNo}
                                locale={{ emptyText: this.state.loading? null : this.props.label_keys.dataUnavailable }}
                                // dataSource={rejectedData}
                                columns={cols}
                                rowSelection={rowSelection}
                                // loading={this.state.loading}
                                // dataSource={rejectedFilterTable !== null ? rejectedFilterTable : rejectedSearchData !== null ? rejectedSearchData : rejectedData}
                                dataSource={rejectedData}
                                onChange={this.handleChange}
                                scroll={{ x: 1500 }}
                                // style={{ marginLeft: "2%", minWidth: "250px", marginRight: "2%", marginBottom: "2%" }}
                                pagination={{
                                    defaultPageSize: 10,
                                    showSizeChanger: true,
                                    current: this.state.current,
                                    itemRender: this.itemRender,
                                    pageSizeOptions: ["10", "20", "50", "100"],
                                    position: "bottom",
                                    showTitle: true,
                                    showTotal: (total, range) => <div> <span className="total-text-pagination">{this.props.label_keys.showing} {range[0]}-{range[1]} {this.props.label_keys.of} {total} </span>
                                        <span className="page-count-span" style={{marginLeft: this.props.selectedLang === 'French' ? "0%" : null}}>{this.props.label_keys.page} {this.state.current} {this.props.label_keys.of} {this.state.TotalPage}</span></div>,
                                    onChange: this.pageChanger,
                                    onShowSizeChange: this.OnPageSizeChange
                                }}
                                // footer={() => rejectedFooter}
                                onRow={(record, rowIndex) => {
                                    return {
                                        onClick: event => {
                                            this.setState({ selectedRow: record })
                                        }, // click row
                                    }
                                }}
                            />
                            <div>{rejectedFooter}</div>
                        </div>

                    </Card>
                    {this.state.dataVisible ?
                        <RequestDetails
                            createVisible={this.state.dataVisible}
                            onCLoseCreate={() => {
                                if (this.state.dataVisible) {
                                    if (document.body.contains(this.state.previousActiveElement)) {
                                        this.state.previousActiveElement.focus();
                                    }
                                }
                                this.setState({ dataVisible: !this.state.dataVisible, previousActiveElement: null })
                            }}
                            label_keys={this.props.label_keys}
                            selectedRow={this.state.selectedRow}
                            activeTab={this.state.activeTab}
                        />
                        : null}
                </div>
            </>

        );

    }
}

function mapStateToProps(state, props) {
    return {
        userDetails: state.authenticationReducer.userDetails,
        token: state.authenticationReducer.token,
        iclanguageList: state.instantCardMultiLanReducer.iclanguageList,
        approvalData: state.bulkcardReducer.approvalObj,
        responseObj: state.myApprovalReducer.updateApprovalResponseObj,
        allState: state
    }
}

const mapDispatchToProps = dispatch => {
    return {
        logOutFun: () => { dispatch(logOut()) },
        updateReqStatusFunc: (reqObj, updatestate, getData, label_keys, isModalVisible) => {
            dispatch(updateReqStatus(reqObj, updatestate, getData, label_keys, isModalVisible));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyApproval)
