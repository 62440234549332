import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Alert, Card, Select } from 'antd';
import { Link } from 'react-router-dom';
import qs from 'qs';
import { login, resetPassword, forgotPassword, forgotPasswordStateReset, sendOTP, activateAccountStateReset, verifyOTP, resetPasswordStateReset } from '../../actions/authenticationActions';
import { withRouter } from 'react-router-dom';
import ElanLoginFormComponent from './ElanLoginFormComponent';
import ResetPasswordFormComponent from './ResetPasswordFormComponent';
import ElanResetPassword from './ElanResetPassword.js';
import RegisterFormComponent from './RegisterFormComponent';
import OpenSourceLicense from '../OpenSourceLicense';
import { errorMessages } from '../../data/constants';
import { fetchinstantCardListLangauages, instantCardLabelRetrieve } from '../../actions/instantCardMultiLanAction'
import ElanLogo from '../../images/elan/Group 22213.svg'
import { multiLanguageLabelRetrieve } from '../../actions/multilanAction'
import LanguageSelectBtn from '../ProvisionerScreen/LanguageSelect';

//import ElanWhiteLogo from '../../images/elan/CPS_Logo_White.svg'
import '../../styling/elanLogin.css';

const { Option } = Select;

class ElanComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedLang: 'EN',
        }
    }

    componentDidMount() {
        this.props.fetchinstantCardListLangauages();
        this.props.instantCardLabelRetrieve('elan');
        this.props.multiLanguageLabelRetrieve('EN');
    }

    isUrlMalformed(type = 'resetPassword', url) {
        switch (type) {
            case 'resetPassword':
                let queryString = url;
                return typeof queryString !== 'string'
            default:
                return false
        }
    }

    getRequestTypeForResetPassword(queryString) {
        return !this.isUrlMalformed('resetPassword', queryString) && qs.parse(queryString).type
    }

    languageChange = (event) => {
        if(this.state.selectedLang !== event.target.textContent){
            this.setState({ selectedLang: event.target.textContent })
        }
    }

    redirectToLogin = () => {
        this.props.history.push('/login')
    }

    render() {
        let requestType
        if (this.props.match.url === '/resetPassword') {

            requestType = this.getRequestTypeForResetPassword(this.props.location.search.slice(1))
            if (!requestType) {
                this.props.history.push('/login')
                return null
            }
        }
        return (this.props.label_keys &&
            <div className="container-header-elan">
                <Row role="banner">
                    <Col  xs={16} sm={16} md={20} lg={20} xl={20} offset={1} >
                        <span tabIndex={(this.props.match.url === '/login') ? null : 0} 
                            style={{cursor: (this.props.match.url === '/login') ? "auto" :"pointer"}}
                            role={(this.props.match.url === '/login') ? null : "link"}
                            aria-label={(this.props.match.url === '/login') ? null : "Elan Easy Pay"} onClick={this.redirectToLogin}>
                            <img src={require(`../../images/provisioner/elan/logo.svg`).default} height="25px" alt='Elan' />&nbsp;
                            <span className="instant-card-elan">|&nbsp;&nbsp;{this.props.label_keys[this.state.selectedLang].instantCard}</span>
                            {/* <span style={{ top: '10%', position: 'relative' }}>{this.props.label_keys[this.state.selectedLang].instantCard}</span> */}
                        </span>
                    </Col>
                    <Col xs={6} sm={1} md={1} offset={1}>
                        {/* <Select className='elan-select' value={this.state.selectedLang}
                            showArrow={false}
                            onChange={this.languageChange}>
                            {this.props.iclanguageList && this.props.iclanguageList.map(item =>{
                                if(item.code == 'EN')  {return <Option value={item.code}>{item.code}</Option>}
                                else {return <Option value={item.code}  disabled>{item.code}</Option>}
                            } 
                            )}
                             
                        </Select> */}
                        {/* <LanguageSelectBtn onChange={this.languageChange}
                            value={this.state.selectedLang}
                            iclanguageList={this.props.iclanguageList}
                            theme="elan"
                        /> */}
                    </Col>
                </Row>
                <Card className="box-content" role="main">
                    <Row>
                        <Col className="col-logo-elan" xs={{ span: 0, offset: 0 }} sm={{ span: 0, offset: 0 }} md={{ span: 4, offset: 4 }} lg={{ span: 4, offset: 2 }} style={{ marginTop: '19%' }}>
                            <img src={ElanLogo} alt='Elan logo' width='100%' />
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 12, offset: 4 }} lg={{ span: 12, offset: 4 }} style={{ marginTop: '4%' }}>
                            {(this.props.match.url === '/resetPassword') ?
                                <div style={{ marginTop: '-4%' }}>
                                    <Row>
                                        {
                                            requestType === 'otp' && this.props.credentialsResetSuccess !== true ? (
                                                <Alert
                                                    style={{ marginBottom: '5px' }}
                                                    message={errorMessages.sendOtpSuccessMessage}
                                                    type="info" />
                                            ) : null
                                        }
                                        {
                                            this.props.credentialsResetError || this.props.credentialsResetSuccess === true ? this.props.history.push('/login')
                                                : null
                                        }
                                    </Row>
                                    {
                                        this.props.credentialsResetSuccess !== true && !this.props.credentialsResetError ?
                                            (
                                                <Row>
                                                    <ResetPasswordFormComponent
                                                        label_keys={this.props.label_keys[this.state.selectedLang]}
                                                        onSubmit={(values) => {
                                                            this.props.resetPassword(values);
                                                        }}
                                                        isPasswordResetting={this.props.isPasswordResetting}
                                                        requestType={requestType}
                                                        resetPasswordStateReset={this.props.resetPasswordStateReset}
                                                    />
                                                </Row>
                                            ) : null
                                    }
                                </div> :
                                (this.props.match.url === '/forgotPassword') ?
                                    <ElanResetPassword
                                        label_keys={this.props.label_keys[this.state.selectedLang]}
                                        sendOTPRequest={this.props.sendOTPRequest}
                                        sendOTPSuccess={this.props.sendOTPSuccess}
                                        sendOTPError={this.props.sendOTPError}
                                        verifyOTPRequest={this.props.verifyOTPRequest}
                                        verifyOTPSuccess={this.props.verifyOTPSuccess}
                                        verifyOTPError={this.props.verifyOTPError}
                                        activateAccountStateReset={this.props.activateAccountStateReset}
                                        sendOTP={(e) => this.props.sendOTP(e)}
                                        verifyOTP={(e) => this.props.verifyOTP(e)}
                                        resetPasswordStateReset={this.props.resetPasswordStateReset}
                                    /> :

                                    (this.props.match.url === '/register') ?
                                        <RegisterFormComponent
                                            label_keys={this.props.label_keys[this.state.selectedLang]}
                                            selectedLang={this.state.selectedLang}
                                            sendOTP={(e) => this.props.sendOTP(e)}
                                        /> :
                                        (this.props.match.url === '/openSourceLicenses') ?
                                            <Row>
                                                {/* <h1 className="visually-hidden">Open Source License</h1> */}
                                                <OpenSourceLicense
                                                    label_keys = {this.props.label_keys[this.state.selectedLang]}
                                                />
                                            </Row>
                                        :
                                            <Row>
                                                <ElanLoginFormComponent label_keys={this.props.label_keys[this.state.selectedLang]} onSubmit={(values, rmCheck) => {
                                                    this.props.login(values, rmCheck);
                                                }}
                                                    isAuthenticating={this.props.isAuthenticating}
                                                    authenticationError={this.props.authenticationError}
                                                    selectedLang={this.state.selectedLang}
                                                    sendOTP={(e) => this.props.sendOTP(e)}
                                                    credentialsResetError={this.props.credentialsResetError}
                                                    credentialsResetSuccess={this.props.credentialsResetSuccess} />
                                            </Row>
                            }
                        </Col>
                    </Row>
                </Card>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        isAuthenticating: state.authenticationReducer.isAuthenticating,
        isAuthenticated: state.authenticationReducer.isAuthenticated,
        isCredentialsResetting: state.authenticationReducer.isCredentialsResetting,
        authenticationError: state.authenticationReducer.authenticationError,
        credentialsResetError: state.authenticationReducer.credentialsResetError,
        credentialsResetSuccess: state.authenticationReducer.credentialsResetSuccess,
        isForgettingCredentials: state.authenticationReducer.isForgettingCredentials,
        forgotCredentialsSuccess: state.authenticationReducer.forgotCredentialsSuccess,
        forgotCredentialsError: state.authenticationReducer.forgotCredentialsError,
        sendOTPRequest: state.authenticationReducer.sendOTPRequest,
        sendOTPSuccess: state.authenticationReducer.sendOTPSuccess,
        sendOTPError: state.authenticationReducer.sendOTPError,
        verifyOTPRequest: state.authenticationReducer.verifyOTPRequest,
        verifyOTPSuccess: state.authenticationReducer.verifyOTPSuccess,
        verifyOTPError: state.authenticationReducer.verifyOTPError,
        iclanguageList: state.instantCardMultiLanReducer.iclanguageList,
        label_keys: state.instantCardMultiLanReducer.label_keys
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchinstantCardListLangauages: () => {
            dispatch(fetchinstantCardListLangauages())
        },
        instantCardLabelRetrieve: (elan) => {
            dispatch(instantCardLabelRetrieve(elan))
        },
        login: (values, rmCheck, grantType, touch_token) => {
            dispatch(login(values, grantType, touch_token, rmCheck))
        },
        resetPassword: (values) => {
            dispatch(resetPassword(values))
        },
        forgotPassword: (values) => {
            dispatch(forgotPassword(values))
        },
        forgotPasswordStateReset: () => {
            dispatch(forgotPasswordStateReset())
        },
        resetPasswordStateReset: () => {
            dispatch(resetPasswordStateReset())
        },
        activateAccountStateReset: () => {
            dispatch(activateAccountStateReset())
        },
        sendOTP: (values) => {
            dispatch(sendOTP(values))
        },
        verifyOTP: (values) => {
            dispatch(verifyOTP(values))
        },
        multiLanguageLabelRetrieve: (values) => {
            dispatch(multiLanguageLabelRetrieve(values))
        }
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ElanComponent));