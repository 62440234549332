import React from "react";
import '../../../styling/viewEditProfile.css';
import { Row, Col,  Avatar, Input, notification, Select, Modal, Button  } from 'antd';
import axios from 'axios';
import { Autocomplete, Switch, TextField } from '@mui/material';
import { connect } from 'react-redux';
import Tick from '../../../images/bulk/tick.svg'

import { deleteUserAction, resetUserDelete } from "../../../actions/userAction";
const { URLs, errorMessages, deleteUserConstants } = require('../../../data/constants.js');

// const { app_labels } = require('../../../data/english_label');
const Option = Select.Option;
var children = [];
const confirm = Modal.confirm;
var userRoleIds = [];
var userRoleDesc = [];
var phoneNoString = "";
var phoneNoString1 = "";
class EditProfile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            editcolor: "black",
            //Name: this.capitalize(this.props.data.firstName)+" "+this.capitalize(this.props.data.lastName), //Removed capitalize EW-817
            //Name: this.props.data.firstName + " " + this.props.data.lastName,
            Name: this.props.data.firstName.trim() + " " + this.props.data.lastName.trim(), //EW-933 - trim first and last name
            dropdownChildren: [],
            checked: this.props.data.viewActiveCardEnable,
            successModalVisible: false,
            showDeleteModal: false,
            isDeleted: false

        }
    }

    /**To display texts in correct foramt(First letter will be in Capital)
   * @param  {} text
   */
    capitalize = (text) => {
        var s = text.toLowerCase();
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
    }
    /**
     * @param  {} e
     * name has been mapped to state variable
     */
    nameChange = (e) => {
        this.sendFirstAndLastName(e.target.value.trim());
        this.setState({ Name: e.target.value.trim() });
    }

    /**
     * sends the first name and last name to updateEditedProfileData function
     * @param {*} Name 
     */
    sendFirstAndLastName(Name) {
        let newData = this.props.data;
        let newName = Name.split(" ");
        newData["firstName"] = newName.length >= 1 ? newName[0] : "";
        newData["lastName"] = newName.length >= 2 ? Name.substring(Name.indexOf(" ") + 1, Name.length) : "";

        this.props.updateEditedProfileData(newData);
    }

    /**
     * Phone number format in the required convention
     */
    phoneNoFormat = (value, e) => {
        phoneNoString = value.toString()
        phoneNoString1 = ""
        for (let i = phoneNoString.length - 1; i > phoneNoString.length - 5; i--) {
            if (phoneNoString[i] === ' ') {
                continue
            }
            else {
                phoneNoString1 = phoneNoString[i] + phoneNoString1
            }
        }
        phoneNoString1 = " " + phoneNoString1
        for (let i = phoneNoString.length - 5; i > phoneNoString.length - 8; i--) {
            if (phoneNoString[i] === ' ') {
                continue
            }
            else {
                phoneNoString1 = phoneNoString[i] + phoneNoString1
            }
        }
        phoneNoString1 = " " + phoneNoString1
        for (let i = phoneNoString.length - 8; i > phoneNoString.length - 11; i--) {
            if (phoneNoString[i] === ' ') {
                continue
            }
            else {
                phoneNoString1 = phoneNoString[i] + phoneNoString1
            }
        }
        if (phoneNoString.length > 10) {
            phoneNoString1 = " " + phoneNoString1
            for (let i = phoneNoString.length - 11; i >= 0; i--) {
                if (phoneNoString[i] === ' ') {
                    continue
                }
                else {
                    phoneNoString1 = phoneNoString[i] + phoneNoString1
                }
            }
        }
        return phoneNoString1
    }

    /**
     * @param  {} value
     * @param  {} e
     * will be called on change of Role
     */
    roleChange = (value, e) => {
        userRoleIds = [];
        userRoleDesc = [];
        let item = this.props.cadreRoles;
        for (let i = 0; i < value.length; i++) {
            for (let j = 0; j < item.length; j++) {
                if (item[j].roleDesc === value[i]) {
                    userRoleIds.push(parseInt(item[j].roleId));
                    userRoleDesc.push(value[i]);
                }
            }
        }
        children = [];
        userRoleDesc.length === 0 ? this.props.cadreRoles.map(item =>
            children.push(<Option key={item.roleDesc}>{item.roleDesc}</Option>)) :
            userRoleDesc.includes('CSR') ?
                this.props.cadreRoles.map(item =>
                    children.push(<Option disabled={true} key={item.roleDesc}>{item.roleDesc}</Option>)) :
                this.props.cadreRoles.map(item =>
                    item.roleDesc === 'CSR' ? children.push(<Option disabled={true} key={item.roleDesc}>{item.roleDesc}</Option>) :
                        children.push(<Option disabled={false} key={item.roleDesc}>{item.roleDesc}</Option>))
        this.setState({ dropdownChildren: children })
        // this.sendRoles(userRoleDesc);
    }

    /**
     * rends the user role to updateEditedProfileData function
     * @param {*} roles 
     */
    sendRoles(roles) {
        let data = this.props.data;
        data["roles"] = roles;
        this.props.updateEditedProfileData(data);
    }

    /**
     * Lifecycle Method, its Called on Mount of the component
     */
    componentDidMount() {
        userRoleIds = [];
        userRoleDesc = [];
        this.setState({ Name: this.props.data.firstName.trim() + " " + this.props.data.lastName.trim() });
        let item = this.props.cadreRoles;
        for (let i = 0; i < this.props.data.roles.length; i++) {
            for (let j = 0; j < item.length; j++) {
                if (item[j].roleDesc === this.props.data.roles[i]) {
                    userRoleIds.push(parseInt(item[j].roleId));
                    userRoleDesc.push(this.props.data.roles[i]);
                }
            }
        }
        children = [];
        userRoleDesc.includes('CSR') ?
            this.props.cadreRoles.map(item =>
                children.push(<Option disabled={true} key={item.roleDesc}>{item.roleDesc}</Option>)) :
            this.props.cadreRoles.map(item =>
                item.roleDesc === 'CSR' ? children.push(<Option disabled={true} key={item.roleDesc}>{item.roleDesc}</Option>) :
                    children.push(<Option key={item.roleDesc}>{item.roleDesc}</Option>))
        this.setState({ dropdownChildren: children })
    }

    componentDidUpdate(){
        const icon = document.querySelectorAll('.anticon.anticon-user');
        if (icon) {
            icon.forEach(el => {
                el.setAttribute('role', 'img')
                el.setAttribute('aria-hidden', 'true')
            })
        }

        const inputDiv = document.getElementsByClassName('MuiInput-input MuiInputBase-input MuiInputBase-inputAdornedEnd MuiAutocomplete-input MuiAutocomplete-inputFocused');
        if(inputDiv && inputDiv[0]){
            if(!inputDiv[0].getAttribute('aria-label')){
                inputDiv[0].setAttribute('aria-label', 'Select roles');
            }
        }
        const iconClass = document.getElementsByClassName('icon-Edit')
        if(iconClass[0]){
            iconClass[0].classList.add('icon-zoom-class')
        }
        if (this.state.successModalVisible) {
            setTimeout(() => {
                const div = document.querySelectorAll('.ant-modal-root .ant-modal-wrap');
                if (div) {
                    div.forEach(el => {
                        if (!el.getAttribute("aria-label") && el.getAttribute("aria-labelledby")) {
                            el.ariaLabel = "Profile Detail Update";
                            el.removeAttribute("aria-labelledby")
                        }
                    })
                };
            }, 500)
        }
        if(this.state.editcolor !== "black"){
            const divAuto = document.getElementsByClassName("MuiChip-deletableColorDefault MuiChip-filledDefault MuiAutocomplete-tag MuiAutocomplete-tagSizeMedium");
            // console.log("Div  autocomplete::", divAuto, divAuto.length);
            if(divAuto[0]){
                for(let i=0; i<divAuto.length;  i++ ){
                    divAuto[i].setAttribute('tabindex', "0");
                    // divAuto[i].setAttribute("role", "button");
                    divAuto[i].setAttribute('aria-label', `${divAuto[i].innerText}, press backspace key to delete`);
                }

            }
            // const divAutoFocus = document.getElementsByClassName('MuiButtonBase-root MuiChip-root MuiChip-filled MuiChip-sizeMedium MuiChip-colorDefault MuiChip-deletable MuiChip-deletableColorDefault MuiChip-filledDefault MuiAutocomplete-tag MuiAutocomplete-tagSizeMedium');
            // console.log("Div  autocomplete::", divAutoFocus, divAutoFocus.length);
            // if(divAutoFocus[0]){
            //     for(let i=0; i<divAutoFocus.length;  i++ ){
            //         divAutoFocus[i].setAttribute('aria-checked', "true");
            //     }

            // }
        }
    }
    /**
     *onClick of cancel
     */
    onCancel = () => {
        this.setState({ editcolor: "black", checked: this.props.data.viewActiveCardEnable })
        document.getElementById('edit-btn') && document.getElementById('edit-btn').focus();
    }
    /**
     * update user profile
     */
    updatEProfile = () => {
        this.props.spinFunc(true)
        let firstName;
        let lastName;
        let index = this.state.Name.lastIndexOf(" ");
        if (index === -1) {
            firstName = this.state.Name;
            lastName = "";
        }
        else {
            firstName = this.state.Name.substring(0, index);
            lastName = this.state.Name.substring(index + 1, this.state.Name.length);
        }
        if (!(firstName && firstName.trim().length > 0)) {
            notification.warning({
                message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{this.props.app_labels.error_req_editName}</span>,
                duration: 3,
                style: {
                    backgroundColor: '#fffbe6',
                    border: "1px solid #ffe58f",
                }
            });
            return false;
        }
        if (!(lastName && lastName.trim().length > 0)) {
            notification.warning({
                message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{this.props.app_labels.error_req_editName}</span>,
                duration: 3,
                style: {
                    backgroundColor: '#fffbe6',
                    border: "1px solid #ffe58f",
                }
            });
            return false;
        }
        let obj = {
            "userProfileId": this.props.data.travelerId,
            "firstName": firstName.trim(),
            "lastName": lastName.trim(),
            "roleIds": userRoleIds,
            "travelerType": this.props.data.travelerType,
            "viewActiveCardEnable": this.state.checked,
        }
        this.props.updateDeleteFlag(true);
        axios.post(URLs.adminUrl + "updateUserProfile", obj, {
            responseType: 'json',
            headers: {
                "Authorization": 'Bearer ' + this.props.token.access_token
            }
        }).then(response => {
            this.props.spinFunc(false)
            this.sendRoles(userRoleDesc);
            if (response.data.serviceStatus.statusCode === 200) {
                this.props.updateDeleteFlag(false);
                if (this.props.searchInput && this.props.searchInput !== "") {
                    this.props.onSearch(this.props.searchInput);
                } else {
                    this.props.FetchAllProfile()
                }
                this.setState({ editcolor: "black", successModalVisible: true })
                // notification.success({
                //     message: <span  role='alert' aria-live="assertive" aria-atomic="true">{response.data.serviceStatus.message}</span>,
                //     duration: 3,
                //     style: {
                //         backgroundColor: '#dff0d8',
                //         color: "green",
                //         border: "1px solid green",
                //     }
                // });
            }
            else if (response.data.serviceStatus.statusCode === 210) {
                this.props.updateDeleteFlag(false);
                notification.error({
                    message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error</span>{this.props.app_labels.error_updateProfile}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }
            else {
                this.props.updateDeleteFlag(false);
                notification.error({
                    message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error</span>{this.props.app_labels.error_updateProfile}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }
        }).catch((error) => {
            this.props.spinFunc(false)
            if (error.response !== undefined && error.response.data !== null && error.response.data !== undefined && error.response.data.serviceStatus !== undefined) {
                notification.error({
                    message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error</span>{this.props.app_labels.error_updateProfile}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }
            else {
                notification.error({
                    message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error</span>{this.props.app_labels.couldNotcompleteReq}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }
        })

    }
    /**
     *deletes the existing profile
     */
    deletProfile = () => {
        var current = this;
        let obj = {
            "userProfileId": this.props.data.travelerId,
            "travelerType": this.props.data.travelerType,
        }
        confirm({
            
            title: `${this.props.app_labels.doYouWanttoDisableProfile}`,
            onOk() {
                current.props.updateDeleteFlag(true);
                axios.post(URLs.adminUrl + "deleteUserProfile", obj, {
                    responseType: 'json',
                    headers: {
                        "Authorization": 'Bearer ' + current.props.token.access_token
                    }
                }).then(response => {
                    if (response.data.serviceStatus.statusCode === 200) {
                        current.props.updateDeleteFlag(false);
                        current.props.FetchAllProfile();
                        if (current.props.searchInput && current.props.searchInput !== "") {
                            current.props.onSearch(current.props.searchInput);
                        }
                        current.setState({ editcolor: "black" })
                        notification.success({
                            message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Success</span>{current.props.app_labels.userProfileUser} {current.state.Name} {current.props.app_labels.disabledSuccessfully}</span>,
                            duration: 3,
                            style: {
                                backgroundColor: '#dff0d8',
                                color: "green",
                                border: "1px solid green",
                            }
                        });
                    }
                    else if (response.data.serviceStatus.statusCode === 210) {
                        current.props.updateDeleteFlag(false);
                        notification.error({
                            message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error</span>{current.props.app_labels.error_deleteProfile}</span>,
                            duration: 3,
                            style: {
                                backgroundColor: '#fff1f0',
                                border: "1px solid #ffa39e",
                            }
                        });
                    }
                    else {
                        current.props.updateDeleteFlag(false);
                        notification.error({
                            message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error</span>{current.props.app_labels.error_deleteProfile}</span>,
                            duration: 3,
                            style: {
                                backgroundColor: '#fff1f0',
                                border: "1px solid #ffa39e",
                            }
                        });
                    }
                }).catch((error) => {
                    current.props.updateDeleteFlag(false);
                    if (error !== null && error !== undefined && error.response !== undefined && error.response.data !== null && error.response.data !== undefined && error.response.data.serviceStatus !== undefined) {
                        notification.error({
                            message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error</span>{current.props.app_labels.couldNotcompleteReq}</span>,
                            duration: 3,
                            style: {
                                backgroundColor: '#fff1f0',
                                border: "1px solid #ffa39e",
                            }
                        });
                    }
                    else {
                        notification.error({
                            message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error</span>{current.props.app_labels.couldNotcompleteReq}</span>,
                            duration: 3,
                            style: {
                                backgroundColor: '#fff1f0',
                                border: "1px solid #ffa39e",
                            }
                        });
                    }
                })
            },
            onCancel() { },
        });
        setTimeout(() => {
            const div = document.querySelector('.ant-modal-root .ant-modal-wrap');
            div.setAttribute('aria-label', "Disable Profile");
            const btns = div.querySelectorAll('button');
            btns.forEach(btn => {btn.blur()})
            if (this.props.theme === "elan"){
                const divImage = document.querySelector('.anticon.anticon-question-circle');
                divImage.setAttribute('role', "img");
                divImage.setAttribute('aria-hidden', "true");
            }
        }, 100)
    }

    handleDeleteModal = ()=>{
        this.setState({ showDeleteModal: true })
    }

    /**
     * this function enabales the user profile
     */
    enableProfile = () => {
        var current = this;
        let obj = {
            "cadreId": this.props.data.cadreId,
            "travelerType": this.props.data.travelerType,
        }
        confirm({
            title: `${this.props.app_labels.doYouWantEnableUser}`,
            onOk() {
                current.props.updateDeleteFlag(true);
                axios.post(URLs.adminUrl + "enableUser", obj, {
                    responseType: 'json',
                    headers: {
                        "Authorization": 'Bearer ' + current.props.token.access_token
                    }
                }).then(response => {
                    if (response.data.serviceStatus.statusCode === 200) {
                        current.props.updateDeleteFlag(false);
                        current.props.FetchAllProfile();
                        if (current.props.searchInput && current.props.searchInput !== "") {
                            current.props.onSearch(current.props.searchInput);
                        }
                        current.setState({ editcolor: "black" })
                        notification.success({
                            message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Success</span>{current.props.app_labels.userProfileUser} {current.state.Name} {current.props.app_labels.enabledSuccessfully}</span>,
                            duration: 3,
                            style: {
                                backgroundColor: '#dff0d8',
                                color: "green",
                                border: "1px solid green",
                            }
                        });
                    }
                    else if (response.data.serviceStatus.statusCode === 210) {
                        current.props.updateDeleteFlag(false);
                        notification.error({
                            message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error</span>{current.props.app_labels.error_enableProfile}</span>,
                            duration: 3,
                            style: {
                                backgroundColor: '#fff1f0',
                                border: "1px solid #ffa39e",
                            }
                        });
                    }
                    else {
                        current.props.updateDeleteFlag(false);
                        notification.error({
                            message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error</span>{current.props.app_labels.error_enableProfile}</span>,
                            duration: 3,
                            style: {
                                backgroundColor: '#fff1f0',
                                border: "1px solid #ffa39e",
                            }
                        });
                    }
                }).catch((error) => {
                    current.props.updateDeleteFlag(false);
                    if (error !== null && error !== undefined && error.response !== undefined && error.response.data !== null && error.response.data !== undefined && error.response.data.serviceStatus !== undefined) {
                        notification.error({
                            message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error</span>{current.props.app_labels.couldNotcompleteReq}</span>,
                            duration: 3,
                            style: {
                                backgroundColor: '#fff1f0',
                                border: "1px solid #ffa39e",
                            }
                        });
                    }
                    else {
                        notification.error({
                            message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error</span>{current.props.app_labels.couldNotcompleteReq}</span>,
                            duration: 3,
                            style: {
                                backgroundColor: '#fff1f0',
                                border: "1px solid #ffa39e",
                            }
                        });
                    }
                })
            },
            onCancel() { },
        });
        
        setTimeout(() => {
            const div = document.querySelector('.ant-modal-root .ant-modal-wrap');
            const buttons = div.querySelectorAll("button");
            buttons.forEach(btn => {btn.blur()})
            div.setAttribute('aria-label', "Enable Profile");
            if (this.props.theme === "elan"){
                const divImage = document.querySelector('.anticon.anticon-question-circle');
                divImage.setAttribute('role', "img");
                divImage.setAttribute('aria-hidden', "true");
            }

        }, 100)
    }

    /**
     * Called when the component may be receiving new prop
     * @param {*} newProps 
     */
    componentWillReceiveProps(newProps) {
        newProps.profileChangeFlag && this.setState({ editcolor: "black" })
        this.setState({
            //Name : this.capitalize(newProps.data.firstName)+" "+this.capitalize(newProps.data.lastName) //Removed capitalize EW-817
            Name: newProps.data.firstName.trim() + " " + newProps.data.lastName.trim(),
            checked: newProps.data.viewActiveCardEnable,
        });
        userRoleIds = [];
        userRoleDesc = [];
        let item = this.props.cadreRoles;
        for (let i = 0; i < newProps.data.roles.length; i++) {
            for (let j = 0; j < item.length; j++) {
                if (item[j].roleDesc === newProps.data.roles[i]) {
                    userRoleIds.push(parseInt(item[j].roleId));
                    userRoleDesc.push(newProps.data.roles[i]);
                }
            }
        }
        children = [];
        userRoleDesc.includes('CSR') ?
            this.props.cadreRoles.map(item =>
                children.push(<Option disabled={true} key={item.roleDesc}>{item.roleDesc}</Option>)) :
            this.props.cadreRoles.map(item =>
                item.roleDesc === 'CSR' ? children.push(<Option disabled={true} key={item.roleDesc}>{item.roleDesc}</Option>) :
                    children.push(<Option key={item.roleDesc}>{item.roleDesc}</Option>))
        this.setState({ dropdownChildren: children })
        
        if(newProps.deleteUserResponse != null && this.props.deleteUserResponse != newProps.deleteUserResponse && newProps.deleteUserResponse.message ==="User Deleted Successfully"){
            this.props.handleDelete(this.props.data.userId)
            this.setState({isDeleted: true})
            notification.success({
                message: <span  role='alert' aria-live="assertive" aria-atomic="true">{deleteUserConstants.deleteSuccess}</span>,
                duration: 3,
                style: {
                    backgroundColor: '#dff0d8',
                    color: "green",
                    border: "1px solid green",
                }
            });
            //this.props.resetUserDelete();

        }

        if(newProps.deleteUserResponse != null && this.props.deleteUserResponse != newProps.deleteUserResponse && newProps.deleteUserResponse?.error?.code === 'record_not_found_error'){
            //this.props.handleDelete(this.props.data.userId)
            //this.setState({isDeleted: true})
            notification.warning({
                message: <span  role='alert' aria-live="assertive" aria-atomic="true">{deleteUserConstants.deleteError}</span>,
                duration: 3,
                style: {
                    backgroundColor: '#fffbe6',
                    border: "1px solid #ffe58f",
                }
            });

        }

        if(newProps.deleteUserResponse === null){
            this.setState({isDeleted: false})
        }

        // if(newProps.isDeleted){
        //     this.setState({isDeleted: true})

        // }

        // if(newProps.profileChangeFlag){
        //     this.setState({isDeleted: false})
        // }

        

    }

    render() {
        let editFlag = this.props.userDetails.roles.includes("Customer Support") || this.props.userDetails.roles.includes("Super Admin")
            || this.props.userDetails.roles.includes("Company Admin")
            || (this.props.data.roles.length === 1 && (this.props.data.roles.includes('Approver') || this.props.data.roles.includes('Provisioner')))
            || (this.props.data.roles.length === 2 && (this.props.data.roles.includes('Approver') && this.props.data.roles.includes('Provisioner')))
        return (
            <div>
                {(this.state.isDeleted === false) ?
                    <>
                        <div className="ViewEditProfileClass">
                            <div className="row admin_heading" style={{ marginLeft: "4%" }}>
                                <b role="heading" aria-level="2">{this.props.app_labels.profileDetails}</b><br />
                                <hr aria-hidden="true" className="hrStyleclass" style={{ width: "9%", border: ".5px solid #19398A" }} />
                                {this.props.data.activeStatus && editFlag ? <div style={{ textAlign: 'right', marginTop: "-6%" }}/* className="col-sm-1 col-sm-offset-3 edit-icon-details" */>
                                    <span onClick={(e) => {
                                        if (this.props.roles.toLowerCase() !== 'customer support')
                                            this.state.editcolor === "black" ? this.setState({ editcolor: "blue" }) : this.setState({ editcolor: "black", checked: this.props.data.viewActiveCardEnable })
                                    }}
                                        onKeyDown={(e) => {
                                            if (e.keyCode === 13) {
                                                if (this.props.roles.toLowerCase() !== 'customer support')
                                                    this.state.editcolor === "black" ? this.setState({ editcolor: "blue" }) : this.setState({ editcolor: "black", checked: this.props.data.viewActiveCardEnable })
                                            }
                                        }}
                                    ><i tabIndex={0} role="button" aria-label="Edit" className={`icon-Edit ${this.props.roles.toLowerCase() === 'customer support' ? ' disabled-icon' : ''}`} id="edit-btn"
                                        style={{ color: this.state.editcolor, fontSize: "20px", marginRight: "9%" }} /></span>

                                </div> : null}
                            </div>
                            <div className="row avatar">

                                <div style={{ textAlign: 'center' }} /* className="col-xs-3 col-xs-offset-5 col-sm-3 col-sm-offset-5 col-md-3 col-md-offset-5 col-lg-3 col-lg-offset-5 col-xl-3 col-xl-offset-5" */ /* avatar-details */>
                                    <Avatar size='large' icon="user" role="img" aria-hidden="true" style={{ marginLeft: "-4.5%" }} />
                                </div>

                            </div>
                            <br />
                            <div className="row">
                                {/*{this.state.editcolor === "black" ? <div className="margin-left-Name"><b>{this.capitalize(this.props.data.firstName) + " " + this.capitalize(this.props.data.lastName)}</b></div> : */ /*Removed capitalize EW-817 */}
                                <div className="col-xs-8 col-xs-offset-3 col-sm-3 col-sm-offset-5 col-md-3 col-md-offset-5 col-lg-3 col-lg-offset-5 col-xl-3 col-xl-offset-5" >
                                    {this.state.editcolor === "black" ? <div className="margin-left-Name" style={{ marginLeft: "-58%" }}><b>{this.props.data.firstName + " " + this.props.data.lastName}</b></div> :
                                        <Input defaultValue={this.state.Name}
                                            onKeyPress={(event) => {
                                                if (event.key.replace(/[^\w\-. ]/g, '') === '') event.preventDefault();
                                            }
                                            }
                                            value={this.state.Name}
                                            aria-label="User Profile"
                                            onChange={this.nameChange} />}
                                </div>
                            </div>
                            <Row className="row details-row">
                                <Col span={6} offset={4} >
                                    {this.props.app_labels.expenseWizardId}
                                </Col>
                                {/* <MediaQuery minDeviceWidth={770}> */}
                                {/* <div className="col-xs-1 col-xs-offset-0 col-sm-1 col-sm-offset-0 col-md-1 col-md-offset-0 col-lg-1 col-lg-offset-0 col-xl-1 col-xl-offset-0">
                        :
                </div> */}
                                {/* </MediaQuery> */}

                                <Col span={6}  ><span aria-hidden="true">:</span>
                                    <span className="details-editProfile">{this.props.data.cadreId}</span>
                                </Col>
                            </Row>
                            <Row className="row details-row">
                                <Col span={6} offset={4} >
                                    {this.props.app_labels.email}
                                </Col>
                                {/* <MediaQuery minDeviceWidth={770}>
                        <div className="col-sm-1 col-sm-offset-0">
                            :
                </div>
                    </MediaQuery> */}
                                {/* <div className="col-xs-1 col-xs-offset-0 col-sm-1 col-sm-offset-0 col-md-1 col-md-offset-0 col-lg-1 col-lg-offset-0 col-xl-1 col-xl-offset-0">
                        :
                </div> */}
                                <Col span={6} ><span aria-hidden="true">:</span>
                                    <span className="details-editProfile">{this.props.data.emailId}</span>
                                </Col>
                            </Row>
                            <Row className="row details-row">
                                <Col span={6} offset={4} >
                                    <span aria-hidden="true">{this.props.app_labels.mobileNo}</span>
                                    <span className="visually-hidden">Mobile number</span>
                                </Col>
                                {/*  <MediaQuery minDeviceWidth={770}>
                        <div className="col-sm-1 col-sm-offset-0">
                            :
                </div>
                    </MediaQuery> */}

                                {/* <div className="col-xs-1 col-xs-offset-0 col-sm-1 col-sm-offset-0 col-md-1 col-md-offset-0 col-lg-1 col-lg-offset-0 col-xl-1 col-xl-offset-0">
                        :
                </div> */}
                                <Col span={6} style={{ width: '50%' }} ><span aria-hidden="true">:</span>
                                    <span className="details-editProfile phNo">
                                        {this.props.data.phoneNo ? this.phoneNoFormat(this.props.data.phoneNo) : null}
                                    </span>
                                </Col>
                            </Row>
                            <Row className="row details-row">
                                <Col span={6} offset={4} >
                                    <span aria-hidden="true">{this.props.app_labels.alternateNo}</span>
                                    <span className="visually-hidden">Alternate number</span>
                                </Col>
                                {/*  <MediaQuery minDeviceWidth={770}>
                        <div className="col-sm-1 col-sm-offset-0">
                            :
                </div>
                    </MediaQuery> */}
                                {/* <div className="col-xs-1 col-xs-offset-0 col-sm-1 col-sm-offset-0 col-md-1 col-md-offset-0 col-lg-1 col-lg-offset-0 col-xl-1 col-xl-offset-0">
                        :
                </div> */}
                                <Col span={6} style={{ width: '50%' }} ><span aria-hidden="true">:</span>
                                    <span className="details-editProfile phNo">{this.props.data.altPhoneNo ? this.phoneNoFormat(this.props.data.altPhoneNo) : "-"}</span>
                                </Col>
                            </Row>
                            <Row className="row details-row">
                                <Col span={6} offset={4} >
                                    {this.props.app_labels.companyName}
                                </Col>
                                {/*  <MediaQuery minDeviceWidth={770}>
                        <div className="col-sm-1 col-sm-offset-0">
                            :
                </div>
                    </MediaQuery> */}
                                {/* <div className="col-xs-1 col-xs-offset-0 col-sm-1 col-sm-offset-0 col-md-1 col-md-offset-0 col-lg-1 col-lg-offset-0 col-xl-1 col-xl-offset-0">
                        :
                </div> */}
                                <Col span={6} ><span aria-hidden="true">:</span>
                                    <span className="details-editProfile">{this.props.data.companyName}</span>
                                </Col>
                            </Row>
                            <Row className="row details-row">
                                <Col span={6} offset={4} id='assign-role' >
                                    {this.props.app_labels.assignRole}
                                </Col>
                                {/*   <MediaQuery minDeviceWidth={770}>
                        <div className="col-sm-1 col-sm-offset-0">
                            :
                </div>
                    </MediaQuery> */}
                                {/* <div className="col-xs-1 col-xs-offset-0 col-sm-1 col-sm-offset-0 col-md-1 col-md-offset-0 col-lg-1 col-lg-offset-0 col-xl-1 col-xl-offset-0">
                        :
                </div> */}
                                <Col span={12} ><span aria-hidden="true">:</span>
                                    {this.state.editcolor === "black" ? <span style={{ color: "#333333", marginLeft: "11.5%", fontWeight: 600 }}> {this.props.data.roles.length === 0 ? "-" :
                                        this.props.data.roles.toString()
                                    }</span> :
                                        <span style={{ color: "#333333", marginLeft: "11.5%" }}>
                                            {/* <Select
                                    aria-labelledby='assign-role'
                                    mode="multiple"
                                    showArrow={true}
                                    // allowClear={true}
                                    className="select1"
                                    placeholder="Please select"
                                    defaultValue={this.props.data.roles}
                                    onChange={this.roleChange}
                                >
                                    {this.state.dropdownChildren}
                                </Select> */}
                                            <Autocomplete
                                                disablePortal
                                                disableClearable
                                                multiple
                                                id="combo-box-demo"
                                                openText={null}
                                                defaultValue={this.props.data.roles}
                                                options={this.props.cadreRoles.map((option) => option.roleDesc)}
                                                ChipProps={{ component: "button" }}
                                                getOptionDisabled={(option) => option === 'CSR'}
                                                sx={{ width: 300, marginLeft: '13%', marginTop: '-7%' }}
                                                onChange={(event, newValue) => {
                                                    this.roleChange(newValue);
                                                }}
                                                renderInput={(params) => <TextField {...params} variant="standard"
                                                    placeholder={this.props.app_labels.selectRoles}
                                                    sx={{
                                                        "& input::placeholder": {
                                                            color: "#666666",
                                                            opacity: 1,
                                                        }
                                                    }} />
                                                }
                                            />
                                        </span>}
                                </Col>
                            </Row>
                            <Row className="row details-row" style={{ display: this.props.data.roles.includes('Provisioner') ? null : "none" }}>
                                <Col span={6} offset={4} style={{ marginTop: "7px" }} >
                                    {this.props.app_labels.viewAllActiveCards}
                                </Col>
                                {/*   <MediaQuery minDeviceWidth={770}>
                        <div className="col-sm-1 col-sm-offset-0">
                            :
                </div>
                    </MediaQuery> */}
                                {/* <div className="col-xs-1 col-xs-offset-0 col-sm-1 col-sm-offset-0 col-md-1 col-md-offset-0 col-lg-1 col-lg-offset-0 col-xl-1 col-xl-offset-0">
                        :
                </div> */}
                                <Col span={6} ><span aria-hidden="true">:</span>
                                    <span className="details-editProfile">
                                        <Switch className='profile-toggle-button' color="success" disabled={this.state.editcolor === "black"} checked={this.state.checked}
                                            onClick={(event) => this.setState({ checked: event.target.checked })}
                                            inputProps={{ 'aria-label': 'View All Active Cards' }}
                                        />
                                    </span>
                                </Col>
                            </Row><br />
                        </div>
                        <div className="row btnn" style={{ paddingLeft: '3%' }}>
                            {this.props.data.activeStatus ? this.state.editcolor === "black" ? null :
                                <div>
                                    {/* <div className="col-sm-2">
                            <button type="button" style={{ fontSize: '16px', marginTop: '13%', color: "#DE162B", borderColor: "#DE162B", width: this.props.selectedLanguage === 'EN'?'170px': '180px' }} className="button-disableProfile-ghost" onClick={this.deletProfile}><b>{this.props.app_labels.disableProfile}</b></button>
                        </div> */}

                                    <div className="col-sm-2 col-sm-offset-3">
                                        { this.props.roles.toLowerCase() !== 'card company admin' && <button type="button" className="btn-delete " disabled={this.props.roles.toLowerCase() === 'customer support' ? true : false} onClick={this.handleDeleteModal}><b>{this.props.app_labels.deleteTrip}</b></button>}
                                    </div>
                                    <div className="col-sm-2  col-sm-offset-0">
                                        <button type="button" style={{ fontSize: '16px', marginTop: '13%', marginLeft: "40%", width: '150px', color: "#666666", borderColor: "#666666" }} className="edit-profile button-cancel-ghost" onClick={this.onCancel}><b>{this.props.app_labels.cancel}</b></button>
                                    </div>
                                    <div className="col-sm-2 col-sm-offset-1">
                                        <button type="button" style={{ fontSize: '16px', marginTop: '13%', width: '150px', color: "white", borderColor: "#19398A", backgroundColor: "#19398A" }} className="edit-profile button-save" onClick={this.updatEProfile}><b>{this.props.app_labels.save}</b></button>
                                    </div>
                                </div> :
                                <div className="col-sx-12 col-sx-offset-0 col-sm-3 col-sm-offset-8 col-md-5 col-md-offset-7 col-lg-2 col-lg-offset-8">
                                    <button type="button" disabled={this.props.roles.toLowerCase() === 'customer support' ? true : false} style={{ fontSize: '16px' }} className="button-createtrip" onClick={this.enableProfile}><b>{this.props.app_labels.enableProfile}</b></button>
                                </div>

                            }
                        </div></> : null}
                {/* Delete User Modal Start*/}
                
                <Modal className="custom-modal custom-modal-bulk-card" style={{borderTop: "0px"}} role="dialog"
                    visible={this.state.showDeleteModal}
                    width={416}
                    closable={false}
                    aria-labelledby="title"
                    //title={<b className="visually-hidden" id="title">Profile Details Update</b>}
                    footer={[<Button type="primary"
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                this.setState({ showDeleteModal: false })
                                setTimeout(() => {
                                    document.getElementById('edit-btn') && document.getElementById('edit-btn').focus();
                                })
                            }
                        }
                        } onClick={() => {
                            this.setState({ showDeleteModal: false })
                            setTimeout(() => {
                                document.getElementById('edit-btn') && document.getElementById('edit-btn').focus();
                            })
                        }}>Cancel</Button>, <Button style={{ fontSize: '16px', marginTop: '13%', color: "#ffffff", backgroundColor: "#DE162B", borderColor: "#DE162B"}}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    this.setState({ delete: false })
                                    setTimeout(() => {
                                        document.getElementById('edit-btn') && document.getElementById('edit-btn').focus();
                                    })
                                }
                            }
                            } onClick={() => {
                                this.props.deleteUserAction(this.props.data.emailId);
                                this.setState({ showDeleteModal: false })
                                setTimeout(() => {
                                    document.getElementById('edit-btn') && document.getElementById('edit-btn').focus();
                                })
                            }}>Yes delete</Button>]}
                >
                    <div style={{ textAlign: "left", marginLeft: "19px" }}>
                        <p><b>{deleteUserConstants.deleteTitle}</b></p><br />
                        <p>{deleteUserConstants.deleteMsg}</p>
                        <br />
                        <p><b>Provisioner-</b>{deleteUserConstants.deleteMsgDesc} </p>
                        <p>{deleteUserConstants.deleteMsgDesc1}</p>
                        <br />
                        <p><b>Card user-</b> {deleteUserConstants.deleteMsgDesc2}</p>
                        {/* <p>No impact to active cards</p> */}
                    </div>
                </Modal>
                
                {/* Delete User Modal End*/}

                <Modal className="custom-modal custom-modal-bulk-card" role="dialog"
                    visible={this.state.successModalVisible}
                    closable={false}
                    aria-labelledby="title"
                    title={<b className="visually-hidden" id="title">Profile Details Update</b>}
                    footer={[<Button type="primary" 
                    onKeyDown={(e) => {
                        if (e.key === 'Enter'){ 
                            this.setState({successModalVisible: false})
                            setTimeout(() => {
                                document.getElementById('edit-btn') && document.getElementById('edit-btn').focus();
                            })}
                        }
                    } onClick={() => {
                        this.setState({successModalVisible: false})
                        setTimeout(() => {
                            document.getElementById('edit-btn') && document.getElementById('edit-btn').focus();
                        })
                    }}>OK</Button>]}
                >
                    <div className="custom-modal-content">
                        <img src={Tick} alt='success' />
                        <p>{this.props.app_labels.profileDetailsUpdateSuccess}</p>
                    </div>
                </Modal>
            </div>

        )
    }

}

function mapStateToProps(state, props) {
    return {
        userDetails: state.authenticationReducer.userDetails,
        token: state.authenticationReducer.token,
        selectedLanguage: state.adduserReducer.selectedLanguage,
        deleteUserResponse: state.getuserActionsReducer.deleteUserResponse,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        deleteUserAction: (email) => {
            dispatch(deleteUserAction(email));
        },
        resetUserDelete: () => {
            dispatch(resetUserDelete());
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);