import React from 'react';
import '../../styling/report.css';
import axios from 'axios';
import $ from 'jquery';
import { connect } from 'react-redux';
import { notification, Table, DatePicker } from 'antd';
const { URLs, errorMessages } = require('../../data/constants.js');
var dateFormat = require('dateformat');
const EditableCell = ({ value, column, onChange }) => (
    <>

        <DatePicker onChange={onChange}
            className="datePicker"
            // defaultValue={moment('today', "yyyy-mm-dd")}
            format="MM/DD/YYYY" />
    </>
);

class ReportComponent extends React.Component {
    constructor(props) {
        super(props); 
        this.state = {
            reportsList: [],
        }

        this.reportsArray = [
            {
                'reportName': this.props.app_labels.report1,
                'serviceName': 'canceledTrips',
                'reportKey': 1,
                'startDate': null,
                'endDate': null,
            }, {
                'reportName': this.props.app_labels.report2,
                'serviceName': 'personalExpenseTransactions',
                'reportKey': 2,
                'startDate': null,
                'endDate': null,
            }, {
                'reportName': this.props.app_labels.report3,
                'serviceName': 'informationAwaitedTransactions',
                'reportKey': 3,
                'startDate': null,
                'endDate': null,
            },/*  {
                'reportName': this.props.app_labels.report4,
                'serviceName': 'monthlyBudget',
                'reportKey': 4,
                'startDate': null,
                'endDate': null,
            }, */ {
                'reportName': this.props.app_labels.report5,
                'serviceName': 'tripsPendingClosure',
                'reportKey': 5,
                'startDate': null,
                'endDate': null,
            },{
                'reportName': "Company Transactions",
                'serviceName': 'companyTransactions',
                'reportKey': 6,
                'startDate': null,
                'endDate': null,
            },{
                'reportName': "CR Process Exception Data",
                'serviceName': 'processException',
                'reportKey': 7,
                'startDate': null,
                'endDate': null,
            }
        ]
    }
    componentWillMount() {
        this.setState({ reportsList: this.reportsArray })
    }

    /**
     * Lifecycle method on mount of the component
     */
    componentDidMount() {
        let element = $('.anticon.anticon-calendar.ant-calendar-picker-icon')
        element.empty()
        element.removeClass().addClass('icon-Calendar')

    }

    /**
     * Handles all the date validations according to the format
     */
    dateValidations = (record) => {
        if (!record.startDate || !record.endDate) {
            notification.warning({
                message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Error </span>{errorMessages.noDateSelected}</span>,
                duration: 3,
                style: {
                    backgroundColor: '#fffbe6',
                    border: "1px solid #ffe58f",
                }
            });
            return true;
        }
        if (record.startDate > record.endDate) {
            notification.warning({
                message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Error </span>{errorMessages.startDategreater}</span>,
                duration: 3,
                style: {
                    backgroundColor: '#fffbe6',
                    border: "1px solid #ffe58f",
                }
            });
            return true;
        }
    }

    /**
     * Downloads the excel sheet reports based on selected dates
     */
    downloadReport = (record) => {
        /*  var uri = '/cadre' + url.split('/cadre')[1] */
        if (record.reportKey !== 6 && this.dateValidations(record)) {
            return;
        }
        let paramObj = {};
        if (record.serviceName === "companyTransactions") {
            if(record.startDate === null){
                paramObj = {
                    'from': record.startDate,
                    'to': record.endDate,
                }
            }
            else{
                paramObj = {
                    'from': dateFormat(record.startDate, 'yyyy-mm-dd'),
                    'to': dateFormat(record.endDate, 'yyyy-mm-dd'),
                }

            }  
        }
        else {
            paramObj = {
                'from': dateFormat(record.startDate, 'yyyy-mm-dd'),
                'to': dateFormat(record.endDate, 'yyyy-mm-dd'),
            }
        }
        axios.get(URLs.reportUrl + record.serviceName, {
            params: paramObj,
            responseType: 'blob',
            headers: {
                "Authorization": 'Bearer ' + this.props.token.access_token
            }
        }).then(response => {
            if (navigator.msSaveBlob) {                // IE block
                var blobObject = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                window.navigator.msSaveOrOpenBlob(blobObject, record.serviceName + ".xlsx");
            }
            else {   //non IE block
                var a = document.createElement('a');
                a.href = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })); // xhr.response is a blob
                a.download = record.serviceName; // Set the file name.
                a.style.display = 'none';
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                setTimeout(function () {
                    window.URL.revokeObjectURL(a.href);
                }, 100)

            }
        }).catch(async (error) => {
            let textData = await this.getErrorFromBlob(error.response.data)
            if (textData !== undefined && textData.serviceStatus !== undefined) {
                notification.warning({
                    message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Error </span>{textData.serviceStatus.message}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fffbe6',
                        border: "1px solid #ffe58f",
                    }
                });
            }
            else {
                notification.error({
                    message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Error </span>{errorMessages.generic_error}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }

        })
    }
    getErrorFromBlob(blob) {
        return new Promise((resolve, reject) => {
            let reader = new FileReader()
            reader.onload = function (data) {
                try {
                    let jsonData = JSON.parse(data.target.result);
                    return resolve(jsonData)
                } catch (e) {
                    return resolve(undefined)
                }
            }
            reader.onerror = function (event) {
                reader.abort()
                return resolve(undefined)
            }
            reader.readAsText(blob)
        })
    }
    renderColumns(text, record, column) {
        return (
            <EditableCell
                value={text}
                column={column}
                onChange={value => this.handleChange(value, record.reportKey, column)}
            />
        );
    }
    handleChange = (value, key, column) => {
        const newData = [...this.state.reportsList];
        const target = newData.filter(item => key === item.reportKey)[0];
        if (target) {

            target[column] = value;
            this.setState({ reportsList: newData });
        }
    }
    render() {


        const columns = [
            {
                title: <span className='tableHeader'>{this.props.app_labels.reportHeader}</span>,
                dataIndex: "reportName",
                align: "left",
                width: '50%',
                render: (text, record) => (
                    <div className="col-sm-10 col-sm-offset-0 reportrule" >
                        <span className = "a-link" style={{ textDecoration: "underline",cursor:"pointer" }} onClick={(e) => this.downloadReport(record)} download>
                            {text}</span>
                    </div>
                ),
            }, {
                title: <span className='tableHeader'>{this.props.app_labels.startDateHeader}</span>,
                dataIndex: 'startDate',
                width: '10%',
                align: "left",
                render: (text, record) =>
                    <div style={{ marginLeft: "0%" }} className={"startDate" + record.reportKey} >
                        {this.renderColumns(text, record, 'startDate')}
                    </div>

            }, {
                title: <span className='tableHeader'>{this.props.app_labels.endDateHeader}</span>,
                dataIndex: 'endDate',
                width: '10%',
                align: "left",
                render: (text, record) =>
                    <div style={{ marginLeft: "0%" }} className={"endDate" + record.reportKey} >
                        {this.renderColumns(text, record, 'endDate')}
                    </div>

            }


        ];

        return (
            <div className="reportclass" >
                <div className="row admin_heading">
                    <b>{this.props.app_labels.reportHeading}</b><br /><hr className="hrStyleclass" />
                </div>
                <Table style={{ minWidth: "150px", marginLeft: "2%", marginRight: "2%" }}
                    scroll={{ x: 970,/*  y: 300  */ }}
                    columns={columns}
                    dataSource={this.state.reportsList}
                    pagination={false} />
            </div >
        )
    }
}
function mapStateToProps(state, props) {
    return {
        token: state.authenticationReducer.token,
    };
}
export default connect(mapStateToProps)(ReportComponent); 