import axios from 'axios';
import React from 'react';
import { notification } from 'antd';
const { URLs, errorMessages } = require('../data/constants.js')
const {updateRequestNo} = require('./bulkcardAction.js') 

function getData(data) {
  return {
    type: "GET_BULKDATA",
    data
  }
}


export function getBulkData(label_keys,req) {
  return (dispatch, getState) => {
    let {
      token,
    } = getState().authenticationReducer;
    axios.get(URLs.cardUrl + '/getCardRequestData?request_id='+req, {
      responseType: 'json',
      headers: {
        "Authorization": 'Bearer ' + token.access_token
      }
    }).then(result => {
      if (result.data.serviceStatus.statusCode === 200) {
        dispatch(getData(result));
        dispatch(updateRequestNo(req))
      }
      else {
        notification.error({
          message: <span  role='alert' aria-live="assertive" aria-atomic="true" ><span className="visually-hidden">Error </span>{label_keys.couldNotcompleteReq}</span>,
          duration: 3,
          style: {
            backgroundColor: '#fff1f0',
            border: "1px solid #ffa39e",
          }
        });
      }
    }).catch((error) => {
        notification.error({
          message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{label_keys.couldNotcompleteReq}</span>,
          duration: 3,
          style: {
            backgroundColor: '#fff1f0',
            border: "1px solid #ffa39e",
          }
        });
    })
  }
}



