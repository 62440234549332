import React from 'react'
import { Row } from 'antd'
import TripTravelerInformationContainer from "../TripTravelerInformationContainer"
import TransactionDetailsContainer from "../TransactionDetailsContainer"
import ChatHistoryContainer from "../ChatHistoryContainer";
import Error from '../Error'
import Loader from '../Loader'

class Body extends React.Component {
  render() {
    console.log(this.props.data)
    return (
      <Row className='body' style={{ minHeight: '700px' }}>
        {
          this.props.isFetching === true ? (
            <Loader/>
          ) :
            this.props.error ? (
              <Error message={this.props.error.message}
                type={this.props.error.type}
              />
            ) :
              this.props.data ? (
                <React.Fragment>
                  <TripTravelerInformationContainer 
                    travelerData={this.props.data.transactionDetails.data.travelerDetails} 
                    tripData={this.props.data.transactionDetails.data.trip}
                    downloadFile={this.props.downloadFile} 
                  />
                  <TransactionDetailsContainer transactionId={this.props.data.transactionDetails.data.transactionId} 
                    transactionData={this.props.data.transactionDetails.data.transactions}
                    transactionQuestionnaire={this.props.data.transactionQuestionnaire.transactionDetails}
                    isUpdating={this.props.isUpdating}
                    updateError={this.props.updateError}
                    updateSuccess={this.props.updateSuccess}
                    updateTransactionDetails={this.props.updateTransactionDetails}
                  />
                  <ChatHistoryContainer transactionChatHistory={this.props.data.transactionChatHistory}/>
                </React.Fragment>
              ) : null
        }
      </Row>
    )
  }
}

export default Body