import React, { Component } from 'react';
import history from '../history';
import '../../styling/appintro.css';
import { Button } from 'antd';
var images = ['tour1.png', 'tour2.png', 'tour3.png', 'tour4.png', 'tour5.png', 'tour6.png', 'tour7.png'];
export default class Apptour extends Component {
    constructor(props) {
        super(props);

        this.state = {
            cnt: 1,
        }
    }
    close = () => {
        history.push('./dashboard')
    }
    finish = () => {
        history.push('./dashboard')
    }
    chng = () => {

        var len = images.length;
        if (this.state.cnt < len) {
            this.setState({ cnt: this.state.cnt + 1 })
        }
        else if (this.state.cnt === len) {
            this.setState({ cnt: 1 })

        }
    }
    render() {
        return (
            <div className='container'>
                <div style={{ textAlign: 'center' }}>
                    <img alt = "image_tour" src={`${require('../../images/tourpics/tour' + this.state.cnt + '.png')}`} id="rr" style={{ height: '70%', width: '100%' }} />


                    {this.state.cnt !== images.length ? <Button style={{ border: '1px solid white' }} className="button-wrapclass-tutorial skipbtn" onClick={this.close}>Skip tutorial</Button> : null}


                    {this.state.cnt === images.length ? <Button style={{ border: '1px solid white' }} className="button-wrapclass-tutorial nextbtn" onClick={this.finish}>Finish</Button> :
                        <Button style={{ border: '1px solid white' }} className="button-wrapclass-tutorial nextbtn" onClick={this.chng}>Next</Button>}


                </div>
            </div>
        )
    }
}