import React from 'react';
import { data } from './phoneData';
// import '../styling/createtrip.css';

class PhoneCode extends React.Component {
    constructor(props) {
        super(props);
        this.renderOptions = this.renderOptions.bind(this);
    }

    componentDidMount() {
        const { showFirst } = this.props;
        if (showFirst && Array.isArray(showFirst) && showFirst.length && data[showFirst[0]]) {
            this.props.onSelect(data[showFirst[0]].code);
        }
    }

    renderOptions() {
        const options = [];
        const map = {};
        const { showFirst } = this.props;
        const defaultValue=this.props.defaultvalue
        if (showFirst && Array.isArray(showFirst)) {
            showFirst.forEach(c => {
                map[c] = true;
                if (data[c]) {
                    options.push(
                        
                       defaultValue === data[c].code ? <option selected style={{width:"25%"}} key={c} value={data[c].code}
                            className={this.props.optionClassName}
                        >{data[c].code}{/* ({data[c].code}) */}
                        </option>: <option style={{width:"25%"}} key={c} value={data[c].code}
                            className={this.props.optionClassName}
                        >{data[c].code}{/* ({data[c].code}) */}
                        </option>
                    );
                }
            });
        } else {
            options.push(
                <option style={{width:"20%"}} key='--' value="" className={this.props.optionClassName}>{this.props.defaultValue || ''}</option>
            );
        }
        Object.keys(data).forEach(c => {
            if (!map[c]) {
                options.push(
                    <option style={{width:"20%"}} key={c} value={data[c].code}
                        className={this.props.optionClassName}
                    >{data[c].code}{/* ({data[c].codename}) */}
                    </option>
                );
            }
        });
        return options;
    }

    render() {
        return (
            <select aria-label={this.props.label ? this.props.label : 'select country code'} style={{border:"none", borderBottom:"1px solid #666666", width:"fit-content", marginTop: 13, paddingBottom: 4}}
                name={this.props.name}
                id={this.props.id}
                className={`${this.props.className} phoneCode_number`}
                onChange={(e) => this.props.onSelect(e.target.value)}
            >{this.renderOptions()}
            </select>
        );
    }
}

export default PhoneCode;
