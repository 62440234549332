/* eslint-disable no-unused-expressions */
import axios from 'axios';
import qs from 'qs';
import React from 'react';
import MediaQuery from 'react-responsive';
import history from '../components/history';
import tokenValidator from '../utils/tokenValidator';
import actionTypes from '../data/actionTypes';
const { URLs , errorMessages, elanRelatedUrls } = require('../data/constants.js')

export function loginRequest() {
    return {
        type: 'LOGIN_REQUEST'
    }
}

function loginSuccess(data) {
    window.sessionStorage.setItem("LOGIN_STATUS",true);
    return {
        type: 'LOGIN_SUCCESS',
        payload: data
    }
}

function loginFailure(err) {
    return {
        type: 'LOGIN_FAILURE',
        error: err
    }
}

function interruptLogin() {
    return {
        type: 'LOGIN_INTERRUPTED'
    }
}

function fetchTokenRequest() {
    return {
        type: 'FETCH_TOKEN_REQUEST'
    }
}

function fetchTokenSuccess() {
    return {
        type: 'FETCH_TOKEN_SUCCESS'
    }
}

function fetchTokenFailure() {
    return {
        type: 'FETCH_TOKEN_FAILURE'
    }
}

function fetchUserDetailsRequest() {
    return {
        type: 'FETCH_USER_DETAILS_REQUEST'
    }
}

function fetchUserDetailsSuccess() {
    return {
        type: 'FETCH_USER_DETAILS_SUCCESS'
    }
}

function fetchUserDetailsFailure() {
    return {
        type: 'FETCH_USER_DETAILS_FAILURE'
    }
}

export function saveToken(data) {
    return {
        type: 'SAVE_TOKEN',
        payload: data
    }
}

function saveUserDetails(data) {
    return {
        type: 'SAVE_USER_DETAILS',
        payload: data
    }
}

function saveTempUsername(data) {
    return {
        type: 'SAVE_TEMP_USERNAME',
        payload: data
    }
}

function resetPasswordRequest() {
    return {
        type: 'RESET_PASSWORD_REQUEST'
    }
}

function resetPasswordSuccess(data) {
    return {
        type: 'RESET_PASSWORD_SUCCESS',
        payload: data
    }
}

function resetPasswordFailure(err) {
    return {
        type: 'RESET_PASSWORD_FAILURE',
        error: err
    }
}

export function resetPasswordStateReset() {
    return {
        type: 'RESET_PASSWORD_STATE_RESET'
    }
}

function forgotPasswordRequest() {
    return {
        type: 'FORGOT_PASSWORD_REQUEST'
    }
}

function forgotCredentialsSuccess(data) {
    return {
        type: 'FORGOT_PASSWORD_SUCCESS',
        payload: data
    }
}

function forgotPasswordFailure(err) {
    return {
        type: 'FORGOT_PASSWORD_FAILURE',
        error: err
    }
}

export function forgotPasswordStateReset() {
    return {
        type: 'FORGOT_PASSWORD_STATE_RESET'
    }
}

function sendOTPRequest() {
    return {
        type: 'SEND_OTP_REQUEST'
    }
}

function sendOTPSuccess() {
    return {
        type: 'SEND_OTP_SUCCESS'
    }
}

function sendOTPFailure(error) {
    return {
        type: 'SEND_OTP_FAILURE',
        error
    }
}

function verifyOTPRequest() {
    return {
        type: 'VERIFY_OTP_REQUEST'
    }
}

function verifyOTPSuccess() {
    return {
        type: 'VERIFY_OTP_SUCCESS'
    }
}

function verifyOTPFailure(error) {
    return {
        type: 'VERIFY_OTP_FAILURE',
        error
    }
}

export function activateAccountStateReset() {
    return {
        type: 'ACTIVATE_ACCOUNT_STATE_RESET'
    }
}

export function refreshToken() {
    return {
        type: actionTypes.REFRESH_TOKEN
    }
}

export function refreshTokenRequest() {
    return {
        type: actionTypes.REFRESH_TOKEN_REQUEST
    }
}

export function refreshTokenSuccess(data) {
    return {
        type: actionTypes.REFRESH_TOKEN_SUCCESS,
        payload: data
    }
}

export function refreshTokenFailure(error) {
    return {
        type: actionTypes.REFRESH_TOKEN_FAILURE,
        error
    }
}

export function setRefreshTokenTimer(data) {
    return {
        type: actionTypes.SAVE_REFRESH_TOKEN_TIMER,
        payload: data
    }
}
export function setTheme(data) {
    return {
        type: actionTypes.SET_THEME,
        payload: data
    }
}

export function flowCheckFlag(data) {
    return {
        type: 'SET_FLOW_CHECK',
        payload: data
    }
}

export function fetchToken(data) {
    return function (dispatch) {
        dispatch(fetchTokenRequest());
        return axios.post(URLs.tokenUrl + 'token',
            qs.stringify(data), {
                headers: {
                    "Authorization": "Basic Q2FkcmUtV2ViOmNhZHJl"
                }
            })
            .then((response) => {
                dispatch(fetchTokenSuccess());
                return response;
            })
            .catch((err) => {
                dispatch(fetchTokenFailure());
                throw err;
            })
    }
}

export function fetchUserDetails(access_token) {
    return function (dispatch) {
        dispatch(fetchUserDetailsRequest());
        return axios.get(URLs.userUrl + 'userDetails', {
            headers: {
                "Authorization": `Bearer ${access_token}`
            }
        })
            .then((response) => {
                dispatch(fetchUserDetailsSuccess());
                let theme = window.location.hostname.includes(elanRelatedUrls.elanUrlCheck) ? 'elan' : 'ic'
                dispatch(setTheme(theme)); 
                return response.data;
            })
            .catch((err) => {
                dispatch(fetchUserDetailsFailure());
                throw err;
            })
    }
}

export function login(values, grantType = 'password', touch_token = null, rmCheck) {
    let theme = window.location.hostname.includes(elanRelatedUrls.elanUrlCheck) ? 'elan' : 'ic'
    return function (dispatch, getState) {
        const data = {
            ...values,
            grant_type: grantType,
            touch_token: touch_token,
        }
        dispatch(loginRequest());

        if (data.grant_type === 'mfa') {
            try {
                let queryParams = qs.parse(history.location.search.slice(1))
                data.mfa_token = queryParams.mfa_token
            }
            catch (err) {
                dispatch(loginFailure(err.response ? err.response.data.serviceStatus : { message: errorMessages.loginGenericErrorMessage }))
                return
            }
        }

        dispatch(fetchToken(data))
            .then((response) => {
                rmCheck ? localStorage.username = values.username : null
                //Received a token
                let tokenObject = {
                    token: response.data,
                    tokenTimestamp: Date.now() / 1000
                };
                //Now fetch the user details
                dispatch(fetchUserDetails(tokenObject.token.access_token))
                    .then((response) => {
                        //Got the user details
                        let userDetails = response.data;
                        if (false /* userDetails.isNewUser === true */) {
                            dispatch(interruptLogin());
                            history.push(`/resetPassword?type=token&access_token=${tokenObject.token.access_token}`)
                        } else {
                            dispatch(saveToken(tokenObject));
                            dispatch(saveUserDetails(userDetails));
                            dispatch(loginSuccess());
                            dispatch(setTheme(theme));

                            if(theme === 'ic'){
                                window.pendo.initialize({
                                    visitor: {
                                        id: userDetails.userId, 
                                        roleId: userDetails.roles,
                                        city: userDetails.companyAddress && userDetails.companyAddress.city ? userDetails.companyAddress.city : null ,
                                        state: userDetails.companyAddress && userDetails.companyAddress.state ? userDetails.companyAddress.state : null,
                                        country: userDetails.companyAddress && userDetails.companyAddress.country ? userDetails.companyAddress.country : null,
                                        lang: 'EN',
                                    },
                                    account: {
                                        id: userDetails.companyName ? userDetails.companyName : null,
                                        acctID: userDetails.companyName ? userDetails.companyName : null,
                                        city: userDetails.companyAddress && userDetails.companyAddress.city ? userDetails.companyAddress.city : null ,
                                        state: userDetails.companyAddress && userDetails.companyAddress.state ? userDetails.companyAddress.state : null,
                                        country: userDetails.companyAddress && userDetails.companyAddress.country ? userDetails.companyAddress.country : null,
                                        osn: userDetails.osn ? userDetails.osn : null
                        
                                    }
                                
                                });
                            }
                            if (userDetails.roles.length === 1) {
                                console.log("userDetails.roles[0] is")
                                console.log(userDetails.roles[0])
                                if (userDetails.roles[0] === "Company Admin") {
                                    history.push({
                                        pathname: '/admin',
                                        state: { role: 'Admin' }
                                    });
                                }
                                else if (userDetails.roles[0] === "Super Admin" ) {
                                    console.log("it is for SA")
                                    history.push({
                                        pathname: '/superadmin',
                                        state: { role: 'Super Admin' }
                                    });
                                }
                                else if (userDetails.roles[0] === "Customer Support" ) {
                                    console.log("it is for SA-RO")
                                    history.push({
                                        pathname: '/customersupport',
                                        state: { role: 'Customer Support' }
                                    });
                                }
                                else if (userDetails.roles[0] === "Card Company Admin") {
                                    history.push({
                                        pathname: '/cardadmin',
                                        state: { role: 'Card Company Admin' }
                                    });
                                }
                                else if(userDetails.roles[0] === "Provisioner") {
                                    history.push({
                                        pathname: '/carddashboard',
                                        state: { role: 'Provisioner' }
                                    });
                                }
                                else if(userDetails.roles[0] === "Approver") {
                                    history.push({
                                        pathname: '/carddashboard',
                                        state: { role: 'Approver' }
                                    });
                                }
                                else if (userDetails.roles[0] === "CSR") {
                                    history.push({
                                        pathname: '/csr',
                                        state: { role: 'CSR' }
                                    });
                                }
                                else if (userDetails.lastLoginTime === null || userDetails.lastLoginTime === undefined) {
                                    <div>
                                        <MediaQuery minDeviceWidth={1025}>
                                            {history.push('/tutorial')}
                                        </MediaQuery>
                                        <MediaQuery maxDeviceWidth={1024}>
                                            {history.push('/dashboard')}
                                        </MediaQuery>
                                    </div>

                                }
                                else {
                                    history.push('/dashboard');
                                }
                            }
                            else {
                                if (userDetails.roles.includes("Manager")) {
                                    if (userDetails.lastLoginTime === null || userDetails.lastLoginTime === undefined) {
                                        <div>
                                            <MediaQuery minDeviceWidth={1025}>
                                                {history.push('/tutorial')}
                                            </MediaQuery>
                                            <MediaQuery maxDeviceWidth={1024}>
                                                {history.push('/dashboard')}
                                            </MediaQuery>
                                        </div>
                                    }
                                    else {
                                         history.push('/dashboard') 
                                    }
                                }
                                else if (userDetails.roles.includes("Company Admin")) {
                                    history.push({
                                        pathname: '/admin',
                                        state: { role: 'Company Admin' }
                                    });
                                }
                                else if(userDetails.roles.includes("Card Company Admin")) {
                                    history.push({
                                        pathname: '/cardadmin',
                                        state: { role: 'Card Company Admin' }
                                    });
                                }
                                else if(userDetails.roles.includes("Provisioner")) {
                                    history.push({
                                        pathname: '/carddashboard',
                                        state: { role: 'Provisioner' }
                                    });
                                }
                                else if(userDetails.roles.includes("Approver")) {
                                    history.push({
                                        pathname: '/carddashboard',
                                        state: { role: 'Approver' }
                                    });
                                }
                                else {
                                    console.log("Its none, so make it as SA")
                                    history.push({
                                        pathname: '/superadmin',
                                        state: { role: 'Super Admin' }
                                    });
                                }
                            }

                        }
                    }).catch((err) => {
                        dispatch(loginFailure(err.response ? err.response.data.serviceStatus : { message: errorMessages.loginGenericErrorMessage }))
                    });
            })
            .catch((err) => {
                let status = err.response ? err.response.status : null
                err = err.response ? err.response.data : { message: errorMessages.loginGenericErrorMessage }

                if (err.error === 'mfa_required' && status === 403) {
                    dispatch(interruptLogin());
                    history.push(`/multifactor?mfa_token=${err.mfa_token}`)
                } else dispatch(loginFailure(err))
            })
    }
}

export function attemptAutoLogin() {
    return function (dispatch, getState) {
        let {
            token,
            tokenTimestamp
        } = getState().authenticationReducer;
        dispatch(loginRequest());
        //check if a valid token is present in the store
        if (tokenValidator({
            token,
            tokenTimestamp
        })) {
            //Now fetch the user details
            dispatch(fetchUserDetails(token.access_token))
                .then((response) => {
                    //Got the user details
                    let userDetails = response.data;
                    if (false /* userDetails.isNewUser === true */) {
                        dispatch(interruptLogin());
                    } else {
                        dispatch(saveUserDetails(userDetails));
                        dispatch(loginSuccess());
                    }
                }).catch((error) => dispatch(interruptLogin()));
        } else dispatch(interruptLogin());
    }
}

export function resetPassword(values) {
    let theme = window.location.hostname.includes(elanRelatedUrls.elanUrlCheck) ? 'elan' : 'ic'
    return function (dispatch, getState) {
        const data = {
            ...values
        };
        dispatch(resetPasswordRequest());
        try {
            let queryParams = qs.parse(history.location.search.slice(1))
            let requestType = queryParams.type
            let access_token
            let options = {}

            switch (requestType) {
                case 'otp':
                    data.username = getState().authenticationReducer.tempUsername
                    if (!data.username) {
                        dispatch(resetPasswordFailure({ message: errorMessages.unableToResetPasswordErrorMessage }))
                        return
                    }
                    break
                case 'token':
                    access_token = queryParams.access_token
                    if (access_token) {
                        options.headers = {
                            "Authorization": `Bearer ${access_token}`
                        }
                    } else {
                        dispatch(resetPasswordFailure({ message: errorMessages.unableToResetPasswordErrorMessage }))
                        return
                    }
                    break
                default:
                    dispatch(resetPasswordFailure({ message: errorMessages.unableToResetPasswordErrorMessage }))
                    return

            }
            axios.post(URLs.userUrl + 'resetPassword', qs.stringify(data), options)
                .then((response) => {
                    dispatch(resetPasswordSuccess())
                })
                .catch(err => {
                    dispatch(resetPasswordFailure(err.response ? err.response.data.serviceStatus : { message: errorMessages.unableToResetPasswordErrorMessage }))
                })
        } catch (err) {
            dispatch(resetPasswordFailure(err.response ? err.response.data.serviceStatus : { message: errorMessages.unableToResetPasswordErrorMessage }))
        }

    }
}

export function forgotPassword(values) {
    return function (dispatch, getState) {
        const data = {
            ...values
        };

        // Inform the store that the request is starting
        dispatch(forgotPasswordRequest())

        // Make the call
        axios.post(URLs.userUrl + 'forgotPassword',
            qs.stringify(data), {
                headers: {
                    "Authorization": "Basic Q2FkcmUtV2ViOmNhZHJl",
                }
            })
            .then((response) => {
                dispatch(forgotCredentialsSuccess());
                dispatch(saveTempUsername(data.username))
                history.push(`/resetPassword?type=otp`)
            })
            .catch((err) => {
                dispatch(forgotPasswordFailure(err.response ? err.response.data.serviceStatus : { message: errorMessages.forgotPasswordGenericErrorMessage }));
            })

    }
}

export function sendOTP(data) {
    data.OTP_PREFERENCE=data.messageType
    return function (dispatch) {
        dispatch(sendOTPRequest());
        return axios.post(URLs.mfaUrl + 'sendOTP',
            qs.stringify(data), {
                headers: {
                    "Authorization": "Basic Q2FkcmUtV2ViOmNhZHJl",
                }
            })
            .then((response) => {
                dispatch(sendOTPSuccess());
                dispatch(saveTempUsername(data.username))
            })
            .catch((err) => {
                dispatch(sendOTPFailure(err.response ? err.response.data.serviceStatus : { message: errorMessages.otpSendingGenericErrorMessage }));
            })
    }
}

export function verifyOTP(data) {
    return function (dispatch, getState) {
        dispatch(verifyOTPRequest());
        let { tempUsername } = getState().authenticationReducer;
        data = { ...data, username: tempUsername }
        return axios.post(URLs.mfaUrl + 'verifyOTP',
            qs.stringify(data), {
                headers: {
                    "Authorization": "Basic Q2FkcmUtV2ViOmNhZHJl"
                }
            })
            .then((response) => {
                dispatch(verifyOTPSuccess());
                history.push(`/resetPassword?type=token&access_token=${response.data.data.token}`)
            })
            .catch((err) => {
                /* EW-61 - issue - invalid OTP attempts for unlimited times
                saving whole response data in verifyOTPError state variable to get third wrong otp error code  */
                dispatch(verifyOTPFailure(err.response ? err.response.data : { message: errorMessages.otpVerificationGenericErrorMessage }));
            })
    }
}