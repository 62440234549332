import axios from 'axios';
import React from 'react';
import qs from 'qs';
import { notification } from 'antd';
import { loaderFocus } from '../utils/accessability';
import { getBulkData } from './getBulkData';
import { data } from '../components/phoneData';
const { URLs } = require('../data/constants.js');

export function addCards(data) {
    return {
        type: "ADD_BULK_DATA",
        data
    }
}

export function deleteCardsData(data) {
    return {
        type: "DELETE_CARDS",
        data
    }
}

export function validateData(data) {
    return {
        type: "VALIDTE_BULK_DATA",
        data
    }
}

export function createCard(data) {
    return {
        type: "CREATE_CARD",
        data
    }
}

export function sendForApproval(data) {
    return {
        type: "SEND_APPROVAL",
        data
    }
}

export function removeBulkData() {
    return {
        type: "REMOVE_BULKDATA",
    }
}

export function updateRequestNo(data) {
    return {
        type: "UPDATE_REQID",
        data
    }
}


export function addBulkCardData(bulkdataObj, updatestate, label_keys) {
    return (dispatch, getState) => {
        let {
            token,
        } = getState().authenticationReducer;


        axios.post(URLs.cardUrl + '/addCardRequestData', bulkdataObj, {
            responseType: 'json',
            headers: {
                "Authorization": 'Bearer ' + token.access_token
            }
        }).then(result => {
            updatestate(false)
            if (result.data.serviceStatus.statusCode === 200) {
                updatestate(true);
                loaderFocus(".loader-color .ant-spin-text");
                dispatch(addCards(bulkdataObj, updatestate));
                dispatch(getBulkData(label_keys, result.data.requestNo));
                dispatch(updateRequestNo(result.data.requestNo))
                updatestate(false);
                notification.success({
                    message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Success </span>
                    {label_keys.cardDetailsSubmitSuccess}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#dff0d8',
                        color: "green",
                        border: "1px solid green",
                    }
                });
            }
            else {
                updatestate(false);
                notification.error({
                    message: <span  role='alert' aria-live="assertive" aria-atomic="true" ><span className="visually-hidden">Error </span>
                    {label_keys.couldNotcompleteReq}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }
        }).catch((error) => {
            notification.error({
                message: <span  role='alert' aria-live="assertive" aria-atomic="true" ><span className="visually-hidden">Error </span>
                {label_keys.couldNotcompleteReq}</span>,
                duration: 3,
                style: {
                    backgroundColor: '#fff1f0',
                    border: "1px solid #ffa39e",
                }
            });

        })
    }
}


export function createCardBulk(bulkdataObj, id, updatestate, label_keys, ReqFlag) {
    return (dispatch, getState) => {
        let {
            token,
        } = getState().authenticationReducer;

        let {
            requestNo,
        } = getState().bulkcardReducer;
        axios.put(URLs.cardUrl + '/createBulkCard', bulkdataObj, {
            responseType: 'json',
            headers: {
                "Authorization": 'Bearer ' + token.access_token
            }
        }).then(result => {
            if (result.data.serviceStatus.statusCode === 211 && result.data.serviceStatus.message === "User Role Restricted") {
                updatestate(false);
            }
            else if (result.data.serviceStatus.statusCode === 200) {
                updatestate(true);
                loaderFocus(".loader-color .ant-spin-text")
                dispatch(createCard(result, updatestate));
                requestNo != result.data.data.requestNo && dispatch(getBulkData(label_keys, requestNo));
                requestNo == result.data.data.requestNo && result.data.data.failedCount === 0 && dispatch(removeBulkData())
                updatestate(false);

                result.data.data.failedCount === 0 && ReqFlag && result.data.data.passedCount > 0 && notification.success({
                    message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Success </span>
                    {label_keys.cardCreateSuccessMsg}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#dff0d8',
                        color: "green",
                        border: "1px solid green",
                    }
                });
            }
            else {
                notification.error({
                    message: <span  role='alert' aria-live="assertive" aria-atomic="true" ><span className="visually-hidden">Error </span>
                    {label_keys.couldNotcompleteReq}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }
        }).catch((error) => {
            notification.error({
                message: <span  role='alert' aria-live="assertive" aria-atomic="true" ><span className="visually-hidden">Error </span>
                {label_keys.couldNotcompleteReq}</span>,
                duration: 3,
                style: {
                    backgroundColor: '#fff1f0',
                    border: "1px solid #ffa39e",
                }
            });
        })
    }
}

export function sentForCardApproval(bulkdataObj, id, updatestate, label_keys, updateSentForApproval, updateRole) {
    return (dispatch, getState) => {
        let {
            token,
        } = getState().authenticationReducer;

        let {
            requestNo,
        } = getState().bulkcardReducer;

        axios.post(URLs.cardUrl + '/sendForCardApproval', bulkdataObj, {
            responseType: 'json',
            headers: {
                "Authorization": 'Bearer ' + token.access_token
            }
        }).then(result => {
            if (result.data.serviceStatus.statusCode === 200) {
                updatestate(true);
                loaderFocus(".loader-color .ant-spin-text")
                dispatch(sendForApproval(result, updatestate));
                // requestNo != result.data.requestNo && dispatch(getBulkData(label_keys, requestNo));
                // requestNo == result.data.requestNo && dispatch(removeBulkData())
                dispatch(getBulkData(label_keys, requestNo))
                updateSentForApproval(true);
                updatestate(false);
                notification.success({
                    message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Success </span>{label_keys.approvalSentSuccess}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#dff0d8',
                        color: "green",
                        border: "1px solid green",
                    }
                });
            } else if (result.data.serviceStatus.statusCode === 211 && result.data.serviceStatus.message === "User Role Restricted") {
                updatestate(false);
                updateRole(result.data.data)
                dispatch(getBulkData(label_keys, requestNo));
            }
            else {
                notification.error({
                    message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>
                    {label_keys.couldNotcompleteReq}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }
        }).catch((error) => {
            notification.error({
                message: <span  role='alert' aria-live="assertive" aria-atomic="true" ><span className="visually-hidden">Error </span>
                {label_keys.couldNotcompleteReq}</span>,
                duration: 3,
                style: {
                    backgroundColor: '#fff1f0',
                    border: "1px solid #ffa39e",
                }
            });

        })
    }
}

export function validateBulkData(bulkdataObj, id, updatestate, label_keys, noMessage) {
    return (dispatch, getState) => {
        let {
            token,
        } = getState().authenticationReducer;

        axios.post(URLs.cardUrl + '/saveRequestData', bulkdataObj, {
            responseType: 'json',
            headers: {
                "Authorization": 'Bearer ' + token.access_token
            }
        }).then(result => {
            if (result.data.serviceStatus.statusCode === 200) {
                updatestate(false);
                dispatch(validateData(result, updatestate));
                dispatch(getBulkData(label_keys, bulkdataObj.data[0].request_id));
                updatestate(false);
                if (!noMessage) {
                    notification.success({
                        message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Success </span>
                        {label_keys.cardDetailsUploadSuccess}</span>,
                        duration: 3,
                        style: {
                            backgroundColor: '#dff0d8',
                            color: "green",
                            border: "1px solid green",
                        }
                    });
                }
            }
            else {
                if (!noMessage) {
                    notification.error({
                        message: <span  role='alert' aria-live="assertive" aria-atomic="true" ><span className="visually-hidden">Error </span>
                        {label_keys.couldNotcompleteReq}</span>,
                        duration: 3,
                        style: {
                            backgroundColor: '#fff1f0',
                            border: "1px solid #ffa39e",
                        }
                    });
                }
            }
        }).catch((error) => {
            if (!noMessage) {
                notification.error({
                    message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>
                    {label_keys.couldNotcompleteReq}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }
        })
    }
}


export function deleteCards(bulkdataObj, id, updatestate, label_keys) {
    return (dispatch, getState) => {
        let {
            token,
        } = getState().authenticationReducer;

        axios.delete(URLs.cardUrl + '/deleteCardRequestData', {
            responseType: 'json',
            headers: {
                "Authorization": 'Bearer ' + token.access_token
            },
            data: {
                deletedIds: [...bulkdataObj.deletedIds]
            }
        }).then(result => {
            if (result.data.serviceStatus.statusCode === 200) {
                updatestate(true);
                loaderFocus(".loader-color .ant-spin-text")
                dispatch(deleteCardsData(result, updatestate));
                dispatch(getBulkData(label_keys, result.data.requestNo));
                updatestate(false);
                notification.success({
                    message: <span  role='alert' aria-live="assertive" aria-atomic="true" ><span className="visually-hidden">Success </span>{label_keys.cardRequestDeletedSuccess}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#dff0d8',
                        color: "green",
                        border: "1px solid green",
                        minWidth: 420
                    }
                });
            }
            else {
                notification.error({
                    message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{label_keys.couldNotcompleteReq}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }
        }).catch((error) => {
            notification.error({
                message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>
                {label_keys.couldNotcompleteReq}</span>,
                duration: 3,
                style: {
                    backgroundColor: '#fff1f0',
                    border: "1px solid #ffa39e",
                }
            });
        })
    }
}
