import React from 'react';
import { notification, Upload, Modal, Spin, Icon, Button, } from 'antd';
import { loaderFocus } from '../../utils/accessability';
import 'antd/dist/antd.css';
// import '../../styling/createtrip.css';
import '../../styling/userAction.css';
import { connect } from 'react-redux';
import MediaQuery from 'react-responsive';
import 'react-responsive-ui/style.css';
import reqwest from "reqwest";
const { URLs , errorMessages } = require('../../data/constants.js');
const Dragger = Upload.Dragger;
 
class PolicyUpload extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            checkboxStatus: true,
            fileList: [],
            dbFetch: false,
        }
    }
    /**upload file
     * @param  {true}} =>{this.setState({dbFetch
     */
    handleUpload = () => {
        const { fileList } = this.state;
        if (fileList.length === undefined || fileList.length === 0) {
            notification.warning({
                message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Error </span>{errorMessages.error_noFileFound}</span>,
                duration: 3,
                style: {
                    backgroundColor: '#fffbe6',
                    border: "1px solid #ffe58f",
                }
            });
            return
        }
        this.setState({
            dbFetch: true
        });
        loaderFocus(".loader-color .ant-spin-text");  
        const formData = new FormData();
        let fileNames = [];
        fileList.forEach(file => {
            fileNames.push(file.name);
            formData.append("companyFile", file);
        });
        formData.append("fileNames",JSON.stringify(fileNames));
        formData.append("companyId", this.props.companyId);
        formData.append("documentType", this.props.policyType);
        reqwest({
            url: URLs.fileUrl + "uploadPolicyFile",
            headers: {
                "Authorization": 'Bearer ' + this.props.token.access_token
            },
            method: "post",
            processData: false,
            data: formData,
            success: (response) => {
                this.setState({ dbFetch: false })
                if (response.serviceStatus.statusCode === 210) {
                    notification.error({
                        message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Error </span>{response.serviceStatus.message}</span>,
                        duration: 3,
                        style: {
                            backgroundColor: '#fff1f0',
                            border: "1px solid #ffa39e",
                        }
                    });
                }
                else if (response.serviceStatus.statusCode === 200) {
                    this.setState({
                        fileList: [],
                    });
                    this.props.hideUploadModal();
                    notification.success({
                        message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Success </span>{errorMessages.success_uploadCompl}</span>,
                        duration: 3,
                        style: {
                            backgroundColor: '#dff0d8',
                            color: "green",
                            border: "1px solid green",
                        }
                    });
                }
            },
            error: (err) => {
                this.setState({ dbFetch: false })
                notification.error({
                    message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Error </span>{JSON.parse(err.response).serviceStatus.message}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }
        });
    };

    /**Cancel will hid the popup
     * @param  {} =>{this.props.hideFligthModal(
     */
    handleCancel = () => {
        this.props.hideUploadModal();
    }
    render() {
        const uploadprops = {
            name: 'companyFile',
            multiple: true,
            // action: "http://localhost:8081/api/flightFile",
            onRemove: file => {
                this.setState(({ fileList }) => {
                    const index = fileList.indexOf(file);
                    const newFileList = fileList.slice();
                    newFileList.splice(index, 1);
                    return {
                        fileList: newFileList
                    };
                });
            },
            beforeUpload: file => {
                let fileSize= file.size / 1024 / 1024;
                const pdf = (file.type === 'application/pdf');
                if (!pdf) {
                    notification.warning({
                        message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Error </span>{errorMessages.error_onlyPdf}</span>,
                        duration: 3,
                        style: {
                            backgroundColor: '#fffbe6',
                            border: "1px solid #ffe58f",
                        }
                    });
                    return;
                }
                if(fileSize>5){
                    notification.warning({
                        message:  <span role='alert' aria-live="assertive"><span className="visually-hidden">Error </span>{errorMessages.error_fileSize}</span>,
                        duration: 3,
                        style: {
                            backgroundColor: '#fffbe6',
                            border: "1px solid #ffe58f",
                        }
                    });
                    return; 
                }
                this.setState(({ fileList }) => ({
                    fileList: [...fileList, file]
                }));
                return false;
            },
            fileList: this.state.fileList
        };
        return (
            <Modal
                visible={this.props.action}
                /*  afterClose={this.afterClose1} */
                closable={false}
                destroyOnClose={true}
                onCancel={this.handleCancel}
                footer={null}
                className='modal_container'

            >
                <MediaQuery minDeviceWidth={768}>
                    <div className='row row_center'>
                        <div className='col-sm-12 col-sm-offset-0 col-xs-12 col-xs-offset-0 col_center'>
                            <Dragger {...uploadprops} showUploadList={false}>
                                <p className="ant-upload-drag-icon">
                                    <Icon type="safety-certificate" style={{ fontSize: "50px", color: "grey" }} />
                                </p>
                                <p className="ant-upload-text" style={{ color: "grey" }} ><b>{this.props.app_labels.dragnDropPolicy}</b></p>
                                {/* <p className="ant-upload-hint">Support for a single or bulk upload. Strictly prohibit from uploading company data or other band files</p> */}
                            </Dragger>
                        </div>
                    </div>
                </MediaQuery>
                <div className='row upload-class row_center'>
                    <div className='col-sm-12 col-sm-offset-0 col-xs-12 col-xs-offset-0 col_center'>
                        <Upload {...uploadprops}>
                            <Button >
                                <Icon type="cloud-upload-o" style={{ fontSize: 25, color: "#112A70" }} /><span className="upload-text">{this.props.app_labels.clickToBrowse}</span>
                            </Button>
                        </Upload>
                    </div>
                </div>
                {this.state.dbFetch ? <div className="dark-spinner loader-color"><Spin tip="Uploading..." /></div> : null}
                <div className='row'>
                    <div className='col-sm-4 col-sm-offset-1 col-xs-4 col-xs-offset-0'>
                        <Button onClick={this.handleCancel} className="upload-buttons button-wrapclass1" type="primary" ghost><b>{this.props.app_labels.cancelCapital}</b></Button>
                    </div>
                    <div className='col-sm-5 col-sm-offset-1 col-xs-4 col-xs-offset-2'>
                        <Button disabled={this.state.fileList.length === 0} onClick={this.handleUpload} className="upload-buttons-save button-wrapclass1" type="primary"><b>{this.props.app_labels.saveCapital}</b></Button>
                    </div>
                </div>
                {/*     <Button onClick={this.handleCancel} className="upload-buttons button-wrapclass1" type="primary" ghost><b>CANCEL</b></Button>
                <span style={{ marginLeft: "14%" }}><Button onClick={this.handleUpload} className="upload-buttons-save button-wrapclass1" type="primary"><b>SAVE</b></Button></span> */}
            </Modal>

        )
    }
}

function mapStateToProps(state, props) {
    return {
        tripId: state.createtripReducer.tripId,
        token: state.authenticationReducer.token,
        app_labels: state.adduserReducer.app_labels,
    };
}

export default connect(mapStateToProps)(PolicyUpload);
