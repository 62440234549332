import React from 'react'
import { Col } from 'antd'
import TransactionDetails from './TransactionDetails'

class TransactionDetailsContainer extends React.Component {
  render() {
    return (
      <Col
        xs={{span: 24}} 
        sm={{span: 24}} 
        md={{span: 8}} 
        lg={{span: 8}} 
        xl={{span: 12}}
      >
        <TransactionDetails transactionId={this.props.transactionId} 
          transactionData={this.props.transactionData}
          transactionQuestionnaire={this.props.transactionQuestionnaire}
          isUpdating={this.props.isUpdating}
          updateError={this.props.updateError}
          updateSuccess={this.props.updateSuccess}
          updateTransactionDetails={this.props.updateTransactionDetails}
        />
      </Col>
    )
  }
}

export default TransactionDetailsContainer