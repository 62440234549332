
import React from 'react';
import 'antd/dist/antd.css';
import { Modal, Button } from 'antd';
import 'antd/dist/antd.css';
// import '../../styling/createtrip.css';

class CreateCard extends React.Component {
  /**assigning card on click of OK**/
  handleOk = () => {
    this.props.handlenewTripandCard(this.props.startdate, this.props.newtripobj);
  }


  /*componentDidUpdate(prevProps){
   if(prevProps.cardAssigned === false && this.props.cardAssigned === true){
     this.props.handlenewTripandCard(this.props.creditCardNo, this.props.newtripobj);
   }
 }  */

  render() {
    return (
      <div>
        <Modal
          title={
            <div>
              <div className="cardHeading">{this.props.app_labels.confirmCreateCard}</div>
              <div className="modalheader">{this.props.app_labels.createCardWarning}</div>
            </div>
          }
          visible={this.props.action}
          onCancel={this.props.hideModal}
          footer={null}
          style={{ display: 'table' }}
        >
          <div className='row buttonTopPadding'>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
              <div style={{ flexGrow: 1, paddingLeft: "2%", paddingRight: "2%" }}>
                <Button style={{ width: "100%" }} className='horizontalCentering model-button' onClick={this.props.hideModal} type="primary" ghost><b>{this.props.app_labels.createCardCancel}</b></Button>
              </div>
              <div style={{ flexGrow: 1, paddingRight: "2%" }}>
                <Button style={{ width: "100%" }} className='horizontalCentering model-button' onClick={this.handleOk} type="primary" ><b>{this.props.app_labels.createCardConfirm}</b></Button>
              </div>
            </div>
          </div>

        </Modal>
      </div >
    );
  }
}

/* function mapStateToProps(state, props) {
  return {
    cardAssigned: state.createtripReducer.cardAssigned,
    creditCardNo: state.createtripReducer.fullcardNumber,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    createCardnew: (startDate) => {
      dispatch(createCard(startDate));
    },
  }
} */

export default CreateCard;
