import axios from 'axios';
import { URLs, errorMessages } from '../data/constants.js';


function resetTransaction() {
  return {
    type: 'RESET_TRANSACTION_DETAILS'
  }
}

function fetchTransactionDetailsRequest() {
  return {
    type: 'FETCH_TRANSACTION_DETAILS_REQUEST'
  }
}

function fetchTransactionDetailsSuccess(data) {
  return {
    type: 'FETCH_TRANSACTION_DETAILS_SUCCESS',
    payload: data
  }
}

function fetchTransactionDetailsFailure(error) {
  return {
    type: 'FETCH_TRANSACTION_DETAILS_FAILURE',
    error
  }
}

function fetchTransactionQuestionnaireRequest() {
  return {
    type: 'FETCH_TRANSACTION_QUESTIONNAIRE_REQUEST'
  }
}

function fetchTransactionQuestionnaireSuccess(data) {
  return {
    type: 'FETCH_TRANSACTION_QUESTIONNAIRE_SUCCESS',
    payload: data
  }
}

function fetchTransactionQuestionnaireFailure(error) {
  return {
    type: 'FETCH_TRANSACTION_QUESTIONNAIRE_FAILURE',
    error
  }
}

function fetchTransactionChatHistoryRequest() {
  return {
    type: 'FETCH_TRANSACTION_CHAT_HISTORY_REQUEST'
  }
}

function fetchTransactionChatHistorySuccess(data) {
  return {
    type: 'FETCH_TRANSACTION_CHAT_HISTORY_SUCCESS',
    payload: data
  }
}

function fetchTransactionChatHistoryFailure(error) {
  return {
    type: 'FETCH_TRANSACTION_CHAT_HISTORY_FAILURE',
    error
  }
}

function updateTransactionDetailsRequest() {
  return {
    type: 'UPDATE_TRANSACTION_DETAILS_REQUEST'
  }
}

function updateTransactionDetailsSuccess() {
  return {
    type: 'UPDATE_TRANSACTION_DETAILS_SUCCESS'
  }
}

function updateTransactionDetailsFailure(error) {
  return {
    type: 'UPDATE_TRANSACTION_DETAILS_FAILURE',
    error
  }
}

export function resetTransactionDetails() {
  return async function (dispatch, getState) {
    dispatch(resetTransaction())
  }
}

/**
 * Fetches the transaction details using axios 
 * @param {*} data 
 */
export function fetchTransactionDetails(data) {
  return async function (dispatch, getState) {
    try {
      dispatch(fetchTransactionDetailsRequest())
      let access_token = getState().authenticationReducer.token.access_token
      let transactionDetails = await axios.post(URLs.csrUrl + '/customers/transactions', data, {
        headers: {
          "Authorization": 'Bearer ' + access_token
        }
      }).then(response => response.data)

      let transactionQuestionnaireResult = await dispatch(fetchTransactionQuestionnaire(transactionDetails.data.transactionId))
      let transactionChatHistoryResult = []
      try {
        transactionChatHistoryResult = await dispatch(fetchTransactionChatHistory(transactionDetails.data.travelerDetails.travelerId, transactionDetails.data.transactionId))
      } catch (e) {
        console.log('Could not fetch transaction chat history', e)
        transactionChatHistoryResult = []
      }
      
      // let transactionChatHistoryResult = yield call(transactionChatHistory, { userId: 'user10', interactionId: '100010' })
      dispatch(fetchTransactionDetailsSuccess({
        transactionDetails,
        transactionQuestionnaire: transactionQuestionnaireResult,
        transactionChatHistory: transactionChatHistoryResult
      }))
    } catch (err) {
      dispatch(fetchTransactionDetailsFailure(err.response && err.response.data && err.response.data.serviceStatus ? err.response.data.serviceStatus : { message: errorMessages.fetchCSRTransactionDetailsGenericErrorMessage }))
    }
  }
}

/**
 * fetches the transaction questionnaire
 */
function fetchTransactionQuestionnaire(transactionId) {
  return async function (dispatch, getState) {
    dispatch(fetchTransactionQuestionnaireRequest())
    // let access_token = getState().authenticationReducer.token.access_token
    return axios.get(URLs.transactionQuestionnaireUrl + `/getTrasactionQuestionnaireForCSR/${transactionId}`, {
      headers: {
        'Authorization': 'Basic Q2FkcmUtV2ViOmNhZHJl'
      }
    })
      .then((response) => {
        dispatch(fetchTransactionQuestionnaireSuccess());
        return response.data;
      })
      .catch((err) => {
        dispatch(fetchTransactionQuestionnaireFailure());
        throw err;
      })
  }
}

/**
 * fetches the transaction Chat history
 * @param {*} travelerId 
 * @param {*} transactionId 
 */
function fetchTransactionChatHistory(travelerId, transactionId) {
  return async function (dispatch, getState) {
    dispatch(fetchTransactionChatHistoryRequest())
    let access_token = getState().authenticationReducer.token.access_token
    return axios.get(URLs.historyServiceUrl(travelerId, transactionId), {
      headers: {
        'Authorization': `Bearer ${access_token}`
      }
    })
      .then((response) => {
        dispatch(fetchTransactionChatHistorySuccess());
        return response.data.Data;
      })
      .catch((err) => {
        dispatch(fetchTransactionChatHistoryFailure());
        throw err;
      })
 
  }
}

/**
 * Updates the transaction details based on the changes made
 * @param {*} data 
 */
export function updateTransactionDetails(data) {
  return async function (dispatch, getState) {
    try {
      dispatch(updateTransactionDetailsRequest())
      let updatedTransactionDetails = getState().csrReducer.csrTransactionData.transactionQuestionnaire.transactionDetails
      updatedTransactionDetails.forEach(field => {
        if (data.hasOwnProperty(field.name)) {
          field.value = data[field.name]
        }
      })
      let postData = {
        transactionId: getState().csrReducer.csrTransactionData.transactionDetails.data.transactionId,
        transactionDetails: {
          "formArray": [],
          "receipt": {
            "applicable": false
          },
          "fields": updatedTransactionDetails
        }
      }
      let response = await axios.post(URLs.transactionQuestionnaireUrl + '/postTransactionResponse', postData, {
        headers: {
          'Authorization': 'Basic Q2FkcmUtQ2hhdDpjYWRyZQ=='
        }
      })
        .then(response => response.data)
      dispatch(updateTransactionDetailsSuccess(response))
    } catch (err) {
      dispatch(updateTransactionDetailsFailure(err.response && err.response.data && err.response.data.serviceStatus ? err.response.data.serviceStatus : { message: errorMessages.updateCSRTransactionDetailsGenericErrorMessage }))
    }
  }
}

/**
 * Download the trip media attachments
 * @param {*} url 
 * @param {*} fileName 
 * @param {*} attachmentId 
 */
export function downloadTripMedia(url, fileName, attachmentId) {
  return async function (dispatch, getState) {
    try {
      let access_token = getState().authenticationReducer.token.access_token
      axios.get(URLs.fileUrl + 'downloads/' + attachmentId, {
        responseType: 'blob',
        headers: {
          "Authorization": 'Bearer ' + access_token
        }
      }).then(response => {
        var a = document.createElement('a');
        a.href = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' })); // xhr.response is a blob
        a.download = fileName; // Set the file name.
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        setTimeout(function () {
          window.URL.revokeObjectURL(a.href);
        }, 100)
      })
    } catch (err) {
      console.log(err)
    }
  }
}