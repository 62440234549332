import axios from 'axios';
import React from 'react';
import { notification } from 'antd';
const { URLs, errorMessages } = require('../../data/constants.js');
function CreateNew_Profile(data) {
    return {
        type: "CREATE_PROFILE",
        data
    }
}
export function CreateMore(data) {
    return {
        type: "CREATE_MORE",
        data
    }
}
export function CreateNewProfile(object, updateState, successMessage, errorMessage) {
    return (dispatch, getState) => {
        let {
            token
        } = getState().authenticationReducer;
        axios.post(URLs.userUrl + 'create', JSON.stringify(object), {
            responseType: 'application/json',
            headers: {
                "Content-Type": 'application/json',
                "Authorization": 'Bearer ' + token.access_token
            }
        }).then(result => {
            if (result.data.serviceStatus.statusCode === 210) {
                updateState(false);
                notification.error({
                    message: <span  role='alert' aria-live="assertive" aria-atomic="true" ><span className="visually-hidden">Error </span>{errorMessage}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }
            else if (result.data.serviceStatus.statusCode === 200) {
                dispatch(CreateNew_Profile());
                notification.success({
                    message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Success </span>{successMessage}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#dff0d8',
                        color: "green",
                        border: "1px solid green",
                    }
                });
                updateState(false);

            }
            else {
                updateState(false);
                notification.error({
                    message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{errorMessage}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }
        }).catch((error) => {
            updateState(false);
            if (error.response !== undefined && error.response.data !== null && error.response.data !== undefined && error.response.data.serviceStatus !== undefined) {
                notification.error({
                  message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{errorMessage}</span>,
                  duration: 3,
                  style: {
                    backgroundColor: '#fff1f0',
                    border: "1px solid #ffa39e",
                  }
                });
              }
              else {
                notification.error({
                  message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{errorMessage}</span>,
                  duration: 3,
                  style: {
                    backgroundColor: '#fff1f0',
                    border: "1px solid #ffa39e",
                  }
                });
              }
            
        })
    }
}
