import initialState from './initialState';
// import store from '../store/store.js';


const adduserReducer = (state = initialState, action) => {
    switch (action.type) {

        case 'ADDUSER':
            console.log("inside adduser reducer function")
            return Object.assign({}, state, { userobj: action.obj, saved: action.saved });

        case 'MULTI_LAN_REQUEST':
            return {
                ...state,
                isMulLanLabelFetch: true
            }
        case 'MULTI_LAN_REQUEST_SUCCESS':
            return {
                ...state,
                isMulLanLabelFetch: false,
                app_labels: action.response,
                selectedLanguage:action.selectedLanguage
            }
        case 'MULTI_LAN_REQUEST_COMP':
            return {
                ...state,
                isMulLanLabelFetch: false
            }
        case 'LIST_LANGUAGE':
            return {
                ...state,
                isMulLanLabelFetch: false,
                languageList: action.response
            }
        default:
            return state;
    }
};

export default adduserReducer;