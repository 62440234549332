import React, { Component } from 'react'
import ReactDOM from 'react-dom';
import { connect } from 'react-redux'
import { Card, Row, Col, Table, notification, Icon, Modal, Button, message, Slider,Spin } from 'antd';
import { Input } from "antd/lib/index";
import moment from 'moment';
import XLSX from "xlsx";
// import '../../styling/activeCard.css';
// import '../../styling/createtrip.css'
import CradDetailComponent from './CradDetailComponent'
import { EditableFormRowActiveCards, EditableCellActiveCards } from "./activeCardEditable";
import Edit from '../../images/bulk/Edit@2x.png'
import FilterComp from './filterComp'
import 'antd/dist/antd.css'
import axios from "axios";
import prepareTitle,{ loaderFocus,checkBoxStatusForCards } from '../../utils/accessability';
import Tick from '../../images/bulk/tick.svg';
import ExportBtn from '../ExportBtn';
import ProfileDetails from '.././profileDetails';
import Filter from '../../images/provisioner/filter.png';
import { viewPhoneNumber } from './phoneNumberFormat'

var dateFormat = require('dateformat');
const { URLs } = require('../../data/constants.js')
const { Search } = Input;
class ActiveCards extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      data1: [],
      detailsVisible: false,
      activeCardCount: '0',
      searchTerm: '',
      filterTable: null,
      selectedRow: {},
      index: 0,
      filterData: [],
      current: 1,
      TotalPage: null,
      isLoading: false,
      sortOrderText: '',
      sortedInfo: null,
      prevActiveElement: null,
      maxValue: 0,
      minValue: 0,
      left: 0,
      right: 40,
      size: 10,
      detailUpdateSuccess: false,
      okClicked: false,
      selectedRows: [],
      selectedRowKeys: [],
      editingKey: [],
      editClick: false,
      cols: [],
      rows: [],
      updatedActiveData: [],
      validToEdit: false,
      validTo: false,
      error: null,
      disableSaveChanges: true,
      updatedErrorList: [],
      exportAllCardsModalVisible: false,
      currentDataSource: [],
      modalFilterVisible: false,
      profileDetailsVisible: false,
      profileType: '',
    }
    this.searchBox = React.createRef();
    // this.defaultFocus = this.defaultFocus.bind(this);
  }


  componentDidMount(prevProps) {
    this.fetchActiveCardDetails();
    if (!this.props.userDetails.viewActiveCardEnable) {
      if (window.location.hostname.includes('elan-solutions.elaneasypay'))
        document.title = "Active cards: Elan Easy Pay";
      else
        document.title = "Active cards: US Bank Instant Card";
      if (ReactDOM.findDOMNode(this.refs.itemtest)) {
        //alert('yes')
        ReactDOM.findDOMNode(this.refs.itemtest).focus();
      }
    } else {
      if (window.location.hostname.includes('elan-solutions.elaneasypay'))
        document.title = "My Active cards: Elan Easy Pay";
      else
        document.title = "My Active cards: US Bank Instant Card";
      if (ReactDOM.findDOMNode(this.refs.itemtest)) {
        //alert('yes')
        ReactDOM.findDOMNode(this.refs.itemtest).focus();
      }
    }
    if (this.state.editable) {
      // if(this.props.okClicked){
      document.getElementById('edit-btn').focus();
      // }
    }


  }

  componentDidUpdate() {

    var ulDiv = document.querySelectorAll(".ant-pagination.ant-table-pagination");
    if (ulDiv[0]) {
      ulDiv.forEach(div => {
        // console.log("Last element:::", div.lastElementChild , div.lastElementChild.className ==="ant-pagination-options");
        if (div.lastElementChild.className === "ant-pagination-options")
          div.prepend(div.lastElementChild)
      })
    }

    const divUp = document.querySelectorAll(".anticon.anticon-caret-up.ant-table-column-sorter-up.off");
    const divDown = document.querySelectorAll(".anticon.anticon-caret-down.ant-table-column-sorter-down.off");
    // console.log("div:::", divUp)
    if (divUp[0]) {
      divUp.forEach(div => {
        div.setAttribute('role', 'img')
        div.setAttribute('aria-hidden', 'true')
      })
    }
    if (divDown[0]) {

      divDown.forEach(div => {
        div.setAttribute('role', 'img')
        div.setAttribute('aria-hidden', 'true')
      })

    }

    const divSearch = document.getElementsByClassName("anticon anticon-search ant-input-search-icon");

    if (divSearch[0]) {
      divSearch[0].setAttribute('role', 'img')
      divSearch[0].setAttribute('aria-hidden', 'true')
    }
    const icon = document.querySelectorAll('.anticon.anticon-down.ant-select-arrow-icon');
    if (icon) {
      icon.forEach(el => {
        el.setAttribute('role', 'img')
        el.setAttribute('aria-hidden', 'true')
      })
    }
    const paginationDiv = document.querySelectorAll('.ant-pagination.ant-table-pagination');
    if (paginationDiv[0]) {
      paginationDiv.forEach(div => {
        if (!div.getAttribute('role'))
          div.setAttribute('role', 'presentation');
      })
    }

    const ellipsis = document.querySelectorAll('.ant-pagination-item-ellipsis');
    if (ellipsis[0]) {
      ellipsis.forEach(div => {
        if (!div.getAttribute('aria-hidden'))
          div.setAttribute('aria-hidden', 'true');
      })
    }

    const prevFiveDiv = document.querySelectorAll('.ant-pagination-jump-prev.ant-pagination-jump-prev-custom-icon');
    if (prevFiveDiv[0]) {
      prevFiveDiv.forEach(div => {
        if (!div.getAttribute('role')) {
          div.setAttribute('role', 'link');
          div.setAttribute('aria-label', "Previous 5 pages");
        }

      })
    }

    const prevFiveDivIcon = document.querySelectorAll('.anticon.anticon-double-left.ant-pagination-item-link-icon');
    if (prevFiveDivIcon[0]) {
      prevFiveDivIcon.forEach(div => {
        if (div.getAttribute('aria-label'))
          div.removeAttribute('aria-label');
      })
    }

    const nextFiveDiv = document.querySelectorAll('.ant-pagination-jump-next.ant-pagination-jump-next-custom-icon');
    if (nextFiveDiv[0]) {
      nextFiveDiv.forEach(div => {
        if (!div.getAttribute('role')) {
          div.setAttribute('role', 'link');
          div.setAttribute('aria-label', "Next 5 pages");
        }
      })
    }

    const nextFiveDivIcon = document.querySelectorAll('.anticon.anticon-double-right.ant-pagination-item-link-icon');
    if (nextFiveDivIcon[0]) {
      nextFiveDivIcon.forEach(div => {
        if (div.getAttribute('aria-label'))
          div.removeAttribute('aria-label');
      })
    }

    const prevPageDiv = document.querySelectorAll('.ant-pagination-prev');
    if (prevPageDiv[0]) {
      prevPageDiv.forEach(div => {
        if (!div.getAttribute('role')) {
          div.setAttribute('role', 'link');
          div.setAttribute('aria-label', "Previous page");
        }
      })
    }

    const nextPageDiv = document.querySelectorAll('.ant-pagination-next');
    if (nextPageDiv[0]) {
      nextPageDiv.forEach(div => {
        if (!div.getAttribute('role')) {
          div.setAttribute('role', 'link');
          div.setAttribute('aria-label', "Next page");
        }
      })
    }


    const dropDown = document.querySelectorAll('.ant-select-selection.ant-select-selection--single');
    if (dropDown[0]) {
      dropDown.forEach(div => {
        if (!div.getAttribute('aria-label') && !div.firstElementChild.innerHTML.includes("Active&nbsp;Cards"))
          div.setAttribute('aria-label', 'Select rows');


        div.addEventListener('keyup', function () {
          // console.log("Event on click fired");
          const comboboxOption = document.querySelectorAll('.ant-select-dropdown-menu-item');
          // console.log("Div:::", comboboxOption);
          if (comboboxOption[0]) {
            var i = 0
            comboboxOption.forEach(el => {
              i += 1
              if (!el.getAttribute('id'))
                el.setAttribute('id', `option-${i}`);
              el.removeAttribute('aria-selected');
            })
          }
          let optionDiv = document.querySelectorAll('.ant-select-dropdown-menu-item.ant-select-dropdown-menu-item-active');
          // console.log("Div:::", optionDiv);
          if (optionDiv[0]) {
            let activeDescendant = optionDiv[0].getAttribute('id')
            //  console.log("active descendant::", activeDescendant);   
            div.setAttribute('aria-activedescendant', activeDescendant);
          }
        });
      })

    }
    if (this.state.modalFilterVisible) {
      const modal = document.querySelector('.ant-modal-wrap');
      if (modal) {
        setTimeout(() => {
          modal.setAttribute('aria-labelledby', 'slider_title');
        }, 100)
      }
      setTimeout(() => {
        const div = document.getElementsByClassName('ant-slider-handle');
        if (div && !div[0].getAttribute('aria-label')) {
          div[0].setAttribute('aria-label', 'Credit limit min');
          div[1].setAttribute('aria-label', 'Credit limit max');
        }
      }, 500)
    }
  }
  showProfileDetails = (type) => {
    this.setState({
      prevActiveElement: document.activeElement,
      profileDetailsVisible: true,
      profileType: type,
    })
  }

  handleCancel = () => {
    this.setState({ selectedRowKeys: [], selectedRows: [] });
  }

  fetchActiveCardDetails() {
    // console.log('this.state.selectedLang', this.props.label_keys.saveChanges, this.props.selectedLang)
    this.setState({ isLoading: true });
    loaderFocus(".loader-color .ant-spin-text");  
    axios.get(URLs.cardUrl + `/getActiveCardsData`, {
      headers: {
        "Authorization": 'Bearer ' + this.props.token.access_token
      }
    }).then(res => {
      res.data.data &&
        this.setState({
          data: res.data.data,
          data1: res.data.data,
          currentDataSource: res.data.data,
          activeCardCount: res.data.data.length,
          TotalPage: (Math.ceil(res.data.data.length / this.state.size)),
          isLoading: false
        },() => (this.state.searchTerm !== '' && this.state.searchTerm !== null) && this.onSearch(this.state.searchTerm))
      if (this.state.selectedRow && this.state.selectedRow.cardRef) {
        let selectedRecord = res.data.data.filter((row) => row.cardRef === this.state.selectedRow.cardRef);
        // console.log("Selected Record:::", selectedRecord[0]);
        this.setState({ selectedRow: selectedRecord[0] });
      }
      setTimeout(() => {
        const div = document.querySelectorAll('.ant-checkbox-input');
        if (div[0]) {
          div.forEach(el => {
            if (!el.getAttribute('aria-label')) {
              el.setAttribute('aria-label', 'Select all cards created');
            }
          })
        }
      }, 500)
    }).catch(error => {
        this.setState({ isLoading: false })
        if (error.response !== undefined && error.response.data !== null && error.response.data !== undefined && error.response.data.serviceStatus !== undefined) {
          notification.error({
            message: <span role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{this.props.label_keys.couldNotcompleteReq}</span>,
            duration: 3,
            style: {
              backgroundColor: '#fff1f0',
              border: "1px solid #ffa39e",
            }

          });

        }
        else {
          notification.error({
            message: <span role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{this.props.label_keys.couldNotcompleteReq}</span>,
            duration: 3,
            style: {
              backgroundColor: '#fff1f0',
              border: "1px solid #ffa39e",
            }
          });
        }
      }

      )}

  handleSliderChange = (e) => {
    if (e) this.setState({ left: e[0], right: e[1] <= this.state.maxValue ? e[1] : this.state.maxValue })
  }

  handleFilterCancel = () => {
    this.setState({ modalFilterVisible: false });
  };

  filterData = () => {
    let filterData;
    let filteredValue = this.state.data1;
    if (filteredValue.length > 0) {
      filterData = filteredValue.filter((row) => row.creditLimit >= this.state.left && row.creditLimit <= this.state.right)
      this.setState({ data: filterData,currentDataSource: filterData, modalFilterVisible: false, TotalPage: Math.ceil(filterData.length / this.state.size), current: 1, display: filterData && filterData.length > 0 ? true : false })
    }
  }

  // filterRange = (range) => {
  //   let filterData;
  //   let filteredValue = this.state.data1;
  //   if (range) {
  //     filterData = filteredValue.filter((row) => row.creditLimit >= this.state.left && row.creditLimit <= this.state.right)
  //     this.setState({ data: filterData, currentDataSource: filterData, current: 1, TotalPage: Math.ceil(filterData.length / this.state.size) })
  //     // this.setPageSize(Math.ceil(filterData.length / this.state.size));
  //   }
  // }

  defaultFocus = () => {
    console.log("Default focus");
    if (this.state.prevActiveElement) {
      if (document.body.contains(this.state.prevActiveElement)) {
        // setTimeout(() => {
        this.state.prevActiveElement.focus();
        // this.setState({
        //   prevActiveElement: null
        // })
        // }, 100);
      }
    } else {

      if (ReactDOM.findDOMNode(this.refs.itemtest)) {
        //alert('yes')
        ReactDOM.findDOMNode(this.refs.itemtest).focus();
      }
    }
    //ReactDOM.findDOMNode(this.refs.itemtest).focus();
  }

  onSearch = (value) => {
    let cards = this.state.data1;
    let cardsArray;
    let keys = ['name', 'employeeId', 'creditLimit', 'validFrom', 'validTo', 'field1', 'emailId']
    if (value === "") {
      this.setState({ data: this.state.data1, currentDataSource: this.state.data1, current: 1, TotalPage: Math.ceil(cards.length / this.state.size) });
      // this.setPageSize(Math.ceil(cards.length / this.state.size));
    } else if (value) {
      cardsArray = cards.filter(o =>
        keys.some(k =>
          String(k === 'creditLimit' ? "$" + o[k] : o[k])
            .toLowerCase()
            .includes(value.toLowerCase())
        )
      );
      this.setState({ data: cardsArray, currentDataSource: cardsArray, TotalPage: Math.ceil(cardsArray.length / this.state.size), current: 1 });
      // this.setPageSize(Math.ceil(cardsArray.length / this.state.size));
    }

  }
  updateDetails = () => {
    this.fetchActiveCardDetails()
  }

  handleChange = (pagination, filters, sorter, currentDataSource) => {
    this.setState({
      sortedInfo: sorter,
      currentDataSource: currentDataSource.currentDataSource,
      sortOrderText: `Table sorted by ${sorter.columnKey}, in ${sorter.order}ing order`,
    });
  }

  itemRender = (current, type, originalElement) => {
    if (type === 'prev') {
      return <a className="myAct_arrows-left"><Icon className="myAct_arrows-left" type="arrow-left" aria-label={null} /></a>

    }
    if (type === 'next') {
      return <a className="myAct_arrows-right"><Icon type="arrow-right" aria-label={null} /></a>;
    }
    return originalElement;

  }

  pageChanger = (page, defaultPageSize) => {


    let TotalPage = Math.ceil(this.state.data.length / defaultPageSize)
    this.setState({ current: page, TotalPage: TotalPage })

  }

  OnPageSizeChange = (current, size) => {
    let TotalPage = Math.ceil(this.state.data.length / size)
    this.setState({ current: current, TotalPage: TotalPage, size: size })
  }

  cardDetails() {

    this.setState({
      prevActiveElement: document.activeElement,
      detailsVisible: !this.state.detailsVisible
    })


    // console.log("Active element ::: ", this.state.prevActiveElement, document.activeElement);
  }

  getMaxCardLimitValue = () => {
    let currentData = this.state.data1;
    let maxValue = Math.max.apply(null,
      currentData.map(function (o) { return o.creditLimit; }));
    return maxValue;
  }

  isEditing = record => {
    // console.log("record:::", record.cardRef)
    for (let i = 0; i < this.state.editingKey.length; i++) {
      if (record.cardRef === this.state.editingKey[i]) {
        return true;
      }
    }
  }

  edit(key) {
    this.setState({ editingKey: key })
  }

  onSelectChange = (selectedRowKeys) => {
    let result = [];
    setTimeout(() => {
      if(selectedRowKeys && !(selectedRowKeys.length > 0)){
        this.setState({editableMode: false, updatedActiveData: [], disableSaveChanges: true});
          this.edit([]);
      }
      this.setState({ selectedRowKeys });
      if (this.state.editableMode === true) {
        let editingkeyAdd = [];
        for (let i = 0; i < selectedRowKeys.length; i++) {
          editingkeyAdd.push(selectedRowKeys[i]);

          let updatedData = this.state.updatedActiveData.filter(item => selectedRowKeys[i] === item.cardRef);
          this.setState({ updatedActiveData: updatedData });
        }
        this.edit(editingkeyAdd);
      }
      for (let i = 0; i < this.state.selectedRowKeys.length; i++) {
        result.push(this.state.data.find(obj => {
          return obj.cardRef === this.state.selectedRowKeys[i];
        }))
      }
      if (result === []) {
        this.setState({ deactivateBtnEnable: false })
      } else {
        this.setState({ deactivateBtnEnable: true })
      }
    }, 500);
  }

  selecteAllUpdate = () => {
    let data_;
    this.setState({ selectedRowKeys: data_.map(row => row.key) }, () => { this.onSelectChange(this.state.selectedRowKeys) });
  }
  unSelectAllUpdate = () => {
    let newArray = [];
    this.setState({ selectedRowKeys: newArray }, () => { this.onSelectChange(this.state.selectedRowKeys) });
  }

  handleEditMode = () => {
    if (this.state.selectedRowKeys.length > 0) {
      // this.setState({ editClick: true });
      this.setState({ editableMode: true });
      let editingkeyAdd = [];
      for (let i = 0; i < this.state.selectedRowKeys.length; i++) {
        editingkeyAdd.push(this.state.selectedRowKeys[i]);
      }
      this.edit(editingkeyAdd);
      // this.setState({ multiCardSuccessModal: true })
    } 
    else
      (message.error({ content: <span role="alert" ><span className="visually-hidden">Error </span>{this.props.label_keys.selectRecordToEdit}</span> }))

  }

  handleValidate = (option) => {
    // console.log("option???", option, this.state.selectedRowKeys)
    let selectedKeys = this.state.selectedRowKeys;
    let newPassedData = {};
    let apiData = {};
    let newFailedData = 0;
    let newPassedDataTxt = 0;
    let { failedData } = this.props;
    let apiReqData = {};
    apiReqData.data = [];
    newPassedData.data = [];
    // /** Note: noMessage setting flag false will show the Req messages on screen for saveRequestData api req
    // **/
    let noMessage = false;
    // for (let i = 0; i < selectedKeys.length; i++) {
    //   console.log("selectedKeys[i]:: :", selectedKeys[i])
    // };

    setTimeout(() => {
      const errorDiv = document.querySelector('.ant-modal-confirm-error');
      const closeIcon = document.querySelectorAll('.ant-modal-confirm-error .anticon.anticon-close-circle');
      if (errorDiv) {
        errorDiv.parentNode.setAttribute("aria-labelledby", "alert");
      }
      if (closeIcon[0]) {
        closeIcon[0].setAttribute('aria-label', 'Error');
        closeIcon[0].setAttribute('role', 'img');
      }
    }, 100)
  }

  handleCancelEditMode = () => {
    this.setState({ editableMode: false, updatedActiveData: [], disableSaveChanges: true });
    this.edit({});
  }

  getMinCardLimitValue = () => {
    let currentData = this.state.data1;
    let minValue = Math.min.apply(null,
      currentData && currentData.map(function (o) { return o.creditLimit; }));
    return minValue;
  }

  showFilter = () => {
    let maxValue = this.getMaxCardLimitValue();
    let minValue = this.getMinCardLimitValue();
    this.setState({ minValue: minValue, maxValue: maxValue,  modalFilterVisible: true });
  }

  handleSave = (row, errorObj) => {

    let toCompareObject = (({ validFrom, validTo, cardRef, creditLimit, osn }) => ({ validFrom, validTo, cardRef, creditLimit, osn }))(this.state.selectedRow)

    // console.log("toCompareArray", toCompareObject.validTo == row.endDate, toCompareObject.validTo, row.endDate)
    // this.setState({ disableSaveChanges: ((toCompareObject.creditLimit == row.budgetAmount && row.startDate == null && row.endDate == null) || (toCompareObject.creditLimit == row.budgetAmount && toCompareObject.validTo == row.endDate && row.startDate == null) || (toCompareObject.validTo == row.endDate && row.budgetAmount == null && row.startDate == null)) ? true : false })

    let activeData = this.state.updatedActiveData;
    let index = activeData.filter(item => row.cardRef !== item.cardRef);
    index.push(row)


    let errorList = this.state.updatedErrorList;
    let errorData = errorList.filter(item => row.cardRef !== item.cardRef);
    if (errorObj.cL || errorObj.vF || errorObj.vT) {
      errorData.push(errorObj)
    }

    this.setState({ updatedActiveData: index, updatedErrorList: errorData, disableSaveChanges: errorData && errorData.length > 0 ? true : false });

    // console.log("updatedActiveData", this.state.updatedActiveData, errorData, this.state.updatedErrorList)
  };

  updateDetails = () => {
    this.fetchActiveCardDetails()
  }



  checkCardValidityDates = (record) => {
    let today = new Date(moment(new Date()).tz('America/Chicago').format('MM/DD/YYYY'));
    let date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

    // let fromDate = moment(record['validFrom']);
    // let toDateFrom = moment(date);
    // let fromDays = toDateFrom.diff(fromDate, 'days');
    // let isValidFromDate = fromDays > 0 ? false : true;

    let tillDate = moment(record['validTill']);
    let toDateTill = moment(date);
    let tillDays = toDateTill.diff(tillDate, 'days');
    let isValidTillDate = tillDays > 0 ? false : true

    let fromDateChk = moment(record['validFrom']);
    let tillDateChk = moment(record['validTill']);
    let bothDaysDiff = tillDateChk.diff(fromDateChk, 'days');
    let isBothValid = bothDaysDiff > 0 && bothDaysDiff <= 365 ? true : false;
    // if (isValidFromDate && isValidTillDate && isBothValid)
    if (isValidTillDate && isBothValid)
      return true;
    else
      return false;
  }

  onSaveCardDetails = () => {
    this.setState({ editableMode: false })
    this.edit({});
    if (this.state.updatedActiveData) {
      let cardDetailsList = { cardDetails: this.state.updatedActiveData }

      axios.post(URLs.cardUrl + '/updateCardData', cardDetailsList, {
        responseType: 'json',
        headers: {
          "Authorization": 'Bearer ' + this.props.token.access_token
        }
      }).then(result => {
        console.log(result);
        if (result.data.serviceStatus.statusCode === 200 && result.data.requestNo && result.data.requestNo.cardStatus && result.data.requestNo.cardStatus.length > 0 && result.data.requestNo.cardStatus[0].status === "Success") {
          // let validFrom = this.state.validFrom;
          // let validTo = this.state.validTo;
          // let error = { creditLimit: false, validFrom: false, validTo: false };
          // if ((this.state.validToEdit && (dateFormat(validTo, 'mm/dd/yyyy') !== (this.state.selectedRow.validTo ? dateFormat(this.state.selectedRow.validTo, 'mm/dd/yyyy') : dateFormat(this.state.selectedRow.validTill, 'mm/dd/yyyy'))))) {
          //   if (!this.checkCardValidityDates(validTo) || !this.checkValidToRule())
          //     console.log("error.validTo :::", error.validTo)
          //   error.validTo = true;
          // }

          this.fetchActiveCardDetails();
          this.setState({ updatedActiveData: [], detailUpdateSuccess: true })
        } else {
          notification.error({
            message: <span role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>
              {this.props.label_keys.couldNotcompleteReq}</span>,
            duration: 3,
            style: {
              backgroundColor: '#fff1f0',
              border: "1px solid #ffa39e",
            }
          });
          this.setState({ updatedActiveData: []})
        }
      })
        .catch(error => {
          console.log(error);
          notification.error({
            message: <span role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>
              {this.props.label_keys.couldNotcompleteReq}</span>,
            duration: 3,
            style: {
              backgroundColor: '#fff1f0',
              border: "1px solid #ffa39e",
            }
          });
          this.setState({ updatedActiveData: []})
        })
    }
  }

  onExport = (prevEle) => {
    this.setState({ exportAllCardsModalVisible: true, prevActiveElement: prevEle });
    // console.log("this.props.previousActiveElement:::", prevEle)
  }


  handleExportCards = () => {

    let records = this.state.selectedRowKeys.length === 0 ? this.state.currentDataSource : this.state.selectedRows;
    let fileName = this.props.userDetails.companyName + "_" + moment().format("MM_DD_YYYY_HH_mm_ss") + "_" + this.props.label_keys.activeCards + ".xlsx";

    const newData = records.map(obj => {
      return {
          'NAME': obj.name,
          'CARD NUMBER': obj.cardNumber,
          'EMPLYOEE ID': obj.employeeId,
          'PROVISIONED CREDIT LIMIT': '$' + obj.creditLimit,
          'VALID FROM': obj.validFrom,
          'VALID TO': obj.validTo,
          'FIELD1': obj.field1,
          'FIELD2': obj.field2,
          'NOTES': obj.notes,
          'DATE CREATED': obj.cardCreatedDate,
          'CREATED BY': `${this.props.userDetails.firstName} ${this.props.userDetails.lastName}`,
          'APPROVED BY': !obj.approvername || obj.approvername === ' ' ? "Not applicable" : obj.approvername,
          'EMAIL ID': obj.emailId,
          'MOBILE NUMBER': viewPhoneNumber(obj.mobileNumber),
          'ACCOUNT ID': `${obj.osn} ${this.props.theme === "elan" || (obj.serviceInterface && obj.serviceInterface.includes("Elan EasyPay"))? 'EP' : 'IC'}`,
          'BILLING ADDRESS': `${obj.FLAT !== null ? obj.FLAT : ''} ${obj.STREET_ADDRESS !== null ? obj.STREET_ADDRESS: null} ${obj.CITY !== null ? obj.CITY: ''} ${obj.STATE !== null ? obj.STATE : ''} ${obj.COUNTRY !== null ? obj.COUNTRY : ''} ${obj.ZIP_CODE !== null ? obj.ZIP_CODE : ''}`,
          'BUSINESS NUMBER': viewPhoneNumber(obj.businessNumber)

        }
    })

    let binaryWS = XLSX.utils.json_to_sheet(newData);
    let wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, binaryWS, 'Active Cards')
    XLSX.writeFile(wb, fileName);
    this.setState({ exportAllCardsModalVisible: false });
    setTimeout(() => { this.state.selectedRowKeys && this.setState({ selectedRowKeys: [], selectedRows: [] }) }, 1000);
    // console.log("document.body.contains(this.state.previousActiveElement)", document.body.contains(this.state.previousActiveElement))
    if (document.body.contains(this.state.prevActiveElement)) {
      this.state.prevActiveElement.focus()
    }
  }


  render() {
    let { sortedInfo, selectedRowKeys } = this.state;
    sortedInfo = sortedInfo || {};

    const operations = (
      <>
      <Row>
        <Col span={12} style={{ marginLeft: null }}>
          <Search
              tabIndex={0}
              className="search-input search-input-suffix all-active-search all-active-card-search"
              aria-label="Search"
              prefix={<Icon type="search" role="img" aria-hidden="true" />}
              suffix={<div style={{ display: 'none' }}>-</div>}
              // style={{ width: this.props.theme === "elan" ? '81%' : '81%', marginLeft: this.props.theme === "elan" ? '-77px' : '-39px' }}
              style={{
                height: '38px', minHeight: '20px', border: 'none', width: this.props.roles === 'Super Admin' ? '34%': null,
                borderTopLeftRadius: '4px', borderBottomLeftRadius: '4px', borderTopRightRadius: '4px', borderBottomRightRadius: '4px',position:'absolute',left: '-39%'
              }}
              placeholder={this.props.label_keys.search}
              onChange={(e) => {
                if (e.target.value === "" || e.target.value === null) {
                  this.setState({ searchTerm: "" });
                  this.onSearch("")
                } else {
                  this.setState({ searchTerm: e.target.value });
                }
              }}
              autocomplete="off"
              onSearch={value => this.onSearch(value)}
            // onChange={() => this.setState} 
          />
        </Col>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <Button type={`primary filter-comp ${this.state.selectedRowKeys && !this.state.selectedRowKeys.length > 0? 'disabled-edit-icon': ''}`} className="edit-btn edit-btn-zoom myAct_edit-btn edit400 actv-edit" ref={"provisionItem"} id="credit-limit-input" style={{
          // left: '68%',
          padding: '2px', backgroundColor: '#19398A',borderColor: '#19398A', width: '40px',height:'40px',left: '-32%',paddingTop:'7px'
        }} onClick={() => {
          this.handleEditMode()
          // this.setState({ multiCardSuccessModal: true }, console.log("this.state.multiCardSuccessModal)", this.state.multiCardSuccessModal))
        }} disabled={!this.state.selectedRowKeys.length > 0}>
          <div style={{ textAlign: 'right', outlineColor: 'transparent', marginTop: this.props.theme === "elan" ? "-5%" : "-6%" }}/* className="col-sm-1 col-sm-offset-3 edit-icon-details" */>
            <span
              onClick={(e) => {
                // this.setState({ editEnable: true })
                setTimeout(() => {
                  // console.log("document.getElementById('credit-limit-input').focus()", document.getElementById('credit-limit-input').focus());
                  // document.getElementById('edit-btn').focus()
                }, 100);
              }}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  // this.setState({ editEnable: true });
                  setTimeout(() => {
                    // console.log("document.getElementById('credit-limit-input').focus()", document.getElementById('credit-limit-input').focus());
                    // document.getElementById('edit-btn').focus()
                  }, 100);
                }
              }}
            >
              <i role="img" aria-label="Edit" className="icon-Edit" id="edit-btn"
                style={{ color: "white", fontSize: "19px", fontWeight: 600, padding: '6px', outlineColor: 'transparent' }} /></span>
          </div>
        </Button>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <Col span={2} style={{ marginLeft: "8%" }}>
          <Button className ="filtered_btn" type="primary filter-comp all-active-filter" style={{ backgroundColor: '#19398A', borderColor: '#19398A', height: '38px', 
            left: this.props.theme === 'elan' ? '-18px': '-8px' }} onClick={this.showFilter} >            
            <img className="filter" style={{
              width: "38px", height: "38px", marginTop: this.props.theme === 'elan' ? '-4%' : '-1px',
            }} src={Filter} alt={this.props.label_keys.filter} />
          </Button>
        </Col>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <Col  span={2} className={'exprt-btn'} style={{ marginLeft: this.props.theme === 'elan' ? "-8%" :"-1%", marginTop: this.props.theme === 'elan' ? '-0.5px':'1px' }}>
          <ExportBtn label_keys={this.props.label_keys} selectedLang={this.props.selectedLang === "French" ? "French" : "English"} selected={this.state.selectedRowKeys && this.state.selectedRowKeys.length !== 0} onExport={(prevEle) => this.onExport(prevEle)} />
        </Col>

      </Row>

      <Modal className="confirm-visible filter-modal actCard-filter-zoom" role='dialog'
        title={<h2 style={{ fontSize: '16px', fontWeight: "700", color: "#161a80" }}>{this.props.label_keys.filter}</h2>}
        visible={this.state.modalFilterVisible} onCancel={this.handleFilterCancel}
        onOk={this.filterData} cancelText={this.props.label_keys.cancel}
        okText={<span style={{ textTransform: this.props.theme !== "elan" ? "capitalize" : "uppercase" }}>{this.props.label_keys.applyFilter}</span>}>
        <div tabindex={-1} style={{ paddingBottom: 20 }}>
          <p className="message-zoom" id="slider_title" style={{ textAlign: "left" }}>{this.props.label_keys.provisionedCreditLimitRange}</p>
          <Slider
            range className="message-zoom"
            tooltipVisible={this.state.modalFilterVisible ? true : false}
            tooltipPlacement="bottom"
            min={this.state.minValue}
            max={this.state.maxValue}
            value={[this.state.left, this.state.right]}
            onChange={e => this.setState({ left: e[0], right: e[1] })}
          />

        </div>
      </Modal>
      </>
    );

    const activeCardsDatarowSelection = {
      onSelectAll: (selected, selectedRows) => {
        // console.log("Seleceted Rows:::", selectedRows);
        this.setState({ selectedRows });
        if(selectedRows && !(selectedRows.length > 0)){
          this.setState({editableMode: false, updatedActiveData: [], disableSaveChanges: true});
            this.edit([]);
        }
      },
      //   onSelectAll: (selected) => {
      //     if (selected) {

      //         this.selecteAllUpdate()

      //     } else {
      //         this.unSelectAllUpdate()
      //     }
      // },
      selectedRowKeys,
      onSelect: (record, selected, selectedRows, nativeEvent) => {
        // console.log("Seleceted Rows onSelect:::", selectedRows);
        this.setState({ selectedRows });
        if (document.getElementById(record.cardRef)) {
          document.getElementById(record.cardRef).setAttribute('aria-checked', selected);
        }

      },
      onChange: this.onSelectChange,
      getCheckboxProps: record => ({ // Column configuration not to be checked        
        'aria-checked': false,
        name: !record.name ? "text" : record.name,
        'aria-label': record.name,
        role: "checkbox",
        id: record.cardRef,
        onKeyDown: checkBoxStatusForCards('#cards-tab-panel')
      }),
    };

    // saveCreditLimit = () =>{
    //   console.log("creditLimitData", creditLimitData)
    // }

    const columns = [
      {
        title: prepareTitle('0', <span role="button" >{this.props.label_keys.name}</span>),
        dataIndex: 'name',
        key: 'name',
        sorter: (a, b) => {
          let name = a.name.toLowerCase();
          let name2 = b.name.toLowerCase();
          return name < name2 ? -1 : 1
        },
        sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order,
        render: text => <div
          tabIndex={0}
          onKeyDown={e => {
            if (e.keyCode === 13) { this.cardDetails() }
          }}
          role="button"
          onClick={() => {
            this.cardDetails()
          }}><a style={{ color: '#19398A' }}><u>{text}</u></a></div>
      },
      {
        title: prepareTitle('0', <span role="button" >{this.props.label_keys.cardNumber}</span>),
        dataIndex: 'cardNumber',
        key: 'cardNumber',
        align: 'left',
        width: '11%',
        sorter: (a, b) => {
          let cdN1 = a.cardNumber;
          let cdN2 = b.cardNumber;
          return cdN1 < cdN2 ? -1 : 1
        },
        sortOrder: sortedInfo.columnKey === 'cardNumber' && sortedInfo.order,
        render: text => !text || text === " " ? <div>-</div> :
          <div><span className="visually-hidden">card number ending with {text.substring(15,19)}</span><span aria-hidden='true'>{text}</span></div>
      },
      {
        title: prepareTitle('0', <span role="button" >{this.props.label_keys.employeeId}</span>),
        dataIndex: 'employeeId',
        key: 'employeeId',
        sorter: (a, b) => {
          let eid = a.employeeId.toLowerCase();
          let eid1 = b.employeeId.toLowerCase();
          return eid < eid1 ? -1 : 1
        },
        sortOrder: sortedInfo.columnKey === 'employeeId' && sortedInfo.order,
      },
      {
        title: prepareTitle('0', <span role="button" >{this.props.label_keys.provisionedCredit}</span>),
        dataIndex: 'creditLimit',
        key: 'creditLimit',
        editable: 'true',
        width: '10%',
        align: 'center',
        sorter: (a, b) => a.creditLimit - b.creditLimit,
        sortOrder: sortedInfo.columnKey === 'creditLimit' && sortedInfo.order,
        render: text => <div>${text}</div>
      },
      {
        title: prepareTitle('0', <span role="button" >{this.props.label_keys.validFrom}</span>),
        dataIndex: 'validFrom',
        key: 'validFrom',
        editable: 'true',
        align: 'center',
        sorter: (a, b) => {
          return moment(a.validFrom || 0).unix() - moment(b.validFrom || 0).unix()
        },
        sortOrder: sortedInfo.columnKey === 'validFrom' && sortedInfo.order,
      },
      {
        title: prepareTitle('0', <span role="button" >{this.props.label_keys.validTill}</span>),
        dataIndex: 'validTo',
        key: 'validTo',
        editable: 'true',
        align: 'center',
        sorter: (a, b) => {
          return Number(new Date(a.validTo)) - Number(new Date(b.validTo));
        },
        sortOrder: sortedInfo.columnKey === 'validTo' && sortedInfo.order,
      },
      {
        title: prepareTitle('0', <span role="button" >{this.props.label_keys.field1}</span>),
        dataIndex: 'field1',
        key: 'field1',
        sorter: (a, b) => a.field1.length - b.field1.length,
        sortOrder: sortedInfo.columnKey === 'field' && sortedInfo.order,
      },
      {
        title: prepareTitle('0', <span role="button" >{this.props.label_keys.dateCreated}</span>),
        dataIndex: 'cardCreatedDate',
        key: 'dateCreated',
        sorter: (a, b) => {
          return Number(new Date(a.cardCreatedDate)) - Number(new Date(b.cardCreatedDate));
        },
        sortOrder: sortedInfo.columnKey === 'dateCreated' && sortedInfo.order,
      },
      {
        title: prepareTitle('0', <span role="button" >{this.props.label_keys.approvedBy}</span>),
        dataIndex: 'approvername',
        key: 'approvedBy',
        sorter: (a, b) => {
          let cdN1 = a.approvername.toLowerCase();
          let cdN2 = b.approvername.toLowerCase();
          return cdN1 < cdN2 ? 1 : -1
        },
        sortOrder: sortedInfo.columnKey === 'approvedBy' && sortedInfo.order,
        render: text => !text || text === " " ? <i style={{ color: "#666666" }}> Not applicable </i> :
          <div
            tabIndex={0}
            role="button"
            onKeyDown={e => {
              if (e.keyCode === 13) {
                this.showProfileDetails("Approver")
              }
            }}
            onClick={() => {
              this.showProfileDetails("Approver")
            }}><a style={{ color: '#19398A' }}><u>{text}</u></a></div>
      },
      {
        title: prepareTitle('0', <span role="button" >{this.props.label_keys.emailID}</span>),
        dataIndex: 'emailId',
        key: 'emailId',
        width: '13%',
        sorter: (a, b) => a.emailId.length - b.emailId.length,
        sortOrder: sortedInfo.columnKey === 'emailId' && sortedInfo.order,
      },
    ];

    const components = {
      body: {
        row: EditableFormRowActiveCards,
        cell: EditableCellActiveCards
      }
    };

    const _columnArray = columns.map(col => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          cardRef: record.cardRef,
          editing: this.isEditing(record),
          handleSave: this.handleSave,
          // saveCreditLimit: this.saveCreditLimit,
          label_keys: this.props.label_keys,
          updatedActiveData: this.state.updatedActiveData,
        })
      };
    });

    const tabListTitle = [
      {
        key: 'CardsCreated',
        tab: <div tabIndex={"-1"} role="heading" aria-level="2">{this.props.label_keys.cardsCreated + ' (' + this.state.data.length + ')'}  </div>
      }]

    return (
      <div role="main">
        <div aria-relevant="text"
          aria-live={sortedInfo.order === "ascend" || sortedInfo.order === "descend" ? "polite" : "off"}
          aria-atomic="true" className="visually-hidden">{
            sortedInfo.order === "ascend" || sortedInfo.order === "descend" ?
              <div><span>Table sorted by {sortedInfo.columnKey}</span>
                <span> in {sortedInfo.order}ing order</span></div> : null}   </div>

        <div id='itemtest' ref={'itemtest'}  >
          {this.state.isLoading ? <div className="dark-spinner loader-color">
            <Spin tip={"Loading..."} /></div> :
            <Card className='container ant-card-pv tab-link' >

              <Row className={!this.state.editableMode ? 'myActive allActiveCards-extra zoom-400' : 'myApproval-extra myActiveCards-extra editableMode'}>
                {!this.state.editableMode ?
                  <Col >{operations}</Col>
                  :
                  // <Col offset={10} span={12} style={{ marginLeft: this.props.selectedLang === "French" ? '15%' : (this.props.theme === 'elan' && !this.props.ReqFlag ? '57%' : '27%') }}>
                  //   <Col span={11} >
                  //     <Button className="cancel-btn-ant bulkUpload-cancl" onClick={() => this.handleCancelEditMode()} style={{ marginLeft: this.props.selectedLang === "French" ? '-35%' : '-25%' }}>{this.props.label_keys.cancel}</Button>
                  //   </Col>
                  //   <Col span={11} offset={2}>
                  //     <button className="card-detail-save myAct_card-detail-save ant-btn ant-btn-primary" onClick={e => this.onSaveCardDetails()} >{this.props.label_keys.saveChanges}</button>
                  //   </Col>
                  // </Col>
                  <Col span={12} className="edit-enableDiv edit-enableDiv-zoom" style={{ marginLeft: this.props.theme === 'elan' ? '-8%' : this.props.selectedLang === 'French' ? '-15%' : null }}>
                    <button className="card-detail-cancel card-detail-cancel-zoom ant-btn" id='cancel-btn' onClick={e => {
                      this.handleCancelEditMode();
                      this.setState({
                        // error: null,
                        // editEnable: false,
                        // validFromEdit: false,
                        // validToEdit: false,
                        // creditLimitEdit: false,
                        // creditLimit: '$' + this.props.selectedRow.creditLimit,
                        // validFrom: dateFormat(this.props.selectedRow.validFrom, 'mm/dd/yyyy'),
                        // validTo: this.props.selectedRow.validTo ? dateFormat(this.props.selectedRow.validTo, 'mm/dd/yyyy') : dateFormat(this.props.selectedRow.validTill, 'mm/dd/yyyy'),
                      }, () => document.getElementById('edit-btn').focus())
                    }}>{this.props.label_keys.cancel}</button>
                    <button className={this.props.selectedLang === 'French' ? "card-detail-save.french ant-btn ant-btn-primary":"card-detail-save-zoom card-detail-save ant-btn ant-btn-primary"} style={{ background: '#19398A' }}
                      // disabled={(this.state.creditLimit !== "$" + this.props.selectedRow.creditLimit || (this.state.validFrom !== null && (dateFormat(this.state.validFrom, 'mm/dd/yyyy') !== dateFormat(this.props.selectedRow.validFrom, 'mm/dd/yyyy'))) || (this.state.validTo !== null && (dateFormat(this.state.validTo, 'mm/dd/yyyy') !== (this.state.selectedRow.validTo ? dateFormat(this.state.selectedRow.validTo, 'mm/dd/yyyy') : dateFormat(this.state.selectedRow.validTill, 'mm/dd/yyyy')))))
                      //   ? false : true}
                      disabled={this.state.disableSaveChanges}
                      onClick={e => this.onSaveCardDetails()}>{this.props.label_keys.saveChanges}</button>
                  </Col>
                }
              </Row>
              <Row className="activeCards-extra allActive-title activeCrd-400zoom"
                style={{marginTop: '-40px', marginBottom: '5px', top: '9px',
                  paddingTop: '13px', paddingLeft: '20px', float: 'left', width: '100%', textTransform: 'none',
                }}
              >
                <Col span={12}>
                  <div id="cards-tab" className="cards-tab">
                    <div role="heading" aria-level="2">{this.props.label_keys.cardsCreated + ' (' + this.state.data.length + ')'}  </div>
                    <div style={{ color: '#666666', fontSize: 13 }} aria-live="polite" aria-atomic='true'><span style={{ textTransform: 'capitalize' }}>{this.props.label_keys.selected}</span> {this.state.selectedRowKeys.length} {this.props.label_keys.of} {this.state.data.length}</div>
                  </div>
                </Col>

              </Row>

              {/* <Tabs className="activeCards-extra" value='1'
                  style={{
                      borderBottom: '1px solid #e8e8e8', marginTop: '-30px', marginBottom: '5px',
                      paddingTop: '10px', float: 'left', width: '100%', textTransform: 'none',
                  }}
                  size="small" >
                  <Tab disableRipple tabIndex={-1} id="cards-tab" aria-controls='cards-tab-panel'
                      className="cards-tab" value='1' 
                      label={<div role="heading" aria-level="2">{this.props.label_keys.cardsCreated + ' (' + this.state.data.length + ')'}  </div>}
                  />
                            
              </Tabs> */}
              <div id='cards-tab-panel' value='1' style={{ marginTop: '4%' }}>
                {/* <Row>
                  <Col> */}
                <Table className='Req-table userlist'
                  rowKey={obj => obj.cardRef}
                  columns={_columnArray}
                  components={components}
                  rowClassName={() => "editable-row"}
                  rowSelection={activeCardsDatarowSelection}
                  dataSource={this.state.data}
                  //loading={false}
                  locale={{ emptyText: this.props.label_keys.dataUnavailable }}
                  scroll={{ x: 2000 }}
                  onChange={this.handleChange}
                  pagination={{
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    current: this.state.current,
                    itemRender: this.itemRender,
                    pageSizeOptions: ["10", "20", "50", "100"],
                    position: "bottom",
                    showTitle: false,
                    showTotal: (total, range) => <Row >
                      <Col className="total-text-pagination" xs={{ span: 12, offset: 24 }} sm={{ span: 12, offset: 24 }} md={{ span: 12, offset: 24 }} lg={12} xl={12} xxl={12}>{this.props.label_keys.showing} {range[0]}-{range[1]} {this.props.label_keys.of} {total} </Col>
                      <Col className="page-count-span myAct_page-count-span"  xs={{ span: 8 }} sm={{ span: 6 }} md={{ span: 6 }} lg={6} xl={6} xxl={6} >{this.props.label_keys.page} {this.state.current} {this.props.label_keys.of} {this.state.TotalPage}</Col></Row>,
                    onChange: this.pageChanger,
                    onShowSizeChange: this.OnPageSizeChange,
                    total:this.state.data ? this.state.data .length : 0
                  }}
                  onRow={(record, rowIndex) => {
                    return {
                      onClick: event => {
                        this.setState({ selectedRow: record })
                      },
                    }
                  }}
                />
                {/* </Col>
                </Row> */}
              </div>

            </Card>
          }
          
          {this.state.detailsVisible === true && <CradDetailComponent
            updateDetails={this.updateDetails}
            cardDetails={this.state.data}
            detailsVisible={this.state.detailsVisible}
            selectedRow={this.state.selectedRow}
            mobileNumber={this.state.data.mobileNumber}
            selectedLang={this.props.selectedLang}
            okClicked={this.state.okClicked}
            onCLoseDetails={reloadFlag => {
              // console.log("Active element ::: ", this.state.prevActiveElement);
              if (this.state.detailsVisible) {
                if (document.body.contains(this.state.prevActiveElement)) {
                  // setTimeout(() => {
                  this.state.prevActiveElement.focus();
                  // this.setState({
                  //   prevActiveElement: null
                  // })
                  // }, 100);
                }
              }
              this.setState({
                detailsVisible: false,
                prevActiveElement: null,
              })
              const divPagination = document.querySelectorAll(".ant-pagination-item");
              if(divPagination[0]){
                  divPagination.forEach(div => {
                      div.setAttribute('tabindex', "0");
                  })
              }
      
              const divPaginationPrev = document.querySelectorAll(".ant-pagination-prev");
              if(divPaginationPrev[0]){
                  divPaginationPrev.forEach(div => {
                      div.setAttribute('tabindex', "0");
                  })
              }
      
              const divPaginationNext = document.querySelectorAll(".ant-pagination-next");
              if(divPaginationNext[0]){
                  divPaginationNext.forEach(div => {
                      div.setAttribute('tabindex', "0");
                  })
              }
              const divPaginationPrevDis = document.querySelectorAll(".ant-pagination-disabled");
              if(divPaginationPrevDis[0]){
                divPaginationPrevDis.forEach(div => {
                      div.setAttribute('tabindex', "-1");
                  })
              }
      
            }
          }
            updateSuccess={() => { this.setState({ detailUpdateSuccess: true, okClicked: false }) }}

            label_keys={this.props.label_keys} />}


          {this.state.detailUpdateSuccess &&
            <Modal className="custom-modal custom-modal-bulk-card" role="dialog"
              visible={this.state.detailUpdateSuccess}
              closable={false}
              aria-labelledby="title"
              title={<b className="visually-hidden" id="title">Changes Update Success</b>}
              footer={[<Button ref={this.modalBtnRef} type="primary" style={{ backgroundColor: '#19398A', fontWeight: 400, borderColor: '#19398A' }} onKeyDown={(e) => {
                if(e.key === 'Escape'){
                  this.setState({ detailUpdateSuccess: false, okClicked: true })
                }
              }} onClick={() => {
                this.setState({ detailUpdateSuccess: false, okClicked: true });
                // this.props.onCLoseDetails(true)
              }}>Ok</Button>]}
            >
              <div className="custom-modal-content">
                <img src={Tick} alt='success' /><br />
                <label>{this.props.label_keys.changesUpdatedSuccessfully}<br/>{this.props.label_keys.creditLimitWillUpdate}</label>
                {/* <span className="visually-hidden">Cards successfully deactivated.</span> */}
              </div>
            </Modal>
          }

          {this.state.exportAllCardsModalVisible ?
            <Modal className="custom-modal custom-modal-bulk-card" role="dialog"
              visible={this.state.exportAllCardsModalVisible}
              closable={false}
              aria-labelledby="title" style={{ marginTop: "12%" }}
              title={<b className="visually-hidden" id="title">Export Cards</b>}
              footer={[
                <div key='export-cards'>
                  <button className="cancel-custom-btn ant-btn " style={{ color: "#19398a", borderColor: "#19398a", fontSize: 14, fontWeight: 400 }}
                    // ref={this.okBtnRef}
                    onKeyDown={e => {
                      if (e.key === 'Escape') {
                        // this.handleVisibleChange()
                        // console.log('document.body.contains(:::', document.body.contains(this.state.previousActiveElement))
                        if (document.body.contains(this.state.prevActiveElement)) {
                          this.state.prevActiveElement.focus()
                        }
                        this.setState({ exportAllCardsModalVisible: false });
                        // this.state.selectedRowKeys && this.setState({selectedRowKeys : [], selectedRow: []});

                      }
                    }}
                    onClick={() => {
                      if (document.body.contains(this.state.prevActiveElement)) {
                        this.state.prevActiveElement.focus()
                      }
                      this.setState({ exportAllCardsModalVisible: false })
                      // this.state.selectedRowKeys && this.setState({selectedRowKeys : [], selectedRow: []})
                    }} >{this.props.label_keys.cancel}</button>
                  <button className="ok-custom-btn ant-btn ant-btn-primary " style={{ marginLeft: "10%", backgroundColor: "#19398a", borderColor: "#19398a", fontSize: 14, fontWeight: 400 }}
                    onKeyDown={e => {
                      if (e.key === 'Escape') {
                        // this.handleVisibleChange()
                        if (document.body.contains(this.state.prevActiveElement)) {
                          this.state.prevActiveElement.focus()
                        }
                        this.setState({ exportAllCardsModalVisible: false })
                      }
                    }}
                    onClick={() => this.handleExportCards()}>{this.props.label_keys.confirm}</button>
                </div>
              ]}
            >
              <div className="custom-modal-content">
                {/* <p>{this.props.label_keys.createdsentForApproval}</p> */}
                <p>{this.props.label_keys.exporting} {this.state.selectedRowKeys.length > 0 ? this.state.selectedRowKeys.length : this.state.currentDataSource.length} {this.props.label_keys.of} {this.state.currentDataSource.length} {this.props.label_keys.cards_insmall}</p>
              </div>
            </Modal>
            : null
          }
          {this.state.profileDetailsVisible === true &&
            <ProfileDetails
              profileDetailsVisible={this.state.profileDetailsVisible}
              profileType={this.state.profileType}
              selectedRow={this.state.selectedRow}
              selectedLang={this.props.selectedLang}
              label_keys={this.props.label_keys}
              onCloseProfileDetail={() => {
                if (this.state.profileDetailsVisible) {
                  if (document.body.contains(this.state.prevActiveElement)) {
                    this.state.prevActiveElement.focus();
                  }
                }
                this.setState({ profileDetailsVisible: false, prevActiveElement: null })
              }}
            />
          }
        </div>
      </div>
    )
  }
}


function mapStateToProps(state, props) {
  return {
    userDetails: state.authenticationReducer.userDetails,
    token: state.authenticationReducer.token,
    iclanguageList: state.instantCardMultiLanReducer.iclanguageList,
    theme: state.authenticationReducer.theme
  }
}

const mapDispatchToProps = dispatch => {
  return {
    // validateBulkDataFun: (bulkobj, id, updatestate, label_keys, noMessage) => {
    //   dispatch(validateBulkData(bulkobj, id, updatestate, label_keys, noMessage));
    // },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ActiveCards)

