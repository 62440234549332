import React, { useState } from 'react';
import { connect } from 'react-redux'
import{ MenuItem, Menu, Button} from '@mui/material';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';

// Accessbility To invoke click method on tab enter.
function onKeyDown(event) {
    if (event.keyCode === 13) {
        event.preventDefault()
        document.activeElement.click();
    }

}


export default function GraphIntervalSelect(props){

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    return(
        <>
        <div style={{fontStyle:'U.S.BankCircularWeb-Regular'}}>
            <Button
                className={props.className}
                id={`${props.className}-button`}
                disableRipple
                disableElevation
                style={{ backgroundColor: "transparent",  textTransform: 'none', color: '#0C2074',
                    textAlign: "left", fontSize: '14px', opacity: 1,
                    fontFamily: "U.S.BankCircularWeb-Regular"
                }}
                aria-label={props.ariaLabel + ' ' + props.value}
                aria-controls={open ? `${props.className}-menu` : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant="contained"
                disableElevation
                onClick={handleClick}
                endIcon={<KeyboardArrowDownOutlinedIcon />}
                >
                    <span >{props.value}</span>
                </Button>
                <Menu
                    id={`${props.className}-menu`}
                    anchorEl={anchorEl}
                    open={open}
                    onClick={handleClose}
                    onClose={handleClose}
                    onKeyDown={(event) => {
                        if (event.keyCode === 13) 
                            handleClose()
                        }
                    }
                    MenuListProps={{
                    'aria-labelledby': `${props.className}-button`,
                    }}
                    // style={{ widt }}
                >
                    <MenuItem onClick={props.onChange} className="graph-menuItem" value="weekly"><div className="cardContent1-dropDown">{props.week}</div></MenuItem>
                    <MenuItem onClick={props.onChange} tabIndex="0" className="graph-menuItem" value="monthly"><div className="cardContent1-dropDown">{props.month}</div></MenuItem>  
           
                </Menu>
                </div>
        </>
    )
}
