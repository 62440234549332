/* eslint-disable no-unused-expressions */
import React from 'react';
import { connect } from 'react-redux';
import $ from 'jquery';
import '../../styling/receiptrules.css';
import { Radio, Divider, Spin, Table, Input, notification, Modal, Select } from 'antd';
import { loaderFocus } from '../../utils/accessability';
import axios from 'axios';
const RadioGroup = Radio.Group;
const confirm = Modal.confirm;
const Option = Select.Option;
const { URLs, errorMessages } = require('../../data/constants.js');
var expcatFirstValue;
var expcatFirstDes;
const EditableCell = ({ editable, value, newflag, column, onChange }) => (
    <div>
        {newflag ? <input type="text" onFocus={(e) => {
            e.target.value = "";
        }} defaultValue='0' style={{ color: '#636466' }} className="ruleconditions input-text" /* ref={this.ruleConditions} */ onChange={e => onChange(e.target.value)} /> : editable
                ? <Input
                    className="ruleconditions"
                    style={{ margin: '-5px 0', marginTop: "2px", width: '100%', border: "1px solid lightgrey", borderRadius: "5px", backgroundColor: "transparent" }}
                    value={value}
                    onChange={e => onChange(e.target.value)}
                />
                : <span className="input-text" >{value}</span>
        }
    </div>
);
const EditableCellExpCat = ({ editable, value, newflag, column, onChange, expenseCat, compId }) => (
    <div className="receiptruleclass" >
        {expenseCat.forEach(item => {
             if (item.companyId === parseInt(compId) && value === item.cadreCategory) {
                expcatFirstValue = item.expCategoryId;
                expcatFirstDes = value;
            }
        })}
        {newflag || editable ?
            <Select style={{ width: "80%" }} defaultValue={value} onChange={value => onChange(value)}>
                {expenseCat.map(item =>
                (item.companyId === parseInt(compId)) ?
                    <Option value={item.expCategoryId}>{item.cadreCategory}</Option> : null)}
            </Select> : <span className="input-text" style={{ color: "black" }}>{value}</span>
        }
    </div>
);
const EditableCellType = ({ editable, value, newflag, column, onChange, transactionType }) => (
    <div className="TypeSelect receiptruleclass">
        {newflag || editable ?
            <Select style={{ width: "100%" }} defaultValue={value} onChange={value => onChange(value)}>
                {transactionType.map(item =>
                    <Option value={item.transactionType}>{item.transactionType}</Option>)}
            </Select> : <span className="input-text">{value}</span>
        }
    </div>
);
const EditableReceiptReq = ({ editable, value, newflag, column, onChange }) => (
    <div >
        {newflag || editable ?
            <div onChange={e => onChange(e.target.value)} className="radibuttonTop">
                <RadioGroup name="radiogroup" defaultValue={value}>
                    <Radio value={'Yes'}>Yes</Radio>
                    <Radio value={'No'}>NO</Radio>
                </RadioGroup>
            </div> : <span className="input-text">{value}</span>
        } 
    </div>
);
class ReceiptRulesComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            receiptRuleList: [],
            companyName: [],
            retrieve: false,
            selectedCompanyId: null,
            visible: false,
        }
        this.cacheData = this.state.receiptRuleList.map(item => ({ ...item }));
    }

    /**
     * Life cycle method called upon mounting of the component
     */
    componentDidMount() {
        /* this.fetchReceiptRules(); */
        this.setState({ companyName: this.props.activeCompanyName, visible: true });
        if (!this.props.superAdmin && this.props.activeCompanyName.length !== 0) {
            this.fetchReceiptRules(this.props.activeCompanyName.companyId);
            this.setState({ selectedCompanyId: this.props.activeCompanyName.companyId })
        }
        if (this.props.superAdmin) {
            this.setState({ visible: false })
        }
        // prepare the icons
        let element = $('.ant-select-arrow-icon')
        element.empty()
        element.removeClass().addClass('icon-Drop-down')
    }

    /**
     * Sets the slected company value, calls fetchReceiptRules method based on selected company Id
     */
    companyChange = (value) => {
        this.setState({ selectedCompanyId: value, visible: true })
        this.fetchReceiptRules(value);
    }
    /**
    * fetching all the ReceiptRules from the Database
    */
    fetchReceiptRules = (value) => {
        this.setState({ retrieve: true });
        loaderFocus(".loader-color .ant-spin-text");  
        axios.get(URLs.tripUrl + "fetchReceiptRules/" + value, {
            responseType: 'json',
            headers: {
                "Authorization": 'Bearer ' + this.props.token.access_token
            }
        }).then(response => {
            if (response.data.serviceStatus.statusCode === 200) {
                var newObj = [];
                var count = 1;
                response.data.data.forEach((item) => {
                    item.key = count;
                    item.saveColor = "#B4B4B4";
                    item.editColor = "#0A41C5";
                    item.disableColor = "#0A41C5";
                    count++;
                    newObj.push(item);
                })
                this.setState({ receiptRuleList: newObj, retrieve: false });
            }
            else if (response.data.serviceStatus.statusCode === 210) {
                this.setState({ retrieve: false });
                /*  message.error(response.data.serviceStatus.message) */
                notification.error({
                    message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Error </span>{response.data.serviceStatus.message}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }
        }).catch((error) => {
            /*  message.error(err.message) */
            this.setState({ retrieve: false });
            if (error.response !== undefined && error.response.data !== null && error.response.data !== undefined && error.response.data.serviceStatus !== undefined) {
                notification.error({
                    message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Error </span>{error.response.data.serviceStatus.message}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }
            else {
                notification.error({
                    message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Error </span>{errorMessages.generic_error}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }
        })
    }

    /**
     * Add new reciept rule
     */
    addNewReceiptRule = () => {
        if(this.props.expenseCat && this.props.expenseCat.length > 0) {
            const { receiptRuleList } = this.state;
            const newData = [{
                key: this.state.receiptRuleList.length + 1,
                ruleId: "new",
                expenseCategoryDescription: expcatFirstDes ? expcatFirstDes : this.props.expenseCat[0].cadreCategory,
                expenseCategoryId: expcatFirstValue ? expcatFirstValue : this.props.expenseCat[0].expCategoryId,
                transactionTypeDescription: this.props.transactionType[0].transactionType,
                ruleCondition: "0",
                receiptRequired: "Yes",
                saveColor: "#0A41C5",
                editColor: "#B4B4B4",
                disableColor: "#0A41C5",
            }];
            this.setState({ receiptRuleList: newData.concat(receiptRuleList)  });
        } else notification.warning({
            message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Error </span>No Expense Category defined yet. Contact Super Admin</span>,
            duration: 3,
            style: {
                backgroundColor: '#fffbe6',
                border: "1px solid #ffe58f",
            }
        });
    }

    highlightInvalidField = (element, key) => {
        var class1 = ".ruleConditionclass" + key;
        let label = $(element);
        let label1 = $(element).closest('.ant-table-row').find(class1)
        label1.addClass('has-error')
        label.focus()
    }

    clearInvalidField(element, key) {
        var class1 = ".ruleConditionclass" + key;
        let label1 = $(element).closest('.ant-table-row').find(class1)
        label1.removeClass('has-error')
    }

    /**
     * create a new reciept rule by calling axios post request and updating the new reciept rule in the dB
     */
    createReceiptRule = (record) => {
         this.setState({ retrieve: true });
         loaderFocus(".loader-color .ant-spin-text");  
        if (record.ruleId === "new") {
            record.ruleId = null;
        }
        var transactionTypeId = null;
        this.props.transactionType.forEach((object) => {
            if (record.transactionTypeDescription.toLowerCase() === object.transactionType.toLowerCase()) {
                transactionTypeId = object.transactionTypeId;
            }
        })
        let rule = {
            "ruleId": parseInt(record.ruleId),
            "ruleCondition": record.ruleCondition ? record.ruleCondition : "0",
            "receiptRequired": record.receiptRequired,
            "transactionType": transactionTypeId,
            "expenseCategoryId": parseInt(record.expenseCategoryId),
            "companyId": this.state.selectedCompanyId,
        };
        axios.post(URLs.tripUrl + "createReceiptRules", rule, {
            headers: {
                "Content-Type": 'application/json',
                "Authorization": 'Bearer ' + this.props.token.access_token
            }
        }).then(response => {
            if (response.data.serviceStatus.statusCode === 200) {
                this.fetchReceiptRules(this.state.selectedCompanyId);
                /*  message.success(response.data.serviceStatus.message); */
                notification.success({
                    message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Success </span>{response.data.serviceStatus.message}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#dff0d8',
                        color: "green",
                        border: "1px solid green",
                    }
                });
            } else if (response.data.serviceStatus.statusCode === 409) {
                this.fetchReceiptRules(this.state.selectedCompanyId);
                notification.warning({
                    message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Error </span>{response.data.serviceStatus.message}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fffbe6',
                        border: "1px solid #ffe58f",
                    }
                });
            } else {
                this.fetchReceiptRules(this.state.selectedCompanyId);
                /*   message.error("{errorMessages.error_addingNewRule}"); */
                notification.error({
                    message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Error </span>{errorMessages.error_addingNewRule}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }
        })
            .catch(error => {
                this.fetchReceiptRules(this.state.selectedCompanyId);
                /* message.error("{errorMessages.error_addingNewRule}"); */
                if (error.response !== undefined && error.response.data !== null && error.response.data !== undefined && error.response.data.serviceStatus !== undefined) {
                    notification.error({
                        message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Error </span>{error.response.data.serviceStatus.message}</span>,
                        duration: 3,
                        style: {
                            backgroundColor: '#fff1f0',
                            border: "1px solid #ffa39e",
                        }
                    });
                } else {
                    notification.error({
                        message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Error </span>{errorMessages.generic_error}</span>,
                        duration: 3,
                        style: {
                            backgroundColor: '#fff1f0',
                            border: "1px solid #ffa39e",
                        }
                    });
                }
            })
    }
    /**
  * @param  {} record
  * @param  {} e
  * deletes the receipt rule from the database
  */
    deleteRule = (record, e) => {
        var current = this;

        if (record.ruleId === 'new') {
            const dataSource = [...this.state.receiptRuleList];
            this.setState({ receiptRuleList: dataSource.filter(item => item.key !== record.key) });
        } else {
           /*  let obj = {
                "ruleId": record.ruleId
            } */
            confirm({
                title: 'Do you want to delete selected row?',
                onOk() {
                    current.setState({ retrieve: true });
                    loaderFocus(".loader-color .ant-spin-text");  
                    axios.delete(URLs.tripUrl + "deleteReceiptRule/" + record.ruleId, {
                        responseType: 'json',
                        headers: {
                            "Authorization": 'Bearer ' + current.props.token.access_token
                        }
                    }).then(response => {
                        if (response.data.serviceStatus.statusCode === 200) {
                            current.fetchReceiptRules(current.state.selectedCompanyId);
                            /* message.success(response.data.serviceStatus.message) */
                            notification.success({
                                message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Success </span>{response.data.serviceStatus.message}</span>,
                                duration: 3,
                                style: {
                                    backgroundColor: '#dff0d8',
                                    color: "green",
                                    border: "1px solid green",
                                }
                            });
                        } else if (response.data.serviceStatus.statusCode === 210) {
                            current.setState({ retrieve: false });
                            /* message.error(response.data.serviceStatus.message) */
                            notification.error({
                                message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Error </span>{response.data.serviceStatus.message}</span>,
                                duration: 3,
                                style: {
                                    backgroundColor: '#fff1f0',
                                    border: "1px solid #ffa39e",
                                }
                            });
                        }
                    }).catch((error) => {
                        current.setState({ retrieve: false });
                        if (error.response !== undefined && error.response.data !== null && error.response.data !== undefined && error.response.data.serviceStatus !== undefined) {
                            notification.error({
                                message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Error </span>{error.response.data.serviceStatus.message}</span>,
                                duration: 3,
                                style: {
                                    backgroundColor: '#fff1f0',
                                    border: "1px solid #ffa39e",
                                }
                            });
                        } else {
                            notification.error({
                                message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Error </span>{errorMessages.generic_error}</span>,
                                duration: 3,
                                style: {
                                    backgroundColor: '#fff1f0',
                                    border: "1px solid #ffa39e",
                                }
                            });
                        }
                    })
                },
                onCancel() {},
            });

        }
    }

    /**
     * renders expense category dropdown
     * @param {*} text 
     * @param {*} record 
     * @param {*} column 
     */
    renderDropdownExpCat(text, record, column) {
        return (
            <EditableCellExpCat
                editable={record.editable}
                value={record.expenseCategoryDescription}
                newflag={record.ruleId === "new" ? true : false}
                column={column}
                onChange={value => this.handleChange(value, record.key, column)}
                expenseCat={this.props.expenseCat}
                compId={this.state.selectedCompanyId}
            />
        );
    }

    /**
     * render transaction type dropdown
     */
    renderDropdowntransactionType(text, record, column) {
        return (
            <EditableCellType
                editable={record.editable}
                value={text}
                newflag={record.ruleId === "new" ? true : false}
                column={column}
                onChange={value => this.handleChange(value, record.key, column)}
                transactionType={this.props.transactionType}
            />
        );
    }
    renderReceiptReq(text, record, column) {
        return (
            <EditableReceiptReq
                editable={record.editable}
                value={record.receiptRequired}
                newflag={record.ruleId === "new" ? true : false}
                column={column}
                onChange={value => this.handleChange(value, record.key, column)}
            />
        );
    }
    renderColumns(text, record, column) {
        return (
            <EditableCell
                editable={record.editable}
                value={text}
                newflag={record.ruleId === "new" ? true : false}
                column={column}
                onChange={value => this.handleChange(value, record.key, column)}
            />
        );
    }

    /**
     * Handles the changes made in the receiptRuleList
     */
    handleChange = (value, key, column) => {
        this.clearInvalidField('.ruleconditions', key);
        const newData = [...this.state.receiptRuleList];
        const target = newData.filter(item => key === item.key)[0];
        if (target) {
            if (column === "expenseCategoryId") this.props.expenseCat.map(item =>
                    item.expCategoryId === value ? 
                        target["expenseCategoryDescription"] = item.expCategoryDesc : null)
            target[column] = value;
            this.setState({ receiptRuleList: newData });
        }
    }

    render() {
        const columns = [{
            dataIndex: 'receiptRequired',
            width: 10,
            render: (text, record) => (
                <div>
                    {record.receiptRequired.toUpperCase() === 'YES' ? 
                        < Divider type="verticle" className="dividerclass" /> : null}
                </div>
            ),
        }, {
            dataIndex: 'expenseCategoryId',
            width: 80,
            render: (text, record) =>
                <div style={{ marginLeft: "0%" }} >
                    {this.renderDropdownExpCat(text, record, 'expenseCategoryId')}
                </div>
        }, {
            dataIndex: 'transactionTypeDescription',
            width: 90,
            render: (text, record) =>
                <div style={{ marginLeft: "0%" }} >
                    <span >{this.props.app_labels.askForReceipt}</span><br />
                    {this.renderDropdowntransactionType(text, record, 'transactionTypeDescription')}
                </div>

        }, {
            dataIndex: 'ruleCondition',
            width: 90,
            render: (text, record) =>
                <div style={{ marginLeft: "0%" }} className={"ruleConditionclass" + record.key} >
                    <span>{this.props.app_labels.amountCriteria}</span><br />
                    {this.renderColumns(text, record, 'ruleCondition')}
                </div>

        }, {
            dataIndex: 'receiptRequired',
            width: 90,
            render: (text, record) =>
                <div style={{ marginLeft: "18%" }} >
                    <span >{this.props.app_labels.receiptRequired}</span><br />
                    {this.renderReceiptReq(text, record, 'receiptRequired')}
                </div>

        }, {
            dataIndex: '',
            width: 110,
            render: (text, record) =>
                <div>
                    <button className="pseudoButtonIcon" onClick={() => {
                        if (record.editable) {
                            const newData = [...this.state.receiptRuleList];
                            record.editColor = "#0A41C5";
                            record.saveColor = "#B4B4B4";
                            record.disableColor = '#0A41C5';
                            record.editable = false;
                            this.setState({ receiptRuleList: newData });
                        }
                    }}><i className='icon-Cancel' style={{ fontSize: "17px", color: !record.editable ? "#B4B4B4" : '#0A41C5' }} />
                    </button>
                    <button className="pseudoButtonIcon" onClick={(e) => {
                        if (record.ruleCondition === "" || record.ruleCondition === null) {
                            this.highlightInvalidField('.ruleconditions', record.key)
                            notification.warning({
                                message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Error </span>{errorMessages.error_req_ruleCondition}</span>,
                                duration: 3,
                                style: {
                                    backgroundColor: '#fffbe6',
                                    border: "1px solid #ffe58f",
                                }
                            });
                            return;
                        }
                        if (record.saveColor === '#0A41C5') {
                            this.createReceiptRule(record);
                            record.ruleId === "new" ? record.ruleId = null : null
                            const newData = [...this.state.receiptRuleList];
                            delete record.editable;
                            record.editColor = "#0A41C5";
                            record.saveColor = "#B4B4B4";
                            record.disableColor = "#0A41C5";
                            this.setState({ receiptRuleList: newData });
                            this.cacheData = newData.map(item => ({ ...item }));
                        }
                    }
                    } ><i className="icon-Save" style={{ color: record.saveColor, fontSize: "17px" }} /></button>
                    <button className="pseudoButtonIcon" onClick={(e) => {
                        if (record.ruleId !== "new") {
                            if (record.editColor === "#0A41C5") {
                                const newData = [...this.state.receiptRuleList];
                                record.editable = true;
                                record.editColor = "#B4B4B4";
                                record.saveColor = "#0A41C5";
                                record.disableColor = "#B4B4B4";
                                this.setState({ receiptRuleList: newData });
                            }

                        }
                    }}><i className="icon-Edit" style={{ color: record.editColor, fontSize: "17px", marginLeft: "4%" }} /></button>
                    <button className="pseudoButtonIcon" onClick={(e) => {
                        this.clearInvalidField('.ruleconditions', record.key);
                        this.deleteRule(record, e);
                    }
                    }><i className="icon-Delete-or-Disable" style={{ color: record.disableColor, fontSize: "18px", marginLeft: "4%" }} /></button>
                </div >

        }];

        return (


            <div className="receiptRulesclass" >
                <div className="row admin_heading" >
                    <b>{this.props.app_labels.receiptRule}</b><br /><hr className="hrStyleclass" />
                </div>
                <div className="row" style={{ textAlign: "left" }}>
                    <div className="col-sm-3 col-sm-offset-0 margibnLeftExpCat cardsetrule" >
                        {this.props.app_labels.companyName}<br /><br />
                        {this.props.superAdmin ? <Select onChange={this.companyChange} style={{ width: '100%' }} placeholder="Please Select Company">
                            {this.state.companyName.map(item =>
                                <Option value={item.companyId}>{item.companyName}</Option>
                            )}
                        </Select> : <div className="input-text">{this.props.activeCompanyName.companyName}</div>}
                    </div>
                </div> <br />
                {this.state.retrieve === false ?
                    <div>
                        <div className="row">
                            <div style={{ textAlign: "right", marginRight: "1%" }}>
                                {this.state.visible ? <button className="pseudoButton" onClick={this.addNewReceiptRule}><b>{this.props.app_labels.defineNewReceiptRule}</b></button> : null}
                            </div>
                        </div>
                        {this.props.expenseCat && this.props.expenseCat.length > 0 &&
                        <div className="row">
                            <Table style={{ marginLeft: "2%", minWidth: "150px", marginRight: "2%" ,marginBottom:"2%"}}
                                scroll={{ x: 970, y: 300 }}
                                columns={columns}
                                dataSource={this.state.receiptRuleList}
                                pagination={false} />
                        </div>}
                    </div> : <div className="dark-spinner loader-color"><Spin tip="Loading..." /></div>}

            </div >
        )
    }
}
function mapStateToProps(state, props) {
    return {
        token: state.authenticationReducer.token,
    };
}
export default connect(mapStateToProps)(ReceiptRulesComponent);