import React, { Component } from 'react';
//import ReactDOM from 'react-dom';
import $ from 'jquery';
import { Icon, notification, Checkbox, Button, Layout, DatePicker, Spin, Modal, Input } from 'antd';
import { loaderFocus } from '../../utils/accessability';
import FlightFileUpload from './flightfileupload';
import HotelFileUpload from './hotelfileupload';
import 'antd/dist/antd.css';
// import '../../styling/createtrip.css';
import '../../styling/createtripPending.css';
import { connect } from 'react-redux';
import { sendInfomationToTraveller } from '../../actions/sendinfototravellerActions';
import { tripStatus } from '../../actions/changetripstatusAction';
import { reDirectTo } from '../../actions/createtripActions.js';
import { getParticularTrip, fetchingCompletes } from '../../actions/getparticulartripActions.js';
import MediaQuery from 'react-responsive';
import HeaderComponent from '../headercomponent';
import AirportAutosuggest from './AirportAutosuggest'
import 'react-responsive-ui/style.css';
import moment from 'moment';
import axios from 'axios';
import Greenbar from '../../images/greenPb.svg';
import Greybar from '../../images/greyPb.svg';
import Bluebar from '../../images/bluePb.svg';
import { viewCard } from '../../actions/createcardActions';
import qs from 'qs'
import { Counter } from '../ProvisionerScreen/Counter'
const confirm = Modal.confirm;
const { progressBarHoverText_Blue } = require('../../data/constants.js')
var dateFormat = require('dateformat');
const { RangePicker } = DatePicker;
const { URLs, tripStatusConstants, errorMessages } = require('../../data/constants.js');
const { Header, Content, Footer } = Layout;
var phoneNoString = "";
var phoneNoString1 = "";

/*
*rendered when clicked on continue button to view the saved trip details
*/
class CreatetripPending extends Component {
    constructor(props) {
        super(props);
        this.state = {
            travelerBookTickets: this.props.bookedByTraveler,
            deleteFileFlag: false,
            cancelTripStatus: false,
            flightupload: false,
            visible: false,
            hotelupload: false,
            toggleCarddetails: false,
            enable: false,
            origin: this.props.origin,
            origintemp: "",
            destination: this.props.destination,
            destinationtemp: "",
            actualStrtDt: "",
            actualEndDt: "",
            checkboxStatus: false,
            errorMsg: false,
            cardNumberFromService: {},
            progressBarValue: 33.33,
            updatedFromPrevProp: false,
            showOtp: false,
            otp1: '',
            otp2: '',
            otp3: '',
            otp4: '',
            /* XW-61 issue - invalid OTP attempts for unlimited times 
            added two more state variable to store sth and 6th otp digits*/
            otp5: '',
            otp6: '',
            activeResend: false,
            otpCom: false,
            wrongOTP: false,
            show: false,
        }
        this.isChecked = this.isChecked.bind(this);
        this.sendinfoToTraveller = this.sendinfoToTraveller.bind(this)
        // Create refs
        this.overallTrip = React.createRef();
        this.actulaTravelDateRangePicker = React.createRef();
        this.overallTripDestination = React.createRef();
    }

    /**life cycle method**/
    componentWillReceiveProps(nextProps) {
        console.log("NEXT_PROPS ", nextProps);
        let data = {
            origin: nextProps.origin,
            destination: nextProps.destination,
        }
        if (this.props.bookedByTraveler !== nextProps.bookedByTraveler) {
            data["travelerBookTickets"] = nextProps.bookedByTraveler;
            // this.setState({ travelerBookTickets: nextProps.bookedByTraveler })
        }
        // Load new data when the dataSource property changes.
        // this.setState({ origin: nextProps.origin, destination: nextProps.destination, actualStrtDt: nextProps.from, actualEndDt: nextProps.to })
        if (nextProps.actualStartdate !== "") {
            data["actualStrtDt"] = nextProps.actualStartdate;
            // this.setState({ actualStrtDt: nextProps.actualStartdate })
        }
        if (nextProps.actualEnddate !== "") {
            data["actualEndDt"] = nextProps.actualEnddate;
            // this.setState({ actualEndDt: nextProps.actualEnddate })
        }

        this.setState(data, () => {
            this.setState({
                updatedFromPrevProp: true,
            })
        });
    }
    /**Trip status will be updated to cancel**/
    cancelTrip = () => {
        var current = this;
        confirm({
            title:
                current.props.carddetails.length !== 0 ?
                    <div><i role='img' aria-label="Attention" className='icon-attention' style={{ float: 'left', fontSize: '23px', color: '#faad14' }} />
                        <div className="cardHeading">{this.props.app_labels.cardAssignedTrip}</div>
                        <div className="modalheader">{this.props.app_labels.cardInfo}</div>
                        <div className="modalheader" style={{ marginTop: '2%' }}><b>{this.props.app_labels.cancelTripConfirmation}</b></div>
                    </div> : <div className="cardHeading">{this.props.app_labels.confirmCancelTrip}</div>,
            okText: 'Yes',
            cancelText: 'No',
            onOk() {
                current.props.changeTripStatus(current.props.tripId, "Cancelled", null);
                current.setState({ cancelTripStatus: true })
            },
            onCancel() { },
        });
    }

    /**life cycle method (will be called on mount of this component)**/
    componentDidMount() {
        if (!this.props.userDetails.roles.includes('Manager')) {
            window.history.back();
        }
        this.props.getParticularTrip1(this.props.tripId);
        this.props.reDirectto(false);
        this.setState({ travelerBookTickets: false });
    }

    /**life cycle method (will be called on update of this component)**/
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.isFetching === true &&
            this.props.isFetching === false) {

            if (this.props.location.overallTripFocusSource === true && this.props.bookedByTraveler === false) {
                this.overallTrip.current.focus()
            }
            if (this.props.location.overallTripFocusDest === true && this.props.bookedByTraveler === false) {
                this.overallTripDestination.current.focus()
            }
        }
    }

    /**life cycle method**/
    componentWillUnmount() {
        this.props.fetchingComplete(true)
    }

    /**handles the date change
     * @param  {} e
     */
    handeldatechange = (e) => {
        this.clearInvalidField(e.target)
        if (e.length !== 0) {
            this.setState({ actualStrtDt: e[0]._d.toString() });
            this.setState({ actualEndDt: e[1]._d.toString() });
        }
    };

    /**Can not select days before today and today
     * @param  {} current
     */
    disabledDate = (current) => {
        return current && current < moment().startOf('day');
    }

    /**Handles change in destination(updating state variable)
     * @param  {} e
     */
    destinationChange = (e) => {
        this.clearInvalidField(e.target)
        this.setState({ destination: e.target.value });
    }

    /**Change in Origin will be updated on state variables 
     * @param  {} e
     */
    sourceChange = (e) => {
        this.clearInvalidField(e.target)
        this.setState({ origin: e.target.value });
    }

    handleTravelPlaceInput1(value, type) {
        if (type === 'origin')
            this.setState(() => ({
                origintemp: value
            }))
        else if (type === 'destination')
            this.setState(() => ({
                destinationtemp: value
            }))
    }

    handleTravelPlaceInput(value, type) {
        if (type === 'origin')
            this.setState(() => ({
                origin: value
            }))
        else if (type === 'destination')
            this.setState(() => ({
                destination: value
            }))
    }

    /**On click of save the changes what has been done will be updated **/
    updateDetails = () => {
        let vlaidationFlag = true
        if (!this.state.travelerBookTickets && !this.props.bookedByTraveler) {
            vlaidationFlag = this.validateFields();
        }
        if (vlaidationFlag !== true) return;
        this.setState({ checkboxStatus: false, errorMsg: false })
        let travelObj = {
            "tripId": this.props.tripId,
            "bookedByTraveler": this.state.travelerBookTickets,
            "origin": this.state.origin,
            "destination": this.state.destination,
            "actualStartDate": dateFormat(this.state.actualStrtDt, "yyyy-mm-dd"),
            "actualEndDate": dateFormat(this.state.actualEndDt, "yyyy-mm-dd"),
            "sendToTraveler": "false",
            "emailId": this.props.email,
            "name": this.props.firstname + " " + this.props.lastname,
            "tripConfirmed": this.state.travelerBookTickets ? false : this.state.checkboxStatus
        }
        this.props.sendInfoToTraveller(travelObj, this.props.tripId);
    }

    /**
    * highlighting the field when its epmty or not valid
    */
    highlightInvalidField(element) {
        let label = $(element).siblings('.text-class')
        label = label.length === 0 ? $(element).find('.text-class') : label
        label = label.length === 0 ? $(element).closest('.form-group').find('.text-class') : label
        label.addClass('has-error')
        element.focus()
    }

    /**
     *removing the error class applied during validation on change of event
     */
    clearInvalidField(element) {
        let label = $(element).siblings('.text-class')
        label = label.length === 0 ? $(element).find('.text-class') : label
        label = label.length === 0 ? $(element).closest('.form-group').find('.text-class') : label
        label.removeClass('has-error')
    }

    /**
     *checks for the valid input values for all the input fields
     */
    validateFields() {
        var startDate = this.state.actualStrtDt;
        var endDate = this.state.actualEndDt;
        [this.overallTrip.current,
        this.overallTripDestination.current,
        this.actulaTravelDateRangePicker.current].forEach(el => this.clearInvalidField(el))
        if (!(this.overallTrip.current.value && this.overallTrip.current.value.trim().length > 0)) {
            notification.warning({
                message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Error </span>{errorMessages.error_req_actualOrigin}</span>,
                duration: 3,
                style: {
                    backgroundColor: '#fffbe6',
                    border: "1px solid #ffe58f",
                }
            });
            this.highlightInvalidField(this.overallTrip.current)
            return false;
        }
        if (this.overallTrip.current.value !== this.state.origin) {
            notification.warning({
                message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Error </span>{errorMessages.error_req_actualOrigin}</span>,
                duration: 3,
                style: {
                    backgroundColor: '#fffbe6',
                    border: "1px solid #ffe58f",
                }
            });
            this.highlightInvalidField(this.overallTrip.current)
            return false;
        }
        if (!(this.overallTripDestination.current.value && this.overallTripDestination.current.value.trim().length > 0)) {
            notification.warning({
                message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Error </span>{errorMessages.error_req_actualDestination}</span>,
                duration: 3,
                style: {
                    backgroundColor: '#fffbe6',
                    border: "1px solid #ffe58f",
                }
            });
            this.highlightInvalidField(this.overallTripDestination.current)
            return false;
        }
        if (this.overallTripDestination.current.value !== this.state.destination) {
            notification.warning({
                message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Error </span>{errorMessages.error_req_actualDestination}</span>,
                duration: 3,
                style: {
                    backgroundColor: '#fffbe6',
                    border: "1px solid #ffe58f",
                }
            });
            this.highlightInvalidField(this.overallTripDestination.current)
            return false;
        }
        if (!(startDate && startDate.length > 0) || !(endDate && endDate.length > 0)) {
            notification.warning({
                message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Error </span>{errorMessages.error_req_actualTripDates}</span>,
                duration: 3,
                style: {
                    backgroundColor: '#fffbe6',
                    border: "1px solid #ffe58f",
                }
            });
            this.highlightInvalidField(this.actulaTravelDateRangePicker.current)
            return false;
        }
        return true;
    }
    /**sends the informatiion to the traveller regarding trip
     * @param  {} e
     */
    sendinfoToTraveller(e) {
        /* if (this.validateFields() !== true) return */
        let vlaidationFlag = true
        if (!this.state.travelerBookTickets && !this.props.bookedByTraveler) {
            vlaidationFlag = this.validateFields();
        }
        if (vlaidationFlag !== true) return;

        let travelObj = {
            "tripId": !this.props.tripId ? this.props.tripId : parseInt(this.props.tripId),
            "origin": this.state.origin,
            "bookedByTraveler": this.state.travelerBookTickets,
            "destination": this.state.destination,
            "actualStartDate": dateFormat(this.state.actualStrtDt, "yyyy-mm-dd"),
            "actualEndDate": dateFormat(this.state.actualEndDt, "yyyy-mm-dd"),
            "sendToTraveler": "true",
            "emailId": this.props.email,
            "name": this.props.firstname + " " + this.props.lastname,
            "tripConfirmed": this.state.travelerBookTickets ? false : this.state.checkboxStatus
        }
        if (!this.state.travelerBookTickets && !this.props.bookedByTraveler) {
            if (this.state.checkboxStatus) {
                this.setState({ errorMsg: false, checkboxStatus: false });
                this.props.sendInfoToTraveller(travelObj, this.props.tripId);
            }
            else {
                this.setState({ errorMsg: true });
            }
        }
        else {
            this.props.sendInfoToTraveller(travelObj, this.props.tripId);
        }

    }

    /** Confirm checkbox needs to be checked before proceeding
     * @param  {} e
     */
    isChecked(e) {
        if (e.target.checked === true) {
            this.setState({ errorMsg: false })
        }
        this.setState({ checkboxStatus: e.target.checked });
    }

    /**View card details condition**/
    viewCardDetails = () => {
        /* this.setState({ toggleCarddetails: !this.state.toggleCarddetails }) */
        this.setState({ showOtp: !this.state.showOtp })
        // this.props.viewcard(this.props.tripId, this.setCardDetails, this.handleCancel);

    }

    /*
    *Handles change in otp values entered by user
    */
    handleCancel = (data) => {
        this.setState({ wrongOTP: true, otp1: '', otp2: '', otp3: '', otp4: '', otp5: '', otp6: '' })
        /* XW-61 issue - invalid OTP attempts for unlimited times
            now redirecting to OTP screen on third wrong attempt of otp */
        if (data.type === "authorization_error") {
            this.setState({ showOtp: !this.state.showOtp })
            this.toggleCardDetails()
        }
        /*  this.setState({ toggleCarddetails: !this.state.toggleCarddetails }) */
    }
    /*
    *To go back to send otp screen from verify otp screen
    */
    toggleCardDetails = () => {
        this.setState({ toggleCarddetails: false, otpCom: !this.state.otpCom, show: false })

    }
    /*
    *To populate the card details in state variable assigned and to show card with card details
    */
    setCardDetails = (data) => {
        this.setState({ cardNumberFromService: data, toggleCarddetails: !this.state.toggleCarddetails })
    }

    /**Enable Upload Flight Itenerary Pop up**/
    showflightModal = () => {
        this.setState({
            flightupload: true,
            visible: true
        });
    }

    /*
    *Handles change in checkbox for traveler ticket
    */
    onChangebookedByTraveler = (e) => {
        this.setState({ travelerBookTickets: e.target.checked })
    }
    /**Downloads the file on click of file name
     * @param  {} e
     * @param  {} url
     * @param  {} filename
     */
    downloadFile = (e, url, filename, attachmentId) => {
        /*  var uri = '/cadre' + url.split('/cadre')[1] */
        axios.get(URLs.fileUrl + 'downloads/' + attachmentId, {
            responseType: 'blob',
            headers: {
                "Authorization": 'Bearer ' + this.props.token.access_token
            }
        }).then(response => {
            if (navigator.msSaveBlob) {                // ie block
                var blobObject = new Blob([response.data], { type: 'application/pdf' });
                window.navigator.msSaveOrOpenBlob(blobObject, filename + '.pdf');
            }
            else {  // non IE block
                var a = document.createElement('a');
                a.href = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' })); // xhr.response is a blob
                a.download = filename; // Set the file name.
                a.style.display = 'none';
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                setTimeout(function () {
                    // For Firefox it is necessary to delay revoking the ObjectURL
                    window.URL.revokeObjectURL(a.href);
                }, 100)
            }
        }).catch(error => {
            if (error.response !== undefined && error.response.data !== null && error.response.data !== undefined && error.response.data.serviceStatus !== undefined) {
                notification.error({
                    message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Error </span>{error.response.data.serviceStatus.message}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }
            else {
                notification.error({
                    message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Error </span>{errorMessages.generic_error}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }
        })
    }

    /**
     * deletes all the files
     */
    deleteAllFile = (e, type, tripId) => {
        var current = this;

        confirm({
            title: <span className="cardHeading">{this.props.app_labels.confirmDeleteFileAll}</span>,
            onOk() {
                current.setState({ deleteFileFlag: true });
                loaderFocus(".loader-color .ant-spin-text");  
                axios.get(URLs.fileUrl + "delete/" + type + "/" + tripId, {
                    responseType: 'json',
                    headers: {
                        "Authorization": 'Bearer ' + current.props.token.access_token
                    }
                }).then(response => {
                    if (response.data.serviceStatus.statusCode === 200) {
                        current.props.getParticularTrip1(current.props.tripId);
                        notification.success({
                            message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Success </span>{response.data.serviceStatus.message}</span>,
                            duration: 3,
                            style: {
                                backgroundColor: '#dff0d8',
                                color: "green",
                                border: "1px solid green",
                            }
                        });
                        current.setState({ deleteFileFlag: false });
                    }
                    else if (response.data.serviceStatus.statusCode === 210) {
                        current.setState({ deleteFileFlag: false });
                        notification.error({
                            message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Error </span>{response.data.serviceStatus.message}</span>,
                            duration: 3,
                            style: {
                                backgroundColor: '#fff1f0',
                                border: "1px solid #ffa39e",
                            }
                        });
                    }
                }).catch((error) => {
                    current.setState({ deleteFileFlag: false });
                    if (error.response !== undefined && error.response.data !== null && error.response.data !== undefined && error.response.data.serviceStatus !== undefined) {
                        notification.error({
                            message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Error </span>{error.response.data.serviceStatus.message}</span>,
                            duration: 3,
                            style: {
                                backgroundColor: '#fff1f0',
                                border: "1px solid #ffa39e",
                            }
                        });
                    }
                    else {
                        notification.error({
                            message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Error </span>{errorMessages.generic_error}</span>,
                            duration: 3,
                            style: {
                                backgroundColor: '#fff1f0',
                                border: "1px solid #ffa39e",
                            }
                        });
                    }
                })

            },
            onCancel() { },
        });


    }
    /**delete hotel the file which has been uploaded
     * @param  {} e
     * @param  {} attachmentId
     */
    deleteFile = (e, attachmentId) => {
        e.preventDefault();
        var current = this;
        current.setState({ deleteFileFlag: true });
        loaderFocus(".loader-color .ant-spin-text");  
        confirm({
            title: 'Do you want to delete this file?',
            onOk() {
                axios.get(URLs.fileUrl + "delete/" + attachmentId.toString(), {
                    responseType: 'json',
                    headers: {
                        "Authorization": 'Bearer ' + current.props.token.access_token
                    }
                }).then(response => {
                    if (response.data.serviceStatus.statusCode === 200) {
                        current.setState({
                            hotelcolor: "grey",
                        });
                        current.props.getParticularTrip1(current.props.tripId);
                        notification.success({
                            message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Success </span>{response.data.serviceStatus.message}</span>,
                            duration: 3,
                            style: {
                                backgroundColor: '#dff0d8',
                                color: "green",
                                border: "1px solid green",
                            }
                        });
                        current.setState({ deleteFileFlag: false });

                    }
                    else if (response.data.serviceStatus.statusCode === 210) {
                        current.setState({ deleteFileFlag: false });
                        notification.error({
                            message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Error </span>{response.data.serviceStatus.message}</span>,
                            duration: 3,
                            style: {
                                backgroundColor: '#fff1f0',
                                border: "1px solid #ffa39e",
                            }
                        });
                    }
                }).catch((error) => {
                    current.setState({ deleteFileFlag: false });
                    notification.error({
                        message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Error </span>{error.response.data.serviceStatus.message}</span>,
                        duration: 3,
                        style: {
                            backgroundColor: '#fff1f0',
                            border: "1px solid #ffa39e",
                        }
                    });
                })
            },
            onCancel() {
                current.setState({ deleteFileFlag: false });
            },
        });

    }

    /**Enable Upload Hotel Itenerary Pop up**/
    showhotelModal = () => {
        this.setState({
            hotelupload: true,
            visible: true
        });
    }

    /**Hide Upload flight Itenerary Pop up**/
    hideFligthModal = () => {
        this.setState({ visible: false, flightupload: false })
    }

    /**Hide Upload Hotel Itenerary Pop up**/
    hideHotelModal = () => {
        this.setState({ visible: false, hotelupload: false })
    }

    /**Formatting the mobile number by adding space */
    phoneNoFormat = (value, e) => {
        phoneNoString = value.toString()
        phoneNoString1 = ""
        for (let i = phoneNoString.length - 1; i > phoneNoString.length - 5; i--) {
            if (phoneNoString[i] === ' ') {
                continue
            }
            else {
                phoneNoString1 = phoneNoString[i] + phoneNoString1
            }
        }
        phoneNoString1 = " " + phoneNoString1
        for (let i = phoneNoString.length - 5; i > phoneNoString.length - 8; i--) {
            if (phoneNoString[i] === ' ') {
                continue
            }
            else {
                phoneNoString1 = phoneNoString[i] + phoneNoString1
            }
        }
        phoneNoString1 = " " + phoneNoString1
        for (let i = phoneNoString.length - 8; i > phoneNoString.length - 11; i--) {
            if (phoneNoString[i] === ' ') {
                continue
            }
            else {
                phoneNoString1 = phoneNoString[i] + phoneNoString1
            }
        }
        if (phoneNoString.length > 10) {
            phoneNoString1 = " " + phoneNoString1
            for (let i = phoneNoString.length - 11; i >= 0; i--) {
                if (phoneNoString[i] === ' ') {
                    continue
                }
                else {
                    phoneNoString1 = phoneNoString[i] + phoneNoString1
                }
            }
        }
        return phoneNoString1
    }

    /*
    *To generate otp in order to view the card details
    */
    sendOTP = () => {
        /* XW-61 issue - invalid OTP attempts for unlimited times 
        added two more state variable to store sth and 6th otp digits*/
        this.setState({ otpCom: true, wrongOTP: false, otp1: '', otp2: '', otp3: '', otp4: '', otp5: '', otp6: '', activeResend: false })
        let obj = { username: this.props.userDetails.email, OTP_PREFERENCE: 'email' }
        axios.post(URLs.mfaUrl + 'sendOTP', qs.stringify(obj), {
            headers: {
                "Authorization": "Basic Q2FkcmUtV2ViOmNhZHJl",
            }
        }).then(res => setTimeout(() => this.setState({ activeResend: true }), 30000))
            .catch(err => notification.error({
                message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Error </span>{errorMessages.otpSendingGenericErrorMessage}</span>,
                duration: 3,
                style: {
                    backgroundColor: '#fff1f0',
                    border: "1px solid #ffa39e",
                }
            })
            )
    }

    /*
    *To verify the otp and get the card details
    */
    submitOTP = () => {
        /* XW-61 issue - invalid OTP attempts for unlimited times 
        added two more state variable to store sth and 6th otp digits*/
        let otp = this.state.otp1 + this.state.otp2 + this.state.otp3 + this.state.otp4 + this.state.otp5 + this.state.otp6
        this.props.viewcard(this.props.tripId, this.setCardDetails, this.handleCancel, otp);
    }

    render() {
        var flight = this.props.flight1;
        var hotel = this.props.hotel1;
        var country = this.props.userDetails.companyAddress.country
        var state = this.props.userDetails.companyAddress.state
        var city = this.props.userDetails.companyAddress.city
        var zipCode = this.props.userDetails.companyAddress.zipCode
        var flat = this.props.userDetails.companyAddress.flat
        var streetAddress = this.props.userDetails.companyAddress.streetAddress
        var companyAddress
        flat ?
            companyAddress = flat + "," + streetAddress + "," + city + "," + state + "," + country + "," + zipCode
            : companyAddress = streetAddress + "," + city + "," + state + "," + country + "," + zipCode

        return (
            <div >
                {!this.props.isFetching && !this.props.sendInfo ? <div>
                    <Layout>
                        <div><HeaderComponent roles={"Manager"} /></div>
                        <Layout className="App">
                            <Header style={{ backgroundColor: "#f2f2f2", width: "100%" }}><span className="App-title"><b>{this.props.app_labels.createTripHeading}</b></span></Header>
                            <Content style={{ margin: "-5px 0px 0", width: "100%" }}>
                                <div style={{ padding: 24, borderRadius: "15px", background: "#fff", minHeight: 360 }}>
                                    <MediaQuery minDeviceWidth={770}>
                                        <div className="row">
                                            <div className="col-sm-4 col-sm-offset-0">
                                                <img alt="" src={Greenbar} style={{ width: "100%" }} title={progressBarHoverText_Blue.createProfile} />
                                            </div>
                                            <div className="col-sm-4 col-sm-offset-0 progressbar-margin">
                                                {flight === true && hotel === true && this.props.flightStatus === "green" && this.props.hotelStatus === "green" ? <img alt="" src={Greenbar} style={{ width: "100%" }} title={progressBarHoverText_Blue.uploadItenerary} />
                                                    : null}
                                                {flight === true && hotel === true && (this.props.flightStatus === "red" || this.props.hotelStatus === "red") ? <img alt="" src={Bluebar} style={{ width: "100%" }} title={progressBarHoverText_Blue.uploadItenerary} />
                                                    : null}
                                                {flight === true && hotel === false && this.props.flightStatus === "green" ? <img alt="" src={Greenbar} style={{ width: "100%" }} title={progressBarHoverText_Blue.uploadItenerary} />
                                                    : null}
                                                {flight === true && hotel === false && this.props.flightStatus === "red" ? <img alt="" src={Bluebar} style={{ width: "100%" }} title={progressBarHoverText_Blue.uploadItenerary} />
                                                    : null}
                                                {flight === false && hotel === true && this.props.hotelStatus === "green" ? <img alt="" src={Greenbar} style={{ width: "100%" }} title={progressBarHoverText_Blue.uploadItenerary} />
                                                    : null}
                                                {flight === false && hotel === true && this.props.hotelStatus === "red" ? <img alt="" src={Bluebar} style={{ width: "100%" }} title={progressBarHoverText_Blue.uploadItenerary} />
                                                    : null}
                                                {flight === false && hotel === false ? <img alt="" src={Greenbar} style={{ width: "100%" }} title={progressBarHoverText_Blue.uploadItenerary} />
                                                    : null}
                                            </div>
                                            <div className="col-sm-4 col-sm-offset-0 progressbar-margin">
                                                {this.props.bookedByTraveler ? this.props.travelerNotified ? <img alt="" src={Greenbar} style={{ width: "100%" }} title={progressBarHoverText_Blue.sendInfo} /> : <img alt="" src={Greybar} style={{ width: "100%" }} title={progressBarHoverText_Blue.sendInfo} /> : null}
                                                {flight === true && hotel === true && this.props.hotelStatus === "green" && this.props.flightStatus === "green" && this.props.travelerNotified === false && !this.props.bookedByTraveler ? <img alt="" src={Bluebar} style={{ width: "100%" }} title={progressBarHoverText_Blue.sendInfo} /> : null}
                                                {flight === true && hotel === true && (this.props.hotelStatus === "red" || this.props.flightStatus === "red") && this.props.travelerNotified === false && !this.props.bookedByTraveler ? <img alt="" src={Greybar} style={{ width: "100%" }} title={progressBarHoverText_Blue.sendInfo} /> : null}
                                                {flight === true && hotel === true && this.props.hotelStatus === "green" && this.props.flightStatus === "green" && this.props.travelerNotified === true && !this.props.bookedByTraveler ? <img alt="" src={Greenbar} style={{ width: "100%" }} title={progressBarHoverText_Blue.sendInfo} /> : null}
                                                {flight === true && hotel === true && (this.props.hotelStatus === "red" || this.props.flightStatus === "red") && this.props.travelerNotified === true && !this.props.bookedByTraveler ? <img alt="" src={Greybar} style={{ width: "100%" }} title={progressBarHoverText_Blue.sendInfo} /> : null}


                                                {flight === false && hotel === false && this.props.travelerNotified === true && !this.props.bookedByTraveler ? <img alt="" src={Greenbar} style={{ width: "100%" }} title={progressBarHoverText_Blue.sendInfo} /> : null}
                                                {flight === false && hotel === false && this.props.travelerNotified === false && !this.props.bookedByTraveler ? <img alt="" src={Bluebar} style={{ width: "100%" }} title={progressBarHoverText_Blue.sendInfo} /> : null}

                                                {flight === true && hotel === false && this.props.flightStatus === "green" && this.props.travelerNotified === false && !this.props.bookedByTraveler ? <img alt="" src={Bluebar} style={{ width: "100%" }} title={progressBarHoverText_Blue.sendInfo} /> : null}
                                                {flight === true && hotel === false && this.props.flightStatus === "red" && this.props.travelerNotified === false && !this.props.bookedByTraveler ? <img alt="" src={Greybar} style={{ width: "100%" }} title={progressBarHoverText_Blue.sendInfo} /> : null}
                                                {flight === true && hotel === false && this.props.flightStatus === "green" && this.props.travelerNotified === true && !this.props.bookedByTraveler ? <img alt="" src={Greenbar} style={{ width: "100%" }} title={progressBarHoverText_Blue.sendInfo} /> : null}

                                                {flight === false && hotel === true && this.props.hotelStatus === "green" && this.props.travelerNotified === false && !this.props.bookedByTraveler ? <img alt="" src={Bluebar} style={{ width: "100%" }} title={progressBarHoverText_Blue.sendInfo} /> : null}
                                                {flight === false && hotel === true && this.props.hotelStatus === "red" && this.props.travelerNotified === false && !this.props.bookedByTraveler ? <img alt="" src={Greybar} style={{ width: "100%" }} title={progressBarHoverText_Blue.sendInfo} /> : null}
                                                {flight === false && hotel === true && this.props.hotelStatus === "green" && this.props.travelerNotified === true && !this.props.bookedByTraveler ? <img alt="" src={Greenbar} style={{ width: "100%" }} title={progressBarHoverText_Blue.sendInfo} /> : null}

                                                {/*   {this.props.flightStatus === "green" && this.props.hotelStatus === "green" && this.props.travelerNotifield !== true ?
                          <img src={Bluebar} style={{ width: "100%" }} title={progressBarHoverText_Blue.sendInfo} /> : null}
                        {this.props.flightStatus === "green" && this.props.hotelStatus === "green" && this.props.travelerNotifield === true ?
                          <img src={Greenbar} style={{ width: "100%" }} title={progressBarHoverText_Blue.sendInfo} /> : null}
                        {(flight === true && this.props.flightStatus !== "green") || (hotel === true && this.props.hotelStatus !== "green") ?
                          <img src={Greybar} style={{ width: "100%" }} title={progressBarHoverText_Blue.sendInfo} /> : null} */}

                                            </div>
                                        </div>
                                        <div className="row">
                                            {((this.props.flightStatus !== "green" && flight === true) || (this.props.hotelStatus !== "green" && hotel === true)) ?
                                                <div className="col-sm-3 col-sm-offset-5">
                                                    <span className="progressbar-text">{this.props.app_labels.uploadItinerary}</span>
                                                </div> :
                                                <div className="col-sm-4 col-sm-offset-8">
                                                    <span className="progressbar-text">{this.props.app_labels.sendInfoToTraveller}</span>
                                                </div>}
                                        </div>
                                    </MediaQuery>
                                    <br /><br />
                                    <div className="row">
                                        <div className="col-sm-4 col-md-3 col-md-offset-1">
                                            <span className="text-class">{this.props.app_labels.typeOfTraveler}</span> <br />
                                            <span className="input-text">{this.props.role}</span>
                                        </div>
                                        <div className="col-sm-4 col-md-3 spacebtwn-columns rowspace">
                                            <span className="text-class">{this.props.app_labels.department}</span><br />
                                            <span className="input-text">{this.props.department}</span>
                                        </div>
                                        <div className="col-sm-4 col-md-3 spacebtwn-columns rowspace"  >
                                            <span className="text-class">{this.props.app_labels.role}</span> <br />
                                            <span className="input-text">{this.props.role_freefield}</span>
                                        </div>
                                    </div>

                                    <div className="row spacebtwn-rows">
                                        <div className="col-sm-4 col-md-3 col-md-offset-1">
                                            <span className="text-class">{this.props.app_labels.firstName_AdminView}</span> <br />
                                            <span className="input-text">{this.props.firstname}</span>
                                        </div>
                                        <div className="col-sm-4 col-md-3 col-md-offset-0 spacebtwn-columns rowspace">
                                            <span className="text-class">{this.props.app_labels.lastName_AdminView}</span> <br />
                                            <span className="input-text">{this.props.lastname}</span>
                                        </div>
                                        <div className="col-sm-4 col-md-3 col-md-offset-0 spacebtwn-columns rowspace">
                                            <span className="text-class">{this.props.app_labels.email}</span><br />
                                            <span className="input-text">{this.props.email}</span>
                                        </div>
                                    </div>

                                    <div className="row spacebtwn-rows">
                                        <div className="col-sm-4 col-sm-offset-0 col-md-3 col-md-offset-1">
                                            <span className="text-class">{this.props.app_labels.mobileNumber}</span><br />
                                            <span className="input-text">{this.props.phno1 && this.props.phno1.toString() !== 'N.A.' ? this.phoneNoFormat(this.props.phno1) : null}</span>
                                        </div>
                                        <div className="col-sm-4 col-md-3 col-md-offset-0 spacebtwn-columns rowspace"  >
                                            <span className="text-class">{this.props.app_labels.altMobileNumber}</span><br />
                                            <span className="input-text">{this.props.phno2 && this.props.phno2.toString() !== 'N.A.' ? this.phoneNoFormat(this.props.phno2) : null}</span>
                                        </div>
                                        <div className="col-sm-4 col-md-3 col-md-offset-0 spacebtwn-columns rowspace"   >
                                            <span className="text-class">{this.props.app_labels.travelerId}</span> <br />
                                            <span className="input-text">{this.props.cadreId}</span>
                                        </div>
                                    </div>

                                    <div className="row spacebtwn-rows">
                                        <div className="col-sm-4 col-sm-offset-0 col-md-3 col-md-offset-1">
                                            <span className="text-class">{this.props.app_labels.source}</span><br />
                                            <span className="input-text">{this.props.origin}</span>
                                        </div>
                                        <div className="col-sm-4 col-md-3 col-md-offset-0 spacebtwn-columns rowspace" >
                                            <span className="text-class">{this.props.app_labels.destination}</span> <br />
                                            <span className="input-text">{this.props.destination}</span>
                                        </div>
                                        <div className="col-sm-4 col-md-3 col-md-offset-0 spacebtwn-columns rowspace">
                                            <span className="text-class">{this.props.app_labels.estimatedTravelDate}</span> <br />
                                            <span className="input-text" style={{ fontSize: "21px" }}>{dateFormat(this.state.actualStrtDt, "mm/dd/yyyy")}</span><span className="input-text" style={{ fontSize: "20px" }}>&nbsp;&nbsp;to</span>
                                            <span className="input-text" style={{ fontSize: "21px" }}> {dateFormat(this.state.actualEndDt, "mm/dd/yyyy")}</span>
                                        </div>
                                    </div>

                                    <div className="row spacebtwn-rows">
                                        <div className="col-sm-4 col-sm-offset-0 col-md-3 col-md-offset-1">
                                            <span className="text-class">{this.props.app_labels.accountingCode}</span><br />
                                            <span className="input-text">{this.props.accountingCode}</span>
                                        </div>
                                        {this.props.entityCode[0] &&
                                            <div className="col-sm-4 col-md-3 col-md-offset-0 spacebtwn-columns rowspace">
                                                <span className="text-class">{this.props.app_labels.entityTypeID1}</span><br />
                                                <span className="input-text">{this.props.entityCode[0]}</span>
                                            </div>}
                                        {this.props.entityCode[1] &&
                                            <div className="col-sm-4 col-md-3 col-md-offset-0 spacebtwn-columns rowspace">
                                                <span className="text-class">{this.props.app_labels.entityTypeID2}</span><br />
                                                <span className="input-text">{this.props.entityCode[1]}</span>
                                            </div>}
                                    </div>

                                    <div className="row spacebtwn-rows">
                                        <div className="col-sm-4 col-sm-offset-0 col-md-3 col-md-offset-1"><span className="text-class">{this.props.app_labels.selectAirnHotel}</span>
                                            <br /> <br />
                                            <div className="row">
                                                <div style={{ marginLeft: "3%" }}>
                                                    <span className="col-sm-6 col-sm-offset-0 col-md-6 col-md-offset-0 col-lg-4 col-lg-offset-0"><Checkbox aria-label="Flight" checked={flight} value="travelbooking"><i role='img' aria-label="Flight" className="icon-Airplane-blue-size-1" style={{ fontWeight: "bold", fontSize: "18px" }} /></Checkbox></span>
                                                    <span className="col-sm-6 col-sm-offset-0 col-md-6 col-md-offset-0 col-lg-5 col-lg-offset-0"><Checkbox aria-label="Hotel" checked={hotel} value="hotelbooking"><i role='img' aria-label="Hotel" className="icon-Buildings-blue-size-1" style={{ fontWeight: "bold", fontSize: "18px" }} /></Checkbox></span>
                                                </div>
                                            </div><br /><br />
                                        </div>
                                    </div>

                                    {this.props.carddetails.length !== 0 ? <div>
                                        {
                                            this.state.toggleCarddetails === false ?
                                                <div>
                                                    <div className="row">
                                                        <div className="col-sm-10 col-sm-offset-0 col-md-10 col-md-offset-1">
                                                            <span className="cardDetails">{this.props.app_labels.cardDetails}</span><span className="required">*</span><br /><hr className="dash-filelist-line" style={{ width: "102%" }} />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-4 col-sm-offset-0 col-md-4 col-md-offset-1">
                                                            <span className="text-class">{this.props.app_labels.cardLimit}</span><br />&nbsp;&nbsp;
                                                            <span className="input-text">${this.props.budget}</span>
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{ marginTop: "2%", marginLeft: "-4%" }}>
                                                        {this.state.otpCom === false ?
                                                            <div style={{ overflowX: "auto", overflowY: "hidden", minWidth: "100" }} className="col-sm-10 col-sm-offset-0 col-md-10 col-md-offset-1">
                                                                {this.state.showOtp === false ?
                                                                    <><div className={`jp-card-container jp-card jp-card-jcb jp-card-identified`}>
                                                                        <div className="jp-card-number">
                                                                            <div>{this.props.carddetails[0].maskedCreditCardNo}</div>
                                                                            <div style={{ marginTop: '4%' }}>
                                                                                <span className='validthru'>{this.props.app_labels.validThru}</span>
                                                                                <span className="jp-card-expiry" > {dateFormat(this.props.carddetails[0].cardExpiryDate, "mm/yyyy")}</span>
                                                                                <span style={{ marginLeft: "27%" }} className='validthru'>{this.props.app_labels.cvv}</span>
                                                                                < span className="jp-card-expiry" >XXX</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                        <div className="viewcardClass" onClick={this.viewCardDetails}>
                                                                            <i role='img' aria-label="view card details" className="icon-show-password viewcardClass-icon" />
                                                                            <span>{this.props.app_labels.viewCardDetails}</span>
                                                                        </div></>
                                                                    : <div className={`jp-card-container jp-card jp-card-jcb jp-card-identified card-blur`}
                                                                        onClick={() => this.setState({ showOtp: !this.state.showOtp })}>
                                                                        <div className="blur-card-otp">
                                                                            <span><b>To view Card Details, tap on 'Send OTP'</b></span>
                                                                            <div>
                                                                                <Button ghost onClick={this.sendOTP}>Send OTP</Button>
                                                                            </div>
                                                                        </div>
                                                                    </div>}
                                                            </div>
                                                            : <div style={{ overflowX: "auto", overflowY: "hidden", minWidth: "100" }} className="col-sm-10 col-sm-offset-0 col-md-7">
                                                                <div className="preview-otp-body">
                                                                    <div><span>A 6-digit verification has been sent to your registered Email Id.</span></div>
                                                                    {this.state.wrongOTP === true ?
                                                                        <div className="preview-otp-error-text">
                                                                            <Icon type="warning" />
                                                                            Please enter the correct varification code!!
                                                                        </div> : <div className="preview-otp-error-empty"></div>}
                                                                    <div><span className={`verification-text ${this.state.wrongOTP === true && 'error-text'}`}><b>Verification code</b></span></div>
                                                                    <div className="otp-input">
                                                                        <Input maxLength='1' type={this.state.show === false && 'password'} value={this.state.otp1} onChange={e => this.setState({ otp1: e.target.value })}
                                                                            onKeyUp={e => {
                                                                                (e.keyCode !== 8 && e.keyCode !== 46 && e.keyCode !== 9 && e.keyCode !== 16) && document.getElementById("otp2").focus()
                                                                            }}
                                                                            id="otp1" className={this.state.wrongOTP === true ? "error-border" : (this.state.otp1 !== "" && "blue-border")} />
                                                                        <Input maxLength='1' type={this.state.show === false && 'password'} value={this.state.otp2} onChange={e => this.setState({ otp2: e.target.value })}
                                                                            onKeyUp={e => {
                                                                                (e.keyCode !== 8 && e.keyCode !== 46 && e.keyCode !== 9 && e.keyCode !== 16) && document.getElementById("otp3").focus()
                                                                                if (e.keyCode === 8) document.getElementById("otp1").focus()
                                                                            }}
                                                                            id="otp2" className={this.state.wrongOTP === true ? "error-border" : (this.state.otp2 !== "" && "blue-border")} />
                                                                        <Input maxLength='1' type={this.state.show === false && 'password'} value={this.state.otp3} onChange={e => this.setState({ otp3: e.target.value })}
                                                                            onKeyUp={e => {
                                                                                (e.keyCode !== 8 && e.keyCode !== 46 && e.keyCode !== 9 && e.keyCode !== 16) && document.getElementById("otp4").focus()
                                                                                if (e.keyCode === 8) document.getElementById("otp2").focus()
                                                                            }}
                                                                            id="otp3" className={this.state.wrongOTP === true ? "error-border" : (this.state.otp3 !== "" && "blue-border")} />
                                                                        <Input maxLength='1' type={this.state.show === false && 'password'} value={this.state.otp4} onChange={e => this.setState({ otp4: e.target.value })}
                                                                            onKeyUp={e => {
                                                                                (e.keyCode !== 8 && e.keyCode !== 46 && e.keyCode !== 9 && e.keyCode !== 16) && document.getElementById("otp5").focus()
                                                                                if (e.keyCode === 8) document.getElementById("otp3").focus()
                                                                            }}
                                                                            id="otp4" className={this.state.wrongOTP === true ? "error-border" : (this.state.otp4 !== "" && "blue-border")} />
                                                                            {/* XW-61 issue - invalid OTP attempts for unlimited times 
                                                                                added two more input fields for user to enter 5th and 6th otp digits*/}
                                                                        <Input maxLength='1' type={this.state.show === false && 'password'} value={this.state.otp5} onChange={e => this.setState({ otp5: e.target.value })}
                                                                            onKeyUp={e => {
                                                                                (e.keyCode !== 8 && e.keyCode !== 46 && e.keyCode !== 9 && e.keyCode !== 16) && document.getElementById("otp6").focus()
                                                                                if (e.keyCode === 8) document.getElementById("otp4").focus()
                                                                            }}
                                                                            id="otp5" className={this.state.wrongOTP === true ? "error-border" : (this.state.otp5 !== "" && "blue-border")} />
                                                                        <Input maxLength='1' type={this.state.show === false && 'password'} value={this.state.otp6} onChange={e => this.setState({ otp6: e.target.value })}
                                                                            onKeyUp={e => {
                                                                                (e.keyCode !== 8 && e.keyCode !== 46 && e.keyCode !== 9 && e.keyCode !== 16) && document.getElementById("otp6").blur()
                                                                                if (e.keyCode === 8) document.getElementById("otp5").focus()
                                                                            }}
                                                                            id="otp6" className={this.state.wrongOTP === true ? "error-border" : (this.state.otp6 !== "" && "blue-border")} />
                                                                    </div>
                                                                    {(this.state.otp1 !== '' || this.state.otp2 !== '' || this.state.otp3 !== '' || this.state.otp4 !== '' || this.state.otp5 !== '' || this.state.otp6 !== '') &&
                                                                        <p className="show"
                                                                            onClick={() => this.setState({ show: !this.state.show })}>
                                                                            {this.state.show === true ? 'Hide' : 'Show'}
                                                                        </p>}
                                                                    <div className={`resend-otp ${!this.state.activeResend ? 'resend-padding' : ''}`}>
                                                                        {!this.state.activeResend && <Icon type="loading" className="count-down" />}
                                                                        {!this.state.activeResend && <Counter />}
                                                                        <Button className="resend-otp-button"
                                                                            disabled={!this.state.activeResend}
                                                                            onClick={this.sendOTP}>Resend code</Button>
                                                                        <Button className="otp-button" type="primary"
                                                                            disabled={(this.state.otp1 === '' || this.state.otp2 === '' || this.state.otp3 === '' || this.state.otp4 === '' || this.state.otp5 === '' || this.state.otp6 === '') ? true : false}
                                                                            onClick={() => this.submitOTP()}>
                                                                            Submit
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                            </div>}
                                                    </div><br /><br />
                                                </div>
                                                : <div>
                                                    <div className="row">
                                                        <div className="col-sm-6 col-sm-offset-0 col-md-6 col-md-offset-1"><span className="cardDetails">{this.props.app_labels.cardDetails}</span><br /></div></div>
                                                    <div className="row" style={{ marginTop: "2%" }}>
                                                        <div className="col-sm-4 col-sm-offset-0 col-md-4 col-md-offset-1">
                                                            <span className="text-class">{this.props.app_labels.cardLimit}</span><br />&nbsp;&nbsp;
                                                            <span className="input-text">${this.props.budget}</span>
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{ marginTop: "2%", marginLeft: "-4%" }}>
                                                        <div style={{ overflowX: "auto", overflowY: "hidden", minWidth: "100" }} className="col-sm-10 col-sm-offset-0 col-md-10 col-md-offset-1">
                                                            <div className="jp-card-container jp-card jp-card-jcb jp-card-identified">
                                                                <div className="jp-card-number">
                                                                    <div>{this.state.cardNumberFromService.cardNo.replace(/(\d{4})/g, '$1 ').replace(/(^\s+|\s+$)/, '')}</div>
                                                                    <div style={{ marginTop: '4%' }}>
                                                                        <span className="validthru">{this.props.app_labels.validThru}</span>
                                                                        <span className="jp-card-expiry"> {dateFormat(this.props.carddetails[0].cardExpiryDate, "mm/yyyy")}</span>
                                                                        <span style={{ marginLeft: "24%" }} className="validthru">{this.props.app_labels.cvv}</span>
                                                                        <span className="jp-card-expiry">{this.state.cardNumberFromService.cvv}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="viewcardClass" onClick={this.toggleCardDetails}>
                                                                <i role='img' aria-label="view card details" className="icon-hide-password viewcardClass-icon" />
                                                                <span>{this.props.app_labels.hideCardDetails}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>}
                                        <div className="row">
                                            <div className="col-sm-6 col-sm-offset-0 col-md-5 col-md-offset-0">
                                                <div className="row">
                                                    <div className="col-sm-4 col-sm-offset-0 col-md-4 col-md-offset-2">
                                                        <span className="text-class" style={{ marginLeft: "9px", color: "#000", whiteSpace: "nowrap" }}>Company Address</span><br />
                                                        <span className="text-class" style={{ marginLeft: "9px", color: "#636466", whiteSpace: "nowrap" }}>{companyAddress}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> : null}
                                </div>

                                {/*   {
                  this.props.flight1 === true || this.props.hotel1 === true ? <div className="row">
                    <div className="left-space1"><span className="cardDetails">Travel Details</span></div>
                   
                  </div> : null
                } */}
                                <br />
                                {this.props.bookedByTraveler !== true /* || this.props.travelerNotified !== true  */ ?
                                    <div className="row">
                                        <span className="left-space1" style={{ width: 'fitContent' }}><span className="cardDetails">{this.props.app_labels.travelDetails}</span></span>
                                        <span className='spacebtwn-checkbox'><Checkbox aria-label="Traveler book tickets" defaultChecked={this.state.travelerBookTickets} onChange={this.onChangebookedByTraveler}><span className='text-class'>{this.props.app_labels.travelerBooksTickets}</span></Checkbox></span>
                                        <span>
                                            <Icon title={errorMessages.travelerBooksTicketsToolTip} type="question-circle" style={{ color: "#112a70" }} />
                                        </span>
                                    </div> : null}
                                {this.state.travelerBookTickets === false && this.props.bookedByTraveler === false ?
                                    <div>
                                        {this.state.deleteFileFlag ? <div className="dark-spinner loader-color"><Spin tip="Uploading..." /></div> : null}
                                        {this.props.flightStatus === "red" || this.props.flightStatus === "green" ?
                                            <div className="flight-itenerary-box">
                                                <div className="row">
                                                    <div className="col-xs-3 col-xs-offset-1 col-sm-1 col-sm-offset-0">
                                                        <div className="tripAll-icon">
                                                            {this.props.flightStatus === "red" ? <i role='img' aria-label="Flight" className="icon-Airplane-grey-size-1 itenary-icons" style={{ fontWeight: "bold" }} ></i> : null}
                                                            {this.props.flightStatus === "green" ? <i role='img' aria-label="Flight" className="icon-Airplane-blue-size-1 itenary-icons" style={{ fontWeight: "bold", color: "#1B3281" }}></i> : null}
                                                        </div>
                                                    </div>
                                                    <div className="col-xs-6 col-xs-offset-1 col-sm-2 col-sm-offset-0">
                                                        <div className="trip-itenarydetails">{this.props.app_labels.flightItinerary}</div>
                                                    </div>
                                                    <div>
                                                        {this.props.flightfile.length !== 0 ? <div className="col-xs-12 col-xs-offset-0 col-sm-6 col-sm-offset-0 trip-filelist-line"><br /> {this.props.flightfile.map((object) => {
                                                            return <div>
                                                                <div title={object.fileName} className="col-xs-8 col-xs-offset-0 col-sm-7 col-sm-offset-1" style={{ color: "#112A70", wordBreak: 'break-all' }}><p className="a1-link" onClick={(e) => this.downloadFile(e, object.locationUrl, object.fileName, object.tripAttachmentId)} download>{object.fileName.length < 56 ? object.fileName : object.fileName.substring(0, 56) + "...."}</p></div>
                                                                {flight ? <div className="col-xs-2 col-xs-offset-1 col-sm-3 col-sm-offset-1"> <i role='img' aria-label="Close" className="icon-Close" style={{ color: '#C53D47', textDecoration: "none", fontWeight: 'bold' }} onClick={(e) => this.deleteFile(e, object.tripAttachmentId)} /></div> : null}
                                                            </div>
                                                        })}
                                                        </div> : <div className="col-xs-10 col-xs-offset-1 col-sm-6 col-sm-offset-2 pendingUpload">{this.props.app_labels.pendingUpload}</div>}

                                                    </div>
                                                    <div className="col-xs-9 col-xs-offset-1 col-sm-1 col-sm-offset-1" >
                                                        <div className="trip-upload">
                                                            {flight && this.props.flightfile.length !== 0 ? (<i role='img' aria-label='Delete' title='Delete' className="icon-Trash-Garbage-Can uploadBtnClass" style={{ fontSize: 22 }} onClick={(e) => this.deleteAllFile(e, 'F', this.props.tripId)}  ></i>) : null}&nbsp;&nbsp;&nbsp;
                                                            {flight ? <i role='img' aria-label="Upload attachment" className="icon-Upload-attachment" style={{ fontSize: 25, color: "grey" }} onClick={this.showflightModal} ></i> : null}
                                                            {this.state.flightupload === true ? <FlightFileUpload action={this.state.visible} hideFligthModal={this.hideFligthModal} tripId={this.props.tripId} /> : null}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> : null}

                                        {this.props.hotelStatus === "red" || this.props.hotelStatus === "green" ?
                                            <div className="hotel-itenerary-box">
                                                <div className="row">
                                                    <div className="col-xs-2 col-xs-offset-1 col-sm-1 col-sm-offset-0">
                                                        <div className="tripAll-icon">
                                                            {this.props.hotelStatus === "red" ? <i role='img' aria-label="Hotel" className="icon-Buildings-grey-size-1 itenary-icons" style={{ fontWeight: "bold" }} /> : null}
                                                            {this.props.hotelStatus === "green" ? <i role='img' aria-label="Hotel" className="icon-Buildings-blue-size-1 itenary-icons" style={{ fontWeight: "bold", color: "#1B3281" }} /> : null}
                                                        </div>
                                                    </div>
                                                    <div className="col-xs-7 col-xs-offset-1 col-sm-2 col-sm-offset-0">
                                                        <div className="trip-itenarydetails">{this.props.app_labels.hotelReservations}</div>
                                                    </div>
                                                    <div >
                                                        {this.props.hotelfile.length !== 0 ? <div className="col-xs-12 col-xs-offset-0 col-sm-6 col-sm-offset-0 trip-filelist-line"><br /> {this.props.hotelfile.map((object) => {
                                                            return <div>
                                                                <div title={object.fileName} className="col-xs-8 col-xs-offset-0 col-sm-7 col-sm-offset-1" style={{ color: "#112A70", wordBreak: 'break-all' }}><p className="a1-link" onClick={(e) => this.downloadFile(e, object.locationUrl, object.fileName, object.tripAttachmentId)} download>{object.fileName.length < 56 ? object.fileName : object.fileName.substring(0, 56) + "...."}</p></div>
                                                                {hotel ? <div className="col-xs-2 col-xs-offset-1 col-sm-3 col-sm-offset-1"> <i role='img' aria-label="Close" className="icon-Close" style={{ color: '#C53D47', textDecoration: "none", fontWeight: 'bold' }} onClick={(e) => this.deleteFile(e, object.tripAttachmentId)} /></div> : null}
                                                            </div>
                                                        })}
                                                        </div> : <div className="col-xs-10 col-xs-offset-1 col-sm-6 col-sm-offset-2 pendingUpload">{this.props.app_labels.pendingUpload}</div>}

                                                    </div>
                                                    <div className="col-xs-9 col-xs-offset-1 col-sm-1 col-sm-offset-1">
                                                        <div className="trip-upload">
                                                            {hotel && this.props.hotelfile.length !== 0 ? (<i role='img' aria-label='Delete' title='Delete' className="icon-Trash-Garbage-Can uploadBtnClass" style={{ fontSize: 22 }} onClick={(e) => this.deleteAllFile(e, 'H', this.props.tripId)}  ></i>) : null}&nbsp;&nbsp;&nbsp;
                                                            {hotel ? <i className="icon-Upload-attachment" style={{ fontSize: 25 }} onClick={this.showhotelModal} /> : null}
                                                            {this.state.hotelupload === true ? <HotelFileUpload action={this.state.visible} hideHotelModal={this.hideHotelModal} tripId={this.props.tripId} /> : null}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> : null}

                                        <br />

                                        <div className="row"><span className="left-space cardDetails" style={{ marginLeft: '3%' }}>{this.props.app_labels.overallTripJourney}</span><br /><br /></div>
                                        <div className="row left-space">
                                            <div className="col-sm-4 col-sm-offset-0 col-md-3 col-md-offset-0" tabIndex="0">
                                                <span className="text-class">{this.props.app_labels.actualOrigin}</span><br />&nbsp;&nbsp;
                                                {/* <input aria-label="Actual origin" ref={this.overallTrip} className="input-bg input-text" type="text" value={this.state.origin} disabled={this.props.travelerNotified} onChange={this.sourceChange} required /><br /><br /> */}
                                                <div className="aiportInput" style={{ marginLeft: '4%' }}>
                                                    {
                                                        this.state.updatedFromPrevProp ?
                                                            <AirportAutosuggest
                                                                disableFlag={this.props.travelerNotified}
                                                                handleTravelPlaceInput1={(value, type) => this.handleTravelPlaceInput1(value, type)}
                                                                handleTravelPlaceInput={(value, type) => this.handleTravelPlaceInput(value, type)}
                                                                setInputRef={(ele) => {
                                                                    this.overallTrip.current = ele
                                                                }}
                                                                value={this.state.origin}
                                                                type='origin'
                                                                labelName='Actual origin'
                                                                className='input-bg input-text'
                                                                id='autocomplete'
                                                                clearInvalidField={() => this.clearInvalidField(this.overallTrip.current)}
                                                            /> : null
                                                    }
                                                </div>
                                            </div >
                                            <div className="col-sm-4 col-sm-offset-0 col-md-3 col-md-offset-1">
                                                <span className="text-class">{this.props.app_labels.actualDestination}</span><br />&nbsp;&nbsp;
                                                <div className="aiportInput" style={{ marginLeft: '4%' }}>
                                                    {/* <input aria-label="Actual Destination" ref={this.overallTripDestination} className="input-bg input-text" type="text" value={this.state.destination} disabled={this.props.travelerNotified} onChange={this.destinationChange} required /><br /><br /> */}
                                                    {
                                                        this.state.updatedFromPrevProp ?
                                                            <AirportAutosuggest
                                                                disableFlag={this.props.travelerNotified}
                                                                handleTravelPlaceInput1={(value, type) => this.handleTravelPlaceInput1(value, type)}
                                                                handleTravelPlaceInput={(value, type) => this.handleTravelPlaceInput(value, type)}
                                                                setInputRef={(ele) => {
                                                                    this.overallTripDestination.current = ele
                                                                }}
                                                                value={this.state.destination}
                                                                type='destination'
                                                                labelName='Actual Destination'
                                                                className='input-bg input-text'
                                                                id='autocomplete'
                                                                clearInvalidField={() => this.clearInvalidField(this.overallTripDestination.current)}
                                                            /> : null}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row left-space cardDetails">{this.props.app_labels.actualTripDates}</div>&nbsp;&nbsp;
                                            <div className="row left-space">
                                            <div className="col-sm-4 col-sm-offset-0 col-md-3 col-md-offset-0">
                                                <label for="actualDates">
                                                    <RangePicker id="actualDates" disabledDate={this.disabledDate} ref={this.actulaTravelDateRangePicker} className="left-space"
                                                        placeholder={['From', 'To']}
                                                        disabled={this.props.travelerNotified}
                                                        value={[
                                                            moment(dateFormat(this.state.actualStrtDt, "mm/dd/yyyy"), "MM/DD/YYYY"),
                                                            moment(dateFormat(this.state.actualEndDt, "mm/dd/yyyy"), "MM/DD/YYYY")
                                                        ]}
                                                        onChange={this.handeldatechange}
                                                        format="MM/DD/YYYY"
                                                    /></label><br /><br />
                                            </div>

                                            <div className="col-sm-8 col-sm-offset-0 col-md-3 col-md-offset-1">
                                                <Checkbox aria-label="Confirm trip details" onChange={this.isChecked}><span className="text-class">{this.props.app_labels.confirmTripDetails}</span> <span className="required">*</span></Checkbox><br />
                                                {this.state.errorMsg === true ? <span className="error-meassage">{errorMessages.confirm_errorMessage}</span> : null}
                                            </div>
                                        </div>
                                    </div> : null}
                                <br />
                                {this.state.cancelTripStatus === false ? <div className="row" style={{ marginBottom: "2%" }}>
                                    <div className="col-sm-3 col-sm-offset-0" style={{ textAlign: 'left' }}>
                                        <Button className="trip-button button-wrapclass" type="primary"
                                            disabled={this.props.tripstatusDescription.toLowerCase() === tripStatusConstants.PendingClosure.toLowerCase() ||
                                                this.props.tripstatusDescription.toLowerCase() === tripStatusConstants.PendingExpenseReporting.toLowerCase() ||
                                                this.props.tripstatusDescription.toLowerCase() === tripStatusConstants.InProgress.toLowerCase()}
                                            ghost onClick={this.cancelTrip}><b>{this.props.app_labels.cancelTrip}</b></Button>
                                    </div>
                                    <div className="col-sm-9 col-sm-offset-0" style={{ textAlign: 'right' }}>
                                        <Button className="trip-button button-wrapclass buttonRightPadding" type="primary" ghost onClick={this.updateDetails}><b>{this.props.app_labels.save}</b></Button>
                                        {/* </div>
                  <div className="col-sm-3 col-sm-offset-0" style={{ textAlign: 'right' }}> */}
                                        <Button title={errorMessages.sendtoTravelerOnhover} className="trip-button button-wrapclass" type="primary" disabled={this.props.travelerNotified ? true : ((!this.state.checkboxStatus || (this.props.flightStatus !== "green" && flight === true) || (this.props.hotelStatus !== "green" && hotel === true)) && (this.state.travelerBookTickets === false && this.props.bookedByTraveler === false))} onClick={this.sendinfoToTraveller}><b>{this.props.app_labels.sendToTraveler} </b></Button>
                                    </div>
                                </div> : null}
                            </Content >
                        </Layout >
                        <Footer id='footer'></Footer>
                    </Layout >
                </div > : <div className="dark-spinner loader-color"><Spin tip="Loading..." /></div>}
            </div >
        );
    }
}

function mapStateToProps(state, props) {
    return {
        isFetching: state.getparticulartripReducer.isFetching,
        travelerNotified: state.getparticulartripReducer.travelerNotifield,
        tripId: state.createtripReducer.tripId,
        firstname: state.getparticulartripReducer.travellerFirstName,
        lastname: state.getparticulartripReducer.travelerLastName,
        role: state.getparticulartripReducer.travellerType,
        email: state.getparticulartripReducer.travellerEmail,
        cadreId: state.getparticulartripReducer.travellerId,
        accountingCode: state.getparticulartripReducer.accountingCode,
        entityCode: state.getparticulartripReducer.entityCode,
        phno1: state.getparticulartripReducer.phno,
        phno2: state.getparticulartripReducer.phno2,
        from: state.getparticulartripReducer.startdate,
        to: state.getparticulartripReducer.enddate,
        actualStartdate: state.getparticulartripReducer.actualStartdate,
        actualEnddate: state.getparticulartripReducer.actualEnddate,
        destination: state.getparticulartripReducer.destination,
        origin: state.getparticulartripReducer.origin,
        budget: state.getparticulartripReducer.budget,
        flight1: state.getparticulartripReducer.flight,
        flightStatus: state.getparticulartripReducer.flightstatus,
        hotelStatus: state.getparticulartripReducer.hotelstatus,
        flightfile: state.getparticulartripReducer.flightfile,
        hotelfile: state.getparticulartripReducer.hotelfile,
        hotel1: state.getparticulartripReducer.hotel,
        creditCardNo: state.getparticulartripReducer.cardNumber,
        carddetails: state.getparticulartripReducer.creditCardDetails,
        cardAssigned: state.createtripReducer.cardAssigned,
        token: state.authenticationReducer.token,
        sendInfo: state.getparticulartripReducer.sendInfo,
        bookedByTraveler: state.getparticulartripReducer.bookedByTraveler,
        department: state.getparticulartripReducer.department,
        role_freefield: state.getparticulartripReducer.role,
        tripstatusDescription: state.getparticulartripReducer.tripstatusDescription,
        userDetails: state.authenticationReducer.userDetails,
        app_labels: state.adduserReducer.app_labels,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getParticularTrip1: (tripId) => {
            dispatch(getParticularTrip(tripId));
        },
        reDirectto: (flag) => {
            dispatch(reDirectTo(flag));
        },
        sendInfoToTraveller: (travelObj, tripId) => {
            dispatch(sendInfomationToTraveller(travelObj, tripId));
        },
        changeTripStatus: (tripId, status, cadreID) => {
            dispatch(tripStatus(tripId, status, cadreID));
        },
        fetchingComplete: (data) => {
            dispatch(fetchingCompletes(data));
        },
        viewcard: (tripId, setcardDetails, setactionOnFail, otp) => {
            dispatch(viewCard(tripId, setcardDetails, setactionOnFail, otp));
        }

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(CreatetripPending);
