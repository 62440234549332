import React from 'react'
import { Icon } from 'antd'

export default function (props) {
  return (
    <div style={{
      width: '50%',
      position: 'relative',
      margin: 'auto',
      marginTop: '100px',
      textAlign: 'center'
    }}>
      <Icon type="loading" style={{ color: '#112A70', fontSize: '48px' }}/>
    </div>
  )
}