import React from 'react';
import '../../styling/cardsettings.css';
import $ from 'jquery';
import { Select, notification } from 'antd';
import { connect } from 'react-redux';
import axios from 'axios';
const { URLs, errorMessages } = require('../../data/constants.js');
const Option = Select.Option;
var dateFormat = require('dateformat');

class BatchJob extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            runFlag: false,
            selectedJob: "",
            jobDate : null,
            coolOffMsg: ""
        }
    }

    /**
     * Life cycle method, on mount of the component
     */
    componentDidMount() {

        // prepare the icons
        let element = $('.ant-select-arrow-icon')
        element.empty()
        element.removeClass().addClass('icon-Drop-down')
    }
    /**
    * @param  {} element
    *removing the error class applied during validation on change of event
    */
    clearInvalidField(element) {
        let label = $(element).siblings('.text-class')
        label = label.length === 0 ? $(element).find('.text-class') : label
        label = label.length === 0 ? $(element).closest('.form-group').find('.text-class') : label
        label.removeClass('has-error')
    }


    /**
     * On selecting a Job name, set the value of the selected job
     */
    jobChange = (value) => {
        let selector = document.getElementsByClassName("companyClass");
        this.clearInvalidField(selector)
        this.setState({ selectedJob: value, runFlag: false, coolOffMsg: "" })
        this.getBatchDate(value);
    }

    /**
     * Based on selected job name, run the batch using axios request
     */
    runJob = (e) => {
        var selector = document.getElementsByClassName("companyClass");
        [selector].forEach(el => this.clearInvalidField(el))
        if (this.state.selectedJob === "" || this.state.selectedJob === null) {
            let label = $(selector).siblings('.text-class')
            label.addClass('has-error');
            /*  let element = $(selector).find('ant-select-selection__placeholder');
             element.click() 
            message.warning("Please Select Job Role");*/
            notification.warning({
                message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Error </span>{errorMessages.selectJob}</span>,
                duration: 3,
                style: {
                    backgroundColor: '#fffbe6',
                    border: "1px solid #ffe58f",
                }
            });
            return;
        }
        this.setState({ runFlag: true });

        let obj = {
            "jobName": this.state.selectedJob
        }
        axios.post(URLs.comUrl + "callJOB", obj, {
            responseType: 'json',
            headers: {
                "Authorization": 'Bearer ' + this.props.token.access_token
            }
        }).then(response => {
            if (response.data.serviceStatus.statusCode === 200) {
                this.setState({ runFlag: false });
            }
            else if (response.data.serviceStatus.statusCode === 210) {
                this.setState({ runFlag: false });
                /*  message.error(response.data.serviceStatus.message) */
                notification.error({
                    message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Error </span>{response.data.serviceStatus.message}</span>,
                    duration: 0,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }
        }).catch((error) => {
            this.setState({ runFlag: false });
            if (error.response !== undefined && error.response.data !== null && error.response.data !== undefined && error.response.data.serviceStatus !== undefined) {
                notification.error({
                    message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Error </span>{error.response.data.serviceStatus.message}</span>,
                    duration: 0,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }
            else {
                notification.error({
                    message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Error </span>{errorMessages.generic_error}</span>,
                    duration: 0,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }
        })
    }

    getApiRequest(URL, params, headers, callback) {
        axios.get(URL, {
            responseType: 'json',
            headers: headers,
        }).then(response => {
            callback({
                status: true,
                data: response.data
            });
            return;
        }).catch((error) => {
            callback({
                status: false,
                error: error
            });
            if (error.response !== undefined && error.response.data !== null && error.response.data !== undefined && error.response.data.serviceStatus !== undefined) {
                notification.error({
                    message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Error </span>{error.response.data.serviceStatus.message}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }
            else {
                notification.error({
                    message: <span role='alert' aria-live="assertive"><span className="visually-hidden">Error </span>{errorMessages.generic_error}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }
        })
    }

    getBatchDate(jobName){
        let url = URLs.comUrl+"/batch/"+jobName;
        let headers = {
            "Authorization": 'Bearer ' + this.props.token.access_token
        }
        this.getApiRequest(url,null,headers,(response) => {
            if(response.status){
                if(response.data && response.data.data){
                    this.setState({
                        jobDate : response.data.data[0].END_TIME
                    })
                    var todayDate = new Date()
                    todayDate.setHours(todayDate.getHours()-6)
                    if (todayDate < new Date(response.data.data[0].END_TIME)) {
                        this.setState({ 
                            runFlag: true,
                            coolOffMsg: "Please wait for sometime. The cool off period for job run is 6 hours after the last run."
                        })
                    }
                }
            }
        })
    }

    getLastDate(){
        let result = "LAST EXECUTED : ";
        // yyyy-mm-dd | h:MM:ss TT
        let date = dateFormat(this.state.jobDate, 'mmmm dS, yyyy, h:MM:ss TT')
        return this.state.jobDate ? result += date : "";
    }

    render() {
        return (
            <div className="delegatecontainer" style={{ minHeight: "60vh", maxHeight: "80vh" }} placeholder="Please Select Job">
                <div className="row admin_heading">
                    <b>Batch Job</b><br /><hr className="hrStyleclass" />
                </div>
                <div className="row" style={{ marginLeft: "4%" }}>
                    <div className="col-sm-3 cardsetrule">
                        <span className="text-class1">Batch Job Name</span><br /><br />
                        <Select onChange={this.jobChange} className='companyClass' placeholder="Select Batch Job Name" style={{ width: '100%' }}>
                            <Option value="endDateUpdateJob">changeTripStatusByEndDate</Option>
                            <Option value="startDateUpdatejob">changeTripStatusByStartDate</Option>
                            <Option value="startDateUpdateStatusjob">changeTripStatusByStartDateStatus</Option>
                            <Option value="crCallJob">processCRExceptions</Option>
                            <Option value="expenseCategorySyncJob">syncExpenseCategories</Option>
                            <Option value="deleteTokenJob">truncateBlockedTokens</Option>
                            <Option value="transactionUpdateJob">processDummyTransactions</Option>
                        </Select><br></br>
                        <div style = {{padding : "2%",paddingTop : "4%",fontSize : 12,color : "#808080"}}>
                            <span>{this.getLastDate()}</span>
                        </div>
                    </div>
                    <div className="col-sm-1 col-sm-offset-3">
                        <button className="button-createtrip" style={{ marginTop: "40px" }} onClick={this.runJob} disabled={this.state.runFlag}>
                            <b>RUN</b>
                        </button>
                    </div>
                </div>
                <div className="row" style={{ marginLeft: "4%" }}>
                    <div className="col-sm-7 col-sm-offset-5">
                        <span>{this.state.coolOffMsg}</span>
                    </div>
                </div>
            </div>
        )
    }
}
function mapStateToProps(state, props) {
    return {
        token: state.authenticationReducer.token,
        app_labels: state.adduserReducer.app_labels,
    };
}
export default connect(mapStateToProps)(BatchJob);