import React, { Component, Fragment } from 'react';
import { Form, Icon, Input, Button, Row, Col, Alert, Select, Modal } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import history from '../history';
import '../../styling/login.css';
import '../../FontIcons/style.css';
import { errorMessages } from '../../data/constants';
import lock from '../../images/provisioner/elan/lock.svg'
import { Counter } from '../ProvisionerScreen/Counter'
import warningIcon from '../../images/bulk/warningIcon.svg'
const FormItem = Form.Item;
const { app_labels } = require('../../data/english_label');

class ElanResetPassword extends Component {
  constructor(props) {
    super(props)
    this.state = {
      verifyOTPVisible: true,
      otpDigits: [null, null, null, null, null, null],  // XW-61 issue resolve - increased otp digit lenght to 6
      validationError: null,
      activeResend: false,
      user: '',
      btnDisableFlag: true,
      errMsgFlag:false
    }
    this.otpLength = 6 // XW-61 issue resolve - increased otp digit lenght to 6
    this.otpDigitsInputs = [null, null, null, null, null, null]
    this.digit = ["first", "second", "third", "fourth", "fifth", "sixth"]
  }

  /**
    * Lifecycle method
    * will be called onMount of the component
    */
  componentDidMount() {
    this.props.activateAccountStateReset()
    this.props.resetPasswordStateReset()
    setTimeout(() => this.setState({ activeResend: true }), 30000)
    this.saveUser()
    if(window.location.hostname.includes('elan-solutions.elaneasypay'))
            document.title= this.props.registerFlowFlag ? "Set Password: Elan Easy Pay" : "Reset Password: Elan Easy Pay" ;
        
  }

  saveUser() {
    this.props.tempUsername &&
      this.setState({ user: this.props.tempUsername }, () => {
      })
  }

  /** Handles sending of OTP */
  resendCode = () => {
    console.log(this.state.tempUsername)
    if (this.props.tempUsername !== '') {
      let data = {
        username: this.props.tempUsername,
        messageType: "email"
      }
      this.props.sendOTP(data)
      this.setState({ activeResend: false })
      setTimeout(() => this.setState({ activeResend: true }), 30000)
    } else {
      history.push('/login')
    }
  }

  redirectLogin = () => {
    this.props.registerFlowFlag ? history.push('/register') : history.push('/login')
  }


  /** Handles verifying of OTP */
  renderVerifyOTPComponent() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div>
        <Row>
          <Col xs={{ span: 24 }} md={{ span: 20 }} lg={{ span: 18 }}>

            <Form onSubmit={(e) => {
              e.preventDefault();
              let otp = ''
              for (let i = 0; i < this.state.otpDigits.length; i++) {
                let otpDigit = this.props.form.getFieldValue(`otp-digit-${i}`);
                if (!(otpDigit >= '0' && otpDigit <= '9')) {
                  this.setState(() => ({
                    validationError: errorMessages.error_val_otp
                  }))
                  return
                }
                otp += otpDigit
              }
              this.props.verifyOTP({ otp })
              this.setState({ btnDisableFlag: true,errMsgFlag:true })
              for (let i = 0; i < this.state.otpDigits.length; i++) {
                this.props.form.setFieldsValue({
                  [`otp-digit-${i}`]: '',
                });
              }
            }} className="elan-input-otp" >
              {
                (() => {
                  let otpDigitsInputs = []
                  for (let i = 0; i < this.otpLength; i++) {
                    otpDigitsInputs.push(
                      <Col span={4} key={i}> {/* adjusted span for six input field to enter otp*/}
                        <FormItem>
                          {getFieldDecorator(`otp-digit-${i}`)(
                            <Input
                              autoComplete="off"
                              aria-required="true"
                              maxLength="1"
                              aria-label={`OTP ${this.digit[i]} digit`}
                              ref={node => {
                                this.otpDigitsInputs[i] = {
                                  id: i,
                                  node
                                }
                              }}
                              onChange={(e) => {
                                this.clearError()
                                let value = e.target.value
                                this.props.form.setFieldsValue({
                                  [`otp-digit-${i}`]: value,
                                });
                                if (value >= '0' && value <= '9') {
                                  if (i < this.otpDigitsInputs.length - 1) this.otpDigitsInputs[i + 1].node.focus()
                                }
                                (!this.props.form.getFieldValue('otp-digit-0') ||
                                  !this.props.form.getFieldValue('otp-digit-1') ||
                                  !this.props.form.getFieldValue('otp-digit-2') ||
                                  !this.props.form.getFieldValue('otp-digit-3') ||
                                  !this.props.form.getFieldValue('otp-digit-4') ||
                                  !this.props.form.getFieldValue('otp-digit-5'))
                                  ? this.setState({ btnDisableFlag: true })
                                  : this.setState({ btnDisableFlag: false })
                                // (!abc['otp-digit-0'] || !abc['otp-digit-1'] || !abc['otp-digit-2'] || !abc['otp-digit-3'] || !abc['otp-digit-4'] || !abc['otp-digit-5']) ? this.setState({btnDisableFlag: true}) : this.setState({btnDisableFlag: false})
                              }}
                              onKeyUp={(e) => {
                                /* fixed backspace key functionality for otp input fields */
                                (i > 0 && e.keyCode === 8) && this.otpDigitsInputs[i - 1].node.focus()
                              }}
                            />
                          )}
                        </FormItem>
                      </Col>

                    )

                  }
                  return (<>
                    <Row>
                      {otpDigitsInputs}
                    </Row>
                    <Row>
                      <div className={`resend-otp ${!this.state.activeResend ? '' : ''}`}>
                        {!this.state.activeResend && <Icon type="loading" className="count-down" />}
                        {!this.state.activeResend && <Counter />}
                        <button className='link elan-resendBtn'
                          disabled={!this.state.activeResend}
                          onClick={this.resendCode}>{this.props.label_keys.resendCode}</button>
                      </div>
                    </Row>
                  </>
                  )
                })()
              }
              <br />
              <br />             
              <FormItem>
                <Button htmlType="submit" className='loginBtnClass' disabled={this.state.btnDisableFlag}>
                  {this.props.isAuthenticating === true ?
                    (
                      <Fragment>
                        <span>{this.props.label_keys.continue}</span>
                        <Icon type="loading" />
                      </Fragment>
                    ) : <span className='loginBtnText'>{this.props.label_keys.continue}</span>
                  }
                </Button>
              </FormItem>
            </Form>
          </Col>
        </Row>
        <Row>
          <Col xs={{ span: 24 }} md={{ span: 20 }} lg={{ span: 18 }}>
            <div style={{ color: "#C92323" }} role="alert">
              <span className="visually-hidden"> Error: </span>
              {this.state.validationError}
            </div>
          </Col>
        </Row>
      </div>
    )
  }

  /**
  * To clear otp error messages
  */
  clearError() {
    this.setState(() => ({
      validationError: null,
      errMsgFlag:false
    }))
  }

  render() {
    return (
      <div>
        {this.props.verifyOTPError && this.props.verifyOTPError.serviceStatus.message === 'Unable to process now. Please regenerate the OTP' ?
          <Modal className="confirm-visible modalAlign" 
            style={{ display: 'grid', placeContent: 'center',placeItems: 'center',margin: 'auto' }}
            visible={true} 
            title={<b role="heading" aria-level="2">{this.props.label_keys.alert}</b>}
            cancelButtonProps={{ style: { display: 'none' }, disabled: true }} onOk={this.redirectLogin} 
            okButtonProps={{ style: { marginRight: "auto", backgroundColor: "#19398A" } }}
            okText={<b>{this.props.label_keys.ok}</b>} onCancel={this.redirectLogin}>
            <><img style={{ marginLeft: "auto" }} src={warningIcon} alt='error' /></><br /><br />
            {this.props.label_keys.regenerateOtp}
            <><br /><br />
            </>
          </Modal> : null}
        <Row>
          <Col xs={{ span: 24 }} md={{ span: 20 }} lg={{ span: 18 }} >
            {this.props.registerFlowFlag ? <>
              <Row>
                <Col className="headingStyle">{this.props.label_keys.welcomeInstantCard}</Col>
              </Row><br /><br /><br />
              <Row>
                <Col xs={{ span: 24 }} md={{ span: 20 }} lg={{ span: 24 }}>
                  <label className='reset-password-text'  role="heading" aria-level="1">{this.props.label_keys.setPassword}</label>
                </Col>
              </Row>
            </> :
              <><img alt="" src={lock}></img><br /><br />
                <label className='reset-password-text'  role="heading" aria-level="1">{this.props.label_keys.resetPassword}</label><br /></>}
            <label>{this.props.label_keys.pleaseVerificationCode}</label><br /><br />
            <label>{this.props.label_keys.verificationCode}</label>
            {
              this.props.sendOTPError ?
                <div style={{ color: "#C92323" }} role="alert">
                <span className="visually-hidden"> Error: </span>{this.props.sendOTPError.error_description || this.props.sendOTPError.message || errorMessages.otpSendingGenericErrorMessage}</div>
                :
                this.props.verifyOTPError ? /* added condition to check third wrong otp attempt to display error message accordingly*/
                  this.state.verifyOTPVisible && this.props.verifyOTPError.error && 
                  this.props.verifyOTPError.error.code === "authorization_error" ? null
                    : this.state.errMsgFlag && <div style={{ color: "#C92323" }} role="alert">
                    <span className="visually-hidden"> Error: </span>{this.props.verifyOTPError.error_description || this.props.verifyOTPError.serviceStatus.message || this.props.verifyOTPError.message || errorMessages.otpVerificationGenericErrorMessage}</div>
                  : null
            }

          </Col>
        </Row>
        <Row>
          <Col xs={{ span: 24 }} md={{ span: 20 }} lg={{ span: 18 }}>
            {/*  <div className='loginIconDiv'> */} {/* <span><i className="icon-profile userIcon_font" style={{ fontWeight: "lighter", lineHeight: '2' }} /></span> */}
          </Col>

        </Row>
        {
          this.renderVerifyOTPComponent()
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    tempUsername: state.authenticationReducer.tempUsername,
    registerFlowFlag: state.authenticationReducer.registerFlowFlag
  }
}




const WrappedForgorPasswordFormComponent = Form.create()(ElanResetPassword);
export default withRouter(connect(mapStateToProps)(WrappedForgorPasswordFormComponent))
