import React, { Component } from 'react';
import { Form, Icon, Input, Button, Row, Col } from 'antd';
import { Link } from 'react-router-dom'
import '../../FontIcons/style.css';
import { errorMessages, elanRelatedUrls } from '../../data/constants';
import ElanTnC from './ElanTnC'
const FormItem = Form.Item;


class RegisterFormComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            emailId: '',
            tncVisible: false,
            flagUsername: false,
            flagValidation: false,
        }
    }
    componentDidMount() {
        if(window.location.hostname.includes('elan-solutions.elaneasypay'))
            document.title="Register: Elan Easy Pay";
        else
            document.title="Register: US Bank Instant Card";
      }

    handleEmailChange(e){
        if(e.target.value == ""){
            this.setState({flagUsername: true})
        }else{
            this.setState({flagUsername: false})
        }
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            this.state.tncVisible ?
                <ElanTnC
                    label_keys={this.props.label_keys}
                    selectedLang={this.props.selectedLang}
                    sendOTP={(e) => this.props.sendOTP(e)}
                    emailId={this.state.emailId}
                /> :
                <div>
                    <Row>
                        <Col className="headingStyle">{this.props.label_keys.welcomeInstantCard}</Col>
                    </Row><br /><br />
                    <Row>
                        <Col className="loginText"><b role='heading' aria-level='1' >{this.props.label_keys.register}</b></Col>
                    </Row>
                    <Row>
                        <Col className="loginMsgText">{this.props.label_keys.pleaseEnterCreds}</Col>
                    </Row><br />
                    <Row>
                        <Col xs={{ span: 24 }} md={{ span: 20 }} lg={{ span: 18 }}>
                            <Form onSubmit={(e) => {
                                e.preventDefault();
                                this.props.form.validateFields((err, values) => {
                                    if (!err) {
                                        this.setState({ emailId: values.username, tncVisible: true })
                                    }
                                });
                            }}>
                                <FormItem>
                                    {getFieldDecorator('username', {
                                        rules: [{ required: true, message: <div id="username-error" className="form-ele-error"><span><span className="visually-hidden">Error: </span>{this.props.label_keys.enterRegisteredEmail}</span></div> },
                                            //  { pattern: /^\w+([+.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/, message: this.props.label_keys.pleaseValidEmail }],
                                             {
                                                validator: (rule, value, callback) => {
                                                    let v = String(value)
                                                    if (/^\w+([+.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,64})+$/.test(v) !== true) {
                                                        this.setState({flagValidation: true})
                                                        return callback(<div id="username-error-2" className="form-ele-error">
                                                        <span className="visually-hidden">Error: </span>
                                                        {this.props.label_keys.pleaseValidEmail}</div>)
                                                    } else {
                                                        this.setState({flagValidation: false})
                                                        return callback()
                                                    }
                                                }
                                            }],
                                            validateFirst: true
                                        })(
                                        <Input required aria-label="Email Id" 
                                            onChange={e => this.handleEmailChange(e)}
                                            aria-describedby={ (!this.state.flagValidation && !this.state.flagUsername? null :
                                                (this.state.flagValidation && !this.state.flagUsername)?"username-error-2":
                                                 "username-error")} 
                                            className='loginForm' autocomplete="email" placeholder={`${this.props.label_keys.email}*`} />
                                    )}
                                </FormItem>
                                <Row>
                                    <Col span={20}>
                                        <Link to='/login' style={{ color: '#19398A' }}><b>{this.props.label_keys.alreadyRegistered}</b></Link>
                                    </Col>
                                </Row><br /><br /><br />
                                <Row style={{ color: '#19398A' }}>
                                    <Col span={15}>
                                        <Link target="_blank" className='link' to={`//${elanRelatedUrls.securityPolicyUrl}`}  >
                                            <b style={{ textDecoration: 'underline' }}>{this.props.label_keys.securityPrivacyPolicies}</b>
                                            <span className="visually-hidden">&nbsp; opens in new window</span>
                                        </Link>
                                    </Col>
                                </Row><br />
                                <Row>
                                    <FormItem>
                                        <Button htmlType="submit" className="loginBtnClass" disabled={!this.props.form.getFieldValue('username') || this.props.form.getFieldError('username')}>
                                            <span className='loginBtnText'>{this.props.label_keys.proceed}</span>
                                        </Button>
                                    </FormItem>
                                </Row>
                            </Form>
                        </Col>
                    </Row >
                </div >
        )
    }
}


const WrappedOTPValidationFormComponent = Form.create()(RegisterFormComponent);

export default WrappedOTPValidationFormComponent

