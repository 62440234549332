/* eslint-disable no-unused-expressions */
import axios from 'axios';
import { setTheme } from './authenticationActions';
const { URLs, elanRelatedUrls } = require('../data/constants.js')


function multiLanRequest() {
    return {
        type: 'IC_MULTI_LAN_REQUEST'
    }
}

function multiLanRequestSuccess(response) {
    return {
        type: 'IC_MULTI_LAN_REQUEST_SUCCESS',
        response: response,
    }
}

function multiLanRequestComplete() {
    return {
        type: 'IC_MULTI_LAN_REQUEST_COMP'
    }
}

function listofLanguages(response) {
    return {
        type: 'IC_LIST_LANGUAGE',
        response: response
    }
}

export function instantCardMultiLanguage(type) {
    return function (dispatch) {
        let theme = window.location.hostname.includes(elanRelatedUrls.elanUrlCheck) ? 'elan' : 'ic'
        dispatch(setTheme(theme)); 
        dispatch(multiLanRequest());
        return axios.get(URLs.comUrl + 'instantCardMultiLanguage/web/'+type, {
            headers: {
              "Authorization": "Basic Q2FkcmUtV2ViOmNhZHJl"
            }
        })
            .then((response) => {
                dispatch(multiLanRequestComplete());
                return response.data.data;
            })
            .catch((err) => {
                dispatch(multiLanRequestComplete());
                throw err;
            })
    }
}

export function instantCardLabelRetrieve(type) {
    return function (dispatch, getState) {

        dispatch(instantCardMultiLanguage(type))
            .then((response) => {
                dispatch(multiLanRequestSuccess(response));
            }).catch(error => {
                dispatch(multiLanRequestComplete());
            })

    }
}

export function getInstaCardListOfLanguage() {
    return function (dispatch) {
        dispatch(multiLanRequest());
        return axios.get(URLs.comUrl + 'instantCardMultiLanguageList/', {
            headers: {
                "Authorization": "Basic Q2FkcmUtV2ViOmNhZHJl"
            }
        })
            .then((response) => {
                dispatch(multiLanRequestComplete());
                console.log(response.data.data)
                return response.data.data;
            })
            .catch((err) => {
                dispatch(multiLanRequestComplete());
                throw err;
            })
    }
}

export function fetchinstantCardListLangauages() {
    return function (dispatch, getState) {

        dispatch(getInstaCardListOfLanguage())
            .then((response) => {
                console.log(response)
                dispatch(listofLanguages(response));
            }).catch(error => {
                dispatch(multiLanRequestComplete());
            })

    }
}