import { notification } from 'antd';
import React from 'react';
import { loaderFocus } from '../utils/accessability.js';
import axios from 'axios';
import qs from 'qs';
const { URLs, errorMessages } = require('../data/constants.js')
export function cleartripId() {
	return {
		type: "CLEAR_TRIPID",

	}
}
export function settripId(data) {
	return {
		type: "SET_TRIPID",
		data
	}
}
export function reDirectTo(data) {
	return {
		type: "RE_DIRECT",
		data
	}
}
function savetrips(data) {
	return {
		type: "SAVE_TRIP",
		data
	}
}
function cardLimit(data) {
	return {
		type: "CARD_LIMIT",
		data
	}
}
function addtrips(data) {
	return {
		type: "NEWTRIP",
		data
	}
}

/**
 * Creates new Trip using axios post request
 * @param {*} obj 
 * @param {*} updatestate 
 * @param {*} disableOnCardFail 
 */
export function createtrip(obj, updatestate, disableOnCardFail) {
	return (dispatch, getState) => {
		let {
			token
		} = getState().authenticationReducer;

		updatestate(true, false);
		loaderFocus(".loader-color .ant-spin-text");
		axios.post(URLs.tripUrl + 'createTrip', qs.stringify(obj), {
			responseType: 'json',
			headers: {
				"Authorization": 'Bearer ' + token.access_token
			}
		}).then(result => {
			updatestate(false, true);
			if (result.data.serviceStatus.statusCode === 210) {
				if (result.datat.data === undefined && result.data.data.tripId === undefined) {
					updatestate(false, false);
				}
				notification.error({
					message: <span  role='alert' aria-live="assertive" aria-atomic="true" ><span className="visually-hidden">Error </span>{result.data.serviceStatus.message}</span>,
					duration: 3,
					style: {
						backgroundColor: '#fff1f0',
						border: "1px solid #ffa39e",
					}
				});
			}
			else if (result.data.serviceStatus.statusCode === 200) {

				if (result.data.data.redirect) {
					dispatch(addtrips(result.data));
					notification.success({
						message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Success </span>{result.data.serviceStatus.message}</span>,
						duration: 3,
						style: {
							backgroundColor: '#dff0d8',
							color: "green",
							border: "1px solid green",
						}
					});
				}
				else {
					dispatch(savetrips(result.data));
					disableOnCardFail();
					notification.warning({
						message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{result.data.data.warningMessage}</span>,
						duration: 3,
						style: {
							backgroundColor: '#fffbe6',
							border: "1px solid #ffe58f",
						}
					});
				}

			}
			else {
				if (result.data === undefined && result.data.data === undefined && result.data.data.tripId === undefined) {
					updatestate(false, false);
				}
				notification.error({
					message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{result.data.serviceStatus.message}</span>,
					duration: 3,
					style: {
						backgroundColor: '#fff1f0',
						border: "1px solid #ffa39e",
					}
				});
			}
		}).catch((error) => {
			updatestate(false, false);
			/* disableOnCardFail(); */
			if (error.response !== undefined && error.response.data !== null && error.response.data !== undefined && error.response.data.serviceStatus !== undefined) {
				notification.error({
					message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{error.response.data.serviceStatus.message}</span>,
					duration: 3,
					style: {
						backgroundColor: '#fff1f0',
						border: "1px solid #ffa39e",
					}
				});
			}
			else {
				notification.error({
					message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{errorMessages.generic_error}</span>,
					duration: 3,
					style: {
						backgroundColor: '#fff1f0',
						border: "1px solid #ffa39e",
					}
				});
			}
		})
	}
}

/**
 * Saves the created trip 
 */
export function savetrip(obj, saveDraftSpin) {
	saveDraftSpin(true, false)
	loaderFocus(".loader-color .ant-spin-text")
	return (dispatch, getState) => {
		let {
			token
		} = getState().authenticationReducer;
		axios.post(URLs.tripUrl + 'createTrip', qs.stringify(obj), {
			responseType: 'json',
			headers: {
				"Authorization": 'Bearer ' + token.access_token
			}
		}).then(result => {

			if (result.data.serviceStatus.statusCode === 210) {
				saveDraftSpin(false, false)
				notification.error({
					message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{result.data.serviceStatus.message}</span>,
					duration: 3,
					style: {
						backgroundColor: '#fff1f0',
						border: "1px solid #ffa39e",
					}
				});
			}
			else if (result.data.serviceStatus.statusCode === 200) {
				saveDraftSpin(false, true)
				dispatch(savetrips(result.data));
				notification.success({
					message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Success </span>{result.data.serviceStatus.message}</span>,
					duration: 3,
					style: {
						backgroundColor: '#dff0d8',
						color: "green",
						border: "1px solid green",
					}
				});
			}
			else {
				saveDraftSpin(false, false)
				notification.error({
					message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{result.data.serviceStatus.message}</span>,
					duration: 3,
					style: {
						backgroundColor: '#fff1f0',
						border: "1px solid #ffa39e",
					}
				});
			}
		}).catch((error) => {
			saveDraftSpin(false, false)
			if (error.response !== undefined && error.response.data !== null && error.response.data !== undefined && error.response.data.serviceStatus !== undefined) {
				notification.error({
					message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{error.response.data.serviceStatus.message}</span>,
					duration: 3,
					style: {
						backgroundColor: '#fff1f0',
						border: "1px solid #ffa39e",
					}
				});
			}
			else {
				notification.error({
					message: <span  role='alert' aria-live="assertive" aria-atomic="true" ><span className="visually-hidden">Error </span>{errorMessages.generic_error}</span>,
					duration: 3,
					style: {
						backgroundColor: '#fff1f0',
						border: "1px solid #ffa39e",
					}
				});
			}
		})
	}
}

/**
 * Fetches the card limit information using axios get method
 */
export function getCardLimit() {
	return (dispatch, getState) => {
		let {
			token
		} = getState().authenticationReducer;
		axios.get(URLs.comUrl + "getCardLimit", {
			responseType: 'json',
			headers: {
				"Authorization": 'Bearer ' + token.access_token
			}
		}).then(response => {
			if (response.data.serviceStatus.statusCode === 200) {
				if (response.data.data != null) {
					dispatch(cardLimit(response.data.data.cardLimit));
				}
				else {
					dispatch(cardLimit(0));
				}

			}
			else if (response.data.serviceStatus.statusCode === 210) {
				notification.error({
					message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{response.data.serviceStatus.message}</span>,
					duration: 3,
					style: {
						backgroundColor: '#fff1f0',
						border: "1px solid #ffa39e",
					}
				});
			}
			else {
				notification.error({
					message: <span  role='alert' aria-live="assertive" aria-atomic="true" ><span className="visually-hidden">Error </span>{errorMessages.error_cardLimit}</span>,
					duration: 3,
					style: {
						backgroundColor: '#fff1f0',
						border: "1px solid #ffa39e",
					}
				});
			}
		}).catch((error) => {
			if (error.response !== undefined && error.response.data !== null && error.response.data !== undefined && error.response.data.serviceStatus !== undefined) {
				notification.error({
					message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{error.response.data.serviceStatus.message}</span>,
					duration: 3,
					style: {
						backgroundColor: '#fff1f0',
						border: "1px solid #ffa39e",
					}
				});
			}
			else {
				notification.error({
					message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{errorMessages.generic_error}</span>,
					duration: 3,
					style: {
						backgroundColor: '#fff1f0',
						border: "1px solid #ffa39e",
					}
				});
			}
		})
	}
}
