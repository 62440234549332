/* App.js */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Row, Col, Select } from 'antd'
import moment from 'moment';
import GraphIntervalSelect from './GraphIntervalSelect';
const { Option } = Select;

class DashboardChart extends Component {
    constructor(props) {
        super(props);

        this.state = {
            SelectedGraphOption: this.props.userDetails.roles.includes("Provisioner") ? "Weekly" : "Current Week",
            graphDropdown: "weekly",
            interval: null
        };
    }

    handleChange = (event) => {
        console.log("Selected option :::", event, event.target.textContent === "Weekly");
        this.setState({ SelectedGraphOption: event.target.textContent,
        graphDropdown: (event.target.textContent === "Weekly" || event.target.textContent.includes("Week")) ? 'weekly' : 'monthly' }, () => console.log(this.state.graphDropdown))
    }

    componentDidUpdate() {
        let weeklyData = []
        let montlyData = []
        let weeklyInterval
        let monthlyInterval
        let interval
        if (this.props.userDetails.roles.includes("Provisioner") && this.props.graphData) {
            this.props.graphData && this.props.graphData['weekly'].forEach(data => {
                weeklyData.push(data.created)
                weeklyData.push(data.rejected)
            })
            this.props.graphData && this.props.graphData['monthly'].forEach(data => {
                montlyData.push(data.created)
                montlyData.push(data.rejected)
            })
            weeklyInterval = Math.round((Math.max.apply(null, weeklyData) + 5) / 5)
            monthlyInterval = Math.round((Math.max.apply(null, montlyData) + 5) / 5)
            interval = {
                'weekly': weeklyInterval,
                'monthly': monthlyInterval
            }
            interval && (!this.state.interval || this.state.interval['weekly'] !== interval['weekly'] || this.state.interval['monthly'] !== interval['monthly']) && this.setState({ interval })
        } else if (this.props.approvalgraphData) {
            this.props.approvalgraphData && this.props.approvalgraphData['weekly'].forEach(data => {
                weeklyData.push(data.created)
                weeklyData.push(data.rejected)
            })
            this.props.approvalgraphData && this.props.approvalgraphData['monthly'].forEach(data => {
                montlyData.push(data.created)
                montlyData.push(data.rejected)
            })
            weeklyInterval = Math.round((Math.max.apply(null, weeklyData) + 5) / 5)
            monthlyInterval = Math.round((Math.max.apply(null, montlyData) + 5) / 5)
            interval = {
                'weekly': weeklyInterval,
                'monthly': monthlyInterval
            }
            interval && (!this.state.interval || this.state.interval['weekly'] !== interval['weekly'] || this.state.interval['monthly'] !== interval['monthly']) && this.setState({ interval })
        }
    }

    render() {
        return (<>
            {this.props.userDetails.roles.includes("Provisioner") ? <><Row gutter={[16, {xs: 24, sm: 24, lg: 24, xl: 12, xxl: 12}]}>
                <Col span={7}>
                    <div role='heading' aria-level="2" style={{ fontSize: "15px" }} className="graphContent">{this.props.label_keys.cardCreationStatus}</div>
                </Col>
                <Col className="dropDownArrow" xs={{span: 4, offset:7}} sm={{span: 4, offset:7}} md={{span: 4, offset:8}} lg={{span: 4, offset:12}} xl={{span: 4, offset:12}} xxl={{span: 4, offset:12}} >
                    <GraphIntervalSelect className="dropDownArrow-select" 
                    value={this.state.SelectedGraphOption}
                        ariaLabel="Card creation status" week={this.props.label_keys.weekly}
                        month={this.props.label_keys.monthly} onChange={this.handleChange}
                    />
                </Col>
            </Row><br />
                <Row style={{ height: '190px' }}>
                    {this.state.interval &&
                        <Col span={1} style={{ textAlign: 'right' }}>
                            <div className="visually-hidden">Y-axis represents number of Cards</div>
                            <Row>{this.state.interval[this.state.graphDropdown] * 5}</Row>
                            <Row className="margin-space">{this.state.interval[this.state.graphDropdown] * 4}</Row>
                            <Row className="margin-space">{this.state.interval[this.state.graphDropdown] * 3}</Row>
                            <Row className="margin-space">{this.state.interval[this.state.graphDropdown] * 2}</Row>
                            <Row className="margin-space">{this.state.interval[this.state.graphDropdown]}</Row>
                            <Row className="margin-space">0</Row>
                        </Col>}
                    {this.props.graphData && this.state.interval && this.props.graphData[this.state.graphDropdown].map((e) => (
                        <>
                            <Col span={1} offset={this.state.graphDropdown === "weekly" ? 1 : 2} style={{ paddingLeft: '1%' }}>
                                <div className="bar" style={{ height: e.created * (165 / (this.state.interval[this.state.graphDropdown] * 5)), backgroundColor: "#39BF71" }}></div>
                            </Col>
                            <Col span={1}>
                                <div className="bar" style={{ height: e.rejected * (165 / (this.state.interval[this.state.graphDropdown] * 5)), backgroundColor: "#FEAB4C" }}></div>
                            </Col>
                        </>
                    ))}
                </Row>
                <Row style={{ height: '12px', fontSize: '12px' }}>
                    <Col span={2}></Col>
                    <div className="visually-hidden">{`x-axis represents current ${this.state.graphDropdown === "weekly"? "week" : "month"}`}</div>
                    {this.props.graphData && this.props.graphData[this.state.graphDropdown].map((e) => (
                        this.state.graphDropdown === "weekly" ? <Col className="dashboard-chart-x" xs={{span: 3}} sm={{span: 3}} md={{span: 3}} lg={3} xl={3} xxl={3}
                        >
                            <span className="visually-hidden">{e.created> 0 ? `${e.created} cards created`: ''}</span>
                            <span className="visually-hidden">{e.rejected> 0 ? `${e.rejected} cards rejected`: ''}</span>
                            &nbsp;{(moment(e.date, "DD/MM/YYYY").toString()).substring(8, 10)} {(moment(e.date, "DD/MM/YYYY").toString()).substring(4, 7)} {(moment(e.date, "DD/MM/YYYY").toString()).substring(13, 15)} 
                        </Col> : <Col xs={{span: 4}} sm={{span: 4}} md={{span: 4}} lg={4} xl={4} xxl={4}
                        style={{ textAlign: "center",overflow: 'auto' }}><span className="visually-hidden">{e.created> 0 ? `${e.created} cards created`: ''}</span>&nbsp;
                            <span className="visually-hidden">{e.rejected> 0 ? `${e.rejected} cards rejected`: ''}</span>&nbsp;
                           {this.props.label_keys.week} {e.week}</Col>
                    ))}
                </Row></>
                : <><Row gutter={[16, {xs: 24, sm: 24, lg: 24, xl: 12, xxl: 12}]}>
                    <Col xs={{span: 1}} sm={{span: 1}} md={7} lg= {7} xl= {7} xxl= {7}>
                        <div role='heading' aria-level="2" className="graphContent">{this.props.label_keys.cardApprovalStatus}</div>
                    </Col>
                    <Col className="dropDownArrow" xs={{span: 4, offset:12}} sm={{span: 4, offset:12}} md={{span: 4, offset:8}} lg={{span: 4, offset:12}} xl={{span: 4, offset:12}} xxl={{span: 4, offset:12}} >
                        <GraphIntervalSelect className="dropDownArrow-approver" 
                            value={this.state.SelectedGraphOption}
                            ariaLabel="Card approval status" week={this.props.label_keys.currentWeek}
                            month={this.props.label_keys.currentMonth} onChange={this.handleChange}
                        />
                    </Col>
                </Row><br />
                    <Row style={{ height: '190px' }}>
                        {this.state.interval &&
                            <Col span={1} style={{ textAlign: 'right' }}>
                                <div className="visually-hidden">Y-axis represents number of Cards</div>
                                <Row>{this.state.interval[this.state.graphDropdown] * 5}</Row>
                                <Row className="margin-space">{this.state.interval[this.state.graphDropdown] * 4}</Row>
                                <Row className="margin-space">{this.state.interval[this.state.graphDropdown] * 3}</Row>
                                <Row className="margin-space">{this.state.interval[this.state.graphDropdown] * 2}</Row>
                                <Row className="margin-space">{this.state.interval[this.state.graphDropdown]}</Row>
                                <Row className="margin-space">0</Row>
                            </Col>}
                        {this.props.approvalgraphData && this.state.interval && this.props.approvalgraphData[this.state.graphDropdown].map((e) => (
                            <>
                                <Col span={1} offset={this.state.graphDropdown === "weekly" ? 1 : 2} style={{ paddingLeft: '1%' }}>
                                    <div className="bar" style={{ height: e.created * (165 / (this.state.interval[this.state.graphDropdown] * 5)), backgroundColor: "#39BF71" }}></div>
                                </Col>
                                <Col span={1}>
                                    <div className="bar" style={{ height: e.rejected * (165 / (this.state.interval[this.state.graphDropdown] * 5)), backgroundColor: "#FEAB4C" }}></div>
                                </Col>
                            </>
                        ))}
                    </Row>
                    <Row style={{ height: '12px', fontSize: '12px' }}>
                        <Col span={2}></Col>
                        <div className="visually-hidden">{`x-axis represents current ${this.state.graphDropdown === "weekly"? "week" : "month"}`}</div>
                        {this.props.approvalgraphData && this.props.approvalgraphData[this.state.graphDropdown].map((e) => (
                            
                            this.state.graphDropdown === "weekly" ? <Col className="dashboard-chart-x" xs={{span: 3}} sm={{span: 3}} md={{span: 3}} lg={3} xl={3} xxl={3}
                            >
                                <span className="visually-hidden">{e.created> 0 ? `${e.created} cards approved`: ''}</span>&nbsp;
                                <span className="visually-hidden">{e.rejected> 0 ? `${e.rejected} cards rejected`: ''}</span>&nbsp;
                                {(moment(e.day, "DD/MM/YYYY").toString()).substring(8, 10)} {(moment(e.day, "DD/MM/YYYY").toString()).substring(4, 7)} {(moment(e.day, "DD/MM/YYYY").toString()).substring(13, 15)}
                            </Col> : <Col xs={{span: 4}} sm={{span: 4}} md={{span: 4}} lg={4} xl={4} xxl={4}
                        style={{ textAlign: "center",overflow: 'auto' }}><span className="visually-hidden">{e.created> 0 ? `${e.created} cards approved`: ''}</span>&nbsp;
                            <span className="visually-hidden">{e.rejected> 0 ? `${e.rejected} cards rejected`: ''}</span>&nbsp;
                           {this.props.label_keys.week} {e.week}</Col>
                        ))}
                    </Row></>
            }
            <br />
            <Row className="cardContent1" gutter={[16, {xs: 30, sm: 30, lg: 4, xl: 4, xxl: 4}]}>
                <Col span={5}  style={{width: '30%'}}>
                    <span className="dot1"></span>&nbsp; <span className="visually-hidden">Green represents </span>&nbsp;
                    {this.props.userDetails.roles.includes("Provisioner") ? this.props.label_keys.cardsCreated : this.props.label_keys.cardsApproved}
                </Col>
                <Col xs={{span: 5, offset: 6}} sm={{span: 5, offset: 6}} md={{span: 5, offset: 2}} lg={5} xl={5} xxl={5}  style={{width: '30%'}}>
                    <span className="dot2"></span>&nbsp; <span className="visually-hidden">Orange represents </span>&nbsp;
                    {this.props.label_keys.cardsRejected}
                </Col>
            </Row></>
        )
    }
}

function mapStateToProps(state, props) {
    return {
        userDetails: state.authenticationReducer.userDetails,
        token: state.authenticationReducer.token
    }
}

export default connect(mapStateToProps, null)(DashboardChart)