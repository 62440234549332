import React, { Component } from "react";
import ReactDOM from 'react-dom';
import $ from 'jquery';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Table, Input, Button, Popover, Modal, Result, Row, Col, Slider, Icon, Upload, message, notification, Card } from "antd";
import { Spin } from 'antd'
import { Tabs, Tab } from '@mui/material';
import importLogo from "../../images/bulk/importExcel.png"
import downloadTemplate from "../../images/bulk/downloadTemplate.png"
import XLSX from "xlsx";
import CradDetailComponent from './../ProvisionerScreen/CradDetailComponent'
import axios from "axios";
import moment from 'moment-timezone';
import { EditableFormRow, EditableCell } from "./editable";
import isApproveBtn from '../../utils/approveButtonValidator';
import { logOut } from '../../actions/logoutactions'
import { schema } from './validationSchema'
import { addBulkCardData, sentForCardApproval, createCardBulk, validateBulkData, deleteCards, removeBulkData } from '../../actions/bulkcardAction.js';
import { getBulkData } from '../../actions/getBulkData.js';
import { data } from '../phoneData';
import Filter from '../../images/provisioner/filter.png'
import warningIcon from '../../images/bulk/warningIcon.svg'
import Download from '../../images/bulk/Download.svg'
import Edit from '../../images/bulk/Edit@2x.png'
import Delete from '../../images/bulk/Delete@2x.png'
import disableDelete from '../../images/bulk/delete_disable.svg'
import enableDelete from '../../images/bulk/delete_enable.svg'
import prepareTitle,{ loaderFocus,checkBoxStatus } from '../../utils/accessability';
import Tick from '../../images/bulk/tick.svg'

const { TextArea } = Input;
const { Dragger } = Upload;
const { URLs } = require('../../data/constants.js');
const { Search } = Input;

class BulkUploadComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            data1: [],
            cols: [],
            rows: [],
            selectedRow: {},
            passedData: [],
            failedData: [], // validation failed records
            selectedRowKeys: [],
            loading: false,
            activeTab: "1",
            sortedInfo: null,
            editingKey: [],
            errorMessage: null,
            filterTable: null,
            dataSave: false,
            sentForApprPopupVisible: false,
            sentForApprovalModalVisible: false,
            deleteModalVisible: false,
            createCardModalVisible: false,
            uploadFileModalVisible: false,
            modalFilterVisible: false,
            passedFilterTable: null,
            failedFilterTable: null,
            approvalButton: false,
            maxValue: 0,
            minValue: 0,
            left: 0,
            right: 400,
            current: 1,
            TotalPage: (this.props.passedData && this.props.uploadedRecords.length > 0) ? Math.ceil(this.props.uploadedRecords.length / 10) : null,
            size: 1,
            firstTimeLoad: true,
            roleFlag: false,
            roleData: {},
            fileObj: {},
            title: '',
            fileSelected: false,
            visible: false,
            isRequestNo: false,
            previousActiveElement: null,
            sortOrderText: '',
            activeStatusTab: '1',
            uploadedRecords: [],
            inProcessRecords: [],
            completedRecords: [],
            failedRecords: [], //cards creation failed records
            uploadedFilterTable: null,
            inProcessFilterTable: null,
            completedFilterTable: null,
            failedRecordsFilterTable: null,
            display: (this.props.passedData && this.props.passedData.length > 0) && (this.props.uploadedRecords && this.props.uploadedRecords.length > 0) ? true : false,
            cardCreationModalVisible: false,
            prevActiveElement: null,
            detailsVisible: false,
            requestModalVisible: false,
            index: 0,
            delete: false,
            interval: null

        };
        
        this.okBtnRef = React.createRef();
        this.cancelBtnRef = React.createRef();
        this.modalBtnRef = React.createRef();
        this.modalOkBtnRef = React.createRef()
    }

    componentDidMount() {
        this.getCountryCodeData();
        
        if (window.location.hostname.includes('elan-solutions.elaneasypay'))
            document.title = "Bulk card: Elan Easy Pay";
        else
            document.title = "Bulk card: US Bank Instant Card";

        window.addEventListener("dragover", function (e) {
            // e = e || event;
            e.preventDefault();
        }, false);
        window.addEventListener("drop", function (e) {
            // e = e || event;
            e.preventDefault();
        }, false);

        if ((this.props.passedData && this.props.passedData.length > 0)) {
            this.modifySelectAllCheckBoxAriaLabel();
            // setTimeout(() => {
            //     const div = document.querySelectorAll('.ant-checkbox-input');

            //     if (div[0]) {
            //         div.forEach(el => {
            //             if (!el.getAttribute('aria-label')) {
            //                 el.setAttribute('aria-label', 'Select all');
            //                 el.setAttribute('id', 'Select-all-checkbox');
            //             }
            //         })
            //     }


            //     // this.modalBtnRef.current.buttonNode.focus();
            // }, 500)
        }

        if(!this.state.interval && this.props.ReqFlag && this.props.inProcessRecords && this.props.inProcessRecords.length>0){
            setTimeout(() => {
                console.log("--------------------Start timer after component mount-------------------------------");
                this.setAPICallTimer()
            }, 1000);

        }


    }
    componentWillUnmount() {
        this.props.removeBulkDataFunc();
        clearInterval(this.state.interval);
        this.setState({interval: null});
    }

    setAPICallTimer = () =>{
            console.log("---timer----", this.state.interval)
            let timer = setInterval(() => {
                console.log("Executed after 5 sec",  this.props.requestNo, this.state.interval);
                if(this.props.inProcessRecords && this.props.inProcessRecords.length === 0){
                    clearInterval(this.state.interval);
                    this.setState({interval: null});
                    console.log('--------Timer cleared----------', this.state.interval);
                }
                this.props.getBulkDataOnMount(this.props.label_keys, this.props.requestNo);
    
            }, 5000);
            console.log('----timer2----', timer);
            this.setState({interval: timer});

        

        // this.setState({interval : timer});
    }

    modifySelectAllCheckBoxAriaLabel = () => {
        let uploadTabPanel = $("#pass-tab-panel #upload-tab-panel table th input[type='checkbox']");
        let failedTabPanel = $("#pass-tab-panel #failed-tab-panel table th input[type='checkbox']");
        let failTabPanel2 = $("#fail-tab-panel table th input[type='checkbox']");

        uploadTabPanel && uploadTabPanel.attr('aria-label', 'Select all validation passed uploaded cards') ;
        failedTabPanel && failedTabPanel.attr('aria-label', 'Select all validation passed failed cards');
        failTabPanel2 && failTabPanel2.attr('aria-label', 'Select all validation failed cards');
    }
    handleBack = () => {
        if(this.state.requestModalVisible === true){
            this.setState({requestModalVisible: false})
        }
        this.props.handleIsBack(false);
        this.onClose();
    }

    openDeleteModal = () => {
        this.setState({ deleteModalVisible: true })
    }

    /** Filter Methods */
    getMaxCardLimitValue = () => {
        let currentData;
        if (this.state.activeTab === '1') {
            if (this.state.activeStatusTab === '1')
                currentData = this.props.uploadedRecords;
            if (this.state.activeStatusTab === '2')
                currentData = this.props.inProcessRecords;
            if (this.state.activeStatusTab === '3')
                currentData = this.props.failedRecords;
            if (this.state.activeStatusTab === '4')
                currentData = this.props.completedRecords;
        }
        else
            currentData = this.props.failedData;

        // const currentData = this.state.activeTab === '1' ? this.props.passedData : this.props.failedData;
        let maxValue = Math.max.apply(null,
            currentData.map(function (o) { return o.creditLimit; }));
        return maxValue;
    }

    getMinCardLimitValue = () => {
        let currentData;
        if (this.state.activeTab === '1') {
            if (this.state.activeStatusTab === '1')
                currentData = this.props.uploadedRecords;
            if (this.state.activeStatusTab === '2')
                currentData = this.props.inProcessRecords;
            if (this.state.activeStatusTab === '3')
                currentData = this.props.failedRecords;
            if (this.state.activeStatusTab === '4')
                currentData = this.props.completedRecords;
        }
        else
            currentData = this.props.failedData;
        // const currentData = this.state.activeTab === '1' ? this.props.passedData : this.props.failedData;
        let minValue = Math.min.apply(null,
            currentData.map(function (o) { return o.creditLimit; }));
        return minValue;
    }

    cardDetails() {

        this.setState({
            prevActiveElement: document.activeElement,
            detailsVisible: !this.state.detailsVisible
        })


        console.log("Active element ::: ", this.state.prevActiveElement, document.activeElement);
    }

    showFilter = () => {
        let maxValue = this.getMaxCardLimitValue();
        let minValue = this.getMinCardLimitValue();
        this.setState({ minValue: minValue, maxValue: maxValue, modalFilterVisible: true });
        // setTimeout(() => { document.getElementsByClassName('ant-slider-handle')[0].focus() }, 500)
    }

    handleFilterCancel = () => {
        this.setState({ modalFilterVisible: false });
    };

    filterData = () => {
        let data;
        if (this.state.activeTab === '1') {
            if (this.state.activeStatusTab === '1')
                data = this.props.uploadedRecords;
            if (this.state.activeStatusTab === '2')
                data = this.props.inProcessRecords;
            if (this.state.activeStatusTab === '3')
                data = this.props.failedRecords;
            if (this.state.activeStatusTab === '4')
                data = this.props.completedRecords;
        }
        else
            data = this.props.failedData;

        // let data = this.state.activeTab === '1' ? this.props.passedData : this.props.failedData;
        if (data.length > 0) {
            let filterData =
                data.filter(
                    d => this.state.left <= d.creditLimit && d.creditLimit <= this.state.right
                );
            // this.state.activeTab === '1' ?
            //     this.setState({ modalFilterVisible: false, passedFilterTable: filterData }) :
            //     this.setState({ modalFilterVisible: false, failedFilterTable: filterData })

            if (this.state.activeTab === '1') {
                if (this.state.activeStatusTab === '1')
                    this.setState({ modalFilterVisible: false, uploadedFilterTable: filterData });
                if (this.state.activeStatusTab === '2')
                    this.setState({ modalFilterVisible: false, inProcessFilterTable: filterData });
                if (this.state.activeStatusTab === '3')
                    this.setState({ modalFilterVisible: false, failedRecordsFilterTable: filterData });
                if (this.state.activeStatusTab === '4')
                    this.setState({ modalFilterVisible: false, completedFilterTable: filterData });
            }
            else
                this.setState({ modalFilterVisible: false, failedFilterTable: filterData });
        } else {
            (message.error({ content: <span role="alert" ><span className="visually-hidden">Error </span>{this.props.label_keys.dataUnavailable}</span> })
            )
        }
    }

    setCreateCardModalVisible = () => {
        this.setState({ cardCreationModalVisible: false });
        setTimeout(() => {
            $('.focus-heading').attr('tabIndex','-1').focus();    
            }, 100);
        // if (document.getElementById('pass-tab-div'))
        //     document.getElementById('pass-tab-div').focus();
        if(!this.state.interval){
            setTimeout(() => {
                console.log("--------------------Start timer after cards sent for creation-------------------------------");
                this.setAPICallTimer()
            }, 100);

        }
    }

    setSentForApprovalModalVisible = () => {
        this.setState({ sentForApprovalModalVisible: false });
        if (document.getElementById('pass-tab-div'))
            document.getElementById('pass-tab-div').focus();
        if(!this.state.interval){
            setTimeout(() => {
                console.log("--------------------Start timer after send for approval-------------------------------");
                this.setAPICallTimer()
            }, 100);
        }
    }

    updateDetails = () => {
        this.fetchActiveCardDetails()
    }

    fetchActiveCardDetails() {
        this.setState({ isLoading: true })
        axios.get(URLs.cardUrl + `/getActiveCardsData`, {
            headers: {
                "Authorization": 'Bearer ' + this.props.token.access_token
            }
        }).then(res => {
            res.data.data &&
                this.setState({
                    data: res.data.data,
                    data1: res.data.data,
                    activeCardCount: res.data.data.length,
                    TotalPage: (Math.ceil(res.data.data.length / 10)),
                    isLoading: false
                })
                console.log(res.data.data)
        })
            .catch(error => {
                this.setState({ isLoading: false })
                if (error.response !== undefined && error.response.data !== null && error.response.data !== undefined && error.response.data.serviceStatus !== undefined) {
                    notification.error({
                        message: <span role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{this.props.label_keys.couldNotcompleteReq}</span>,
                        duration: 3,
                        style: {
                            backgroundColor: '#fff1f0',
                            border: "1px solid #ffa39e",
                        }

                    });

                }
                else {
                    notification.error({
                        message: <span role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{this.props.label_keys.couldNotcompleteReq}</span>,
                        duration: 3,
                        style: {
                            backgroundColor: '#fff1f0',
                            border: "1px solid #ffa39e",
                        }
                    });
                }
            }

            )
    }

    uploadFileModalVisibleFunc = () => {
        this.setState({ uploadFileModalVisible: false });
        const div = document.getElementById('pass-tab-div');
        div.focus();
    }
    // Accessbility To invoke click method on tab enter.
    onKeyDown = (event) => {
        if (event.keyCode === 13) {
            event.preventDefault()
            document.activeElement.click();
        }
    }
    //For Accessability to make defalut focus on tabbing 
    defaultFocus = () => {
        if (ReactDOM.findDOMNode(this.refs.passItem) && this.state.firstTimeLoad) {
            ReactDOM.findDOMNode(this.refs.passItem).focus();
            this.setState({ firstTimeLoad: false })
        }
    }


    /** Search Methods */
    search = value => {
        const { rows } = this.state;
        let searchData;
        if (this.state.activeTab === '1') {
            if (this.state.activeStatusTab === '1')
                searchData = this.props.uploadedRecords;
            if (this.state.activeStatusTab === '2')
                searchData = this.props.inProcessRecords;
            if (this.state.activeStatusTab === '3')
                searchData = this.props.failedRecords;
            if (this.state.activeStatusTab === '4')
                searchData = this.props.completedRecords;
        }
        else
            searchData = this.props.failedData;

        // const searchData = this.state.activeTab === '1' ? this.props.passedData : this.props.failedData;
        const filterTable = searchData.filter(o =>
            Object.keys(o).some(k =>
                String(o[k])
                    .toLowerCase()
                    .includes(value.toLowerCase())
            )
        );
        // this.state.activeTab === '1' ?
        //     this.setState({ passedFilterTable: filterTable, TotalPage: (Math.ceil(filterTable.length / this.state.size)), current: 1 }) :
        //     this.setState({ failedFilterTable: filterTable, TotalPage: (Math.ceil(filterTable.length / this.state.size)), current: 1 })
        if (this.state.activeTab === '1') {
            if (this.state.activeStatusTab === '1')
                this.setState({ TotalPage: (Math.ceil(filterTable.length / this.state.size)), current: 1, uploadedFilterTable: filterTable });
            if (this.state.activeStatusTab === '2')
                this.setState({ TotalPage: (Math.ceil(filterTable.length / this.state.size)), current: 1, inProcessFilterTable: filterTable });
            if (this.state.activeStatusTab === '3')
                this.setState({ TotalPage: (Math.ceil(filterTable.length / this.state.size)), current: 1, failedRecordsFilterTable: filterTable });
            if (this.state.activeStatusTab === '4')
                this.setState({ TotalPage: (Math.ceil(filterTable.length / this.state.size)), current: 1, completedFilterTable: filterTable });
        }
        else
            this.setState({ TotalPage: (Math.ceil(filterTable.length / this.state.size)), current: 1, failedFilterTable: filterTable });
    };

    /**Delete methods */
    handleDelete = () => {
        let needDeletedCardJson = {};
        needDeletedCardJson.deletedIds = [];
        this.setState({ visible: false, title: "", fileSelected: false });
        if (this.state.selectedRowKeys && this.state.selectedRowKeys.length > 0) {
            for (let i = 0; i < this.state.selectedRowKeys.length; i++) {
                needDeletedCardJson.deletedIds.push(this.state.selectedRowKeys[i]);
            }
            this.props.deleteCardFunc(needDeletedCardJson, this.props.userDetails.userId, this.updateState, this.props.label_keys);
            this.setState({ selectedRowKeys: [], delete: true });
            
        } else {
            (message.error({ content: <span role="alert" ><span className="visually-hidden">Error </span>{this.props.label_keys.selectRecordDeletion}</span> }))
        }
        this.setState({ deleteModalVisible: false });
        setTimeout(() => {
        $('.focus-heading').attr('tabIndex','-1').focus();    
        }, 3500);
    };

    /**Editable Grid methods */
    isEditing = record => {
        for (let i = 0; i < this.state.editingKey.length; i++) {
            if (record.key === this.state.editingKey[i])
                return true;
        }
    }

    edit(key) {
        this.setState({ editingKey: key })
    }

    onSelectChange = (selectedRowKeys) => {
        let result = [];
        this.setState({ selectedRowKeys });
        if (this.state.editableMode === true) {
            let editingkeyAdd = [];
            for (let i = 0; i < selectedRowKeys.length; i++) {
                editingkeyAdd.push(selectedRowKeys[i]);
            }
            this.edit(editingkeyAdd);
        }
        if (this.state.activeTab === '1') {
            for (let i = 0; i < selectedRowKeys.length; i++) {
                result.push(this.props.passedData.find(obj => {
                    return obj.key === selectedRowKeys[i];
                }))
            }
            let approvalThresholds = this.props.userDetails.approvalThresholds;
            let approvalFlowEnabled = this.props.userDetails.bulkCardApproval;
            if (approvalFlowEnabled) {
                if (isApproveBtn(approvalThresholds, this.props.userDetails.cardLimitRule, this.props.userDetails.cardValidityRule, result))
                    this.setState({ approvalButton: true });
                else
                    this.setState({ approvalButton: false });
            }
        }
    }

    handleEditMode = () => {
        if (this.state.selectedRowKeys.length > 0) {
            this.setState({ editableMode: true });
            let editingkeyAdd = [];
            for (let i = 0; i < this.state.selectedRowKeys.length; i++) {
                editingkeyAdd.push(this.state.selectedRowKeys[i]);
            }
            this.edit(editingkeyAdd);
        }
        else
            (message.error({ content: <span role="alert" ><span className="visually-hidden">Error </span>{this.props.label_keys.selectRecordToEdit}</span> }))

    }

    handleSave = row => {
        const activeData = this.state.activeTab === '1' ? this.state.passedData : this.state.failedData;
        const index = activeData.findIndex(item => row.key === item.key);
        const item = activeData[index];
        activeData.splice(index, 1, {
            ...item,
            ...row
        });
        activeData.mobileNumber = row.mobileNumber;
        activeData.cCode = row.cCode;
        this.state.activeTab === '1' ? this.setState({ passedData: activeData }) : this.setState({ failedData: activeData });
    };

    handleCancelEditMode = () => {
        this.setState({ editableMode: false });
        this.edit({});
        setTimeout(() => {
            $('.edit-button-focus').focus();
        }, 100);
    }


    handleDownload = (data) => {
        const newData = data.map(obj => {
            return {
                'FIRSTNAME': obj.firstName,
                'LASTNAME': obj.lastName,
                'EMAIL': obj.userEmail,
                'PHONE NUMBER': obj.mobileNumber,
                'VALID TO': obj.validTo,
                'CARD LIMIT': obj.cardLimit,
                'FIELD1': obj.field1,
                'FIELD2': obj.field2,
                'NOTES': obj.notes,
                'EMPLOYEE ID': obj.employeeId,
                'FAILURE REASON': obj.failureReason
            }
        })
        let binaryWS = XLSX.utils.json_to_sheet(newData);
        let wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, binaryWS, 'Failed Records')
        XLSX.writeFile(wb, 'failedRecords.xlsx');
    }
    handleDownloadReport = () => {
        let data;
        if (this.state.roleData) {
            data = this.state.roleData
        }
        const newData = data.map(obj => {
            return {
                'FIRSTNAME': obj.FIRST_NAME,
                'LASTNAME': obj.LAST_NAME,
                'EMAIL': obj.EMAIL,
                'PHONE NUMBER': (obj.PHONE_NUMBER).replace("@", " "),
                'VALID TO': obj.CARD_VALID_TO,
                'CARD LIMIT': obj.CREDIT_LIMIT,
                'FIELD1': obj.FIELD1,
                'FIELD2': obj.FIELD2,
                'NOTES': obj.NOTES,
                'EMPLOYEE ID': obj.EMPLOYEE_ID,
            }
        })
        let binaryWS = XLSX.utils.json_to_sheet(newData);
        let wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, binaryWS, 'Failed Records')
        XLSX.writeFile(wb, 'failedRecords.xlsx');
    }

    handleDownloadErrorReport = () => {
        let data;
        if (this.props.failedRecords) {
            data = this.props.failedRecords
        }
        const newData = data.map(obj => {
            return {
                'FIRSTNAME': obj.name,
                'LASTNAME': obj.lastName,
                'EMAIL': obj.emailId,
                'PHONE NUMBER': (obj.mobileNumber),
                'VALID TO': obj.validTill,
                'CARD LIMIT': obj.creditLimit,
                'FIELD1': obj.field1,
                'FIELD2': obj.field2,
                'NOTES': obj.notes,
                'EMPLOYEE ID': obj.employeeId,
            }
        })
        let binaryWS = XLSX.utils.json_to_sheet(newData);
        let wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, binaryWS, 'Failed Records')
        XLSX.writeFile(wb, 'failedRecords.xlsx');
    }


    handleChange = (pagination, filters, sorter) => {
        console.log("Changesss ::::", sorter);
        this.setState({
            filteredInfo: filters,
            sortedInfo: sorter,
            sortOrderText: `Table sorted by ${sorter.columnKey}, in ${sorter.order}ing order`,
        });
    }

    /** Validation checks */
    checkValidFromDate = record => {
        let today = new Date();
        let date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
        if (!/^\d{4}-\d{2}-\d{2}$/.test(record['validFrom'])) //yyyyy-mm-dd
            return false;
        else {
            let fromDate = moment(record['validFrom']);
            let toDate = moment(date);
            let days = toDate.diff(fromDate, 'days');
            return days > 0 ? false : true;
        }
    }

    checkValidTillDate = record => {
        let today = new Date(moment(new Date()).tz('America/Chicago').format('MM/DD/YYYY'));
        let date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
        if (!/^\d{4}-\d{2}-\d{2}$/.test(record['validTill'])) //yyyyy-mm-dd
            return false;
        else {
            let tillDate = moment(record['validTill']);
            let toDate = moment(date);
            let days = toDate.diff(tillDate, 'days');
            return days > 0 ? false : true;
        }
    }

    checkValidDateFeild = record => {
        let fromDate = moment(record['validFrom']);
        let tillDate = moment(record['validTill']);
        let days = tillDate.diff(fromDate, 'days');
        return days > 0 && days <= 365 ? true : false;
    }

    checkDatesValidity = record => {
        //if (this.checkValidFromDate(record))
        if (this.checkValidTillDate(record))
            if (this.checkValidDateFeild(record)) {
                return true;
            }
        return false;
    }

    getCountryCodeData = () => {
        let values = Object.values(data);
        let countryCodesList = values.map(x => x.code);
        this.setState({ cCode: countryCodesList });
    }

    updateState = (value) => {
        this.setState({ dataSave: value });
    }

    updateRole = (data) => {
        if (data) {
            this.setState({ roleData: data, roleFlag: true })
        }
    }

    updateSentForApproval = (value) => {
        this.setState({ sentForApprPopupVisible: value });
    }

    checkFile(file) {
        let errorMessage = "";
        if (!file || !file[0]) {
            return;
        }
        const isExcel =
            file[0].type === "application/vnd.ms-excel" ||
            file[0].type ===
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        if (!isExcel) {
            errorMessage = this.props.label_keys.excelFileOnly;
        }
        const isLt2M = file[0].size / 1024 / 1024 < 2;
        if (!isLt2M) {
            errorMessage = this.props.label_keys.fileMustBeSmaller;
        }
        return errorMessage;
    }

    uploadFile = () => {
        this.setState({ uploadFileModalVisible: true, delete: false })
        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString;
        let today = new Date(moment(new Date).tz('America/Chicago').format('MM/DD/YYYY'));
        let todayDate = (today.getDate()) > 9 ? today.getDate() : '0' + today.getDate();
        let date = today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + todayDate;
        reader.onload = e => {
            let resultarray;
            let requiredArray = [];
            let data = e.target.result;
            if (!rABS) data = new Uint8Array(data);
            const newData = XLSX.read(data, { type: rABS ? 'binary' : 'array' });
            newData.SheetNames.forEach(function (sheetName) {
                resultarray = XLSX.utils.sheet_to_json(newData.Sheets[sheetName], { raw: false, header: 1 });
            });
            for (let i = 2; i < resultarray.length; ++i) {
                if ((resultarray[i][0] || resultarray[i][1] || resultarray[i][2] || resultarray[i][3])) {
                    let obj = {
                        'key': i - 1,
                        'name': (!resultarray[i][0]) ? '---' : resultarray[i][0],
                        'lastName': (!resultarray[i][1]) ? '---' : resultarray[i][1],
                        'emailId': (!resultarray[i][2]) ? '---' : resultarray[i][2],
                        'cCode': (!resultarray[i][3]) ? '---' : resultarray[i][3].includes('+') ? resultarray[i][3]: "+"+resultarray[i][3],
                        'mobileNumber': (!resultarray[i][4]) ? '---' : resultarray[i][4],
                        'validFrom': date,
                        'validTill': (!resultarray[i][5]) ? '---' : resultarray[i][5],
                        'creditLimit': (!resultarray[i][6]) ? '---' : resultarray[i][6],
                        'field1': (!resultarray[i][7]) ? '' : resultarray[i][7],
                        'field2': (!resultarray[i][8]) ? '' : resultarray[i][8],
                        'notes': (!resultarray[i][9]) ? '' : resultarray[i][9],
                        'employeeId': (!resultarray[i][10]) ? '' : resultarray[i][10],

                    }
                    requiredArray.push(obj);
                }
            }
            if (resultarray.length > 502) {
                //TODO : need top add globalization
                message.error({ content: <span role="alert" ><span className="visually-hidden">Error </span>{this.props.label_keys.exceedRecordMsg}</span> })

                return false;
            }
            else if (requiredArray.length === 0) {
                message.error({ content: <span role="alert" ><span className="visually-hidden">Error </span>{this.props.label_keys.dataNotFoundInFile}</span> });
                return false;
            } else {
                let failedObject = [];
                let passedObject = [];
                for (let i = 0; i < requiredArray.length; i++) {
                    if (this.getPassedElements(requiredArray[i]) && this.checkDatesValidity(requiredArray[i])) {
                        passedObject.push(requiredArray[i]);
                    } else {
                        failedObject.push(requiredArray[i]);
                    }
                }
                const passedJson = passedObject.map(v => ({ ...v, isPassed: true, mobileNumber: v.cCode + "@" + v.mobileNumber }));
                const failedJson = failedObject.map(v => ({ ...v, isPassed: false, mobileNumber: v.cCode + "@" + v.mobileNumber }));
                let requestJson = {
                    requester_id: this.props.userDetails.userId,
                    provisioner_id: this.props.userDetails.userId,
                    company_id: this.props.userDetails.companyId,
                    type: 'Bulk',
                    title: this.state.title,
                    cardDetails: [...passedJson, ...failedJson]
                };
                const obj = this.props.addData(requestJson, this.updateState, this.props.label_keys);
                this.setState({ isRequestNo: true, display: passedJson.length > 0 ? true : false, TotalPage: passedJson.length > 0 ? Math.ceil(passedJson.length / 10) : null })
                this.props.handleBackBtn(true)
            }
        };
        if (rABS) reader.readAsBinaryString(this.state.fileObj);
        else reader.readAsArrayBuffer(this.state.fileObj);
        return false;
    }

    fileHandler = fileList => {
        let fileObj = fileList;
        // console.log("File Obj ::", fileObj);
        if (!fileObj) {
            this.setState({
                errorMessage: this.props.label_keys.noFileUploaded
            });
            return false;
        }
        if (
            !(
                fileObj.type === "application/vnd.ms-excel" ||
                fileObj.type ===
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            )
        ) {
            this.setState({
                errorMessage: this.props.label_keys.onlyExcelFiles
            });
            message.error({ content: <span role="alert" ><span className="visually-hidden">Error </span>{this.props.label_keys.onlyExcelFiles}</span> });

            return false;
        }

        this.setState({ fileObj, fileSelected: true });

    };

    handleValidate = (option) => {
        let selectedKeys = this.state.selectedRowKeys;
        let newPassedData = {};
        let apiData = {};
        let newFailedData = 0;
        let newPassedDataTxt = 0;
        let { failedData } = this.props;
        let apiReqData = {};
        apiReqData.data = [];
        newPassedData.data = [];
        /** Note: noMessage setting flag false will show the Req messages on screen for saveRequestData api req
        **/
        let noMessage = false;
        for (let i = 0; i < selectedKeys.length; i++) {
            var result = failedData.find(obj => {
                return obj.key === selectedKeys[i];
            })
            if (this.getPassedElements(result) && this.checkDatesValidity(result)) {
                result.isPassed = true;
                newPassedData.data.push(result);
                apiReqData.data.push(result);
                newPassedDataTxt = newPassedDataTxt + 1;
            }
            else {
                newFailedData = newFailedData + 1;
            }
        };

        let errorMessageString = "<br/>" +this.props.label_keys.validationPassedFor+"<font color='green'> <b> " + newPassedDataTxt + "</b> </font>"+ this.props.label_keys.records + "<br /><br />" + this.props.label_keys.validationFailedFor +"<font color='red'> <b>" + newFailedData + " </b> </font>"+this.props.label_keys.records;
        Modal.error({
            title: <b role='heading' aria-level='2' id="alert">{this.props.label_keys.alert}</b>,
            content: <p dangerouslySetInnerHTML={{ __html: errorMessageString }} />,
            onOk: () => {
                // if (document.getElementById('fail-tab'))
                //     document.getElementById('fail-tab').focus();
                $('.focus-heading').attr('tabIndex','-1').focus();
            }
        });
        setTimeout(() => {
            const errorDiv = document.querySelector('.ant-modal-confirm-error');
            const closeIcon = document.querySelectorAll('.ant-modal-confirm-error .anticon.anticon-close-circle');
            if (errorDiv) {
                errorDiv.parentNode.setAttribute("aria-labelledby", "alert");
            }
            if(closeIcon[0]){
                closeIcon[0].setAttribute('aria-label', 'Error');
                closeIcon[0].setAttribute('role', 'img');
            }
        }, 100)
        if (newPassedData.data.length > 0 && option !== 'Validate') {
            let passedJson = {};
            passedJson.data = [];
            passedJson.data = newPassedData.data.map(v => ({ ...v, mobileNumber: v.cCode + "@" + v.mobileNumber }));
            this.props.validateBulkDataFun(passedJson, this.props.userDetails.userId, this.updateState, this.props.label_keys, noMessage);
        }
        if (option !== 'Validate') {
            this.setState({ editableMode: false, selectedRowKeys: [] });
            this.edit({});
        }
    }

    getPassedElements = (record) => {
        let count = 0;
        let errors = Object.keys(schema).filter(function (key) {
            if (record && schema[key](record[key])) {
                count = count + 1;
            }
        });
        return count === 13 ? true : false;
    }

    handleCancel = () => {
        this.setState({ selectedRowKeys: [] });
    }

    OnSizeChange = (e) => {
        this.OnPageSizeChange(1, e.target.value, this.props.uploadedRecords);
    }

    changeStatusTab = (value) => {
        let currentData;
        if (value === '1')
            currentData = this.props.uploadedRecords;
        if (value === '2')
            currentData = this.props.inProcessRecords;
        if (value === '3')
            currentData = this.props.failedRecords;
        if (value === '4')
            currentData = this.props.completedRecords;
        this.setState({
            activeStatusTab: value,
            display: value === '2' || value === '4' ? false : currentData.length > 0 ? true : false,
            editableMode: false,
            selectedRowKeys: this.state.selectedRowKeys.length > 0 && [],
            left: 0,
            right: 400,
            passedFilterTable: null,
            failedFilterTable: null,
            uploadedFilterTable: null,
            inProcessFilterTable: null,
            completedFilterTable: null,
            failedRecordsFilterTable: null,
        }, () => this.pageCountSetter(currentData));

        this.edit({});

        if (value === '3') {
        this.modifySelectAllCheckBoxAriaLabel();
            // setTimeout(() => {
            //     const div = document.querySelectorAll('.ant-checkbox-input');

            //     if (div[0]) {
            //         div.forEach(el => {
            //             if (!el.getAttribute('aria-label')) {
            //                 el.setAttribute('aria-label', 'Select all');
            //                 el.setAttribute('id', 'Select-all-checkbox');
            //             }
            //         })
            //     }

            //     // this.modalBtnRef.current.buttonNode.focus();
            // }, 500)
        }

    }

    changeTab = (value, currentData) => {
        this.setState({
            activeTab: value,
            activeStatusTab: '1',
            editableMode: false,
            display: value === '2' ? false : this.props.uploadedRecords.length > 0 ? true : false,
            selectedRowKeys: this.state.selectedRowKeys.length > 0 && [],
            left: 0,
            right: 400,
            passedFilterTable: null,
            failedFilterTable: null,
            uploadedFilterTable: null,
            inProcessFilterTable: null,
            completedFilterTable: null,
            failedRecordsFilterTable: null,
            current: 1
        }, () => { (value === '2') && this.pageCountSetter(currentData) });

        this.edit({});

        if (value === '2') {
            setTimeout(() => {
                const div = document.querySelectorAll('.ant-checkbox-input');

                if (div[0]) {
                    div.forEach(el => {
                        if (!el.getAttribute('aria-label')) {
                            el.setAttribute('aria-label', 'Select all');
                            el.setAttribute('id', 'Select-all-checkbox');
                        }
                    })
                }

                // this.modalBtnRef.current.buttonNode.focus();
            }, 500)
        }
    };


    /**Form submission methods */
    submitForApproval = () => {
        this.state.selectedRowKeys.length === 0 &&
            message.error({ content: <span role="alert"><span className="visually-hidden">Error </span>{this.props.label_keys.selectRecordForApproval}</span> });

        let needApprovalJson = {};
        needApprovalJson.card_ids = [];
        let { passedData } = this.props;
        let selectedKeys = this.state.selectedRowKeys;
        let newPassedData = {};
        /** Note: noMessage setting flag true will not show the Req messages on screen for saveRequestData api req
        **/
        let noMessage = true;
        newPassedData.data = [];

        for (let i = 0; i < selectedKeys.length; i++) {
            var result = passedData.find(obj => {
                return obj.key === selectedKeys[i];
            })
            result.mobileNumber = result.cCode + "" + result.mobileNumber;
            newPassedData.data.push(result);
        };
        //this.props.validateBulkDataFun(newPassedData, this.props.userDetails.userId, this.updateState, this.props.label_keys,noMessage);
        for (let i = 0; i < this.state.selectedRowKeys.length; i++) {
            needApprovalJson.card_ids.push(this.state.selectedRowKeys[i]);
        }
        this.setState({ dataSave: true });
        loaderFocus(".loader-color .ant-spin-text");
        this.updateSentForApproval(false);
        this.props.sentForCardApprovalFun(needApprovalJson, this.props.userDetails.userId, this.updateState, this.props.label_keys, this.updateSentForApproval, this.updateRole);
        this.setState({ sentForApprovalModalVisible: true, selectedRowKeys: [] });
        
    }

    submitForCreateCard = () => {
        let selectedKeys = this.state.selectedRowKeys;
        let data = [];
        this.state.selectedRowKeys.length === 0 &&
            message.error({ content: <span role="alert"><span className="visually-hidden">Error </span>{this.props.label_keys.selectRecordsubmitionForCardCreation}</span> });


        for (let i = 0; i < selectedKeys.length; i++) {
            let result = this.props.passedData.find(obj => {
                return obj.key === selectedKeys[i];
            })
            if (result) {
                let cardData = {};
                let userData = {};
                cardData.validFrom = result.validFrom;
                cardData.validTo = result.validTill;
                cardData.cardLimit = result.creditLimit;
                cardData.field1 = result.field1;
                cardData.field2 = result.field2;
                cardData.notes = result.notes;
                cardData.type = 'Bulk';
                userData.firstName = result.name;
                userData.lastName = result.lastName;
                userData.userEmail = result.emailId;
                userData.mobileNumber = result.cCode + "" + result.mobileNumber;
                userData.employeeId = result.employeeId;
                userData.id = result.id;
                userData.request_id = result.request_id;
                data.push({ cardData: cardData, userData: userData });
            }
        }
        this.setState({ dataSave: true });
        loaderFocus(".loader-color .ant-spin-text");
        this.props.createCardBulkFun(data, this.props.userDetails.userId, this.updateState, this.props.label_keys, this.props.ReqFlag, this.updateRole);
        this.setState({ cardCreationModalVisible: true, selectedRowKeys: [] });
        
    }

    /**Pagination Methods */
    itemRender = (current, type, originalElement, data) => {
        // let totalPage = this.state.TotalPage ? this.state.TotalPage : this.props.totalPage
        if (type === 'prev') {
            return <a><Icon aria-label={null} type="arrow-left" /></a>
        }
        if (type === 'next') {
            return <a><Icon aria-label={null} type="arrow-right" /></a>;
        }
        return originalElement;
    }

    pageChanger = (page, defaultPageSize, data) => {
        let TotalPage = Math.ceil(data.length / defaultPageSize)
        this.setState({ current: page, TotalPage: TotalPage })
    }

    OnPageSizeChange = (current, size, data) => {
        let TotalPage = Math.ceil(data.length / size)
        this.setState({ current: current, TotalPage: TotalPage, size: size })
    }

    pageCountSetter = (data) => {
        this.setState({ TotalPage: (Math.ceil(data.length / 10)), current: 1 });
    }

    onClose() {
        if(this.state.interval){
            clearInterval(this.state.interval);
            this.setState({interval: null});
        }
        this.setState({
            selectedRowKeys: [],
            rows: [], fileObj: {}, fileSelected: false, title: ''
        })
        this.props.removeBulkDataFunc()
    }

    selecteAllUpdate = () => {
        let data;
        if (this.state.activeStatusTab === '1')
            data = this.props.uploadedRecords;
        if (this.state.activeStatusTab === '2')
            data = this.props.inProcessRecords;
        if (this.state.activeStatusTab === '3')
            data = this.props.failedRecords;
        if (this.state.activeStatusTab === '4')
            data = this.props.completedRecords;


        this.setState({ selectedRowKeys: data.map(row => row.key) }, () => { this.onSelectChange(this.state.selectedRowKeys) });
    }
    unSelectAllUpdate = () => {
        let newArray = [];
        this.setState({ selectedRowKeys: newArray }, () => { this.onSelectChange(this.state.selectedRowKeys) });
    }

    changeRoleModal = () => {
        this.setState({ roleFlag: !this.state.roleFlag })
    }

    handleVisibleChange = () => {
        console.log("Active element ::: ", this.state.previousActiveElement);
        if (this.cancelBtnRef.current === document.activeElement) {
            if (document.body.contains(this.state.previousActiveElement)) {
                // setTimeout(() => {
                this.state.previousActiveElement.focus()
            }
        }
        this.setState({
            previousActiveElement: this.state.visible ? null : document.activeElement,
            visible: !this.state.visible
        });
        this.setState({ deleteModalVisible: false })
    }

    capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    handleEsc = (e, request) => {
        if (e.key === 'Escape') {
            if (request) {
                this.setSentForApprovalModalVisible()
                this.handleBack()
                return;
            }
            if(!this.state.interval && this.state.cardCreationModalVisible){
                setTimeout(() => {
                    console.log("--------------------Start timer after cards sent for creation-------------------------------");
                    this.setAPICallTimer()
                }, 100);
    
            }
            if(this.state.cardCreationModalVisible){
                if (document.getElementById('pass-tab-div'))
                    document.getElementById('pass-tab-div').focus();
            }
            this.setState({ uploadFileModalVisible: false, cardCreationModalVisible: false })
        }
    }

    componentDidUpdate() {
        // console.log("Data:::", this.props.passedData, this.props.passedData , this.props.failedData);
        if(this.state.delete && (this.props.passedData && this.props.passedData.length === 0)
         && (this.props.failedData && this.props.failedData.length === 0)){
            // console.log("No data");
            this.props.handleIsBack(false);
            if(this.state.interval){
                clearInterval(this.state.interval);
                this.setState({interval: null});
            }
            this.setState({delete: false});
        }

        var ulDiv = document.querySelectorAll(".ant-pagination.ant-table-pagination");
        if(ulDiv[0]){
            ulDiv.forEach(div => {
                // console.log("Last element:::", div.lastElementChild , div.lastElementChild.className ==="ant-pagination-options");
                if(div.lastElementChild.className ==="ant-pagination-options")
                    div.prepend(div.lastElementChild)
            })
        }

        const icon = document.querySelectorAll('.anticon.anticon-down.ant-select-arrow-icon');
        if (icon) {
            icon.forEach(el => {
                el.setAttribute('role', 'img')
                el.setAttribute('aria-hidden', 'true')
            })
        };

        const divSearch = document.getElementsByClassName("anticon anticon-search ant-input-search-icon");

        if (divSearch[0]) {
            divSearch[0].setAttribute('role', 'img')
            divSearch[0].setAttribute('aria-hidden', 'true')
        }

        const divUp = document.querySelectorAll(".anticon.anticon-caret-up.ant-table-column-sorter-up.off");
        const divDown = document.querySelectorAll(".anticon.anticon-caret-down.ant-table-column-sorter-down.off");
        // console.log("div:::", divUp)
        if (divUp[0]) {
            divUp.forEach(div => {
                div.setAttribute('role', 'img')
                div.setAttribute('aria-hidden', 'true')
            })
        }
        if (divDown[0]) {

            divDown.forEach(div => {
                div.setAttribute('role', 'img')
                div.setAttribute('aria-hidden', 'true')
            })

        }

        const paginationDiv = document.querySelectorAll('.ant-pagination.ant-table-pagination');
        if (paginationDiv[0]) {
            paginationDiv.forEach(div => {
                if (!div.getAttribute('role'))
                    div.setAttribute('role', 'presentation');
            })
        }

        const ellipsis = document.querySelectorAll('.ant-pagination-item-ellipsis');
        if (ellipsis[0]) {
            ellipsis.forEach(div => {
                if (!div.getAttribute('aria-hidden'))
                    div.setAttribute('aria-hidden', 'true');
            })
        }

        const prevFiveDiv = document.querySelectorAll('.ant-pagination-jump-prev.ant-pagination-jump-prev-custom-icon');
        if (prevFiveDiv[0]) {
            prevFiveDiv.forEach(div => {
                if (!div.getAttribute('role')) {
                    div.setAttribute('role', 'link');
                    div.setAttribute('aria-label', "Previous 5 pages");
                }
            })
        }

        const prevFiveDivIcon = document.querySelectorAll('.anticon.anticon-double-left.ant-pagination-item-link-icon');
        if (prevFiveDivIcon[0]) {
            prevFiveDivIcon.forEach(div => {
                if (div.getAttribute('aria-label'))
                    div.removeAttribute('aria-label');
            })
        }

        const nextFiveDiv = document.querySelectorAll('.ant-pagination-jump-next.ant-pagination-jump-next-custom-icon');
        if (nextFiveDiv[0]) {
            nextFiveDiv.forEach(div => {
                if (!div.getAttribute('role')) {
                    div.setAttribute('role', 'link');
                    div.setAttribute('aria-label', "Next 5 pages");
                }
            })
        }

        const nextFiveDivIcon = document.querySelectorAll('.anticon.anticon-double-right.ant-pagination-item-link-icon');
        if (nextFiveDivIcon[0]) {
            nextFiveDivIcon.forEach(div => {
                if (div.getAttribute('aria-label'))
                    div.removeAttribute('aria-label');
            })
        }

        const prevPageDiv = document.querySelectorAll('.ant-pagination-prev');
        if (prevPageDiv[0]) {
            prevPageDiv.forEach(div => {
                if (!div.getAttribute('role')) {
                    div.setAttribute('role', 'link');
                    div.setAttribute('aria-label', "Previous page");
                }
            })
        }

        const nextPageDiv = document.querySelectorAll('.ant-pagination-next');
        if (nextPageDiv[0]) {
            nextPageDiv.forEach(div => {
                if (!div.getAttribute('role')) {
                    div.setAttribute('role', 'link');
                    div.setAttribute('aria-label', "Next page");
                }
            })
        }


        const dropDown = document.querySelectorAll('.ant-select-selection.ant-select-selection--single');
        if (dropDown[0]) {
            dropDown.forEach(div => {
                if(!div.getAttribute('aria-label') && !div.firstElementChild.innerHTML.includes("Active&nbsp;Cards"))
                    div.setAttribute('aria-label', 'Select rows');


                div.addEventListener('keyup', function () {
                    // console.log("Event on click fired");
                    const comboboxOption = document.querySelectorAll('.ant-select-dropdown-menu-item');
                    // console.log("Div:::", comboboxOption);
                    if (comboboxOption[0]) {
                        var i = 0
                        comboboxOption.forEach(el => {
                            i += 1
                            if (!el.getAttribute('id'))
                                el.setAttribute('id', `option-${i}`);
                            el.removeAttribute('aria-selected');
                        })
                    }
                    let optionDiv = document.querySelectorAll('.ant-select-dropdown-menu-item.ant-select-dropdown-menu-item-active');
                    // console.log("Div:::", optionDiv);
                    if (optionDiv[0]) {
                        let activeDescendant = optionDiv[0].getAttribute('id')
                        //  console.log("active descendant::", activeDescendant);   
                        div.setAttribute('aria-activedescendant', activeDescendant);
                    }
                });
            })

        }

        if (this.state.sentForApprovalModalVisible) {
            setTimeout(() => {
                const div = document.querySelectorAll('.ant-modal-root .ant-modal-wrap');
                if (div) {
                    div.forEach(el => {
                        if (!el.getAttribute("aria-label") && !el.getAttribute("aria-labelledby")) {
                            el.setAttribute("aria-labelledby", "title");
                            el.removeAttribute("aria-label")
                        } 
                    })
                };
            }, 500)
        }

        // if (this.props.requestNo && (this.props.isBack === true || this.state.uploadFileModalVisible === true)) {
        //     setTimeout(() => {
        //         const div = document.querySelectorAll('.ant-modal-root .ant-modal-wrap');
        //         if (div) {
        //             div.forEach(el => {
        //                 if (el.getAttribute("aria-label") && el.getAttribute("aria-labelledby")) {
        //                     el.ariaLabel = "Bulk Card Request Number";
        //                     el.removeAttribute("aria-labelledby")
        //                 }
        //             })
        //         };
        //     }, 500)
        // }

        if (this.state.modalFilterVisible) {
            setTimeout(() => {
                const div = document.getElementsByClassName('ant-slider-handle');

                if (div[0]) {
                    div[0].setAttribute('aria-label', 'Credit limit min');
                    div[1].setAttribute('aria-label', 'Credit limit max');


                    // div[0].focus();
                }
            }, 500)
        }

        if ((this.props.passedData && this.props.passedData.length > 0 && this.state.activeStatusTab === '1') || (this.props.failedData && this.props.failedData.length > 0)) {
            this.modifySelectAllCheckBoxAriaLabel();
            // setTimeout(() => {
            //     const div = document.querySelectorAll('.ant-checkbox-input');

            //     if (div[0]) {
            //         div.forEach(el => {
            //             if (!el.getAttribute('aria-label') || el.getAttribute('id')==='Select-all-bulk-checkbox') {
            //                 if(this.state.activeTab === "1"){
            //                 this.state.activeStatusTab === "1"?
            //                     el.setAttribute('aria-label', 'Select all validation passed uploaded cards'):
            //                     el.setAttribute('aria-label', 'Select all validation passed failed cards');
            //                 }
            //                 else{
            //                     el.setAttribute('aria-label', 'Select all validation failed cards');
            //                 }
            //                 el.setAttribute('id', 'Select-all-bulk-checkbox');
            //             }
            //         })
            //     }

            // }, 500)
        }
    }

    render() {
        const { TabPane } = Tabs;
        const { loading, selectedRowKeys, passedFilterTable, failedFilterTable, uploadedFilterTable, inProcessFilterTable,
            completedFilterTable, failedRecordsFilterTable, errorMessage, visible } = this.state;
        let { passedData, failedData, uploadedRecords, inProcessRecords, completedRecords, failedRecords } = this.props;
        let { sortedInfo } = this.state;
        let approvalButton = this.state.approvalButton;
        sortedInfo = sortedInfo || {};
        passedData = passedData || [];
        failedData = failedData || [];
        uploadedRecords = uploadedRecords || [];
        inProcessRecords = inProcessRecords || [];
        completedRecords = completedRecords || [];
        failedRecords = failedRecords || [];
        let passedFilterTableData = passedFilterTable || [];
        let failedFilterTableData = failedFilterTable || [];
        let uploadedFilterTableData = uploadedFilterTable || [];
        let inProcessFilterTableData = inProcessFilterTable || [];
        let completedFilterTableData = completedFilterTable || [];
        let failedRecordsFilterTableData = failedRecordsFilterTable || [];
        if (passedFilterTableData.length !== 0) passedData = passedFilterTableData;
        if (failedFilterTableData.length !== 0) failedData = failedFilterTableData;
        if (uploadedFilterTableData.length !== 0) uploadedRecords = uploadedFilterTableData;
        if (inProcessFilterTableData.length !== 0) inProcessRecords = inProcessFilterTableData;
        if (completedFilterTableData.length !== 0) completedRecords = completedFilterTableData;
        if (failedRecordsFilterTableData.length !== 0) failedRecords = failedRecordsFilterTableData;
        let currentData = this.state.activeTab === '1' ? failedData : passedData;
        let createCardBtnEnable = this.state.selectedRowKeys && this.state.selectedRowKeys.length !== 0;
        let approvalFlowEnabled = this.props.userDetails.bulkCardApproval;
        const content =
            (
                <div className="custom-Popover">
                    <div className="ant-popover-message">
                        <span role="img" aria-hidden="true" className="anticon anticon-exclamation-circle"><svg viewBox="64 64 896 896" focusable="false" data-icon="exclamation-circle" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm-32 232c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v272c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8V296zm32 440a48.01 48.01 0 010-96 48.01 48.01 0 010 96z"></path></svg></span>
                        <div className="ant-popover-message-title">
                            {this.props.label_keys.sureDeletion}
                        </div>
                    </div>
                    <div className="ant-popover-buttons">
                        <button className="cancel-custom-btn ant-btn " ref={this.cancelBtnRef}
                            onKeyDown={e => {
                                if (e.key === "Tab") {
                                    if (e.shiftKey == true) {
                                        if (this.okBtnRef.current) {
                                            setTimeout(() => {
                                                this.okBtnRef.current.focus();
                                            }, 200)
                                        }
                                    }
                                }
                                if (e.key === 'Escape') {
                                    this.handleVisibleChange()
                                }
                            }}
                            onClick={this.handleVisibleChange} >{this.props.label_keys.cancel}</button>
                        <button className="ok-custom-btn ant-btn ant-btn-primary " ref={this.okBtnRef}
                            onKeyDown={e => {
                                if (e.key === "Tab") {
                                    if (this.cancelBtnRef.current) {
                                        setTimeout(() => {
                                            this.cancelBtnRef.current.focus();
                                        }, 200)
                                    }
                                }
                                if (e.key === 'Escape') {
                                    this.handleVisibleChange()
                                }
                            }} onClick={() => this.handleDelete()}>Ok</button>
                        <button aria-hidden="true" className="visually-hidden"
                        ></button>
                    </div>
                </div>
            );
        const cols = [
            this.state.activeStatusTab === '4' || this.state.activeStatusTab === '2'?
                {
                    title: prepareTitle('0', <span role="button" > {this.props.label_keys.firstName}</span>),
                    dataIndex: "name",
                    align: 'left',
                    width: '20%',
                    sorter: (a, b) => {
                        let name1 = a.name.toLowerCase();
                        let name2 = b.name.toLowerCase();
                        return name1 < name2 ? -1 : 1
                    },
                    sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order,
                    className: "bulk-tableth",
                    render: (text, record )=> record.status === "Approval Declined" || record.status === "Rejected" || this.state.activeStatusTab === '2' ?
                    <div style={{color: "#333333", fontWeight: 600}}>{text}</div>
                    :
                    <div
                        tabIndex={0}
                        onKeyUp={e => {
                            if (e.keyCode === 13) { this.cardDetails() }
                        }}
                        role="button"
                        onClick={() => {
                            this.cardDetails()
                        }}><a><u>{text}</u></a></div>
                } : {
                    title: prepareTitle('0', <span role="button" > {this.props.label_keys.firstName}</span>),
                    dataIndex: "name",
                    editable: "true",
                    align: 'left',
                    width: '20%',
                    sorter: (a, b) => {
                        let name1 = a.name.toLowerCase();
                        let name2 = b.name.toLowerCase();
                        return name1 < name2 ? -1 : 1
                    },
                    sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order,
                    className: "bulk-tableth-name-tabs"
                },
            {
                title: prepareTitle('0', <span role="button" >{this.props.label_keys.employeeId}</span>),
                dataIndex: "employeeId",
                editable: "true",
                align: 'left',
                width: '20%',
                sorter: (a, b) => {
                    let name1 = a.employeeId.toLowerCase();
                    let name2 = b.employeeId.toLowerCase();
                    return name1 < name2 ? -1 : 1
                },
                sortOrder: sortedInfo.columnKey === 'employeeId' && sortedInfo.order,
            },

            {
                title: prepareTitle('0', <span role="button" >{this.props.label_keys.creditLimit}</span>),
                dataIndex: "creditLimit",
                editable: "true",
                align: 'left',
                width: '25%',
                sorter: (a, b) => {
                    return a.creditLimit < b.creditLimit ? -1 : 1
                },
                sortOrder: sortedInfo.columnKey === 'creditLimit' && sortedInfo.order,
            },
            {
                title: prepareTitle('0', <span role="button" >{this.props.label_keys.validFrom}</span>),
                dataIndex: "validFrom",
                align: 'left',
                editable: "true",
                width: '20%',
                sorter: (a, b) => { return Number(new Date(a.validFrom)) > Number(new Date(b.validFrom)) ? -1 : Number(new Date(a.validFrom)) < Number(new Date(b.validFrom)) ? 1 : 0 },
                sortOrder: sortedInfo.columnKey === 'validFrom' && sortedInfo.order,
            },
            {
                title: prepareTitle('0', <span role="button" >{this.props.label_keys.validTill}</span>),
                dataIndex: "validTill",
                align: 'left',
                width: '20%',
                editable: "true",
                sorter: (a, b) => { return Number(new Date(a.validTill)) > Number(new Date(b.validTill)) ? -1 : Number(new Date(a.validTill)) < Number(new Date(b.validTill)) ? 1 : 0 },
                sortOrder: sortedInfo.columnKey === 'validTill' && sortedInfo.order,

            },
            {
                title: prepareTitle('0', <span role="button" >{this.props.label_keys.emailID}</span>),
                dataIndex: "emailId",
                type: "email",
                align: 'left',
                width: '20%',
                editable: "true",
                sorter: (a, b) => {
                    let name1 = a.emailId.toLowerCase();
                    let name2 = b.emailId.toLowerCase();
                    return name1 < name2 ? -1 : 1
                },
                sortOrder: sortedInfo.columnKey === 'emailId' && sortedInfo.order,
            },
            {
                title: prepareTitle('0', <span role="button" >{this.props.label_keys.status}</span>),
                dataIndex: "status",
                editable: "false",
                align: 'left',
                width: '20%',
                sorter: (a, b) => {
                    let name1 = a.status.toLowerCase();
                    let name2 = b.status.toLowerCase();
                    return name1 < name2 ? -1 : 1
                },
                sortOrder: sortedInfo.columnKey === 'status' && sortedInfo.order,
                render: text => <div
                    style={{
                        fontWeight: 600, color: (this.state.activeStatusTab === '1' || this.state.activeStatusTab === '2') ? "#19398A" : (text === "Approval Declined" ? "#333333" :
                            ((this.state.activeStatusTab === '3' || this.state.activeTab === '2') ? "#C92323" :  "#2E710B"))
                    }}
                >{text === "true" ? "Awaiting submission" : text === "false" ? "Failed validation" :
                 text === "Failed" || text === 'Card Creation Failed' ? "Card creation failed" :
                 text === "Sent For Approval" ? "Sent for approval" : text === "Rejected"? "Approval Declined": text}</div>     

            },
            {
                title: prepareTitle('0', <span role="button" >{this.props.label_keys.country}</span>),
                dataIndex: "cCode",
                editable: "true",
                align: 'left',
                width: '20%',
                sorter: (a, b) => {
                    return a.cCode < b.cCode ? -1 : 1
                },
                sortOrder: sortedInfo.columnKey === 'cCode' && sortedInfo.order,

            },
            {
                title: prepareTitle('0', <span role="button" >{this.props.label_keys.mobileNumber}</span>),
                dataIndex: "mobileNumber",
                editable: "true",
                align: 'left',
                width: '25%',
                sorter: (a, b) => {
                    return a.mobileNumber < b.mobileNumber ? -1 : 1
                },
                sortOrder: sortedInfo.columnKey === 'mobileNumber' && sortedInfo.order,

            },
            {
                title: prepareTitle('0', <span role="button" >{this.props.label_keys.notes}</span>),
                dataIndex: "notes",
                editable: "true",
                align: 'left',
                width: '20%',
                sorter: (a, b) => {
                    let name1 = a.notes.toLowerCase();
                    let name2 = b.notes.toLowerCase();
                    return name1 < name2 ? -1 : 1
                },
                sortOrder: sortedInfo.columnKey === 'notes' && sortedInfo.order,

            },
            {
                title: prepareTitle('0', <span role="button" >{this.props.label_keys.lastName}</span>),
                dataIndex: "lastName",
                editable: "true",
                align: 'left',
                width: '20%',
                sorter: (a, b) => {
                    let name1 = a.notes.toLowerCase();
                    let name2 = b.notes.toLowerCase();
                    return name1 < name2 ? -1 : 1
                },
                sortOrder: sortedInfo.columnKey === 'notes' && sortedInfo.order,

            },
            {
                title: prepareTitle('0', <span role="button" >{this.props.label_keys.field1}</span>),
                dataIndex: "field1",
                editable: "true",
                align: 'left',
                width: '20%',
                sorter: (a, b) => {
                    let name1 = a.notes.toLowerCase();
                    let name2 = b.notes.toLowerCase();
                    return name1 < name2 ? -1 : 1
                },
                sortOrder: sortedInfo.columnKey === 'notes' && sortedInfo.order,

            },
            {
                title: prepareTitle('0', <span role="button" >{this.props.label_keys.field2}</span>),
                dataIndex: "field2",
                editable: "true",
                align: 'left',
                width: '20%',
                sorter: (a, b) => {
                    let name1 = a.notes.toLowerCase();
                    let name2 = b.notes.toLowerCase();
                    return name1 < name2 ? -1 : 1
                },
                sortOrder: sortedInfo.columnKey === 'notes' && sortedInfo.order,

            },

        ];
        const passedDatarowSelection = {           
            onSelectAll: (selected) => {
                if (selected) {

                    this.selecteAllUpdate()

                } else {
                    this.unSelectAllUpdate()
                }
            },
            selectedRowKeys,
            onChange: this.onSelectChange,
            onSelect: (record, selected, selectedRows, nativeEvent) => {
                // console.log("Items",record, selected, selectedRows, nativeEvent );
                if (document.getElementById(record.id)) {
                    document.getElementById(record.id).setAttribute('aria-checked', selected);
                }

            },
            getCheckboxProps: record => ({ // Column configuration not to be checked        
                'aria-checked': false,
                name: !record.name ? "text" : record.name,
                'aria-label': record.name,
                role: "checkbox",
                id: record.id,
                onKeyDown: checkBoxStatus(this.state.activeStatusTab === '1' ? '#upload-tab-panel':'#failed-tab-panel')
            }),
        };

        const failedDatarowSelection = {
            onSelectAll: (selected) => {
                if (selected) {
                    this.setState({ selectedRowKeys: this.props.failedData.map(row => row.key) });
                } else {
                    this.setState({ selectedRowKeys: [] });
                }
            },
            selectedRowKeys,
            onChange: this.onSelectChange,
            onSelect: (record, selected, selectedRows, nativeEvent) => {
                // console.log("Items",record, selected, selectedRows, nativeEvent );
                if (document.getElementById(record.id)) {
                    document.getElementById(record.id).setAttribute('aria-checked', selected);
                }

            },
            getCheckboxProps: record => ({ // Column configuration not to be checked
                'aria-checked': false,
                name: !record.name ? "text" : record.name,
                'aria-label': record.name,
                role: "checkbox",
                id: record.id,
                onKeyDown: checkBoxStatus('#fail-tab-panel')
            }),

        };

        const components = {
            body: {
                row: EditableFormRow,
                cell: EditableCell
            }
        };
        const columns = cols.map(col => {
            if (!col.editable) {
                return col;
            }
            return {
                ...col,
                onCell: record => ({
                    record,
                    editable: col.editable,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    id: record.id,
                    creditLimitRule: this.props.userDetails.cardLimitRule,
                    cardValidityRule: this.props.userDetails.cardValidityRule,
                    approvalEnabled: approvalFlowEnabled,
                    editing: this.isEditing(record),
                    handleSave: this.handleSave,
                    label_keys: this.props.label_keys,
                    activeStatusTab: this.props.activeStatusTab
                })
            };
        });

        // const passedfooter = <Row>
        //     <Col>
        //     <Select
        //         labelId="demo-simple-select-label"
        //         id="demo-simple-select"
        //         value={this.state.size}
        //         onChange={e => this.OnSizeChange(e)}
        //     >
        //         <MenuItem value={10}>10 / page</MenuItem>
        //         <MenuItem value={20}>20 / page</MenuItem>
        //         <MenuItem value={50}>50 / page</MenuItem>
        //         <MenuItem value={100}>100 / page</MenuItem>
        //     </Select>
        //     </Col>
        // </Row>

        const footer = <Row gutter={[16, {xs: 12, sm: 24, md:6, lg: 6, xl: 6, xxl: 6}]}>
            <Col xs={4} sm={6} md={16} lg={16} xl={16} xxl={16}>&nbsp;</Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8} className={approvalButton ? "approv-bulk-pass-footer": "bulk-pass-footer"}
                style={{marginLeft: this.props.selectedLang === "French" && approvalButton ? '-9%' : this.props.selectedLang === "English" && approvalButton ? '-4%' : '-2%', zIndex: '2', marginTop: '-3.5%'} }>
                <Row gutter={[16, {xs: 30, sm: 30, md:6, lg: 6, xl: 6, xxl: 6}]}>
                    <Col xs={10} sm={10} md={10} lg={10} xl={10} xxl={10}>
                        {(this.state.display) &&
                            <Button role="button" className="bulk-upload-cancel cancel-btn-ant" aria-label="Cancel" onClick={this.handleCancel} style={createCardBtnEnable ? {color: '#0A41C5', borderColor: '#0A41C5' } : null}>{this.props.label_keys.cancel}</Button>
                        }
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} offset={1}>
                        {(approvalButton) ? (
                            (this.state.display) &&
                            <Button className="approval-btn" role="button" type="primary" aria-label="Send for approval" onClick={this.submitForApproval} disabled={!createCardBtnEnable} >{this.props.label_keys.sendForApproval}</Button>
                        ) : (
                            (this.state.display) &&
                            <Button className="create-card-btn bulkUpload-create-card" style={{ fontWeight: "normal", fontSize: "16px" }} role="button" type="primary" aria-label="Create Cards" onClick={this.submitForCreateCard} disabled={!createCardBtnEnable}>{this.props.label_keys.createCards}</Button>
                        )}
                    </Col>
                </Row>
            </Col>
        </Row>

        const failedFooter = <Row>
            <Col span={16}>&nbsp;</Col>
            <Col span={4}>&nbsp;
            </Col>
            <Col span={4} style={{marginTop: '-3.5%'}}>
                <Button tabIndex={0} role="button" type="primary" className="validate-btn validate-btn-zoom" aria-label="Validate" onClick={() => this.handleValidate('Validate')}
                    disabled={!this.state.selectedRowKeys.length > 0}>
                    {this.props.label_keys.validate}
                </Button>
            </Col>
        </Row>

        const importExcelString =
            <> <div >
                {errorMessage}
            </div>
                <Row gutter={4} style={{
                    borderSpacing: "0 8px"
                }}>
                    <Col span={24} style={{
                        fontWeight: 400, textAlign: "right", alignItems: "center",
                        paddingRight: "25px", verticalAlign: "middle"
                    }}
                    >
                        <span className="downloadTemplate" style={{ color: '#666666', opacity: 1 }}>{this.props.label_keys.templateForBulkCard} &nbsp;&nbsp;</span>
                        <Link to="/bulkupload_template.xlsx" target="_blank" download >
                            <span style={{ color: '#19398A', cursor: 'pointer', opacity: 1, fontWeight: 600 }} className='download-file-link' key="console">
                                {/* <img  style={{ width: 'auto', height: '15px', marginTop: '-5px' }} src={Download} alt='' />  */}
                                <Icon type="download" aria-hidden="true" alt="" style={{ width: 'auto', height: '15px', marginTop: '-5px' }} />
                                &nbsp;{this.props.label_keys.downloadTemplate}
                            </span><br />
                        </Link>
                    </Col>
                </Row>

                <Row>
                    <Col span={24} style={{ marginTop: '1%', verticalAlign: "middle" }}>
                        <div style={!this.state.fileSelected ? {} : { opacity: '0.4' }}
                            aria-hidden={this.state.fileSelected}
                        >
                            <Dragger name="file" ref={'draggerItem'}
                                className='draggerItem'
                                disabled={this.state.fileSelected}
                                showUploadList={false}
                                beforeUpload={this.fileHandler}
                                onRemove={() => this.setState({ rows: [] })}
                                multiple={false}
                                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                style={{
                                    marginLeft: '15%', width: '70%', borderWidth: "1.5 px",
                                    borderStyle: "dashed", borderColor: '#707070', borderRadius: '0',

                                }}
                                aria-disabled={this.state.fileSelected}
                            >
                                <Row >
                                    <Col>
                                        <img alt='' src={require(`../../images/bulk/${this.props.theme}/importExcel.svg`).default}
                                            style={{ height: '8%', width: '8%' }} />
                                    </Col>
                                </Row>
                                <Row style={{ borderSpacing: "0 10px" }}>
                                    <Col style={{
                                        textalign: "center",
                                        fontSize: "16px",
                                        letterspacing: "0px",
                                        opacity: "1",
                                        fontWeight: '600'
                                    }} >
                                        <p > {this.props.label_keys.attachCSVOrExcel} <br />{this.props.label_keys.thebulkCardCreation}</p>

                                        {/* <span style={{color: "#666666", opacity: "1"}} >(Or)</span> */}
                                    </Col>
                                </Row>
                                <Row >
                                    <Col style={{
                                        textalign: "center",
                                        fontSize: "18px",
                                        letterspacing: "0",
                                        color: "#666666",
                                        opacity: "1"
                                    }}>
                                        <span style={{ color: "#666666", opacity: "1" }} className="or-label" >({this.props.label_keys.or})</span>

                                    </Col>
                                </Row>
                                <Row style={{ borderSpacing: "0 5px", marginBottom: '-15px' }} >
                                    <Col style={{ marginTop: '20px' }}>
                                        <span className="browse-file-btn"  ><u>{this.props.label_keys.browseFile}</u></span>
                                    </Col>
                                </Row>
                            </Dragger>
                        </div>
                    </Col>
                </Row>

                {this.state.fileSelected && this.state.fileObj &&
                    <Row>
                        <Col>
                            <div>
                                <div className="uploadFile-container">
                                    <span>
                                        <Icon rotate="180" aria-hidden="true" type="paper-clip"
                                            style={{ color: '#19398A', position: "absolute", marginTop: '8px', marginLeft: '5px' }} />
                                        <span className="uploaded-file" id="fileName" >{this.state.fileObj.name}</span>
                                        <span style={{ marginLeft: '70%' }}>
                                            <Icon role="button" aria-describedby="fileName" tabIndex={0} aria-label="delete" type="delete" className="deleteIcon"
                                                onKeyUp={(e) => {
                                                    if (e.keyCode === 13) {
                                                        this.setState({ rows: [], fileObj: {}, fileSelected: false, title: '' })
                                                    }
                                                }}
                                                style={{ marginTop: '8px' }}
                                                onClick={() => this.setState({ rows: [], fileObj: {}, fileSelected: false, title: '' })} />
                                        </span>
                                    </span>
                                    <span>
                                        <TextArea placeholder={this.props.label_keys.typeTitle} ref={'titleInputItem'}
                                            aria-label='Enter file description text'
                                            aria-describedby="max-char"
                                            rows={2}
                                            className='text-area'
                                            maxLength={50}
                                            value={this.state.title}
                                            onChange={e => this.setState({ title: e.target.value })} />
                                        <span id="max-char" className="suggestion-text-area">{this.props.label_keys.maxCharacters}</span>
                                        <span aria-live={`${this.state.title.length % 10 === 0 ? "assertive" : "off"}`} className="visually-hidden">
                                            {this.state.title.length}/50 Characters
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </Col>
                    </Row>
                }
                <Row style={{ height: '50px', borderSpacing: (this.state.fileSelected && this.state.fileObj) ? "0 15px" : "0 65px" }}  >
                    <Col span={24} style={{
                        minHeight: 70, fontWeight: 600, textAlign: "center",
                        paddingRight: 10, alignItems: "center", verticalAlign: "middle"
                    }} >

                        <Button type="primary" className="upload-btn" disabled={!this.state.fileSelected} onClick={this.uploadFile} >
                            {this.props.label_keys.upload}
                        </Button>

                    </Col>
                </Row>

            </>

        let PassedCount = this.props.passedData && this.props.passedData.length || 0;
        let failedCount = this.props.failedData && this.props.failedData.length || 0;
        let uploadedCount = this.props.uploadedRecords && this.props.uploadedRecords.length || 0;
        let inProcessCount = this.props.inProcessRecords && this.props.inProcessRecords.length || 0;
        let completedCount = this.props.completedRecords && this.props.completedRecords.length || 0;
        let failedRecordCount = this.props.failedRecords && this.props.failedRecords.length || 0;

        const passedLable = this.props.label_keys.validation + "(" + PassedCount + ")";
        const failedLable = this.props.label_keys.failedValidation + "(" + failedCount + ")";
        const uploadedLable = this.props.label_keys.uploaded + "(" + uploadedCount + ")";
        const inProcessLable = this.props.label_keys.processing + "(" + inProcessCount + ")";
        const failedRecordsLable = this.props.label_keys.failed + "(" + failedRecordCount + ")";
        const completedLable = this.props.label_keys.completed + "(" + completedCount + ")";

        const uploadedEmptyText = this.props.label_keys.noCardsAwaitingSubmission;
        const inProcessEmptyText = this.props.label_keys.noCardsInProcess;
        const failedRecordsEmptyText = this.props.label_keys.noCardsFailed;
        const completedEmptyText = this.props.label_keys.noCardsCompleted;

        let path = "";
        { path = this.props.responseCreateCard && URLs.cardUrl + '/' + this.props.responseCreateCard.downloadPath; }
        return (

            <>
                {/* <div role="main"> */}
                {this.state.dataSave ? <div className="dark-spinner loader-color">
                    <Spin tip={this.props.label_keys.loading} /></div> : null}
                {this.state.roleFlag &&
                    (
                        <Modal role="dialog" className="confirm-visible modalAlign" visible={this.state.roleFlag}
                            title={<h2 style={{ fontSize: '16px', fontWeight: "700", color: "#161a80" }}>{this.props.label_keys.alert}</h2>}
                            cancelButtonProps={{ style: { display: 'none' } }} onOk={this.changeRoleModal}
                            okText={this.props.label_keys.ok} closable >
                            <><img src={warningIcon} alt='Error' /></><br /><br />

                            {this.props.label_keys.userRoleRestrictedMsgForBulk}
                            <><br /><br />
                                <span tabindex={0} aria-label="Download report" role="button"
                                    style={{ color: '#0a41c5', cursor: 'pointer' }} key="console"
                                    onKeyUp={e => {
                                        if (e.keyCode === 13) {
                                            this.handleDownloadReport()
                                        }
                                    }}
                                    onClick={() => this.handleDownloadReport()}>
                                    <a>
                                        <img src={Download} alt='' /> &nbsp;{this.props.label_keys.downloadReport}
                                    </a>
                                </span><br />
                            </>

                        </Modal>
                    )}
                {(!this.state.dataSave && this.props.responseCreateCard && this.props.responseCreateCard !== null && this.props.responseCreateCard.failedCount > 0) &&
                    (
                        <Modal role="dialog" className="confirm-visible modalAlign" visible={this.state.createCardModalVisible}
                            title={<h2 style={{ fontSize: '16px', fontWeight: "700", color: "#161a80" }}>{this.props.label_keys.alert}</h2>}
                            cancelButtonProps={{ style: { display: 'none' } }}
                            onOk={this.setCreateCardModalVisible} okText={this.props.label_keys.ok} closable>
                            <><img src={warningIcon} alt='Error' /></><br /><br />

                            {this.props.label_keys.hi + ' ' + this.props.userDetails.firstName + ', ' + this.props.responseCreateCard.failedCount + ' ' + this.props.label_keys.doYouWantCon}
                            <><br /><br />
                                <span tabindex={0} role="button" aria-label="Download report"
                                    style={{ color: '#0a41c5', cursor: 'pointer' }}
                                    key="console"
                                    onKeyUp={e => {
                                        if (e.keyCode === 13) {
                                            this.handleDownload(this.props.responseCreateCard.failedRows)
                                        }
                                    }} onClick={() => this.handleDownload(this.props.responseCreateCard.failedRows)}>
                                    <a><img src={Download} alt='' /> &nbsp;{this.props.label_keys.downloadReport} </a>
                                </span><br />
                            </>
                        </Modal>
                    )}
                {(!this.state.dataSave && this.props.responseCreateCard && this.props.responseCreateCard !== null && this.props.responseCreateCard.failedCount === 0) &&
                    (
                        <Modal visible={this.state.createCardModalVisible} role="dialog"
                            title={<h2 style={{ fontSize: '16px', fontWeight: "700", color: "#161a80" }}>{this.props.label_keys.alert}</h2>}
                            onCancel={this.setCreateCardModalVisible} footer={[]}>
                            <Result
                                icon={<Icon type="check-circle" theme="filled" aria-hidden='true' />}
                                status="success"
                                subTitle={this.props.label_keys.cardsCreatedSuccessfull}
                            /></Modal>
                    )
                }
                {(this.state.sentForApprPopupVisible && this.props.responseSentForApproval && this.props.responseSentForApproval.requestNo !== 0) &&
                    (
                        <Modal className="custom-modal custom-modal-bulk-card" role="dialog"
                            visible={this.state.sentForApprovalModalVisible}
                            closable={false}
                            aria-labelledby="title"
                            title={<b className="visually-hidden" id="title">Sent For Approval Request Number</b>}
                            footer={[<Button ref={this.modalBtnRef} type="primary" onKeyDown={(e) => this.handleEsc(e, 'request')} onClick={() => this.setSentForApprovalModalVisible()}>Ok</Button>]}
                        >
                            <div className="custom-modal-content">
                                <img src={Tick} alt='success' />
                                <p><span style={{ color: "#19398A" }}>{this.props.label_keys.bulkCardRequest}{this.props.responseSentForApproval.requestNo}</span> {this.props.label_keys.createdsentForApproval}</p>
                            </div>
                        </Modal>
                    )
                }
                {/* {(passedData.length > 0 || failedData.length > 0) && !this.props.ReqFlag &&
                    <div className="create-head bulk-close">
                        <Icon tabindex={0} aria-label="Close" type="close-circle" onClick={() => this.onClose()} />
                    </div>} */}
                <div role="main">
                    <Card style={{ borderRadius: "12px" }} className='container ant-card-pv'>
                        {passedData.length === 0 && failedData.length === 0 ? <>{importExcelString}  </>
                            : <>
                                <div>
                                    <Modal role="dialog" id="bulk-card-popup"
                                        className="custom-modal custom-modal-bulk-card"
                                        closable={false}
                                        aria-labelledby="title"
                                        title={<b className="visually-hidden" id="title">Bulk Card Request Number</b>}
                                        visible={this.state.uploadFileModalVisible}
                                        footer={[<Button aria-describedby="message-popup" aria-labelledby="bulk-card-popup" type="primary" ref={this.modalBtnRef} onKeyDown={(e) => this.handleEsc(e)} onClick={() => this.uploadFileModalVisibleFunc()}>{this.props.label_keys.ok}</Button>]}
                                    >
                                        <div className="custom-modal-content">
                                            <img src={Tick} alt='success' />
                                            <p id="message-popup"><span style={{ color: "#19398A" }}>{`${this.props.label_keys.bulkCardRequest}${this.props.requestNo}`}</span> {`${this.props.label_keys.bulkRequestCreated}`}</p>
                                        </div>
                                    </Modal>
                                </div>
                                <div>
                                    <Modal role="dialog"
                                        className="custom-modal custom-modal-bulk-card"
                                        closable={false}
                                        aria-labelledby="title"
                                        title={<b className="visually-hidden" id="title">Card creation request</b>}
                                        visible={this.state.cardCreationModalVisible}
                                        footer={[<Button aria-describedby="message-popup" type="primary" ref={this.modalBtnRef} onKeyDown={(e) => this.handleEsc(e)} onClick={this.setCreateCardModalVisible}>{this.props.label_keys.ok}</Button>]}
                                    >
                                        <div className="custom-modal-content">
                                            <img src={Tick} alt='success' />
                                            <p id="message-popup">{`${this.props.label_keys.cardCreationRequestSubmitted}`}</p>
                                        </div>
                                    </Modal>
                                </div>
                                <div aria-relevant="text"
                                    aria-live={sortedInfo.order === "ascend" || sortedInfo.order === "descend" ? "polite" : "off"}
                                    aria-atomic="true" className="visually-hidden">
                                    {
                                        sortedInfo.order === "ascend" || sortedInfo.order === "descend" ?
                                        <div><span>Table sorted by {sortedInfo.columnKey}</span>
                                            <span>in {sortedInfo.order}ing order</span></div> : null
                                    } 
                                </div>
                                <Row gutter={[16, {xs: 30, sm: 30, md:6, lg: 6, xl: 6, xxl: 6}]}>
                                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                                        <Row gutter={[16, {xs: 12, sm: 12, lg: 12, xl: 12, xxl: 12}]} 
                                        className={`myRequest-extra-bulk ${!this.props.ReqFlag && this.state.activeTab === '2' ? 'req-activetab': ''}`} style={{ left: this.props.ReqFlag ? null : (this.state.activeTab === '2' ? '45%' : '45%') }}>
                                            {this.state.editableMode !== true &&
                                                <>
                                                    <Col offset={1} xs={24} sm={16} md={13} lg={14} xl={13} xxl={13}
                                                        style={{marginTop: this.props.theme === 'elan' ? '1.5%' : null}} >
                                                        <Search
                                                            aira-label="Search"
                                                            // tabIndex={3}
                                                            className="search-input"
                                                            style={{ width: '90%' }}
                                                            placeholder={this.props.label_keys.search}
                                                            onChange={(e) => {
                                                                if (e.target.value === "" || e.target.value === null) {
                                                                    this.search("")
                                                                }
                                                            }}
                                                            autocomplete="off"
                                                            onSearch={this.search} /></Col>
                                                    <Col xs={this.state.activeTab === '2' ? 4 : 6} sm={this.state.activeTab === '2' ? 4 : 6} md={4} lg={2} xl={2} xxl={2}>

                                                        <Button type="primary filter-comp " style={{ left: "-10px", backgroundColor: '#19398A', borderColor: '#19398A' }} onClick={this.showFilter} >
                                                            <img className="filter" style={{
                                                                width: "40px",
                                                                height: "40px", marginTop: this.props.theme === 'elan' ? '-2px' : '-1px'
                                                            }} src={Filter} alt={this.props.label_keys.filter} />
                                                        </Button> </Col>&nbsp;&nbsp;&nbsp;&nbsp;
                                                    {this.state.activeTab === '2' && <Col xs={16} sm={16} md={4} lg={2} xl={2} xxl={2}>

                                                        <Button type={`primary filter-comp ${!this.state.selectedRowKeys.length > 0? 'disabled-edit-icon': ''}`} className="edit-btn edit-btn-zoom edit-button-focus" style={{ left: "-3px", padding: '2px', backgroundColor: '#19398A', borderColor: '#19398A' }} onClick={this.handleEditMode} disabled={!this.state.selectedRowKeys.length > 0}>
                                                            {/* <img className="edit" style={{
                                                                width: "30px",
                                                                height: "30px"
                                                            }} src={Edit} alt="Edit" /> */}
                                                            <i  role="img" aria-label="Edit" className="icon-Edit" id="edit-btn"
                                                                style={{ color: "white", fontSize: "18px", fontWeight: 600, padding: '6px', outlineColor: 'transparent' }} />
                                                        </Button>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                                    </Col>}
                                                    <Col className='diableDelete' xs={12} sm={12} md={4} lg={this.state.activeTab === '2' ? 2 : 4} xl={this.state.activeTab === '2' ? 2 : 4} xxl={4}>
                                                        {/* <Popover
                                                            role="dialog"
                                                            aria-labelledby="modal-title"
                                                            aria-modal="true"
                                                            placement={"top"}
                                                            content={content}
                                                            visible={visible}
                                                            trigger='click'
                                                            onVisibleChange={this.handleVisibleChange}
                                                            id="delete-popover"
                                                        >
                                                            <Button type="primary filter-comp" aria-controls="delete-popover" className="delete-btn"
                                                                hidden={this.state.activeStatusTab === '2' || this.state.activeStatusTab === '4'}
                                                                tabIndex={!this.state.selectedRowKeys.length > 0 ? -1 : null}
                                                                disabled={!this.state.selectedRowKeys.length > 0}
                                                                style={{marginLeft: this.state.activeTab === '2'? '-9px' : null}} >
                                                                <img className="filter" alt="Delete" style={{
                                                                    // background: "#0A41C5 0% 0% no-repeat padding-box",
                                                                    width: "30px", height: "30px"
                                                                }} src={this.state.selectedRowKeys.length > 0 ? enableDelete :disableDelete} />
                                                            </Button>
                                                        </Popover> */}
                                                        <Button type="primary filter-comp" aria-controls="delete-popover" onClick={() => this.openDeleteModal()} className={this.state.activeTab === "2" ? "delete-btn delete-btn-zoom-failed" : "delete-btn delete-btn-zoom-passed"}
                                                            hidden={this.state.activeStatusTab === '2' || this.state.activeStatusTab === '4'}
                                                            tabIndex={!this.state.selectedRowKeys.length > 0 ? -1 : null}
                                                            disabled={!this.state.selectedRowKeys.length > 0}
                                                            style={{ left: "5px", marginLeft: this.state.activeTab === '2' ? '-7px' : null }} >
                                                            <img className="filter" alt="Delete" style={{
                                                                // background: "#0A41C5 0% 0% no-repeat padding-box",
                                                            }} src={this.state.selectedRowKeys.length > 0 ? enableDelete : disableDelete} />
                                                        </Button>
                                                        <Modal role="dialog"
                                                            aria-labelledby="delete-popover"
                                                            aria-modal="true"
                                                            className="custom-modal custom-modal-bulk-card"
                                                            closable={false}
                                                            title={<b role="heading" aria-level="2" className="visually-hidden" id="delete-popover">{this.props.label_keys.delete}</b>}
                                                            visible={this.state.deleteModalVisible}
                                                            footer={[<>
                                                                <div>
                                                                    <button className="cancel-custom-btn ant-btn "
                                                                        // ref={this.okBtnRef}
                                                                        onKeyDown={e => {
                                                                            if (e.key === 'Escape') {
                                                                                // this.handleVisibleChange()
                                                                                this.setState({ deleteModalVisible: false })
                                                                            }
                                                                        }}
                                                                        onClick={this.handleVisibleChange} >{this.props.label_keys.cancel}</button>
                                                                    <button className="ok-custom-btn ant-btn ant-btn-primary "
                                                                        onKeyDown={e => {
                                                                            if (e.key === 'Escape') {
                                                                                // this.handleVisibleChange()
                                                                                this.setState({ deleteModalVisible: false })
                                                                            }
                                                                        }} onClick={() => this.handleDelete()}>{this.props.label_keys.delete}</button>
                                                                </div>
                                                            </>]}
                                                        >
                                                            <div className="custom-modal-content">
                                                                {/* <img src={Tick} alt='success' /> */}
                                                                <p id="message-popup"><span>{`${this.props.label_keys.youSureDeletion} "${this.state.selectedRowKeys.length}" ${this.props.label_keys.cards_q}`}</span></p>
                                                            </div>
                                                        </Modal>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;

                                                    </Col>
                                                    {!this.props.ReqFlag && <Col xs={this.state.activeTab === '2' ? 4 : 24} sm={12} md={2} lg={2} xl={2} xxl={2}>

                                                        <Button type="primary create-more filter-comp" style={{ left: this.state.activeTab === '2' ? "-1px" : '-30px', borderColor: '#19398A', backgroundColor: '#19398A' }} onClick={() => {this.props.handleIsBack(true); this.setState({requestModalVisible: true});}} >
                                                            <span style={{ width: '160px' }}>{this.props.label_keys.createMore}</span>
                                                        </Button>
                                                        &nbsp;&nbsp;
                                                    </Col>
                                                    }
                                                    <Modal className="confirm-visible filter-modal blk-upload-zoom" role='dialog'
                                                        title={<h2 style={{ fontSize: '16px', fontWeight: "700", color: "#161a80" }}>{this.props.label_keys.filter}</h2>}
                                                        visible={this.state.modalFilterVisible} onCancel={this.handleFilterCancel}
                                                        onOk={this.filterData} cancelText={this.props.label_keys.cancel}
                                                      okText={<span className="applyFilterBtn">{this.props.label_keys.applyFilter}</span>}>
                                                        <div tabindex={-1}>
                                                            <p className="msg-blkupload-zoom">{this.props.label_keys.creditLimit}</p>
                                                            <Slider
                                                                range
                                                                min={this.state.minValue}
                                                                max={this.state.maxValue}
                                                                value={[this.state.left, this.state.right]}
                                                                onChange={e => this.setState({ left: e[0], right: e[1] })}
                                                            />

                                                        </div>
                                                    </Modal>
                                                </>
                                            }
                                            {this.state.editableMode === true &&
                                                <Row className="mb-10" gutter={[16, {xs: 12, sm: 10, lg: 26, xl: 26, xxl: 26}]}  >
                                                    <Col offset={10} span={12} style={{ marginLeft: this.props.selectedLang === "French"? '15%' : (this.props.theme === 'elan' && !this.props.ReqFlag ? '57%' :'27%' )}}>
                                                        <Col span={11} >
                                                            <Button className="cancel-btn-ant bulkUpload-cancl" onClick={this.handleCancelEditMode} style={{ marginLeft: this.props.selectedLang === "French"? '-35%' : '-25%' }}>{this.props.label_keys.cancel}</Button>
                                                        </Col>
                                                        <Col span={11} offset={2}>
                                                            <Button type="primary bulkUpload-save" onClick={() => this.handleValidate('Save')} >{this.props.label_keys.save}</Button>
                                                        </Col>
                                                    </Col>
                                                </Row>
                                            }
                                        </Row>
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                                        <Tabs className="request-extra" value={this.state.activeTab}
                                            style={{
                                                borderBottom: '1px solid #e8e8e8', marginTop: '-45px', marginBottom: '5px',
                                                paddingTop: '10px', float: 'left', width: '100%', textTransform: 'none',
                                            }}
                                            onChange={(event, value) => this.changeTab(value, currentData)}
                                            size="small" >
                                            <Tab disableRipple id="pass-tab" aria-controls='pass-tab-panel'
                                                className="pass-tab" value='1'
                                                label={<div id="pass-tab-div" tabIndex={-1} ref={"passItem"}>{passedLable}</div>}
                                            />
                                            <Tab disableRipple id="fail-tab" aria-controls='fail-tab-panel'
                                                className="fail-tab" ref={"failItem"}
                                                value='2'
                                                label={<div >{failedLable} </div>} />

                                        </Tabs>
                                    </Col>
                                </Row>
                                <div role="tabpanel" id='pass-tab-panel' aria-labelledby='pass-tab' value='1'
                                    hidden={this.state.activeTab !== '1'} style={{ marginTop: '1.5%' }}>
                                    <Row>
                                        <Col>
                                            <Tabs className="request-extra-sub" value={this.state.activeStatusTab}
                                                // style={{ borderBottom:'1px solid #e8e8e8', marginTop: '-30px', marginBottom: '5px',
                                                // paddingTop: '10px', float: 'left', width: '100%', textTransform: 'none', }} 
                                                onChange={(event, value) => this.changeStatusTab(value)}
                                                size="small"
                                            >

                                                <Tab disableRipple id="upload-tab" aria-controls='upload-tab-panel'
                                                    className="upload-tab" value='1'
                                                    label={<div className='upload-label' ref={"passItem"}>{uploadedLable}</div>} />
                                                <Tab disableRipple id="process-tab" aria-controls='process-tab-panel'
                                                    className="process-tab"
                                                    value='2'
                                                    label={<div className='process-label' ref={"failItem"}>{inProcessLable} </div>} />
                                                <Tab disableRipple id="failed-tab" aria-controls='failed-tab-panel'
                                                    className="failed-tab"
                                                    value='3'
                                                    label={<div className='failed-label' ref={"failItem"}>{failedRecordsLable} </div>} />
                                                <Tab disableRipple id="comp-tab" aria-controls='comp-tab-panel'
                                                    className="comp-tab"
                                                    value='4'
                                                    label={<div className='comp-label' ref={"failItem"}>{completedLable} </div>} />
                                            </Tabs>
                                            <div role="tabpanel" id='upload-tab-panel' aria-labelledby='upload-tab' value={'1'}
                                                hidden={this.state.activeStatusTab !== '1'}
                                            >
                                                <Table className='Req-table userlist paginationAlign pageArrowBulk'
                                                    rowKey={obj => obj.id}
                                                    components={components}
                                                    rowClassName={() => "editable-row"}
                                                    dataSource={uploadedRecords}
                                                    columns={columns}
                                                    locale={{ emptyText: uploadedEmptyText }}
                                                    rowSelection={passedDatarowSelection}
                                                    onChange={this.handleChange}
                                                    // footer={() => footer}
                                                    scroll={{ x: 2000 }}
                                                    bordered={false}
                                                    pagination={{
                                                        defaultPageSize: 10,
                                                        showSizeChanger: true,
                                                        size: "small",
                                                        total: uploadedRecords.length,
                                                        current: this.state.current,
                                                        itemRender: this.itemRender,
                                                        pageSizeOptions: ["10", "20", "50", "100"],
                                                        position: "bottom",
                                                        showTitle: false,
                                                        showTotal: (total, range) => <div> <span className="total-text-pagination">{this.props.label_keys.showing} {range[0]}-{range[1]} {this.props.label_keys.of} {total} </span>
                                                        <span className="page-count-span-bulk">{this.props.label_keys.page} {this.state.current} {this.props.label_keys.of} {this.state.TotalPage}</span></div>,
                                                        onChange: (page, defaultPageSize) => this.pageChanger(page, defaultPageSize, uploadedRecords),
                                                        onShowSizeChange: (current, size) => this.OnPageSizeChange(current, size, uploadedRecords)

                                                    }}
                                                />
                                                <div>{footer}</div>
                                            </div>

                                            <div role="tabpanel" id='process-tab-panel' aria-labelledby='process-tab' value={'2'}
                                                hidden={this.state.activeStatusTab !== '2'}
                                            >
                                                <Table className='Req-table userlist paginationAlign pageArrowBulk'
                                                    components={components}
                                                    rowClassName={() => "editable-row"}
                                                    dataSource={inProcessRecords}
                                                    columns={columns}
                                                    locale={{ emptyText: inProcessEmptyText }}
                                                    // rowSelection={passedDatarowSelection}
                                                    onChange={this.handleChange}
                                                    // footer={() => footer}
                                                    scroll={{ x: 2000 }}
                                                    bordered={false}
                                                    pagination={{
                                                        defaultPageSize: 10,
                                                        showSizeChanger: true,
                                                        size: "small",
                                                        total: inProcessRecords.length,
                                                        current: this.state.current,
                                                        itemRender: this.itemRender,
                                                        pageSizeOptions: ["10", "20", "50", "100"],
                                                        position: "bottom",
                                                        showTitle: false,
                                                        showTotal: (total, range) => <div> <span className="total-text-pagination">{this.props.label_keys.showing} {range[0]}-{range[1]} {this.props.label_keys.of} {total} </span>
                                                        <span className="page-count-span-bulk">{this.props.label_keys.page} {this.state.current} {this.props.label_keys.of} {this.state.TotalPage}</span></div>,
                                                        onChange: (page, defaultPageSize) => this.pageChanger(page, defaultPageSize, inProcessRecords),
                                                        onShowSizeChange: (current, size) => this.OnPageSizeChange(current, size, inProcessRecords)

                                                    }}
                                                />
                                            </div>

                                            <div role="tabpanel" id='failed-tab-panel' aria-labelledby='failed-tab' value={'3'}
                                                hidden={this.state.activeStatusTab !== '3'}
                                            >
                                                <Button className="download-record" key="console" disabled={!this.state.selectedRowKeys.length > 0}
                                                    aria-label={this.props.label_keys.downloadErrorRecords}
                                                    onKeyUp={e => {
                                                        if (e.keyCode === 13) {
                                                            this.handleDownloadErrorReport()
                                                        }
                                                    }}
                                                    onClick={() => this.handleDownloadErrorReport()}
                                                >
                                                    <Icon type="download" aria-hidden="true" alt="" role="img"
                                                        style={{ width: 'auto', height: '15px', marginTop: '-5px', marginRight: '-14%',
                                                         marginLeft: this.props.selectedLang === 'French' ? '-27%' : null }} />
                                                    {this.props.label_keys.downloadErrorRecords}
                                                </Button>
                                                <Table className='Req-table userlist paginationAlign pageArrowBulk'
                                                    rowKey={obj => obj.id}
                                                    components={components}
                                                    rowClassName={() => "editable-row"}
                                                    dataSource={failedRecords}
                                                    columns={columns}
                                                    locale={{ emptyText: failedRecordsEmptyText }}
                                                    rowSelection={passedDatarowSelection}
                                                    onChange={this.handleChange}
                                                    // footer={() => footer}
                                                    scroll={{ x: 2000 }}
                                                    bordered={false}
                                                    pagination={{
                                                        defaultPageSize: 10,
                                                        showSizeChanger: true,
                                                        size: "small",
                                                        total: failedRecords.length,
                                                        current: this.state.current,
                                                        itemRender: this.itemRender,
                                                        pageSizeOptions: ["10", "20", "50", "100"],
                                                        position: "bottom",
                                                        showTitle: false,
                                                        showTotal: (total, range) => <div> <span className="total-text-pagination">{this.props.label_keys.showing} {range[0]}-{range[1]} {this.props.label_keys.of} {total} </span>
                                                        <span className="page-count-span-bulk">{this.props.label_keys.page} {this.state.current} {this.props.label_keys.of} {this.state.TotalPage}</span></div>,
                                                        onChange: (page, defaultPageSize) => this.pageChanger(page, defaultPageSize, failedRecords),
                                                        onShowSizeChange: (current, size) => this.OnPageSizeChange(current, size, failedRecords)

                                                    }}
                                                />
                                                <div>{footer}</div>
                                            </div>

                                            <div role="tabpanel" id='comp-tab-panel' aria-labelledby='comp-tab' value={'4'}
                                                hidden={this.state.activeStatusTab !== '4'}
                                            >
                                                <Table className='Req-table userlist paginationAlign pageArrowBulk'
                                                    key={this.state.index}
                                                    components={components}
                                                    rowClassName={() => "editable-row"}
                                                    dataSource={completedRecords}
                                                    columns={columns}
                                                    locale={{ emptyText: completedEmptyText }}
                                                    // rowSelection={passedDatarowSelection}
                                                    onChange={this.handleChange}
                                                    // footer={() => footer}
                                                    scroll={{ x: 2000 }}
                                                    bordered={false}
                                                    pagination={{
                                                        defaultPageSize: 10,
                                                        showSizeChanger: true,
                                                        size: "small",
                                                        total: completedRecords.length,
                                                        current: this.state.current,
                                                        itemRender: this.itemRender,
                                                        pageSizeOptions: ["10", "20", "50", "100"],
                                                        position: "bottom",
                                                        showTitle: false,
                                                        showTotal: (total, range) => <div> <span className="total-text-pagination">{this.props.label_keys.showing} {range[0]}-{range[1]} {this.props.label_keys.of} {total} </span>
                                                        <span className="page-count-span-bulk">{this.props.label_keys.page} {this.state.current} {this.props.label_keys.of} {this.state.TotalPage}</span></div>,
                                                        onChange: (page, defaultPageSize) => this.pageChanger(page, defaultPageSize, completedRecords),
                                                        onShowSizeChange: (current, size) => this.OnPageSizeChange(current, size, completedRecords)

                                                    }}
                                                    onRow={(record, rowIndex) => {
                                                        return {
                                                            onClick: event => { console.log("Record::", completedRecords)
                                                                this.setState({ selectedRow: record }) },
                                                        }
                                                    }}
                                                />
                                                {/* <div>{footer}</div> */}
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <div role="tabpanel" id='fail-tab-panel' aria-labelledby='fail-tab' value='2'
                                    hidden={this.state.activeTab !== '2'} style={{ marginTop: '1.5%' }}>
                                    <Table className='Req-table userlist paginationAlign pageArrowBulk'
                                        rowKey={obj => obj.id}
                                        components={components}
                                        rowClassName={() => "editable-row"}
                                        dataSource={failedData}
                                        columns={columns}
                                        rowSelection={failedDatarowSelection}
                                        locale={{ emptyText: this.props.label_keys.dataUnavailable }}
                                        onChange={this.handleChange}
                                        scroll={{ x: 2000 }}
                                        bordered={false}
                                        pagination={{
                                            defaultPageSize: 10,
                                            showSizeChanger: true,
                                            size: "small",
                                            total: failedData.length,
                                            itemRender: this.itemRender,
                                            pageSizeOptions: ["10", "20", "50", "100"],
                                            position: "bottom",
                                            showTitle: false,
                                            showTotal: (total, range) => <div> <span className="total-text-pagination">{this.props.label_keys.showing} {range[0]}-{range[1]} {this.props.label_keys.of} {total} </span>
                                            <span className="page-count-span-bulk">{this.props.label_keys.page} {this.state.current} {this.props.label_keys.of} {this.state.TotalPage}</span></div>,
                                            onChange: (page, defaultPageSize) => this.pageChanger(page, defaultPageSize, failedData),
                                            onShowSizeChange: (current, size) => this.OnPageSizeChange(current, size, failedData)

                                        }}
                                        // footer={() => failedData.length > 0 && failedFooter}
                                        onRow={(record, rowIndex) => {
                                            return {
                                                onClick: event => { this.setState({ selectedRow: record }) },
                                            }
                                        }}
                                    />
                                    <div>{failedData.length > 0 && failedFooter}</div>
                                </div>
                                {/* </Tabs> */}
                            </>
                        }
                    </Card>
                    {this.state.detailsVisible === true && <CradDetailComponent
                        updateDetails={this.updateDetails}
                        cardDetails={this.state.data}
                        detailsVisible={this.state.detailsVisible}
                        selectedRow={this.state.selectedRow}
                        mobileNumber={this.state.data.mobileNumber}
                        selectedLang={this.props.selectedLang}
                        type={'bulkCard'}
                        onCLoseDetails={reloadFlag => {
                            console.log("Active element ::: ", this.state.prevActiveElement);
                            if (this.state.detailsVisible) {
                                if (document.body.contains(this.state.prevActiveElement)) {
                                    // setTimeout(() => {
                                    this.state.prevActiveElement.focus();
                                    // this.setState({
                                    //   prevActiveElement: null
                                    // })
                                    // }, 100);
                                }
                            }
                            this.setState({
                                detailsVisible: false,
                                prevActiveElement: null,
                            })
                        }}

                        label_keys={this.props.label_keys} />}
                    <div>
                        <Modal role='dialog'
                            aria-labelledby="title-pop"
                            title={<b className="visually-hidden" id="title-pop">Bulk Card Request Number</b>}
                            className="custom-modal custom-modal-bulk-card"
                            closable={false}
                            visible={(this.props.requestNo && this.props.isBack === true) || (this.props.requestNo && this.state.requestModalVisible === true ) ? true : false}
                            footer={[<Button ref={this.modalBtnRef} type="primary" onKeyDown={(e) => this.handleEsc(e, 'request')} onClick={() => this.handleBack()}>Ok</Button>]}
                        >
                            <div className="custom-modal-content">
                                <img src={Tick} alt='success' />
                                <p>{`${this.props.label_keys.viewStatus}${this.props.requestNo}`}</p>
                                <p>{`${this.props.label_keys.goTo}`} <b>{`"${this.props.label_keys.myRequests}"`}</b></p>
                            </div>
                        </Modal>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state, props) {
    return {
        userDetails: state.authenticationReducer.userDetails,
        token: state.authenticationReducer.token,
        iclanguageList: state.instantCardMultiLanReducer.iclanguageList,
        passedData: state.bulkcardReducer.passedData,
        failedData: state.bulkcardReducer.failedData,
        failedRecords: state.bulkcardReducer.failedRecords,
        uploadedRecords: state.bulkcardReducer.uploadedRecords,
        completedRecords: state.bulkcardReducer.completedRecords,
        inProcessRecords: state.bulkcardReducer.inProcessRecords,
        responseCreateCard: state.bulkcardReducer.cardCreationResponseObj,
        responseSentForApproval: state.bulkcardReducer.sentForApprovalObj,
        totalPage: state.bulkcardReducer.totalPage,
        requestNo: state.bulkcardReducer.requestNo,
        theme: state.authenticationReducer.theme
    }
}

const mapDispatchToProps = dispatch => {
    return {
        addData: (bulkobj, updatestate, label_keys) => {
            dispatch(addBulkCardData(bulkobj, updatestate, label_keys));
        },
        logOutFun: () => { dispatch(logOut()) },
        getBulkDataOnMount: ( label_keys, reqId) => {
            dispatch(getBulkData(label_keys, reqId ));
        },
        createCardBulkFun: (bulkobj, id, updatestate, label_keys, ReqFlag) => {
            dispatch(createCardBulk(bulkobj, id, updatestate, label_keys, ReqFlag));
        },
        sentForCardApprovalFun: (bulkobj, id, updatestate, label_keys, updateSentForApproval, updateRole) => {
            dispatch(sentForCardApproval(bulkobj, id, updatestate, label_keys, updateSentForApproval, updateRole));
        },
        validateBulkDataFun: (bulkobj, id, updatestate, label_keys, noMessage) => {
            dispatch(validateBulkData(bulkobj, id, updatestate, label_keys, noMessage));
        },
        deleteCardFunc: (bulkobj, id, updatestate, label_keys) => {
            dispatch(deleteCards(bulkobj, id, updatestate, label_keys));
        },
        removeBulkDataFunc: () => {
            dispatch(removeBulkData())
        }

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BulkUploadComponent)
