import initialState from './initialState';
import actionTypes from '../data/actionTypes'

const authenticationReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'LOGIN_REQUEST':
            return {
                ...state,
                isAuthenticating: true,
                authenticationError:false,
                credentialsResetError: false,
            }
        case 'LOGIN_SUCCESS':
            return {
                ...state,
                isAuthenticating: false,
                isAuthenticated: true,
                authenticationError:false,
                credentialsResetError: false,
                credentialsResetSuccess: false,
            }
        case 'SET_THEME':
                return {
                    ...state,
                    theme: action.payload
                }    
        case 'LOGIN_FAILURE':
            return {
                ...state,
                isAuthenticating: false,
                authenticationError: action.error,
                isAuthenticated: false,
                token: null,
                tokenTimestamp: null,
                credentialsResetError: false,
                credentialsResetSuccess: false
            }
        case 'LOGIN_INTERRUPTED':
            return {
                ...state,
                isAuthenticating: false,
                authenticationError: null,
                isAuthenticated: false
            }
        case 'SAVE_TOKEN':
            return {
                ...state,
                token: action.payload.token,
                tokenTimestamp: action.payload.tokenTimestamp
            }
        case 'SAVE_USER_DETAILS':
            return {
                ...state,
                userDetails: action.payload
            }
        case 'RESET_PASSWORD_REQUEST':
            return {
                ...state,
                isPasswordResetting: true,
                authenticationError:null,
                credentialsResetError: false,
                authenticationError: false
            }
        case 'RESET_PASSWORD_SUCCESS':
            return {
                ...state,
                isPasswordResetting: false,
                credentialsResetError: false,
                credentialsResetSuccess: true,
                authenticationError: false
            }
        case 'RESET_PASSWORD_STATE_RESET':
            return {
                ...state,
                isPasswordResetting: null,
                credentialsResetError: null,
                credentialsResetSuccess: null
            }
        case 'RESET_PASSWORD_FAILURE':
            return {
                ...state,
                isPasswordResetting: false,
                credentialsResetError: action.error || true,
                credentialsResetSuccess: false,
                authenticationError: false
            }
        case 'FORGOT_PASSWORD_REQUEST':
            return {
                ...state,
                isForgettingCredentials: true,
                forgotCredentialsSuccess: null,
                forgotCredentialsError: null,
            }
        case 'FORGOT_PASSWORD_SUCCESS':
            return {
                ...state,
                isForgettingCredentials: false,
                forgotCredentialsSuccess: true,
                forgotCredentialsError: false,
            }
        case 'FORGOT_PASSWORD_FAILURE':
            return {
                ...state,
                isForgettingCredentials: false,
                forgotCredentialsSuccess: false,
                forgotCredentialsError: action.error || true,
            }
        case 'FORGOT_PASSWORD_STATE_RESET':
            return {
                ...state,
                isForgettingCredentials: undefined,
                forgotCredentialsSuccess: null,
                forgotCredentialsError: null
            }
        case 'SAVE_TEMP_USERNAME':
            return {
                ...state,
                tempUsername: action.payload
            }
        case 'SEND_OTP_REQUEST':
            return {
                ...state,
                sendOTPRequest: true,
                sendOTPSuccess: null,
                sendOTPError: null,
            }
        case 'SEND_OTP_SUCCESS':
            return {
                ...state,
                sendOTPRequest: false,
                sendOTPSuccess: true,
                sendOTPError: null,
            }
        case 'SEND_OTP_FAILURE':
            return {
                ...state,
                sendOTPRequest: false,
                sendOTPSuccess: false,
                sendOTPError: action.error || true,
            }
        case 'VERIFY_OTP_REQUEST':
            return {
                ...state,
                verifyOTPRequest: true,
                verifyOTPSuccess: null,
                verifyOTPError: null,
            }
        case 'VERIFY_OTP_SUCCESS':
            return {
                ...state,
                verifyOTPRequest: false,
                verifyOTPSuccess: true,
                verifyOTPError: null,
            }
        case 'VERIFY_OTP_FAILURE':
            return {
                ...state,
                verifyOTPRequest: false,
                verifyOTPSuccess: false,
                verifyOTPError: action.error || true,
            }
        case 'ACTIVATE_ACCOUNT_STATE_RESET':
            return {
                ...state,
                sendOTPRequest: null,
                sendOTPSuccess: null,
                sendOTPError: null,
                verifyOTPRequest: null,
                verifyOTPSuccess: null,
                verifyOTPError: null,
            }
        case actionTypes.SAVE_REFRESH_TOKEN_TIMER: 
            return {
                ...state,
                refreshTokenTimer: action.payload
            }
        case 'SET_FLOW_CHECK':
            let a=action.payload
            return Object.assign({}, state, { registerFlowFlag: action.payload });
        default: return state
    }
}

export default authenticationReducer;