import React, { Component, Fragment } from 'react';
import { Form, Icon, Input, Button, Row, Col, Alert, Select } from 'antd';
import $ from 'jquery'
// import TextField from '@mui/material/TextField';
// import MenuItem from '@mui/material/MenuItem';
// import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import '../styling/login.css';
import '../FontIcons/style.css';
import '../styling/otpselect.css';
import { app_labels } from '../data/english_label'
import { errorMessages } from '../data/constants';
const FormItem = Form.Item;
/**
* renders when forget password is clicked in login screen
*/
class OTPValidationFormComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      verifyOTPVisible: false,
      otpDigits: [null, null, null, null, null, null],  // XW-61 issue resolve - increased otp digit lenght to 6
      validationError: null,
      mail: "Email",
      flagUsername: false,
      flagPassword: false,
      visible: false,
    }
    this.otpLength = 6 // XW-61 issue resolve - increased otp digit lenght to 6
    this.otpDigitsInputs = [null, null, null, null, null, null]
    this.digit = ["first", "second", "third", "fourth", "fifth", "sixth"]
  }

  /**
    * Lifecycle method
    * will be called onMount of the component
    */
  componentDidMount() {
    this.props.activateAccountStateReset()
    this.props.resetPasswordStateReset()
    if (window.location.hostname.includes('elan-solutions.elaneasypay') && this.props.type === 'forgotPassword') {
      $('.page-title').text("Forgot Password: Elan Easy Pay").focus();
      document.title = "Forgot Password: Elan Easy Pay";
    } else if (!window.location.hostname.includes('elan-solutions.elaneasypay') && this.props.type === 'forgotPassword') {
      $('.page-title').text("Forgot Password: US Bank Instant Card").focus();
      document.title = "Forgot Password: US Bank Instant Card";

    } else if (window.location.hostname.includes('elan-solutions.elaneasypay') && this.props.type === 'register') {
      $('.page-title').text("Register: Elan Easy Pay").focus();
      document.title = "Register: Elan Easy Pay";
    } else if (!window.location.hostname.includes('elan-solutions.elaneasypay') && this.props.type === 'register') {
      $('.page-title').text("Register: US Bank Instant Card").focus();
      document.title = "Register: US Bank Instant Card";

    }

  }

  componentDidUpdate(prevProps, prevState) {
    // show the verify otp screen
    if (!!prevProps.sendOTPSuccess === false && this.props.sendOTPSuccess === true) {
      this.setState(() => ({
        verifyOTPVisible: true
      }))
    }

    // auto focus the first otp field 
    if (prevState.verifyOTPVisible === false && this.state.verifyOTPVisible === true) {
      this.otpDigitsInputs[0].node.focus()
    }

    /* XW-61 issue - XW allows Reset password – invalid OTP attempts for unlimited times
      now redirecting to OTP screen on third wrong attempt of otp */
    if (prevProps.verifyOTPRequest && this.state.verifyOTPVisible && this.props.verifyOTPError && this.props.verifyOTPError.error && this.props.verifyOTPError.error.code === "authorization_error") {
      this.setState({
        verifyOTPVisible: false
      })
    }
  }

  handleUserNameValue(e){
    if(e.target.value == ""){
        this.setState({flagUsername: true})
    }else{
        this.setState({flagUsername: false})
    }
}

  /** Handles sending of OTP */
  renderSendOTPComponent() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div>
        <Row>
          <Col xs={{ span: 24, offset: 0 }} md={{ span: 19, offset: 5 }} lg={{ span: 14, offset: 10 }}>
            <Alert
              style={{ marginBottom: '5px', marginTop: '10px' }}
              message={errorMessages.messageOtpPrompt}
              type="info" />
          </Col>
        </Row>
        <Row>
          <Col xs={{ span: 24, offset: 0 }} md={{ span: 19, offset: 5 }} lg={{ span: 14, offset: 10 }}>
            <Form onSubmit={(e) => {
              e.preventDefault();
              this.props.form.validateFields((err, values) => {
                if (!err) {
                  this.props.sendOTP(values);
                }else{
                  if(this.state.flagUsername)
                    document.getElementById("username").focus();
                }
              });
            }} className="sendotp-form">
              <span class='visually-hidden page-title' tabIndex={-1}></span>
              <FormItem>
                {getFieldDecorator('username', {
                  rules: [{ required: true, message: <span id="username-error" className="form-ele-error"><span className="visually-hidden">Error: </span>{errorMessages.usernamePrompt}</span> }],
                })(
                  <Input required id="username" onChange={e => this.handleUserNameValue(e)} className="forgot-pass-email"
                  aria-describedby={this.state.flagUsername?"username-error": null} 
                  aria-label="email id" autocomplete="email" placeholder="Email ID*" />
                )}

              </FormItem>
              {this.props.type === 'forgotPassword' ? (
                <FormItem>
                  {getFieldDecorator('messageType', {
                    initialValue: "email", rules: [{ required: true, message: errorMessages.selectMessageType }],
                  })(
                    <Select className="sendotp-form" aria-label="Select"
                      style={{ border: '1px solid #737373' }}
                      aria-activedescendant = {this.state.visible ? "email-option" : null}
                      onDropdownVisibleChange={() => this.setState({visible : !this.state.visible})}
                      suffixIcon={<Icon type="down" aria-hidden="true" />}
                    >
                      {/* <Select.Option value="sms">SMS</Select.Option> */}
                      <Select.Option id="email-option" value="email">
                        <div >
                          Email
                        </div></Select.Option>
                    </Select>

                    // <div>
                    //   <TextField
                    //     className="sendotp-form"
                    //     id="sendotp-form"
                    //     select
                    //     variant="outlined"
                    //     SelectProps={{
                    //       IconComponent: KeyboardArrowDownOutlinedIcon
                    //     }}
                    //     value={this.state.mail}
                    //     onChange={(event) => { this.setState({ mail: event.target.value }) }}
                    //   >
                    //     <MenuItem value="Email">Email</MenuItem>
                    //   </TextField>
                    // </div>
                  )}
                </FormItem>
              ) : null}
              <Button aria-label="Send OTP" type="primary" htmlType="submit"
                className="login-form-button login-button forgot-pass-send-otp-btn"

                /* style={{ backgroundColor: '#0A41C5', borderRadius: '4px', color: 'white', borderColor: "#0A41C5", width: "190px" }} */
                disabled={this.props.sendOTPRequest}>
                {
                  this.props.sendOTPRequest === true ?
                    (
                      <Fragment>
                        <Icon type="loading" style={{ color: 'white' }} />
                      </Fragment>
                    ) : <span>{app_labels.sendOtp}</span>
                }
              </Button>
            </Form>
          </Col>
        </Row >
      </div>
    )
  }

  /** Handles verifying of OTP */
  renderVerifyOTPComponent() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div>
        <Row>
          <Col xs={{ span: 24, offset: 0 }} md={{ span: 19, offset: 5 }} lg={{ span: 14, offset: 10 }}>
            <Alert
              style={{ marginBottom: '5px', marginTop: '10px' }}
              message={errorMessages.sendOtpSuccessMessage}
              type="info" />
          </Col>
        </Row>
        <Row>
          <Col xs={{ span: 24, offset: 0 }} md={{ span: 19, offset: 5 }} lg={{ span: 14, offset: 10 }}>
            <Form onSubmit={(e) => {
              e.preventDefault();
              let otp = ''
              for (let i = 0; i < this.state.otpDigits.length; i++) {
                let otpDigit = this.props.form.getFieldValue(`otp-digit-${i}`);
                if (!(otpDigit >= '0' && otpDigit <= '9')) {
                  this.setState(() => ({
                    validationError: <span><span className="visually-hidden">Error: </span>{errorMessages.error_val_otp}</span>
                  }))
                  return
                }
                otp += otpDigit
              }
              this.props.verifyOTP({ otp })
            }} className="verifyotp-form">
              {
                (() => {
                  let otpDigitsInputs = []
                  for (let i = 0; i < this.otpLength; i++) {
                    otpDigitsInputs.push(
                      <Col span={4} key={i}> {/* adjusted span for six input field to enter otp*/}
                        <FormItem>
                          {getFieldDecorator(`otp-digit-${i}`)(
                            <Input
                              className="otp-password"
                              autoComplete="off"
                              aria-required="true"
                              maxLength="1"
                              aria-label={`OTP ${this.digit[i]} digit`}
                              style={{ textAlign: 'center', width: '80%' }}
                              ref={node => {
                                this.otpDigitsInputs[i] = {
                                  id: i,
                                  node
                                }
                              }}
                              onChange={(e) => {
                                this.clearError()
                                let value = e.target.value
                                this.props.form.setFieldsValue({
                                  [`otp-digit-${i}`]: value,
                                });
                                if (value >= '0' && value <= '9') {
                                  if (i < this.otpDigitsInputs.length - 1) this.otpDigitsInputs[i + 1].node.focus()
                                }
                              }}
                              onKeyUp={(e) => {
                                /* fixed backspace key functionality for otp input fields */
                                (i > 0 && e.keyCode === 8) && this.otpDigitsInputs[i - 1].node.focus()
                              }}
                            />
                          )}
                        </FormItem>
                      </Col>
                    )
                  }
                  return (
                    <Row>
                      {otpDigitsInputs}
                    </Row>
                  )
                })()
              }

              <FormItem style={{ textAlign: 'center' }}>
                <Button aria-label="verify" type="primary" htmlType="submit"
                  className="login-form-button login-button"
                  /*  style={{ backgroundColor: '#0A41C5', borderRadius: '4px', color: 'white', borderColor: "#0A41C5", width: "190px",  marginLeft: "-7%"   }} */
                  disabled={this.props.verifyOTPRequest}>
                  {
                    this.props.verifyOTPRequest === true ?
                      (
                        <Fragment>
                          <Icon type="loading" style={{ color: 'white' }} />
                        </Fragment>
                      ) : <span>Verify</span>
                  }
                </Button>
              </FormItem>
            </Form>
          </Col>
        </Row>
        <Row>
          <Col xs={{ span: 24, offset: 0 }} md={{ span: 19, offset: 5 }} lg={{ span: 14, offset: 10 }} style={{textAlign: "center"}}>
            <div role='alert' style={{ color: "#C92323" }}>

              {this.state.validationError}
            </div>

          </Col>
        </Row>
      </div>
    )
  }

  /**
  * To clear otp error messages
  */
  clearError() {
    this.setState(() => ({
      validationError: null
    }))
  }

  render() {
    return (
      <div>
        <Row style={{textAlign: "center"}}>
          <Col xs={{ span: 24, offset: 0 }} md={{ span: 19, offset: 5 }} lg={{ span: 14, offset: 10 }}>
            {
              this.props.sendOTPError ?
                <div role='alert' style={{ color: "#C92323" }}>

                  {this.props.sendOTPError.error_description || this.props.sendOTPError.message || errorMessages.otpSendingGenericErrorMessage}</div>
                :
                this.props.verifyOTPError ? /* added condition to check third wrong otp attempt to display error message accordingly*/
                  this.state.verifyOTPVisible && this.props.verifyOTPError.error && this.props.verifyOTPError.error.code === "authorization_error" ? null
                    : <div role='alert' style={{ color: "#C92323" }}>
                      {this.props.verifyOTPError.error_description || this.props.verifyOTPError.serviceStatus.message || this.props.verifyOTPError.message || errorMessages.otpVerificationGenericErrorMessage}</div>
                  : null
            }
          </Col>
        </Row>
        <Row>
          <Col xs={{ span: 24, offset: 0 }} md={{ span: 19, offset: 5 }} lg={{ span: 14, offset: 10 }}>
            {/*  <div className='loginIconDiv'> */} {/* <span><i className="icon-profile userIcon_font" style={{ fontWeight: "lighter", lineHeight: '2' }} /></span> */}
          </Col>

        </Row>
        {
          this.state.verifyOTPVisible === true ? (
            this.renderVerifyOTPComponent()
          ) : (
            this.renderSendOTPComponent()
          )
        }
      </div>
    )
  }
}


const WrappedOTPValidationFormComponent = Form.create()(OTPValidationFormComponent);

export default WrappedOTPValidationFormComponent

