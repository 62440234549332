import React from 'react';
import Fuse from "fuse.js";
import { AutoComplete } from 'antd'
import { connect } from 'react-redux';
const { airports } = require('../../data/airport.js');
const Option = AutoComplete.Option;

class AirportAutosuggest extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      results: []
    }

    this.options = {
      shouldSort: true,
      threshold :0.0, 
      maxPatternLength: 32,
      keys:['city', 'iata']
     /*  keys: [ {
        name: 'iata',
        weight: 0.5
      },  {
        name: 'name',
        weight: 0.3
      }, {
          name: 'city', 
          weight: 0.2 
        }] */
    };

    this.fuse = new Fuse(airports, this.options)

    // Create refs
    this.inputField = React.createRef()
  }
  /**
   * @param  {} value
   * search depending upon the entered string 
   */
  handleSearch(value) {
    let results = []
    // let selectedIndex = this.state.selectedIndex
    if (value) {
      setTimeout(() => {
        let arrayOfResults = this.fuse.search(value);
        results = arrayOfResults.slice(0);
        this.setState(() => ({
          results
        }))
      }, 0)
    }
  }
  /**
   * @param  {} result
   * rendering the autosuggestion dropdown
   */
  renderResult(res, index) {
    let result = res.item ? res.item : res
    return <Option key={index} value={result.city + ' (' + result.iata + ')'}>
      {/*  <div>
        <b>{result.iata}</b> - {result.name}       
      </div> */}
      <div>
        {result.city + ', ' + result.country}
      </div>
      <div>
        <b>{result.iata + ' (' + result.name + ')'}</b>
      </div>
    </Option>
  }

  isValidInput = (value) => {
    // commenting this for now; until the regex can be
    // let pattern = /[@.!$%^*/#&\\+]/gi
    return true/* pattern.test(value) */;

  }
  render() {
    return (
      <AutoComplete
        backfill={true}
        dataSource={this.state.results.map(this.renderResult.bind(this))}
        onSearch={(value) => {
          if (!this.isValidInput(value)) {
            return
          }
          this.handleSearch(value)
        }}
        disabled={this.props.disableFlag}
        onChange={(value) => {
          if (!this.isValidInput(value)) {
            return
          }
          this.props.clearInvalidField()
          this.props.handleTravelPlaceInput1(value, this.props.type)
        }

        }
        onSelect={(value) => {
          if (!this.isValidInput(value)) {
            return
          }
          this.props.clearInvalidField()
          this.props.handleTravelPlaceInput(value, this.props.type)
        }}
        optionLabelProp="value"
        dropdownMatchSelectWidth={false}
        defaultValue={this.props.value}
      /* value={this.props.value} */
      >
        <input type="text"
          autocomplete="off"
          placeholder={this.props.type === "origin" ? this.props.app_labels.selectOrigin : this.props.app_labels.selectDestination}
          aria-label={this.props.labelName}
          ref={(ele) => this.props.setInputRef(ele)}
          className={this.props.className || 'airport-autocomplete-input'}
          id={this.props.id || ''}
        />
      </AutoComplete>
    )
  }
}

function mapStateToProps(state, props) {
  return {
    app_labels: state.adduserReducer.app_labels,
  };
}
export default connect(mapStateToProps)(AirportAutosuggest)