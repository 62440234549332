import React from 'react';
// import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import 'antd/dist/antd.css';
// import { Tabs } from 'antd';
import { Tabs, Tab } from '@mui/material' ;
// import axios from 'axios';
import CreateNewUserProfileComponent from './createNewUserProfile';
import CreateBulkUserProfileComponent from './createBulkUserProfile';
// const { URLs , errorMessages } = require('../../../data/constants.js');
// const TabPane = Tabs.TabPane;
class CreateUserProfileComponent extends React.Component {
    
    lblSingle = "SingleUser";
    lblBulk = "BulkUser";

    constructor(props) {
        super(props);
        this.state = {
            cadreRoles: [],
            retrieve: false,
            activeTab: "SingleUser"
        }
    }

    componentDidMount(){
        // const tabs = document.querySelectorAll('[role="tab"]');
        // for(let i = 0; i < tabs.length; i++){
        //     if (i === 0) tabs[i].style.marginLeft =  "5px";
        //     tabs[i].setAttribute("tabindex", "0");
        //     tabs[i].addEventListener('keydown', (e) => {
        //         const key = String(i+1)
        //         if(e.keyCode === 13 || e.key === 'Enter') {
        //             this.setState({activeTab: key})
        //         }
        //     })
        // }
        const presentation = document.querySelector(".ant-layout-content");
        const banner = document.querySelector(".ant-layout-content>div");
        const main = document.querySelector(".container-fluid");
        banner.setAttribute("role", "banner");
        presentation.setAttribute("role", "presentation")
        main.setAttribute("role", "main")
    }
    
    callback = (event, tabName) => {
        console.log(tabName);
        this.setState({activeTab: tabName})
    }
    render() {
        
        return (
            <div className="careteuser_class">
                <Tabs style={{ marginLeft: "2%", borderBottom: '1px solid #333333' }} className="request-extra" 
                    value={this.state.activeTab} onChange={this.callback}> 
                        <Tab disableRipple id="single-tab" aria-controls='single-tab-panel' className="single-tab" value={this.lblSingle} label={
                            <span id='singleTab' ref={'UserTab'} >
                                {this.props.app_labels.createNewUserProfile}
                            </span>}  
                            />
                        
                        <Tab disableRipple id="bulk-tab" aria-controls='bulk-tab-panel' className="bulk-tab" value={this.lblBulk} label={ <span id='bulkTab' 
                            ref={'bulkUserTab'}
                            >{this.props.app_labels.createNewBulkUserProfile} </span>} /> 
                                            
                </Tabs>
                <div role="tabpanel" id='single-tab-panel' aria-labelledby='single-tab' value={this.lblSingle}
                    hidden={this.state.activeTab !== this.lblSingle} 
                    style={{marginTop: '2%'}}
                >
                    <CreateNewUserProfileComponent 
                        app_labels = {this.props.app_labels} 
                        cadreRoles={this.props.cadreRoles} 
                        companyDetails={this.props.companyDetails} 
                        selectedRole={this.props.selectedRole} />
                </div>
                <div role="tabpanel" id='bulk-tab-panel' aria-labelledby='bulk-tab' value={this.lblBulk}
                    hidden={this.state.activeTab !== this.lblBulk} 
                    style={{marginTop: '2%'}}
                >
                    <CreateBulkUserProfileComponent app_labels = {this.props.app_labels} cadreRoles={this.props.cadreRoles} />
                </div>
                {/* <Tabs defaultActiveKey="1" activeKey={this.state.activeTab} onChange={this.callback} style={{ marginLeft: "2%" }}>
                    <TabPane tab={this.props.app_labels.createNewUserProfile} key="1">
                        <CreateNewUserProfileComponent 
                        app_labels = {this.props.app_labels} 
                        cadreRoles={this.props.cadreRoles} 
                        companyDetails={this.props.companyDetails} 
                        selectedRole={this.props.selectedRole} />
                    </TabPane>
                    <TabPane tab={this.props.app_labels.createNewBulkUserProfile} key="2"><CreateBulkUserProfileComponent app_labels = {this.props.app_labels} cadreRoles={this.props.cadreRoles} /></TabPane>
                </Tabs> */}
            </div>
        );
    }
}
function mapStateToProps(state, props) {
    return {
        token: state.authenticationReducer.token,
    };
}
export default connect(mapStateToProps)(CreateUserProfileComponent);