import React, { Component, Fragment } from 'react';
import { Form, Row, Col, notification } from 'antd';
import axios from 'axios'
import { connect } from 'react-redux';
import { flowCheckFlag } from '../actions/authenticationActions';
import '../styling/login.css';
import '../FontIcons/style.css';
import { errorMessages, icRelatedUrls, URLs } from '../data/constants';
import { app_labels } from '../data/english_label';
import history from './history';
const FormItem = Form.Item;
/* const loginBg = {
   backgroundImage: 'url("../images/loginBG.png")',
   backgroundRepeat: 'no-repeat',
   backgroundSize: 'cover',
   backgroundPosition: 'center',
 }; 
*/



class OpenSourceLicenseComponent extends Component {
    constructor(props){
        super(props);
        this.state={
            licenseData: '',
        }
    }

    componentDidMount() {
        if(window.location.hostname.includes('elan-solutions.elaneasypay'))
            document.title="Open Source Licenses: Elan Easy Pay";
        else
            document.title="Open Source Licenses: US Bank Instant Card";
        this.onLicensesClick()
        window.onpopstate = () => {
            this.redirect();
        };
    }

    redirect() {
        history.forward();
        // history.push('/login');
    }

    // componentWillMount() {
    //     window.removeEventListener("popstate", this.redirect);
    // }

    onLicensesClick = () =>{
        axios.get(URLs.comUrl + 'getOpenSourceLicence', {
            headers: {
              "Authorization": "Basic Q2FkcmUtV2ViOmNhZHJl"
            }
        }).then(response => {

            console.log("Open source licenses:::", response)
            this.setState({licenseData: response.data.data})
        }).catch(error => {
            console.log("Error:::", error);
            notification.error({
                message: <span  role='alert' aria-live="assertive" aria-atomic="true">
                    <span className="visually-hidden"> Error: </span>
                    {this.props.label_keys.couldNotcompleteReq}</span>,
                duration: 3,
                style: {
                    backgroundColor: '#fff1f0',
                    border: "1px solid #ffa39e",
                }
            })
        })
    }

    

    render() {
        return ( this.state.licenseData &&
            <div>
                <Row>
                    <Col className={window.location.hostname.includes('elan-solutions.elaneasypay') ? "headingStyle" :"headingStyle-ic"} role="heading" aria-level="1">{this.props.label_keys.openSourcesLicenses}</Col>
                </Row>
                <br />
                <Row>
                    <Col className='scrollable-container ic-open-source' span={24}>
                        <div style={{marginRight: '5%'}} dangerouslySetInnerHTML={{ __html: this.state.licenseData }}></div>
                    </Col>
                </Row><br />
            </div>
            
        );
    }
}

const WrappedNormalLoginForm = Form.create()(OpenSourceLicenseComponent);

const mapDispatchToProps = dispatch => {
    return {
        flowCheckFlag: (flag) => {
            dispatch(flowCheckFlag(flag));
        }
    }
}

export default connect(null, mapDispatchToProps)(WrappedNormalLoginForm)