import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import { Form, Icon, Input, Button, Alert, Row, Col } from 'antd';
import '../../styling/login.css';
import { errorMessages } from '../../data/constants';
import passwordValidator from 'password-validator'
import lock from '../../images/provisioner/elan/lock.svg'
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
const FormItem = Form.Item;
class ResetPasswordFormComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            passwordsMatch: true,
            type: 'password',
            flagValidation: false,
            flagPassword: false,
            flagConfirmPassword: false
        }
    }

    componentDidMount() {
        this.props.resetPasswordStateReset()
        this.passwordValidatorSchema = new passwordValidator()
        this.passwordValidatorSchema
            .is().min(8) // Minimum length 8 
            .is().max(15) // Maximum length 15
            .has().uppercase() // Must have uppercase letters
            .has().lowercase() // Must have lowercase letters
            .has().digits() // Must have digits
            .has().symbols()
            .has().not().spaces() // Should not have spaces
        if(window.location.hostname.includes('elan-solutions.elaneasypay'))
            document.title= this.props.registerFlowFlag ? "Set Password: Elan Easy Pay" : "Reset Password: Elan Easy Pay" ;
        
    }

    showPasswordsDontMatchWarning() {
        this.setState(() => ({
            passwordsMatch: false
        }))
    }

    clearPasswordsDontMatchWarning() {
        this.setState(() => ({
            passwordsMatch: true
        }))
    }

    passwordChange = (e) =>{
        if(e.target.value == ""){
            this.setState({flagPassword: true, passwordsMatch: true})
        }else{
            this.setState({flagPassword: false, passwordsMatch: true})
        }
    }

    confirmPasswordChange = (e) =>{
        if(e.target.value == ""){
            this.setState({flagConfirmPassword: true, passwordsMatch: true})
        }else{
            this.setState({flagConfirmPassword: false, passwordsMatch: true})
        }

    }

    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <div>
                <Row>
                    <Col xs={{ span: 24 }} md={{ span: 20 }} lg={{ span: 24 }}>
                        {this.props.registerFlowFlag ? <>
                            <Row>
                                <Col className="headingStyle">{this.props.label_keys.welcomeInstantCard}</Col>
                            </Row><br /><br /><br />
                            <Row>
                                <Col xs={{ span: 24 }} md={{ span: 20 }} lg={{ span: 24 }}>
                                    <label className='reset-password-text' role="heading" aria-level="1">{this.props.label_keys.setPassword}</label>
                                </Col>
                            </Row>
                        </> :
                            <>  <img alt="" src={lock}></img><br /><br />
                                <label className='reset-password-text' role="heading" aria-level="1">{this.props.label_keys.resetPassword}</label><br /></>}
                        <label>{this.props.label_keys.passwordMinimumChar}</label><br />
                    </Col>
                </Row>
                <Form onSubmit={(e) => {
                    e.preventDefault();
                    this.props.form.validateFields((err, values) => {
                        if (!err) {
                            if (values.password !== values.confirmPassword) {
                                this.showPasswordsDontMatchWarning()
                            } else {
                                this.clearPasswordsDontMatchWarning()
                                this.props.onSubmit(values)
                            }
                        }
                    });
                }} className="create-card-details-body pass-reset-body" style={{ width: '50%', marginBottom: '3%' }}>
                    {
                        this.state.passwordsMatch === false ? (
                            <Alert
                                role="alert"
                                style={{ marginBottom: '5px' }}
                                message={this.props.label_keys.passwordNotMatch}
                                type="error" />
                        )
                            : null
                    }
                    {
                        this.props.requestType === 'otp' ?
                            (
                                <FormItem>
                                    {getFieldDecorator('otp', {
                                        validateFirst: true,
                                        rules: [{ required: true, message: errorMessages.otpTypeMessage },
                                        {
                                            validator: function (rule, value, callback) {
                                                let v = String(value)
                                                if (v.length !== 4) return callback(errorMessages.error_val_otp)
                                                for (let i = 0; i < v.length; i++) {
                                                    if (isNaN(+v[i])) return callback(errorMessages.error_val_otp)
                                                }
                                                callback()
                                            }
                                        }
                                        ]
                                    })(
                                        <Input required autoComplete="off" placeholder="OTP" style={{ textAlign: 'center' }} />
                                    )}
                                </FormItem>
                            )
                            :
                            null
                    }
                    <FormItem>
                        {getFieldDecorator('password', {
                            rules: [{ required: true, message: <div id="password-error" className="form-ele-error"><span><span className="visually-hidden">Error: </span>{this.props.label_keys.enterPassword}</span></div> },
                            {
                                validator: (rule, value, callback) => {
                                    let v = String(value)
                                    if (this.passwordValidatorSchema.validate(v) !== true) {
                                        this.setState({flagValidation: true})
                                        return callback(
                                            <div id="password-error-2" className="form-ele-error">
                                                <span className="visually-hidden">Error: </span>
                                                {this.props.label_keys.passwordNotMeetStrength}
                                            </div>
                                                )
                                    } else {
                                        this.setState({flagValidation: false})
                                        return callback()
                                    }
                                }
                            }],
                            validateFirst: true
                        })(
                            <Input required onChange={this.passwordChange.bind(this)}
                            aria-describedby={ (!this.state.flagValidation && !this.state.flagPassword? null :
                                (this.state.flagValidation && !this.state.flagPassword)?"password-error-2":
                                "password-error")}
                             autocomplete="new-password" aria-label="password" className="loginForm" type={this.state.type} placeholder="New Password*" />

                        )}
                        {this.state.type == 'password' ? <Col span={2} role="button" tabIndex={0} className="show-password show-password-align" style={{width: "20%"}}
                         onClick={e => { this.setState(() => ({ type: 'text' })) }} ><b>{this.props.label_keys.show.toUpperCase()}</b></Col> : null}
                        {this.state.type == 'text' ? <Col span={2} role="button" tabIndex={0} className="show-password show-password-align" style={{width: "20%"}}
                         onClick={e => { this.setState(() => ({ type: 'password' })) }} ><b>{this.props.label_keys.hide.toUpperCase()}</b></Col> : null}
                    </FormItem>
                    <FormItem>
                        {getFieldDecorator('confirmPassword', {
                            rules: [{ required: true, message: <div id="confirm-pass-error" className="form-ele-error"><span><span className="visually-hidden">Error: </span>{this.props.label_keys.pleaseConfirmPass}</span></div>  },
                            {
                                // validator: (rule, value, callback) => {
                                //     let v = String(value)
                                //     if (this.passwordValidatorSchema.validate(v) !== true) {
                                //         return callback(this.props.label_keys.passwordNotMatch)
                                //     } else {
                                //         return callback()
                                //     }
                                // }
                            }],
                            validateFirst: true
                        })(
                            <Input required onChange={this.confirmPasswordChange.bind(this)} 
                            aria-describedby={ this.state.flagConfirmPassword ? 'confirm-pass-error' : null}
                            autocomplete="new-password" aria-label="confirm Password" className="loginForm" type="password" placeholder="Confirm Password*" />

                            /*   <Input autoComplete="off" prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="Confirm Password" /> */
                        )}
                    </FormItem>
                    <FormItem>
                        <Button htmlType="submit"
                            className='loginBtnClass'
                            // style={{ backgroundColor: '#0A41C5', borderRadius: '0.5rem', color: 'white' }}
                            disabled={this.props.isPasswordResetting ||
                                this.props.form.getFieldError('password') ||
                                !this.props.form.getFieldValue('password') ||
                                !this.props.form.getFieldValue('confirmPassword') ||
                                this.props.form.getFieldError('confirmPassword')}>
                            {
                                this.props.isPasswordResetting === true ?
                                    (
                                        <Fragment>
                                            <span>{this.props.label_keys.reseting}</span>
                                            <Icon type="loading" />
                                        </Fragment>
                                    ) : <span className='loginBtnText'>{this.props.label_keys.continue}</span>
                            }
                        </Button>
                    </FormItem>
                </Form>
            </div>

        );
    }
}

const mapStateToProps = (state) => {
    return {
        registerFlowFlag: state.authenticationReducer.registerFlowFlag
    }
}


const WrappedResetPasswordForm = Form.create()(ResetPasswordFormComponent);

export default connect(mapStateToProps)(WrappedResetPasswordForm)