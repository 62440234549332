import React, { Component } from 'react';
import MediaQuery from 'react-responsive';
import { connect } from 'react-redux';
import { Row, Col, Button } from 'antd';
import styled from 'styled-components';
import Apptour from './dashboard/appIntro';
import history from './history';
const StyledDiv = styled.div`
    width: 500px;
    margin: 50px auto;
    text-align: center
    padding: 20px;
`;

class InitialAppTutorial extends Component {
    constructor(props) {
        super(props);
        this.state = {
            startTuto: false,
        }
    }
    componentDidMount() {
        let elem = document.querySelector('body');
        elem.classList.add('login-page');
    }

    componentWillUnmount() {
        let elem = document.querySelector('body');
        elem.classList.remove('login-page');
    }
    startWithTuto = () => {
        this.setState({ startTuto: true });
    }
    skipTuto = () => {
        history.push('/dashboard');
    }
    render() {
        if (!this.state.startTuto) {
            return (
                <MediaQuery minDeviceWidth={1025}>
                    <Row >
                        <StyledDiv>
                            <div>
                                <Row className='tuto-margintop'>
                                    <Col xs={{ span: 22, offset: 2 }} md={{ span: 14, offset: 9 }} lg={{ span: 22, offset: 2 }}>
                                        <Row>
                                            <Col xs={{ span: 14, offset: 5 }} md={{ span: 14, offset: 5 }} lg={{ span: 14, offset: 5 }}>
                                                <div className='tutoheading'>Hi {this.props.userDetails !== null && this.props.userDetails !== undefined ? this.props.userDetails.firstName : null}!</div>
                                                <div className='tutoheading'> Welcome to new expense wizard desktop application</div>
                                            </Col>
                                        </Row>
                                        {/* <Row className='tuto-middlemargin'>
                                            <Col xs={{ span: 14, offset: 5 }} md={{ span: 14, offset: 5 }} lg={{ span: 14, offset: 5 }}>
                                                <div className='tutocontent'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam porta fringilla tortor, a pellentesque neque euismod nec.</div>
                                                <div className='tutocontent'> Welcome to new expense wizard desktop application</div>
                                            </Col>
                                        </Row> */}
                                        <Row className='tuto-middlemargin'>
                                            <Button style={{ float: 'left' }} className='button-wrapclass-tuto' type='primary' ghost onClick={this.skipTuto}>Skip Tutorial</Button>
                                            <Button style={{ float: 'right' }} className='button-wrapclass-tuto' type='primary' onClick={this.startWithTuto}>Start with Tutorial</Button>
                                        </Row>

                                    </Col>
                                </Row>
                            </div>
                        </StyledDiv>
                    </Row >
                </MediaQuery>
            )
        }
        else {
            return (
                <MediaQuery minDeviceWidth={1025}>
                    <Apptour />
                </MediaQuery>
            )
        }

    }
}

const mapStateToProps = (state) => {
    return {
        userDetails: state.authenticationReducer.userDetails,
    }
}

export default connect(mapStateToProps)(InitialAppTutorial);