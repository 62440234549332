import React from 'react';
import { connect } from 'react-redux';
import AdminCarousel from './adminCarousel';
import DelegateHiringManager from './delegateManager';
import CreateUserProfileComponent from './CreateUserProfile/createUserProfile';
import UserActionHeaderComponent from '../useractionheader.js';
import ViewandEditProfile from './ViewEditProfile/viewandeditprofile';
import CardMileage from './Settings/cardMileage';
import ReceiptRulesComponent from './ReceiptRules';
import ReportComponent from './reports';
import '../../styling/companysetup.css';
import { Spin, Layout, notification } from 'antd';
import { getExpenseCategoryAll, getTransactionTypeAll } from '../../actions/AdminActions/receiptruleActions';
import axios from 'axios';
import { loaderFocus } from '../../utils/accessability';
const { URLs, errorMessages } = require('../../data/constants.js');
const { Content, Footer } = Layout;
class AdminComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            companyDetails: "NA",
            selecteditem: 1,
            companyName: "",
            cadreRoles: [],
            expenseCat: [],
            expenseCatAll: [],
            catRetrieve: false,
            transactionType: [],
            transactionTypeAll: [],
            typeRetrieve: false,
            retrieve: false,
            retrievecomp: false,
            activeCompanyName: [],

        }
    }

    /**
     * Lifecycle method
     * will be called onMount of the component
     */
    componentDidMount() {
        if (!(this.state.retrieve === true && this.state.retrievecomp === false && this.state.catRetrieve === true && this.state.typeRetrieve === true)) {
            loaderFocus(".loader-color .ant-spin-text");
        }
        if (!this.props.userDetails.roles.includes('Company Admin')) {
            window.history.back();
        }

        this.setState({ retrievecomp: true });
        axios.get(URLs.tripUrl + "registeredCompanies/" + this.props.userDetails.companyId, {
            responseType: 'Json',
            headers: {
                "Authorization": 'Bearer ' + this.props.token.access_token
            }
        }).then(response => {
            this.setState({ retrievecomp: false });
            if (response.data.serviceStatus.statusCode === 200) {
                let companyArray = [];
                companyArray.push(response.data.data.companyName);
                let companyDetails = response.data.data;
                companyDetails.companyId = this.props.userDetails.companyId
                this.setState({ activeCompanyName: companyArray, companyName: response.data.data.companyName, companyDetails: companyDetails })

            }
            else if (response.data.serviceStatus.statusCode === 210) {
                notification.error({
                    message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{response.data.serviceStatus.message}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }
            else {
                notification.error({
                    message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{errorMessages.error_fetchCompany}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }
        }).catch(error => {
            this.setState({ retrievecomp: false });
            if (error.response !== undefined && error.response.data !== null && error.response.data !== undefined && error.response.data.serviceStatus !== undefined) {
                notification.error({
                    message: <span  role='alert' aria-live="assertive" aria-atomic="true">{error.response.data.serviceStatus.message}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }
            else {
                notification.error({
                    message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{errorMessages.generic_error}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }
        })
        this.getCadreRole();
        this.props.getExpenseCatAll(this.updateCatAllSpin, this.updateCatAllState);
        this.props.getTransTypeAll(this.updateTypeAllSpin, this.updateTypeAllState)
        /* this.getExpenseCategory();
        this.getTransactionType(); */
    }

    /**
     * Fetches the cadre roles using axios request
     */
    /**********getcadre roles *********/
    getCadreRole = () => {
        this.setState({ retrieve: false });
        axios.get(URLs.adminUrl + "cadreRoles", {
            responseType: 'json',
            headers: {
                "Authorization": 'Bearer ' + this.props.token.access_token
            }
        }).then(response => {
            this.setState({ cadreRoles: response.data.data, retrieve: true });
        }).catch((error) => {
            this.setState({ retrieve: true });
            if (error.response !== undefined && error.response.data !== null && error.response.data !== undefined && error.response.data.serviceStatus !== undefined) {
                notification.error({
                    message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{error.response.data.serviceStatus.message}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }
            else {
                notification.error({
                    message: <span  role='alert' aria-live="assertive" aria-atomic="true" ><span className="visually-hidden">Error </span>{errorMessages.generic_error}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }
        })
    }
    /**********getExpense category*************/
    /* getExpenseCategory = () => {
        axios.get(URLs.tripUrl + "getCompanyExpenseCategory", {
            responseType: 'json',
            headers: {
                "Authorization": 'Bearer ' + this.props.token.access_token
            }
        }).then(response => {
            if (response.data.serviceStatus.statusCode === 200) {
                this.setState({ expenseCat: response.data.data, catRetrieve: true });
            }
            else if (response.data.serviceStatus.statusCode === 210) {
                this.setState({ catRetrieve: true });
                notification.error({
                    message: <span  role='alert' aria-live="assertive" aria-atomic="true">{response.data.serviceStatus.message}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }
        }).catch((error) => {
            this.setState({ catRetrieve: true });
            if (error.response !== undefined && error.response.data !== null && error.response.data !== undefined && error.response.data.serviceStatus !== undefined) {
                notification.error({
                    message: <span  role='alert' aria-live="assertive" aria-atomic="true">{error.response.data.serviceStatus.message}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }
            else {
                notification.error({
                    message: <span  role='alert' aria-live="assertive" aria-atomic="true">{errorMessages.generic_error}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }
        })
    } */
    /**********getExpense category and All*************/

    updateCatAllSpin = (value) => {
        this.setState({ catRetrieve: value });
    }
    updateCatAllState = (value) => {
        this.setState({ expenseCatAll: value });
    }
    /**********get Transaction type and All*************/
    updateTypeAllSpin = (value) => {
        this.setState({ typeRetrieve: value });
    }
    updateTypeAllState = (value) => {
        this.setState({ transactionTypeAll: value });
    }
    /**********get Transaction type*************/
    /*   getTransactionType = () => {
          axios.get(URLs.tripUrl + "getTransactionTypes", {
              responseType: 'json',
              headers: {
                  "Authorization": 'Bearer ' + this.props.token.access_token
              }
          }).then(response => {
              if (response.data.serviceStatus.statusCode === 200) {
                  this.setState({ transactionType: response.data.data, typeRetrieve: true });
              }
              else if (response.data.serviceStatus.statusCode === 210) {
                  this.setState({ typeRetrieve: true });
                  notification.error({
                      message: <span  role='alert' aria-live="assertive" aria-atomic="true">{response.data.serviceStatus.message}</span>,
                      duration: 3,
                      style: {
                          backgroundColor: '#fff1f0',
                          border: "1px solid #ffa39e",
                      }
                  });
              }
          }).catch((error) => {
              this.setState({ typeRetrieve: true });
              if (error.response !== undefined && error.response.data !== null && error.response.data !== undefined && error.response.data.serviceStatus !== undefined) {
                  notification.error({
                      message: <span  role='alert' aria-live="assertive" aria-atomic="true">{error.response.data.serviceStatus.message}</span>,
                      duration: 3,
                      style: {
                          backgroundColor: '#fff1f0',
                          border: "1px solid #ffa39e",
                      }
                  });
              }
              else {
                  notification.error({
                      message: <span  role='alert' aria-live="assertive" aria-atomic="true">{errorMessages.generic_error}</span>,
                      duration: 3,
                      style: {
                          backgroundColor: '#fff1f0',
                          border: "1px solid #ffa39e",
                      }
                  });
              }
          })
      }
   */
    selectedItems = item => this.setState({ selecteditem: item })
    
    render() {
        if (this.props.cardAssigned) {
            this.props.handlenewTripandCard(this.props.creditCardNo, this.props.newtripobj);
        }
        return (
            this.state.retrieve === true && this.state.retrievecomp === false && this.state.catRetrieve === true && this.state.typeRetrieve === true ?
                <Layout>
                    <div><UserActionHeaderComponent app_labels = {this.props.app_labels} roles={"Company Admin"} /></div>
                    {
                        this.state.selecteditem === 2 ?

                            <div className="row">
                                <div className="col-sm-12 col-sm-offset-0">
                                    <ViewandEditProfile app_labels = {this.props.app_labels}  activeCompanyName={this.state.activeCompanyName} cadreRoles={this.state.cadreRoles} selectedItems={this.selectedItems} userrole={"Company Admin"} />
                                </div>
                            </div> :
                            <Content>
                                <div className="container-fluid" style={{ boxShadow: " 0px 3px 5px 5px #f2f2f2" }}>
                                    <div className="row" style={{ backgroundColor: "white", textAlign: "center" }}>
                                        <span className="company-name"><b>{this.state.companyName} Administration</b></span>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-10 col-sm-offset-1">
                                            <AdminCarousel app_labels = {this.props.app_labels}  index={1} initialSlide={this.state.selecteditem - 1} selectedItems={this.selectedItems} userrole={"Company Admin"} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12 col-sm-offset-0">
                                            <div>
                                                {this.state.selecteditem === 1 ? <CreateUserProfileComponent app_labels = {this.props.app_labels}  cadreRoles={this.state.cadreRoles} selectedRole={"Company Admin"} companyDetails={this.state.companyDetails} /> : null}
                                                {/*    {this.state.selecteditem === 3 ? <ExpenseCategoryComponent expenseCat={this.state.expenseCat} transactionType={this.state.transactionType} /> : null} */}
                                                {this.state.selecteditem === 3 ? <ReceiptRulesComponent app_labels = {this.props.app_labels}  expenseCat={this.state.expenseCatAll} transactionType={this.state.transactionTypeAll} superAdmin={false} activeCompanyName={this.state.companyDetails} /> : null}
                                                {/* {this.state.selecteditem === 6 ? <CompanySetupComponent /> : null} */}
                                                {/* {this.state.selecteditem === 3 ? <CardSettingComponent activeCompanyName={this.state.activeCompanyName} />} */}
                                                {this.state.selecteditem === 6 ? <CardMileage app_labels = {this.props.app_labels}  activeCompanyName={this.state.activeCompanyName} companyDetails={this.state.companyDetails} roles={"Company Admin"} /> : null}
                                                {this.state.selecteditem === 5 ? <DelegateHiringManager app_labels = {this.props.app_labels}  activeCompanyName={this.state.activeCompanyName} companyDetails={this.state.companyDetails} roles={"Company Admin"} /> : null}
                                                {this.state.selecteditem === 4 ? <ReportComponent  app_labels = {this.props.app_labels} /> : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Content>
                    }

                    <Footer id='footer'></Footer>
                </Layout> : <div className="dark-spinner loader-color"><Spin tip="Loading..." /></div>
        )
    }

}
function mapStateToProps(state, props) {
    return {
        userDetails: state.authenticationReducer.userDetails,
        token: state.authenticationReducer.token,
        app_labels: state.adduserReducer.app_labels,
    };
}
function mapDispatchToProps(dispatch) {
    return {
        getExpenseCatAll: (updateCatAllSpin, updateCatAllState) => {
            dispatch(getExpenseCategoryAll(updateCatAllSpin, updateCatAllState));
        },
        getTransTypeAll: (updateTypeAllSpin, updateTypeAllState) => {
            dispatch(getTransactionTypeAll(updateTypeAllSpin, updateTypeAllState))

        }

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AdminComponent);