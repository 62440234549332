import React, { useState } from 'react';
import { connect } from 'react-redux'
import{ MenuItem, Menu, Button} from '@mui/material';



export default function LanguageSelectBtn(props){

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    return(
        <>
        <div style={{fontStyle:'U.S.BankCircularWeb-Regular'}}>
            <Button
                className={props.theme.includes("admin") ? "cardAdmin-language-select" :"language-select"}
                id="language-select-button"
                disableRipple
                disableElevation
                style={{ height: 30, width: "84%", minWidth: "44%", borderBottom: "none",
                    border: "1px solid #666666" , borderRadius: "4px", color:"black",
                    marginLeft: "8%", backgroundColor: "white", fontSize: 14,
                    fontFamily: "U.S.BankCircularWeb-Regular"
                 }}
                aria-label={`Select language ${props.value === `FR` ? `French` : `English`}`}
                aria-controls={open ? 'language-select-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant="contained"
                disableElevation
                onClick={handleClick}
                >
                    <span>{props.value}</span>
                </Button>
                <Menu
                    id="language-select-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClick={handleClose}
                    onClose={handleClose}
                    MenuListProps={{
                    'aria-labelledby': 'language-select-button',
                    }}
                    onKeyDown={(event) => {
                        if (event.keyCode === 13) 
                            handleClose()
                        }
                    }
                    // style={{ widt }}
                >
                     {props.iclanguageList.map(item =>{
                            if(!props.theme.includes('elan') && (props.role && props.role != 'Super Admin' && props.role != 'Customer Support')){
                                return <MenuItem className="language-menuItem" tabIndex="0"
                                aria-label={item.code == 'EN'? 'English' : 'French'} onClick={props.onChange}
                                key={item.code} value={item.code} ><div className='langmenu-value'>{item.code}</div></MenuItem>
                                
                            }else{
                                if(item.code == 'EN')  
                                    return <MenuItem className="language-menuItem"  className="language-option" aria-label='English'
                                    onClick={props.onChange} key={item.code} value={item.code} ><div className='langmenu-value'>{item.code}</div></MenuItem>
                                else 
                                    return <MenuItem disabled className="language-option" key={item.code} value={item.code} >{item.code}</MenuItem> 
                            }
                        })
                    }
                    {/* <MenuItem  key="0"className="CreateCard-menuItem" onKeyDown={onKeyDown.bind(this)}  onClick={() => {
                        setvisible(!visible) && handleClose()
                    }}>
                    <div >{props.label_keys.singleCard}</div>
                    </MenuItem>
                    <MenuItem className="CreateCard-menuItem" key="1" onKeyDown={onKeyDown.bind(this)}   onClick={() => {
                        props.tabsClick(props.label_keys.bulkCardCreation)
                    }}>
                        <div  >{props.label_keys.bulkCard}</div>
                    </MenuItem> */}
                </Menu>
                </div>
        </>
    )
}
