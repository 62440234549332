import React from 'react'
import Header from '../header'
import Body from './body'
import './travelerDetails.css'

class TravelerDetails extends React.Component {
  render() {
    return (
      <div className='traveler-details'>
        <Header text='Traveler details'/>
        <Body travelerData={this.props.travelerData}/>
      </div>
    )
  }
}

export default TravelerDetails