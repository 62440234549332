import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Select, Menu, Dropdown, Icon } from 'antd';
import { logOut } from '../actions/logoutactions';
import { connect } from 'react-redux';
import MediaQuery from 'react-responsive';
import '../styling/header.css';
import history from './history';
import LogUSBank from '../FontIcons/fonts/US BANK.svg';
import { multiLanguageLabelRetrieve } from '../actions/multilanAction'
const { Option } = Select;
class HeaderComponent extends React.Component {
    /**clearing all session*/
    logout = () => {
        this.props.logOutFun();
        sessionStorage.clear();
    }

    languageChange = (value) => {
        this.props.multiLanguageLabelRetrieve(value);
    }

    /**Will route to corresponding pages on chnage of role
     * @param  {} e
     */
    roleChange = (e) => {
        console.log("in headercomponent: e.target.value is:"+e.target.value)
        if (e.target.value === "Company Admin") {
            history.push({
                pathname: '/admin',
                state: { role: 'Company Admin' }
            });
        }
        if (e.target.value === "Super Admin") {
            history.push({
                pathname: '/superadmin',
                state: { role: 'Super Admin' }
            });
        }
        if (e.target.value === "Customer Support") {
            history.push({
                pathname: '/customersupport',
                state: { role: 'Customer Support' }
            });
        }
        if (e.target.value === "Card Company Admin") {
            history.push({
                pathname: '/cardadmin',
                state: { role: 'Card Company Admin' }
            });
        }
        if (e.target.value === "Provisioner") {
            history.push({
                pathname: '/carddashboard',
                state: { role: 'Provisioner' }
            });
        }
        if (e.target.value === "Approver") {
            history.push({
                pathname: '/carddashboard',
                state: { role: 'Approver' }
            });
        }
        if (e.target.value === "Manager") {
            history.push('/dashboard');
        }
        if (e.target.value.toUpperCase() === "CSR") {
            history.push('/csr');
        }
    }

    helpTuto = () => {
        history.push('/tutorial');
    }
    render() {
        let userDetailsLocal = [];
        this.props.userDetails.roles.map(data => data!=='Traveler' && userDetailsLocal.push(data))
        const menu = (
            <Menu>
                <Menu.Item>
                    <span className='header-user'>
                        {this.props.userDetails.firstName + " " + this.props.userDetails.lastName}
                    </span>
                </Menu.Item>
                <Menu.Item>
                    <span onClick={e => {
                        e.stopPropagation()
                    }}>
                        <select onChange={this.roleChange} aria-label='roles of user'>
                            {userDetailsLocal.map(item =>
                                this.props.roles === item ? <option value={item} selected>{item}</option> : <option value={item}>{item}</option>
                            )}
                        </select>
                    </span>
                </Menu.Item>
                <Menu.Item>
                    <Link aria-label='logout button' className="logout-button" to="/login" onClick={this.logout}>{this.props.app_labels.logout_AdminView}</Link>
                </Menu.Item>
            </Menu>
        );
        return (
            <Row className='creatertrip-header useraction-header home_header_row'>
                <Col className='home_header'
                    xs={{ span: 2 }}
                    sm={{ span: 2 }}
                    md={{ span: 2 }}
                    lg={{ span: 1 }}
                    xl={{ span: 1 }}
                >
                    <Link aria-label='Home button' to="/dashboard"><i className="icon-Home header-home" /></Link>
                </Col>
                <Col
                    xs={{ span: 15 }}
                    sm={{ span: 14 }}
                    md={{ span: 7, offset: 1 }}
                    lg={{ span: 5, offset: 1 }}
                    xl={{ span: 3, offset: 1 }}
                >
                    <div>
                        {/*  <img src={LogUSBank} className='logo' /> */}
                        <img alt='US Bank logo' src={LogUSBank} className='logo' />&nbsp; &nbsp;&nbsp;<i role='img' aria-label="Expense wizard" className='icon-Expense-wizard iconFontsize' />
                    </div>
                </Col>

                <Col
                    xs={{ span: 7 }}
                    sm={{ span: 8 }}
                    md={{ span: 0 }}
                    lg={{ span: 0 }}
                    xl={{ span: 0 }}
                    style={{ paddingRight: '5px' }}
                >
                    <div style={{
                        justifyContent: 'flex-end'
                    }}>
                        <Dropdown overlay={menu} trigger='click'>
                            <span style={{
                                alignItems: 'center',
                                justifyContent: 'center',
                                display: 'flex'
                            }}>
                                <i className='icon-Group--1 header-profile'></i>
                                <i className='icon-Drop-down' style={{ marginLeft: '3px' }}></i>
                            </span>
                        </Dropdown>
                    </div>
                </Col>

                <Col
                    xs={{ span: 0, offset: 0 }}
                    sm={{ span: 0, offset: 0 }}
                    md={{ span: 1, offset: 6 }}
                    lg={{ span: 1, offset: 10 }}
                    xl={{ span: 1, offset: 12 }}
                    style={{ textAlign: 'center' }}
                >
                    <div
                    >{/* <i className="icon-Group--1 header-profile" /> */}
                    </div>
                </Col>
                <Col
                    xs={{ span: 0 }}
                    sm={{ span: 0 }}
                    md={{ span: 4 }}
                    lg={{ span: 3 }}
                    xl={{ span: 3 }}
                >
                    <div>
                        <Row style={{ width: '100%' }}>
                            <Col span={24} className='header-user'>
                                {this.props.userDetails.firstName + " " + this.props.userDetails.lastName}
                            </Col>
                            <Col span={24}  style={{ width: "fit-content" }}>
                                <select onChange={this.roleChange} aria-label='roles of user'>
                                    {userDetailsLocal.map(item =>
                                        this.props.roles === item ? <option value={item} selected>{item}</option> : <option value={item}>{item}</option>
                                    )};
                                </select>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col
                    xs={{ span: 0 }}
                    sm={{ span: 0 }}
                    md={{ span: 3 }}
                    lg={{ span: 3 }}
                    xl={{ span: 3 }}
                >
                    <div className="headerClass"><Link aria-label='logout button' className="logout-button" to="/login" onClick={this.logout}>{this.props.app_labels.logout_AdminView}</Link>
                        <MediaQuery minDeviceWidth={1025}>
                            <span style={{ marginLeft: '4%' }}><Icon title='Help' style={{ color: '#0a41c5' }} type="info-circle" onClick={this.helpTuto} />
                            </span>
                        </MediaQuery>
                        <Select defaultValue={this.props.selectedLanguage}
                            showArrow={false}
                            onChange={this.languageChange}
                            style={{ width: 44, borderBottom: "none", border: "1px solid lightgrey", borderRadius: "4px", marginLeft: "8%" }}  /*onChange={handleChange}*/>
                            {this.props.languageList.map(item =>
                               item.code === 'SP' || item.code === 'FR' ?
                               <Option value={item.name} disabled>{item.code}</Option> :
                               <Option value={item.name}>{item.code}</Option>
                            )}
                        </Select>
                    </div>
                </Col>
            </Row>

        )
    }
}
function mapStateToProps(state, props) {
    return {
        userDetails: state.authenticationReducer.userDetails,
        languageList: state.adduserReducer.languageList,
        selectedLanguage: state.adduserReducer.selectedLanguage,
        app_labels: state.adduserReducer.app_labels,
    };
}
function mapDispatchToProps(dispatch) {
    return {
        logOutFun: () => {
            dispatch(logOut());
        },
        multiLanguageLabelRetrieve: (values) => {
            dispatch(multiLanguageLabelRetrieve(values))
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(HeaderComponent);