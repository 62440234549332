import React, { Component, Fragment } from 'react';
import { Form, Icon, Input, Button, Row, Col } from 'antd';

const FormItem = Form.Item;

class RejectApprovalComponent extends Component {


  sendForReject = (value) =>{
    this.props.approvalFunc('Rejected',value);
    this.props.form.resetFields();
  }

  render() {
    const onReset = () => {
      this.props.form.resetFields();
      this.props.onCancel()
  };
    const { getFieldDecorator } = this.props.form;
    const { TextArea } = Input;
    return (
      <div>
        <Row>
          <Col>
            <Form onSubmit={(e) => {
              e.preventDefault();
              this.props.form.validateFields((err, values) => {
                if (!err) {                  
                  this.sendForReject( values.remarks);
                }
              });
            }} >
              
              <FormItem>
                {getFieldDecorator('remarks', {
                  
                  rules: [{ required: true,  message:<div id="remark-error"><span className="visually-hidden">Error:</span>{this.props.label_keys.pleaseEnterRemarks}</div>,  }],
                })(
                  <TextArea required aria-label="Rejection Remarks" className="Reject_modal"
                   maxLength={500} aria-describedby="remark-error" />
                  )}
              </FormItem>
              <FormItem style={{ textAlign: 'center' }}>
              <Button htmlType="button" onClick={onReset} className= 'cancel-btn-ant modal-cancel-zoom'>
               {this.props.label_keys.cancel}
              </Button>&nbsp;&nbsp;

                <Button aria-label="Submit" type="primary" htmlType="submit"
                onKeyDown={e =>{
                  if(e.key === "Tab"){
                    setTimeout(() => {
                      this.props.reference.current.focus();
                  }, 100)
                  }
                }}
                  
                  >{this.props.label_keys.submit}
                  
                </Button>
               
              </FormItem>
            </Form>
          </Col>
        </Row>
      </div>
    )
  }
}


const WrappedRejectApprovalComponent = Form.create()(RejectApprovalComponent);

export default WrappedRejectApprovalComponent

