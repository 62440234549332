import React from 'react'
import { Col } from 'antd'
import ChatHistory from './ChatHistory'

class ChatHistoryContainer extends React.Component {
  render() {
    return (
      <Col
        xs={{ span: 24 }}
        sm={{ span: 24 }}
        md={{ span: 8 }}
        lg={{ span: 8 }}
        xl={{ span: 6 }}
      >
        <ChatHistory transactionChatHistory={this.props.transactionChatHistory} />
      </Col>
    )
  }
}

export default ChatHistoryContainer