import axios from 'axios';
import React from 'react';
import { notification } from 'antd';
const { URLs, errorMessages } = require('../data/constants.js');
function getAllDetail(data) {
    return {
        type: "ALL_DETAILS",
        data
    }
}
export function fetchingUserTripsDbCompletes(data) {
    return {
        type: "IS_DB_USRTRIP_FETCHING",
        data
    }
}

/**
 * Fetches all the trip details
 */
export function getAllDetails() {
    return (dispatch, getState) => {
        let {
            token,
        } = getState().authenticationReducer;

        dispatch(fetchingUserTripsDbCompletes(true));
        axios.get(URLs.tripUrl + 'getUserTrips'/*?buster=' + new Date().getTime()*/, {
            responseType: 'json',
            headers: {
                "Authorization": 'Bearer ' + token.access_token
            }
        }).then(result => {
            dispatch(fetchingUserTripsDbCompletes(false));
            if (result.data.serviceStatus.statusCode === 210) {
                notification.error({
                    message: <span  role='alert' aria-live="assertive" aria-atomic="true" ><span className="visually-hidden">Error </span>{result.data.serviceStatus.message}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }
            else if (result.data.serviceStatus.statusCode === 200) {
                dispatch(getAllDetail(result.data.travelerTripDetails));
            }
            else {
                notification.error({
                    message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{errorMessages.error_pendingAction}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }
        }).catch((error) => {
            dispatch(fetchingUserTripsDbCompletes(false));
            if (error.response !== undefined && error.response.data !== null && error.response.data !== undefined && error.response.data.serviceStatus !== undefined) {
                notification.error({
                    message: <span  role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{error.response.data.serviceStatus.message}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }
            else {
                notification.error({
                    message: <span  role='alert' aria-live="assertive" aria-atomic="true" ><span className="visually-hidden">Error </span>{errorMessages.generic_error}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                });
            }
        })
    }
}
