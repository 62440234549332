import React from 'react';
class ToMobileApp extends React.Component {
    componentDidMount() {
        window.location.replace('ExpenseWizard-Mobile://login')
        setTimeout(function () { window.open('https://apps.apple.com/us/app/u-s-bank-expense-wizard/id1471213964', '_blank') }, 250);
    }
    render() {
        return (
            <div style={{ textAlign: "center", marginTop: "4%" }}>
                <h2>Please wait...</h2>
                {/*  <a href="ExpenseWizard-Mobile://login">
                    <Button className='button-wrapclass-tuto' type='primary'><Icon type="appstore" theme="filled" style={{ color: "white" }} />App Store</Button>
                </a> */}
            </div>
        )
    }
}
export default ToMobileApp;