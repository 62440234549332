import React from 'react'
import { Row, Col, Form, Input, DatePicker, Radio, Button, Select, InputNumber } from 'antd'
import moment from 'moment'
const Option = Select.Option
const dateFormat = 'MM/DD/YYYY';

class TransactionDetailsForm extends React.Component {

  handleReset = () => {
    this.props.form.resetFields();
  }

  handleSubmit() {
    this.props.form.validateFields((err, values) => {
      if(!err) {
        this.props.updateTransactionDetails(values)
      }
    });
  }

  renderFormField(field) {
    const { getFieldDecorator } = this.props.form;
    switch (field.type) {
      case 'SelectOne':
        return (
          <Form.Item>
            {getFieldDecorator(`${field.name}`, {
              rules: [{
                required: true, message: `Please enter ${field.label}`,
              }],
              initialValue: field.systemValue && field.systemValue !== "" ? field.systemValue : undefined
            })(
              <Select className="csr_input" disabled={!this.props.isEditable}>
                {
                  field.options.map(option => (
                    <Option key={option.code}>{option.label}</Option>
                  ))
                }
              </Select>
              )}
          </Form.Item>
        )
      case 'DateValue':
        return (
          <Form.Item>
            {getFieldDecorator(`${field.name}`, {
              rules: [{
                required: true, message: `Please enter ${field.label}`,
              }],
              initialValue: field.systemValue && field.systemValue !== "" ? moment(field.systemValue) : undefined
            })(
              <DatePicker className={!this.props.isEditable ? "csr_datepicker": null} disabled={!this.props.isEditable} format={dateFormat}/>
              )}
          </Form.Item>
        )
      case 'Boolean':
        return (
          <Form.Item>
            {getFieldDecorator(`${field.name}`, {
              rules: [{
                required: true, message: `Please enter ${field.label}`,
              }],
              initialValue: field.systemValue && field.systemValue !== "" ? field.systemValue : 'yes'
            })(
              <Radio.Group className="csr_input" disabled={!this.props.isEditable}>
                <Radio className="radio" value={'yes'}> Yes </Radio>
                <Radio className="radio" value={'no'}> No </Radio>
              </Radio.Group>
              )}
          </Form.Item>
        )
      case 'String':
        return (
          <Form.Item>
            {getFieldDecorator(`${field.name}`, {
              rules: [{
                required: true, message: `Please enter ${field.label}`,
              }],
              initialValue: field.systemValue && field.systemValue !== "" ? field.systemValue : undefined
            })(
              <Input className="csr_input" disabled={!this.props.isEditable} />
              )}
          </Form.Item>
        )
      case 'Number':
        return (
          <Form.Item>
            {getFieldDecorator(`${field.name}`, {
              rules: [{
                required: true, message: `Please enter ${field.label}`,
              }],
              initialValue: field.systemValue && field.systemValue !== "" ? field.systemValue : undefined
            })(
              <InputNumber className="csr_input" disabled={!this.props.isEditable} />
              )}
          </Form.Item>
        )
      default:
        return (
          <Form.Item>
            {getFieldDecorator(`${field.name}`, {
              rules: [{
                required: true, message: `Please enter ${field.label}`,
              }],
              initialValue: field.name === "allocation" ? (field.systemValue && field.systemValue !== "" ? field.systemValue[0].allocationId : undefined) : (field.systemValue && field.systemValue !== "" ? field.systemValue : undefined)
            })(
              <Input  className="csr_input" disabled={!this.props.isEditable} />
              )}
          </Form.Item>
        )
    }
  }
  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form layout='horizontal'
        style={{ paddingLeft: '20px', paddingRight: '20px' }}
        className="transaction-questionnaire-form"
      >
        <Row gutter={16}>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 24 }}
            xl={{ span: 12 }}
          >
            <Row>
              <Col span={24} style={{ marginBottom: '5px' }}>
                Expense Category
              </Col>
              <Col span={24}>
                <Form.Item>
                  {getFieldDecorator('expenseCategory', {
                    initialValue: this.props.expenseCategory
                  })(
                    <Input className="csr_input" disabled={true}/>
                    )}
                </Form.Item>
              </Col>
            </Row>
          </Col>

          {this.props.transactionQuestionnaire.map((field, index) => (
            field.name === "allocation" && field.systemValue[0].entity1Code &&
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
                xl={{ span: 12 }}
              >
                <Row>
                  <Col span={24} style={{ marginBottom: '5px' }}>
                    Entity Code 1
                  </Col>
                  <Col span={24}>
                    <Form.Item>
                      {getFieldDecorator('expenseCategory', {
                        initialValue: field.systemValue[0].entity1Code
                      })(
                        <Input className="csr_input" disabled={true}/>
                        )}
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            ))
          }

          {this.props.transactionQuestionnaire.map((field, index) => (
            field.name === "allocation" && field.systemValue[0].entity2Code &&
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
                xl={{ span: 12 }}
              >
                <Row>
                  <Col span={24} style={{ marginBottom: '5px' }}>
                    Entity Code 2
                  </Col>
                  <Col span={24}>
                    <Form.Item>
                      {getFieldDecorator('expenseCategory', {
                        initialValue: field.systemValue[0].entity2Code
                      })(
                        <Input className="csr_input" disabled={true}/>
                        )}
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            ))
          }

          {
            this.props.transactionQuestionnaire.map((field, index) => (
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
                xl={{ span: 12 }}
                key={index}
              >
                <Row>
                  <Col span={24} style={{ marginBottom: '5px' }}>
                    {field.label}
                  </Col>
                  <Col span={24}>
                    {
                      this.renderFormField(field)
                    }
                  </Col>
                </Row>
              </Col>
            ))
          }

          <Col span={24} style={{ textAlign: 'right' }}>
            <Button className='cadre-btn-default focusbutton button-createtrip-ghost button-wrapclass'
              type='button'
              style={{ marginRight: '10px' }}
              disabled={this.props.isUpdating === true}
              onClick={(e) => {
                e.preventDefault()
                this.handleReset()
              }}>
              Reset
            </Button>
            <Button className='cadre-btn-primary focusbutton button-createtrip button-wrapclass'
              type='button'
              disabled={this.props.isUpdating === true}
              onClick={(e) => {
                e.preventDefault()
                this.handleSubmit()
              }}
            >
              {
                this.props.isUpdating === true ? 'Updating': 'Update and Submit' 
              }
            </Button>
          </Col>
        </Row>
      </Form>
    )
  }
}

const WrappedTransactionDetailsForm = Form.create()(TransactionDetailsForm);

export default WrappedTransactionDetailsForm


