import React from 'react'
import Header from './header'
import Body from './body'
import './transactionDetails.css'

class TransactionDetails extends React.Component {
  render() {
    return (
      <div className='transaction-details'>
        <Header text={`Transaction Id: ${this.props.transactionId}`}/>
        <Body transactionData={this.props.transactionData}
          transactionQuestionnaire={this.props.transactionQuestionnaire}
          isUpdating={this.props.isUpdating}
          updateError={this.props.updateError}
          updateSuccess={this.props.updateSuccess}
          updateTransactionDetails={this.props.updateTransactionDetails}
        />
      </div>
    )
  }
}

export default TransactionDetails