import { call, take, put, select } from 'redux-saga/effects'
import axios from 'axios'
import qs from 'qs'
import { URLs } from '../data/constants'
import actionTypes from '../data/actionTypes'
import {
    refreshTokenRequest,
    refreshTokenSuccess,
    refreshTokenFailure,
    saveToken
} from '../actions/authenticationActions'

export default function* refreshTokenWatcher() {
    while (true) {
        yield take(actionTypes.REFRESH_TOKEN)
        yield call(refreshTokenWorker)
    }
}

function* refreshTokenWorker() {
    try {
        yield put(refreshTokenRequest())
        let refreshToken = yield select(state => state.authenticationReducer.token.access_token)
        let data = {
            grant_type: 'refresh_token',
            refresh_token: refreshToken
        }
        let newToken = yield call(fetchRefreshToken, data)
        let tokenObject = {
            token: newToken,
            tokenTimestamp: Date.now() / 1000
        }
        yield put(saveToken(tokenObject));
        yield put(refreshTokenSuccess())
    } catch (e) {
        console.log(e)
        yield put(refreshTokenFailure({ message: 'cannot refresh token' }))
    }
}

function fetchRefreshToken(data) {
    return axios.post(URLs.tokenUrl + 'token',
            qs.stringify(data), {
                headers: {
                    "Authorization": "Basic Q2FkcmUtV2ViOmNhZHJl"
                }
            }).then(response => response.data)
}