import React from 'react'
import { Row, Col, Avatar } from 'antd'

function Identity(props) {
  return (
    <Row className='traveler-identity'>
      <Col span={8}>
        <Avatar style={{ backgroundColor: '#112A70', fontSize: '16px' }} size='large'>
          <span>
            {props.travelerData.firstName ? props.travelerData.firstName[0].toUpperCase() : ''}
            {props.travelerData.lastName ? props.travelerData.lastName[0].toUpperCase() : ''}
          </span>
        </Avatar>
      </Col>
      <Col span={16}>
        <Row>
          <Col span={24}>
            <span className='traveler-name'>
              {`${props.travelerData.firstName} ${props.travelerData.lastName}`}
            </span>
          </Col>
          <Col span={24}>
            <span className='label'>Traveler id: </span>
            <span className='traveler-id'>{`${props.travelerData.cadreId}`}</span>
          </Col>
          <Col span={24}>
            <span className='label'>Email id: </span>
            <span className='traveler-emailid'>{`${props.travelerData.emailId}`}</span>
          </Col>
          <Col span={24}>
            <span className='label'>Mobile No: </span>
            <span className='traveler-mobile-number'>{`${props.travelerData.phoneNo}`}</span>
          </Col>
          <Col span={24} style={{ marginTop: '5px' }}>
            {
              props.travelerData.flightStatus === 'red' || props.travelerData.flightStatus === 'green' ?
                (
                  <span className='status'>
                    <i className="icon-Airplane-grey-size-1 dashboard-listicons travel-icon"></i>
                    {
                      props.travelerData.flightStatus === 'red' ?
                        <i className="icon-attention status-icon" style={{ color: '#C53D47', verticalAlign: 'sub' }} ></i>
                        : <i className="icon-green-check status-icon" style={{ color: '#00B818', verticalAlign: 'sub' }} ></i>
                    }
                  </span>
                )
                : null
            }

            {
              props.travelerData.hotelStatus === 'red' || props.travelerData.hotelStatus === 'green' ?
                (
                  <span className='status'>
                    <i className="icon-Buildings-grey-size-1 dashboard travel-icon"></i>
                    {
                      props.travelerData.hotelStatus === 'red' ?
                        <i className="icon-attention status-icon" style={{ color: '#C53D47', verticalAlign: 'sub' }} ></i>
                        : <i className="icon-green-check status-icon" style={{ color: '#00B818', verticalAlign: 'sub' }} ></i>
                    }
                  </span>
                )
                : null
            }

            {
              props.travelerData.cardStatus === 'red' || props.travelerData.cardStatus === 'green' ?
                (
                  <span className='status'>
                    <i className="icon-Credit-Card-grey-size1 dashboard-listicons travel-icon"></i>
                    {
                      props.travelerData.cardStatus === 'red' ?
                        <i className="icon-attention status-icon" style={{ color: '#C53D47', verticalAlign: 'sub' }} ></i>
                        : <i className="icon-green-check status-icon" style={{ color: '#00B818', verticalAlign: 'sub' }} ></i>
                    }
                  </span>
                )
                : null
            }
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

function Card(props) {
  return (
    <Row className='card-information'>
      <Col span={6}>
        <i className="icon-Card-Settings" style={{ fontSize: '24px' }}></i>
      </Col>
      <Col span={18}>
        <Row>
          <Col span={24}>
            <span className='label'>Accounting code: </span>
            <span className='traveler-accounting-code'>{`${props.travelerData.accountingCode}`}</span>
          </Col>
          <Col span={24}>
            <span className='label'>Preset card limit: </span>
            <span className='traveler-card-limit'>{`${props.travelerData.cardLimit}`}</span>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

function Manager(props) {
  return (
    <Row className='manager-information'>
      <Col span={24}>
        <div>
          <span className='label'>Manager: </span>
          <span className='traveler-manager'>{`${props.travelerData.managerName || ''}`}</span>
        </div>
        <div>
          <span className='label'>Email Id: </span>
          <span className='traveler-manager-emailid'>{`${props.travelerData.managerEmailId || ''}`}</span>
        </div>
      </Col>
    </Row>
  )
}

class Body extends React.Component {
  render() {
    return (
      <div className='body'>
        <Identity travelerData={this.props.travelerData} />
        <Card travelerData={this.props.travelerData} />
        <Manager travelerData={this.props.travelerData} />
      </div>
    )
  }
}

export default Body