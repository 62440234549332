import React from 'react'
import Header from '../header'
import Body from './body'
import './tripDetails.css'

class TripDetails extends React.Component {
  render() {
    return (
      <div className='trip-details'>
        <Header text='Trip details'/>
        <Body tripData={this.props.tripData}
          downloadFile={this.props.downloadFile}
        />
      </div>
    )
  }
}

export default TripDetails